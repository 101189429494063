import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'pushdr-alert-bar',
  templateUrl: './alert-bar.component.html',
  styleUrls: ['./alert-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertBarComponent implements OnInit {
  /**
   * Material icon name from https://material.io/tools/icons/?style=baseline
   */
  @Input()
  matIconName = '';

  /**
   * Changes the theme colours of the bar and content
   */
  @Input()
  type: 'info' | 'warning' | 'success' | 'error' = 'warning';

  /**
   * Whether the alert can be dismissed by the user
   */
  @Input()
  dismissable = false;

  /**
   * Whether the alert should appear at the top of the screen
   */
  @Input()
  top = false;

  visible = true;
  alertBarClass = {};

  ngOnInit() {
    this.alertBarClass['alert-bar--top'] = this.top;
    this.alertBarClass['alert-bar--' + this.type] = true;
  }
}
