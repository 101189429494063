import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ModalService } from '@pushdr/common/overlay';
import { SnomedData } from '@pushdr/common/types';
import { PharmaceuticalsApiService } from '@pushdr/prescription/api';
import { ClinicalDecisionSupportWarnings } from './models';

export interface ClinicalDecisionSupportQuery {
  dateOfBirth: string;
  gender: string;
  prospectiveMedications: SnomedData[];
  currentMedications: SnomedData[];
  allergies: SnomedData[];
  conditions: SnomedData[];
}

@Injectable({
  providedIn: 'root',
})
export class ClinicalDecisionSupportService {
  constructor(private modal: ModalService, private pharmaApi: PharmaceuticalsApiService) {}

  getCdsWarnings(query: ClinicalDecisionSupportQuery): Observable<ClinicalDecisionSupportWarnings> {
    return this.getCdsWarningsFromApi(query);
  }

  private getCdsWarningsFromApi(query: ClinicalDecisionSupportQuery) {
    const requestPayload = mapCdsQueryToApiPayload(query);
    return this.pharmaApi.getClinicialDecisionSupport(requestPayload).pipe(
      // Maps FDB's CDS raw response into consumable view model by the angular components
      map(cds => ClinicalDecisionSupportWarnings.fromClinicialDecisionSupportResponse(cds)),
      catchError(err => this.showErrorModal(err))
    );
  }

  private showErrorModal(err: any): Observable<never> {
    return this.modal.error(err?.message ?? err).pipe(switchMap(() => throwError(err)));
  }
}

function mapCdsQueryToApiPayload(query: ClinicalDecisionSupportQuery) {
  return {
    ProspectiveMedication: query.prospectiveMedications,
    DateOfBirth: query.dateOfBirth,
    Gender: query.gender === 'male' ? 1 : 2,
    Conditions: query.conditions,
    Allergens: query.allergies,
    CurrentMedication: query.currentMedications,
    ConditionListComplete: true,
  };
}
