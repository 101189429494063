<ng-container *ngIf="patient$ | async as consultation">
  <pushdr-card [hasData]="!submitting">
    <section
      *ngIf="childIdentificationTypes$ | async as identificationTypes"
      class="flex flex-col w-full h-full">
      <header class="relative z-10 p-4 text-xl bg-grey-100 text-bluegrey-800 shadow-header">
        Child verification
      </header>

      <form
        *ngIf="nhsChildFormGroup"
        class="h-full"
        [formGroup]="nhsChildFormGroup"
        (ngSubmit)="nhsChildFormGroup.valid && onSubmitChildNHS()">
        <article
          *ngIf="patient$ | async as consultation"
          class="flex flex-col h-full overflow-y-auto bg-white scrollbar">
          <section class="p-4 font-normal bg-information-light">
            <p class="mb-0 text-sm font-medium text-information">
              Please ask and input the child’s full name, date of birth and gender. Then, verify
              their identity using a UK passport, birth certificate or questions about their medical
              record.
            </p>
          </section>
          <section class="p-4 text-sm">
            <div class="flex flex-row mb-4">
              <div class="w-1/5 mb-4 mr-2">
                <label class="block mb-1 text-sm font-light text-bluegrey-800">First name</label>
                <input
                  placeholder="Type here"
                  formControlName="firstName"
                  class="self-stretch flex-grow h-12 border-2 pdr-input input border-bluegrey-200"
                  type="text" />
              </div>
              <div class="w-1/5 mb-4 mr-2">
                <label class="block mb-1 text-sm font-light text-bluegrey-800">Last name</label>
                <input
                  placeholder="Type here"
                  formControlName="lastName"
                  class="self-stretch flex-grow h-12 border-2 pdr-input input border-bluegrey-200"
                  type="text" />
              </div>
              <div class="w-2/5 mb-4 mr-2">
                <label class="block mb-1 text-sm font-light text-bluegrey-800">DOB</label>
                <input
                  placeholder="DD/MM/YYYY"
                  formControlName="dob"
                  class="self-stretch flex-grow h-12 border-2 pdr-input input border-bluegrey-200"
                  type="date" />
              </div>
              <div class="w-1/5 mb-4 mr-2">
                <label class="block mb-1 text-sm font-light text-bluegrey-800">Gender</label>
                <ng-select
                  formControlName="gender"
                  name="Gender"
                  placeholder="Select"
                  aria-live="polite"
                  [clearable]="false"
                  [ngStyle]="{ width: '100%' }">
                  <ng-option *ngFor="let option of genders; let i = index" [value]="option.value">
                    {{ option.description }}
                  </ng-option>
                </ng-select>
              </div>
            </div>
            <div class="flex flex-row">
              <div class="inline-block mr-10">
                <span class="block mb-1 text-grey-500">Registered GP Surgery</span>
                <span class="block text-bluegrey-800">
                  {{ consultation.Patient.GpSummary.Name | uppercase }}
                </span>
              </div>
            </div>
          </section>
          <section class="px-4 py-2 mx-2 bg-cream">
            <div
              class="flex flex-col items-center justify-center h-full p-4 mx-auto text-base leading-6 text-left rounded bg-body-text sm:w-full md:w-3/5">
              <p class="w-full mt-4 text-base font-normal leading-6 text-white bg-body">
                Ask the account holder to hold up the child’s passport or birth certificate to the
                camera.
              </p>
              <p class="w-full mt-4 mb-4 text-base font-normal leading-6 text-white bg-body">
                If they don’t have either, open the child’s medical record on your other laptop and
                ask the account holder questions about it.
              </p>
            </div>
          </section>
          <div class="flex flex-grow w-full border-b border-solid border-grey-300"></div>
          <section class="p-4">
            <div class="flex flex-row flex-grow">
              <label class="flex-grow mr-6 text-sm text-body-text">
                Child ID type
                <select
                  formControlName="idTypeSelect"
                  class="block p-3 bg-white border border-solid rounded border-bluegrey-600">
                  <option value="null" disabled selected>Pick from dropdown</option>
                  <option *ngFor="let idType of identificationTypes" [ngValue]="idType">
                    {{ idType.Description }}
                  </option>
                </select>
              </label>
              <button
                class="self-end p-3 mx-2 text-white rounded bg-primary hover:bg-primary-hover"
                [ngClass]="{
                  'cursor-not-allowed bg-primary-disabled hover:bg-primary-disabled':
                    !nhsChildFormGroup.valid
                }"
                type="submit">
                Submit
              </button>
            </div>
          </section>
        </article>
      </form>
    </section>
  </pushdr-card>
</ng-container>

<ng-template #loading>
  <pushdr-spinner-loader></pushdr-spinner-loader>
</ng-template>
