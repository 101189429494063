import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { fadeInOnEnterAnimation } from 'angular-animations';
import { CliniciansAuthService } from '@pushdr/clinicians/common';

@Component({
  selector: 'pushdr-clinicians-secure-mobile',
  templateUrl: './secure-mobile.component.html',
  styleUrls: ['./secure-mobile.component.scss'],
  animations: [fadeInOnEnterAnimation({ anchor: 'showContent' })],
})
export class CliniciansSecureMobileComponent implements OnInit {
  textResent = false;
  secureMobile: UntypedFormGroup = null;
  success = false;
  invalidCode = false;

  constructor(
    private auth: CliniciansAuthService,
    private router: Router,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit() {
    if (this.auth.missingAuthCredentials()) {
      this.router.navigate(['/auth/login']);
    }
    this.secureMobile = this.formBuilder.group({
      securityCode1: ['', Validators.required],
      securityCode2: ['', Validators.required],
      securityCode3: ['', Validators.required],
      securityCode4: ['', Validators.required],
    });
  }

  resendCode(e) {
    e.preventDefault();
    this.textResent = true;
    this.auth.resendSMSVerificationCode().subscribe(
      response => {
        setTimeout(() => {
          this.textResent = false;
        }, 10000);
      },
      err => {
        this.textResent = false;
      }
    );
  }

  handleClickToFocusOnInput(e) {
    e.target.value = '';
  }

  handleInput(e) {
    this.invalidCode = false;
    if (e.target.nextElementSibling !== null) {
      e.target.nextElementSibling.value = '';
      e.target.nextElementSibling.focus();
    } else {
      e.target.blur();
      this.invalidCode = this.securityCodeString.length < 4;
      if (!this.invalidCode) {
        this.submit();
      }
    }
  }

  private submit() {
    this.auth.login(this.securityCodeString).subscribe(
      res => {
        this.success = true;
        const hasLastPasswordEmpty = res.DoctorClaim?.LastPasswordUpdate === null;
        const hasPasswordResetNeeded = res.PasswordResetNeeded === true;
        if (hasLastPasswordEmpty || hasPasswordResetNeeded) {
          this.router.navigate(['/auth/update-password']);
        }
      },
      err => {
        this.success = false;
        this.invalidCode = true;
      }
    );
  }

  private get securityCodeString() {
    return Object.values(this.secureMobile.value).join('').toString();
  }
}
