import { Component, OnDestroy, OnInit } from '@angular/core';
import { AnalyticsBusService, AnalyticsEvent } from '@pushdr/common/data-access/analytics';
import { StorageService, SystemCheckService, WebrtcDevicesetupService } from '@pushdr/common/utils';
import { Observable, Subject } from 'rxjs';
import { delay, finalize, takeUntil } from 'rxjs/operators';
import { NetworkSpeedService } from '../../services/network-speed/network-speed.service';
import { PresurgeryService } from '../../services/patientapp-pre-surgery.service';
import { PresurgeryStateEnum } from '../../services/presurgery-common-states';

export enum SpeedCheckStates {
  SPEED_DEFAULT_INFO = 'SpeedDefaultInfo',
  SPEED_ACTIVE_INFO = 'SpeedActiveInfo',
  SPEED_GOOD_INFO = 'SpeedGoodInfo',
  SPEED_OKAY_WARNING = 'SpeedOkayWarning',
  SPEED_SLOW_ERROR = 'SpeedFailedError',
  SPEED_ISSUE_ERROR = 'SpeedIssueError',
  SPEED_DEVICE_ERROR = 'SpeedDeviceError',
}

export interface SpeedTestResult {
  speed: number;
  ping: number;
}

@Component({
  selector: 'pushdr-speed-check',
  templateUrl: './speed-check.component.html',
  styleUrls: ['./speed-check.component.scss'],
})
export class SpeedCheckComponent implements OnInit, OnDestroy {
  isSpeedTestPassed = false;
  isSpeedTestActive = false;
  lottieConfigSpeedTest = {
    path: '/assets/anims/speed-test.json',
    autoplay: true,
    loop: true,
  };
  lottieConfigSuccess = {
    path: '/assets/anims/success.json',
    autoplay: true,
    loop: false,
  };

  _state: SpeedCheckStates = SpeedCheckStates.SPEED_DEFAULT_INFO;
  SpeedCheckStates: typeof SpeedCheckStates = SpeedCheckStates;
  isWindows = this.systemCheck.isWindows;
  helpId: number;
  destroy$ = new Subject<boolean>();

  constructor(
    private bus: AnalyticsBusService,
    private preSurgeryService: PresurgeryService,
    private webRtcDevices: WebrtcDevicesetupService,
    private systemCheck: SystemCheckService,
    private networkSpeedTest: NetworkSpeedService,
    private storage: StorageService
  ) {}

  ngOnInit() {
    this.extractHelpArticleId();
    this.setSpeedCheckPreSurgeryStage();
    this.startSpeedTest();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.webRtcDevices.detachAllStreams();
  }

  get state() {
    return this._state;
  }

  set state(newState) {
    this.preSurgeryService.stateName = this._state = newState;
  }

  retry() {
    if (!this.isSpeedTestActive) {
      this.startSpeedTest();
    }
  }

  gotoWaitingRoom() {
    this.preSurgeryService.stateName = PresurgeryStateEnum.COMPLETED;
  }

  startSpeedTest() {
    this.isSpeedTestActive = true;
    this.state = SpeedCheckStates.SPEED_ACTIVE_INFO;
    this.performSpeedTest()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        speedResult => {
          this.bus.trackEvent(
            AnalyticsEvent.info(
              'tech-checks.speedtest.result',
              'SpeedTEstResult: ' + JSON.stringify(speedResult)
            )
          );
          this.storage.set('cachedSpeedTestResult', speedResult);
          this.handleResults(speedResult);
        },
        error => {
          this.handleError(error);
        }
      );
  }

  private extractHelpArticleId() {
    const help = this.preSurgeryService.help.connection;
    this.helpId = this.isWindows ? help.win : help.mac;
  }

  private setSpeedCheckPreSurgeryStage() {
    this.state = SpeedCheckStates.SPEED_DEFAULT_INFO;
    this.preSurgeryService.stage = 5;
  }

  private performSpeedTest(): Observable<SpeedTestResult> {
    return this.networkSpeedTest.start(5).pipe(
      delay(2000),
      finalize(() => (this.isSpeedTestActive = false))
    );
  }

  private handleResults(results: SpeedTestResult) {
    this.isSpeedTestActive = false;
    if (results.speed > 8) {
      this.speedTestPassed(SpeedCheckStates.SPEED_GOOD_INFO);
      // Todo(mattm): Change this to an input. Pulling from a cookie for FTG for now.
    } else if (results.speed > Number(this.storage.get('speedTestMinimum') || 4)) {
      this.speedTestPassed(SpeedCheckStates.SPEED_OKAY_WARNING);
    } else {
      this.state = SpeedCheckStates.SPEED_SLOW_ERROR;
    }
  }

  private speedTestPassed(messageType: SpeedCheckStates) {
    this.isSpeedTestPassed = true;
    this.preSurgeryService.stage = 6;
    this.state = messageType;
  }

  private handleError(error: any) {
    this.isSpeedTestActive = false;
    this.state = SpeedCheckStates.SPEED_ISSUE_ERROR;
  }
}
