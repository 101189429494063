import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { CardReaderService, CliniciansDoctorsDetailsService } from '@pushdr/clinicians/common';
import { AbstractModal } from '@pushdr/common/overlay';
import { DoctorDetails, SmartCardData } from '@pushdr/doctors/data-access/doctors-api';
import { combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  Canceler,
  CancelReason,
  PrescriptionCancelRequest,
  PrescriptionsManagementService,
} from '../../../../services/prescriptions-management.service';
import { PrescriptionCancelReason, PrescriptionCancelResult } from '../../../../types/Prescription';

export interface CancelPrescriptionModalProps {
  items: Set<string>;
}

export const DEFAULT_VALUE: CancelReason = {
  Code: null,
  Text: 'Pick a reason',
};

export function reasonSelected(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: unknown } | null =>
    control.value !== DEFAULT_VALUE ? null : { notSelected: control.value };
}

@Component({
  selector: 'pushdr-cancel-prescription-modal',
  templateUrl: './cancel-prescription-modal.component.html',
  styleUrls: ['./cancel-prescription-modal.component.scss'],
})
export class CancelPrescriptionModalComponent
  extends AbstractModal<CancelPrescriptionModalProps, PrescriptionCancelResult>
  implements OnInit
{
  items: Set<string> = new Set();
  submitting = false;
  cancellationForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private prescriptionService: PrescriptionsManagementService,
    private cardReaderService: CardReaderService,
    private doctor: CliniciansDoctorsDetailsService
  ) {
    super();

    this.cancellationForm = this.fb.group({
      cancellationReason: [
        DEFAULT_VALUE.Code,
        Validators.compose([Validators.required, reasonSelected()]),
      ],
      accompanyingDescription: [''],
      newPrescriptionRequired: [null, Validators.required],
    });
  }

  ngOnInit() {
    this.items = this.data.items;
  }

  closeWithDelay(result: PrescriptionCancelResult) {
    // we force doctor to wait, so we have bigger chances to have an updated data as its async
    setTimeout(() => {
      this.submitting = false;
      this.done(result);
    }, 2000);
  }

  onSubmit() {
    if (!this.submitting) {
      this.submitting = true;

      combineLatest([this.cardReaderService.getCardData$(false), this.doctor.details$])
        .pipe(take(1))
        .subscribe(([cardInfo, doctor]) => {
          if (cardInfo) {
            const canceler = this.mapCanceler(cardInfo, doctor);
            this.submitCancellation(canceler);
          } else {
            // Card not valid
            this.prescriptionService
              .permissionsError$('cancel', null)
              .pipe(take(1))
              .subscribe(() => {
                this.submitting = false;
                this.done(PrescriptionCancelResult.Error);
              });
          }
        });
    }
  }

  submitCancellation(canceler: Canceler) {
    const data: PrescriptionCancelRequest = this.cancellationForm.value;
    const cancelReason = this.cancellationForm.get('cancellationReason').value;
    data.cancellationReason = {
      Code: cancelReason,
      Text: PrescriptionCancelReason[cancelReason],
    };

    data.canceler = canceler;
    data.medications = Array.from(this.items);
    this.prescriptionService.cancelPrescriptionItems(data).subscribe({
      error: () => {
        this.closeWithDelay(PrescriptionCancelResult.Error);
      },
      complete: () => {
        this.closeWithDelay(PrescriptionCancelResult.Success);
      },
    });
  }

  private mapCanceler(cardInfo: SmartCardData, doctor: DoctorDetails): Canceler {
    const sessionForPractice = cardInfo.rbac.getSelectedSession();

    return {
      cancelersName: doctor.name,
      cancelersGmcNumber: doctor.GMC,
      cancelersUniqueIdentifier: cardInfo.rbac.uniqueUserId,
      cancelersTelephoneNumber: doctor.mobile,
      roleProfileId: cardInfo.rbac.uniqueRoleProfileId,
      roleBasedAccessCode: sessionForPractice.nhsJobRoleCode,
    };
  }
}
