<pushdr-card [hasData]="hasData" [messageOveride]="messageOveride" [class]="extraCssClass">
  <section class="flex flex-col w-full h-full">
    <header
      *ngIf="showHeader"
      data-cy="header"
      class="relative z-10 px-5 py-3 text-xl text-bluegrey-800 shadow-header"
      [class.bg-grey-100]="greyHeader"
      [class.pb-1]="!greyHeader">
      <ng-content select="[header]"></ng-content>
    </header>

    <article
      data-cy="body"
      class="flex flex-col flex-auto flex-grow h-px overflow-y-auto text-sm leading-5 bg-white alert scrollbar"
      [class]="bodyPadding"
      [class.justify-center]="isEmpty"
      [pdScroll]="true"
      (scrolledToEnd)="onScrolledToEnd()">
      <h5 *ngIf="isEmpty" class="text-center">There's nothing here</h5>
      <ng-content select="[body]"></ng-content>
    </article>

    <footer *ngIf="showFooter" data-cy="footer" class="flex flex-col items-center justify-end">
      <ng-content select="[footer]"></ng-content>
    </footer>
  </section>
</pushdr-card>
