<div class="bg-white rounded-md">
  <div class="border-b border-grey-100 p-2">
    <span class="p-3 text-xl text-bluegrey-800">Issues:</span>
  </div>
  <ng-container *ngFor="let issue of issues; let i = index">
    <pushdr-medication-issues-item [issue]="issue" [medicationType]="medicationType">
      <span issue>{{ issues.length - i }}</span>
    </pushdr-medication-issues-item>
  </ng-container>
</div>
