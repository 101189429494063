import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from '@pushdr/common/overlay';
import { DoctorsBulletin, doctorsBulletinPath } from '@pushdr/doctors/data-access/doctors-api';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CliniciansIframeModalComponent } from '../../iframe-modal';
import { CliniciansBulletinsService } from '../bulletins.service';

@Component({
  selector: 'pushdr-clinicians-bulletins-list',
  templateUrl: './bulletins-list.component.html',
  styleUrls: ['./bulletins-list.component.scss'],
})
export class CliniciansBulletinsListComponent implements OnInit {
  @Input() query = '';

  selected: DoctorsBulletin = null;
  bulletins$: Observable<DoctorsBulletin[]>;

  constructor(private bulletinsSvc: CliniciansBulletinsService, private modal: ModalService) {}

  ngOnInit() {
    this.bulletins$ = this.bulletinsSvc
      .getReadBulletins()
      .pipe(
        map(bulletins => [...bulletins.sort((a, b) => moment(b.readDate).diff(moment(a.readDate)))])
      );
  }

  selectBulletin(bulletin: DoctorsBulletin) {
    this.selected = bulletin;
    if (this.isValidDocumentLink(this.selected.link)) {
      this.modal.showCustom(CliniciansIframeModalComponent, {
        title: this.selected.heading,
        linkToDocument: this.selected.link,
        dateCreated: this.selected.dateStamp,
      });
    } else {
      this.modal.error(
        'Something went wrong trying to open this bulletin, please try again later.'
      );
    }
  }

  private isValidDocumentLink(link: string) {
    return !!link && link.startsWith(doctorsBulletinPath);
  }
}
