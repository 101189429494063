import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  RestClient,
  RestErrorParserServiceBypass,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';
import { PrescribedMedication } from '@pushdr/common/types';
import { cache } from '@pushdr/common/utils';
import { EnvironmentProxyService } from '@pushdr/environment';
import { MonoTypeOperatorFunction, Observable, pipe, throwError } from 'rxjs';
import { catchError, map, pluck } from 'rxjs/operators';

import {
  PrescriptionCreate,
  PrescriptionCreateResponse,
  PrescriptionIssueResponse,
  PrescriptionStatusModel,
  SignedPrescription,
} from './models';

@Injectable({
  providedIn: 'root',
})
export class ApiPrescriptionsService extends RestClient {
  private pastCache$: Observable<any>;
  curCache$: Observable<any>;

  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserServiceBypass,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    const baseUrl = this.proxy.environment.doctors.prescriptionAPI;
    return baseUrl + '/';
  }

  create(prescription: PrescriptionCreate): Observable<PrescriptionCreateResponse> {
    const getErrMessage = (error: { error: { errors: Array<{ message: string }> } }) => ({
      message: error?.error?.errors?.reduce((r, c) => r + c.message + '\r\n\r\n', ''),
    });

    return this.post(
      'create',
      { prescription },
      this.headerService.legacyHeadersWithCorrelationId
    ).pipe(
      pluck('prescription'),
      catchError(error => throwError(getErrMessage(error)))
    );
  }

  issue(prescription: SignedPrescription): Observable<PrescriptionIssueResponse> {
    return this.post('issue', { prescription }, this.headerService.legacyHeadersWithCorrelationId);
  }

  hasPrescribed(orderId: number): Observable<PrescriptionStatusModel[]> {
    const headers = this.headerService.legacyHeadersWithCorrelationId;
    return this.get('hasPrescribed', { consultationId: orderId }, headers);
  }

  cancel(): Observable<any[]> {
    return this.post('cancel', {}, this.headerService.legacyHeadersWithCorrelationId);
  }

  cancelitem(): Observable<any> {
    return this.post('cancelitem', {}, this.headerService.legacyHeadersWithCorrelationId);
  }

  getStatus(type: string, conversationId: string): Observable<any> {
    return this.get(
      `status/${type}/${conversationId}`,
      this.headerService.legacyHeadersWithCorrelationId
    );
  }

  search(
    prescriptionId: string = null,
    patientId: number = null,
    prescriberId: number = null,
    startTime: number = null
  ): Observable<any[]> {
    return this.post('search', {}, this.headerService.legacyHeadersWithCorrelationId);
  }

  getCurrentPushDoctorAcuteMedications(nhsNumber: string): Observable<PrescribedMedication[]> {
    this.curCache$ = (x =>
      x
        ? x
        : this.get<PrescribedMedication[]>(
            `medications/current/${nhsNumber}`,
            {},
            this.headerService.legacyHeadersWithCorrelationId
          ).pipe(markAsActive(true), cache()))(this.curCache$);
    return this.curCache$;
  }

  getPastPushDoctorAcuteMedications(nhsNumber: string): Observable<PrescribedMedication[]> {
    this.pastCache$ = (x =>
      x
        ? x
        : this.get<PrescribedMedication[]>(
            `medications/past/${nhsNumber}`,
            {},
            this.headerService.legacyHeadersWithCorrelationId
          ).pipe(markAsActive(false), cache()))(this.pastCache$);
    return this.pastCache$;
  }
}

function markAsActive(active: boolean): MonoTypeOperatorFunction<PrescribedMedication[]> {
  return pipe(map(meds => meds.map(med => ({ ...med, isActive: active }))));
}
