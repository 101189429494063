<div class="c-modal__wrap" [cdkTrapFocus]="ready$ | async">
  <h2 *ngIf="data.header">
    {{ data.header }}
  </h2>

  <div *ngIf="data.bottomText && !data.customHTML">
    <h4>{{ data.bottomText }}</h4>
  </div>

  <div *ngIf="data.customHTML" [innerHTML]="data.customHTML"></div>

  <div class="modal__footer">
    <button class="c-btn" (click)="done(true)" [ngClass]="'c-btn--' + data.buttonConfirmType">
      {{ data.confirm }}
    </button>
    <button class="c-btn c-btn--secondary" (click)="done(false)">{{ data.cancel }}</button>
  </div>
</div>
