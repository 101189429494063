import { PrescriptionStatus } from '../prescriptions';
import { SnomedData } from './snomed-data';

export enum MedicationTypes {
  acute = 'acute',
  repeat = 'repeat',
  repeatDispensing = 'repeat-dispensing',
  delayedPrescribing = 'delayed-prescribing',
  acuteHandwritten = 'acute-handwritten',
  unk = 'unk',
}

export enum StructuredPrescriptionStatus {
  active = 'active',
  completed = 'completed',
  stopped = 'stopped',
}

export type MedicationStatus = PrescriptionStatus | StructuredPrescriptionStatus;

export interface PrescribedMedication {
  isPd: boolean;
  isActive: boolean;
  type: MedicationTypes;
  medicationCode: string;
  medicationName: string;
  medicationStatus: MedicationStatus;
  cancellationReason?: string;
  accompanyingDescription?: string;
  quantity: number | null;
  unitTypeDescription: string;
  dosage: string;
  startDate: string;
  endDate: string;
  daysDuration: number;
  durationUnit?: string;
  patientNotes: string;
  endorsement?: string[];
  controlledScheduleNumber?: number;
  statement?: any;
  requests?: any;
  snomedCode?: string;
}

export interface PatientMedicationGroup {
  groupName: string;
  medications: PrescribedMedication[];
}

export interface PatientRecordHTMLSection {
  name: string;
  value: string;
  error?: any;
}

export class PatientHTMLRecords {
  parsedRecords: { [key: string]: PatientRecordHTMLSection };
  error: any;

  original: any;

  static fromApi(response) {
    const record = new PatientHTMLRecords();
    record.original = response;
    record.parsedRecords = record.parseRecords(response);
    return record;
  }

  static fromError(error) {
    const record = new PatientHTMLRecords();
    record.parsedRecords = {} as any;
    record.error = error;
    return record;
  }

  getSection(recordSection: RecordsSectionTypes) {
    const section = this.parsedRecords[recordSection] || { name: recordSection, value: null };
    section.error = this.error;
    return section;
  }

  private parseRecords(records: { [key: number]: any }): { [key: string]: any } {
    const dictionary = {};
    const recordKeys = Object.keys(records);
    recordKeys.forEach(recordKey => {
      const record = records[recordKey];
      const key = this.getRecordSectionAbreviation(record);
      const name = this.getRecordSectionTitle(record);
      const value = this.getRecordHTML(record);
      dictionary[key] = {
        name,
        value,
      };
    });
    return dictionary as { [section: string]: PatientRecordHTMLSection };
  }

  private getRecordHTML(record: any) {
    return record.entry[0].resource.section[0].text.div;
  }

  private getRecordSectionTitle(record: any) {
    return record.entry[0].resource.section[0].title;
  }

  private getRecordSectionAbreviation(record: any) {
    return record.entry[0].resource.section[0].code.coding[0].code;
  }
}

export interface PatientRecordsSection {
  name: string;
  value: string;
}

export enum RecordsSectionTypes {
  ADMINISTRATIVE = 'ADM',
  ALLERGIES = 'ALL',
  CLINICAL = 'CLI',
  ENCOUNTERS = 'ENC',
  IMMUNISATIONS = 'IMM',
  MEDICATIONS = 'MED',
  OBSERVATIONS = 'OBS',
  PROBLEMS = 'PRB',
  REFERRALS = 'REF',
  SUMMARY = 'SUM',
}

export class PatientStructuredRecords {
  original: any;
  lists = [];
  allergies = [];
  patientWarning: string;
  patient: any;
  snomedAllergies = [];
  snomedMedications = [];
  snomedConditions = [];

  snomedActiveAllergies: SnomedData[] = [];
  snomedActiveMedications: SnomedData[] = [];

  private practitionersMap = new Map<string, any>();
  private organisationsMap = new Map<string, any>();

  displayedMedications = [];
  displayedMedicationsCombined: PrescribedMedication[] = [];

  private statements = [];
  private medications = new Map<string, any>();
  private requests = { plan: [], order: [] };

  get medication() {
    return this.displayedMedications;
  }

  get practitioners() {
    return this.getDataArrayFromMap(this.practitionersMap);
  }

  get organisations() {
    return this.getDataArrayFromMap(this.organisationsMap);
  }

  static fromRecords(input: any) {
    const record = new PatientStructuredRecords();
    record.original = input;
    input.entry.forEach(entry => {
      record.populateRecord(entry);
    });
    record.createMedicationsMap();
    record.checkForWarnings();
    record.initialiseSnomedMappings();
    return record;
  }

  initialiseSnomedMappings() {
    const fhirAllergies = this.allergies;
    const fhirMedications = this.displayedMedications.map(med => med.medication);
    const fhirActiveMedications = this.displayedMedications
      .filter(med => med.statement.status === 'active')
      .map(med => med.medication);

    this.snomedAllergies = this.getSnomedCodedData(fhirAllergies);
    this.snomedMedications = this.getSnomedCodedData(fhirMedications);

    const filterActiveAllergy = fhir => fhir.clinicalStatus === 'active';
    this.snomedActiveAllergies = this.getSnomedCodedData(fhirAllergies, filterActiveAllergy);
    this.snomedActiveMedications = this.getSnomedCodedData(fhirActiveMedications);
  }

  getSnomedCodedData(data: any[], filterFn?: (fhirItem) => boolean): SnomedData[] {
    const sourceData = filterFn ? data.filter(filterFn) : data;
    return sourceData.map(fhirData => ({
      snomedCode:
        (
          (fhirData &&
            fhirData.code &&
            fhirData.code.coding.find(item => item.system.includes('snomed'))) ||
          {}
        ).code || '',
      snomedName:
        (fhirData && fhirData.code && fhirData.code.text) ||
        (fhirData &&
          fhirData.code &&
          fhirData.code.coding &&
          fhirData.code.coding[0] &&
          fhirData.code.coding[0].display) ||
        '',
    }));
  }

  private findItem(arr: any[], key: string, expectedValue: string): any {
    if (arr && arr.length) {
      return arr.find(item => item[key].includes(expectedValue)) || {};
    } else {
      return {};
    }
  }

  private createMedicationsMap() {
    this.displayedMedications = this.statements.map(statement => {
      const medicationRequestReferenceId = statement.basedOn[0].reference.split('/')[1];
      const plan = this.requests.plan.find(
        currentRequest => medicationRequestReferenceId === currentRequest.id
      );
      const identifier = plan.groupIdentifier
        ? plan.groupIdentifier.value
        : plan.id.split('_plan')[0];
      const order = this.requests.order.filter(currentRequest => {
        const currentRequestIdentifier = currentRequest.groupIdentifier
          ? currentRequest.groupIdentifier.value
          : currentRequest.id.split('_plan')[0];
        return identifier === currentRequestIdentifier;
      });
      const medicationReferenceId = statement.medicationReference.reference.split('/')[1];
      const medication = this.medications.get(medicationReferenceId);
      const type = this.getMedicationPrescriptionType(plan);
      const requests = [plan, ...order];
      return { statement, medication, requests, type };
    });
    this.displayedMedicationsCombined = this.displayedMedications.map(medication => {
      const medicationPlan = this.findItem(medication.requests, 'intent', 'plan');
      const duration = medicationPlan?.expectedSupplyDuration;
      const dosageInstruction = medicationPlan?.dosageInstruction[0];
      const quantityInstructions = medicationPlan?.dispenseRequest?.quantity;
      const quantityUnit = this.findItem(
        quantityInstructions?.extension,
        'url',
        'MedicationQuantityText'
      );

      return {
        isPd: false,
        isActive: medication.statement.status === StructuredPrescriptionStatus.active,
        medicationName:
          medication.medication.code.text || medication.medication.code.coding[0]?.display,
        // medicationCode: medication.medication.code.value || medication.medication.code.coding[0]?.value,
        medicationStatus: medication.statement.status,
        daysDuration: duration?.value,
        durationUnit: duration?.unit,
        type: medication.type,
        quantity: quantityInstructions?.value,
        unitTypeDescription: quantityUnit?.valueString,
        dosage: dosageInstruction?.text,
        patientNotes: dosageInstruction?.patientInstruction,
        startDate: medication.statement.effectivePeriod.start,
        endDate: medication.statement.effectivePeriod.end,
        statement: medication.statement,
        requests: medication.requests,
      } as PrescribedMedication;
    });
  }

  private checkForWarnings() {
    if (this.lists.length > 0 && this.lists[0].extension) {
      const warningObj = this.lists[0].extension.find(item => item.url.includes('ListWarningCode'));
      if (warningObj) {
        const warningMessage = this.lists[0].note[0].text;
        this.patientWarning = warningMessage || '';
      }
    }
  }

  private getDataArrayFromMap(map: Map<any, any>) {
    return Array.from(map.entries()).map(([id, data]) => data);
  }

  private populateRecord(careRecord: any) {
    const resource = careRecord.resource;
    switch (resource.resourceType) {
      case 'AllergyIntolerance': {
        this.allergies.push(resource);
        break;
      }
      case 'List': {
        this.parseListForAllergies(resource);
        this.lists.push(resource);
        break;
      }
      case 'Medication': {
        this.createOrUpdateMedicationEntry(resource, resource.id);
        break;
      }
      case 'MedicationRequest': {
        this.requests[resource.intent].push(resource);
        break;
      }
      case 'MedicationStatement': {
        this.statements.push(resource);
        break;
      }
      case 'Patient': {
        this.patient = resource;
        break;
      }
      case 'Organization': {
        this.organisationsMap.set(resource.id, resource);
        break;
      }
      case 'Practitioner': {
        this.practitionersMap.set(resource.id, resource);
        break;
      }
      default:
        console.warn(careRecord.resource.resourceType + ': resourceType not accounted for.');
        break;
    }
  }

  private parseListForAllergies(listResource) {
    if (
      listResource.status === 'current' &&
      listResource.title.toLowerCase().includes('allergies') &&
      listResource.contained
    ) {
      listResource.contained.forEach(entry => {
        if (entry.resourceType === 'AllergyIntolerance') {
          this.allergies.push(entry);
        }
      });
    }
  }

  private createOrUpdateMedicationEntry(resource: any, medicationId: string) {
    if (this.medications.has(medicationId)) {
      throw new Error(medicationId + ' exists already');
    }
    this.medications.set(medicationId, resource);
  }

  private getMedicationPrescriptionType(request: any) {
    return request.extension.find(extension => extension.url.includes('PrescriptionType'))
      .valueCodeableConcept.coding[0].code;
  }
}
