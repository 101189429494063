export type AnalyticsProperties = { [key: string]: any };
export type AnalyticsMessage = { [key: string]: any } | string | number | boolean;
export enum LoggingEvent {
  Info = 'INFO',
  Error = 'ERROR',
}

export class AnalyticsEvent {
  view?: string;
  action: string;
  target?: string;
  properties?: AnalyticsProperties;

  constructor(data) {
    this.view = data.view || '';
    this.action = data.action || '';
    this.target = data.target || '';
    this.properties = data.properties || {};
  }

  static userType(tgt: string) {
    return this.eventFactory('userType', tgt);
  }

  static log(tgt: string, msg: AnalyticsMessage, props?: AnalyticsProperties): AnalyticsEvent {
    return this.eventFactory('log', tgt, msg, props);
  }

  static info(tgt: string, msg: AnalyticsMessage, props?: AnalyticsProperties): AnalyticsEvent {
    return this.eventFactory('info', tgt, msg, { ...props, type: LoggingEvent.Info });
  }

  static error(tgt: string, msg: AnalyticsMessage, props?: AnalyticsProperties): AnalyticsEvent {
    return this.eventFactory('error', tgt, msg, { ...props, type: LoggingEvent.Error });
  }

  static network(tgt: string, msg: AnalyticsMessage, props?: AnalyticsProperties): AnalyticsEvent {
    return this.eventFactory('network', tgt, msg, props);
  }

  static system(tgt: string, msg: AnalyticsMessage, props?: AnalyticsProperties): AnalyticsEvent {
    return this.eventFactory('system', tgt, msg, props);
  }

  static router(tgt: string, msg: string, props?: AnalyticsProperties): AnalyticsEvent {
    const event = this.eventFactory('router', tgt, msg, { ...props, type: LoggingEvent.Info });
    event.view = msg;
    return event;
  }

  static eventFactory(
    action: string,
    target: string,
    message: AnalyticsMessage = {},
    otherProperties: AnalyticsProperties = {}
  ) {
    return new AnalyticsEvent({
      action,
      target,
      properties: {
        message,
        ...otherProperties,
      },
    });
  }
}

export class AnalyticsUser {
  id: string;
  [key: string]: string;

  constructor(input: any) {
    this.id = input ? input.id : 'anon';
  }
}

export interface AnalyticsIntegrationConfig {
  events: string[];
  user: string[];
}
