<form [formGroup]="prescriptionForm" autocomplete="off" class="flex flex-col p-1 text-bluegrey-800">
  <div
    class="mb-6"
    [class.pdr-input--error]="
      prescriptionForm.get('dosage').touched && !prescriptionForm.get('dosage').valid
    ">
    <label class="block text-sm text-grey-700" for="dosage">
      Dosage
      <input
        formControlName="dosage"
        id="dosage"
        placeholder="Type dosage instructions in full without abbreviations"
        class="pdr-input" />
    </label>
  </div>

  <div class="mb-6">
    <div class="flex flex-wrap">
      <div class="w-1/4 pr-3">
        <label class="block text-sm" for="duration">
          Duration <span class="text-grey-500">(optional)</span>
        </label>
        <div
          class="flex flex-nowrap items-center"
          [class.pdr-input--error]="
            prescriptionForm.get('duration').touched && !prescriptionForm.get('duration').valid
          ">
          <input
            type="number"
            formControlName="duration"
            id="duration"
            class="mr-1 pdr-input"
            placeholder="##" />
          <span class="text-sm text-bluegrey-700">Day(s)</span>
        </div>
      </div>
      <div class="w-3/4 pr-3 pb-5 relative">
        <label class="block text-sm" for="quantity"> Quantity </label>
        <div
          class="flex flex-nowrap items-center"
          [class.pdr-input--error]="
            prescriptionForm.get('quantity').touched && !prescriptionForm.get('quantity').valid
          ">
          <input
            type="number"
            formControlName="quantity"
            id="quantity"
            class="w-auto mr-1 pdr-input"
            placeholder="##" />
          <ng-container *ngIf="medication.unitsOfMeasure?.length === 1">
            <span class="text-sm text-bluegrey-700"
              >{{ medication.unitsOfMeasure[0]?.unitTypeDescription | toTitleCase }}(s)</span
            >
          </ng-container>
          <ng-container *ngIf="medication.unitsOfMeasure?.length > 1">
            <select formControlName="unitOfMeasure" class="px-1 py-3 ml-1">
              <option *ngFor="let unit of medication.unitsOfMeasure" [ngValue]="unit.unitTypeCode">
                {{ unit.unitTypeDescription }}(s)
              </option>
            </select>
          </ng-container>
        </div>
        <i *ngIf="prescriptionForm.get('quantity').value !== null" class="absolute bottom-0">
          <ng-container *ngIf="prescriptionForm.get('quantity').valid; else invalidQuantity">
            <ng-container *ngIf="medication.areWordsAndFiguresRequired">
              {{ prescriptionForm.get('quantity').value | numberToWords }}
            </ng-container>
          </ng-container>
          <ng-template #invalidQuantity>
            <ng-container *ngIf="prescriptionForm.get('quantity').errors.max">
              Maximum quantity exceeded
            </ng-container>
            <ng-container *ngIf="prescriptionForm.get('quantity').errors.pattern">
              Decimal point exceeded
            </ng-container>
          </ng-template>
        </i>
      </div>
    </div>
  </div>

  <div class="mb-6">
    <div class="flex flex-nowrap">
      <div class="w-1/3">Pack size options</div>
      <div class="w-2/3">Form</div>
    </div>
    <div class="flex flex-nowrap p-3 pl-5 pr-5 mb-5 bg-bluegrey-100">
      <div class="w-1/3 font-bold">
        {{ medication | medicationPackSizes: prescriptionForm.get('unitOfMeasure')?.value }}
      </div>
      <div class="w-2/3 font-bold">
        {{ medication.formulation }}
      </div>
    </div>
  </div>

  <div class="mb-6">
    <label class="block text-sm"> Endorsements </label>

    <div
      *ngFor="let endorsement of endorsementsVocabulary; let i = index"
      formArrayName="endorsements">
      <label
        *ngIf="
          !endorsement.fdbOnly ||
          (medicationEndorsementsCodes.includes(endorsement.code) && endorsement.fdbOnly)
        "
        class="flex items-center flex-grow-0 flex-shrink w-1/2 text-sm text-grey-700"
        [for]="endorsement.code">
        <input
          *ngIf="endorsement.code !== endorsementCodeACBS"
          type="checkbox"
          [formControlName]="i"
          class="m-2 ml-0"
          [id]="endorsement.code"
          [defaultChecked]="endorsement.checked || endorsement.code === endorsementCodeACBS" />
        <ng-container *ngIf="endorsement.code === endorsementCodeACBS">
          <input type="hidden" [formControlName]="i" [id]="endorsement.code" />
          <input type="checkbox" class="m-2 ml-0" checked disabled />
        </ng-container>
        <span>
          {{ endorsement.label }}
        </span>
        <span class="text-bluegrey-600" [attr.tooltip-right]="endorsement.description">
          <i class="m-2 text-lg material-icons">info</i>
        </span>
      </label>
    </div>
  </div>

  <div class="mb-3">
    <pushdr-input-with-character-count
      [label]="'Pharmacy note'"
      [control]="prescriptionForm.get('pharmacyNote')"
      [max]="120"
      [optional]="true"></pushdr-input-with-character-count>
  </div>
</form>
