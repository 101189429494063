<pushdr-consultation-card [showFooter]="showFooter$ | async" bodyPadding="relative">
  <pushdr-prescription-card-header header></pushdr-prescription-card-header>

  <ng-container body>
    <div class="p-5">
      <pushdr-fdb-search-medications
        noMatchesText="No matching medications, please check your spelling. If the medication still doesn\'t appear, then it is not available on the Push Doctor formulary. Please select an alternative or signpost the patient to their usual GP."
        (medicationChange)="medicationChanged($event)">
      </pushdr-fdb-search-medications>
    </div>
    <div class="h-full relative">
      <ng-container *ngIf="warnings$ | async as warnings; else noWarnings">
        <ng-container *ngIf="showOverrideWarning; else noOverrideWarning">
          <pushdr-override-warning
            [control]="overrideWarningControl"
            (pdrReturn)="showOverrideWarning = false"></pushdr-override-warning>
        </ng-container>
        <ng-template #noOverrideWarning>
          <pushdr-fdb-clinical-decision-support
            [warnings]="warnings"
            (pdrViewed)="viewedAllWarnings = true">
          </pushdr-fdb-clinical-decision-support>
        </ng-template>
      </ng-container>
      <ng-template #noWarnings>
        <pushdr-spinner-loader
          *ngIf="warningsLoading; else noWarningsLoading"
          message="Getting medication decision support"
          [minheight]="0">
        </pushdr-spinner-loader>
      </ng-template>
      <ng-template #noWarningsLoading>
        <p
          *ngIf="hasWarningsError"
          class="p-4 mx-5 border border-red-500 bg-red-100 text-red-500 rounded">
          Sorry, currently we are unable to provide support via
          <abbr title="Clinical Decision Support">CDS</abbr> channel. Please continue with the
          consultation, and use either EMIS/TPP system where necessary.
        </p>
      </ng-template>
    </div>
  </ng-container>

  <ng-container footer>
    <div class="flex mt-3 mb-3">
      <div class="flex flex-col w-full items-center">
        <ng-container *ngIf="showOverrideWarning; else noOverrideButton">
          <button
            data-cy="proceed"
            class="p-3 btn text-white bg-red-500 w-48"
            (click)="acceptWarningsAndProceed()"
            [disabled]="!overrideWarningControl.valid">
            Override warning
          </button>
        </ng-container>
        <ng-template #noOverrideButton>
          <button
            data-cy="proceed"
            class="p-3 btn text-white bg-red-500 w-48"
            (click)="acceptWarningsAndProceed()"
            [disabled]="!viewedAllWarnings">
            Proceed
          </button>
          <p class="mb-0 mt-1 text-sm text-red-500" *ngIf="!viewedAllWarnings">
            Please view all the warnings before proceeding
          </p>
        </ng-template>
      </div>
    </div>
  </ng-container>
</pushdr-consultation-card>
