import { Injectable } from '@angular/core';
import {
  ApiDoctorsAccount as ApiCliniciansAccount,
  ApiDoctorsConsultation as ApiCliniciansConsultation,
} from '@pushdr/doctors/data-access/doctors-api';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WaitingRoomService {
  constructor(
    private accountApi: ApiCliniciansAccount,
    private consultationApi: ApiCliniciansConsultation
  ) {}

  getNumberOfPatientsWaiting() {
    return this.accountApi
      .getBookingStatus()
      .pipe(map(response => parseInt(response.line4.replace(/[^0-9.]/g, ''), 10)));
  }

  getWhereIShouldBe() {
    return this.consultationApi.whereShouldIBe();
  }
}
