import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgModule,
  AfterViewInit,
  Output,
} from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[pdScroll]',
})
export class ScrollDirective implements AfterViewInit {
  @Input('pdScroll') isFocused: boolean;
  @Output() scrolledToEnd = new EventEmitter();

  constructor(private hostElement: ElementRef) {}

  @HostListener('scroll', ['$event'])
  onScroll(event: Event) {
    const el = event.target as HTMLElement;
    if (el.offsetHeight + el.scrollTop >= el.scrollHeight - 1) {
      this.scrolledToEnd.emit();
    }
  }

  ngAfterViewInit() {
    const el = this.hostElement.nativeElement as HTMLElement;
    if (el.offsetHeight && Math.abs(el.offsetHeight - el.scrollHeight) <= 2) {
      //This accounts for some issues when scaling the website.
      setTimeout(() => {
        this.scrolledToEnd.emit();
      });
    }
  }
}

@NgModule({
  declarations: [ScrollDirective],
  exports: [ScrollDirective],
})
export class ScrollModule {}
