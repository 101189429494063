import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CardReaderService,
  CliniciansDoctorsDetailsService,
  ClinicianSessionsService,
} from '@pushdr/clinicians/common';
import {
  DoctorSession as ClinicianSession,
  DoctorSessionStatus as ClinicianSessionStatus,
} from '@pushdr/doctors/data-access/doctors-api';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ClinicianAwayService } from '../../services/clinician-away/clinician-away-service.service';

@Component({
  selector: 'pushdr-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit, OnDestroy {
  nextOrCurrent$: Observable<ClinicianSession>;
  todaysSessions$: Observable<ClinicianSession[]>;
  ClinicianSessionStatus: typeof ClinicianSessionStatus = ClinicianSessionStatus;
  ngUnsubscribe$ = new Subject<void>();

  constructor(
    private sessions: ClinicianSessionsService,
    private awayState: ClinicianAwayService,
    private router: Router,
    private route: ActivatedRoute,
    private details: CliniciansDoctorsDetailsService,
    private cardReader: CardReaderService
  ) {}

  ngOnInit() {
    this.details.details$.pipe(take(1)).subscribe(details => {
      this.sessions.pollSessionsUntil(
        this.ngUnsubscribe$,
        30000,
        moment().startOf('day'),
        moment().endOf('day')
      );
      this.todaysSessions$ = this.sessions.todaysSessions$();
      this.nextOrCurrent$ = this.sessions.nextOrCurrent$();
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  showEnd(session: ClinicianSession) {
    return this.sessions.isLateToEnd(session) && this.sessions.isActive(session);
  }

  showResume(session: ClinicianSession) {
    return this.sessions.isActive(session);
  }

  showStart(session: ClinicianSession, next: ClinicianSession) {
    return next && session.id === next.id && !this.sessions.isActive(session);
  }

  status(session: ClinicianSession) {
    switch (session.status) {
      case ClinicianSessionStatus.PENDING:
        return this.sessions.isLateToEnd(session)
          ? 'Missed'
          : this.sessions.isLateToStart(session)
          ? 'Ready'
          : 'Upcoming';
      case ClinicianSessionStatus.IN_PROGRESS:
      case ClinicianSessionStatus.ON_BREAK:
        return 'Active';
      case ClinicianSessionStatus.COMPLETED:
        return 'Completed';
      default:
        return 'Unknown';
    }
  }

  async startConsulting(id: string) {
    await this.cardReader.preloadCardInfoAsync();
    await this.sessions.startSession(id);
    this.resumeConsulting();
  }

  async resumeConsulting() {
    await this.cardReader.preloadCardInfoAsync();
    this.router.navigate(['../active'], { relativeTo: this.route });
  }

  endConsulting(id: string) {
    this.sessions.endSession(id);
    this.awayState.setUnavailable();
  }
}
