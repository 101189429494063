import { Component } from '@angular/core';
import { ModalService } from '@pushdr/common/overlay';
import { fadeInOnEnterAnimation } from 'angular-animations';
import { PatientNavigationService } from '../../../patient/services/patient-navigation/patient-navigation.service';
import { ConsultationSummaryService } from '../../services/consultation-summary/consultation-summary.service';

@Component({
  selector: 'pushdr-doctors-additional-notes',
  templateUrl: './additional-notes.component.html',
  styleUrls: ['./additional-notes.component.scss'],
  animations: [fadeInOnEnterAnimation({ anchor: 'showNotes' })],
})
export class AdditionalNotesComponent {
  // TODO use a formcontrol
  additionalNotesText = '';
  loading = false;

  constructor(
    private summaryService: ConsultationSummaryService,
    private navigation: PatientNavigationService,
    private modal: ModalService
  ) {}

  onInputAdditionalNotes(e) {
    this.additionalNotesText = e.target.value;
  }

  onSubmitAdditionalNotes() {
    if (this.loading) return false;

    this.loading = true;
    const otherReasons = this.summaryService.otherReasonText
      ? '[Other: ' + this.summaryService.otherReasonText + '], '
      : '';
    const additionalNotes = this.additionalNotesText
      ? '[Additional: ' + this.additionalNotesText + '], '
      : '';
    const clericalNote = otherReasons + additionalNotes;

    this.summaryService.sendClericalNote(clericalNote).subscribe({
      error: () => this.notifyErrorSaving(),
      complete: () => this.returnToWaitingRoom(),
    });
  }

  notifyErrorSaving() {
    this.modal
      .confirm(
        'Something went wrong',
        'We were unable to save your clinical notes for this consultation, try again or return to the waiting room',
        'Continue to waiting room',
        'Try again'
      )
      .subscribe(gotoWR => {
        this.loading = false;
        if (gotoWR) {
          this.returnToWaitingRoom();
        }
      });
  }

  returnToWaitingRoom() {
    this.summaryService.otherReasonText = '';
    this.navigation.gotoWaitingRoom();
  }

  formatNote(otherReasonText, additionalNotesText) {
    let note = otherReasonText ? `[Other: ${otherReasonText}], ` : '';
    note += additionalNotesText ? `[Additional: ${additionalNotesText}]` : '';
    return note;
  }
}
