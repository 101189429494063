import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function matchValueValidator(sourceControl: string, targetControl: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    const source = control.get(sourceControl);
    const target = control.get(targetControl);
    const isMatch = source?.value === target?.value;
    return isMatch ? null : { mismatch: true };
  };
}
