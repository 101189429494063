import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ENDORSEMENTS_VOCABULARY } from '@pushdr/prescription/api';
import { DoctorDetails } from '@pushdr/doctors/data-access/doctors-api';
import { PrescriptionCreateResponse, PrescriptionMedication } from '@pushdr/prescription/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { PrescriptionSigningService } from '../services/prescription-signing.service';
import { PrescriptionStatusService } from '../services/prescription-status.service';

@Component({
  selector: 'pushdr-sign-prescription',
  templateUrl: './sign-prescription.component.html',
  styleUrls: ['./sign-prescription.component.scss'],
})
export class SignPrescriptionComponent implements OnInit {
  frmSignPrescription: UntypedFormGroup;
  medications: PrescriptionMedication[];
  doctorInfo$: Observable<DoctorDetails>;
  prescriptions: PrescriptionCreateResponse[];

  scrolledToEnd$ = new BehaviorSubject<boolean>(false);
  isScrolledToEnd$ = this.scrolledToEnd$ as Observable<boolean>;
  issuingPrescription = false;

  constructor(
    private prescriptionSigning: PrescriptionSigningService,
    private prescriptionStatus: PrescriptionStatusService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  isSchedule2or3(item: PrescriptionMedication): boolean {
    return !![2, 3].find(x => x === item.controlledScheduleNumber);
  }

  ngOnInit(): void {
    this.frmSignPrescription = this.fb.group({
      pin: ['', Validators.compose([Validators.required, Validators.pattern(/^[0-9]\d*$/)])],
    });
    this.prescriptions = this.prescriptionSigning.createPrescriptionResponse?.prescriptions;
    this.medications = this.prescriptions
      ? [].concat(...this.prescriptions.map(p => p.medications))
      : [];
  }

  getEndorsments = (codes: string[]) =>
    codes.map(code => ENDORSEMENTS_VOCABULARY.find(f => f.code === code));

  confirm() {
    const handlePrescriptionIssue = () => {
      this.router.navigate(['../select-medication'], { relativeTo: this.route });
      this.prescriptionStatus.markAsPrescribed();
    };

    if (this.frmSignPrescription.valid && !this.issuingPrescription) {
      this.issuingPrescription = true;
      const cardPin = this.frmSignPrescription.get('pin').value;
      this.prescriptionSigning
        .issuePrescription$(cardPin)
        .pipe(take(1))
        .subscribe({
          next: handlePrescriptionIssue,
          error: () => (this.issuingPrescription = false),
        });
    }
  }

  cancel() {
    this.router.navigate(['../review-prescription'], { relativeTo: this.route });
  }

  getPrescriptionIds() {
    return this.prescriptions?.map((x, i) => `ID#${i + 1} ${x.prescriptionId}\r\n`).join('');
  }

  getFirstPrescription = () => (this.prescriptions ? this.prescriptions[0] : null);

  onContainerScroll() {
    this.scrolledToEnd$.next(true);
  }
}
