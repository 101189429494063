import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ConsultationState, ConsultationStateService } from '@pushdr/clinicians/common';
import { ModalService } from '@pushdr/common/overlay';
import { CustomerType, PatientDetailsV2 } from '@pushdr/common/types';
import { SystemCheckService } from '@pushdr/common/utils';
import { CurrentOrderIdService } from '@pushdr/common/data-access/analytics';
import { ApiDoctorsConsultation, PatientAlert } from '@pushdr/doctors/data-access/doctors-api';
import { combineLatest, EMPTY, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { ConsultationFeatureService } from '../../../../../services/consultation-feature/consultation-feature.service';
import { PatientDetailsService } from '../../../../../services/patient-details/patient-details.service';
import { PatientNavigationService } from '../../../services/patient-navigation/patient-navigation.service';
import { ChildIdModalComponent } from './components/child-id-modal/child-id-modal.component';
import { IdCheckService, IdentificationType } from '../../../services/id-check/id-check.service';
import { ActivatedRoute, Router } from '@angular/router';

export interface ChildConfig {
  isChild: boolean;
  canConsultWithChildren: boolean;
}
@Component({
  selector: 'pushdr-id-checks',
  templateUrl: './id-checks.component.html',
  styleUrls: ['./id-checks.component.scss'],
})
export class IdChecksComponent implements OnInit {
  patient$: Observable<PatientDetailsV2>;
  idBase64Image$: Observable<string>;
  accountHolderFormGroup: UntypedFormGroup;
  accountHolderIdentificationTypes$: Observable<IdentificationType[]>;

  canConsultChildNhs = false;
  submitting = false;
  bigImage = false;

  CustomerType = CustomerType;

  constructor(
    private patientDetails: PatientDetailsService,
    private patientNav: PatientNavigationService,
    private api: ApiDoctorsConsultation,
    private consultationState: ConsultationStateService,
    private formBuilder: UntypedFormBuilder,
    private modal: ModalService,
    private system: SystemCheckService,
    private consultationFeatures: ConsultationFeatureService,
    private idCheckService: IdCheckService,
    private router: Router,
    private route: ActivatedRoute,
    private order: CurrentOrderIdService
  ) {}

  ngOnInit() {
    this.idBase64Image$ = this.idCheckService.getIdDocument$();
    this.accountHolderIdentificationTypes$ = this.idCheckService.accountHolderIdentificationTypes$;
    this.consultationState.state = ConsultationState.VERIFYING_IDENTITY;

    this.patient$ = combineLatest([
      this.patientDetails.details$,
      this.consultationFeatures.canConsultWithChildrenNHS$.pipe(catchError(() => of(false))),
    ]).pipe(
      map(([patient, canConsultChildNhs]) => {
        const consultationType =
          patient.Patient.CustomerType === CustomerType.Adult && canConsultChildNhs
            ? null
            : patient.Patient.CustomerType;
        this.accountHolderFormGroup = this.formBuilder.group({
          idTypeSelect: [null, [Validators.required]],
          consultationType: [consultationType, [Validators.required]],
        });
        this.canConsultChildNhs = canConsultChildNhs;
        return patient;
      })
    );

    this.logBrowser();
  }

  toggleVideoSize(bigImage: boolean) {
    this.bigImage = bigImage;
  }

  onSubmit({ idTypeSelect, consultationType }) {
    if (this.submitting) return;
    this.submitting = true;

    if (consultationType === CustomerType.Child) {
      this.continuetoChildIdCheck(idTypeSelect.identificationTypeId);
    } else {
      this.idCheckService
        .confirmPatient$(idTypeSelect.identificationTypeId)
        .pipe(
          catchError(err => {
            this.submitting = false;
            return this.modal.error(err.message).pipe(mergeMap(() => EMPTY));
          }),
          switchMap(() => this.patientDetails.alerts$)
        )
        .subscribe(
          alerts => this.exitIdChecks(alerts),
          err => {
            this.submitting = false;
            this.modal.error(err.message);
          }
        );
    }
  }

  continuetoChildIdCheck(identificationTypeId) {
    this.idCheckService
      .confirmPatient$(identificationTypeId)
      .pipe(
        catchError(err => {
          this.submitting = false;
          return this.modal.error(err.message).pipe(mergeMap(() => EMPTY));
        }),
        switchMap(() => this.modal.showCustom(ChildIdModalComponent))
      )
      .subscribe(confirmed => {
        this.submitting = false;
        if (confirmed) {
          const childIdCheckRoute = this.canConsultChildNhs
            ? '../child-id-check-nhs'
            : '../child-id-check';
          this.router.navigate([childIdCheckRoute], {
            relativeTo: this.route,
          });
        }
      });
  }

  exitIdChecks(alerts: PatientAlert[]) {
    const nextConsultationState =
      alerts.length > 0 ? ConsultationState.CHECKING_ALERTS : ConsultationState.CONSULTING;
    this.patientDetails.clearCache();
    this.patientNav.gotoConsultationHome(nextConsultationState);
  }

  private logBrowser() {
    this.api
      .logBrowserDetails(this.order.id, this.system.browser, this.system.OS, this.system.userAgent)
      .toPromise();
  }
}
