export class SmartCardData {
  constructor(data: SmartCardData) {
    Object.assign(this, data);
    this.rbac = new Rbac(data?.rbac);
  }

  rbac: Rbac;
  cardInfo: CardInfo;
  error: string;
}

export class Rbac {
  constructor(data: Rbac) {
    Object.assign(this, data);
    this.sessions = data?.sessions.map(x => new Session(x));
  }
  sessions?: Session[];
  uniqueUserId?: string;
  uniqueRoleProfileId?: string;
  errorType?: string;
  cardInfo: CardInfo;

  getSelectedSession() {
    return this.sessions?.filter(x => x.active)[0];
  }
}

export interface CardInfo {
  serialNumber: string;
  status: string;
  isSha256: boolean;
  sigAlgName: string;
}

export interface CardInfoResponse {
  currentEnvironment: string;
  cardInfo: CardInfo;
}

export interface CardToken {
  ticket: string;
  saml: string; // Later should be server side
  cardInfo: CardInfo;
  currentEnvironment: string;
}

export class Session {
  constructor(data: Session) {
    Object.assign(this, data);
  }
  sessionId: string;
  orgCode: string;
  nhsJobRoleCode: string;
  active: boolean;
  role: string;
  permissions: string[];

  hasPermission(permission: RbacPerms) {
    return !!this.permissions.find(f => f === permission);
  }
}

export interface SlotInfo {
  slotId: number;
  slotDescription: string;
  manufacturerId: string;
  slotFlags: {
    flags: number;
    tokenPresent: boolean;
    removableDevice: boolean;
    hardwareSlot: boolean;
  };
  hardwareVersion: string;
  firmwareVersion: string;
}

export interface SlotInfoResponse {
  slotInfo: SlotInfo;
  currentEnvironment: string;
}

export enum RbacPerms {
  prescriptionSign = 'prescription/sign',
  prescriptionCreate = 'prescription/create',
  prescriptionCancel = 'prescription/cancel',
  prescriptionView = 'prescription/view',
}

export enum OdsCodes {
  PushDr = 'DH6',
}

export enum CardStatuses {
  InvalidEnvironment = -1,
  NoStatusSet = 0,
  CertificateInFuture = 1,
  CertificateExpired = 2,
  SmartCardRevoked = 3,
  VpnNotConnected = 4,
  OK = 5,
  IdentityAgentNotRunning = 6,
  NoCardsFound = 7,
  PinLocked = 8,
  UnknownError = 9,
  IncorrectPin = 10,
  PinInvalid = 11,
  TokenNotPresent = 12,
  ServerNotRunning = 101,
  RbacServiceDown = 102,
}
