import { Component, OnInit } from '@angular/core';
import { AbstractModal } from '../abstract-modal';
import { take } from 'rxjs/operators';
import * as moment from 'moment';
import { CsvDownloadService } from '@pushdr/common/utils';
import { ErrorLog, ErrorLogsService } from '@pushdr/common/data-access/analytics';

@Component({
  selector: 'pushdr-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: [],
})
export class ErrorModalComponent extends AbstractModal<{ bottomText: string }> implements OnInit {
  logs: ErrorLog[] = [];

  constructor(private errorLogsService: ErrorLogsService, private csvService: CsvDownloadService) {
    super();
  }

  ngOnInit() {
    this.errorLogsService.logs$.pipe(take(1)).subscribe(logs => (this.logs = logs));
  }

  downloadLogs() {
    this.csvService.exportObjToCsv(
      `ERROR_LOGS:${moment().format('dd-MMM-YYYY_hh-mm-ss')}`,
      this.logs
    );
  }
}
