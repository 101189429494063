import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  RestClient,
  RestErrorParserService,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';

import { EnvironmentProxyService } from '@pushdr/environment';
import { DoctorNotePage } from './models';

@Injectable({
  providedIn: 'root',
})
export class DoctorNotesApiService extends RestClient {
  constructor(
    protected http: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParser: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(http, headerService, errorParser, proxy);
  }

  endpoint = () =>
    `${this.proxy.environment.doctors.api}/consultationnotes.svc/consultationnotesW/`;

  getDoctorNotes(customerId: number, page?: number, size?: number): Observable<DoctorNotePage> {
    const headers = this.headerService.authorisedHeadersLegacy;
    const body = { customerId, PageNumber: page, PageSize: size };
    return this.post('GetConsultationPDNotes', body, headers);
  }
}
