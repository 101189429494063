import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ClinicianSessionsService,
  CliniciansTermsCheckerService,
  ConsultationState,
} from '@pushdr/clinicians/common';
import {
  AnalyticsBusService,
  AnalyticsEvent,
  LogService,
} from '@pushdr/common/data-access/analytics';
import { ModalService } from '@pushdr/common/overlay';
import { StorageService } from '@pushdr/common/utils';
import {
  DoctorSession as ClinicianSession,
  DoctorSessionStatus as ClinicianSessionStatus,
  WhereShouldIBe,
} from '@pushdr/doctors/data-access/doctors-api';
import * as moment from 'moment';
import { Moment } from 'moment';
import { combineLatest, EMPTY, Observable, of, Subject, timer } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { PatientDetailsService } from '../../../../services/patient-details/patient-details.service';
import { EndSessionModalComponent } from '../../components/end-session-modal/end-session-modal.component';
import { GoodPracticeModalComponent } from '../../components/good-practice-modal/good-practice-modal.component';
import {
  ClinicianAwayService,
  ClinicianAwayState,
} from '../../services/clinician-away/clinician-away-service.service';
import { WaitingRoomService } from '../../services/waiting-room/waiting-room.service';
import { formattedDurationHHMMSS } from '../../utils/time-since-as-string';

export enum WaitingRoomState {
  PENDING,
  FOUND,
  IDLE,
  AWAY,
}

@Component({
  selector: 'pushdr-waiting-with-sessions-room',
  templateUrl: './waiting-room.component.html',
  styleUrls: ['./waiting-room.component.scss'],
})
export class WaitingRoomComponent implements OnInit, OnDestroy {
  patientReadyAudio: HTMLAudioElement;
  patientCount: number;
  clinicianState: ClinicianAwayState;
  clinicianStateStart: Moment;
  ngUnsubscribe$ = new Subject<void>();
  connectingToPatient = false;
  endSessionModalOpen = false;
  clinicianAwayState = ClinicianAwayState;
  timerText$: Observable<string>;
  longAway = false;
  activeSession: ClinicianSession;

  private readonly maxLongAway = 10 * 60 * 1000;
  private readonly pollingBookingStatusMs = 5000;
  private readonly pollingSessionStatusMs = 14000;
  private readonly timerViewUpdateFrequency = 1000;

  constructor(
    private waitingRoomService: WaitingRoomService,
    private sessions: ClinicianSessionsService,
    private clinicianIdleTimeoutService: ClinicianAwayService,
    private patientDetails: PatientDetailsService,
    private termsChecker: CliniciansTermsCheckerService,
    private storage: StorageService,
    private modal: ModalService,
    private router: Router,
    private route: ActivatedRoute,
    private logger: LogService,
    private msgBus: AnalyticsBusService
  ) {}

  ngOnInit() {
    this.uploadUnsentLogs();
    this.clinicianState = null;
    this.clinicianStateStart = null;
    this.patientCount = null;
    this.patientReadyAudio = new Audio('assets/sounds/youve-been-informed.mp3');
    this.watchPatientCount();
    this.watchCliniciansStatus();
    this.watchActiveSessions();
    this.sendClinicianWhereTheyShouldBe(false);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  watchActiveSessions() {
    this.sessions.pollSessionsUntil(
      this.ngUnsubscribe$,
      this.pollingSessionStatusMs,
      moment().startOf('day'),
      moment().endOf('day')
    );

    combineLatest([this.sessions.activeSession$(), this.route.queryParams])
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(data => {
        const [session, params] = data;

        if (!session) {
          this.goToWelcome();
          return;
        }

        this.activeSession = session;

        if (!params.supressEndSessionModal && this.activeSessionShouldEnd()) {
          this.showEndActiveSessionModal();
        }

        if (session.status === ClinicianSessionStatus.ON_BREAK) {
          this.msgBus.trackEvent(
            AnalyticsEvent.info(
              `waiting-room.watch-active-sessions.auto-away`,
              'Setting to away as session is already on a break'
            )
          );
          this.away(session.events[session.events.length - 1].eventTime, false);
        }
      });
  }

  uploadUnsentLogs() {
    this.logger.uploadLogStore().subscribe(() => {
      console.log('Logs sent...');
    });
  }

  get clinicianIsAway(): boolean {
    return [ClinicianAwayState.AWAY_IDLE, ClinicianAwayState.AWAY_MANUAL].includes(
      this.clinicianState
    );
  }

  sendClinicianWhereTheyShouldBe(peekPatient = true) {
    this.connectingToPatient = true;
    this.waitingRoomService
      .getWhereIShouldBe()
      .pipe(
        switchMap(status => {
          switch (status) {
            case WhereShouldIBe.GETTING_NEXT_PATIENT:
              if (peekPatient) {
                return this.patientConfirmedGoodPractice().pipe(
                  switchMap(() => this.getNextPatientConfig()),
                  map(() => ConsultationState.MEET_PATIENT)
                );
              } else {
                return EMPTY;
              }
            case WhereShouldIBe.IN_CONSULTATION:
            case WhereShouldIBe.ALREADY_IN_CONSULTATION:
              return this.modal
                .confirm(
                  'Resume consultation',
                  'Your patient is still in consultation, would you like to resume the consultation',
                  'Goto consultation',
                  'Stay here'
                )
                .pipe(map(confirmed => (confirmed ? ConsultationState.VERIFYING_IDENTITY : EMPTY)));

            case WhereShouldIBe.ACCEPTING_NEW_TERMS:
              this.termsChecker.check();
              return EMPTY;

            default:
              this.msgBus.trackEvent(
                AnalyticsEvent.info(`waiting-room.whereShouldIBe`, 'Where should I be: ' + status)
              );
              this.modal.error(
                'Please get in contact with the support team. It looks like there is an issue preventing you from connecting with patients.'
              );
              return EMPTY;
          }
        }),
        take(1)
      )
      .subscribe(
        (state: ConsultationState) => {
          this.modal.close('session');
          if (state === ConsultationState.MEET_PATIENT) {
            this.router.navigate(['../../meet-patient'], { relativeTo: this.route });
          } else if (state === ConsultationState.VERIFYING_IDENTITY) {
            this.router.navigate(['../../patient/id/check'], { relativeTo: this.route });
          }
        },
        error => {
          this.modal.error(error.message);
        },
        () => {
          this.unAway();
          this.connectingToPatient = false;
        }
      );
  }

  getNextPatientConfig() {
    this.patientDetails.clearCache();
    return this.patientDetails.config$;
  }

  away(from = moment(), emit = true) {
    if (!this.clinicianIsAway) {
      this.clinicianState = ClinicianAwayState.AWAY_MANUAL;
      this.clinicianIdleTimeoutService.setAway(from);
      if (emit) {
        this.sessions.startBreak(this.activeSession.id);
      }
    }
  }

  unAway() {
    if (this.clinicianIsAway) {
      this.clinicianState = ClinicianAwayState.ACTIVE;
      this.clinicianIdleTimeoutService.setActive();
      this.sessions.endBreak(this.activeSession.id);
    }
  }

  patientConfirmedGoodPractice(): Observable<boolean> {
    if (!this.checkIfConfirmedGoodPractice()) {
      return this.modal.showCustom(GoodPracticeModalComponent).pipe(
        tap(confirmed => confirmed && this.storage.setSessionStorage('confirmedGoodPractice', 1)),
        mergeMap(confirmed =>
          confirmed
            ? of(confirmed)
            : this.modal
                .error(
                  'Cannot consult unless you agree to our good practice policy, we are sending you back to the welcome page'
                )
                .pipe(tap(() => this.goToWelcome()))
        )
      );
    } else {
      return of(true);
    }
  }

  playNewPatientSound() {
    this.patientReadyAudio.play();
  }

  activeSessionShouldEnd() {
    if (!this.activeSession) {
      return false;
    }
    return this.sessions.isLateToEnd(this.activeSession);
  }

  endActiveSession() {
    this.sessions.endSession(this.activeSession.id);
    this.clinicianIdleTimeoutService.setUnavailable();
  }

  private goToWelcome() {
    this.router.navigate(['../welcome'], { relativeTo: this.route, queryParams: { thankyou: 1 } });
  }

  private showEndActiveSessionModal() {
    if (this.endSessionModalOpen) return;
    this.endSessionModalOpen = true;
    this.modal.showCustom(EndSessionModalComponent, null, 'session').subscribe(shouldEnd => {
      this.endSessionModalOpen = false;
      if (shouldEnd) {
        this.endActiveSession();
      } else {
        this.router.navigate([], {
          queryParams: { supressEndSessionModal: 1 },
          relativeTo: this.route,
        });
      }
    });
  }

  private watchPatientCount() {
    timer(0, this.pollingBookingStatusMs)
      .pipe(
        mergeMap(() => this.waitingRoomService.getNumberOfPatientsWaiting()),
        takeUntil(this.ngUnsubscribe$),
        catchError(err =>
          this.modal
            .error('An unexpected error has occured, we are sending you back to the welcome page')
            .pipe(tap(() => this.goToWelcome()))
        )
      )
      .subscribe(count => {
        if (!this.patientCount && count !== 0 && !this.clinicianIsAway) {
          this.msgBus.trackEvent(
            AnalyticsEvent.info('waiting-room.next-patient-count', 'Next patient count: ' + count)
          );
          this.msgBus.trackEvent(
            AnalyticsEvent.info(
              'waiting-room.known-patient-count',
              'Known patient count: ' + this.patientCount
            )
          );
          this.clinicianIdleTimeoutService.startTimer();
          this.playNewPatientSound();
        } else if (this.patientCount && count === 0) {
          this.msgBus.trackEvent(
            AnalyticsEvent.info('waiting-room.next-patient-count', 'Next patient count: ' + count)
          );
          this.msgBus.trackEvent(
            AnalyticsEvent.info(
              'waiting-room.known-patient-count',
              'Known patient count: ' + this.patientCount
            )
          );
          this.clinicianIdleTimeoutService.stopTimer();
        }

        this.patientCount = count;
      });
  }

  private watchCliniciansStatus() {
    this.clinicianIdleTimeoutService.initAwayState();
    this.clinicianIdleTimeoutService.clinicianAwayStatus$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(status => {
        this.clinicianState = status.state;
        this.clinicianStateStart = status.start;
        if (this.activeSession && this.clinicianState === ClinicianAwayState.AWAY_IDLE) {
          this.sessions.startBreak(this.activeSession.id);
        }
      });

    this.timerText$ = timer(0, this.timerViewUpdateFrequency).pipe(
      tap(
        () =>
          (this.longAway =
            this.maxLongAway < moment().diff(this.clinicianStateStart, 'milliseconds'))
      ),
      map(() => formattedDurationHHMMSS(this.clinicianStateStart))
    );
  }

  private checkIfConfirmedGoodPractice() {
    const goodPractice = parseInt(this.storage.getSessionStorage('confirmedGoodPractice'), 10);
    return !!goodPractice;
  }
}
