import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import {
  PharmaceuticalEquivalent,
  PharmaceuticalsMedicationDetails,
} from '@pushdr/prescription/api';

@Component({
  selector: 'pushdr-equivalents-select',
  templateUrl: './equivalents-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquivalentsSelectComponent implements OnInit {
  @Input() genericAllowed: boolean;
  @Input() medication: PharmaceuticalsMedicationDetails;
  @Input() equivalents: PharmaceuticalEquivalent[];
  @Output() selectedEquivalent = new EventEmitter<PharmaceuticalEquivalent>();

  eqControl: UntypedFormControl;
  medications: PharmaceuticalEquivalent[] = [];
  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit() {
    this.eqControl = this.fb.control(null);
    if (this.genericAllowed) {
      this.medications = [
        {
          isBrand: false,
          medicationName: `${this.medication.medicationName} (generic)`,
          snomedCode: this.medication.snomedCode,
        },
      ];
    }
    if (this.equivalents) {
      this.medications = [...this.medications, ...this.equivalents];
    }
  }

  onChange(drugEquivalent: PharmaceuticalEquivalent) {
    this.selectedEquivalent.emit(drugEquivalent);
  }

  dummySearch() {
    return true;
  }
}
