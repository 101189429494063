import {
  Component,
  EventEmitter,
  Input,
  forwardRef,
  OnInit,
  Output,
  ViewChild,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, map, retry, startWith, switchMap, tap } from 'rxjs/operators';
import { PharmaceuticalsApiService, PharmaceuticalsMedication } from '@pushdr/prescription/api';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ModalService } from '@pushdr/common/overlay';

const DEFAULT_VALUE = null;
const DEFAULT_VALUE_CLEAR_ALL_TEXT = 'x';
const DEFAULT_VALUE_NO_MATCHES_TEXT = 'No matching medications';
@Component({
  selector: 'pushdr-fdb-search-medications',
  templateUrl: './fdb-search-medications.component.html',
  styleUrls: ['./fdb-search-medications.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FdbSearchMedicationsComponent),
      multi: true,
    },
  ],
})
export class FdbSearchMedicationsComponent
  implements ControlValueAccessor, OnInit, OnDestroy, AfterViewInit
{
  medications$: Observable<PharmaceuticalsMedication[]>;

  @Input() control = new FormControl(DEFAULT_VALUE, [Validators.required]);
  @Input() clearAllText = DEFAULT_VALUE_CLEAR_ALL_TEXT;
  @Input() noMatchesText = DEFAULT_VALUE_NO_MATCHES_TEXT;
  @Input() validationError = false;
  @Output() medicationChange = new EventEmitter<PharmaceuticalsMedication>();
  @Output() focusEvent = new EventEmitter<null>();

  customNoMatchTextApplied$ = new BehaviorSubject<boolean>(false);
  customClearTextApplied$ = new BehaviorSubject<boolean>(false);
  controlValueChangeSubscription: Subscription = null;
  isLoading = false;
  hasError = false;

  @ViewChild('ngSelect', { static: false }) ngSelect: NgSelectComponent;

  get searchTerm$() {
    return this._searchTerm$.asObservable();
  }
  private _searchTerm$ = new Subject<string>();

  constructor(private modal: ModalService, private pharmAPI: PharmaceuticalsApiService) {}

  ngOnInit() {
    this.medications$ = this.searchTerm$.pipe(
      tap(() => {
        this.isLoading = true;
        this.control.setValue(null);
        this.medicationChange.emit(null);
      }),
      debounceTime(500),
      switchMap((searchTerm: string) => {
        if (searchTerm && searchTerm.length > 2) {
          return this.pharmAPI
            .getPharmaceuticalsBySearchTerm(searchTerm)
            .pipe(map((searchResult: any) => searchResult && searchResult.pharmaceuticals));
        } else {
          return of([]);
        }
      }),
      tap({
        next: () => {
          this.isLoading = false;
          this.hasError = false;
        },
        error: err => {
          this.modal.error(
            'Medication search has failed, please continue prescription in either EMIS/TPP system.'
          );
          this.isLoading = false;
          this.hasError = true;
        },
      }),
      startWith([]),
      retry()
    );
    if (this.noMatchesText !== DEFAULT_VALUE_NO_MATCHES_TEXT) {
      this.customNoMatchTextApplied$.next(true);
    }
  }

  ngAfterViewInit() {
    if (this.clearAllText !== DEFAULT_VALUE_CLEAR_ALL_TEXT) {
      this.controlValueChangeSubscription = this.control.valueChanges.subscribe(value => {
        const el = this.ngSelect.element.querySelector('.ng-clear');
        this.customClearTextApplied$.next(!!value);
        if (el) {
          el.innerHTML = value ? this.clearAllText : DEFAULT_VALUE_CLEAR_ALL_TEXT;
        }
      });
    }
  }

  onTouched() {
    this.focusEvent.emit();
  }

  registerOnChange(fn: (val: any) => void) {
    this.control.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = () => {
      this.focusEvent.emit();
      fn();
    };
  }

  writeValue(val: PharmaceuticalsMedication) {
    if (val) {
      this.control.setValue(val);
    }
  }

  changeSearchTerm(term: string) {
    this._searchTerm$.next(term);
  }

  onChange(value: PharmaceuticalsMedication) {
    this.medicationChange.emit(value);
  }

  dummySearch() {
    return true;
  }

  ngOnDestroy() {
    if (this.controlValueChangeSubscription) {
      this.controlValueChangeSubscription.unsubscribe();
    }
  }

  setPos(event: MouseEvent) {
    const el = event.target as HTMLElement;
    const yOffset = el.getBoundingClientRect().y;
    const nextEl = el.nextSibling as HTMLElement;
    nextEl.style.top = `${yOffset + 25}px`;
  }
}
