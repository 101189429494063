export const DURATION1 = 100;
export const DURATION2 = 200;
export const DURATION3 = 300;
export const DURATION4 = 400;
export const DURATION5 = 500;
export const DURATION6 = 600;
export const DURATION7 = 700;
export const DURATION8 = 800;
export const DURATION9 = 900;
export const DURATION10 = 1000;

export const DELAY1 = 100;
export const DELAY2 = 200;
export const DELAY3 = 300;
export const DELAY4 = 400;
export const DELAY5 = 500;
export const DELAY6 = 600;
export const DELAY7 = 700;
export const DELAY8 = 800;
export const DELAY9 = 900;
export const DELAY10 = 1000;
