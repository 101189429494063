<div class="c-modal__wrap">
  <h3 class="mb-8">{{ title }}</h3>
  <p>
    {{ message }}
  </p>

  <div class="flex-row justify-center sm:block md:flex">
    <button
      class="p-4 pl-6 pr-6 bg-white border border-solid rounded hover:bg-grey-100 border-primary text-primary md:mr-2"
      (click)="done(false)">
      Try again
    </button>
    <button
      class="p-4 pl-6 pr-6 text-white rounded bg-error hover:bg-red-300 md:ml-2"
      (click)="done(true)">
      Ignore and continue
    </button>
  </div>
</div>
