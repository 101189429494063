<pushdr-consultation-card class="alert-card" [hasData]="true">
  <span header> Patient alerts </span>
  <ng-container body>
    <div *ngFor="let alert of alerts$ | async" class="rounded text-error bg-white p-4 text-sm">
      {{ alert.message }}
    </div>
    <section class="flex flex-row items-center mt-8">
      <label
        class="confirm-label bg-white text-body-text p-3 rounded text-sm border border-red-500 border-solid">
        <input [formControl]="readCheckboxControl" type="checkbox" name="confirm" />
        I confirm i have read these alerts
      </label>
      <button
        (click)="onConfirmTerms()"
        class="p-3 ml-4 rounded bg-primary hover:bg-primary-hover text-white text-sm font-normal"
        [ngClass]="{
          'cursor-not-allowed bg-primary-disabled hover:bg-primary-disabled':
            !readCheckboxControl.value
        }"
        type="submit">
        Submit
      </button>
    </section>
  </ng-container>
</pushdr-consultation-card>
