import { Component, OnInit } from '@angular/core';
import { GPResource, resources$ } from './gp-resources-card-resources';
import { Observable } from 'rxjs';

@Component({
  selector: 'pushdr-gp-resources-card',
  templateUrl: './gp-resources-card.component.html',
  styleUrls: ['./gp-resources-card.component.scss'],
})
export class GpResourcesCardComponent implements OnInit {
  resources$: Observable<GPResource[]>;

  constructor() {}

  ngOnInit() {
    this.resources$ = resources$;
  }
}
