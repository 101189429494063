import { Component, EventEmitter, Input, Output } from '@angular/core';

export enum AutoSaveState {
  Saving = 'saving',
  Saved = 'saved',
  Error = 'error',
}

@Component({
  selector: 'pushdr-auto-save-status',
  templateUrl: './auto-save-status.component.html',
  styleUrls: ['./auto-save-status.component.scss'],
})
export class AutoSaveStatusComponent {
  readonly AutoSaveState = AutoSaveState;

  @Input() state: AutoSaveState = null;
  @Output() pdrRetry = new EventEmitter<void>();

  retry(): void {
    this.pdrRetry.emit();
  }
}
