import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { changes$, ChangeLog } from './whats-new-card.changelog';
import { Observable } from 'rxjs';
import { Moment } from 'moment';
import * as moment from 'moment';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pushdr-whats-new-card',
  templateUrl: './whats-new-card.component.html',
  styleUrls: ['./whats-new-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhatsNewCardComponent implements OnInit {
  changelog$: Observable<ChangeLog[]>;

  ngOnInit() {
    this.getChanges();
  }

  isRecent(m: Moment) {
    return m.isAfter(moment().subtract(7, 'days'));
  }

  private getChanges() {
    // TODO: replace with something dynamic
    this.changelog$ = changes$.pipe(
      map(items => {
        return items.sort((a, b) => b.date.toDate().getTime() - a.date.toDate().getTime());
      })
    );
  }
}
