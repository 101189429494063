import { Component } from '@angular/core';
import { fadeInOnEnterAnimation } from 'angular-animations';
import { Router } from '@angular/router';
import {
  AbstractControl,
  UntypedFormBuilder,
  Validators,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { ApiDoctorsGeneral } from '@pushdr/doctors/data-access/doctors-api';
import { finalize, switchMap } from 'rxjs/operators';
import { ModalService } from '@pushdr/common/overlay';

@Component({
  selector: 'pushdr-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  animations: [fadeInOnEnterAnimation({ anchor: 'showContent' })],
})
export class ForgotPasswordComponent {
  resetPasswordForm = this.fb.group({
    email: ['', Validators.compose([Validators.required, Validators.email])],
  });
  isLoading = false;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private api: ApiDoctorsGeneral,
    private modal: ModalService
  ) {}

  submit() {
    this.resetPasswordForm.markAllAsTouched();
    if (this.resetPasswordForm.valid) {
      const email = this.resetPasswordForm.value.email;
      this.submitPasswordReset(email).subscribe({
        next: () => this.router.navigate(['/auth']),
        error: () => this.setInvalidAccountValidation(email),
      });
    }
  }

  private submitPasswordReset(email: string) {
    this.isLoading = true;
    return this.api.resetPassword(email).pipe(
      finalize(() => (this.isLoading = false)),
      switchMap(() => this.showEmailSentModal())
    );
  }

  private showEmailSentModal() {
    return this.modal.acknowledge(
      'Please check your email account',
      'An email has been sent to your registered email address with the temporary password. Please check your email to proceed further.'
    );
  }

  private setInvalidAccountValidation(email: string) {
    const invalidAccount = invalidAccountValidatorFactory(email);
    const emailControl = this.resetPasswordForm.get('email');
    emailControl.setValidators([invalidAccount, Validators.required, Validators.email]);
    emailControl.updateValueAndValidity();
  }
}

function invalidAccountValidatorFactory(email: string): ValidatorFn {
  return (control?: AbstractControl): ValidationErrors | null => {
    return control.value === email ? { invalidAccount: true } : null;
  };
}
