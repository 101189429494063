import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EndConsultationPlaceEnum } from '../../../../services/end-consultation/end-consultation.service';

@Component({
  selector: 'pushdr-consultation-footer',
  templateUrl: './consultation-footer.component.html',
  styleUrls: ['./consultation-footer.component.scss'],
})
export class ConsultationFooterComponent {
  EndConsultationPlace = EndConsultationPlaceEnum;

  @Output() patientEmergency = new EventEmitter();
  @Output() safeGuarding = new EventEmitter();

  @Input() hideEmergency = false;
  @Input() endConsultationText = 'End consultation';
}
