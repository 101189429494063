import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  ConsultationState,
  ConsultationStateService,
  StateCustomisation,
} from '@pushdr/clinicians/common';
import { AnalyticsBusService } from '@pushdr/common/data-access/analytics';
import { ModalService } from '@pushdr/common/overlay';
import { CanComponentDeactivate } from '@pushdr/common/utils';
import { PrescriptionService } from '@pushdr/prescription/shell';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { filter, map, mergeMap, startWith, switchMap, take, takeUntil } from 'rxjs/operators';
import { ConsultationFeatureService } from '../../services/consultation-feature/consultation-feature.service';
import { CliniciansEndConsultationService } from '../../services/end-consultation/end-consultation.service';
import { PatientDetailsService } from '../../services/patient-details/patient-details.service';
import { PatientIsDeadModalComponent } from './components/patient-is-dead-modal/patient-is-dead-modal.component';
import {
  ConsultationLayoutService,
  PanelIds,
  PatientGridLayout,
} from './services/consultation-layout/consultation-layout.service';
import { PatientConsultationService } from './services/patient-consultation/patient-consultation.service';
import { PatientNavigationService } from './services/patient-navigation/patient-navigation.service';
import { PatientRecordsService } from './services/patient-records/patient-records.service';

@Component({
  selector: 'pushdr-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss'],
})
export class PatientComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  gridLayoutCssClass = '';
  PatientGridLayout = PatientGridLayout;
  private ngUnsubscribe$ = new Subject<void>();
  customisations$: Observable<StateCustomisation>;
  largeMainPanel$: Observable<string>;
  panelIds = [PanelIds.INFO1, PanelIds.INFO2, PanelIds.INFO3, PanelIds.INFO4];
  isConsultationStarted = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private consultation: PatientConsultationService,
    private consultationState: ConsultationStateService,
    private patientNav: PatientNavigationService,
    private patient: PatientDetailsService,
    private record: PatientRecordsService,
    private prescription: PrescriptionService,
    private endConsultationService: CliniciansEndConsultationService,
    private msgBus: AnalyticsBusService,
    private layoutService: ConsultationLayoutService,
    private modalService: ModalService,
    private consultationFeatures: ConsultationFeatureService
  ) {}

  ngOnInit() {
    this.mapRouteToLayout();
    this.record.initialise();
    this.startConsultation();

    this.customisations$ = this.consultationState.getStateCustomisations$();
    this.largeMainPanel$ = this.layoutService.layoutVariation$.asObservable();

    const showReadRecordsErrorModal = () =>
      this.modalService.acknowledge(
        'Data error with patient records',
        'Unfortunately our system experienced an error when returning this patient’s NHS medical records. Please use the provider system to consult instead.'
      );

    const canSeeRecordsModals$ = this.consultationFeatures.canSeeRecordsAll$.pipe(
      switchMap(canSeeRecords => (canSeeRecords ? of(null) : showReadRecordsErrorModal()))
    );

    this.consultationFeatures.hasAccessToRecords$
      .pipe(
        switchMap(hasAccess => (hasAccess ? canSeeRecordsModals$ : of(null))),
        take(1)
      )
      .subscribe();

    this.consultationFeatures.prescribePermsIssue$
      .pipe(
        take(1),
        switchMap(issues => {
          if (issues.canPrescribe) {
            return of(null);
          }

          if (issues.sensitive) {
            this.modalService.acknowledge(
              'Sensitive Patient',
              `EPS has been disabled because this patient is marked as a 'sensitive patient'.
                Please send an urgent task outlining the medication that you are recommending,
                including the strength, form and dosing instructions.
                Note that out of hours, this may cause a delay to the patient receiving their
                prescription.`,
              'Okay',
              'Sensitive'
            );
          } else if (issues.gpOdsMismatch) {
            this.modalService.acknowledge(
              'Practice Inconsistency',
              `Due to an inconsistency in locating where this patient’s medical record is stored,
                we have unfortunately had to disable our prescribing function for safety reasons.
                Please apologise to the patient and ask them to contact our customer support to
                discuss this matter further.`,
              'Okay',
              'OdsMismatch'
            );
          } else if (issues.dead) {
            this.modalService.showCustom(PatientIsDeadModalComponent);
          } else if (
            !issues.featureDisabled &&
            !issues.notNhs &&
            !issues.noEps &&
            !issues.canPrescribe
          ) {
            this.modalService.acknowledge(
              'Permissions Issue',
              'Sorry, you do not have the required prescribe permissions to issue prescriptions for this patient.',
              'Okay',
              'Permission'
            );
          }
          return of(issues);
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.consultation.destroy();
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  canDeactivate() {
    return this.consultationState.state === ConsultationState.NOT_CONSULTING;
  }

  getCss$ = () =>
    combineLatest(this.traverseRoute(this.route)).pipe(
      map(x => x.filter(f => f['cssClass']).map(f => f['cssClass'])),
      map(x => (x.length ? x.pop() : null))
    );

  mapRouteToLayout() {
    this.router.events
      .pipe(
        startWith(new NavigationEnd(0, '', this.router.url)),
        filter(evt => evt instanceof NavigationEnd),
        takeUntil(this.ngUnsubscribe$),
        mergeMap((navEndEvent: NavigationEnd) =>
          this.layoutService.layoutInfo$().pipe(
            map(layout => ({
              layout,
              navEndEvent,
            }))
          )
        ),
        switchMap(lay => this.getCss$().pipe(map(c => ({ ...lay, cssClass: c }))))
      )
      .subscribe(res => {
        const { layout, navEndEvent, cssClass } = res;
        this.gridLayoutCssClass = this.routeToLayoutMap(
          layout,
          navEndEvent.urlAfterRedirects,
          cssClass
        );
      });
  }

  private async startConsultation() {
    const details = await this.patient.details$.toPromise();
    if (details.ConsultationConfig.TranslatorCallStatus?.Status) {
      this.consultation.setTranslatorStatus(details.ConsultationConfig.TranslatorCallStatus.Status);
    }
    const encryptionHash =
      details.Patient.Name + details.Patient.Email + details.Patient.DateOfBirth;
    this.consultation.start(details.Patient.Id.toString(), encryptionHash);
    this.isConsultationStarted = true;
    this.prescription.initialize();
  }

  onPatientEmergency() {
    this.patientNav.gotoPatientEmergency();
  }

  onSafeguarding() {
    this.patientNav.gotoPatientSafeGuarding();
  }

  routeToLayoutMap(grid = PatientGridLayout.DEFAULT, path = '', cssClass: string = '') {
    switch (true) {
      case this.matchesPath(path, '/patient/id'):
        return PatientGridLayout.DEFAULT;
      case grid === PatientGridLayout.GPC_STRUCTURED &&
        this.matchesPath(path, '/patient/consult/default'):
        return PatientGridLayout.DEFAULT;
      case grid === PatientGridLayout.DEFAULT && this.matchesPath(path, '/patient/consult/default'):
        return PatientGridLayout.DEFAULT;
      default:
        return cssClass ? `${grid} ${cssClass}` : grid;
    }
  }

  private matchesPath(nextPath: string, partial: string) {
    return nextPath.indexOf(partial) !== -1;
  }

  private traverseRoute(routeP) {
    const allData$ = [];
    while (routeP.firstChild) {
      routeP = routeP.firstChild;
      if (routeP.data) allData$.push(routeP.data);
    }
    return allData$;
  }
}
