import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  RestClient,
  RestErrorParserService,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';
import { EnvironmentProxyService } from '@pushdr/environment';
import { map } from 'rxjs/operators';
import { DoctorClaim } from '@pushdr/common/types';
import { Observable } from 'rxjs';

interface LoginCheckResponse {
  LoginCheckResult: string;
  DoctorClaim: DoctorClaim;
  PasswordResetNeeded: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ApiDoctorsGeneral extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.doctors.api + '/general.svc/generalW/';
  }

  sendLoginValidationCode(strEmail: string, strPassword: string) {
    return this.post(
      'SendLoginValidationCode',
      { strEmail, strPassword },
      this.headerService.authorisedHeadersLegacy
    ).pipe(map(result => result.SendLoginValidationCodeResult === 'OK'));
  }

  loginCheck(strEmail: string, strPassword: string, strVerificationCode: string) {
    return this.post(
      'LoginCheck',
      { strEmail, strPassword, strVerificationCode },
      this.headerService.authorisedHeadersLegacy
    ).pipe(
      map((result: LoginCheckResponse) => {
        if (result.LoginCheckResult.indexOf('token') !== -1) {
          return {
            ...result,
            LoginCheckResult: result.LoginCheckResult.replace('token=', ''),
          };
        }
      })
    );
  }

  resetPassword(email: string): Observable<void> {
    return this.post('ResetDoctor', { Value: email });
  }
}
