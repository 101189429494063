import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ConsultationHistoryService,
  HistoricConsultationExtended,
} from '../../../../services/consultation-history/consultation-history.service';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { HistoricConsultationNotes } from '@pushdr/doctors/data-access/doctors-api';

@Component({
  selector: 'pushdr-notes-viewer',
  templateUrl: './notes-viewer.component.html',
  styleUrls: ['./notes-viewer.component.scss'],
})
export class NotesViewerComponent implements OnInit {
  consultation$: Observable<HistoricConsultationExtended<HistoricConsultationNotes>>;
  showReport = false;

  constructor(
    private consultationHistoryService: ConsultationHistoryService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.consultation$ = this.route.params.pipe(
      map(params => params['id']),
      switchMap(id =>
        this.consultationHistoryService.getHistoricConsultation$<HistoricConsultationNotes>(+id)
      )
    );
  }
}
