import { Injectable, Type } from '@angular/core';

import {
  ConsultationVisibilityService,
  VisibilityItem,
} from '../../../../services/consultation-visibility.service';

import { AcuteMedicationsComponent } from '../../components/acute-medications/acute-medications.component';
import { AllergiesComponent } from '../../components/allergies/allergies.component';
import { GpResourcesCardComponent } from '../../components/gp-resources-card/gp-resources-card.component';
import { PatientReceivedImagesComponent } from '../../components/received-images/received-images.component';
import { RepeatMedicationsComponent } from '../../components/repeat-medications/repeat-medications.component';
import { PanelIds, PatientGridLayout } from './consultation-layout.service';

export interface CardItemDefault {
  layout: PatientGridLayout;
  panelId: string;
}

export interface CardItem extends VisibilityItem {
  component: Type<unknown>;
  title: string;
  defaultFor: CardItemDefault[];
}

@Injectable({
  providedIn: 'root',
})
export class ConsultationCardService {
  readonly cards$ = this.visibilityService.getVisibleItemsFrom(cardItems);

  constructor(private visibilityService: ConsultationVisibilityService) {}

  findDefaultCard(panelId: string, layout: PatientGridLayout): CardItem {
    const hasDefault = (hasPanel: boolean) => (item: CardItemDefault) =>
      item.layout === layout && (!hasPanel || item.panelId === panelId);
    const findDefault = (hasPanel: boolean) => (card: CardItem) =>
      card.defaultFor && card.defaultFor.some(hasDefault(hasPanel));
    return cardItems.find(findDefault(true)) || cardItems.find(findDefault(false));
  }

  findCardByComponent(component: Type<unknown>): CardItem {
    return cardItems.find(card => card.component === component);
  }
}

const cardItems: CardItem[] = [
  {
    component: AllergiesComponent,
    title: 'Allergies',
    defaultFor: [{ layout: PatientGridLayout.GPC_STRUCTURED, panelId: PanelIds.INFO2 }],
    visibility: { struct: true },
  },
  {
    component: GpResourcesCardComponent,
    title: 'GP Resources',
    defaultFor: [
      { layout: PatientGridLayout.PDR_EDIT, panelId: PanelIds.INFO1 },
      { layout: PatientGridLayout.PDR, panelId: PanelIds.INFO1 },
      { layout: PatientGridLayout.GPC_UNSTRUCTURED, panelId: PanelIds.INFO1 },
      { layout: PatientGridLayout.GPC_STRUCTURED, panelId: PanelIds.INFO4 },
    ],
  },
  {
    component: PatientReceivedImagesComponent,
    title: 'Images Received',
    defaultFor: [
      { layout: PatientGridLayout.PDR, panelId: PanelIds.INFO3 },
      { layout: PatientGridLayout.PDR_EDIT, panelId: PanelIds.INFO3 },
      { layout: PatientGridLayout.GPC_UNSTRUCTURED, panelId: PanelIds.INFO2 },
    ],
  },
  {
    component: AcuteMedicationsComponent,
    title: 'Acute Medications',
    defaultFor: [{ layout: PatientGridLayout.GPC_STRUCTURED, panelId: PanelIds.INFO1 }],
    visibility: { struct: true },
  },
  {
    component: RepeatMedicationsComponent,
    title: 'Repeat Medications',
    defaultFor: [{ layout: PatientGridLayout.GPC_STRUCTURED, panelId: PanelIds.INFO3 }],
    visibility: { struct: true },
  },
];
