import { Component, OnInit } from '@angular/core';
import { DURATION3 } from '@pushdr/clinicians/common';
import { PharmaceuticalsApiService } from '@pushdr/prescription/api';
import { PatientStructuredRecords, SnomedData } from '@pushdr/common/types';
import {
  slideInLeftOnEnterAnimation,
  slideInRightOnEnterAnimation,
  slideOutLeftOnLeaveAnimation,
  slideOutRightOnLeaveAnimation,
} from 'angular-animations';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { PatientRecordsService } from '../../services/patient-records/patient-records.service';

@Component({
  selector: 'pushdr-allergies',
  templateUrl: './allergies.component.html',
  styleUrls: ['./allergies.component.scss'],
  animations: [
    slideInLeftOnEnterAnimation({ duration: DURATION3, anchor: 'slideInLeft' }),
    slideOutLeftOnLeaveAnimation({ duration: DURATION3, anchor: 'slideOutLeft' }),
    slideInRightOnEnterAnimation({ duration: DURATION3, anchor: 'slideInRight' }),
    slideOutRightOnLeaveAnimation({ duration: DURATION3, anchor: 'slideOutRight' }),
  ],
})
export class AllergiesComponent implements OnInit {
  activeAllergies$: Observable<any[]>;
  allergiesWarnings$: Observable<SnomedData[]>;
  patientWarning$: Observable<string>;
  selectedAllergy: any;

  constructor(
    private patientRecordsService: PatientRecordsService,
    private pharmaAPI: PharmaceuticalsApiService
  ) {}

  ngOnInit() {
    this.patientWarning$ = this.patientRecordsService
      .getStructuredPatientRecord()
      .pipe(map((patientRecord: PatientStructuredRecords) => patientRecord.patientWarning));

    this.allergiesWarnings$ = this.patientRecordsService
      .getStructuredPatientRecord()
      .pipe(
        switchMap((patientRecord: PatientStructuredRecords) =>
          this.pharmaAPI.getClinicialDecisionSupportWarningMatches(patientRecord.snomedAllergies)
        )
      );

    this.activeAllergies$ = this.patientRecordsService.getStructuredPatientRecord().pipe(
      map((patientRecord: PatientStructuredRecords) =>
        this.sortByMostRecent(patientRecord.allergies)
      ),
      map(allergies => this.filterByClinicalStatus(allergies, 'active'))
    );
  }

  private sortByMostRecent(allergies) {
    return allergies.sort((a, b) =>
      moment(this.getAllergyOnsetDateTime(a)).diff(moment(this.getAllergyOnsetDateTime(b)))
    );
  }

  private filterByClinicalStatus(allergies: any[], status: 'active' | 'resolved' | 'inactive') {
    return allergies.filter(allergy => allergy.clinicalStatus === status);
  }

  private getAllergyOnsetDateTime(allergy) {
    return allergy.onsetDateTime || (allergy.reaction ? allergy.reaction[0].onset : '');
  }
}
