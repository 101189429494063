import { Component, OnInit, OnDestroy } from '@angular/core';
import { ClinicianSessionsService } from '@pushdr/clinicians/common';
import { DoctorSession as ClinicianSession } from '@pushdr/doctors/data-access/doctors-api';
import { Moment } from 'moment';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pushdr-session-schedule-card',
  templateUrl: './session-schedule-card.component.html',
  styleUrls: ['./session-schedule-card.component.scss'],
})
export class SessionScheduleCardComponent implements OnInit, OnDestroy {
  previewDate: Moment = null;
  immediateSessions$: Observable<ClinicianSession[]>;
  selectedMonthSessions$: Observable<ClinicianSession[]>;
  selectedMonthSessionDates$: Observable<Moment[]>;
  listedSessions$: Observable<ClinicianSession[]>;
  ngOnDestroy$ = new Subject<void>();

  constructor(private sessionService: ClinicianSessionsService) {}

  ngOnInit() {
    this.onChangeMonth(moment());
    this.sessionService.pollSessionsUntil(
      this.ngOnDestroy$,
      30000,
      moment().startOf('month'),
      moment().add(12, 'weeks').endOf('month')
    );
  }

  ngOnDestroy() {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
  }

  onChangeMonth(date: Moment) {
    let from: Moment, to: Moment;
    if (date.isSameOrBefore(moment(), 'month')) {
      from = date.startOf('month');
      to = moment().add(12, 'weeks').endOf('month');
    } else {
      from = moment().startOf('month');
      to = date.endOf('month');
    }
    this.sessionService.setRange(from, to);
    this.generateImmediateSessions();
    this.generateSessions(date);
    this.generateSessionsList();
  }

  onSelectedDate(date: Moment) {
    this.previewDate = date;
    this.generateSessionsList();
  }

  private generateSessions(date: Moment = moment()) {
    this.selectedMonthSessions$ = this.sessionService.sessionsOrdered$();
    this.selectedMonthSessionDates$ = this.selectedMonthSessions$.pipe(
      map(sessions => sessions.map(session => session.startTime))
    );
  }

  private generateImmediateSessions() {
    this.immediateSessions$ = this.sessionService.sessionsOrdered$().pipe(
      map(sessions => sessions.filter(session => session.endTime.isAfter(moment()))),
      map(sessions => sessions.slice(0, 3))
    );
  }

  private generateSessionsList() {
    if (this.previewDate) {
      this.listedSessions$ = this.selectedMonthSessions$.pipe(
        map(sessions =>
          sessions.filter(session => session.startTime.isSame(this.previewDate, 'date'))
        )
      );
    } else {
      this.listedSessions$ = this.immediateSessions$;
    }
  }
}
