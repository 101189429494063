import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { combineLatest, forkJoin, from, Observable, timer } from 'rxjs';
import { concatMap, filter, map, take, tap } from 'rxjs/operators';

export interface SpeedResult {
  ping: number;
  speed: number;
}
@Injectable({
  providedIn: 'root',
})
export class NetworkSpeedService {
  // so long as we have cors these can be anywhere - for now in local assets
  private pingTestUrl = `/assets/speedtest/ping.txt`;
  private speedTestURL = `/assets/speedtest/download_alt.png`;

  constructor(private http: HttpClient) {}

  start(numOfDownloads: number = 5): Observable<SpeedResult> {
    let results: SpeedResult[] = [];
    const timeout$ = timer(0, 15000).pipe(take(2));
    return combineLatest([this.queueDownloads(numOfDownloads), timeout$]).pipe(
      take(numOfDownloads),
      map(([sr, timeout]: [any, number]) => {
        if (!timeout) {
          results = [...results, sr];
        }
        return results;
      }),
      tap(() => numOfDownloads--),
      filter(() => numOfDownloads === 0),
      map(allResults => this.calculateAverageSpeed(allResults))
    );
  }

  calculateAverageSpeed(results: SpeedResult[]): SpeedResult {
    const calculatedAverage = results.reduce(
      (total, next) => {
        total.ping += next.ping;
        total.speed += next.speed;
        return total;
      },
      { ping: 0, speed: 0 }
    );
    return {
      ping: parseFloat(calculatedAverage.ping.toFixed(0)) / results.length,
      speed: parseFloat(calculatedAverage.speed.toFixed(1)) / results.length,
    };
  }

  private queueDownloads(numberOfTests: number): Observable<SpeedResult> {
    return from(Array(numberOfTests)).pipe(concatMap(this.timeDownloadSpeedAndPing.bind(this)));
  }

  private timeDownloadSpeedAndPing(): Observable<SpeedResult> {
    const dlt = {
      size: 0,
      start: moment(),
      end: null,
      pingEnd: null,
    };

    const pingUrl$ = this.http
      .get(this.decache(this.pingTestUrl), { responseType: 'text' })
      .pipe(tap(res => (dlt.pingEnd = moment())));
    const dlUrl$ = this.http.get(this.decache(this.speedTestURL), { responseType: 'text' }).pipe(
      tap(res => {
        dlt.size = 1006708; // bytes
        dlt.end = moment();
      })
    );

    return forkJoin([pingUrl$, dlUrl$]).pipe(
      take(1),
      map(res => {
        // this is all approximation so some guards in place for random results
        const ping = Math.max(0, moment(dlt.pingEnd).diff(moment(dlt.start), 'milliseconds'));
        const dltime = moment(dlt.end).diff(moment(dlt.start), 'milliseconds');
        const duration = (dltime > ping ? dltime - ping : dltime) / 1000;
        const bitsLoaded = dlt.size * 8;
        const speedBps = bitsLoaded / duration;
        const speedKbps = speedBps / 1024;
        const speedMbps = speedKbps / 1024;
        const speed: number = speedMbps;
        return {
          ping,
          speed,
        };
      })
    );
  }

  private decache(url: string) {
    return `${url}?s=${Date.now()}`;
  }
}
