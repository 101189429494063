<div [@showContent] *ngIf="!isLoading; else loading">
  <h4>Set your new password</h4>
  <p>Please set yourself a secure and memorable password.</p>
  <form [formGroup]="passwordForm" class="mb-4" (ngSubmit)="passwordForm.valid && updatePassword()">
    <div class="c-form-part__field-wrap">
      <label class="required">New password</label>
      <pushdr-password-input-with-rules
        class="mb-10"
        [formControlProxy]="passwordForm.get('password')">
      </pushdr-password-input-with-rules>

      <label class="required">Confirm password</label>
      <pushdr-password-input-with-rules
        [formControlProxy]="passwordForm.get('confirmPassword')"
        [hasMatch]="!passwordForm.hasError('mismatch')">
      </pushdr-password-input-with-rules>
    </div>
    <button
      type="submit"
      class="w-full py-3 mt-10 btn btn-primary"
      value="Log in"
      [disabled]="passwordForm.invalid"
      trackClick="Set new password">
      Set new password
    </button>
  </form>
</div>

<ng-template #loading>
  <div class="flex justify-center w-full h-48">
    <pushdr-spinner-loader></pushdr-spinner-loader>
  </div>
</ng-template>
