import { Component, OnInit } from '@angular/core';
import { RecordsSectionTypes, PatientRecordHTMLSection } from '@pushdr/common/types';
import { PatientRecordsService } from '../../../../services/patient-records/patient-records.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'pushdr-patient-medications-nhs',
  templateUrl: './patient-medications-information.component.html',
  styleUrls: ['./patient-medications-information.component.scss'],
})
export class PatientMedicationsInformationComponent implements OnInit {
  recordSectionData$: Observable<PatientRecordHTMLSection>;

  constructor(private patientRecordsService: PatientRecordsService) {}

  ngOnInit() {
    this.getRecordData();
  }

  getRecordData() {
    this.recordSectionData$ = this.patientRecordsService.getRecordsSection(
      RecordsSectionTypes.MEDICATIONS
    );
  }
}
