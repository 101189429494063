import {
  Component,
  ComponentFactoryResolver,
  ElementRef,
  HostBinding,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Observable, timer } from 'rxjs';
import { filter, map, takeWhile } from 'rxjs/operators';
import { ConsultationLayoutService } from '../../../services/consultation-layout/consultation-layout.service';
import { ConsultationPanelService } from '../../../services/consultation-layout/consultation-panel.service';
import {
  CardItem,
  ConsultationCardService,
} from '../../../services/consultation-layout/consultation-card.service';

@Component({
  selector: 'pushdr-consultation-dynamic-group',
  templateUrl: './consultation-dynamic-group.component.html',
  styleUrls: ['./consultation-dynamic-group.component.scss'],
})
export class ConsultationDynamicGroupComponent implements OnInit, OnDestroy {
  @Input() panelId: string;
  @ViewChild('cardOutput', { read: ViewContainerRef, static: true }) cardOutput: ViewContainerRef;
  @HostBinding('class')
  elementClass = '';

  showAdd = true;
  isVisble = false;
  selectedCard: CardItem = null;
  groupBecomesVisible$: Observable<boolean>;

  getAvailableCards = () => this.cardService.cards$;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private layoutService: ConsultationLayoutService,
    private panelService: ConsultationPanelService,
    private cardService: ConsultationCardService,
    private elementRef: ElementRef
  ) {}

  ngOnInit() {
    this.groupBecomesVisible$ = timer(250, 250).pipe(
      map(
        () => this.elementRef.nativeElement && this.elementRef.nativeElement.offsetParent !== null
      ),
      takeWhile(x => !x, true),
      filter(f => f)
    );

    this.groupBecomesVisible$.subscribe(x => this.changeCard());
    this.elementClass = this.panelId;
  }

  ngOnDestroy(): void {
    // Dispose *this* panel from the collection
    // Prevents memory leakage by removing references
    this.panelService.removePanel(this);
  }

  changeToComponent(component: Type<unknown>): void {
    const cardItem = this.cardService.findCardByComponent(component);
    this.changeCard(cardItem);
  }

  changeCard(card?: CardItem) {
    if (card === this.selectedCard) return;
    this.showAdd = false;

    const layout = this.layoutService.layoutInfoSnapshot;
    if (!card) card = this.cardService.findDefaultCard(this.panelId, layout);
    if (!card) throw new Error(`No default card assigned to ${this.panelId}`);
    this.selectedCard = card;

    const comp = this.componentFactoryResolver.resolveComponentFactory(card.component);
    const viewContainerRef = this.cardOutput;
    viewContainerRef.clear();

    const inj = Injector.create({
      providers: [{ provide: ConsultationDynamicGroupComponent, useValue: this }],
    });
    this.panelService.addPanel(this);

    viewContainerRef.createComponent<any>(comp, undefined, inj);
  }

  onCardChange(card: any) {
    this.changeCard(card);
  }
}
