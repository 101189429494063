import { of, Subject } from 'rxjs';

export const activatedRouteStub = {
  snapshot: {
    queryParams: of({ with: '', allowanceid: 99, partnerid: 'MOCK_PARTNER_ID' }),
    params: of({ id: '0' }),
    data: {},
    paramMap: {
      get: () => {},
    },
    queryParamMap: { get: () => {} },
  },
  queryParams: of({ with: '', allowanceid: 99, partnerid: 'MOCK_PARTNER_ID' }),
  params: of({ id: '0' }),
};

export const patientAnalyticsServiceStub = {
  brazeInitialized$: new Subject(),
  fireBaseInitialized$: new Subject(),
  initialise: () => {},
  trackEvent: () => {},
  setUser: () => {},
};
