import { Component, OnInit } from '@angular/core';
import { RecordsSectionTypes, PatientRecordHTMLSection } from '@pushdr/common/types';
import { PatientRecordsService } from '../../../services/patient-records/patient-records.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'pushdr-patient-clinical',
  templateUrl: './patient-clinical.component.html',
  styleUrls: ['./patient-clinical.component.scss'],
})
export class PatientClinicalComponent implements OnInit {
  recordSectionData$: Observable<PatientRecordHTMLSection>;

  constructor(private patientRecordsService: PatientRecordsService) {}

  ngOnInit() {
    this.getRecordData();
  }

  getRecordData() {
    this.recordSectionData$ = this.patientRecordsService.getRecordsSection(
      RecordsSectionTypes.CLINICAL
    );
  }
}
