import { Component, OnInit } from '@angular/core';
import { SafeStyle } from '@angular/platform-browser';
import { CliniciansAuthService } from '@pushdr/clinicians/common';
import { CliniciansDoctorsDetailsService, DELAY2 } from '@pushdr/clinicians/common';
import { ModalService } from '@pushdr/common/overlay';
import { ClinicianProfileUpdate } from '@pushdr/common/types';
import {
  ApiDoctorsAccount,
  DoctorDetails,
  PasswordDetails,
} from '@pushdr/doctors/data-access/doctors-api';
import { fadeInOnEnterAnimation } from 'angular-animations';
import { Observable, of } from 'rxjs';
import { finalize, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'pushdr-clinicians-edit-profile-page',
  templateUrl: './clinicians-edit-profile-page.component.html',
  styleUrls: ['./clinicians-edit-profile-page.component.scss'],
  animations: [fadeInOnEnterAnimation({ anchor: 'showProfile', delay: DELAY2 })],
})
export class CliniciansEditProfilePageComponent implements OnInit {
  details$: Observable<DoctorDetails>;
  profileImage$: Observable<SafeStyle>;
  saving = false;

  constructor(
    private api: ApiDoctorsAccount,
    private modal: ModalService,
    private doctorDetailsService: CliniciansDoctorsDetailsService,
    private authService: CliniciansAuthService
  ) {}

  ngOnInit() {
    this.details$ = this.doctorDetailsService.details$;
    this.profileImage$ = this.doctorDetailsService.profileImage$;
  }

  updateProfile(profileUpdate: ClinicianProfileUpdate) {
    this.saving = true;
    this.updateAboutMe$(profileUpdate.aboutMe)
      .pipe(mergeMap(() => this.uploadProfileImage$(profileUpdate.profileImage)))
      .subscribe(
        () => {
          this.saving = false;
          this.modal.success('Profile updated successfully.');
        },
        () => {
          this.saving = false;
          this.modal.error(
            'There was an issue updating your profile. Please check your details and try again.'
          );
        }
      );
  }

  updateAboutMe$(aboutText: string): Observable<boolean> {
    if (!aboutText) return of(true);
    return this.doctorDetailsService.updateAboutMe$(aboutText);
  }

  uploadProfileImage$(base64Image: any): Observable<boolean> {
    if (!base64Image) return of(true);
    return this.doctorDetailsService.uploadProfileImage$(base64Image);
  }

  updatePassword(passwordUpdate: PasswordDetails) {
    this.saving = true;
    this.api
      .updatePassword(passwordUpdate)
      .pipe(finalize(() => (this.saving = false)))
      .subscribe(
        () => {
          this.modal.acknowledge(
            'Password change was successful',
            'You will now be logged out.\n Please log back in with your new password.'
          );
          this.authService.logout();
        },
        error => {
          this.modal.error(
            'Password was not changed. Check that all fields were filled correctly and try again.'
          );
        }
      );
  }
}
