<pushdr-consultation-card [hasData]="true" [showFooter]="true">
  <span header>
    <span class="flex justify-between">
      Send a task
      <pushdr-auto-save-status [state]="saveState" (pdrRetry)="save()"></pushdr-auto-save-status>
    </span>
  </span>
  <ng-container body>
    <form *ngIf="followUpActions$ | async" [formGroup]="frmSendATask">
      <div class="field-wrap required">
        <label>Follow-up actions </label>
        <textarea formControlName="followUpActions" rows="5"></textarea>
        <div class="text-error">Follow-up is required</div>
      </div>
      <label><input type="checkbox" formControlName="urgent" /> Flag as urgent</label>
    </form>
  </ng-container>

  <ng-container footer>
    <div class="flex justify-end justify-between w-full p-2 px-8">
      <button class="btn-outline btn btn-tiny" (click)="patientNav.gotoConsultationHome()">
        Back to consultation
      </button>
    </div>
  </ng-container>
</pushdr-consultation-card>
