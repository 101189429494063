import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'pushdr-override-warning',
  templateUrl: './override-warning.component.html',
  styleUrls: ['./override-warning.component.css'],
})
export class OverrideWarningComponent {
  @Input() control: UntypedFormControl = null;
  @Output() pdrReturn = new EventEmitter<void>();
}
