<div class="flex justify-between items-center">
  <div>Prescribe</div>
  <div class="flex w-full justify-end text-grey-500 text-sm">
    <div *ngFor="let step of steps; let i = index" class="flex items-center">
      <i
        class="material-icons mr-1 ml-6 font-thin text-sm"
        [class.text-teal-500]="currentStep === i">
        {{ currentStep < i ? 'east' : currentStep === i ? 'fiber_manual_record' : 'done' }}
      </i>
      <span
        class="pt-1"
        [class.font-medium]="currentStep === i"
        [class.text-bluegrey-800]="currentStep === i"
        >{{ step }}</span
      >
    </div>
  </div>
</div>
