import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { PasswordRule } from './models';

const thumbClassNames = {
  left: 'thumb-position-left',
  right: 'thumb-position-right',
};

@Component({
  selector: 'pushdr-password-input-tooltip',
  templateUrl: './password-input-tooltip.component.html',
  styleUrls: ['./password-input-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordInputTooltipComponent {
  @Input() rules: PasswordRule[];
  @Input() errors: ValidationErrors;
  @Input() thumbPosition: 'left' | 'right' = 'left';

  @HostBinding('class')
  get thumbPositionClass() {
    return thumbClassNames[this.thumbPosition];
  }

  hasError(rule: PasswordRule): boolean {
    return !!this.errors?.[rule.error];
  }
}
