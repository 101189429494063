<div class="c-modal__wrap c-modal-wrap--wide" [cdkTrapFocus]="ready$ | async">
  <div class="modal__body">
    <div class="flex justify-between date-bg">
      <p class="mb-0 px-1 pb-1">{{ receivedTime | isoDate: 'DD-MMM-YYYY HH:mm' }}</p>
      <button class="border-hidden px-4 text-primary rounded" (click)="output()">Close</button>
    </div>
    <pushdr-patient-id [base64Image]="imageUrl" [showFullScreenButton]="false"></pushdr-patient-id>
  </div>
  <div class="py-4 c-modal__iframe-modal-close"></div>
</div>
