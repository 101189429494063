import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClinicianTypeNamePipe } from './clinician-type-name.pipe';
import { ClinicianTypeAbbreviationPipe } from './clinician-type-abbreviation.pipe';

@NgModule({
  declarations: [ClinicianTypeNamePipe, ClinicianTypeAbbreviationPipe],
  imports: [CommonModule],
  exports: [ClinicianTypeNamePipe, ClinicianTypeAbbreviationPipe],
})
export class DoctorNotesPipesModule {}
