<pushdr-card *ngIf="{ changes: changelog$ | async } as data" [hasData]="data.changes">
  <section class="flex flex-col w-full h-full">
    <header class="pt-5 pb-3 mx-5 text-xl font-light border-b border-grey-300">What's new?</header>
    <article class="flex-auto h-px px-5 py-3 overflow-y-auto scrollbar">
      <div *ngFor="let change of data.changes" class="p-4 mb-4 rounded bg-bluegrey-100">
        <h4 class="mb-1 text-base text-body-text">
          {{ change.title }}
          <span
            *ngIf="isRecent(change.date)"
            class="inline-block w-2 h-2 ml-1 bg-red-500 rounded-full"></span>
        </h4>
        <h5 class="mb-4 text-sm font-light text-bluegrey-700">{{ change.date.fromNow() }}</h5>
        <p class="mb-0 text-sm font-light text-body-text" [innerHTML]="change.body"></p>
      </div>
    </article>
  </section>
</pushdr-card>
