<pushdr-consultation-card
  [hasData]="true"
  bodyPadding=""
  [showFooter]="true"
  [applyScrollFix]="true"
  (scrolledToEnd)="onContainerScroll()">
  <pushdr-prescription-card-header header></pushdr-prescription-card-header>

  <ng-container body>
    <section>
      <h3>Basket</h3>
      <article class="basket-item" *ngFor="let item of medications">
        <span class="line1"
          ><strong>{{ item.displayName }}</strong>
          <span>
            ({{ item.quantity }}
            <span class="capitalize" *ngIf="isSchedule2or3(item)"
              >({{ item.quantity | numberToWords }})</span
            >
            {{ item.unitTypeDescription }})</span
          ></span
        >
        <span class="line2"><label>Dose:</label> {{ item.dosage }}</span>
        <span class="line2">
          <ng-container *ngIf="item.endorsement?.length > 0">
            <label>Endorsment<span *ngIf="item.endorsement?.length > 1">s</span>: </label>
            <span>{{ item.endorsement.join(', ') }}</span>
          </ng-container>
          <ng-container *ngIf="item.dispenserNote">
            <label>Pharmacy note: </label><span>{{ item.dispenserNote }}</span>
          </ng-container>
        </span>
      </article>
      <article class="basket-item" *ngIf="medications.length === 0">
        <span class="line1"><strong>No prescription created to sign</strong> </span>
        <button class="btn-outline btn" (click)="cancel()">Cancel</button>
      </article>
    </section>
  </ng-container>
  <ng-container footer>
    <form [formGroup]="frmSignPrescription" *ngIf="this.getFirstPrescription() as prescription">
      <section class="signing-footer">
        <div class="left">
          <div>
            <label>Signing clinician:</label
            ><span
              >{{ prescription?.prescriber?.prescribersName }} -
              {{ prescription?.prescriber?.prescribersTelephoneNumber }}</span
            >
          </div>
          <div>
            <label>Signing intiated:</label
            ><span [title]="prescription?.startTime | date: 'HH:mm:ss dd/MM/yyyy'">{{
              prescription?.startTime | date: 'HH:mm:ss'
            }}</span>
          </div>
        </div>
        <div class="right">
          <a class="cursor-pointer hover:text-indigo-500" [title]="getPrescriptionIds()"
            >View script ID(s)</a
          >
        </div>

        <p>
          The system will sign the content displayed here on your behalf, by means of information
          stored on your smart card as an Advanced Electronic Signature. By entering your PIN here,
          you affirm your intention to digitally sign and issue this electronic prescription. Do you
          wish to proceed?
        </p>
      </section>
      <section class="pin-area relative">
        <div class="pin-box show-validation">
          <label for="pin">Enter smartcard PIN</label>
          <input type="text" autocomplete="off" class="pdr-input" formControlName="pin" id="pin" />
        </div>
        <button class="btn-outline btn" type="button" (click)="cancel()">Cancel</button>
        <button
          class="btn btn-primary showNextHover"
          type="submit"
          (click)="confirm()"
          [disabled]="(isScrolledToEnd$ | async) === false">
          Confirm
        </button>
        <div *ngIf="(isScrolledToEnd$ | async) === false" class="text-sm">
          We require that you scroll through all medication items in your basket before
          confirmation.
        </div>
      </section>
    </form>
  </ng-container>
</pushdr-consultation-card>
