<div class="c-modal__wrap" [cdkTrapFocus]="ready$ | async">
  <h2>Sorry</h2>

  <div *ngIf="data.bottomText">
    <h4>{{ data.bottomText }}</h4>
  </div>

  <div class="modal__footer">
    <button class="c-btn c-btn--primary" (click)="close()" data-cy="close-btn">OK</button>
  </div>
  <ng-container *ngIf="logs.length">
    <button class="mt-8 text-sm text-primary" data-cy="download-logs-btn" (click)="downloadLogs()">
      Download error report
    </button>
  </ng-container>
</div>
