import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  RestClient,
  RestErrorParserServiceBypass,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';
import { EnvironmentProxyService } from '@pushdr/environment';
import { Observable } from 'rxjs';
import { CardSignResponse } from '@pushdr/prescription/api';
import { CardInfoResponse, CardToken, SlotInfoResponse } from './models';

@Injectable({
  providedIn: 'root',
})
export class ApiCardReaderService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserServiceBypass,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return 'http://localhost:54320/api/';
  }

  signPrescription$(unsignedSignatureXml: string, pin: string): Observable<CardSignResponse> {
    return this.post(
      'signit',
      { pin, perscription: unsignedSignatureXml },
      this.headerService.authorisedHeadersLegacy
    );
  }

  getTicket(): Observable<CardToken> {
    return this.get('ticket', {}, this.headerService.authorisedHeadersLegacy);
  }

  getCardInfo$(): Observable<CardInfoResponse> {
    return this.get('ticket/card-info', {}, this.headerService.authorisedHeadersLegacy);
  }

  getSlotInfo$(): Observable<SlotInfoResponse> {
    return this.get('ticket/slot-info', {}, this.headerService.authorisedHeadersLegacy);
  }
}
