import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, filter, map, share, startWith, take } from 'rxjs/operators';
import { PresurgeryStateEnum } from './presurgery-common-states';

export interface HelpArticleByOS {
  win: number;
  mac: number;
}
export interface HelpArticleIndex {
  camera: HelpArticleByOS;
  microphone: HelpArticleByOS;
  speakers: HelpArticleByOS;
  connection: HelpArticleByOS;
}

@Injectable({
  providedIn: 'root',
})
export class PresurgeryService {
  private _stage = new Subject<number>();
  private _stateName = new Subject<string>();
  baseHref = 'presurgery';
  help: HelpArticleIndex = {
    camera: { mac: 89104, win: 89103 },
    microphone: { mac: 89106, win: 89105 },
    speakers: { mac: 89108, win: 89107 },
    connection: { mac: 81541, win: 81541 },
  };

  set stateName(stateName: string) {
    this._stateName.next(stateName);
  }

  set stage(stageNumber: number) {
    this._stage.next(stageNumber);
  }

  getStage$() {
    return this._stage.pipe(startWith(1), debounceTime(0), share());
  }

  getStateName$() {
    return this._stateName.pipe(
      startWith(PresurgeryStateEnum.INITIALISED),
      debounceTime(0),
      share()
    );
  }

  hasError$() {
    return this.getStateName$().pipe(map(msg => msg.toLowerCase().indexOf('error') > -1));
  }

  whenCompleted$() {
    return this.getStateName$().pipe(
      filter(msg => msg === PresurgeryStateEnum.COMPLETED),
      take(1)
    );
  }
}
