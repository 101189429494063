import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A11yModule } from '@angular/cdk/a11y';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { InlineSVGModule } from 'ng-inline-svg';
import {
  InputWithCharacterCountModule,
  InputWithIconModule,
  SpinnerLoaderModule,
} from '@pushdr/common/components';
import { CliniciansCommonModule } from '@pushdr/clinicians/common';
import { ClipboardModule, CommonPipesModule } from '@pushdr/common/utils';
import { CliniciansConsultationsComponent } from './routes/clinicians-consultations/clinicians-consultations.component';
import { CliniciansConsultationDetailsComponent } from './routes/clinicians-consultation-details/clinicians-consultation-details.component';
import { CliniciansConsultationPatientDetailsComponent } from './routes/clinicians-consultation-details/components/clinicians-consultation-patient-details/clinicians-consultation-patient-details.component';
import { CliniciansPrescribedMedicationsComponent } from './routes/clinicians-consultation-details/components/clinicians-prescribed-medications/clinicians-prescribed-medications.component';
import { CliniciansPrescriberSearchComponent } from './routes/clinicians-consultations/components/clinicians-prescriber-search/clinicians-prescriber-search.component';
import { ConsultationsFilterByPatientPipe } from './pipes/consultations-filter-by-patient.pipe';
import { ConsultationsFilterByPrescriberPipe } from './pipes/consultations-filter-by-prescriber.pipe';
import { PrescriptionStatusNamePipe } from './pipes/prescription-status-name.pipe';
import { PrescriptionStatusDescriptionPipe } from './pipes/prescription-status-description.pipe';
import { PrescriptionErrorStatusCheckPipe } from './pipes/prescription-status-check.pipe';
import { CancelPrescriptionModalComponent } from './routes/clinicians-consultation-details/components/cancel-prescription-modal/cancel-prescription-modal.component';
import { CancelReasonSelectComponent } from './routes/clinicians-consultation-details/components/cancel-prescription-modal/cancel-reason-select/cancel-reason-select.component';

export const CliniciansConsultationHistoryRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: CliniciansConsultationsComponent,
  },
  {
    path: ':id',
    component: CliniciansConsultationDetailsComponent,
  },
];

@NgModule({
  declarations: [
    CliniciansConsultationsComponent,
    CliniciansConsultationDetailsComponent,
    CliniciansPrescribedMedicationsComponent,
    CliniciansConsultationPatientDetailsComponent,
    CliniciansPrescriberSearchComponent,
    ConsultationsFilterByPrescriberPipe,
    ConsultationsFilterByPatientPipe,
    PrescriptionStatusNamePipe,
    PrescriptionStatusDescriptionPipe,
    PrescriptionErrorStatusCheckPipe,
    CancelPrescriptionModalComponent,
    CancelReasonSelectComponent,
  ],
  imports: [
    CommonModule,
    CliniciansCommonModule,
    InputWithIconModule,
    InputWithCharacterCountModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    A11yModule,
    RouterModule,
    InlineSVGModule,
    ClipboardModule,
    CommonPipesModule,
    SpinnerLoaderModule,
  ],
  exports: [CliniciansConsultationsComponent, CliniciansConsultationDetailsComponent],
})
export class CliniciansConsultationHistoryModule {}
