import { Injectable } from '@angular/core';
import {
  RestClient,
  RestErrorParserService,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentProxyService } from '@pushdr/environment';
import * as moment from 'moment';
import { Moment } from 'moment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

export enum DoctorSessionEventType {
  SESSION_START = 0,
  BREAK_START = 1,
  BREAK_END = 2,
  SESSION_END = 3,
}

export enum DoctorSessionStatus {
  PENDING = 0,
  IN_PROGRESS = 1,
  ON_BREAK = 2,
  COMPLETED = 3,
}

export enum SurgeryType {
  UNKNOWN = 0,
  PRIVATE = 2,
  EMIS = 4,
  TPP = 5,
  HYBRID = 7,
}

export interface DoctorSession {
  id: string;
  startTime: Moment;
  endTime: Moment;
  events: DoctorSessionEvent[];
  partnerId: string;
  status: DoctorSessionStatus;
  surgeryType?: SurgeryType; // TODO: delete this if @doctor/elements is no more
  tags?: {
    PartnerId?: string[];
    SurgeryType?: string[];
  };
}

export interface DoctorSessionEvent {
  eventType: DoctorSessionEventType;
  eventTime: Moment;
}

@Injectable({
  providedIn: 'root',
})
export class ApiDoctorsSessionsService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.doctors.sessionsAPI;
  }

  getSessions(
    from = moment().startOf('day'),
    to = moment().endOf('day')
  ): Observable<DoctorSession[]> {
    return this.get<any[]>(
      '/session',
      { from: from.toISOString(), to: to.toISOString() },
      this.headerService.authorisedHeadersLegacy
    ).pipe(
      map(res => {
        return res.map((session: DoctorSession) => {
          session.startTime = moment(session.startTime);
          session.endTime = moment(session.endTime);
          session.events = session.events.map(event => {
            event.eventTime = moment(event.eventTime);
            return event;
          });
          return session;
        });
      })
    );
  }

  startSession(sessionId: string) {
    return this.post(
      '/sessionevent/' + sessionId + '/start',
      {},
      this.headerService.authorisedHeadersLegacy
    );
  }

  endSession(sessionId: string) {
    return this.post(
      '/sessionevent/' + sessionId + '/end',
      {},
      this.headerService.authorisedHeadersLegacy
    );
  }

  startBreak(sessionId: string) {
    return this.post(
      '/sessionevent/' + sessionId + '/break/start',
      {},
      this.headerService.authorisedHeadersLegacy
    );
  }

  endBreak(sessionId: string) {
    return this.post(
      '/sessionevent/' + sessionId + '/break/end',
      {},
      this.headerService.authorisedHeadersLegacy
    );
  }
}
