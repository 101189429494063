<div class="flex flex-row items-center justify-between px-5 h-12 cursor-pointer {{ bg }}">
  <div class="flex-none mr-3 mt-2" *ngIf="icon">
    <i class="material-icons {{ color }}">{{ icon }}</i>
  </div>
  <div class="flex flex-col flex-grow">
    <h5 class="mt-2 font-normal {{ color }}">{{ label }}</h5>
  </div>
  <ng-container *ngIf="!isAlwaysOpen">
    <i class="material-icons expand-icon hidden">expand_more</i>
    <i class="material-icons collapse-icon">chevron_right</i>
  </ng-container>
</div>
