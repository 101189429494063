import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Input,
} from '@angular/core';

import { DoctorNote } from '@pushdr/consultation/api';

@Component({
  selector: 'pushdr-doctor-notes-list-item',
  templateUrl: './doctor-notes-list-item.component.html',
  styleUrls: ['./doctor-notes-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DoctorNotesListItemComponent {
  @Input() note!: DoctorNote;

  @HostBinding('class.active')
  showDetails = false;

  @HostListener('click')
  toggleDetails(): void {
    this.showDetails = !this.showDetails;
  }
}
