<ng-container *ngIf="stats">
  <div class="flex items-end h-0 pl-8">
    <div class="stats-list">
      <div class="mb-5 leading-6">
        <div class="text-grey-500">System type</div>
        <div class="text-body-text" data-cy="session-type">
          {{ stats.surgeryTypes | clinicianSystem }}
        </div>
      </div>
      <div class="leading-6">
        <div class="text-grey-500">Session remaining</div>
        <div class="text-body-text" data-cy="progress-time">
          {{ stats.currentTime }} {{ stats.affix }}
        </div>
      </div>
    </div>
  </div>
  <div class="relative w-full h-2 mt-10 bg-black">
    <div class="absolute h-2 bg-secondary" [style.width.%]="stats.percent || 0"></div>
  </div>
</ng-container>
