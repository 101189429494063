import { Component } from '@angular/core';
import { PatientNavigationService } from '../../../services/patient-navigation/patient-navigation.service';

@Component({
  selector: 'pushdr-safeguarding-info',
  templateUrl: './safeguarding-info.component.html',
  styleUrls: ['./safeguarding-info.component.scss'],
})
export class SafeguardingInfoComponent {
  constructor(private patientNav: PatientNavigationService) {}

  onClickBackToConsultation() {
    this.patientNav.gotoConsultationHome();
  }

  onGotoPatientEmergency() {
    this.patientNav.gotoPatientEmergency();
  }
}
