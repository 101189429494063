import { Component, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ApiDoctorsAccount } from '@pushdr/doctors/data-access/doctors-api';
import { CliniciansAuthService, PasswordInputWithRulesComponent } from '@pushdr/clinicians/common';
import { ModalService } from '@pushdr/common/overlay';
import { fadeInOnEnterAnimation } from 'angular-animations';
import { finalize, switchMap } from 'rxjs/operators';
import { matchValueValidator } from '@pushdr/common/utils';

@Component({
  selector: 'pushdr-first-time-update-password',
  templateUrl: './first-time-update-password.component.html',
  styleUrls: ['./first-time-update-password.component.scss'],
  animations: [fadeInOnEnterAnimation({ anchor: 'showContent' })],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CliniciansFirstTimeUpdatePasswordComponent {
  isLoading = false;

  passwordForm = new UntypedFormGroup(
    {
      password: PasswordInputWithRulesComponent.createPasswordControl(),
      confirmPassword: PasswordInputWithRulesComponent.createPasswordControl(),
    },
    matchValueValidator('password', 'confirmPassword')
  );

  constructor(
    private api: ApiDoctorsAccount,
    private authService: CliniciansAuthService,
    private modal: ModalService
  ) {}

  updatePassword() {
    this.updatePasswordHandler().subscribe({
      next: () => this.authService.logout(),
      error: () => this.showPasswordResetErrorModal(),
    });
  }

  private updatePasswordHandler() {
    this.isLoading = true;
    const strNewPassword = this.passwordForm.value.password;
    const strOldPassword = this.authService.password;
    return this.api.updatePassword({ strNewPassword, strOldPassword }).pipe(
      finalize(() => this.resetPasswordForm()),
      finalize(() => (this.isLoading = false)),
      switchMap(() => this.showPasswordResetModal())
    );
  }

  private resetPasswordForm() {
    this.passwordForm.reset();
  }

  private showPasswordResetModal() {
    return this.modal.acknowledge('Success', 'Password reset successfully.');
  }

  private showPasswordResetErrorModal() {
    return this.modal.error(
      'Password was not changed. Check that all fields were filled correctly and try again.'
    );
  }
}
