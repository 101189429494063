import { Pipe, PipeTransform } from '@angular/core';
import * as converter from 'number-to-words';

@Pipe({
  name: 'numberToWords',
})
export class NumberToWordsPipe implements PipeTransform {
  transform(value: number, decimalPlaces: number = 2): string {
    if (typeof value !== 'number') value = Number(value);
    const deciamlPart = value % 1;

    let result = converter.toWords(value);

    const tenToNumerOfDeci = 10 ** decimalPlaces;
    const decimalPlacesPart = Math.round(deciamlPart * tenToNumerOfDeci) / tenToNumerOfDeci;
    const decimalPlacesPartStr = decimalPlacesPart.toString().split('.')[1];
    if (decimalPlacesPartStr) {
      result += ` point ${decimalPlacesPartStr
        .split('')
        .reduce((a, v) => a + converter.toWords(Number(v)) + ' ', '')
        .trimRight()}`;
    }

    return result;
  }
}
