<pushdr-consultation-card [hasData]="true" [showFooter]="true">
  <span class="text-information" header> Safeguarding process </span>
  <ng-container body>
    <p>
      If you have any safeguarding concerns, including concerns about the identity of the
      accompanying adult or child, or the nature of their relationship, a safeguarding referral
      needs to be made.
    </p>
    <p>
      If it is appropriate, inform the patient/carer that you are making a safeguarding referral
      (remember it may not be appropriate to inform a parent/individual if you suspect
      parental/child/adult abuse or child trafficking).
    </p>
    <p>
      Complete the clinical record and end your consultation with the patient when it is appropriate
      to do so.
    </p>
    <button class="w-2/5 p-4 mb-8 btn btn-primary" routerLink="../steps">
      View steps required
    </button>
    <p>
      Please consider whether separate contact should also be made with emergency services (for
      example when there are concerns the patient is in immediate danger).
    </p>
    <p class="font-medium">
      If so, ensure this is done by calling 999 and asking for the appropriate emergency service.
    </p>
    <button class="w-2/5 p-4 btn btn-error" (click)="onGotoPatientEmergency()">
      Patient emergency
    </button>
  </ng-container>
  <ng-container footer>
    <button class="w-2/5 p-4 m-5 btn btn-outline" (click)="onClickBackToConsultation()">
      Back to consultation
    </button>
  </ng-container>
</pushdr-consultation-card>
