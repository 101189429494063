import * as moment from 'moment';
import { Moment } from 'moment';

export function formattedDurationHHMMSS(start: Moment, end: Moment = moment()): string {
  const milliseconds = end.diff(start, 'seconds') * 1000;
  return getHHMMSSfromMilliseconds(milliseconds);
}
export function formattedDurationMMSS(start: Moment, end: Moment = moment()): string {
  const milliseconds = end.diff(start, 'seconds') * 1000;
  return getMMSSfromMilliseconds(milliseconds);
}

export function getMMSSfromMilliseconds(milliseconds) {
  const isNegative = milliseconds < 0;
  const statusDuration = moment.duration(isNegative ? -milliseconds : milliseconds);
  const mins = (statusDuration.hours() * 60 + statusDuration.minutes()).toString().padStart(2, '0'),
    secs = statusDuration.seconds().toString().padStart(2, '0');
  return `${isNegative ? '-' : ''}${mins}:${secs}`;
}

export function getHHMMSSfromMilliseconds(milliseconds) {
  const isNegative = milliseconds < 0;
  const statusDuration = moment.duration(isNegative ? -milliseconds : milliseconds);
  const hours = statusDuration.hours(),
    mins = statusDuration.minutes().toString().padStart(2, '0'),
    secs = statusDuration.seconds().toString().padStart(2, '0');
  return `${isNegative ? '-' : ''}${hours}:${mins}:${secs}`;
}
