<div class="c-modal__wrap">
  <h3 class="mb-8">Warning!</h3>
  <p>
    This feature has been designed for emergency use only, and should <strong>NOT</strong> be used
    unless instructed to do so by the clerical team, or if you need to make vital changes to a
    patient’s notes.
  </p>
  <p>
    Please ensure that you are making full notes during a consultation and you are
    <strong>NOT</strong> using this feature to complete notes after a consultation has ended.
  </p>

  <p>All use of this functionality is monitored.</p>

  <div class="flex-row justify-center sm:block md:flex">
    <button
      class="p-4 pl-6 pr-6 bg-white border border-solid rounded hover:bg-grey-100 border-primary text-primary md:mr-2"
      (click)="done(false)">
      Cancel
    </button>
    <button
      class="p-4 pl-6 pr-6 text-white rounded bg-error hover:bg-red-300 md:ml-2"
      (click)="done(true)">
      I understand - Give me access
    </button>
  </div>
</div>
