import { Injectable } from '@angular/core';
import { Dispenser } from '@pushdr/prescription/api';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

export interface PrescriptionDispenser {
  dispenser: Dispenser;
  patientNotes: string;
}

@Injectable({
  providedIn: 'root',
})
export class PrescriptionDispenserService {
  private dispenserSubject = new BehaviorSubject<PrescriptionDispenser>({
    dispenser: null,
    patientNotes: '',
  });

  readonly dispenser$ = this.dispenserSubject.pipe(map(state => state.dispenser));
  readonly patientNotes$ = this.dispenserSubject.pipe(map(state => state.patientNotes));

  setDispenser(dispenser: Dispenser): void {
    this.updateDispenserState({ dispenser });
  }

  setPatientNotes(patientNotes: string): void {
    this.updateDispenserState({ patientNotes });
  }

  private updateDispenserState(changes: Partial<PrescriptionDispenser>) {
    const newState = { ...this.dispenserSubject.value, ...changes };
    this.dispenserSubject.next(newState);
  }
}
