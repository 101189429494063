import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiagnosisComponent } from './diagnosis.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [DiagnosisComponent],
  imports: [CommonModule, NgSelectModule, FormsModule, ReactiveFormsModule],
  exports: [DiagnosisComponent],
})
export class DiagnosisModule {}
