<div class="alert-bar" [ngClass]="alertBarClass" *ngIf="visible">
  <div class="alert-content">
    <i class="alert-icon material-icons" *ngIf="matIconName">
      {{ matIconName }}
    </i>
    <div class="alert-text">
      <ng-content></ng-content>
    </div>
    <span class="alert-dismiss-btn" *ngIf="dismissable" (click)="visible = false">&times;</span>
  </div>
</div>
