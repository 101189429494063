<div
  [cdkTrapFocus]="ready$ | async"
  class="c-modal__wrap c-modal-wrap--wide"
  *ngIf="{ pdf: pdfReport$ | async, message: loadingMessage$ | async } as data">
  <div class="modal__body">
    <div class="c-document-viewer__content" *ngIf="data.pdf; else loading" data-cy="notes-preview">
      <iframe
        class="c-document-viewer__iframe"
        [src]="data.pdf"
        title="Consultation notes preview"></iframe>
    </div>
    <ng-template #loading>
      <pushdr-spinner-loader data-cy="loader" [message]="data.message" [minheight]="0">
      </pushdr-spinner-loader>
    </ng-template>
  </div>
  <div class="modal__footer modal__footer--one-row">
    <button
      [disabled]="!data.pdf"
      data-cy="complete-btn"
      class="c-btn c-btn--primary"
      type="submit"
      (click)="done({ success: true })">
      Accept notes
    </button>
    <button
      data-cy="issue-btn"
      class="c-btn c-btn--danger c-btn--white-bg"
      value="Cancel"
      type="button"
      (click)="close()">
      Edit notes
    </button>
  </div>
</div>
