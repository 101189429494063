import { Inject, Injectable } from '@angular/core';
import { ModalService } from '@pushdr/common/overlay';
import { cache, ExtendedWindow, log, WINDOW } from '@pushdr/common/utils';
import { EnvironmentProxyService } from '@pushdr/environment';
import {
  ApiCardReaderService,
  ApiRbacService,
  CardStatuses,
  OdsCodes,
  Rbac,
  RbacPerms,
  Session,
  SmartCardData,
} from '@pushdr/doctors/data-access/doctors-api';
import { CardSignResponse } from '@pushdr/prescription/api';
import { merge, Observable, of, throwError } from 'rxjs';
import { catchError, delay, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { CardReaderErrorModalComponent } from './modals/card-reader-error/card-reader-error-modal.component';
import { LoadingModalComponent } from './modals/loading/loading-modal.component';

@Injectable({
  providedIn: 'root',
})
export class CardReaderService {
  private _availableSessions$: Observable<SmartCardData>;
  private cached: boolean;

  constructor(
    private cardReaderApi: ApiCardReaderService,
    private rbacServiceApi: ApiRbacService,
    private modal: ModalService,
    private env: EnvironmentProxyService,
    @Inject(WINDOW) private window: ExtendedWindow
  ) {}

  checkPermission$(
    permission: RbacPerms,
    isNhs: boolean = true,
    patientPracticeOds: string = null,
    useCache = true
  ): Observable<boolean> {
    if (!isNhs) {
      return of(null);
    }

    const ods = OdsCodes.PushDr;

    return this.getSessionSelected$(useCache).pipe(
      map(session => this.sessionHasPermission(session, permission, ods))
    );
  }

  getCardData$(useCache = true): Observable<SmartCardData> {
    if (!useCache) {
      this.clearCache();
    }

    if (!this._availableSessions$) {
      const availableSessions$ = merge<
        {
          cardReadCancelled?: boolean;
          allCardData?: SmartCardData;
          cardReadError?: { error: string };
        }[]
      >(
        this.showLoadingModal$().pipe(map(x => ({ cardReadCancelled: x }))),
        this.getAvailableSessionsWithModals$().pipe(
          map(x => ({ allCardData: x })),
          catchError(cardReadError => of({ cardReadError }))
        )
      ).pipe(
        switchMap(x => {
          if (x?.allCardData) {
            return of(x.allCardData);
          }

          if (x.cardReadError) {
            console.error(x.cardReadError);
          }

          if (x.cardReadCancelled) {
            console.warn('Card read cancelled');
          }

          return of(null);
        }),
        take(1),
        tap(x => {
          if (x === null) this.clearCache();
        })
      );

      this._availableSessions$ = availableSessions$.pipe(
        cache(), // mem cache
        tap(() => (this.cached = true))
      );
    }

    return this._availableSessions$;
  }

  signPrescription$(unsignedSignatureXml: string, pin: string): Observable<CardSignResponse> {
    return this.cardReaderApi.signPrescription$(unsignedSignatureXml, pin);
  }

  preloadCardInfoAsync() {
    return this.getCardData$(false).toPromise();
  }

  surgeryOdsMustMatch() {
    return false; //this.feature.isActive('clapp_surgery_must_match_pds');
  }

  validateCardEnvironment(environment: string): boolean {
    const cardEnvs = this.env.environment.doctors.cardReaderEnv;
    return cardEnvs.includes(environment);
  }

  private sessionHasPermission(session: Session, permission: RbacPerms, ods: string): boolean {
    if (!session || !session.permissions.length) {
      return false;
    }

    if (ods && session.orgCode !== ods) {
      return false;
    }

    return session.permissions?.indexOf(permission) > -1;
  }

  private clearCache() {
    this.cached = false;
    this._availableSessions$ = null;
  }

  private getSessionSelected$(useCache = true): Observable<Session> {
    return this.getCardData$(useCache).pipe(
      map((saml: SmartCardData) => saml?.rbac.getSelectedSession())
    );
  }

  private getAvailableSessionsFromApis(callCount: number = 0): Observable<SmartCardData> {
    return this.cardReaderApi.getTicket().pipe(
      log(),
      switchMap(token => {
        if (!this.validateCardEnvironment(token.currentEnvironment)) {
          return throwError(-1);
        }
        return of(token);
      }),
      switchMap(t =>
        this.rbacServiceApi.processSaml$(t.saml, t.ticket).pipe(
          catchError(() => of({ errorType: CardStatuses[CardStatuses.RbacServiceDown] } as Rbac)),
          map(rbac => <SmartCardData>{ rbac, cardInfo: t.cardInfo, error: rbac.errorType })
        )
      ),
      catchError(err => {
        // Catch custom error of invalid environment in obscure way
        // Service may need an overhaul to make it easier to maintain the errors handling
        if (err === -1) {
          return throwError(<SmartCardData>{
            error: CardStatuses[CardStatuses.InvalidEnvironment],
          });
        }

        if (err.status !== 0) {
          return throwError(<SmartCardData>{ error: err?.error?.detail });
        }

        if (callCount === 0) {
          this.openSmartCardApp();
        }

        if (callCount > 3) {
          return throwError(<SmartCardData>{ error: CardStatuses[CardStatuses.ServerNotRunning] });
        }

        return of(0).pipe(
          delay(500),
          switchMap(() => this.getAvailableSessionsFromApis(callCount + 1))
        );
      })
    );
  }

  private openSmartCardApp() {
    this.window.location.href = 'pushdrcard://';
  }

  private getAvailableSessionsWithModals$(): Observable<SmartCardData> {
    const sessions$ = this.getAvailableSessionsFromApis().pipe(map(x => new SmartCardData(x)));

    return sessions$.pipe(
      tap(() => this.modal.close('LoadingModal')),
      catchError(x => this.showErrorModal(x.error, x))
    );
  }

  private showLoadingModal$() {
    if (this.cached) {
      return of(false);
    }

    return this.modal
      .showCustom(LoadingModalComponent, {}, 'LoadingModal')
      .pipe(filter(f => f !== undefined));
  }

  private showErrorModal(errorType: string, err: unknown) {
    this.modal.close('LoadingModal');

    return this.modal
      .showCustom(CardReaderErrorModalComponent, { errorType: errorType }, 'ErrorModal')
      .pipe(filter(f => f !== undefined))
      .pipe(
        switchMap(ignoreAndCont => {
          if (ignoreAndCont) {
            return throwError(err);
          }

          return this.getFreshCardInfo$();
        })
      );
  }

  private getFreshCardInfo$(): Observable<SmartCardData> {
    return of(1).pipe(
      tap(() => this.clearCache()),
      switchMap(() => this.getCardData$())
    );
  }
}
