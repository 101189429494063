<ng-container *ngIf="{ meds: medications$ | async } as data">
  <pushdr-consultation-card-dynamic
    [hasData]="!!data.meds"
    [isEmpty]="data.meds && data.meds.length === 0">
    <span header>Repeat Medications</span>
    <ng-container body>
      <div class="flex flex-col -m-4">
        <ng-container *ngFor="let med of data.meds">
          <div class="block p-4 border-b hover:bg-indigo-100 border-grey-200">
            <pushdr-list-item [hasIcon]="false">
              <h4 title class="mb-0 text-sm font-normal">
                {{ med.medication.code | getCodingText }}
              </h4>
              <div subtitle class="flex flex-row justify-between w-full min-w-0 text-xs truncate">
                <div>
                  <p class="mb-0 font-light text-grey-700">Start Date</p>
                  <p class="mb-0">
                    {{ med.statement.effectivePeriod.start | isoDate: 'DD-MMM-YYYY' }}
                  </p>
                </div>
                <div>
                  <p class="mb-0 font-light text-grey-700">Last Issued</p>
                  <p class="mb-0">
                    {{
                      (
                        med.statement.extension
                        | findArrayItem: 'url':'MedicationStatementLastIssueDate'
                      )?.valueDateTime | isoDate: 'DD-MMM-YYYY':'Not Issued'
                    }}
                  </p>
                </div>
              </div>
            </pushdr-list-item>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </pushdr-consultation-card-dynamic>
</ng-container>
