import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ClinicalDecisionSupportWarnings } from '../models';

@Component({
  selector: 'pushdr-warning-review',
  templateUrl: './warning-review.component.html',
  styleUrls: ['./warning-review.component.scss'],
})
export class WarningReviewComponent implements OnChanges {
  @Input() warnings!: ClinicalDecisionSupportWarnings;
  @Input() hideCheck = false;

  @Output() pdrReviewed = new EventEmitter<void>();

  private warningsReviewed = new Set();

  ngOnChanges(changes: SimpleChanges): void {
    if ('warnings' in changes) {
      this.warningsReviewed = new Set();
      this.updateWarnings();
    }
  }

  warningViewed(id: string): void {
    this.warningsReviewed.add(id);
    this.updateWarnings();
  }

  private updateWarnings(): void {
    const maxWarnings = this.warnings?.warningsArr?.length ?? 0;
    if (this.warningsReviewed.size >= maxWarnings) {
      this.pdrReviewed.emit();
    }
  }
}
