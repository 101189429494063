import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'pushdr-patient-warning',
  templateUrl: './patient-warning.component.html',
  styleUrls: ['./patient-warning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientWarningComponent {
  @Input() warning: string;
}
