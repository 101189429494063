<div *ngIf="consultationNotes?.intNoteID; else noNotes">
  <div class="mb-1 font-light title text-grey-500">Examination</div>
  <div class="mb-4 text-bluegrey-800">
    {{ consultationNotes.strExamination || 'No examination notes' }}
  </div>
  <div class="mb-1 font-light title text-grey-500">Management plan</div>
  <div class="mb-4 text-bluegrey-800">
    {{ consultationNotes.strManagementPlan || 'No management plan notes' }}
  </div>
  <div class="mb-1 font-light title text-grey-500">Safety netting</div>
  <div class="mb-4 text-bluegrey-800">
    {{ consultationNotes.strSafetyNetting || 'No safety netting notes' }}
  </div>
</div>

<ng-template #noNotes>
  <p class="text-center text-bluegrey-800">No notes</p>
</ng-template>
