import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnInit,
} from '@angular/core';
import { NetworkMonitorResult } from '../../../services/network-monitor/network-monitor.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'pushdr-network-sub-menu',
  templateUrl: './network-sub-menu.component.html',
  styleUrls: ['./network-sub-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NetworkSubMenuComponent implements OnInit {
  @Input() networkStatus$: Observable<NetworkMonitorResult>;
  @Output() runTest = new EventEmitter<null>();

  status$: Observable<NetworkMonitorResult>;
  loading = false;

  ngOnInit() {
    this.status$ = this.networkStatus$.pipe(tap(() => (this.loading = false)));
  }

  test() {
    if (this.loading) return;

    this.loading = true;
    this.runTest.emit();
  }
}
