import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsultationChatComponent } from './consultation-chat.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonOverlayModule } from '@pushdr/common/overlay';

@NgModule({
  declarations: [ConsultationChatComponent],
  imports: [CommonModule, ReactiveFormsModule, CommonOverlayModule],
  exports: [ConsultationChatComponent],
})
export class ConsultationChatModule {}
