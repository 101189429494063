import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { AutoSaveState } from '../../../components/auto-save-status/auto-save-status.component';
import { ConsultationNotesService } from '../../../services/consultation-notes/consultation-notes.service';
import { PatientNavigationService } from '../../../services/patient-navigation/patient-navigation.service';

@Component({
  selector: 'pushdr-send-a-task',
  templateUrl: './send-a-task.component.html',
  styleUrls: ['./send-a-task.component.scss'],
})
export class SendATaskComponent implements OnInit, OnDestroy {
  frmSendATask: UntypedFormGroup;
  followUpActions$: Observable<any>;

  saveState = AutoSaveState.Saved;
  teardown$ = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private notesService: ConsultationNotesService,
    public patientNav: PatientNavigationService
  ) {}

  ngOnInit(): void {
    this.frmSendATask = this.fb.group({
      followUpActions: ['', [Validators.required]],
      urgent: [false, []],
    });

    this.followUpActions$ = this.notesService.getFollowUpActions$().pipe(
      tap(details => {
        this.frmSendATask.reset(details, { emitEvent: false });
      })
    );

    const saveRequest$ = this.frmSendATask.valueChanges.pipe(
      tap(() => (this.saveState = AutoSaveState.Saving)),
      debounceTime(2000)
    );

    saveRequest$.pipe(takeUntil(this.teardown$)).subscribe({
      next: () => this.save(),
      complete: () => this.save(),
    });
  }

  ngOnDestroy(): void {
    this.teardown$.next();
    this.teardown$.complete();
  }

  save() {
    if (this.frmSendATask.dirty) {
      const args = { ...this.frmSendATask.value };
      this.saveState = AutoSaveState.Saving;
      this.frmSendATask.markAllAsTouched();
      this.notesService.saveFollowUpActions$(args).subscribe({
        next: () => (this.saveState = AutoSaveState.Saved),
        error: () => (this.saveState = AutoSaveState.Error),
      });
    }
  }
}
