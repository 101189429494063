import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { CancelReason } from '../../../../../services/prescriptions-management.service';
import { PrescriptionCancelReason } from '../../../../../types/Prescription';

const REASONS: CancelReason[] = [];

@Component({
  selector: 'pushdr-cancel-reason-select',
  templateUrl: './cancel-reason-select.component.html',
  styleUrls: ['./cancel-reason-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CancelReasonSelectComponent implements ControlValueAccessor, OnInit {
  onTouched: () => void;
  @Input() reasons = REASONS;
  @Output() reasonChange = new EventEmitter<string>();
  @Input() control = new UntypedFormControl();

  private searchTerm$ = new BehaviorSubject<string>('');

  reasons$: Observable<CancelReason[]>;
  constructor(private _fb: UntypedFormBuilder) {}

  ngOnInit() {
    this.reasons = Object.keys(PrescriptionCancelReason).map(
      k =>
        ({
          Code: k as string,
          Text: PrescriptionCancelReason[k] as string,
        } as CancelReason)
    );

    this.reasons$ = this.searchTerm$.pipe(
      debounceTime(250),
      map(searchTerm =>
        !searchTerm || searchTerm.toString().length < 2
          ? this.reasons || []
          : (this.reasons || []).filter(
              reason => reason.Text.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
            )
      )
    );
  }

  registerOnChange(fn: (val: unknown) => void) {
    this.control.valueChanges.subscribe(fn);
  }

  writeValue(val: CancelReason) {
    if (val) {
      this.control.setValue(val);
    }
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  changeSearchTerm(event: any) {
    this.searchTerm$.next(event.target.value);
  }

  onChange(value: CancelReason) {
    this.reasonChange.emit(value.Code);
  }

  dummySearch() {
    return true;
  }
}
