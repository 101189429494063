import { Component } from '@angular/core';
import { fadeInOnEnterAnimation, slideInDownOnEnterAnimation } from 'angular-animations';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  ConsultationState,
  ConsultationStateService,
  DELAY1,
  DELAY3,
  DURATION4,
} from '@pushdr/clinicians/common';

import {
  ApiDoctorsConsultation as ApiCliniciansConsultation,
  ClinicianConsultationStatus,
} from '@pushdr/doctors/data-access/doctors-api';

import { CanComponentDeactivate } from '@pushdr/common/utils';

@Component({
  selector: 'pushdr-clinicians-consultation',
  templateUrl: './clinicians-consultation.component.html',
  styleUrls: ['./clinicians-consultation.component.scss'],
  animations: [
    fadeInOnEnterAnimation({ anchor: 'showContent', delay: DELAY1 }),
    slideInDownOnEnterAnimation({ anchor: 'showMenu', delay: DELAY3, duration: DURATION4 }),
  ],
})
export class CliniciansConsultationComponent implements CanComponentDeactivate {
  isMenuVisible$ = this.consultationState.state$.pipe(
    map(state => [ConsultationState.CONSULTING, ConsultationState.EDITING].includes(state))
  );

  constructor(
    private api: ApiCliniciansConsultation,
    private consultationState: ConsultationStateService
  ) {}

  canDeactivate(): Observable<boolean> {
    return this.api.updateStatus(ClinicianConsultationStatus.UNAVAILABLE).pipe(
      catchError(() => of(true)),
      map(() => true)
    );
  }
}
