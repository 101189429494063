<pushdr-consultation-card [hasData]="!!notes" [showFooter]="true">
  <span header>
    <span class="flex justify-between">
      Consultation notes
      <ng-container [ngSwitch]="true">
        <div class="flex flex-col justify-center text-xs italic text-grey-700">
          <span class *ngSwitchCase="notesForm?.dirty && !errorUpdating"> Saving... </span>
          <span class *ngSwitchCase="!notesForm?.dirty && !errorUpdating"> Saved </span>
          <span class *ngSwitchDefault class="text-error">
            Error saving
            <button class="underline" (click)="manualSave()">Try again</button>
          </span>
        </div>
      </ng-container>
    </span>
  </span>
  <ng-container body>
    <form
      *ngIf="!!notesForm"
      class="flex flex-col"
      [formGroup]="notesForm"
      (ngSubmit)="notesForm.valid && manualSave()">
      <div class="mb-4">
        <p class="mb-1 text-grey-700">
          1. Presenting case & examination
          <span class="text-error">*</span>
        </p>
        <pushdr-input-with-character-count
          #examination
          type="text"
          name="examinationNotes"
          formControlName="examinationNotes"
          rows="5"
          [multiline]="4"
          [max]="10000"></pushdr-input-with-character-count>
      </div>
      <div class="mb-4">
        <p class="mb-1 text-grey-700">
          2. Management plan
          <span class="text-error">*</span>
        </p>
        <pushdr-input-with-character-count
          #managementPlan
          type="text"
          name="managementPlanNotes"
          formControlName="managementPlanNotes"
          rows="5"
          [multiline]="4"
          [max]="10000"></pushdr-input-with-character-count>
      </div>
      <div class="mb-4">
        <p class="mb-1 text-grey-700">
          3. Safety netting
          <span class="text-error">*</span>
        </p>
        <pushdr-input-with-character-count
          #safetyNetting
          type="text"
          name="safetyNettingNotes"
          formControlName="safetyNettingNotes"
          rows="5"
          [multiline]="4"
          [max]="10000"></pushdr-input-with-character-count>
      </div>

      <div class="mb-4 field-wrap">
        <label> 5. GP share status <span class="text-error">*</span> </label>
        <div class="flex flex-wrap gap-3">
          <label class="flex items-baseline gap-2 justify-items-auto">
            <input type="radio" formControlName="confidentialNotes" [value]="false" />
            <span>On</span>
          </label>
          <label class="flex items-baseline gap-2 justify-items-auto">
            <input type="radio" formControlName="confidentialNotes" [value]="true" />
            <span>Off</span>
          </label>
        </div>
      </div>

      <div
        class="p-2 mb-4 bg-red-100 rounded text-bluegrey-800"
        *ngIf="notesForm.get('confidentialNotes').value === true">
        <label class="text-sm font-medium text-red-500"
          >Warning! This setting means that the notes/follow up actions will not be shared with the
          patient's registered GP. The patient's NHS medication record will also not be updated and
          Push Doctor will not be able to arrange for a paper prescription if electronic prescribing
          fails. <br /><br />
          Please be extra vigilant when prescribing medication to prevent potential substance
          abuse.</label
        >
      </div>

      <div class="mb-4 field-wrap" *ngIf="notesForm.get('confidentialNotes').value === true">
        <label> Override reason <span class="text-error">*</span> </label>

        <pushdr-input-with-character-count
          #safetyNetting
          type="text"
          name="overrideGpShareReason"
          formControlName="overrideGpShareReason"
          rows="5"
          [max]="10000"></pushdr-input-with-character-count>
        <div class="text-error">An override reason must be provided</div>
      </div>
    </form>
  </ng-container>
  <ng-container footer>
    <div class="flex justify-end justify-between w-full p-2 px-8">
      <button data-cy="back" class="btn-outline btn btn-tiny" (click)="onClickBackToConsultation()">
        Back to consultation
      </button>
      <span>
        <a [routerLink]="['../list']" class="btn btn-primary btn-tiny"> Consultation history </a>
      </span>
    </div>
  </ng-container>
</pushdr-consultation-card>
