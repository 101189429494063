<div class="c-modal__wrap">
  <h3 class="mb-8">Reading NHS Smart Card</h3>

  <div class="flex justify-center mb-3">
    <img class="u-mb24@xs u-mt48@xs" src="assets/images/smart-card.jpg" alt="Reading smart card" />
  </div>

  <div class="flex-row justify-center sm:block md:flex">
    <button
      class="p-4 pl-6 pr-6 text-white rounded bg-error hover:bg-red-300 md:ml-2"
      (click)="done(true)">
      Cancel Card Read
    </button>
  </div>
</div>
