export interface MedicationEndorsement {
  endorsementName: string;
  endorsementCode: string;
}
export interface MedicationEndorsementVocabularyItem {
  label: string;
  code: string;
  description: string;
  fdbOnly?: boolean;
  checked?: boolean;
}

export const CONTRACEPTIVE_EXCEPTION_DIANETTE_SNOMED_CODE = '587711000001100';
export const CONTRACEPTIVE_EXCEPTION_CO_CYPRINDIOL_SNOMED_CODE = '332402005';
export const CONTRACEPTIVE_EXCEPTION_CLAIRETTE_SNOMED_CODE = '10606111000001100';
export const CONTRACEPTIVE_EXCEPTION_TERAGEZZA_SNOMED_CODE = '29910511000001105';
export const CONTRACEPTIVE_EXCEPTIONS = [
  CONTRACEPTIVE_EXCEPTION_DIANETTE_SNOMED_CODE,
  CONTRACEPTIVE_EXCEPTION_CO_CYPRINDIOL_SNOMED_CODE,
  CONTRACEPTIVE_EXCEPTION_CLAIRETTE_SNOMED_CODE,
  CONTRACEPTIVE_EXCEPTION_TERAGEZZA_SNOMED_CODE,
];
export const FLAVOUR_FLAG_PHRASE = 'Flavour Not Specified';

export enum EndorsmentCodes {
  sls = 'SLS',
  contraceptive = 'CC',
  sti = 'FS',
  flavour = 'AF',
  acbsProduct = 'ACBS',
}

export const ENDORSEMENTS_VOCABULARY: MedicationEndorsementVocabularyItem[] = [
  {
    code: EndorsmentCodes.sls,
    label: 'Selected List Scheme Drugs',
    description:
      'If prescribing Nizoral, Clobazem, Tamiflu, Relenza or any treatment for Erectile Dysfunction (excluding Sildenfil), ensure the SLS criteria (see Part XVIIIB Drug Tariff) are met and this endorsement is selected.',
    fdbOnly: true,
    checked: true,
  },
  {
    code: EndorsmentCodes.contraceptive,
    label: 'Contraceptive',
    description: 'Select if drug is primarily for contraceptive use (e.g. not for acne)',
    fdbOnly: true,
    checked: true,
  },
  {
    code: EndorsmentCodes.sti,
    label: 'Sexually-transmitted infections',
    description: 'Select for all prescriptions for sexually-transmitted infections',
  },
  {
    code: EndorsmentCodes.flavour,
    label: 'Assorted Flavours',
    description:
      'E.g. variety of shake flavours. You must also prescribe as "Flavour not specified"',
  },
  {
    code: EndorsmentCodes.acbsProduct,
    label: 'ACBS product',
    description:
      'Select if prescribing a nutritional or dermatological product intended for a specific medical condition e.g. calorie shakes, specialist foods, over-the-counter emollients',
    checked: true,
  },
];
