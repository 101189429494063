<div *ngIf="!isLoading; else loading">
  <form [@showContent] autocomplete="on" [formGroup]="loginForm" (ngSubmit)="submit()">
    <div
      class="mb-4"
      [class.pdr-input--error]="loginForm.get('email').errors && loginForm.get('email').touched">
      <label class="required" for="ipt-email">Email address</label>
      <input formControlName="email" name="email" class="pdr-input" type="email" />
      <p [ngSwitch]="true" class="mb-2 mt-2 text-sm text-error">
        <ng-template
          [ngSwitchCase]="
            loginForm.get('email').hasError('email') && loginForm.get('email').touched
          ">
          Please check your email address.
        </ng-template>
        <ng-template
          [ngSwitchCase]="
            loginForm.get('email').hasError('required') && loginForm.get('email').touched
          ">
          An email address is required to sign in.
        </ng-template>
      </p>
    </div>

    <div
      class="mb-8"
      [class.pdr-input--error]="
        loginForm.get('password').errors && loginForm.get('password').touched
      ">
      <label class="required" for="ipt-password">
        <span>Password</span>
        <a
          class="float-right ml-1 text-sm"
          [routerLink]="['/auth/forgot-password']"
          trackClick="reset password">
          Forgot password?
        </a>
      </label>
      <input
        #password
        formControlName="password"
        name="password"
        class="pdr-input"
        type="password" />
      <p
        *ngIf="loginForm.get('password').hasError('required') && loginForm.get('password').touched"
        class="mb-2 mt-2 text-sm text-error"
        id="err-msg-password-required">
        A password is required to sign in.
      </p>
    </div>

    <p class="mb-0 text-center">
      <button
        type="submit"
        class="w-full py-3 btn btn-primary"
        value="Log in"
        [disabled]="loginForm.invalid"
        trackClick="Log in">
        Login
      </button>
    </p>
  </form>
  <p *ngIf="loginDetailsIncorrect" class="mt-2 mb-0">
    Something went wrong. Check your login details and try again. If the problem persists,
    <a [routerLink]="['/auth/customer-service']">contact our customer services</a>.
  </p>
</div>

<ng-template #loading>
  <div class="flex justify-center block w-full h-48">
    <pushdr-spinner-loader></pushdr-spinner-loader>
  </div>
</ng-template>
