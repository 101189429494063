import { Component } from '@angular/core';
import { AbstractModal } from '../abstract-modal';

@Component({
  selector: 'pushdr-acknowledge-modal',
  templateUrl: './acknowledge-modal.component.html',
  styleUrls: [],
})
export class AcknowledgeModalComponent extends AbstractModal<{
  header: string;
  bottomText: string;
  acknowledge: string;
}> {}
