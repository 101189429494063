<ng-container *ngIf="{ meds: medications$ | async } as data">
  <pushdr-consultation-card-dynamic
    (scrolledToEnd)="onScrolledToEnd()"
    [isEmpty]="data.meds.items && data.meds.items.length === 0">
    <span header>Acute Medications</span>
    <ng-container body>
      <div class="flex flex-col -m-4">
        <ng-container *ngFor="let med of data.meds.items">
          <div class="block p-4 border-b hover:bg-indigo-100 border-grey-200">
            <pushdr-list-item [hasIcon]="false">
              <h4 title class="flex items-center mb-0 text-sm font-normal">
                <span class="mt-1">{{ med.medicationName }}</span>
                <a
                  *ngIf="med.isPd"
                  class="p-1 px-3 ml-2 text-orange-500 bg-orange-300 rounded menu-item">
                  <span class="text-sm whitespace-nowrap">PD ℞</span>
                </a>
              </h4>
              <div
                subtitle
                class="flex flex-row justify-start gap-3 w-full min-w-0 text-xs truncate">
                <div>
                  <p class="mb-0 font-light text-grey-700">Start Date</p>
                  <p class="mb-0">{{ med.startDate | isoDate: 'DD-MMM-YYYY' }}</p>
                </div>
                <div>
                  <p class="mb-0 font-light text-grey-700">End Date</p>
                  <p class="mb-0">
                    {{ med.endDate | isoDate: 'DD-MMM-YYYY' }}
                  </p>
                </div>
              </div>
            </pushdr-list-item>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </pushdr-consultation-card-dynamic>
</ng-container>
