<pushdr-consultation-card [hasData]="true" [showFooter]="true">
  <span header>Contact Support</span>
  <ng-container body>
    <p>
      <span class="font-medium">
        If the account holder needs customer support or wishes to make a complaint
      </span>
      , you can advise them to contact our Customer Service team through the
      <span class="font-medium">‘Contact us’</span>
      section on our
      <a href="https://www.pushdoctor.co.uk/contact-us" target="_blank" class="link"> website </a>
    </p>

    <p class="mb-2">Our details</p>
    <div class="p-4 mb-4 rounded bg-bluegrey-200">
      <div class="mb-4">
        <p class="mb-1 text-bluegrey-600">Name</p>
        <p class="mb-0 text-lg font-medium text-bluegrey-800">SQUARE HEALTH LIMITED</p>
      </div>
      <div class="mb-4">
        <p class="mb-1 text-bluegrey-600">Address</p>
        <p class="mb-0">Crown House, William Street, Windsor, Berkshire, SL4 1AT</p>
      </div>
      <div class="mb-4">
        <p class="mb-1 text-bluegrey-600">Email</p>
        <a href="mailto:support@pushdoctor.co.uk">support@pushdoctor.co.uk</a>
      </div>
      <div class="mb-4">
        <p class="mb-1 text-bluegrey-600">Company no.</p>
        <p class="mb-0">07054181</p>
      </div>
    </div>

    <p>
      For reference of our T&C’s you can visit the
      <span class="font-medium">Terms and Conditions</span>
      section on our
      <a href="https://www.pushdoctor.co.uk/terms" target="_blank" class="link"> website </a>
    </p>
  </ng-container>
  <ng-container footer>
    <button
      data-cy="back"
      class="w-2/5 p-4 m-5 btn btn-outline"
      (click)="onClickBackToConsultation()">
      Back to consultation
    </button>
  </ng-container>
</pushdr-consultation-card>
