import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { InputWithCharacterCountComponent } from './input-with-character-count.component';

@NgModule({
  imports: [ReactiveFormsModule, CommonModule],
  declarations: [InputWithCharacterCountComponent],
  exports: [InputWithCharacterCountComponent],
})
export class InputWithCharacterCountModule {}
