import { Injectable } from '@angular/core';
import { PatientHTMLRecords, PatientStructuredRecords } from '@pushdr/common/types';
import { ApiDoctorsPatientService as ApiCliniciansRecordsService } from '@pushdr/doctors/data-access/doctors-api';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, publishReplay, refCount } from 'rxjs/operators';
import { PatientDetailsService } from '../../../../services/patient-details/patient-details.service';

@Injectable({
  providedIn: 'root',
})
export class PatientRecordsBaseService {
  private patientStructuredRecord$: Observable<PatientStructuredRecords>;
  private patientRecord$: Observable<PatientHTMLRecords>;

  patientNhsNumber$: Observable<string>;
  patientOdsCode$: Observable<string>;

  constructor(
    private apiPatient: ApiCliniciansRecordsService,
    private patientDetails: PatientDetailsService
  ) {
    this.initialise();
  }

  initialise() {
    const patientDetails$ = this.patientDetails.foundPDS$.pipe(
      mergeMap(canRequestRecord => {
        if (canRequestRecord) {
          return this.patientDetails.details$;
        } else {
          return EMPTY;
        }
      })
    );
    this.patientNhsNumber$ = patientDetails$.pipe(
      map(details => details.Patient.NhsInformation.SuccessResponse.NhsNumber)
    );
    this.patientOdsCode$ = patientDetails$.pipe(
      map(
        details =>
          details.Patient.NhsInformation.SuccessResponse.OrganisationDataServiceCode ||
          details.Patient.GpSummary.OdsCode
      )
    );
  }

  getStructuredPatientRecord() {
    return this.patientStructuredRecord$
      ? this.patientStructuredRecord$
      : this.getStructuredPatientRecordFromApi();
  }

  getPatientRecordsFromApi() {
    this.patientRecord$ = forkJoin([this.patientNhsNumber$, this.patientOdsCode$]).pipe(
      mergeMap(([nhsNumber, odsCode]) => this.apiPatient.getPatientRecord(nhsNumber, odsCode)),
      map(newRecords => PatientHTMLRecords.fromApi(newRecords)),
      publishReplay(1),
      refCount(),
      catchError(err => {
        this.resetPatientRecord();
        return of(PatientHTMLRecords.fromError(err));
      })
    );

    return this.patientRecord$;
  }

  getPatientRecord() {
    return this.patientRecord$ ? this.patientRecord$ : this.getPatientRecordsFromApi();
  }

  private getStructuredPatientRecordFromApi() {
    this.patientStructuredRecord$ = forkJoin([this.patientNhsNumber$, this.patientOdsCode$]).pipe(
      mergeMap(([nhsNumber, odsCode]) =>
        this.apiPatient.getStructuredPatientRecord(nhsNumber, odsCode)
      ),
      map(data => PatientStructuredRecords.fromRecords(data)),
      publishReplay(1),
      refCount()
    );
    return this.patientStructuredRecord$;
  }

  private resetPatientRecord() {
    this.patientRecord$ = null;
  }
}
