import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'pushdr-prescription-card-header',
  templateUrl: './prescription-card-header.component.html',
  styleUrls: ['./prescription-card-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrescriptionCardHeaderComponent implements OnInit {
  currentStep = 0;

  steps = ['Select', 'Specify', 'Review'];

  constructor(private router: Router) {}

  ngOnInit() {
    const routes = [
      {
        path: 'select-medication',
        step: 0,
      },
      {
        path: 'specify-medication',
        step: 1,
      },
      {
        path: 'medication-info',
        step: 1,
      },
      {
        path: 'review-prescription',
        step: 2,
      },
      {
        path: 'select-dispenser',
        step: 1,
      },
      {
        path: 'sign-prescription',
        step: 2,
      },
      {
        path: 'set-patient-notes',
        step: 2,
      },
      {
        path: 'accumulated-warnings',
        step: 2,
      },
    ];
    const lastRoutePath = this.router?.url?.split('/')?.pop();
    this.currentStep = routes.find(route => route.path === lastRoutePath)?.step;
  }
}
