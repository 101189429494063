import { Component, forwardRef, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { DoctorInfoService, StorageService } from '@pushdr/common/utils';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { PrescriptionsManagementService } from '../../services/prescriptions-management.service';
import { Consultation, ConsultationPrescriber } from '../../types/Consultation';

@Component({
  selector: 'pushdr-clinicians-consultations',
  templateUrl: './clinicians-consultations.component.html',
  styleUrls: ['./clinicians-consultations.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CliniciansConsultationsComponent),
      multi: true,
    },
  ],
})
export class CliniciansConsultationsComponent implements OnInit {
  private _isLoading$ = new BehaviorSubject<boolean>(true);
  isLoading$ = this._isLoading$.asObservable();
  private _hasError$ = new BehaviorSubject<boolean>(false);
  hasError$ = this._hasError$.asObservable();
  private _consultations$ = new BehaviorSubject<Consultation[]>([]);
  consultations$ = this._consultations$
    .asObservable()
    .pipe(map(arr => arr.sort((a, b) => (a.createdDate > b.createdDate ? -1 : 1))));
  private _prescribers$ = new BehaviorSubject<ConsultationPrescriber[]>([]);
  prescribers$ = this._prescribers$.asObservable();

  filtersForm: UntypedFormGroup;
  myPrescriberId = null;

  constructor(
    readonly prescriptionsManagement: PrescriptionsManagementService,
    readonly doctor: DoctorInfoService,
    private formBuilder: UntypedFormBuilder,
    private storage: StorageService
  ) {
    const prevDate =
      this.storage.getSessionStorage('consultation-history-date-filter') || new Date();
    this.filtersForm = this.formBuilder.group({
      date: [moment(new Date(prevDate)).format('YYYY-MM-DD'), [Validators.required]],
      quickFilter: [''],
      prescriberId: [null],
      myPatientsOnly: [false],
    });
  }

  ngOnInit() {
    // Check permissions on component init before executing anything else.
    this.prescriptionsManagement
      .canAccess$()
      .pipe(take(1))
      .subscribe(() => {
        this.myPrescriberId = this.doctor.get()?.DoctorId || 'unknown';
        this.onFilterChanges();
        this.fetchData(this.filtersForm.get('date').value);
      });
  }

  filterByPrescriber(prescriber: ConsultationPrescriber) {
    this.filtersForm.get('prescriberId').setValue(prescriber.id);
  }

  onFilterChanges(): void {
    this.filtersForm.get('myPatientsOnly').valueChanges.subscribe(newValue => {
      this.filtersForm.get('prescriberId').setValue(newValue ? this.myPrescriberId : null);
    });
    this.filtersForm.get('date').valueChanges.subscribe(newValue => {
      this.storage.setSessionStorage('consultation-history-date-filter', newValue.toString());
      this.fetchData(newValue);
    });
  }

  fetchData(dateString: string) {
    const date = moment.tz(dateString, 'Europe/London');

    const data$ = this.prescriptionsManagement.searchByDate(date);
    if (data$) {
      data$.subscribe(res => {
        this._isLoading$.next(res.isLoading);
        this._hasError$.next(res.hasError);
        if (!res.hasError) {
          this._consultations$.next(res.consultations);
          this._prescribers$.next(res.prescribers);
          this.filtersForm.patchValue({ prescriberId: null });
        } else {
          if (!this.filtersForm.get('myPatientsOnly').value) {
            this.filtersForm.patchValue({ prescriberId: null });
          }
        }
      });
    }
  }
}
