import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'isoDate',
  pure: false,
})
export class IsoDatePipe implements PipeTransform {
  transform(value: string, format: string = 'YYYY-MM-DD', defaultValue = 'N/a'): any {
    if (value) {
      return moment(value).format(format);
    } else {
      return defaultValue;
    }
  }
}
