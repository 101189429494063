import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, distinctUntilChanged } from 'rxjs/operators';
import { AnalyticsEvent, AnalyticsUser } from '../analytics-types';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsBusService {
  private _eventBus$ = new Subject<AnalyticsEvent>();
  private _userBus$ = new Subject<any>();

  get eventBus$() {
    return this._eventBus$.asObservable().pipe(distinctUntilChanged());
  }

  get userBus$() {
    return this._userBus$.asObservable().pipe(distinctUntilChanged());
  }

  constructor(private router: Router) {
    this.listenToRouteChanges();
  }

  trackEvent(event: AnalyticsEvent) {
    const trackedEvent = this.removePIIdataFromEvent(event);
    console.log('Bus Tracked Event:', event);
    this._eventBus$.next(trackedEvent);
  }

  setUser(user: AnalyticsUser) {
    console.log('Bus Tracked User:', user);
    this._userBus$.next(user);
  }

  private removePIIdataFromEvent(event: AnalyticsEvent) {
    let trackedEvent = event;
    if (event.properties && event.properties.url && this.hasPersonalData(event.properties.url)) {
      trackedEvent = new AnalyticsEvent({
        ...event,
        properties: { ...event.properties, url: this.removeQueryParams(event.properties.url) },
      });
    }
    return trackedEvent;
  }

  private listenToRouteChanges() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const url = event.urlAfterRedirects;
        const path = this.removeQueryParams(url);
        this.trackEvent(AnalyticsEvent.router('view', path, { url }));
      });
  }

  private hasPersonalData(url: string) {
    return url.indexOf('username') > -1;
  }

  private removeQueryParams(url: string) {
    return url.split('?')[0];
  }
}
