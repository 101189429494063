import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

export interface PreSurgeryStage {
  index: number;
  cyname: string;
  name: string;
  icons: {
    error: string;
    active: string;
    pending: string;
  };
}

@Component({
  selector: 'pushdr-stages-status',
  templateUrl: './stages-status.component.html',
  styleUrls: ['./stages-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StagesStatusComponent {
  @Input() progress: number;
  @Input() hasError: boolean;
  @Input() stages: PreSurgeryStage[] = [
    {
      index: 2,
      cyname: 'camera',
      name: 'Camera',
      icons: {
        error: 'assets/icons/camera_icon_red_md.svg',
        active: 'assets/icons/camera_icon_black_md.svg',
        pending: 'assets/icons/camera_icon_gray_md.svg',
      },
    },
    {
      index: 3,
      cyname: 'microphone',
      name: 'Microphone',
      icons: {
        error: 'assets/icons/mic_icon_red_md.svg',
        active: 'assets/icons/mic_icon_black_md.svg',
        pending: 'assets/icons/mic_icon_gray_md.svg',
      },
    },
    {
      index: 4,
      cyname: 'speakers',
      name: 'Speakers',
      icons: {
        error: 'assets/icons/speaker_icon_red_md.svg',
        active: 'assets/icons/speaker_icon_black_md.svg',
        pending: 'assets/icons/speaker_icon_gray_md.svg',
      },
    },
    {
      index: 5,
      cyname: 'speed',
      name: 'Connection',
      icons: {
        error: 'assets/icons/speed_check_red_md.svg',
        active: 'assets/icons/speed_check_black_md.svg',
        pending: 'assets/icons/speed_check_gray_md.svg',
      },
    },
  ];
}
