export const mockStructured = {
  resourceType: 'Bundle',
  meta: {
    profile: ['https://fhir.nhs.uk/STU3/StructureDefinition/GPConnect-StructuredRecord-Bundle-1'],
  },
  type: 'collection',
  entry: [
    {
      resource: {
        resourceType: 'List',
        meta: { profile: ['https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-List-1'] },
        extension: [
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-ClinicalSetting-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'http://snomed.info/sct',
                  code: '1060971000000108',
                  display: 'General practice service',
                },
              ],
            },
          },
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-ListWarningCode-1',
            valueCode: 'data-in-transit',
          },
        ],
        status: 'current',
        mode: 'snapshot',
        title: 'Allergies and adverse reactions',
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '886921000000105',
              display: 'Allergies and adverse reactions',
            },
          ],
        },
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        date: '2021-03-05T15:56:25.908+00:00',
        orderedBy: {
          coding: [
            {
              system: 'http://hl7.org/fhir/list-order',
              code: 'event-date',
              display: 'Sorted by Event Date',
            },
          ],
        },
        note: [
          {
            text: 'Patient record transfer from previous GP practice not yet complete; information recorded before 21-Jul-2020 may be missing.',
          },
        ],
        entry: [
          { item: { reference: 'AllergyIntolerance/3921CB55-7939-47C9-8370-401B18A936BA' } },
          { item: { reference: 'AllergyIntolerance/6B0DBD3A-87AF-48C3-A27E-9B85E185BC7C' } },
          { item: { reference: 'AllergyIntolerance/CB78C815-940C-495D-AD67-60593A809391' } },
          { item: { reference: 'AllergyIntolerance/382531BA-2321-474F-8F00-D5A50A6254F7' } },
          { item: { reference: 'AllergyIntolerance/4AB6E03B-C87F-4426-9388-99BCC475C77C' } },
          { item: { reference: 'AllergyIntolerance/D4B6856C-5561-438E-B7C6-FB6178A13D98' } },
          { item: { reference: 'AllergyIntolerance/72B02065-F876-443B-8607-561E9823CBA7' } },
          { item: { reference: 'AllergyIntolerance/6F921833-58F4-4C96-89C1-199D02BB925A' } },
          { item: { reference: 'AllergyIntolerance/C0FC11DC-47FB-4974-9F20-21FECC2B2C10' } },
        ],
      },
    },
    {
      resource: {
        resourceType: 'List',
        meta: { profile: ['https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-List-1'] },
        extension: [
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-ClinicalSetting-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'http://snomed.info/sct',
                  code: '1060971000000108',
                  display: 'General practice service',
                },
              ],
            },
          },
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-ListWarningCode-1',
            valueCode: 'data-in-transit',
          },
        ],
        status: 'current',
        mode: 'snapshot',
        title: 'Ended allergies',
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '1103671000000101',
              display: 'Ended allergies',
            },
          ],
        },
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        date: '2021-03-05T15:56:25.908+00:00',
        orderedBy: {
          coding: [
            {
              system: 'http://hl7.org/fhir/list-order',
              code: 'event-date',
              display: 'Sorted by Event Date',
            },
          ],
        },
        note: [
          {
            text: 'Patient record transfer from previous GP practice not yet complete; information recorded before 21-Jul-2020 may be missing. Information not available',
          },
        ],
        emptyReason: {
          coding: [
            {
              system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-ListEmptyReasonCode-1',
              code: 'no-content-recorded',
              display: 'No Content Recorded',
            },
          ],
        },
      },
    },
    {
      resource: {
        resourceType: 'List',
        meta: { profile: ['https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-List-1'] },
        extension: [
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-ClinicalSetting-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'http://snomed.info/sct',
                  code: '1060971000000108',
                  display: 'General practice service',
                },
              ],
            },
          },
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-ListWarningCode-1',
            valueCode: 'data-in-transit',
          },
        ],
        status: 'current',
        mode: 'snapshot',
        title: 'Medications and medical devices',
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '933361000000108',
              display: 'Medications and medical devices',
            },
          ],
        },
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        date: '2021-03-05T15:56:25.908+00:00',
        orderedBy: {
          coding: [
            {
              system: 'http://hl7.org/fhir/list-order',
              code: 'event-date',
              display: 'Sorted by Event Date',
            },
          ],
        },
        note: [
          {
            text: 'Patient record transfer from previous GP practice not yet complete; information recorded before 21-Jul-2020 may be missing.',
          },
        ],
        entry: [
          { item: { reference: 'MedicationStatement/EE6BACC2-7E1B-465C-BC26-3F3BA818A219-MS' } },
          { item: { reference: 'MedicationStatement/CF17989A-ACA6-4E6D-83B2-58E58EF0BE4A-MS' } },
          { item: { reference: 'MedicationStatement/3C2A7114-6572-4A7C-82BD-3618F1CC540F-MS' } },
          { item: { reference: 'MedicationStatement/50B7258F-CEE4-4A76-A6E0-ACF04ADD296C-MS' } },
          {
            item: { reference: 'MedicationStatement/CF17989A-ACA6-4E6D-83B2-58E58EF0BE4A-HD-1-MS' },
          },
          {
            item: { reference: 'MedicationStatement/3C2A7114-6572-4A7C-82BD-3618F1CC540F-HD-1-MS' },
          },
          { item: { reference: 'MedicationStatement/E929E87C-E89A-4887-9A93-D9293E45B1F2-MS' } },
          { item: { reference: 'MedicationStatement/FDE36ABC-1E55-4242-80BA-8C850AD24F48-MS' } },
          {
            item: { reference: 'MedicationStatement/0EB15DD3-FF9C-4C0B-B10A-830C1EA4111E-HD-1-MS' },
          },
          { item: { reference: 'MedicationStatement/BB435344-A10A-4EE8-93BE-D2C5DFC59105-MS' } },
          {
            item: { reference: 'MedicationStatement/BB435344-A10A-4EE8-93BE-D2C5DFC59105-FI-1-MS' },
          },
          { item: { reference: 'MedicationStatement/0EB15DD3-FF9C-4C0B-B10A-830C1EA4111E-MS' } },
          { item: { reference: 'MedicationStatement/BE3F5215-1BD6-4CA5-9146-323022BF2324-MS' } },
        ],
      },
    },
    {
      resource: {
        resourceType: 'Patient',
        id: 'DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F',
        meta: {
          versionId: '8355029059758516272',
          profile: ['https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-Patient-1'],
        },
        extension: [
          {
            extension: [
              {
                url: 'preferredBranchSurgery',
                valueReference: { reference: 'Location/12A7BA26-8E91-4AFE-A019-ADD6C735F058' },
              },
            ],
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-RegistrationDetails-1',
          },
        ],
        identifier: [
          {
            extension: [
              {
                url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-NHSNumberVerificationStatus-1',
                valueCodeableConcept: {
                  coding: [
                    {
                      system:
                        'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-NHSNumberVerificationStatus-1',
                      code: '01',
                      display: 'Number present and verified',
                    },
                  ],
                  text: 'Number present and verified',
                },
              },
            ],
            system: 'https://fhir.nhs.uk/Id/nhs-number',
            value: '9990553602',
          },
        ],
        name: [
          { use: 'official', family: 'Xxtestpatient-Thfc', given: ['Donotuse'], prefix: ['Mr'] },
        ],
        gender: 'male',
        birthDate: '1940-07-29',
        address: [
          {
            use: 'home',
            type: 'physical',
            line: [
              'C/O Nhs Digital Test Data Manager',
              'Solution Assurance 1 Trevelyan Sq',
              'Boar Lane',
            ],
            city: 'Leeds',
            district: 'West Yorkshire',
            postalCode: 'LS1 6AE',
          },
        ],
        generalPractitioner: [{ reference: 'Practitioner/68EC1C9E-4ED5-47C9-9E8E-B592ED84E3FC' }],
        managingOrganization: { reference: 'Organization/12A7BA26-8E91-4AFE-A019-ADD6C735F058' },
      },
    },
    {
      resource: {
        resourceType: 'MedicationRequest',
        id: 'EE6BACC2-7E1B-465C-BC26-3F3BA818A219',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-MedicationRequest-1',
          ],
        },
        extension: [
          {
            extension: [
              { url: 'statusReason', valueCodeableConcept: { text: 'sysmptoms disappeared' } },
              { url: 'statusChangeDate', valueDateTime: '2020-07-21T15:02:20.117+00:00' },
            ],
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-MedicationStatusReason-1',
          },
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-PrescriptionType-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-PrescriptionType-1',
                  code: 'acute',
                  display: 'Acute',
                },
              ],
            },
          },
        ],
        identifier: [
          { system: 'https://EMISWeb/L83015', value: 'EE6BACC2-7E1B-465C-BC26-3F3BA818A219' },
        ],
        groupIdentifier: { value: 'ee6bacc2-7e1b-465c-bc26-3f3ba818a219' },
        status: 'stopped',
        intent: 'plan',
        medicationReference: { reference: 'Medication/13DB670E-82CF-44F9-959C-A14E31180D40' },
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        authoredOn: '2020-07-21T14:55:09.55+00:00',
        recorder: { reference: 'Practitioner/68EC1C9E-4ED5-47C9-9E8E-B592ED84E3FC' },
        dosageInstruction: [
          { text: 'One Spray To Be Used In The Affected Ear(s) Three Times A Day' },
        ],
        dispenseRequest: {
          validityPeriod: { start: '2020-07-21', end: '2020-07-21' },
          quantity: { value: 60, unit: 'ml' },
          expectedSupplyDuration: {
            value: 28,
            unit: 'day',
            system: 'http://unitsofmeasure.org',
            code: 'd',
          },
        },
      },
    },
    {
      resource: {
        resourceType: 'MedicationRequest',
        id: 'CF17989A-ACA6-4E6D-83B2-58E58EF0BE4A',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-MedicationRequest-1',
          ],
        },
        extension: [
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-PrescriptionType-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-PrescriptionType-1',
                  code: 'acute',
                  display: 'Acute',
                },
              ],
            },
          },
        ],
        identifier: [
          { system: 'https://EMISWeb/L83015', value: 'CF17989A-ACA6-4E6D-83B2-58E58EF0BE4A' },
        ],
        groupIdentifier: { value: 'cf17989a-aca6-4e6d-83b2-58e58ef0be4a' },
        status: 'active',
        intent: 'plan',
        medicationReference: { reference: 'Medication/7FBFE91F-57CB-41CB-A6F5-625C275AEE2B' },
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        authoredOn: '2020-07-20T23:00:00+00:00',
        recorder: { reference: 'Practitioner/68EC1C9E-4ED5-47C9-9E8E-B592ED84E3FC' },
        dosageInstruction: [{ text: 'One To Be Taken Three Times A Day' }],
        dispenseRequest: {
          validityPeriod: { start: '2020-07-21' },
          quantity: { value: 15, unit: 'capsule' },
          expectedSupplyDuration: {
            value: 5,
            unit: 'day',
            system: 'http://unitsofmeasure.org',
            code: 'd',
          },
        },
        priorPrescription: {
          reference: 'MedicationRequest/CF17989A-ACA6-4E6D-83B2-58E58EF0BE4A-HD-1',
        },
      },
    },
    {
      resource: {
        resourceType: 'MedicationRequest',
        id: '3C2A7114-6572-4A7C-82BD-3618F1CC540F',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-MedicationRequest-1',
          ],
        },
        extension: [
          {
            extension: [
              { url: 'numberOfRepeatPrescriptionsAllowed', valueUnsignedInt: 3 },
              { url: 'numberOfRepeatPrescriptionsIssued', valueUnsignedInt: 0 },
            ],
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-MedicationRepeatInformation-1',
          },
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-PrescriptionType-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-PrescriptionType-1',
                  code: 'repeat-dispensing',
                  display: 'Repeat dispensing',
                },
              ],
            },
          },
        ],
        identifier: [
          { system: 'https://EMISWeb/L83015', value: '3C2A7114-6572-4A7C-82BD-3618F1CC540F' },
        ],
        groupIdentifier: { value: '3c2a7114-6572-4a7c-82bd-3618f1cc540f' },
        status: 'active',
        intent: 'plan',
        medicationReference: { reference: 'Medication/9CCAA461-6A2F-4165-BEFE-8EFA3062BC10' },
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        authoredOn: '2020-07-20T23:00:00+00:00',
        recorder: { reference: 'Practitioner/68EC1C9E-4ED5-47C9-9E8E-B592ED84E3FC' },
        dosageInstruction: [{ text: 'Two To Be Taken Each Day' }],
        dispenseRequest: {
          validityPeriod: { start: '2020-07-21' },
          quantity: { value: 112, unit: 'capsule' },
          expectedSupplyDuration: {
            value: 56,
            unit: 'day',
            system: 'http://unitsofmeasure.org',
            code: 'd',
          },
        },
        priorPrescription: {
          reference: 'MedicationRequest/3C2A7114-6572-4A7C-82BD-3618F1CC540F-HD-1',
        },
      },
    },
    {
      resource: {
        resourceType: 'MedicationRequest',
        id: '50B7258F-CEE4-4A76-A6E0-ACF04ADD296C',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-MedicationRequest-1',
          ],
        },
        extension: [
          {
            extension: [
              { url: 'numberOfRepeatPrescriptionsAllowed', valueUnsignedInt: 6 },
              { url: 'numberOfRepeatPrescriptionsIssued', valueUnsignedInt: 1 },
            ],
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-MedicationRepeatInformation-1',
          },
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-PrescriptionType-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-PrescriptionType-1',
                  code: 'repeat',
                  display: 'Repeat',
                },
              ],
            },
          },
        ],
        identifier: [
          { system: 'https://EMISWeb/L83015', value: '50B7258F-CEE4-4A76-A6E0-ACF04ADD296C' },
        ],
        groupIdentifier: { value: '50b7258f-cee4-4a76-a6e0-acf04add296c' },
        status: 'active',
        intent: 'plan',
        medicationReference: { reference: 'Medication/1D1D9F81-524F-4329-973D-4B1030D7568D' },
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        authoredOn: '2020-07-21T15:05:30.38+00:00',
        recorder: { reference: 'Practitioner/68EC1C9E-4ED5-47C9-9E8E-B592ED84E3FC' },
        dosageInstruction: [{ text: 'One To Be Taken Each Day' }],
        dispenseRequest: {
          validityPeriod: { start: '2020-07-21' },
          quantity: { value: 28, unit: 'capsule' },
          expectedSupplyDuration: {
            value: 28,
            unit: 'day',
            system: 'http://unitsofmeasure.org',
            code: 'd',
          },
        },
      },
    },
    {
      resource: {
        resourceType: 'MedicationRequest',
        id: '5A83C5EB-AD1A-42E2-99FC-0E2293C0252C',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-MedicationRequest-1',
          ],
        },
        extension: [
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-PrescriptionType-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-PrescriptionType-1',
                  code: 'repeat',
                  display: 'Repeat',
                },
              ],
            },
          },
        ],
        identifier: [
          { system: 'https://EMISWeb/L83015', value: '5A83C5EB-AD1A-42E2-99FC-0E2293C0252C' },
        ],
        basedOn: [{ reference: 'MedicationRequest/50B7258F-CEE4-4A76-A6E0-ACF04ADD296C' }],
        groupIdentifier: { value: '50b7258f-cee4-4a76-a6e0-acf04add296c' },
        status: 'completed',
        intent: 'order',
        medicationReference: { reference: 'Medication/1D1D9F81-524F-4329-973D-4B1030D7568D' },
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        authoredOn: '2020-07-23T09:24:37.463+00:00',
        recorder: { reference: 'Practitioner/68EC1C9E-4ED5-47C9-9E8E-B592ED84E3FC' },
        note: [
          {
            authorString: 'Patient Notes',
            text: 'Issue number 1 Come back in 2 months for a review',
          },
        ],
        dosageInstruction: [{ text: 'One To Be Taken Each Day' }],
        dispenseRequest: {
          validityPeriod: { start: '2020-07-23', end: '2020-08-20' },
          quantity: { value: 28, unit: 'capsule' },
          expectedSupplyDuration: {
            value: 28,
            unit: 'day',
            system: 'http://unitsofmeasure.org',
            code: 'd',
          },
        },
      },
    },
    {
      resource: {
        resourceType: 'MedicationRequest',
        id: 'CF17989A-ACA6-4E6D-83B2-58E58EF0BE4A-HD-1',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-MedicationRequest-1',
          ],
        },
        extension: [
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-PrescriptionType-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-PrescriptionType-1',
                  code: 'acute',
                  display: 'Acute',
                },
              ],
            },
          },
        ],
        identifier: [
          { system: 'https://EMISWeb/L83015', value: 'CF17989A-ACA6-4E6D-83B2-58E58EF0BE4A-HD-1' },
        ],
        groupIdentifier: { value: 'cf17989a-aca6-4e6d-83b2-58e58ef0be4a-hd-1' },
        status: 'completed',
        intent: 'plan',
        medicationReference: { reference: 'Medication/7FBFE91F-57CB-41CB-A6F5-625C275AEE2B' },
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        authoredOn: '2020-07-21T15:07:35.21+00:00',
        recorder: { reference: 'Practitioner/68EC1C9E-4ED5-47C9-9E8E-B592ED84E3FC' },
        dosageInstruction: [{ text: 'One To Be Taken Three Times A Day' }],
        dispenseRequest: {
          validityPeriod: { start: '2020-07-21', end: '2020-07-21' },
          quantity: { value: 15, unit: 'capsule' },
          expectedSupplyDuration: {
            value: 5,
            unit: 'day',
            system: 'http://unitsofmeasure.org',
            code: 'd',
          },
        },
      },
    },
    {
      resource: {
        resourceType: 'MedicationRequest',
        id: '3C2A7114-6572-4A7C-82BD-3618F1CC540F-HD-1',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-MedicationRequest-1',
          ],
        },
        extension: [
          {
            extension: [
              { url: 'numberOfRepeatPrescriptionsAllowed', valueUnsignedInt: 3 },
              { url: 'numberOfRepeatPrescriptionsIssued', valueUnsignedInt: 0 },
            ],
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-MedicationRepeatInformation-1',
          },
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-PrescriptionType-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-PrescriptionType-1',
                  code: 'repeat-dispensing',
                  display: 'Repeat dispensing',
                },
              ],
            },
          },
        ],
        identifier: [
          { system: 'https://EMISWeb/L83015', value: '3C2A7114-6572-4A7C-82BD-3618F1CC540F-HD-1' },
        ],
        groupIdentifier: { value: '3c2a7114-6572-4a7c-82bd-3618f1cc540f-hd-1' },
        status: 'completed',
        intent: 'plan',
        medicationReference: { reference: 'Medication/9CCAA461-6A2F-4165-BEFE-8EFA3062BC10' },
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        authoredOn: '2020-07-21T15:06:52.24+00:00',
        recorder: { reference: 'Practitioner/68EC1C9E-4ED5-47C9-9E8E-B592ED84E3FC' },
        dosageInstruction: [{ text: 'Two To Be Taken Each Day' }],
        dispenseRequest: {
          validityPeriod: { start: '2020-07-21', end: '2020-07-21' },
          quantity: { value: 112, unit: 'capsule' },
          expectedSupplyDuration: {
            value: 56,
            unit: 'day',
            system: 'http://unitsofmeasure.org',
            code: 'd',
          },
        },
      },
    },
    {
      resource: {
        resourceType: 'MedicationRequest',
        id: 'E929E87C-E89A-4887-9A93-D9293E45B1F2',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-MedicationRequest-1',
          ],
        },
        extension: [
          {
            extension: [
              { url: 'numberOfRepeatPrescriptionsAllowed', valueUnsignedInt: 1 },
              { url: 'numberOfRepeatPrescriptionsIssued', valueUnsignedInt: 0 },
            ],
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-MedicationRepeatInformation-1',
          },
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-PrescriptionType-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-PrescriptionType-1',
                  code: 'repeat',
                  display: 'Repeat',
                },
              ],
            },
          },
        ],
        identifier: [
          { system: 'https://EMISWeb/L83015', value: 'E929E87C-E89A-4887-9A93-D9293E45B1F2' },
        ],
        groupIdentifier: { value: 'e929e87c-e89a-4887-9a93-d9293e45b1f2' },
        status: 'completed',
        intent: 'plan',
        medicationReference: { reference: 'Medication/D3A70D21-E851-4261-87A9-36F1B41BD1FB' },
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        authoredOn: '2020-07-22T07:47:58.77+00:00',
        recorder: { reference: 'Practitioner/68EC1C9E-4ED5-47C9-9E8E-B592ED84E3FC' },
        dosageInstruction: [{ text: 'One To Be Taken Each Day' }],
        dispenseRequest: {
          validityPeriod: { start: '2020-07-22', end: '2020-07-22' },
          quantity: { value: 7, unit: 'tablet' },
          expectedSupplyDuration: {
            value: 7,
            unit: 'day',
            system: 'http://unitsofmeasure.org',
            code: 'd',
          },
        },
      },
    },
    {
      resource: {
        resourceType: 'MedicationRequest',
        id: 'FDE36ABC-1E55-4242-80BA-8C850AD24F48',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-MedicationRequest-1',
          ],
        },
        extension: [
          {
            extension: [
              { url: 'numberOfRepeatPrescriptionsAllowed', valueUnsignedInt: 8 },
              { url: 'numberOfRepeatPrescriptionsIssued', valueUnsignedInt: 0 },
            ],
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-MedicationRepeatInformation-1',
          },
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-PrescriptionType-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-PrescriptionType-1',
                  code: 'repeat-dispensing',
                  display: 'Repeat dispensing',
                },
              ],
            },
          },
        ],
        identifier: [
          { system: 'https://EMISWeb/L83015', value: 'FDE36ABC-1E55-4242-80BA-8C850AD24F48' },
        ],
        groupIdentifier: { value: 'fde36abc-1e55-4242-80ba-8c850ad24f48' },
        status: 'active',
        intent: 'plan',
        medicationReference: { reference: 'Medication/BB73768F-5E66-4595-8E79-34089F0F7C2D' },
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        authoredOn: '2020-07-23T09:40:11.627+00:00',
        recorder: { reference: 'Practitioner/8E88F7AC-2D2C-4B4D-A04B-0EF465018795' },
        dosageInstruction: [{ text: 'One To Be Taken Each Day' }],
        dispenseRequest: {
          validityPeriod: { start: '2020-07-23' },
          quantity: { value: 7, unit: 'tablet' },
          expectedSupplyDuration: {
            value: 7,
            unit: 'day',
            system: 'http://unitsofmeasure.org',
            code: 'd',
          },
        },
      },
    },
    {
      resource: {
        resourceType: 'MedicationRequest',
        id: '0EB15DD3-FF9C-4C0B-B10A-830C1EA4111E-HD-1',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-MedicationRequest-1',
          ],
        },
        extension: [
          {
            extension: [
              { url: 'numberOfRepeatPrescriptionsAllowed', valueUnsignedInt: 8 },
              { url: 'numberOfRepeatPrescriptionsIssued', valueUnsignedInt: 8 },
            ],
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-MedicationRepeatInformation-1',
          },
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-PrescriptionType-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-PrescriptionType-1',
                  code: 'repeat-dispensing',
                  display: 'Repeat dispensing',
                },
              ],
            },
          },
        ],
        identifier: [
          { system: 'https://EMISWeb/L83015', value: '0EB15DD3-FF9C-4C0B-B10A-830C1EA4111E-HD-1' },
        ],
        groupIdentifier: { value: '0eb15dd3-ff9c-4c0b-b10a-830c1ea4111e-hd-1' },
        status: 'completed',
        intent: 'plan',
        medicationReference: { reference: 'Medication/1E80A7CA-9CC8-48FF-A13A-1DF597BB7EA4' },
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        authoredOn: '2020-07-23T09:37:23.88+00:00',
        recorder: { reference: 'Practitioner/8E88F7AC-2D2C-4B4D-A04B-0EF465018795' },
        dosageInstruction: [{ text: 'One To Be Taken Each Day' }],
        dispenseRequest: {
          validityPeriod: { start: '2020-07-23', end: '2020-09-10' },
          quantity: { value: 7, unit: 'tablet' },
          expectedSupplyDuration: {
            value: 7,
            unit: 'day',
            system: 'http://unitsofmeasure.org',
            code: 'd',
          },
        },
      },
    },
    {
      resource: {
        resourceType: 'MedicationRequest',
        id: '536E8466-FD04-4482-BB44-698253EAC5B2',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-MedicationRequest-1',
          ],
        },
        extension: [
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-PrescriptionType-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-PrescriptionType-1',
                  code: 'repeat-dispensing',
                  display: 'Repeat dispensing',
                },
              ],
            },
          },
        ],
        identifier: [
          { system: 'https://EMISWeb/L83015', value: '536E8466-FD04-4482-BB44-698253EAC5B2' },
        ],
        basedOn: [{ reference: 'MedicationRequest/0EB15DD3-FF9C-4C0B-B10A-830C1EA4111E-HD-1' }],
        groupIdentifier: { value: '0eb15dd3-ff9c-4c0b-b10a-830c1ea4111e-hd-1' },
        status: 'completed',
        intent: 'order',
        medicationReference: { reference: 'Medication/1E80A7CA-9CC8-48FF-A13A-1DF597BB7EA4' },
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        authoredOn: '2020-07-23T09:37:58.223+00:00',
        recorder: { reference: 'Practitioner/68EC1C9E-4ED5-47C9-9E8E-B592ED84E3FC' },
        dosageInstruction: [{ text: 'One To Be Taken Each Day' }],
        dispenseRequest: {
          validityPeriod: { start: '2020-07-23', end: '2020-07-30' },
          quantity: { value: 7, unit: 'tablet' },
          expectedSupplyDuration: {
            value: 7,
            unit: 'day',
            system: 'http://unitsofmeasure.org',
            code: 'd',
          },
        },
      },
    },
    {
      resource: {
        resourceType: 'MedicationRequest',
        id: '79A704D4-A893-4FC0-B295-C76CBF530706',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-MedicationRequest-1',
          ],
        },
        extension: [
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-PrescriptionType-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-PrescriptionType-1',
                  code: 'repeat-dispensing',
                  display: 'Repeat dispensing',
                },
              ],
            },
          },
        ],
        identifier: [
          { system: 'https://EMISWeb/L83015', value: '79A704D4-A893-4FC0-B295-C76CBF530706' },
        ],
        basedOn: [{ reference: 'MedicationRequest/0EB15DD3-FF9C-4C0B-B10A-830C1EA4111E-HD-1' }],
        groupIdentifier: { value: '0eb15dd3-ff9c-4c0b-b10a-830c1ea4111e-hd-1' },
        status: 'completed',
        intent: 'order',
        medicationReference: { reference: 'Medication/1E80A7CA-9CC8-48FF-A13A-1DF597BB7EA4' },
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        authoredOn: '2020-07-23T09:37:58.223+00:00',
        recorder: { reference: 'Practitioner/68EC1C9E-4ED5-47C9-9E8E-B592ED84E3FC' },
        dosageInstruction: [{ text: 'One To Be Taken Each Day' }],
        dispenseRequest: {
          validityPeriod: { start: '2020-07-30', end: '2020-08-06' },
          quantity: { value: 7, unit: 'tablet' },
          expectedSupplyDuration: {
            value: 7,
            unit: 'day',
            system: 'http://unitsofmeasure.org',
            code: 'd',
          },
        },
      },
    },
    {
      resource: {
        resourceType: 'MedicationRequest',
        id: '9F58B075-7FE6-4243-91A5-EF8BD88F53BB',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-MedicationRequest-1',
          ],
        },
        extension: [
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-PrescriptionType-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-PrescriptionType-1',
                  code: 'repeat-dispensing',
                  display: 'Repeat dispensing',
                },
              ],
            },
          },
        ],
        identifier: [
          { system: 'https://EMISWeb/L83015', value: '9F58B075-7FE6-4243-91A5-EF8BD88F53BB' },
        ],
        basedOn: [{ reference: 'MedicationRequest/0EB15DD3-FF9C-4C0B-B10A-830C1EA4111E-HD-1' }],
        groupIdentifier: { value: '0eb15dd3-ff9c-4c0b-b10a-830c1ea4111e-hd-1' },
        status: 'completed',
        intent: 'order',
        medicationReference: { reference: 'Medication/1E80A7CA-9CC8-48FF-A13A-1DF597BB7EA4' },
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        authoredOn: '2020-07-23T09:37:58.223+00:00',
        recorder: { reference: 'Practitioner/68EC1C9E-4ED5-47C9-9E8E-B592ED84E3FC' },
        dosageInstruction: [{ text: 'One To Be Taken Each Day' }],
        dispenseRequest: {
          validityPeriod: { start: '2020-08-06', end: '2020-08-13' },
          quantity: { value: 7, unit: 'tablet' },
          expectedSupplyDuration: {
            value: 7,
            unit: 'day',
            system: 'http://unitsofmeasure.org',
            code: 'd',
          },
        },
      },
    },
    {
      resource: {
        resourceType: 'MedicationRequest',
        id: '24386271-5CB7-4F7B-883A-71F4DA3EC10F',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-MedicationRequest-1',
          ],
        },
        extension: [
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-PrescriptionType-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-PrescriptionType-1',
                  code: 'repeat-dispensing',
                  display: 'Repeat dispensing',
                },
              ],
            },
          },
        ],
        identifier: [
          { system: 'https://EMISWeb/L83015', value: '24386271-5CB7-4F7B-883A-71F4DA3EC10F' },
        ],
        basedOn: [{ reference: 'MedicationRequest/0EB15DD3-FF9C-4C0B-B10A-830C1EA4111E-HD-1' }],
        groupIdentifier: { value: '0eb15dd3-ff9c-4c0b-b10a-830c1ea4111e-hd-1' },
        status: 'completed',
        intent: 'order',
        medicationReference: { reference: 'Medication/1E80A7CA-9CC8-48FF-A13A-1DF597BB7EA4' },
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        authoredOn: '2020-07-23T09:37:58.223+00:00',
        recorder: { reference: 'Practitioner/68EC1C9E-4ED5-47C9-9E8E-B592ED84E3FC' },
        dosageInstruction: [{ text: 'One To Be Taken Each Day' }],
        dispenseRequest: {
          validityPeriod: { start: '2020-08-13', end: '2020-08-20' },
          quantity: { value: 7, unit: 'tablet' },
          expectedSupplyDuration: {
            value: 7,
            unit: 'day',
            system: 'http://unitsofmeasure.org',
            code: 'd',
          },
        },
      },
    },
    {
      resource: {
        resourceType: 'MedicationRequest',
        id: '357D07E4-29A3-4BC0-9BF2-900D2239BACB',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-MedicationRequest-1',
          ],
        },
        extension: [
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-PrescriptionType-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-PrescriptionType-1',
                  code: 'repeat-dispensing',
                  display: 'Repeat dispensing',
                },
              ],
            },
          },
        ],
        identifier: [
          { system: 'https://EMISWeb/L83015', value: '357D07E4-29A3-4BC0-9BF2-900D2239BACB' },
        ],
        basedOn: [{ reference: 'MedicationRequest/0EB15DD3-FF9C-4C0B-B10A-830C1EA4111E-HD-1' }],
        groupIdentifier: { value: '0eb15dd3-ff9c-4c0b-b10a-830c1ea4111e-hd-1' },
        status: 'completed',
        intent: 'order',
        medicationReference: { reference: 'Medication/1E80A7CA-9CC8-48FF-A13A-1DF597BB7EA4' },
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        authoredOn: '2020-07-23T09:37:58.223+00:00',
        recorder: { reference: 'Practitioner/68EC1C9E-4ED5-47C9-9E8E-B592ED84E3FC' },
        dosageInstruction: [{ text: 'One To Be Taken Each Day' }],
        dispenseRequest: {
          validityPeriod: { start: '2020-08-20', end: '2020-08-27' },
          quantity: { value: 7, unit: 'tablet' },
          expectedSupplyDuration: {
            value: 7,
            unit: 'day',
            system: 'http://unitsofmeasure.org',
            code: 'd',
          },
        },
      },
    },
    {
      resource: {
        resourceType: 'MedicationRequest',
        id: 'FFD49CF8-E668-4FF2-811D-3F7FD33FC20A',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-MedicationRequest-1',
          ],
        },
        extension: [
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-PrescriptionType-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-PrescriptionType-1',
                  code: 'repeat-dispensing',
                  display: 'Repeat dispensing',
                },
              ],
            },
          },
        ],
        identifier: [
          { system: 'https://EMISWeb/L83015', value: 'FFD49CF8-E668-4FF2-811D-3F7FD33FC20A' },
        ],
        basedOn: [{ reference: 'MedicationRequest/0EB15DD3-FF9C-4C0B-B10A-830C1EA4111E-HD-1' }],
        groupIdentifier: { value: '0eb15dd3-ff9c-4c0b-b10a-830c1ea4111e-hd-1' },
        status: 'completed',
        intent: 'order',
        medicationReference: { reference: 'Medication/1E80A7CA-9CC8-48FF-A13A-1DF597BB7EA4' },
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        authoredOn: '2020-07-23T09:37:58.223+00:00',
        recorder: { reference: 'Practitioner/68EC1C9E-4ED5-47C9-9E8E-B592ED84E3FC' },
        dosageInstruction: [{ text: 'One To Be Taken Each Day' }],
        dispenseRequest: {
          validityPeriod: { start: '2020-08-27', end: '2020-09-03' },
          quantity: { value: 7, unit: 'tablet' },
          expectedSupplyDuration: {
            value: 7,
            unit: 'day',
            system: 'http://unitsofmeasure.org',
            code: 'd',
          },
        },
      },
    },
    {
      resource: {
        resourceType: 'MedicationRequest',
        id: 'F4FDC333-9BE8-4F49-B8E2-F34A4B8F3155',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-MedicationRequest-1',
          ],
        },
        extension: [
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-PrescriptionType-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-PrescriptionType-1',
                  code: 'repeat-dispensing',
                  display: 'Repeat dispensing',
                },
              ],
            },
          },
        ],
        identifier: [
          { system: 'https://EMISWeb/L83015', value: 'F4FDC333-9BE8-4F49-B8E2-F34A4B8F3155' },
        ],
        basedOn: [{ reference: 'MedicationRequest/0EB15DD3-FF9C-4C0B-B10A-830C1EA4111E-HD-1' }],
        groupIdentifier: { value: '0eb15dd3-ff9c-4c0b-b10a-830c1ea4111e-hd-1' },
        status: 'completed',
        intent: 'order',
        medicationReference: { reference: 'Medication/1E80A7CA-9CC8-48FF-A13A-1DF597BB7EA4' },
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        authoredOn: '2020-07-23T09:37:58.223+00:00',
        recorder: { reference: 'Practitioner/68EC1C9E-4ED5-47C9-9E8E-B592ED84E3FC' },
        dosageInstruction: [{ text: 'One To Be Taken Each Day' }],
        dispenseRequest: {
          validityPeriod: { start: '2020-09-03', end: '2020-09-10' },
          quantity: { value: 7, unit: 'tablet' },
          expectedSupplyDuration: {
            value: 7,
            unit: 'day',
            system: 'http://unitsofmeasure.org',
            code: 'd',
          },
        },
      },
    },
    {
      resource: {
        resourceType: 'MedicationRequest',
        id: '6CE63FFB-F394-4686-B886-935902D5BEAB',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-MedicationRequest-1',
          ],
        },
        extension: [
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-PrescriptionType-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-PrescriptionType-1',
                  code: 'repeat-dispensing',
                  display: 'Repeat dispensing',
                },
              ],
            },
          },
        ],
        identifier: [
          { system: 'https://EMISWeb/L83015', value: '6CE63FFB-F394-4686-B886-935902D5BEAB' },
        ],
        basedOn: [{ reference: 'MedicationRequest/0EB15DD3-FF9C-4C0B-B10A-830C1EA4111E-HD-1' }],
        groupIdentifier: { value: '0eb15dd3-ff9c-4c0b-b10a-830c1ea4111e-hd-1' },
        status: 'completed',
        intent: 'order',
        medicationReference: { reference: 'Medication/1E80A7CA-9CC8-48FF-A13A-1DF597BB7EA4' },
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        authoredOn: '2020-07-23T09:37:58.223+00:00',
        recorder: { reference: 'Practitioner/68EC1C9E-4ED5-47C9-9E8E-B592ED84E3FC' },
        dosageInstruction: [{ text: 'One To Be Taken Each Day' }],
        dispenseRequest: {
          validityPeriod: { start: '2020-09-10', end: '2020-09-17' },
          quantity: { value: 7, unit: 'tablet' },
          expectedSupplyDuration: {
            value: 7,
            unit: 'day',
            system: 'http://unitsofmeasure.org',
            code: 'd',
          },
        },
      },
    },
    {
      resource: {
        resourceType: 'MedicationRequest',
        id: 'BB435344-A10A-4EE8-93BE-D2C5DFC59105',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-MedicationRequest-1',
          ],
        },
        extension: [
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-PrescriptionType-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-PrescriptionType-1',
                  code: 'acute',
                  display: 'Acute',
                },
              ],
            },
          },
        ],
        identifier: [
          { system: 'https://EMISWeb/L83015', value: 'BB435344-A10A-4EE8-93BE-D2C5DFC59105' },
        ],
        groupIdentifier: { value: 'bb435344-a10a-4ee8-93be-d2c5dfc59105' },
        status: 'completed',
        intent: 'plan',
        medicationReference: { reference: 'Medication/AC13ED29-9A3E-42CC-87EE-FF2F5B9D2D4B' },
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        authoredOn: '2020-07-30T08:52:33.27+00:00',
        recorder: { reference: 'Practitioner/8E88F7AC-2D2C-4B4D-A04B-0EF465018795' },
        dosageInstruction: [{ text: 'Apply Thinly At Night' }],
        dispenseRequest: {
          validityPeriod: { start: '2020-07-30', end: '2020-09-11' },
          quantity: { value: 30, unit: 'gram' },
          expectedSupplyDuration: {
            value: 28,
            unit: 'day',
            system: 'http://unitsofmeasure.org',
            code: 'd',
          },
        },
        priorPrescription: {
          reference: 'MedicationRequest/BB435344-A10A-4EE8-93BE-D2C5DFC59105-FI-1',
        },
      },
    },
    {
      resource: {
        resourceType: 'MedicationRequest',
        id: '3AC77D58-C910-43A2-B474-7BA3E4A58F75',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-MedicationRequest-1',
          ],
        },
        extension: [
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-PrescriptionType-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-PrescriptionType-1',
                  code: 'acute',
                  display: 'Acute',
                },
              ],
            },
          },
        ],
        identifier: [
          { system: 'https://EMISWeb/L83015', value: '3AC77D58-C910-43A2-B474-7BA3E4A58F75' },
        ],
        basedOn: [{ reference: 'MedicationRequest/BB435344-A10A-4EE8-93BE-D2C5DFC59105' }],
        groupIdentifier: { value: 'bb435344-a10a-4ee8-93be-d2c5dfc59105' },
        status: 'completed',
        intent: 'order',
        medicationReference: { reference: 'Medication/AC13ED29-9A3E-42CC-87EE-FF2F5B9D2D4B' },
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        authoredOn: '2020-07-30T08:52:33.27+00:00',
        recorder: { reference: 'Practitioner/68EC1C9E-4ED5-47C9-9E8E-B592ED84E3FC' },
        note: [
          {
            authorString: 'Patient Notes',
            text: 'Issue number 1 issue sparingly and come in for meds review please',
          },
        ],
        dosageInstruction: [{ text: 'Apply Thinly At Night' }],
        dispenseRequest: {
          validityPeriod: { start: '2020-07-30', end: '2020-08-27' },
          quantity: { value: 30, unit: 'gram' },
          expectedSupplyDuration: {
            value: 28,
            unit: 'day',
            system: 'http://unitsofmeasure.org',
            code: 'd',
          },
        },
      },
    },
    {
      resource: {
        resourceType: 'MedicationRequest',
        id: 'BB435344-A10A-4EE8-93BE-D2C5DFC59105-FI-1',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-MedicationRequest-1',
          ],
        },
        extension: [
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-PrescriptionType-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-PrescriptionType-1',
                  code: 'acute',
                  display: 'Acute',
                },
              ],
            },
          },
        ],
        identifier: [
          { system: 'https://EMISWeb/L83015', value: 'BB435344-A10A-4EE8-93BE-D2C5DFC59105-FI-1' },
        ],
        groupIdentifier: { value: 'bb435344-a10a-4ee8-93be-d2c5dfc59105-fi-1' },
        status: 'completed',
        intent: 'plan',
        medicationReference: { reference: 'Medication/AC13ED29-9A3E-42CC-87EE-FF2F5B9D2D4B' },
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        authoredOn: '2020-07-30T08:47:25.21+00:00',
        recorder: { reference: 'Practitioner/8E88F7AC-2D2C-4B4D-A04B-0EF465018795' },
        dosageInstruction: [{ text: 'Apply Thinly At Night' }],
        dispenseRequest: {
          validityPeriod: { start: '2020-07-30', end: '2020-07-30' },
          quantity: { value: 30, unit: 'gram' },
          expectedSupplyDuration: {
            value: 28,
            unit: 'day',
            system: 'http://unitsofmeasure.org',
            code: 'd',
          },
        },
      },
    },
    {
      resource: {
        resourceType: 'MedicationRequest',
        id: '4C5ABC2A-49A8-4A30-B1C3-1FD71CBACA34',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-MedicationRequest-1',
          ],
        },
        extension: [
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-PrescriptionType-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-PrescriptionType-1',
                  code: 'acute',
                  display: 'Acute',
                },
              ],
            },
          },
        ],
        identifier: [
          { system: 'https://EMISWeb/L83015', value: '4C5ABC2A-49A8-4A30-B1C3-1FD71CBACA34' },
        ],
        basedOn: [{ reference: 'MedicationRequest/BB435344-A10A-4EE8-93BE-D2C5DFC59105-FI-1' }],
        groupIdentifier: { value: 'bb435344-a10a-4ee8-93be-d2c5dfc59105-fi-1' },
        status: 'completed',
        intent: 'order',
        medicationReference: { reference: 'Medication/AC13ED29-9A3E-42CC-87EE-FF2F5B9D2D4B' },
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        authoredOn: '2020-07-30T08:50:12.33+00:00',
        recorder: { reference: 'Practitioner/68EC1C9E-4ED5-47C9-9E8E-B592ED84E3FC' },
        dosageInstruction: [{ text: 'Apply Thinly At Night' }],
        dispenseRequest: {
          validityPeriod: { start: '2020-07-30', end: '2020-08-27' },
          quantity: { value: 30, unit: 'gram' },
          expectedSupplyDuration: {
            value: 28,
            unit: 'day',
            system: 'http://unitsofmeasure.org',
            code: 'd',
          },
        },
      },
    },
    {
      resource: {
        resourceType: 'MedicationRequest',
        id: '0EB15DD3-FF9C-4C0B-B10A-830C1EA4111E',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-MedicationRequest-1',
          ],
        },
        extension: [
          {
            extension: [{ url: 'numberOfRepeatPrescriptionsIssued', valueUnsignedInt: 0 }],
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-MedicationRepeatInformation-1',
          },
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-PrescriptionType-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-PrescriptionType-1',
                  code: 'repeat-dispensing',
                  display: 'Repeat dispensing',
                },
              ],
            },
          },
        ],
        identifier: [
          { system: 'https://EMISWeb/L83015', value: '0EB15DD3-FF9C-4C0B-B10A-830C1EA4111E' },
        ],
        groupIdentifier: { value: '0eb15dd3-ff9c-4c0b-b10a-830c1ea4111e' },
        status: 'active',
        intent: 'plan',
        medicationReference: { reference: 'Medication/1E80A7CA-9CC8-48FF-A13A-1DF597BB7EA4' },
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        authoredOn: '2020-07-23T09:37:58.223+00:00',
        recorder: { reference: 'Practitioner/8E88F7AC-2D2C-4B4D-A04B-0EF465018795' },
        dosageInstruction: [{ text: 'Two To Be Taken Each Day' }],
        dispenseRequest: {
          validityPeriod: { start: '2020-09-10' },
          quantity: { value: 14, unit: 'tablet' },
          expectedSupplyDuration: {
            value: 7,
            unit: 'day',
            system: 'http://unitsofmeasure.org',
            code: 'd',
          },
        },
        priorPrescription: {
          reference: 'MedicationRequest/0EB15DD3-FF9C-4C0B-B10A-830C1EA4111E-HD-1',
        },
      },
    },
    {
      resource: {
        resourceType: 'MedicationRequest',
        id: 'BE3F5215-1BD6-4CA5-9146-323022BF2324',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-MedicationRequest-1',
          ],
        },
        extension: [
          {
            extension: [
              {
                url: 'statusReason',
                valueCodeableConcept: { text: 'Patient reports GI side effects with doxycycline' },
              },
              { url: 'statusChangeDate', valueDateTime: '2020-09-16T11:54:00.663+00:00' },
            ],
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-MedicationStatusReason-1',
          },
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-PrescriptionType-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-PrescriptionType-1',
                  code: 'acute',
                  display: 'Acute',
                },
              ],
            },
          },
        ],
        identifier: [
          { system: 'https://EMISWeb/L83015', value: 'BE3F5215-1BD6-4CA5-9146-323022BF2324' },
        ],
        groupIdentifier: { value: 'be3f5215-1bd6-4ca5-9146-323022bf2324' },
        status: 'stopped',
        intent: 'plan',
        medicationReference: { reference: 'Medication/9EE372BD-9D1A-4522-B75D-6BC0E8ACEFA7' },
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        authoredOn: '2020-09-16T11:52:30.237+00:00',
        recorder: { reference: 'Practitioner/8E88F7AC-2D2C-4B4D-A04B-0EF465018795' },
        dosageInstruction: [
          { text: 'Two To Be Taken On The First Day Then One To Be Taken Each Day' },
        ],
        dispenseRequest: {
          validityPeriod: { start: '2020-09-16', end: '2020-09-16' },
          quantity: { value: 8, unit: 'capsule' },
          expectedSupplyDuration: {
            value: 28,
            unit: 'day',
            system: 'http://unitsofmeasure.org',
            code: 'd',
          },
        },
      },
    },
    {
      resource: {
        resourceType: 'MedicationRequest',
        id: '3A9C5CAF-9C57-4ED4-BD2E-9312E3DB01A7',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-MedicationRequest-1',
          ],
        },
        extension: [
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-PrescriptionType-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-PrescriptionType-1',
                  code: 'acute',
                  display: 'Acute',
                },
              ],
            },
          },
        ],
        identifier: [
          { system: 'https://EMISWeb/L83015', value: '3A9C5CAF-9C57-4ED4-BD2E-9312E3DB01A7' },
        ],
        basedOn: [{ reference: 'MedicationRequest/BE3F5215-1BD6-4CA5-9146-323022BF2324' }],
        groupIdentifier: { value: 'be3f5215-1bd6-4ca5-9146-323022bf2324' },
        status: 'stopped',
        intent: 'order',
        medicationReference: { reference: 'Medication/9EE372BD-9D1A-4522-B75D-6BC0E8ACEFA7' },
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        authoredOn: '2020-09-16T11:53:10.817+00:00',
        recorder: { reference: 'Practitioner/68EC1C9E-4ED5-47C9-9E8E-B592ED84E3FC' },
        note: [
          {
            text: "Cancellation Reason: At the Patient's Request (GI issues with Doxy), Cancellation Date: 2020-09-16",
          },
        ],
        dosageInstruction: [
          { text: 'Two To Be Taken On The First Day Then One To Be Taken Each Day' },
        ],
        dispenseRequest: {
          validityPeriod: { start: '2020-09-16', end: '2020-09-16' },
          quantity: { value: 8, unit: 'capsule' },
          expectedSupplyDuration: {
            value: 28,
            unit: 'day',
            system: 'http://unitsofmeasure.org',
            code: 'd',
          },
        },
      },
    },
    {
      resource: {
        resourceType: 'AllergyIntolerance',
        id: '3921CB55-7939-47C9-8370-401B18A936BA',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-AllergyIntolerance-1',
          ],
        },
        identifier: [
          { system: 'https://EMISWeb/L83015', value: '3921CB55-7939-47C9-8370-401B18A936BA' },
        ],
        clinicalStatus: 'active',
        verificationStatus: 'unconfirmed',
        category: ['medication'],
        code: {
          coding: [
            {
              extension: [
                {
                  extension: [
                    { url: 'descriptionId', valueId: '1603081000006117' },
                    {
                      url: 'descriptionDisplay',
                      valueString: 'Adverse reaction to Beconase Hayfever',
                    },
                  ],
                  url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-coding-sctdescid',
                },
              ],
              system: 'http://snomed.info/sct',
              code: '1603081000006101',
              display: 'Adverse reaction to Beconase Hayfever',
              userSelected: true,
            },
          ],
        },
        patient: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        onsetDateTime: '2020-03-01',
        assertedDate: '2020-07-22T07:51:32.623+00:00',
        recorder: { reference: 'Practitioner/D4041382-0745-460A-8664-A3F0FDD297C9' },
        note: [{ text: 'Significance : Minor - Episodicity : First' }],
      },
    },
    {
      resource: {
        resourceType: 'AllergyIntolerance',
        id: '6B0DBD3A-87AF-48C3-A27E-9B85E185BC7C',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-AllergyIntolerance-1',
          ],
        },
        identifier: [
          { system: 'https://EMISWeb/L83015', value: '6B0DBD3A-87AF-48C3-A27E-9B85E185BC7C' },
        ],
        clinicalStatus: 'active',
        verificationStatus: 'unconfirmed',
        category: ['medication'],
        code: {
          coding: [
            {
              extension: [
                {
                  extension: [
                    { url: 'descriptionId', valueId: '1603081000006117' },
                    {
                      url: 'descriptionDisplay',
                      valueString: 'Adverse reaction to Beconase Hayfever',
                    },
                  ],
                  url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-coding-sctdescid',
                },
              ],
              system: 'http://snomed.info/sct',
              code: '1603081000006101',
              display: 'Adverse reaction to Beconase Hayfever',
              userSelected: true,
            },
          ],
        },
        patient: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        onsetDateTime: '2020-07-21',
        assertedDate: '2020-07-21T15:08:13.797+00:00',
        recorder: { reference: 'Practitioner/D4041382-0745-460A-8664-A3F0FDD297C9' },
        note: [{ text: 'Significance : Minor - Episodicity : New' }],
      },
    },
    {
      resource: {
        resourceType: 'AllergyIntolerance',
        id: 'CB78C815-940C-495D-AD67-60593A809391',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-AllergyIntolerance-1',
          ],
        },
        identifier: [
          { system: 'https://EMISWeb/L83015', value: 'CB78C815-940C-495D-AD67-60593A809391' },
        ],
        clinicalStatus: 'active',
        verificationStatus: 'unconfirmed',
        category: ['medication'],
        code: {
          coding: [
            {
              extension: [
                {
                  extension: [
                    { url: 'descriptionId', valueId: '1603081000006117' },
                    {
                      url: 'descriptionDisplay',
                      valueString: 'Adverse reaction to Beconase Hayfever',
                    },
                  ],
                  url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-coding-sctdescid',
                },
              ],
              system: 'http://snomed.info/sct',
              code: '1603081000006101',
              display: 'Adverse reaction to Beconase Hayfever',
              userSelected: true,
            },
          ],
        },
        patient: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        onsetDateTime: '2020-07-22T07:50:00+00:00',
        assertedDate: '2020-07-22T07:50:49.607+00:00',
        recorder: { reference: 'Practitioner/D4041382-0745-460A-8664-A3F0FDD297C9' },
      },
    },
    {
      resource: {
        resourceType: 'AllergyIntolerance',
        id: '382531BA-2321-474F-8F00-D5A50A6254F7',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-AllergyIntolerance-1',
          ],
        },
        identifier: [
          { system: 'https://EMISWeb/L83015', value: '382531BA-2321-474F-8F00-D5A50A6254F7' },
        ],
        clinicalStatus: 'active',
        verificationStatus: 'unconfirmed',
        category: ['environment'],
        code: {
          coding: [
            {
              extension: [
                {
                  extension: [
                    { url: 'descriptionId', valueId: '2578488010' },
                    { url: 'descriptionDisplay', valueString: 'Allergy to dog dander' },
                  ],
                  url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-coding-sctdescid',
                },
              ],
              system: 'http://snomed.info/sct',
              code: '419271008',
              display: 'Allergy to dog dander',
              userSelected: true,
            },
          ],
        },
        patient: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        onsetDateTime: '2020-07-23',
        assertedDate: '2020-07-23T09:55:43.183+00:00',
        recorder: { reference: 'Practitioner/8E88F7AC-2D2C-4B4D-A04B-0EF465018795' },
        note: [{ text: 'Significance : Minor - Episodicity : First' }],
      },
    },
    {
      resource: {
        resourceType: 'AllergyIntolerance',
        id: '4AB6E03B-C87F-4426-9388-99BCC475C77C',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-AllergyIntolerance-1',
          ],
        },
        identifier: [
          { system: 'https://EMISWeb/L83015', value: '4AB6E03B-C87F-4426-9388-99BCC475C77C' },
        ],
        clinicalStatus: 'active',
        verificationStatus: 'unconfirmed',
        category: ['medication'],
        code: {
          coding: [
            {
              extension: [
                {
                  extension: [
                    { url: 'descriptionId', valueId: '1027501000006113' },
                    {
                      url: 'descriptionDisplay',
                      valueString: 'Adverse reaction to Phenoxymethylpenicillin',
                    },
                  ],
                  url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-coding-sctdescid',
                },
              ],
              system: 'http://snomed.info/sct',
              code: '1027501000006109',
              display: 'Adverse reaction to Phenoxymethylpenicillin',
              userSelected: true,
            },
          ],
        },
        patient: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        onsetDateTime: '2020-07-23',
        assertedDate: '2020-07-23T09:55:13.137+00:00',
        recorder: { reference: 'Practitioner/8E88F7AC-2D2C-4B4D-A04B-0EF465018795' },
      },
    },
    {
      resource: {
        resourceType: 'AllergyIntolerance',
        id: 'D4B6856C-5561-438E-B7C6-FB6178A13D98',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-AllergyIntolerance-1',
          ],
        },
        identifier: [
          { system: 'https://EMISWeb/L83015', value: 'D4B6856C-5561-438E-B7C6-FB6178A13D98' },
        ],
        clinicalStatus: 'active',
        verificationStatus: 'unconfirmed',
        category: ['medication'],
        code: {
          coding: [
            {
              extension: [
                {
                  extension: [
                    { url: 'descriptionId', valueId: '1061221000006116' },
                    {
                      url: 'descriptionDisplay',
                      valueString: 'Adverse reaction to Clarithromycin',
                    },
                  ],
                  url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-coding-sctdescid',
                },
              ],
              system: 'http://snomed.info/sct',
              code: '1061221000006100',
              display: 'Adverse reaction to Clarithromycin',
              userSelected: true,
            },
          ],
        },
        patient: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        onsetDateTime: '2020-07-23',
        assertedDate: '2020-07-23T09:49:21.24+00:00',
        recorder: { reference: 'Practitioner/8E88F7AC-2D2C-4B4D-A04B-0EF465018795' },
        note: [{ text: 'Significance : Major - Episodicity : First - rash and vomitting' }],
      },
    },
    {
      resource: {
        resourceType: 'AllergyIntolerance',
        id: '72B02065-F876-443B-8607-561E9823CBA7',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-AllergyIntolerance-1',
          ],
        },
        identifier: [
          { system: 'https://EMISWeb/L83015', value: '72B02065-F876-443B-8607-561E9823CBA7' },
        ],
        clinicalStatus: 'active',
        verificationStatus: 'unconfirmed',
        category: ['medication'],
        code: {
          coding: [
            {
              extension: [
                {
                  extension: [
                    { url: 'descriptionId', valueId: '1060611000006115' },
                    { url: 'descriptionDisplay', valueString: 'Adverse reaction to Ramipril' },
                  ],
                  url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-coding-sctdescid',
                },
              ],
              system: 'http://snomed.info/sct',
              code: '1060611000006104',
              display: 'Adverse reaction to Ramipril',
              userSelected: true,
            },
          ],
        },
        patient: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        onsetDateTime: '2020-07-24',
        assertedDate: '2020-07-24T12:00:37.533+00:00',
        recorder: { reference: 'Practitioner/A51DBB94-8E75-4510-80D5-D55AEEBE1796' },
        note: [{ text: 'dizziness' }],
      },
    },
    {
      resource: {
        resourceType: 'AllergyIntolerance',
        id: '6F921833-58F4-4C96-89C1-199D02BB925A',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-AllergyIntolerance-1',
          ],
        },
        identifier: [
          { system: 'https://EMISWeb/L83015', value: '6F921833-58F4-4C96-89C1-199D02BB925A' },
        ],
        clinicalStatus: 'active',
        verificationStatus: 'unconfirmed',
        category: ['medication'],
        code: {
          coding: [
            {
              extension: [
                {
                  extension: [
                    { url: 'descriptionId', valueId: '433120015' },
                    { url: 'descriptionDisplay', valueString: 'Penicillin adverse reaction' },
                  ],
                  url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-coding-sctdescid',
                },
              ],
              system: 'http://snomed.info/sct',
              code: '292954005',
              display: 'Penicillin adverse reaction',
              userSelected: true,
            },
          ],
        },
        patient: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        onsetDateTime: '2020-07-30',
        assertedDate: '2020-07-30T08:46:06.32+00:00',
        recorder: { reference: 'Practitioner/8E88F7AC-2D2C-4B4D-A04B-0EF465018795' },
        note: [{ text: 'Significance : Major - Episodicity : First - rash and fever' }],
      },
    },
    {
      resource: {
        resourceType: 'AllergyIntolerance',
        id: 'C0FC11DC-47FB-4974-9F20-21FECC2B2C10',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-AllergyIntolerance-1',
          ],
        },
        identifier: [
          { system: 'https://EMISWeb/L83015', value: 'C0FC11DC-47FB-4974-9F20-21FECC2B2C10' },
        ],
        clinicalStatus: 'active',
        verificationStatus: 'unconfirmed',
        category: ['environment'],
        code: {
          coding: [
            {
              extension: [
                {
                  extension: [
                    { url: 'descriptionId', valueId: '1800751000000117' },
                    { url: 'descriptionDisplay', valueString: 'Gluten intolerance' },
                  ],
                  url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-coding-sctdescid',
                },
              ],
              system: 'http://snomed.info/sct',
              code: '441831003',
              display: 'Gluten sensitivity',
              userSelected: true,
            },
          ],
        },
        patient: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        onsetDateTime: '2020-09-16',
        assertedDate: '2020-09-16T11:51:38.263+00:00',
        recorder: { reference: 'Practitioner/8E88F7AC-2D2C-4B4D-A04B-0EF465018795' },
        note: [
          {
            text: 'Significance : Major - Episodicity : First - Not coeliac but struggles with gluten',
          },
        ],
      },
    },
    {
      resource: {
        resourceType: 'MedicationStatement',
        id: 'EE6BACC2-7E1B-465C-BC26-3F3BA818A219-MS',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-MedicationStatement-1',
          ],
        },
        extension: [
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-PrescribingAgency-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-PrescribingAgency-1',
                  code: 'prescribed-at-gp-practice',
                  display: 'Prescribed at GP practice',
                },
              ],
            },
          },
        ],
        identifier: [
          { system: 'https://EMISWeb/L83015', value: '4C1F8470-0292-63A7-AE28-EBCDFBAF177E' },
        ],
        basedOn: [{ reference: 'MedicationRequest/EE6BACC2-7E1B-465C-BC26-3F3BA818A219' }],
        status: 'stopped',
        medicationReference: { reference: 'Medication/13DB670E-82CF-44F9-959C-A14E31180D40' },
        effectivePeriod: { start: '2020-07-21', end: '2020-07-21' },
        dateAsserted: '2020-07-21T14:55:09.55+00:00',
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        taken: 'unk',
        dosage: [{ text: 'One Spray To Be Used In The Affected Ear(s) Three Times A Day' }],
      },
    },
    {
      resource: {
        resourceType: 'MedicationStatement',
        id: 'CF17989A-ACA6-4E6D-83B2-58E58EF0BE4A-MS',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-MedicationStatement-1',
          ],
        },
        extension: [
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-PrescribingAgency-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-PrescribingAgency-1',
                  code: 'prescribed-at-gp-practice',
                  display: 'Prescribed at GP practice',
                },
              ],
            },
          },
        ],
        identifier: [
          { system: 'https://EMISWeb/L83015', value: '3FA38403-0BF5-6FE7-627E-972ACD032666' },
        ],
        basedOn: [{ reference: 'MedicationRequest/CF17989A-ACA6-4E6D-83B2-58E58EF0BE4A' }],
        status: 'active',
        medicationReference: { reference: 'Medication/7FBFE91F-57CB-41CB-A6F5-625C275AEE2B' },
        effectivePeriod: { start: '2020-07-21' },
        dateAsserted: '2020-07-20T23:00:00+00:00',
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        taken: 'unk',
        dosage: [{ text: 'One To Be Taken Three Times A Day' }],
      },
    },
    {
      resource: {
        resourceType: 'MedicationStatement',
        id: '3C2A7114-6572-4A7C-82BD-3618F1CC540F-MS',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-MedicationStatement-1',
          ],
        },
        extension: [
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-PrescribingAgency-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-PrescribingAgency-1',
                  code: 'prescribed-at-gp-practice',
                  display: 'Prescribed at GP practice',
                },
              ],
            },
          },
        ],
        identifier: [
          { system: 'https://EMISWeb/L83015', value: 'EFCAC407-FDA8-BF25-AE6A-DC14AB2DCF71' },
        ],
        basedOn: [{ reference: 'MedicationRequest/3C2A7114-6572-4A7C-82BD-3618F1CC540F' }],
        status: 'active',
        medicationReference: { reference: 'Medication/9CCAA461-6A2F-4165-BEFE-8EFA3062BC10' },
        effectivePeriod: { start: '2020-07-21' },
        dateAsserted: '2020-07-20T23:00:00+00:00',
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        taken: 'unk',
        dosage: [{ text: 'Two To Be Taken Each Day' }],
      },
    },
    {
      resource: {
        resourceType: 'MedicationStatement',
        id: '50B7258F-CEE4-4A76-A6E0-ACF04ADD296C-MS',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-MedicationStatement-1',
          ],
        },
        extension: [
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-PrescribingAgency-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-PrescribingAgency-1',
                  code: 'prescribed-at-gp-practice',
                  display: 'Prescribed at GP practice',
                },
              ],
            },
          },
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-MedicationStatementLastIssueDate-1',
            valueDateTime: '2020-07-22T23:00:00+00:00',
          },
        ],
        identifier: [
          { system: 'https://EMISWeb/L83015', value: 'CC85DD1B-680B-8D71-EB65-B488402DE686' },
        ],
        basedOn: [{ reference: 'MedicationRequest/50B7258F-CEE4-4A76-A6E0-ACF04ADD296C' }],
        status: 'active',
        medicationReference: { reference: 'Medication/1D1D9F81-524F-4329-973D-4B1030D7568D' },
        effectivePeriod: { start: '2020-07-21' },
        dateAsserted: '2020-07-21T15:05:30.38+00:00',
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        taken: 'unk',
        note: [
          {
            authorString: 'Patient Notes',
            text: 'Issue number 1 Come back in 2 months for a review',
          },
        ],
        dosage: [{ text: 'One To Be Taken Each Day' }],
      },
    },
    {
      resource: {
        resourceType: 'MedicationStatement',
        id: 'CF17989A-ACA6-4E6D-83B2-58E58EF0BE4A-HD-1-MS',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-MedicationStatement-1',
          ],
        },
        extension: [
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-PrescribingAgency-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-PrescribingAgency-1',
                  code: 'prescribed-at-gp-practice',
                  display: 'Prescribed at GP practice',
                },
              ],
            },
          },
        ],
        identifier: [
          { system: 'https://EMISWeb/L83015', value: 'A3CA685C-27D0-5AA5-C40C-12FD6F8D7567' },
        ],
        basedOn: [{ reference: 'MedicationRequest/CF17989A-ACA6-4E6D-83B2-58E58EF0BE4A-HD-1' }],
        status: 'completed',
        medicationReference: { reference: 'Medication/7FBFE91F-57CB-41CB-A6F5-625C275AEE2B' },
        effectivePeriod: { start: '2020-07-21', end: '2020-07-21' },
        dateAsserted: '2020-07-21T15:07:35.21+00:00',
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        taken: 'unk',
        dosage: [{ text: 'One To Be Taken Three Times A Day' }],
      },
    },
    {
      resource: {
        resourceType: 'MedicationStatement',
        id: '3C2A7114-6572-4A7C-82BD-3618F1CC540F-HD-1-MS',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-MedicationStatement-1',
          ],
        },
        extension: [
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-PrescribingAgency-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-PrescribingAgency-1',
                  code: 'prescribed-at-gp-practice',
                  display: 'Prescribed at GP practice',
                },
              ],
            },
          },
        ],
        identifier: [
          { system: 'https://EMISWeb/L83015', value: '0090003B-3702-E6E1-1793-71B608740FCD' },
        ],
        basedOn: [{ reference: 'MedicationRequest/3C2A7114-6572-4A7C-82BD-3618F1CC540F-HD-1' }],
        status: 'completed',
        medicationReference: { reference: 'Medication/9CCAA461-6A2F-4165-BEFE-8EFA3062BC10' },
        effectivePeriod: { start: '2020-07-21', end: '2020-07-21' },
        dateAsserted: '2020-07-21T15:06:52.24+00:00',
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        taken: 'unk',
        dosage: [{ text: 'Two To Be Taken Each Day' }],
      },
    },
    {
      resource: {
        resourceType: 'MedicationStatement',
        id: 'E929E87C-E89A-4887-9A93-D9293E45B1F2-MS',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-MedicationStatement-1',
          ],
        },
        extension: [
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-PrescribingAgency-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-PrescribingAgency-1',
                  code: 'prescribed-at-gp-practice',
                  display: 'Prescribed at GP practice',
                },
              ],
            },
          },
        ],
        identifier: [
          { system: 'https://EMISWeb/L83015', value: '18351E96-F522-7EBF-ACB8-8139F846197D' },
        ],
        basedOn: [{ reference: 'MedicationRequest/E929E87C-E89A-4887-9A93-D9293E45B1F2' }],
        status: 'completed',
        medicationReference: { reference: 'Medication/D3A70D21-E851-4261-87A9-36F1B41BD1FB' },
        effectivePeriod: { start: '2020-07-22', end: '2020-07-22' },
        dateAsserted: '2020-07-22T07:47:58.77+00:00',
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        taken: 'unk',
        dosage: [{ text: 'One To Be Taken Each Day' }],
      },
    },
    {
      resource: {
        resourceType: 'MedicationStatement',
        id: 'FDE36ABC-1E55-4242-80BA-8C850AD24F48-MS',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-MedicationStatement-1',
          ],
        },
        extension: [
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-PrescribingAgency-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-PrescribingAgency-1',
                  code: 'prescribed-at-gp-practice',
                  display: 'Prescribed at GP practice',
                },
              ],
            },
          },
        ],
        identifier: [
          { system: 'https://EMISWeb/L83015', value: '38144E12-98CF-086F-9581-B8139AA740D8' },
        ],
        basedOn: [{ reference: 'MedicationRequest/FDE36ABC-1E55-4242-80BA-8C850AD24F48' }],
        status: 'active',
        medicationReference: { reference: 'Medication/BB73768F-5E66-4595-8E79-34089F0F7C2D' },
        effectivePeriod: { start: '2020-07-23' },
        dateAsserted: '2020-07-23T09:40:11.627+00:00',
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        taken: 'unk',
        dosage: [{ text: 'One To Be Taken Each Day' }],
      },
    },
    {
      resource: {
        resourceType: 'MedicationStatement',
        id: '0EB15DD3-FF9C-4C0B-B10A-830C1EA4111E-HD-1-MS',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-MedicationStatement-1',
          ],
        },
        extension: [
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-PrescribingAgency-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-PrescribingAgency-1',
                  code: 'prescribed-by-another-organisation',
                  display: 'Prescribed by another organisation',
                },
              ],
            },
          },
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-MedicationStatementLastIssueDate-1',
            valueDateTime: '2020-09-09T23:00:00+00:00',
          },
        ],
        identifier: [
          { system: 'https://EMISWeb/L83015', value: '8ECCA1F8-6EC5-CB4A-B9E2-0BD04F98B457' },
        ],
        basedOn: [{ reference: 'MedicationRequest/0EB15DD3-FF9C-4C0B-B10A-830C1EA4111E-HD-1' }],
        status: 'completed',
        medicationReference: { reference: 'Medication/1E80A7CA-9CC8-48FF-A13A-1DF597BB7EA4' },
        effectivePeriod: { start: '2020-07-23', end: '2020-09-10' },
        dateAsserted: '2020-07-23T09:37:23.88+00:00',
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        taken: 'unk',
        dosage: [{ text: 'One To Be Taken Each Day' }],
      },
    },
    {
      resource: {
        resourceType: 'MedicationStatement',
        id: 'BB435344-A10A-4EE8-93BE-D2C5DFC59105-MS',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-MedicationStatement-1',
          ],
        },
        extension: [
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-PrescribingAgency-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-PrescribingAgency-1',
                  code: 'prescribed-at-gp-practice',
                  display: 'Prescribed at GP practice',
                },
              ],
            },
          },
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-MedicationStatementLastIssueDate-1',
            valueDateTime: '2020-07-29T23:00:00+00:00',
          },
        ],
        identifier: [
          { system: 'https://EMISWeb/L83015', value: '356ED393-8BA6-B5CA-E0D2-5A6AD62BF6FA' },
        ],
        basedOn: [{ reference: 'MedicationRequest/BB435344-A10A-4EE8-93BE-D2C5DFC59105' }],
        status: 'completed',
        medicationReference: { reference: 'Medication/AC13ED29-9A3E-42CC-87EE-FF2F5B9D2D4B' },
        effectivePeriod: { start: '2020-07-30', end: '2020-09-11' },
        dateAsserted: '2020-07-30T08:52:33.27+00:00',
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        taken: 'unk',
        note: [
          {
            authorString: 'Patient Notes',
            text: 'Issue number 1 issue sparingly and come in for meds review please',
          },
        ],
        dosage: [{ text: 'Apply Thinly At Night' }],
      },
    },
    {
      resource: {
        resourceType: 'MedicationStatement',
        id: 'BB435344-A10A-4EE8-93BE-D2C5DFC59105-FI-1-MS',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-MedicationStatement-1',
          ],
        },
        extension: [
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-PrescribingAgency-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-PrescribingAgency-1',
                  code: 'prescribed-at-gp-practice',
                  display: 'Prescribed at GP practice',
                },
              ],
            },
          },
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-MedicationStatementLastIssueDate-1',
            valueDateTime: '2020-07-29T23:00:00+00:00',
          },
        ],
        identifier: [
          { system: 'https://EMISWeb/L83015', value: '8F1017BC-2D5A-635B-DF75-97FF9E4A1E99' },
        ],
        basedOn: [{ reference: 'MedicationRequest/BB435344-A10A-4EE8-93BE-D2C5DFC59105-FI-1' }],
        status: 'completed',
        medicationReference: { reference: 'Medication/AC13ED29-9A3E-42CC-87EE-FF2F5B9D2D4B' },
        effectivePeriod: { start: '2020-07-30', end: '2020-07-30' },
        dateAsserted: '2020-07-30T08:47:25.21+00:00',
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        taken: 'unk',
        dosage: [{ text: 'Apply Thinly At Night' }],
      },
    },
    {
      resource: {
        resourceType: 'MedicationStatement',
        id: '0EB15DD3-FF9C-4C0B-B10A-830C1EA4111E-MS',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-MedicationStatement-1',
          ],
        },
        extension: [
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-PrescribingAgency-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-PrescribingAgency-1',
                  code: 'prescribed-at-gp-practice',
                  display: 'Prescribed at GP practice',
                },
              ],
            },
          },
        ],
        identifier: [
          { system: 'https://EMISWeb/L83015', value: 'DFB77447-30D6-242F-FCEF-20687A900BB0' },
        ],
        basedOn: [{ reference: 'MedicationRequest/0EB15DD3-FF9C-4C0B-B10A-830C1EA4111E' }],
        status: 'active',
        medicationReference: { reference: 'Medication/1E80A7CA-9CC8-48FF-A13A-1DF597BB7EA4' },
        effectivePeriod: { start: '2020-09-10' },
        dateAsserted: '2020-07-23T09:37:58.223+00:00',
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        taken: 'unk',
        dosage: [{ text: 'Two To Be Taken Each Day' }],
      },
    },
    {
      resource: {
        resourceType: 'MedicationStatement',
        id: 'BE3F5215-1BD6-4CA5-9146-323022BF2324-MS',
        meta: {
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-MedicationStatement-1',
          ],
        },
        extension: [
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-PrescribingAgency-1',
            valueCodeableConcept: {
              coding: [
                {
                  system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-PrescribingAgency-1',
                  code: 'prescribed-at-gp-practice',
                  display: 'Prescribed at GP practice',
                },
              ],
            },
          },
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-MedicationStatementLastIssueDate-1',
            valueDateTime: '2020-09-15T23:00:00+00:00',
          },
        ],
        identifier: [
          { system: 'https://EMISWeb/L83015', value: 'BC246B2F-6C8A-965E-BC9A-8EAE10514555' },
        ],
        basedOn: [{ reference: 'MedicationRequest/BE3F5215-1BD6-4CA5-9146-323022BF2324' }],
        status: 'stopped',
        medicationReference: { reference: 'Medication/9EE372BD-9D1A-4522-B75D-6BC0E8ACEFA7' },
        effectivePeriod: { start: '2020-09-16', end: '2020-09-16' },
        dateAsserted: '2020-09-16T11:52:30.237+00:00',
        subject: { reference: 'Patient/DB430DB0-B2C3-4C90-A1A5-54C6D017BD5F' },
        taken: 'unk',
        dosage: [{ text: 'Two To Be Taken On The First Day Then One To Be Taken Each Day' }],
      },
    },
    {
      resource: {
        resourceType: 'Organization',
        id: '12A7BA26-8E91-4AFE-A019-ADD6C735F058',
        meta: {
          versionId: '8893991724304424694',
          profile: ['https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-Organization-1'],
        },
        extension: [
          {
            url: 'https://fhir.nhs.uk/STU3/StructureDefinition/Extension-CareConnect-GPC-MainLocation-1',
            valueReference: { reference: 'Location/12A7BA26-8E91-4AFE-A019-ADD6C735F058' },
          },
        ],
        identifier: [{ system: 'https://fhir.nhs.uk/Id/ods-organization-code', value: 'L83015' }],
        type: [
          {
            coding: [
              {
                system: 'https://fhir.nhs.uk/STU3/CodeSystem/GPConnect-OrganisationType-1',
                code: 'gp-practice',
              },
            ],
            text: 'GP Practice',
          },
        ],
        name: 'Oakside Surgery',
        telecom: [
          { system: 'email', value: 'l83015.surgery@nhs.net', use: 'work', rank: 1 },
          { system: 'phone', value: '01752 766000', use: 'work', rank: 1 },
        ],
        address: [
          {
            use: 'work',
            type: 'physical',
            line: ['Oakside Surgery', 'Guy Miles Way', 'Honicknowle'],
            city: 'Plymouth',
            district: 'Devon',
            postalCode: 'PL5 3PY',
          },
        ],
      },
    },
    {
      resource: {
        resourceType: 'Practitioner',
        id: '68EC1C9E-4ED5-47C9-9E8E-B592ED84E3FC',
        meta: {
          versionId: '7079502206854905704',
          profile: ['https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-Practitioner-1'],
        },
        identifier: [
          { system: 'https://fhir.nhs.uk/Id/sds-user-id', value: '165158550039' },
          { system: 'https://fhir.nhs.uk/Id/sds-role-profile-id', value: '566988729100' },
        ],
        name: [{ use: 'official', family: 'Lenden', given: ['Gary'], prefix: ['Dr'] }],
        gender: 'male',
      },
    },
    {
      resource: {
        resourceType: 'Location',
        id: '12A7BA26-8E91-4AFE-A019-ADD6C735F058',
        meta: {
          versionId: '1713793648895608620',
          profile: ['https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-Location-1'],
        },
        status: 'active',
        name: 'Oakside Surgery',
        type: { text: 'Main Surgery' },
        telecom: [
          { system: 'email', value: 'l83015.surgery@nhs.net', use: 'work', rank: 1 },
          { system: 'phone', value: '01752 766000', use: 'work', rank: 1 },
        ],
        address: {
          use: 'work',
          type: 'physical',
          line: ['Oakside Surgery', 'Guy Miles Way', 'Honicknowle'],
          city: 'Plymouth',
          district: 'Devon',
          postalCode: 'PL5 3PY',
        },
        managingOrganization: {
          reference: 'Organization/12A7BA26-8E91-4AFE-A019-ADD6C735F058',
          display: 'Oakside Surgery',
        },
      },
    },
    {
      resource: {
        resourceType: 'Medication',
        id: '13DB670E-82CF-44F9-959C-A14E31180D40',
        meta: {
          profile: ['https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-Medication-1'],
        },
        code: {
          coding: [
            {
              system: 'https://fhir.hl7.org.uk/Id/emis-drug-codes',
              code: 'OTSP9659BRIDL',
              display: 'Otomize ear spray (Teva UK Ltd)',
              userSelected: true,
            },
            {
              system: 'http://snomed.info/sct',
              code: '507711000001101',
              display: 'Otomize ear spray (Teva UK Ltd)',
            },
          ],
        },
      },
    },
    {
      resource: {
        resourceType: 'Medication',
        id: '7FBFE91F-57CB-41CB-A6F5-625C275AEE2B',
        meta: {
          profile: ['https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-Medication-1'],
        },
        code: {
          coding: [
            {
              system: 'https://fhir.hl7.org.uk/Id/emis-drug-codes',
              code: 'AMCA17511NEMIS',
              display: 'Amoxicillin 500mg capsules',
              userSelected: true,
            },
            {
              system: 'http://snomed.info/sct',
              code: '323510009',
              display: 'Amoxicillin 500mg capsules',
            },
          ],
        },
      },
    },
    {
      resource: {
        resourceType: 'Medication',
        id: '9CCAA461-6A2F-4165-BEFE-8EFA3062BC10',
        meta: {
          profile: ['https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-Medication-1'],
        },
        code: {
          coding: [
            {
              system: 'https://fhir.hl7.org.uk/Id/emis-drug-codes',
              code: 'OMCA10940BRIDL',
              display: 'Omeprazole 20mg gastro-resistant capsules',
              userSelected: true,
            },
            {
              system: 'http://snomed.info/sct',
              code: '317291008',
              display: 'Omeprazole 20mg gastro-resistant capsules',
            },
          ],
        },
      },
    },
    {
      resource: {
        resourceType: 'Medication',
        id: '1D1D9F81-524F-4329-973D-4B1030D7568D',
        meta: {
          profile: ['https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-Medication-1'],
        },
        code: {
          coding: [
            {
              system: 'https://fhir.hl7.org.uk/Id/emis-drug-codes',
              code: 'RACA8951EMIS',
              display: 'Ramipril 2.5mg capsules',
              userSelected: true,
            },
            {
              system: 'http://snomed.info/sct',
              code: '318901006',
              display: 'Ramipril 2.5mg capsules',
            },
          ],
        },
      },
    },
    {
      resource: {
        resourceType: 'Medication',
        id: 'D3A70D21-E851-4261-87A9-36F1B41BD1FB',
        meta: {
          profile: ['https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-Medication-1'],
        },
        code: {
          coding: [
            {
              system: 'https://fhir.hl7.org.uk/Id/emis-drug-codes',
              code: 'CITA32611EMIS',
              display: 'Citalopram 10mg tablets',
              userSelected: true,
            },
            {
              system: 'http://snomed.info/sct',
              code: '321989000',
              display: 'Citalopram 10mg tablets',
            },
          ],
        },
      },
    },
    {
      resource: {
        resourceType: 'Practitioner',
        id: '8E88F7AC-2D2C-4B4D-A04B-0EF465018795',
        meta: {
          versionId: '4522185866177370515',
          profile: ['https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-Practitioner-1'],
        },
        identifier: [
          { system: 'https://fhir.nhs.uk/Id/sds-user-id', value: '839278708046' },
          { system: 'https://fhir.nhs.uk/Id/sds-role-profile-id', value: '571409817107' },
        ],
        name: [{ use: 'official', family: 'Micklewright', given: ['Tom'], prefix: ['Dr'] }],
        gender: 'male',
      },
    },
    {
      resource: {
        resourceType: 'Medication',
        id: 'BB73768F-5E66-4595-8E79-34089F0F7C2D',
        meta: {
          profile: ['https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-Medication-1'],
        },
        code: {
          coding: [
            {
              system: 'https://fhir.hl7.org.uk/Id/emis-drug-codes',
              code: 'PRTA2395',
              display: 'Propranolol 40mg tablets',
              userSelected: true,
            },
            {
              system: 'http://snomed.info/sct',
              code: '318353009',
              display: 'Propranolol 40mg tablets',
            },
          ],
        },
      },
    },
    {
      resource: {
        resourceType: 'Medication',
        id: '1E80A7CA-9CC8-48FF-A13A-1DF597BB7EA4',
        meta: {
          profile: ['https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-Medication-1'],
        },
        code: {
          coding: [
            {
              system: 'https://fhir.hl7.org.uk/Id/emis-drug-codes',
              code: 'SETA9309EMIS',
              display: 'Sertraline 50mg tablets',
              userSelected: true,
            },
            {
              system: 'http://snomed.info/sct',
              code: '321959007',
              display: 'Sertraline 50mg tablets',
            },
          ],
        },
      },
    },
    {
      resource: {
        resourceType: 'Medication',
        id: 'AC13ED29-9A3E-42CC-87EE-FF2F5B9D2D4B',
        meta: {
          profile: ['https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-Medication-1'],
        },
        code: {
          coding: [
            {
              system: 'https://fhir.hl7.org.uk/Id/emis-drug-codes',
              code: 'EPGE42895NEMIS',
              display: 'Epiduo 0.1%/2.5% gel (Galderma (UK) Ltd)',
              userSelected: true,
            },
            {
              system: 'http://snomed.info/sct',
              code: '17403011000001101',
              display: 'Epiduo 0.1%/2.5% gel (Galderma (UK) Ltd)',
            },
          ],
        },
      },
    },
    {
      resource: {
        resourceType: 'Medication',
        id: '9EE372BD-9D1A-4522-B75D-6BC0E8ACEFA7',
        meta: {
          profile: ['https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-Medication-1'],
        },
        code: {
          coding: [
            {
              system: 'https://fhir.hl7.org.uk/Id/emis-drug-codes',
              code: 'DOCA17639NEMIS',
              display: 'Doxycycline 100mg capsules',
              userSelected: true,
            },
            {
              system: 'http://snomed.info/sct',
              code: '324059006',
              display: 'Doxycycline 100mg capsules',
            },
          ],
        },
      },
    },
    {
      resource: {
        resourceType: 'Practitioner',
        id: 'D4041382-0745-460A-8664-A3F0FDD297C9',
        meta: {
          versionId: '1652756649143093726',
          profile: ['https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-Practitioner-1'],
        },
        identifier: [
          { system: 'https://fhir.nhs.uk/Id/sds-user-id', value: '762328565564' },
          { system: 'https://fhir.nhs.uk/Id/sds-role-profile-id', value: '564992185109' },
          { system: 'https://fhir.nhs.uk/Id/sds-role-profile-id', value: '565843473104' },
        ],
        name: [{ use: 'official', family: 'Wallis', given: ['Rianna'], prefix: ['Miss'] }],
        gender: 'female',
      },
    },
    {
      resource: {
        resourceType: 'Practitioner',
        id: 'A51DBB94-8E75-4510-80D5-D55AEEBE1796',
        meta: {
          versionId: '1933185638887701614',
          profile: ['https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-Practitioner-1'],
        },
        name: [{ use: 'official', family: 'Roche', given: ['Edward'], prefix: ['Dr'] }],
        gender: 'male',
      },
    },
    {
      resource: {
        resourceType: 'PractitionerRole',
        id: '68EC1C9E4ED547C99E8EB592ED84E3FC12A7BA268E914AFEA019ADD6C735F058',
        meta: {
          versionId: '4575857927519754286',
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-PractitionerRole-1',
          ],
        },
        practitioner: { reference: 'Practitioner/68EC1C9E-4ED5-47C9-9E8E-B592ED84E3FC' },
        organization: { reference: 'Organization/12A7BA26-8E91-4AFE-A019-ADD6C735F058' },
        code: [
          {
            coding: [
              {
                system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-SDSJobRoleName-1',
                code: 'R0260',
                display: 'General Medical Practitioner',
              },
            ],
          },
        ],
      },
    },
    {
      resource: {
        resourceType: 'PractitionerRole',
        id: '8E88F7AC2D2C4B4DA04B0EF46501879512A7BA268E914AFEA019ADD6C735F058',
        meta: {
          versionId: '1829289575875066226',
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-PractitionerRole-1',
          ],
        },
        practitioner: { reference: 'Practitioner/8E88F7AC-2D2C-4B4D-A04B-0EF465018795' },
        organization: { reference: 'Organization/12A7BA26-8E91-4AFE-A019-ADD6C735F058' },
        code: [
          {
            coding: [
              {
                system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-SDSJobRoleName-1',
                code: 'R6300',
                display: 'Sessional GP',
              },
            ],
          },
        ],
      },
    },
    {
      resource: {
        resourceType: 'PractitionerRole',
        id: 'D40413820745460A8664A3F0FDD297C912A7BA268E914AFEA019ADD6C735F058',
        meta: {
          versionId: '4977870063853495242',
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-PractitionerRole-1',
          ],
        },
        practitioner: { reference: 'Practitioner/D4041382-0745-460A-8664-A3F0FDD297C9' },
        organization: { reference: 'Organization/12A7BA26-8E91-4AFE-A019-ADD6C735F058' },
        code: [
          {
            coding: [
              {
                system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-SDSJobRoleName-1',
                code: 'R1730',
                display: 'Receptionist',
              },
            ],
          },
        ],
      },
    },
    {
      resource: {
        resourceType: 'PractitionerRole',
        id: 'A51DBB948E75451080D5D55AEEBE179612A7BA268E914AFEA019ADD6C735F058',
        meta: {
          versionId: '4701093334711095514',
          profile: [
            'https://fhir.nhs.uk/STU3/StructureDefinition/CareConnect-GPC-PractitionerRole-1',
          ],
        },
        practitioner: { reference: 'Practitioner/A51DBB94-8E75-4510-80D5-D55AEEBE1796' },
        organization: { reference: 'Organization/12A7BA26-8E91-4AFE-A019-ADD6C735F058' },
        code: [
          {
            coding: [
              {
                system: 'https://fhir.nhs.uk/STU3/CodeSystem/CareConnect-SDSJobRoleName-1',
                code: 'R6300',
                display: 'Sessional GP',
              },
            ],
          },
        ],
      },
    },
  ],
};
