<pushdr-consultation-card [hasData]="true" [showFooter]="true" [bodyPadding]="'p-0'">
  <pushdr-prescription-card-header header></pushdr-prescription-card-header>

  <ng-container body *ngIf="selectedMedication$ | async as medication">
    <div class="p-5 shadow-header">
      <div class="flex items-center justify-between p-1 bg-indigo-100 rounded">
        <div class="px-3 text-base">{{ medication.medicationName }}</div>
        <div>
          <button
            data-cy="change-medication"
            class="p-2 px-3 ml-5 bg-white btn text-primary"
            (click)="clearMedication()">
            Clear
          </button>
        </div>
      </div>
    </div>
    <div class="p-3">
      <pushdr-fdb-prescribe
        (formChanged)="updateMedicationToPrescribe($event)"
        [medicationCode]="medication?.snomedCode"
        [inputData]="inputData$ | async">
      </pushdr-fdb-prescribe>
    </div>
  </ng-container>

  <ng-container footer>
    <div class="flex mt-3 mb-3">
      <div class="flex flex-col items-center w-full">
        <button
          class="p-3 px-5 text-white rounded bg-primary hover:bg-primary-hover"
          data-cy="proceed"
          style="min-width: 200px"
          [ngClass]="{
            'cursor-not-allowed bg-primary-disabled hover:bg-primary-disabled':
              !medicationToPrescribeState?.valid
          }"
          [attr.disabled]="!medicationToPrescribeState?.valid ? '' : null"
          (click)="prescribeMedication()">
          {{ (hasDispenser$ | async) ? 'Proceed' : 'Specify pharmacy search area' }}
        </button>
      </div>
    </div>
  </ng-container>
</pushdr-consultation-card>
