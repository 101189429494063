import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CliniciansCommonModule } from '@pushdr/clinicians/common';
import { CanDeactivateComponentGuard, ScrollModule } from '@pushdr/common/utils';
import {
  PreSurgeryModule,
  preSurgeryRoutes as clinicianTechCheckRoutes,
} from '@pushdr/pre-surgery';
import { CliniciansConsultationComponent } from './clinicians-consultation.component';
import { MeetThePatientComponent } from './routes/meet-the-patient/meet-the-patient.component';
import { MeetThePatientModule } from './routes/meet-the-patient/meet-the-patient.module';
import { PatientComponent } from './routes/patient/patient.component';
import {
  cliniciansPatientConsultingRoutes,
  CliniciansPatientModule,
} from './routes/patient/patient.module';
import { TechChecksComponent } from './routes/tech-checks/tech-checks.component';
import { CliniciansWaitingRoomComponent } from './routes/waiting-room/clinicians-waiting-room.component';
import {
  CliniciansWaitingRoomModule,
  cliniciansWaitingRoomRoutes,
} from './routes/waiting-room/clinicians-waiting-room.module';
import { ConsultationSummaryComponent } from './routes/summary/consultation-summary.component';
import {
  consultationSummaryRoutes,
  ConsultationSummaryModule,
} from './routes/summary/consultation-summary.module';
import { HasAuthGuard } from '@pushdr/clinicians/auth';

import { ConsultationMenuComponent } from './components/consultation-menu/consultation-menu.component';
import { ConsultationMenuActionsComponent } from './components/consultation-menu-actions/consultation-menu-actions.component';
import { ConsultationSetupProgressComponent } from './components/consultation-setup-progress/consultation-setup-progress.component';
import { TechChecksPassedGuard } from './guards/tech-checks-passed.guard';
import { RefreshOnRedirectGuard } from './guards/refresh-on-redirect.guard';
import { CommonComponentsModule } from '@pushdr/common/components';

export const CliniciansConsultRoutes: Routes = [
  {
    path: 'consultation',
    component: CliniciansConsultationComponent,
    canDeactivate: [CanDeactivateComponentGuard],
    canActivate: [HasAuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'tech-checks',
        pathMatch: 'full',
      },
      {
        path: 'tech-checks',
        component: TechChecksComponent,
        canActivate: [TechChecksPassedGuard],
        children: clinicianTechCheckRoutes,
      },
      {
        path: 'waiting-room',
        component: CliniciansWaitingRoomComponent,
        children: cliniciansWaitingRoomRoutes,
      },
      {
        path: 'meet-patient',
        canDeactivate: [CanDeactivateComponentGuard],
        component: MeetThePatientComponent,
      },
      {
        path: 'patient',
        canDeactivate: [CanDeactivateComponentGuard],
        component: PatientComponent,
        children: cliniciansPatientConsultingRoutes,
      },
      {
        path: 'summary',
        component: ConsultationSummaryComponent,
        // TODO: Fix app's stale state clearing up on consultation end
        // As for now we're refreshing page on router navigation to mitigate this issue
        canDeactivate: [RefreshOnRedirectGuard],
        children: consultationSummaryRoutes,
      },
    ],
  },
];

@NgModule({
  declarations: [
    CliniciansConsultationComponent,
    TechChecksComponent,
    ConsultationMenuComponent,
    ConsultationMenuActionsComponent,
    ConsultationSetupProgressComponent,
  ],
  imports: [
    CommonModule,
    CliniciansCommonModule,
    PreSurgeryModule,
    CliniciansWaitingRoomModule,
    ConsultationSummaryModule,
    MeetThePatientModule,
    CliniciansPatientModule,
    CommonComponentsModule,
    ScrollModule,
    RouterModule.forChild(CliniciansConsultRoutes),
  ],
})
export class CliniciansConsultationModule {}
