<pushdr-consultation-card
  *ngIf="{ consultation: (consultation$ | async) } as data"
  [hasData]="data.consultation"
  [showFooter]="true">
  <span header>
    <span class="flex justify-between">
      Notes
      <span>{{ data.consultation?.notes?.strDate }}</span>
    </span>
  </span>
  <ng-container body>
    <pushdr-notes-pdf-view
      *ngIf="showReport; else textNotes"
      [reportGuid]="data.consultation?.reportGuid"
      class="flex flex-grow"></pushdr-notes-pdf-view>
    <ng-template #textNotes>
      <pushdr-notes-text-view
        [consultationNotes]="data.consultation?.notes"></pushdr-notes-text-view>
    </ng-template>
  </ng-container>

  <ng-container footer>
    <div class="flex justify-between w-full p-2 px-8">
      <a [routerLink]="['../../list']" class="btn btn-outline"> Back </a>
      <button
        *ngIf="data.consultation?.reportGuid"
        (click)="showReport = !showReport"
        class="btn-primary btn">
        {{ showReport ? 'Close NHS report' : 'View NHS report' }}
      </button>
    </div>
  </ng-container>
</pushdr-consultation-card>
