<p class="px-6 py-1 mb-0 text-grey-700">Account</p>
<a
  [routerLink]="['/home/profile/edit-profile']"
  routerLinkActive="bg-secondary"
  class="px-6 py-3 menu-item"
  tabindex="4"
  data-cy="go-to-change-password">
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      class="fill-current"
      d="M13.95 8.78C13.98 8.53 14 8.27 14 8C14 7.73 13.98 7.47 13.94 7.22L15.63 5.9C15.78 5.78 15.82 5.56 15.73 5.39L14.13 2.62C14.03 2.44 13.82 2.38 13.64 2.44L11.65 3.24C11.23 2.92 10.79 2.66 10.3 2.46L10 0.34C9.97001 0.14 9.80001 0 9.60001 0H6.40001C6.20001 0 6.04001 0.14 6.01001 0.34L5.71001 2.46C5.22001 2.66 4.77001 2.93 4.36001 3.24L2.37001 2.44C2.19001 2.37 1.98001 2.44 1.88001 2.62L0.280007 5.39C0.180007 5.57 0.220008 5.78 0.380008 5.9L2.07001 7.22C2.03001 7.47 2.00001 7.74 2.00001 8C2.00001 8.26 2.02001 8.53 2.06001 8.78L0.370007 10.1C0.220007 10.22 0.180007 10.44 0.270007 10.61L1.87001 13.38C1.97001 13.56 2.18001 13.62 2.36001 13.56L4.35001 12.76C4.77001 13.08 5.21001 13.34 5.70001 13.54L6.00001 15.66C6.04001 15.86 6.20001 16 6.40001 16H9.60001C9.80001 16 9.97001 15.86 9.99001 15.66L10.29 13.54C10.78 13.34 11.23 13.07 11.64 12.76L13.63 13.56C13.81 13.63 14.02 13.56 14.12 13.38L15.72 10.61C15.82 10.43 15.78 10.22 15.62 10.1L13.95 8.78ZM8.00001 11C6.35001 11 5.00001 9.65 5.00001 8C5.00001 6.35 6.35001 5 8.00001 5C9.65001 5 11 6.35 11 8C11 9.65 9.65001 11 8.00001 11Z"></path>
  </svg>
  Settings
</a>
<button
  class="w-full px-6 py-3 text-left menu-item"
  tabindex="5"
  data-cy="open-terms-modal"
  (click)="openTermsModal()">
  <svg width="16" height="20" viewBox="0 0 16 20" fill="none">
    <path
      class="fill-current"
      d="M8 0.833008L0.5 4.16634V9.16634C0.5 13.7913 3.7 18.1163 8 19.1663C12.3 18.1163 15.5 13.7913 15.5 9.16634V4.16634L8 0.833008ZM6.33333 14.1663L3 10.833L4.175 9.65801L6.33333 11.808L11.825 6.31634L13 7.49967L6.33333 14.1663Z" />
  </svg>
  Terms of use
</button>
<a
  [routerLink]="[]"
  class="px-6 py-3 menu-item"
  tabindex="6"
  (click)="logOut()"
  data-cy="go-to-log-out">
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      class="fill-current"
      d="M6.40833 10.9917L7.58333 12.1667L11.75 8L7.58333 3.83333L6.40833 5.00833L8.55833 7.16667H0.5V8.83333H8.55833L6.40833 10.9917ZM13.8333 0.5H2.16667C1.24167 0.5 0.5 1.25 0.5 2.16667V5.5H2.16667V2.16667H13.8333V13.8333H2.16667V10.5H0.5V13.8333C0.5 14.75 1.24167 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V2.16667C15.5 1.25 14.75 0.5 13.8333 0.5Z"></path>
  </svg>
  Log out
</a>
