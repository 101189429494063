<div class="c-card--clinicians-wrapper">
  <div class="c-card c-card--clinicians pt-8 pb-6">
    <ng-container *ngIf="details$ | async as details; else connecting">
      <div class="text-center">
        <p class="mb-6 text-base">Connecting you with...</p>
        <h2 *ngIf="details.Patient.CustomerType === CustomerType.Child" class="mb-12 font-normal">
          <div class="mb-2">
            {{
              details.Customer.FirstName +
                ' ' +
                details.Customer.LastName +
                ' (' +
                details.Customer.Age +
                ')'
            }}
            - Account holder
          </div>
          <div>
            {{
              details.Patient.FirstName +
                ' ' +
                details.Patient.LastName +
                ' (' +
                details.Patient.Age +
                ')'
            }}
            - Child
          </div>
        </h2>
        <h2 *ngIf="details.Patient.CustomerType === CustomerType.Adult">
          <div>
            {{
              details.Patient.FirstName +
                ' ' +
                details.Patient.LastName +
                ' (' +
                details.Patient.Age +
                ')'
            }}
            - Account holder
          </div>
        </h2>
      </div>

      <h2 *ngIf="details.Partner.PartnerType === PartnerType.NHS" class="mb-4 text-primary-dark">
        {{ details.Partner?.Name }}
      </h2>
      <h3 *ngIf="details.Partner.PartnerType === PartnerType.NHS">
        {{ details.Patient?.GpSummary.SurgeryTypeId | clinicianSystem }}
        {{
          isEmis && details.Patient?.GpSummary.SurgeryCode
            ? 'Code: ' + details.Patient?.GpSummary.SurgeryCode
            : ''
        }}
      </h3>
      <h3
        *ngIf="
          details.Partner.PartnerType === PartnerType.NHS && details.Patient?.GpSummary.OdsCode
        ">
        ODS code: {{ details.Patient?.GpSummary.OdsCode }}
      </h3>
      <pushdr-countdown-icon
        [seconds]="10"
        (completed)="goAndMeetPatientInConsultation()"></pushdr-countdown-icon>
      <p class="w-40 mb-16 text-base text-center">seconds until the consultation begins</p>
      <h2 *ngIf="details.IsCallRecordingEnabled" class="text-red-500">
        This consultation will be recorded
      </h2>
      <h2 class="mt-12 font-normal text-bluegrey-800">
        Please be ready to check their identification
      </h2>
    </ng-container>
  </div>
</div>

<ng-template #connecting>
  <pushdr-countdown-icon [seconds]="0"></pushdr-countdown-icon>
  <h3>Fetching patient details</h3>
</ng-template>
