<pushdr-consultation-card class="alert-card" *ngIf="showingAlerts" [hasData]="true">
  <span header>Alerts</span>
  <ng-container body>
    <ng-container *ngTemplateOutlet="alerts"></ng-container>
  </ng-container>
</pushdr-consultation-card>

<pushdr-consultation-card *ngIf="!showingAlerts" [hasData]="true" [class.lite]="liteMode">
  <span header>Key information</span>
  <ng-container body>
    <ng-container *ngTemplateOutlet="keyinfo"></ng-container>
  </ng-container>
</pushdr-consultation-card>

<ng-template #alerts>
  <div class="flex flex-col flex-grow container-contents">
    <div *ngFor="let alert of alerts$ | async" class="p-4 mb-2 text-sm bg-white rounded text-error">
      {{ alert.message }}
    </div>
  </div>
  <div
    (click)="hideAlerts()"
    class="items-center w-full p-5 text-center bg-white rounded cursor-pointer alerts-bar text-body-text">
    <span class="mr-2">Hide Alerts</span>
  </div>
</ng-template>

<ng-template #keyinfo>
  <ng-container *ngIf="keyInfoViewModel$ | async as details; else loading">
    <ng-container *ngIf="isConsultingWithChild$ | async">
      <div class="p-4 mb-4 -mx-4 -mt-4 bg-indigo-300 divider-bar text-bluegrey-800">CHILD</div>
      <div class="flex flex-wrap mb-4">
        <div class="inline-block mb-4 mr-4 detail-block">
          <span class="block mb-1 font-light title text-grey-500">Name</span>
          <span class="font-medium detail text-bluegrey-800">{{ details.child.name }}</span>
        </div>
        <div class="inline-block mb-4 mr-4 detail-block">
          <span class="block mb-1 font-light title text-grey-500">Date of birth</span>
          <span class="detail text-bluegrey-800">
            {{ details.child.dob }}
          </span>
        </div>

        <div class="inline-block mb-4 mr-4 detail-block">
          <span class="block mb-1 font-light title text-grey-500">Gender</span>
          <span class="detail text-bluegrey-800">{{ details.child.gender }}</span>
        </div>
      </div>
    </ng-container>

    <div class="p-4 mb-4 -mx-4 -mt-4 bg-indigo-300 divider-bar text-bluegrey-800">
      ACCOUNT HOLDER
    </div>
    <div class="flex flex-col flex-grow">
      <div class="flex flex-wrap">
        <div class="block w-full mb-4 detail-block">
          <span class="block mb-1 font-light title text-grey-500">Name</span>
          <span class="font-medium detail text-bluegrey-800">{{ details.name }}</span>
        </div>
        <div class="inline-block mb-4 mr-4 detail-block">
          <span class="block mb-1 font-light title text-grey-500">Date of birth (Age)</span>
          <span class="detail text-bluegrey-800">
            {{ details.dateOfBirth }} ({{ details.age }})
          </span>
        </div>
        <div class="inline-block mb-4 mr-4 detail-block">
          <span class="block mb-1 font-light title text-grey-500">NHS Number</span>
          <span class="detail text-bluegrey-800">
            {{ details.nhsNumber | formatNhsNumber }}
          </span>
        </div>
        <div class="inline-block mb-4 mr-4 detail-block">
          <span class="block mb-1 font-light title text-grey-500">Gender</span>
          <span class="detail text-bluegrey-800">{{ details.genderLabel }}</span>
        </div>

        <div class="inline-block mb-4 mr-4 detail-block address-block">
          <span class="block mb-1 font-light title text-grey-500">Home Address</span>
          <span class="block break-all whitespace-pre detail text-bluegrey-800">{{
            details.patientAddress
          }}</span>
        </div>

        <div *ngIf="details.email" class="inline-block mb-4 mr-4 detail-block address-block">
          <span class="block mb-1 font-light title text-grey-500">Email</span>
          <span class="block break-all whitespace-pre detail text-bluegrey-800">{{
            details.email
          }}</span>
        </div>

        <div *ngIf="details.phone" class="inline-block mb-4 mr-4 detail-block address-block">
          <span class="block mb-1 font-light title text-grey-500">Phone</span>
          <span class="block break-all whitespace-pre detail text-bluegrey-800">{{
            details.phone
          }}</span>
        </div>
      </div>
      <div class="p-4 mb-4 -mx-4 bg-indigo-300 divider-bar text-bluegrey-800">NHS</div>
      <div class="flex flex-wrap pb-4">
        <div class="block mb-4 mr-4 detail-block" [class.w-full]="!details.surgeryCode">
          <span class="block mb-1 font-light title text-grey-500">System</span>
          <span class="detail text-bluegrey-800">
            {{ details.surgeryTypeId | clinicianSystem }}
          </span>
        </div>

        <div
          *ngIf="details.surgeryCode"
          class="block mb-4 mr-4 detail-block"
          [ngClass]="liteMode ? 'w-1/2' : 'w-4/5'">
          <span class="block mb-1 font-light title text-grey-500">
            <ng-template [ngIf]="details.isEmis" [ngIfElse]="noEmis">EMIS code</ng-template>
            <ng-template #noEmis>Surgery code</ng-template>
          </span>
          <span class="detail text-bluegrey-800">{{ details.surgeryCode }}</span>
        </div>

        <div class="block mb-4 mr-4 detail-block">
          <span class="block mb-1 font-light title text-grey-500">Partner name</span>
          <span class="detail text-bluegrey-800">{{ details.partner?.name || 'None' }}</span>
        </div>
        <div class="block mb-4 mr-4 detail-block">
          <span class="block mb-1 font-light title text-grey-500">ODS</span>
          <span class="detail text-bluegrey-800">
            {{ details?.partner?.odsCode || 'None' }}
          </span>
        </div>
        <div class="block mb-4 mr-4 detail-block address-block">
          <span class="block mb-1 font-light title text-grey-500">GP address</span>
          <span class="block detail text-bluegrey-800">
            {{ details?.partner?.name }}
          </span>
          <span class="block whitespace-pre detail text-bluegrey-800">
            {{ details?.partner?.address }}
          </span>
        </div>
        <!-- <div *ngIf="details?.Patient.NhsInformation?.SuccessResponse?.OrganisationDataServiceCode"
          class="block mb-4 mr-4 detail-block">
          <span class="block mb-1 font-light title text-grey-500">NHS ODS</span>
          <span class="detail text-bluegrey-800">
            {{details?.Patient.NhsInformation?.SuccessResponse?.OrganisationDataServiceCode}}
          </span>
        </div> -->
      </div>
    </div>
  </ng-container>
  <div
    *ngIf="alerts$ | async as alerts"
    (click)="showAlerts()"
    [class.hidden]="!alerts.length"
    class="flex flex-row items-center justify-center w-full p-5 text-center rounded cursor-pointer alerts-bar bg-error-light text-error">
    <span class="mr-2">View Alerts</span>
    <div
      class="flex items-center justify-center w-6 h-6 text-xs rounded-full counter bg-error text-error-light">
      {{ alerts.length }}
    </div>
  </div>
</ng-template>

<ng-template #loading>Loading patient details</ng-template>
