import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { concatMap, map, retry, scan, shareReplay, takeWhile } from 'rxjs/operators';

import { ModalService } from '@pushdr/common/overlay';
import { DoctorNote, DoctorNotesApiService } from '@pushdr/consultation/api';
import { DoctorNotesProxy } from '../doctor-notes-proxy';

@Component({
  selector: 'pushdr-doctor-notes',
  templateUrl: './doctor-notes.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DoctorNotesComponent {
  private loadPageSubject = new BehaviorSubject<number>(1);

  nextNotes$ = combineLatest([this.doctorNotesProxy.getCustomerId(), this.loadPageSubject]).pipe(
    concatMap(([customerId, pageNo]) => this.doctorNotes.getDoctorNotes(customerId, pageNo, 20)),
    retry({ delay: err => this.modal.tryAgainFor(of(err), 'Failed to load Push Doctor Notes') }),
    takeWhile(notesPage => !!notesPage?.Content?.length, true),
    map(notesPage => notesPage?.Content ?? [])
  );

  notes$ = this.nextNotes$.pipe(
    scan((acc: DoctorNote[], notes) => [...acc, ...notes], []),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  homePath$ = this.doctorNotesProxy.getHomePath();

  constructor(
    private modal: ModalService,
    private doctorNotesProxy: DoctorNotesProxy,
    private doctorNotes: DoctorNotesApiService
  ) {}

  loadMoreNotes(): void {
    const nextPage = this.loadPageSubject.value + 1;
    this.loadPageSubject.next(nextPage);
  }
}
