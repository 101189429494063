<table class="w-full py-4">
  <tbody class="">
    <ng-template #noRecords>
      <tr>
        <td colspan="100%">No medications prescribed</td>
      </tr>
    </ng-template>

    <ng-container *ngIf="prescriptions && prescriptions.length > 0; else noRecords">
      <tr
        *ngFor="let prescription of prescriptions"
        class="border-b border-bluegrey-300 text-gray-800 text-left hover:bg-indigo-100"
        [class.bg-indigo-100]="selectedItems.has(prescription.medicationId)"
        (click)="handleRowClick(prescription)">
        <td class="w-2/12 p-3 pl-5">
          <div class="flex items-center justify-start">
            <ng-container [ngSwitch]="prescription.medicationStatus | prescriptionErrorStatusCheck">
              <i *ngSwitchCase="true" class="material-icons mr-2 mb-1 text-red-500">cancel</i>
              <i *ngSwitchDefault class="material-icons mr-2 mb-1 text-green-500">check_circle</i>
            </ng-container>
            <span [attr.tooltip-right]="prescription | prescriptionStatusDescription">
              {{ prescription | prescriptionStatusName }}
            </span>
          </div>
        </td>
        <td class="w-3/12 p-3">
          {{ prescription.medicationName }}
        </td>
        <td class="w-1/12 p-3">
          {{ prescription.quantity }} {{ prescription.unitTypeDescription }}(s)
        </td>
        <td class="w-4/12 p-3 break-words">
          {{ prescription.dosage }}
        </td>
        <td class="w-2/12 p-3 whitespace-nowrap">
          <div
            class="flex items-center justify-start copy-content"
            [clipboard]="prescription.prescriptionId">
            <span>{{ prescription.prescriptionId }}</span>
            <i class="ml-1 mb-1 text-primary text-sm material-icons">content_copy</i>
          </div>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
