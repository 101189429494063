<nav class="relative h-full font-medium bg-white rounded">
  <div class="absolute top-0 block w-full h-1 rounded-t bg-body-text"></div>
  <section class="flex items-center justify-between menu-section">
    <a
      *ngIf="!hideEmergency"
      class="patient-emergency text-error hover:bg-grey-100 hover:text-error"
      (click)="patientEmergency.emit()">
      Emergency
    </a>

    <a
      class="safe-guard text-information hover:bg-grey-100 hover:text-information"
      (click)="safeGuarding.emit()">
      Safeguarding process
    </a>

    <a
      class="rounded-tr end-consult text-primary hover:bg-grey-100"
      routerLink="/consultation/patient/consult/actions/consultation-complete"
      [queryParams]="{ from: EndConsultationPlace.FOOTER }">
      {{ endConsultationText }}
    </a>
  </section>
</nav>
