import { Component } from '@angular/core';
import { AbstractModal } from '@pushdr/common/overlay';
import { EndConsultationResults } from '../../../../services/end-consultation/models';

@Component({
  selector: 'pushdr-consultation-incomplete-actions-modal',
  templateUrl: './consultation-incomplete-actions-modal.component.html',
  styleUrls: ['./consultation-incomplete-actions-modal.component.scss'],
})
export class ConsultationIncompleteActionsModalComponent extends AbstractModal<
  EndConsultationResults,
  boolean
> {}
