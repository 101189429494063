<ng-container *ngIf="{ data: (dispensers$ | async) } as state">
  <ng-select
    [placeholder]="openOnly ? 'Search by postcode only' : 'Search by postcode (name optional)'"
    aria-live="polite"
    clearAllText="Clear"
    [loading]="state.data?.isLoading && state.data?.isValid"
    [items]="state.data?.values"
    [searchFn]="dummySearch"
    bindLabel="name"
    [notFoundText]="state.data?.isValid ? 'No matching dispensers' : 'Type a valid postcode'"
    [formControl]="control"
    (keyup)="changeSearchTerm($any($event.target).value)"
    (change)="onChange($event)">
    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
      <div class="flex items-center">
        <div class="flex-grow mr-2">
          <p class="mb-0 whitespace-normal font-bold mr-1">
            {{ item.name }}
          </p>
          <p class="mb-0 whitespace-normal">
            {{ item | dispenserAddress }}
          </p>
          <p class="mb-0 whitespace-normal" [innerHTML]="item | dispenserOpenTime"></p>
        </div>
        <div class="ml-2">{{ item.distance.toFixed(1) }} miles</div>
      </div>
    </ng-template>
  </ng-select>
</ng-container>
