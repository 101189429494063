export enum PrescriptionCancelReason {
  '0001' = 'Prescribing error',
  '0002' = 'Clinical contra-indication',
  '0003' = 'Change to medication treatment regime',
  '0004' = 'Clinical grounds',
  '0005' = 'At the Patient’s request',
  '0006' = 'At the Pharmacist’s request',
}

export enum PrescriptionSuccessStatus {
  Created = 1,
  Pending = 2,
  Success = 4,
  Managed_by_PX = 12,
  Manually_Cancelled = 13,
}

export enum PrescriptionErrorStatus {
  Submitted_with_errors = 3,
  TimedOut = 5,
  Rejected = 6,
  Cancellation_pending = 7,
  Cancellation_rejected = 9,
  Cancelled = 10,
  Cancellation_timeout = 11,
}

// Ugly TypeScript way to combine enums
export const PrescriptionStatus = { ...PrescriptionErrorStatus, ...PrescriptionSuccessStatus };
export type PrescriptionStatus = typeof PrescriptionStatus;

export const PrescriptionStatusDescription = {
  [PrescriptionStatus.Rejected]:
    'The electronic prescribing service systems have rejected this prescription. Doctor support have been notified and will take action.',
  [PrescriptionStatus.TimedOut]:
    'The sending of the prescription has timedout. Doctor support have been notified and will take action.',
  [PrescriptionStatus.Cancellation_rejected]:
    'An unsuccessful system response for the cancellation request was received. Doctor support have been notified and will take action.',
  [PrescriptionStatus.Cancellation_timeout]:
    'The cancellation request timedout before a system response was received. Doctor support have been notified and will cancel this manually.',
};

export enum PrescriptionCancelResult {
  Aborted,
  Success,
  Error,
}

export interface PrescriptionGuid {
  value: string;
  hasValue: boolean;
}

export class Prescription {
  prescriptionId: string;
  pharmacyNote: string;
  status: PrescriptionStatus;
  medicationStatus: PrescriptionStatus;
  statusReason: string;
  prescriptionGuid: PrescriptionGuid;
  submissionDate: Date;
  authorisationDate: Date;
  dosage: string;
  duration: number;
  snomedCode: string;
  medicationName: string;
  unitTypeDescription: string;
  patientNote: string;
  quantity: number;
  cancelled: boolean;
  cancellationReason: string;
  type: string;
  endorsement: string;
  startTime: Date;
  medicationId: string;
}
