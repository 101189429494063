<pushdr-consultation-card
  [hasData]="!!data.problems"
  [showFooter]="!!data.problems && !!data.problems.error"
  *ngIf="{ problems: recordSectionData$ | async } as data">
  <span header> Problems </span>
  <ng-container body>
    <pushdr-patient-records-table
      [data]="data.problems"
      *ngIf="data.problems && !data.problems.error; else error">
    </pushdr-patient-records-table>
    <ng-template #error>
      <p>There was an issue retrieving this information.</p>
    </ng-template>
  </ng-container>
  <ng-container footer>
    <div class="p-2">
      <button class="btn btn-error" (click)="getRecordData()">
        <span> Try again </span>
      </button>
    </div>
  </ng-container>
</pushdr-consultation-card>
