<ng-container *ngIf="medicationData$ | async as medData; else loading">
  <pushdr-equivalents-select
    *ngIf="medData.isGenericNameNotRecommendedForPrescribing && !genericPicked"
    [medication]="medData"
    [equivalents]="medData.equivalents"
    (selectedEquivalent)="selectEquivalent($event)"
    [genericAllowed]="medData.isGenericNameNotRecommendedForPrescribing">
  </pushdr-equivalents-select>
  <pushdr-prescription-form
    *ngIf="!medData.isGenericNameNotRecommendedForPrescribing || genericPicked"
    [medication]="medData"
    [isGeneric]="genericPicked"
    [inputData]="inputData"
    (formChanged)="onFormChanged($event)">
  </pushdr-prescription-form>
  <div class="m-2" *ngIf="medData?.monograph">
    <a (click)="viewMedicationInfo(medData)" class="text-indigo-500 cursor-pointer"
      >View medicine info</a
    >
  </div>
</ng-container>
<ng-template #loading>
  <pushdr-spinner-loader [minheight]="0"></pushdr-spinner-loader>
</ng-template>
