<nav>
  <a
    *ngFor="let step of steps$ | async"
    [class.pointer-events-none]="noTouch"
    class="nav-item"
    [routerLink]="[step.path]"
    routerLinkActive="active">
    <i class="nav-icon" [ngClass]="step.icon"></i>
    <span class="text-sm font-medium text-body-text">{{ step.title }}</span>
  </a>
</nav>
