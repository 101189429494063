import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  RestClient,
  RestErrorParserService,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';
import { StorageService, toCamelCase } from '@pushdr/common/utils';
import { EnvironmentProxyService } from '@pushdr/environment';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import {
  ClinicalOutcome,
  ClinicalOutcomeList,
  ReferralType,
  ClinicianReferralTypesList,
} from './interfaces';

@Injectable({
  providedIn: 'root',
})
export class ApiDoctorsConsultationNew extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService,
    protected storage: StorageService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.doctors.newApi + '/';
  }

  getClinicalOutcomes(): Observable<ClinicalOutcome[]> {
    return this.get<ClinicalOutcomeList>(
      'clinicalOutcome',
      {},
      this.headerService.authorisedHeadersLegacy
    ).pipe(pluck('ClinicalOutcomeDetails'), toCamelCase());
  }

  getReferralTypes(partnerId: string): Observable<ReferralType[]> {
    return this.post<ClinicianReferralTypesList>(
      'referralServices',
      { PartnerId: partnerId },
      this.headerService.authorisedHeadersLegacy
    ).pipe(pluck('ReferralServices'), toCamelCase());
  }

  createClinicalOutcome(
    ClinicalOutcomeIds: number[],
    ReferralServiceId: number,
    ConsultationId: number
  ) {
    return this.post(
      'surgeryLineClinicalOutcome',
      {
        ClinicalOutcomeIds,
        ConsultationId,
        ReferralServiceId,
      },
      this.headerService.authorisedHeadersLegacy
    );
  }

  customerRelationVerification(
    OrderId: number,
    IdentificationType: number,
    RelatedCustomerId: number,
    PrimaryCustomerId: number
  ) {
    return this.post(
      'customerRelationVerification',
      {
        OrderId,
        IdentificationType,
        RelatedCustomerId,
        PrimaryCustomerId,
      },
      this.headerService.authorisedHeadersLegacy
    );
  }
}
