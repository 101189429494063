import { Component, Input, OnInit } from '@angular/core';
import { retryBackoff } from 'backoff-rxjs';
import * as moment from 'moment';
import { EMPTY, Observable, timer } from 'rxjs';
import { catchError, filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { VideoParameters } from '@pushdr/common/components';
import { AnalyticsBusService, AnalyticsEvent } from '@pushdr/common/data-access/analytics';
import { ModalService } from '@pushdr/common/overlay';
import { PatientDetailsService } from '../../../../services/patient-details/patient-details.service';
import { formattedDurationMMSS } from '../../../waiting-room/utils/time-since-as-string';
import { PatientNavigationService } from '../../services/patient-navigation/patient-navigation.service';
import { EndVideoSessionModalComponent } from '../end-video-session-modal/end-video-session-modal.component';
import { VideoCredentialsService } from './video-credentials.service';

@Component({
  selector: 'pushdr-video-chat',
  templateUrl: './video-chat.component.html',
  styleUrls: ['./video-chat.component.scss'],
})
export class VideoChatComponent implements OnInit {
  @Input() countFrom = moment().valueOf();

  patient$ = this.patientDetails.details$;

  isRecording$ = this.patient$.pipe(
    map(details => details.IsCallRecordingEnabled),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  videoSession$ = this.videoCredentials.videoSession.pipe(
    retryBackoff({
      initialInterval: 500,
      maxRetries: 3,
      shouldRetry: error => this.shouldRetry(error),
    }),
    catchError(err => this.onCatchError(err)),
    tap(videoSession => {
      if (videoSession?.status === 'connected') {
        this.logConnectVideo(videoSession.credentials);
      }
    })
  );

  connectedToPatient = false;
  fullScreenVideo = false;
  participants = 0;
  formattedCounter$: Observable<string>;

  constructor(
    private patientDetails: PatientDetailsService,
    private patientNav: PatientNavigationService,
    private modal: ModalService,
    private msgBus: AnalyticsBusService,
    private videoCredentials: VideoCredentialsService
  ) {}

  ngOnInit() {
    this.startTimer();
  }

  toggleFullscreen() {
    this.fullScreenVideo = !this.fullScreenVideo;
  }

  startTimer() {
    const startedAt = moment(this.countFrom);
    this.formattedCounter$ = timer(0, 1000).pipe(
      map(() => formattedDurationMMSS(startedAt, moment()))
    );
  }

  handlePartipantCountChange(participants) {
    this.participants = participants;
    this.connectedToPatient = this.connectedToPatient || this.participants > 1;
    if (this.participants > 2) {
      this.msgBus.trackEvent(
        AnalyticsEvent.info(
          'video-chat.double-doctor',
          'Double-doctor: ' +
            JSON.stringify({
              participants: this.participants,
            })
        )
      );
    }
  }

  get lostConnection() {
    return this.participants < 2 && this.connectedToPatient;
  }

  logError(msg: string, error) {
    this.msgBus.trackEvent(AnalyticsEvent.error('video-chat.error', msg + error));
  }

  endVideoSession(isRecording: boolean) {
    this.confirmEndVideoSession(isRecording).subscribe({
      error: err => this.modal.error(err?.message, 'end-video-error'),
    });
  }

  private confirmEndVideoSession(isRecording: boolean): Observable<boolean> {
    return this.modal.showCustom(EndVideoSessionModalComponent, { isRecording }).pipe(
      filter(endVideoSession => endVideoSession === true),
      switchMap(() => this.videoCredentials.stopVideoSession())
    );
  }

  private onCatchError(error) {
    this.logError('Video chat init error: ', error);
    return this.modal
      .error(
        'Something went wrong, we are returning you to the waiting room. If the problem persists please contact customer experience'
      )
      .pipe(
        switchMap(() => {
          this.patientNav.gotoWaitingRoom();
          this.msgBus.trackEvent(
            AnalyticsEvent.info('video-chat.patientToWaiting', 'Patient returned to waiting room')
          );
          return EMPTY;
        })
      );
  }

  private shouldRetry(error) {
    if (error.status) {
      return [400].indexOf(parseInt(error.status, 10)) !== -1;
    }
    return false;
  }

  private logConnectVideo(cred: VideoParameters) {
    const params = {
      AccountId: cred.AccountId,
      RoomId: cred.RoomId,
      RoomUserId: cred.RoomUserId,
      VideoProvider: cred.VideoProvider,
    };
    this.msgBus.trackEvent(
      AnalyticsEvent.info('video-chat.Init', 'Video Connection Params: ' + JSON.stringify(params))
    );
  }
}
