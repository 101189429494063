import { Component, OnInit } from '@angular/core';
import { PatientDetailsService } from '../../../../../services/patient-details/patient-details.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { merge, Observable, of, Subject, timer } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PatientNavigationService } from '../../../services/patient-navigation/patient-navigation.service';
import {
  ApiDoctorsConsultationDocumentsService,
  Referral,
} from '@pushdr/doctors/data-access/doctors-api';
import {
  take,
  distinctUntilChanged,
  tap,
  switchMap,
  map,
  debounceTime,
  startWith,
  catchError,
  filter,
} from 'rxjs/operators';
import * as moment from 'moment';
import { PatientDetailsV2 } from '@pushdr/common/types';
import { AnalyticsBusService, AnalyticsEvent } from '@pushdr/common/data-access/analytics';
import { ModalService } from '@pushdr/common/overlay';

export enum Stage {
  READY = 'Ready',
  LOADING = 'Loading',
  SENDING = 'Sending',
  SENT = 'Sent',
}

@UntilDestroy()
@Component({
  selector: 'pushdr-consultation-referral',
  templateUrl: './consultation-referral.component.html',
  styleUrls: ['./consultation-referral.component.scss'],
})
export class ConsultationReferralComponent implements OnInit {
  patientDetails$: Observable<PatientDetailsV2>;
  private saveTrigger$ = new Subject<void>();
  referralReceived$: Observable<Referral>;
  private referralCreated$ = new Subject<Referral>();

  referralForm: UntypedFormGroup;
  errorMessage = '';
  currentStage = Stage.LOADING;
  Stage = Stage;
  todaysDate = moment();
  submitted = false;
  surgeryId: string;
  emailCorrect: boolean | null = null;
  referralId: string;
  referral: Referral;
  referralExists = false;
  private changesToSave = false;
  updating = false;
  errorUpdating = false;

  constructor(
    private patientDetailsService: PatientDetailsService,
    private fb: UntypedFormBuilder,
    private patientNav: PatientNavigationService,
    private api: ApiDoctorsConsultationDocumentsService,
    private msgBus: AnalyticsBusService,
    private modal: ModalService
  ) {}

  ngOnInit() {
    this.referralForm = this.fb.group({
      content: ['', [Validators.required, Validators.min(1)]],
      emailConfirmed: [false, [Validators.requiredTrue]],
    });

    this.patientDetails$ = this.patientDetailsService.details$.pipe(
      tap(details => (this.surgeryId = details.Surgery.Id))
    );

    this.referralReceived$ = this.api.getReferral().pipe(catchError(() => of(null)));

    merge(this.referralReceived$, this.referralCreated$)
      .pipe(
        filter(referral => !!referral),
        tap(() => (this.currentStage = Stage.READY)),
        tap(referral => (this.referralExists = !!referral)),
        map(referral => (this.referral = referral)),
        tap(referral => {
          this.referralForm.patchValue({ content: referral.Body }, { emitEvent: false });
          this.msgBus.trackEvent(
            AnalyticsEvent.info(`consultation-referral.letter-started`, 'Referral letter started')
          );
          this.currentStage = Stage.READY;
        }),
        switchMap(() =>
          this.updateTriggers$().pipe(
            tap(() => this.updateReferral$().pipe(untilDestroyed(this)).subscribe())
          )
        ),
        untilDestroyed(this)
      )
      .subscribe(() => (this.changesToSave = false));
  }

  createReferral(isEmailCorrect: boolean) {
    if (!isEmailCorrect) return;
    const initialReferral = {
      SurgeryId: this.surgeryId,
      Body: '',
    };
    this.api
      .createReferralLetter(initialReferral)
      .pipe(
        untilDestroyed(this),
        tap(id => this.referralCreated$.next({ ...initialReferral, ReferralLetterId: id }))
      )
      .subscribe();
  }

  manualSave() {
    this.referralForm.markAsDirty();
    this.saveTrigger$.next();
  }

  sendReferral() {
    this.submitted = true;
    if (this.currentStage === Stage.SENDING) return;
    if (!this.referralForm.valid) return;
    this.errorMessage = '';
    this.currentStage = Stage.SENDING;

    this.api
      .sendReferralLetter(this.referral.ReferralLetterId)
      .pipe(
        tap(() =>
          this.msgBus.trackEvent(
            AnalyticsEvent.info(
              `consultation-referral.send-letter`,
              'Send referral letter: ' + this.referral.ReferralLetterId
            )
          )
        ),
        take(1)
      )
      .subscribe({
        next: () => {
          this.referralForm.reset();
          this.currentStage = Stage.SENT;
        },
        error: err => {
          this.errorMessage = err.message || 'Failed to send referral.';
          this.currentStage = Stage.READY;
        },
      });
  }

  updateReferral$() {
    if (!this.referral || !this.changesToSave || this.submitted) return of(null);
    this.updating = true;
    this.referral = {
      ...this.referral,
      Body: this.referralForm.value.content,
    };
    return this.api
      .updateReferralLetter({ ...this.referral, ReferralLetterId: this.referral.ReferralLetterId })
      .pipe(
        map(() => {
          this.updating = false;
          this.errorUpdating = false;
        }),
        catchError(() => {
          this.updating = false;
          this.errorUpdating = true;
          this.modal.error(
            'There has been an issue saving this patients referral letter, please contact support if this problem persists'
          );
          return of(null);
        })
      );
  }

  private updateTriggers$() {
    return merge(
      this.referralForm.valueChanges.pipe(
        distinctUntilChanged(),
        tap(() => (this.changesToSave = true)),
        debounceTime(1000)
      ),
      this.saveTrigger$.pipe(startWith(true)),
      timer(100, 5000)
    );
  }

  onClickBackToConsultation() {
    this.patientNav.gotoConsultationHome();
  }
}
