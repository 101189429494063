import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CurrentOrderIdService } from '@pushdr/common/data-access/analytics';
import {
  RestClient,
  RestErrorParserService,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';
import { toCamelCase } from '@pushdr/common/utils';
import { EnvironmentProxyService } from '@pushdr/environment';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

export interface HistoricConsultationSummary {
  intConsultationID: number;
  objLetters: any[];
  objPrescriptions: any[];
  objSickNotes: any[];
  strConsultationDate: string;
}

export interface FitNote {
  Condition: string;
  Comments: string;
  TimeOffDays: number;
  SurgeryId: string;
  FitForWork: boolean;
  WorkAdvice: {
    PhasedReturn: boolean;
    AmendedDuties: boolean;
    AlteredHours: boolean;
    WorkplaceAdaptation: boolean;
  };
  FitNoteId?: string;
}

export interface PdfDocument {
  docBase64: string;
}

export interface Referral {
  SurgeryId: string;
  Body: string;
  ReferralLetterId?: string;
}
@Injectable({
  providedIn: 'root',
})
export class ApiDoctorsConsultationDocumentsService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService,
    private order: CurrentOrderIdService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return (
      this.proxy.environment.doctors.api + '/consultationdocuments.svc/consultationdocumentsW/'
    );
  }

  getHistoricConsultationSummary(orderId: number): Observable<HistoricConsultationSummary[]> {
    return this.post(
      'GetHistoricConsultationSummary',
      { orderId },
      this.headerService.authorisedHeadersLegacy
    ).pipe(pluck('GetHistoricConsultationSummaryResult'));
  }

  getFitNote(): Observable<FitNote> {
    return this.post(
      'GetFitNote',
      { request: { OrderId: this.order.id } },
      this.headerService.authorisedHeadersLegacy
    ).pipe(pluck('GetFitNoteResult'));
  }

  createFitNote(fitNoteObj: FitNote): Observable<string> {
    return this.post(
      'CreateFitNote',
      { request: fitNoteObj },
      this.headerService.authorisedHeadersLegacy
    ).pipe(pluck('CreateFitNoteResult'), pluck('Id'));
  }

  updateFitNote(fitNoteObj: FitNote): Observable<string> {
    return this.post(
      'UpdateFitNote',
      { request: fitNoteObj },
      this.headerService.authorisedHeadersLegacy
    ).pipe(pluck('CreateFitNoteResult'));
  }

  generateFitNotePDF(fitNoteId: string) {
    return this.post(
      'GenerateFitNotePdf',
      { request: { FitNoteId: fitNoteId } },
      this.headerService.authorisedHeadersLegacy
    ).pipe(pluck('GenerateFitNotePdfResult'));
  }

  sendFitNoteToPatient(fitNoteId: string) {
    return this.post(
      'SendFitNoteToPatient',
      { request: { FitNoteId: fitNoteId } },
      this.headerService.authorisedHeadersLegacy
    ).pipe(pluck('SendFitNoteToPatientResult'));
  }

  getReferral(): Observable<Referral> {
    return this.post(
      'GetReferralLetter',
      { request: { OrderId: this.order.id } },
      this.headerService.authorisedHeadersLegacy
    ).pipe(pluck('GetReferralLetterResult'));
  }

  createReferralLetter(referralObj: Referral) {
    return this.post(
      'CreateReferralLetter',
      { request: referralObj },
      this.headerService.authorisedHeadersLegacy
    ).pipe(pluck('CreateReferralLetterResult'), pluck('Id'));
  }

  updateReferralLetter(referralObj: Referral): Observable<string> {
    return this.post(
      'UpdateReferralLetter',
      { request: referralObj },
      this.headerService.authorisedHeadersLegacy
    ).pipe(pluck('UpdateReferralLetterResult'), pluck('Id'));
  }

  generateReferralLetterPdf(referralLetterId: string) {
    return this.post(
      'GenerateReferralLetterPdf',
      { request: { ReferralLetterId: referralLetterId } },
      this.headerService.authorisedHeadersLegacy
    ).pipe(pluck('GenerateReferralLetterPdfResult'));
  }

  sendReferralLetter(referralLetterId: string) {
    return this.post(
      'SendReferralLetterPdf',
      { request: { ReferralLetterId: referralLetterId } },
      this.headerService.authorisedHeadersLegacy
    ).pipe(pluck('SendReferralLetterResult'));
  }

  downloadFitNotePdf(fitNoteId: string): Observable<PdfDocument> {
    return this.post(
      'DownloadFitNotePdf',
      { request: { FitNoteId: fitNoteId } },
      this.headerService.authorisedHeadersLegacy
    ).pipe(pluck('DownloadFitNotePdfResult'), toCamelCase());
  }

  downloadReferralLetterPdf(referralLetterId: string) {
    return this.post(
      'DownloadReferralLetterPdf',
      { request: { ReferralLetterId: referralLetterId } },
      this.headerService.authorisedHeadersLegacy
    ).pipe(pluck('DownloadReferralLetterPdfResult'), toCamelCase());
  }
}
