import { Injectable } from '@angular/core';
import { ConsultationState, ConsultationStateService } from '@pushdr/clinicians/common';
import { ModalService } from '@pushdr/common/overlay';
import {
  AnalyticsBusService,
  AnalyticsEvent,
  CurrentOrderIdService,
  LogService,
} from '@pushdr/common/data-access/analytics';
import {
  ApiDoctorsConsultation as ApiCliniciansConsultation,
  ClinicalOutcome,
  ClinicalOutcomeResultTypeEnum,
  EndConsultationStatus,
} from '@pushdr/doctors/data-access/doctors-api';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, mapTo, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { CloseEditConsultationModalComponent } from '../../routes/patient/components/close-edit-consultation-modal/close-edit-consultation-modal.component';
import { NotesPdfViewModalComponent } from '../../routes/patient/routes/notes/nhs/notes-pdf-view-modal/notes-pdf-view-modal.component';
import { ConsultationNotesService } from '../../routes/patient/services/consultation-notes/consultation-notes.service';
import { PatientConsultationService } from '../../routes/patient/services/patient-consultation/patient-consultation.service';
import { PatientNavigationService } from '../../routes/patient/services/patient-navigation/patient-navigation.service';
import { ConsultationFeatureService } from '../consultation-feature/consultation-feature.service';
import { ConsultationIncompleteActionsModalComponent } from '../../routes/patient/components/consultation-incomplete-actions-modal/consultation-incomplete-actions-modal.component';
import { ConsultationSummaryService } from '../../routes/summary/services/consultation-summary/consultation-summary.service';
import { StorageService } from '@pushdr/common/utils';
import { EndConsultationResults } from './models';
import { PrescriptionBasketService } from '@pushdr/prescription/shell';

export enum EndConsultationPlaceEnum {
  TOP_MENU = 'header',
  FOOTER = 'footer',
}

interface HelpInputParams {
  state: ConsultationState;
  reasonType: ClinicalOutcomeResultTypeEnum;
  clinicalOutcomeIds: ClinicalOutcome['id'][];
  referralServiceId: number;
}

@Injectable({
  providedIn: 'root',
})
export class CliniciansEndConsultationService {
  constructor(
    private modal: ModalService,
    private api: ApiCliniciansConsultation,
    private consultation: PatientConsultationService,
    private patientNav: PatientNavigationService,
    private consultationFeatures: ConsultationFeatureService,
    private notesService: ConsultationNotesService,
    private consultationState: ConsultationStateService,
    private basket: PrescriptionBasketService,
    private order: CurrentOrderIdService,
    private msgBus: AnalyticsBusService,
    private summaryService: ConsultationSummaryService,
    private storage: StorageService,
    private logger: LogService
  ) {}

  endConsultation(
    clinicalOutcomeIds: ClinicalOutcome['id'][],
    reasonType: ClinicalOutcomeResultTypeEnum,
    place: EndConsultationPlaceEnum,
    referralServiceId: number
  ): Observable<boolean> {
    return this.consultationState.state$.pipe(
      take(1),
      mergeMap(state =>
        this.endSwitch({ state, reasonType, clinicalOutcomeIds, referralServiceId })
      ),
      tap(this.trackAnalyticsCfg(place))
    );
  }

  private endSwitch(data: HelpInputParams): Observable<boolean> {
    switch (data.state) {
      case ConsultationState.EDITING:
        return this.endEditConsultation$();
      case ConsultationState.CONSULTING:
      case ConsultationState.VERIFYING_IDENTITY:
        return this.fullConsultationEnd$(data);
      default:
        return of(false);
    }
  }

  private endEditConsultation$() {
    return this.modal.showCustom(CloseEditConsultationModalComponent).pipe(
      mergeMap(data => this.logger.uploadLogStore().pipe(mapTo(data))),
      tap(x => {
        if (x) {
          this.consultation.stop();
          this.patientNav.gotoDashboard();
        }
      })
    );
  }

  private trackAnalyticsCfg(place: EndConsultationPlaceEnum) {
    const tgtSuccess =
      place === EndConsultationPlaceEnum.FOOTER
        ? 'patient.end-consultation'
        : 'consultation.menu.end-consultation';

    const tgtError =
      place === EndConsultationPlaceEnum.FOOTER
        ? 'patient.end-consultation-error'
        : 'consultation.menu.end-consultation-error';

    return {
      next: endStatus => {
        this.msgBus.trackEvent(
          AnalyticsEvent.info(tgtSuccess, `end consultation status: ${endStatus}`)
        );
      },
      error: error => {
        console.error('Problem while ending consultation', error.message);
        this.msgBus.trackEvent(AnalyticsEvent.error(tgtError, error.message));
      },
    };
  }

  fullConsultationEnd$(data: HelpInputParams): Observable<boolean> {
    return this.handleEndConsultationStatus$(data.reasonType).pipe(
      filter(clinicalOutcomeResultType => clinicalOutcomeResultType !== null),
      switchMap(clinicalOutcomeResultType => {
        const endStatus = this.convertClinicalOutcomeToEndStatus(clinicalOutcomeResultType);
        return this.api
          .endConsultation(this.order.id, endStatus)
          .pipe(map((consultationId: number) => consultationId));
      }),
      tap(consultationId => this.consultation.stop()),
      switchMap(consultationId => {
        return this.summaryService
          .createClinicalOutcome(data.clinicalOutcomeIds, data.referralServiceId, consultationId)
          .pipe(
            tap(() => {
              if (data.reasonType !== ClinicalOutcomeResultTypeEnum.Success) {
                this.msgBus.trackEvent(
                  AnalyticsEvent.info(
                    `disrupted.speed-result`,
                    this.storage.get('cachedSpeedTestResult')
                  )
                );
              }
            }),
            tap(() => this.patientNav.gotoToAdditionalNotes())
          );
      }),
      catchError(err => {
        return this.handleNetworkErrors('save', err).pipe(
          tap(isContinueClicked => isContinueClicked && this.patientNav.gotoToAdditionalNotes())
        );
      }),
      map(() => true)
    );
  }

  private canEndConsultationSuccessfully$(): Observable<boolean> {
    return this.canEndNhsConsultationSuccessfully$();
  }

  private canEndNhsConsultationSuccessfully$() {
    const redirect = (res?: EndConsultationResults) => {
      if (res && !res?.prescription?.success) {
        this.patientNav.gotoPrescription();
      } else {
        this.patientNav.gotoNotesCapture();
      }
    };
    const checkIncompleteConsultation$ = this.getNhsEndConsultationStatus$().pipe(
      mergeMap(res => {
        return this.checkIncompleteConsultation$(res, redirect);
      })
    );

    const acceptNotesReport$ = this.consultationFeatures.canTakeNotes$.pipe(
      mergeMap(canTakeNotes => {
        if (canTakeNotes) {
          return this.modal.showCustom(NotesPdfViewModalComponent).pipe(
            map(acceptedReport => !!acceptedReport),
            tap(x => {
              if (!x) redirect();
            })
          );
        } else {
          return of(true);
        }
      })
    );

    return checkIncompleteConsultation$.pipe(
      mergeMap(endConsultation => {
        if (endConsultation) {
          return acceptNotesReport$;
        }
        return of(false);
      })
    );
  }

  private getNhsEndConsultationStatus$(): Observable<EndConsultationResults> {
    return combineLatest([
      this.basket.hasPrescriptions$,
      this.consultationFeatures.canTakeNotes$,
      this.notesService.hasValidNotes$,
    ]).pipe(
      take(1),
      map(([hasItems, canTakeNotes, hasValidNodes]) => {
        const res: EndConsultationResults = {
          notes: { success: !canTakeNotes || hasValidNodes },
          prescription: { success: !hasItems },
        };
        return res;
      })
    );
  }

  private checkIncompleteConsultation$(
    res: EndConsultationResults,
    callback?: (res?: EndConsultationResults) => void
  ) {
    if (res?.notes?.success && res?.prescription?.success) {
      return of(true);
    } else {
      return this.showIncompleteConsultationModal$(res, callback);
    }
  }

  private showIncompleteConsultationModal$(
    res: EndConsultationResults,
    callback?: (res?: EndConsultationResults) => void
  ) {
    return this.modal.showCustom(ConsultationIncompleteActionsModalComponent, res).pipe(
      map(endConsultation => {
        if (endConsultation) {
          return true;
        }
        if (!res?.notes?.success) {
          callback?.(res);
        }
        if (!res?.prescription?.success) {
          callback?.(res);
        }
        return false;
      })
    );
  }

  private handleEndConsultationStatus$(
    outcomeResultType: ClinicalOutcomeResultTypeEnum
  ): Observable<ClinicalOutcomeResultTypeEnum> {
    switch (outcomeResultType) {
      case ClinicalOutcomeResultTypeEnum.Success:
        return this.canEndConsultationSuccessfully$().pipe(
          map(canEndConsultation => {
            if (canEndConsultation) {
              return outcomeResultType;
            }
            return null;
          })
        );
      case ClinicalOutcomeResultTypeEnum.Disruption:
      case ClinicalOutcomeResultTypeEnum.Discontinuation:
        return of(outcomeResultType);
      default:
        return throwError(new Error('Unmapped consultation reason - ' + outcomeResultType));
    }
  }

  private convertClinicalOutcomeToEndStatus(
    val: ClinicalOutcomeResultTypeEnum
  ): EndConsultationStatus {
    switch (val) {
      case ClinicalOutcomeResultTypeEnum.Success:
        return EndConsultationStatus.COMPLETED_SUCCESSFULLY;
      case ClinicalOutcomeResultTypeEnum.Discontinuation:
        return EndConsultationStatus.COMPLETED_SUCCESSFULLY;
      case ClinicalOutcomeResultTypeEnum.Disruption:
        return EndConsultationStatus.DISRUPTED;
      default:
        return EndConsultationStatus.DISRUPTED;
    }
  }

  handleNetworkErrors(action: 'save' | 'load', err?): Observable<any> {
    this.msgBus.trackEvent(AnalyticsEvent.error(`disrupted.${action}`, err));
    return this.modal.confirm(
      'Something went wrong',
      `We were unable ${action} to your disruption reasons for this consultation, try again or continue.`,
      'Continue',
      'Try again'
    );
  }
}
