import { Injectable } from '@angular/core';

import {
  MedicationsService,
  PatientDetailsService,
  PatientRecordsService,
} from '@pushdr/clinicians/consultation';

import { PrescriptionProxy } from '@pushdr/prescription/shell';

@Injectable({
  providedIn: 'root',
})
export class AppPrescriptionProxy extends PrescriptionProxy {
  getPatientDetails = () => this.details.details$;
  getStructuredRecords = () => this.records.getStructuredPatientRecord();
  getCurrentAcuteMedications = () => this.medications.getEpsCurrentAcuteMedications();

  constructor(
    private details: PatientDetailsService,
    private records: PatientRecordsService,
    private medications: MedicationsService
  ) {
    super();
  }
}
