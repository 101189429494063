<div class="w-full h-full p-2">
  <pushdr-consultation-card
    [showFooter]="true"
    [greyHeader]="false"
    [hasData]="(isLoading$ | async) === false"
    bodyPadding="pt-0">
    <div header class="history-card-header">
      <div
        class="flex justify-between pt-5 pb-3 mx-5 text-xl border-b border-bluegrey-300 text-bluegrey-800">
        <div>Prescribed medications</div>
        <div *ngIf="(isLoading$ | async) === false">
          {{ (consultation$ | async)?.createdDate | britishDate: 'dddd MMM D - h:mm A' }}
        </div>
      </div>
      <div class="flex items-center justify-between px-5 mt-4">
        <pushdr-clinicians-consultation-patient-details [consultation]="consultation$ | async">
        </pushdr-clinicians-consultation-patient-details>
        <button class="hidden p-3 pl-5 pr-5 btn btn-outline" [disabled]="true">
          View consultation report
        </button>
      </div>
      <div class="flex items-stretch pr-4 mt-10">
        <table class="w-full">
          <thead>
            <tr class="text-base leading-none text-left text-grey-700">
              <th class="w-2/12 p-3 pl-5 font-medium whitespace-nowrap">Status</th>
              <th class="w-3/12 p-3 font-medium whitespace-nowrap">
                Medication name/strength/form
              </th>
              <th class="w-1/12 p-3 font-medium whitespace-nowrap">Quantity</th>
              <th class="w-4/12 p-3 font-medium whitespace-nowrap">Dosage</th>
              <th class="w-2/12 p-3 font-medium whitespace-nowrap">Prescription ID</th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
    <div body class="min-h-full overflow-y-scroll">
      <pushdr-clinicians-prescribed-medications
        [prescriptions]="(consultation$ | async)?.prescriptions"
        [selectedItems]="selectedItems">
      </pushdr-clinicians-prescribed-medications>
    </div>
    <div footer class="p-4">
      <button class="p-3 px-5 mr-10 btn btn-outline" (click)="onClickBackToConsultationsList()">
        Back
      </button>
      <button
        class="p-3 px-5 btn btn-primary"
        [disabled]="!selectedItems.size"
        *ngIf="canCancel$ | async"
        (click)="onClickCancelSelected()">
        Cancel selected
      </button>
    </div>
  </pushdr-consultation-card>
</div>
