<div class="c-modal__wrap form-group-modal">
  <h2 *ngIf="data.header">{{ data.header }}</h2>

  <h4 *ngIf="data.subheader" class="text-center">{{ data.subheader }}</h4>

  <form
    *ngIf="formGroup"
    [formGroup]="formGroup"
    (submit)="formGroup?.valid && done(formGroup?.value)"
    class="w-3/4 m-auto">
    <div class="modal__body">
      <ng-container *ngFor="let cfg of data.formControlOptions; trackBy: 'name' | trackByProperty">
        <label *ngIf="cfg.name" for="form-group-modal-{{ cfg.name }}">
          {{ cfg.label }}
          <span class="text-red-500">*</span>
        </label>

        <ng-container [ngSwitch]="cfg.type" *ngVar="formGroup.get(cfg.name) as control">
          <ng-container *ngSwitchCase="ControlTypeEnum.SELECT">
            <ng-select
              class="w-full"
              placeholder="Partner"
              notFoundText="No matching results"
              [formControl]="control">
              <ng-option *ngFor="let item of cfg.options" [value]="item.key">{{
                item.value
              }}</ng-option>
            </ng-select>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <input
              formControlName="{{ cfg.name }}"
              id="form-group-modal-{{ cfg.name }}"
              type="text"
              name="{{ cfg.name }}"
              class="pdr-input rounded-md border-bluegrey-600"
              [class.pdr-input--error]="control.touched && control.errors" />
          </ng-container>
          <p *ngIf="control.touched && control.errors">The provided value is invalid</p>
        </ng-container>
      </ng-container>
    </div>

    <div class="modal__footer flex justify-between">
      <button
        class="c-btn c-btn--outline border-indigo-500 text-indigo-500 rounded-md w-32"
        type="cancel"
        (click)="done(null)">
        Cancel
      </button>
      <button class="c-btn c-btn--primary w-32" type="submit" [disabled]="formGroup?.invalid">
        Confirm
      </button>
    </div>
  </form>
</div>
