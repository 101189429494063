<div *ngIf="status$ | async as networkStatus">
  <p class="px-6 py-1 mb-0 text-grey-700">Network</p>
  <div class="px-6 py-3 font-medium bg-information-light">
    <span class="block mb-1"
      >Speed: {{ loading ? '...' : (networkStatus.result.speed | number) }}</span
    >
    <span class="block mb-1"
      >Ping: {{ loading ? '...' : (networkStatus.result.ping | number) }}</span
    >
    <span class="block">Status: {{ loading ? '...' : networkStatus.status }}</span>
  </div>
  <div class="px-4 text-center">
    <button
      class="flex w-full p-1 px-4 m-auto mt-3 text-xs btn btn-primary btn-tiny"
      data-cy="run-test"
      (click)="test()">
      <span class="mr-2 material-icons"> network_check </span>
      <span class="mx-auto">
        {{ loading ? 'Running test' : 'Run network test' }}
      </span>
    </button>
  </div>
</div>
