<div class="absolute inset-0 flex flex-col calendar">
  <div class="flex flex-row items-center justify-center calendar-nav">
    <div class="calendar-nav-previous-month">
      <button
        class="p-4 text-body-text hover:text-secondary"
        (click)="changeNavMonth(-1)"
        [disabled]="!canChangeNavMonth(-1)">
        <i class="material-icons">chevron_left</i>
      </button>
    </div>
    <div class="mx-12 text-sm text-body-text">{{ navDate.format('MMMM YYYY') }}</div>
    <div class="calendar-nav-next-month">
      <button
        class="p-4 text-body-text hover:text-secondary"
        (click)="changeNavMonth(1)"
        [disabled]="!canChangeNavMonth(1)">
        <i class="material-icons">chevron_right</i>
      </button>
    </div>
  </div>
  <div class="flex flex-col flex-grow calendar-container">
    <div
      class="grid grid-flow-row grid-cols-7 grid-rows-none text-center calendar-header text-bluegrey-600">
      <div class="px-3 py-2 text-sm calendar-date" *ngFor="let day of weekDaysHeaderArr">
        {{ day }}
      </div>
    </div>
    <div
      class="grid flex-grow grid-flow-row grid-cols-7 grid-rows-6 gap-1 text-center calendar-body">
      <div
        #gridSlot
        *ngFor="let day of gridArr"
        class="flex items-center justify-center border-4 border-transparent rounded-full outline-none cursor-pointer focus:border-primary hover:border-primary"
        [class.pointer-events-none]="!day.available"
        [class.border-secondary-light]="day.isToday"
        [class.bg-color-past]="day.isPast && day.isHighlighted"
        [class.bg-secondary]="day.isHighlighted"
        [class.text-grey-200]="!day.available"
        [class.text-body-text]="!day.isHighlighted && day.available"
        (focus)="onFocus(day)"
        (blur)="onBlur()"
        [tabindex]="day.available ? 0 : -1">
        <span class="date-item">
          {{ day.value }}
        </span>
      </div>
    </div>
  </div>
</div>
