import { Injectable, OnDestroy } from '@angular/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { isFinite } from 'lodash';

import { CurrentOrderIdService } from '@pushdr/common/data-access/analytics';
import { PharmaceuticalsMedication } from '@pushdr/prescription/api';
import { PrescriptionInputData } from '@pushdr/prescription/fdb';

import { PrescriptionBasketService } from './prescription-basket.service';
import { PrescriptionDispenserService } from './prescription-dispenser.service';
import { PrescriptionStorageService } from './prescription-storage.service';
import { PrescriptionStatusService } from './prescription-status.service';
import { Dispenser } from '@pushdr/prescription/api';

export interface Prescription {
  prescriptionItems: PrescriptionInputData[];
  dispenser?: Dispenser;
}

export interface Prescriptions {
  prescriptions: Prescription[];
  dispenser: Dispenser;
}

const MAXIMUM_ITEMS_PER_PRESCRIPTION = 4;
const FREE_OF_CHARGE_ENDORSEMENTS = ['CC', 'FS'];

@Injectable({
  providedIn: 'root',
})
export class PrescriptionService implements OnDestroy {
  private readonly prescriptionDetails$ = combineLatest([
    this.basket.prescriptions$,
    this.dispenser.dispenser$,
    this.dispenser.patientNotes$,
  ]);

  private readonly prescriptionChanges$ = this.prescriptionDetails$.pipe(
    map(prescriptionDetails => [...prescriptionDetails, this.order.id] as const),
    filter(([, , , orderId]) => isFinite(orderId) && orderId > 0)
  );

  private changesSubscription: Subscription;

  // TODO: Refactor "current" stuff to be more contextual to the components
  currentMedication: PharmaceuticalsMedication;
  currentOverriddenReason: string;

  constructor(
    private order: CurrentOrderIdService,
    private storage: PrescriptionStorageService,
    private basket: PrescriptionBasketService,
    private dispenser: PrescriptionDispenserService,
    private status: PrescriptionStatusService
  ) {
    this.changesSubscription = this.prescriptionChanges$.subscribe({
      next: ([items, dispenser, patientNotes, orderId]) => {
        const prescription = { items, dispenser, patientNotes };
        this.storage.storePrescription(orderId, prescription);
      },
    });
  }

  ngOnDestroy(): void {
    this.changesSubscription?.unsubscribe();
    this.changesSubscription = null;
  }

  initialize(): void {
    const orderId = this.order.id;
    const prescription = this.storage.restorePrescription(orderId);

    this.basket.setPrescriptions(prescription?.items ?? []);
    this.dispenser.setDispenser(prescription?.dispenser ?? null);
    this.dispenser.setPatientNotes(prescription?.patientNotes ?? '');
    this.status.updatePrescribedStatus(orderId);
  }

  getPrescriptions(): Observable<Prescriptions> {
    const freeItems$ = this.basket.prescriptions$.pipe(
      map(items => items.filter(isFreeOfChargePrescription)),
      map(freeItems => splitPrescriptions(freeItems, MAXIMUM_ITEMS_PER_PRESCRIPTION))
    );

    const regularItems$ = this.basket.prescriptions$.pipe(
      map(items => items.filter(item => !isFreeOfChargePrescription(item))),
      map(regularItems => splitPrescriptions(regularItems, MAXIMUM_ITEMS_PER_PRESCRIPTION))
    );

    return combineLatest([this.dispenser.dispenser$, freeItems$, regularItems$]).pipe(
      map(([dispenser, ...splittedChunks]) => {
        const prescriptions = (<Prescription[]>[]).concat(...splittedChunks);
        return { prescriptions, dispenser };
      })
    );
  }

  clearPrescription(): void {
    this.basket.setPrescriptions([]);
    this.dispenser.setDispenser(null);
    this.dispenser.setPatientNotes('');
    this.storage.clear();
  }
}

function isFreeOfChargePrescription(prescription: PrescriptionInputData) {
  if (prescription?.endorsements?.length) {
    return prescription.endorsements.some(endorsement =>
      FREE_OF_CHARGE_ENDORSEMENTS.includes(endorsement)
    );
  }
  return false;
}

function splitPrescriptions(prescriptions: PrescriptionInputData[], maxSizeEach: number) {
  return prescriptions.reduce((acc, prescription, index) => {
    const accIndex = Math.floor(index / maxSizeEach);
    acc[accIndex] = acc[accIndex] ?? { prescriptionItems: [] };
    acc[accIndex].prescriptionItems.push(prescription);
    return acc;
  }, <Prescription[]>[]);
}
