<pushdr-consultation-card
  *ngIf="{ previousConsultations: (previousConsultations$ | async) } as data"
  [hasData]="!!data.previousConsultations"
  [showFooter]="true">
  <span header>
    <span class="flex justify-between">Consultation history</span>
  </span>
  <ng-container body>
    <div
      *ngFor="let consultation of data.previousConsultations"
      class="flex justify-between p-2 border-b-2 border-grey-200">
      <div>
        <div class="mb-1 font-light title text-grey-500">Date</div>
        <div class="mb-0 text-bluegrey-800">{{ consultation.strConsultationDate }}</div>
      </div>
      <div class="flex flex-col justify-center">
        <a
          [routerLink]="['../view/', consultation.intConsultationID]"
          routerLinkActive="router-link-active"
          class="link">
          View notes
        </a>
      </div>
    </div>
  </ng-container>

  <ng-container footer>
    <div class="flex justify-between w-full p-2 px-8">
      <a [routerLink]="['../capture']" class="my-1 btn btn-outline"> Back </a>
    </div>
  </ng-container>
</pushdr-consultation-card>
