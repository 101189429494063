import { Component, OnInit, OnDestroy } from '@angular/core';
import { PatientDetailsService } from '../../../../../services/patient-details/patient-details.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { PatientNavigationService } from '../../../services/patient-navigation/patient-navigation.service';
import { ApiDoctorsConsultation, DoctorDetails } from '@pushdr/doctors/data-access/doctors-api';
import { take, distinctUntilChanged, takeUntil, tap, switchMap } from 'rxjs/operators';
import {
  ConsultationStorageService,
  State,
  CliniciansDoctorsDetailsService,
} from '@pushdr/clinicians/common';
import * as moment from 'moment';
import { PatientDetailsV2 } from '@pushdr/common/types';

export enum Stage {
  READY = 'Ready',
  LOADING = 'Loading',
  SENDING = 'Sending',
  SENT = 'Sent',
}

@Component({
  selector: 'pushdr-clinicians-patient-email-communication',
  templateUrl: './clinicians-patient-email-communication.component.html',
  styleUrls: ['./clinicians-patient-email-communication.component.scss'],
})
export class CliniciansPatientEmailCommunicationComponent implements OnInit, OnDestroy {
  patientDetails$: Observable<PatientDetailsV2>;
  doctorDetails$: Observable<DoctorDetails>;
  emailForm: UntypedFormGroup;
  errorMessage = '';
  stage = Stage.LOADING;
  destroy$ = new Subject<void>();
  todaysDate = moment();
  submitted = false;

  constructor(
    private patientDetailsService: PatientDetailsService,
    private fb: UntypedFormBuilder,
    private patientNav: PatientNavigationService,
    private api: ApiDoctorsConsultation,
    private consultationStorage: ConsultationStorageService,
    private doctorDetailsService: CliniciansDoctorsDetailsService
  ) {}

  ngOnInit() {
    this.emailForm = this.fb.group({
      content: ['', [Validators.required, Validators.min(1)]],
      emailConfirmed: [false, [Validators.requiredTrue]],
    });

    this.patientDetails$ = this.patientDetailsService.details$;
    this.doctorDetails$ = this.doctorDetailsService.details$;

    this.consultationStorage
      .getState$(State.EMAIL)
      .pipe(
        tap(content => {
          this.emailForm.patchValue({ content }, { emitEvent: false });
          this.stage = Stage.READY;
        }),
        switchMap(() =>
          this.emailForm.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$))
        )
      )
      .subscribe(emailForm => this.consultationStorage.setState(State.EMAIL, emailForm.content));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  sendEmail() {
    this.submitted = true;
    if (this.stage === Stage.SENDING) return;
    if (!this.emailForm.valid) return;
    this.errorMessage = '';
    this.stage = Stage.SENDING;
    this.api
      .sendPatientEmail(this.emailForm.get('content').value)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.emailForm.reset();
          this.stage = Stage.SENT;
        },
        error: () => {
          this.errorMessage = 'Failed to send email.';
          this.stage = Stage.READY;
        },
      });
  }

  onClickBackToConsultation() {
    this.patientNav.gotoConsultationHome();
  }
}
