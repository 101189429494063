import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { DoctorDetails } from '@pushdr/doctors/data-access/doctors-api';
import { ModalService } from '@pushdr/common/overlay';
import { DomSanitizer } from '@angular/platform-browser';
import { ClinicianProfileUpdate } from '@pushdr/common/types';

@Component({
  selector: 'pushdr-clinicians-edit-profile',
  templateUrl: './clinicians-edit-profile.component.html',
  styleUrls: ['./clinicians-edit-profile.component.scss'],
})
export class CliniciansEditProfileComponent implements OnInit {
  @Input() profileDetails: DoctorDetails;
  @Input() profileImage: any;
  @Output() updateProfile = new EventEmitter<ClinicianProfileUpdate>();

  aboutForm: UntypedFormGroup;
  fileToUpload: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modal: ModalService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.aboutForm = this.formBuilder.group({
      aboutText: ['', [Validators.required, Validators.min(1), this.noWhitespaceValidator]],
    });

    this.aboutForm.get('aboutText').setValue(this.profileDetails.aboutYou);
  }

  update() {
    let newAboutText = '';
    if (this.aboutForm.dirty) {
      newAboutText = this.removeLineBreaks(this.aboutForm.get('aboutText').value.trim());
      this.aboutForm.get('aboutText').setValue(newAboutText);
    }
    const newProfileImage = this.fileToUpload || null;
    this.fileToUpload = null;
    this.updateProfile.emit({ aboutMe: newAboutText, profileImage: newProfileImage });
  }

  removeLineBreaks(text: string): string {
    const reToRemoveLineBreaks = /\r?\n|\r/g;
    return text.replace(reToRemoveLineBreaks, ' ');
  }

  handleFileChange(event) {
    const file: File = event.target.files[0];
    if (file?.type !== 'image/jpeg' && file?.type !== 'image/png') {
      this.modal.error(
        'The file you have selected is not a supported file type. Please use a JPEG or a PNG for your profile photo.'
      );
      return false;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const previewImage = reader.result as string;
      this.profileImage = this.sanitizer.bypassSecurityTrustStyle(`url(${previewImage})`);
      this.fileToUpload = previewImage.split(',')[1];
    };
    return true;
  }

  noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }
}
