import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { take } from 'rxjs/operators';
import { PrescriptionDispenserService } from '../services/prescription-dispenser.service';

@Component({
  selector: 'pushdr-set-patient-notes',
  templateUrl: './set-patient-notes.component.html',
  styleUrls: ['./set-patient-notes.component.scss'],
})
export class SetPatientNotesComponent implements OnInit {
  patientNotesControl: UntypedFormControl;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private dispenser: PrescriptionDispenserService
  ) {}

  ngOnInit() {
    this.dispenser.patientNotes$.pipe(take(1)).subscribe({
      next: notes => (this.patientNotesControl = this.fb.control(notes)),
    });
  }

  navigateToBasket() {
    this.router.navigate(['../review-prescription'], { relativeTo: this.route });
  }

  setNote() {
    const notes = this.patientNotesControl.value;
    this.dispenser.setPatientNotes(notes);
    this.navigateToBasket();
  }
}
