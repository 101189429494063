<pushdr-card [hasData]="true">
  <section class="flex flex-col w-full h-full">
    <header class="pt-5 pb-3 mx-5 text-xl font-light border-b border-grey-300">
      Your schedule
    </header>
    <article class="flex-auto h-px px-5 py-3 overflow-y-auto scrollbar">
      <div class="flex flex-row h-full">
        <div class="w-1/3">
          <div class="flex flex-col">
            <h4
              *ngIf="!previewDate"
              class="mb-2 text-sm font-normal tracking-widest uppercase md:text-xs lg:text-sm text-bluegrey-700">
              Next Sessions
            </h4>
            <h4
              *ngIf="previewDate"
              class="mb-2 text-sm font-normal tracking-widest uppercase md:text-xs lg:text-sm text-bluegrey-700">
              Scheduled Sessions
            </h4>
            <ng-container *ngIf="listedSessions$ | async as sessionsList">
              <div *ngIf="sessionsList.length" class="flex flex-col">
                <div
                  *ngFor="let session of sessionsList"
                  class="relative w-full px-3 mb-3 text-center border rounded md:py-4 xl:py-6 md:text-xs lg:text-base text-body-text shift-card border-bluegrey-200">
                  <div class="absolute top-0 left-0 w-2 h-full rounded-l bg-secondary"></div>
                  <div class="leading-8 text-bluegrey-700">
                    {{ session.startTime.format('DD MMM YYYY') }}
                  </div>
                  <div>
                    {{ session.startTime.format('h.mma') }}&nbsp;-&nbsp;{{
                      session.endTime.format('h.mma')
                    }}
                  </div>
                </div>
              </div>
              <div *ngIf="!sessionsList.length" class="flex flex-col">
                <div
                  class="relative w-full px-3 mb-4 text-center border rounded md:py-4 xl:py-6 md:text-xs lg:text-base text-body-text border-bluegrey-200">
                  <div class="leading-8 text-bluegrey-700">
                    {{ previewDate?.format('DD MMM YYYY') }}
                  </div>
                  <div class="italic">Nothing Scheduled</div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="flex-grow pl-4">
          <div class="flex flex-col h-full">
            <h4
              class="mb-2 font-normal tracking-widest uppercase md:text-xs lg:text-sm text-bluegrey-700">
              MONTH OVERVIEW
              <span class="float-right text-xs text-bluegrey-700">
                <i class="inline-block w-2 h-2 mr-2 rounded-full bg-secondary"></i>
                working
              </span>
            </h4>
            <div
              class="flex-grow max-h-full p-4 border rounded calendar-container border-bluegrey-200">
              <pushdr-date-picker
                (emitSelectedDate)="onSelectedDate($event)"
                [highlightDates]="selectedMonthSessionDates$ | async"
                (emitChangedMonth)="onChangeMonth($event)"></pushdr-date-picker>
            </div>
          </div>
        </div>
      </div>
    </article>
  </section>
</pushdr-card>
