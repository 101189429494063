<article class="flex flex-col h-full" *ngIf="profileDetails">
  <div class="flex justify-between mb-6">
    <div class="profile-details">
      <div class="mb-5">
        <p class="mb-1 text-grey-700">Name</p>
        <p class="mb-1 font-medium text-bluegrey-800">{{ profileDetails.name }}</p>
      </div>
      <div class="mb-5">
        <p class="mb-1 text-grey-700">GMC Number</p>
        <p class="mb-1 font-normal text-bluegrey-800">{{ profileDetails.GMC }}</p>
      </div>
      <div class="mb-5">
        <p class="mb-1 text-grey-700">Mobile number</p>
        <p class="mb-1 font-normal text-bluegrey-800">{{ profileDetails.mobile }}</p>
      </div>
      <!-- <div class="mb-5">
                <p class="mb-1 text-grey-700">Clinician type</p>
                <p class="mb-1 font-normal text-bluegrey-800">N/A</p>
            </div> -->
    </div>
    <div>
      <div
        class="flex border-4 rounded cursor-pointer bg-grey-100 border-grey-100 profile-image"
        *ngIf="profileImage"
        [style.backgroundImage]="profileImage"
        (click)="profileImageUpload.click()">
        <div class="flex w-full opacity-0 bg-grey-800 hover:opacity-75">
          <div class="m-0 m-auto btn btn-primary btn-tiny">Change image</div>
          <input hidden type="file" #profileImageUpload (change)="handleFileChange($event)" />
        </div>
      </div>
    </div>
  </div>
  <div class="flex-grow profile-about">
    <form
      class="flex flex-col h-full"
      [formGroup]="aboutForm"
      (ngSubmit)="aboutForm.valid && update()">
      <p class="mb-1 text-grey-700">About you</p>
      <textarea
        #about
        type="text"
        name="aboutText"
        maxlength="125"
        placeholder="Please keep this professional..."
        formControlName="aboutText"
        class="self-stretch flex-grow m-0 pdr-input"></textarea>
      <div>
        <button
          class="mt-6 btn btn-primary btn-tiny"
          [class.bg-primary-disabled]="!aboutForm.valid"
          type="submit"
          [disabled]="!aboutForm.valid">
          Save changes
        </button>
        <p class="float-right mr-1 text-sm text-bluegrey-600">
          {{ about.value?.trim().length }}/125
        </p>
      </div>
    </form>
  </div>
</article>
