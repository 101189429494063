import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AbstractModal } from '@pushdr/common/overlay';

export interface IframeModal {
  title: string;
  linkToDocument: string;
  dateCreated?: string;
}

@Component({
  selector: 'pushdr-clinicians-iframe-modal',
  templateUrl: './iframe-modal.component.html',
  styleUrls: ['./iframe-modal.component.scss'],
})
export class CliniciansIframeModalComponent extends AbstractModal<IframeModal> implements OnInit {
  safeUrl: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {
    super();
  }

  ngOnInit() {
    this.sanitizeUrl();
  }

  output() {
    this.close();
  }

  private sanitizeUrl() {
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.linkToDocument);
  }
}
