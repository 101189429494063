<div class="pd-box">
  <header class="mb-8 text-base font-medium">Dev Tools</header>
  <section class="cols">
    <div class="col">
      <strong>Mocks</strong>
      <button
        *ngFor="let item of mocks1Buttons"
        (click)="item.toggle()"
        [class.active]="item.enabled()"
        class="slide-button">
        <div></div>
        <span [innerHTML]="item.buttonText"></span>
      </button>
    </div>
    <div class="col">
      <strong>Mocks</strong>
      <button
        *ngFor="let item of mocks2Buttons"
        (click)="item.toggle()"
        [class.active]="item.enabled()"
        class="slide-button">
        <div></div>
        <span [innerHTML]="item.buttonText"></span></button
      ><strong>Feature Overrides</strong>
      <button
        *ngFor="let item of featureButtons"
        (click)="item.toggle()"
        [class.active]="item.enabled()"
        class="slide-button">
        <div></div>
        <span>{{ item.buttonText }}</span>
      </button>
    </div>
    <div class="col">
      <strong>API Reroutes</strong>

      <div *ngFor="let reroute of reroutes" class="reroutes">
        <div>
          <label><input type="checkbox" [(ngModel)]="reroute.enabled" /> Enabled </label>
          <button type="button" (click)="deleteReroute(reroute)">Delete</button>
        </div>
        <div><label>From:</label><input type="text" [(ngModel)]="reroute.fromUrl" /></div>
        <div><label>To:</label><input type="text" [(ngModel)]="reroute.toUrl" /></div>
      </div>

      <div class="button-links">
        <button (click)="addReroute()" type="button">Add</button>
        <button (click)="saveReroute()" type="button">Save</button>
      </div>
    </div>

    <div class="col">
      <strong>Other Functions</strong>

      <button (click)="addPrescriptionItems()" class="other-btn">Add Prescription Items</button>
      <a
        href="/consultation/patient/consult/actions/prescribe/review-prescription"
        class="other-btn">
        Review Prescription -&gt;
      </a>
    </div>
    <div class="col alt-endpoints" *ngIf="false">
      <div>
        <strong>Alt Endpoints</strong>
        <a [routerLink]="[]" (click)="saveAltApis()">&nbsp; [Save]</a>
      </div>
      <div *ngFor="let item of altEndpoints">
        <label class="end-p-alt">
          <input type="checkbox" [(ngModel)]="item.enabled" />
          <div>
            <span>{{ item.label }}</span>
            <input type="text" [(ngModel)]="item.newValue" />
          </div>
        </label>
      </div>
    </div>
  </section>

  <div class="p-2 mt-2 rounded bg-grey-200 button-links">
    <button (click)="clearAllMocks()" class="warn">Clear All API Mocks and Features</button>
    <div class="flex-1"></div>
    <a href="/home/dashboard"> Dashboard &gt; </a>
    <button (click)="skipChecks()">Skip Checks &gt;</button>
    <button (click)="gotoConsultation()">Goto consultation &gt;</button>
  </div>

  <div class="flex-row justify-between mt-4 sm:block md:flex">
    <button
      class="p-2 pl-6 pr-6 bg-white border border-solid rounded hover:bg-grey-100 border-error text-error md:mr-2"
      (click)="reload()">
      Reload
    </button>
    <button
      class="p-2 pl-6 pr-6 bg-white border border-solid rounded hover:bg-grey-100 border-primary text-primary md:mr-2"
      (click)="done(false)">
      Close
    </button>
  </div>
</div>
