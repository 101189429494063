<div
  class="m-auto person-icon__animation-container"
  [ngClass]="{ 'person-icon__pulsing': isSearching, 'person-icon__found': isFound }"
  data-cy="container-icon-animation">
  <div class="person-icon" [ngClass]="{ 'person-icon__border': !isFound }">
    <img
      *ngIf="!isFound"
      src="/assets/icons/person_outline.svg"
      width="36"
      style="max-height: 36px"
      alt="finding your next patient" />
    <img
      *ngIf="isFound"
      src="/assets/icons/person_outline_24px.png"
      width="36"
      style="max-height: 36px"
      alt="finding your next patient" />
  </div>
</div>
