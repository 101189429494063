import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SpinnerLoaderModule } from '@pushdr/common/components';
import { CommonOverlayModule } from '@pushdr/common/overlay';
import { CliniciansBulletinsListComponent } from './bulletins-list/bulletins-list.component';
import { CliniciansBulletinsViewerComponent } from './bulletins-viewer/bulletins-viewer.component';
import { CliniciansIframeModalModule } from '../iframe-modal';
import { CliniciansBulletinsViewerModalComponent } from './bulletins-viewer-modal/bulletins-viewer-modal.component';
import { A11yModule } from '@angular/cdk/a11y';
import { CommonPipesModule } from '@pushdr/common/utils';

@NgModule({
  imports: [
    CommonModule,
    SpinnerLoaderModule,
    CommonOverlayModule,
    CliniciansIframeModalModule,
    A11yModule,
    CommonPipesModule,
  ],
  declarations: [
    CliniciansBulletinsViewerComponent,
    CliniciansBulletinsListComponent,
    CliniciansBulletinsViewerModalComponent,
  ],
  exports: [CliniciansBulletinsViewerComponent, CliniciansBulletinsListComponent],
})
export class CliniciansBulletinsModule {}
