import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clinicalOutcomeFilter',
})
export class ClinicalOutcomeFilterPipe implements PipeTransform {
  transform<T>(clinicalOutcomes: T[], property: string, value: any): T[] {
    return clinicalOutcomes?.filter(item => item[property] === value) || [];
  }
}
