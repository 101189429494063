import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SpinnerLoaderModule } from '@pushdr/common/components';
import { CanDeactivateComponentGuard } from '@pushdr/common/utils';
import { ConsultationSummaryComponent } from './consultation-summary.component';
import { AdditionalNotesComponent } from './routes/additional-notes/additional-notes.component';

export const consultationSummaryRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'additional-notes',
        pathMatch: 'full',
      },
      {
        path: 'additional-notes',
        component: AdditionalNotesComponent,
        canDeactivate: [CanDeactivateComponentGuard],
      },
    ],
  },
];

@NgModule({
  imports: [CommonModule, SpinnerLoaderModule, RouterModule],
  declarations: [AdditionalNotesComponent, ConsultationSummaryComponent],
})
export class ConsultationSummaryModule {}
