import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractModal } from '@pushdr/common/overlay';

@Component({
  selector: 'pushdr-good-practice-modal',
  templateUrl: './good-practice-modal.component.html',
  styleUrls: ['./good-practice-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoodPracticeModalComponent extends AbstractModal<any, boolean> {}
