<pushdr-consultation-card-dynamic [hasData]="!!(receivedImages$ | async)">
  <span header>Received Images</span>
  <div body class="grid grid-cols-2 gap-10">
    <div *ngFor="let chatMessage of receivedImages$ | async; trackBy: trackByMessageId" class="">
      <p class="date-bg mb-0 px-2 py-1 rounded-t">
        {{ chatMessage.receivedTime | isoDate: 'DD-MMM-YYYY HH:mm' }}
      </p>
      <div class="w-full relative rounded cursor-pointer received-image">
        <div
          *ngIf="chatMessage.image$ | async as chatImage"
          class="absolute inset-0 bg-cover bg-center"
          (click)="enlargeImage(chatImage.image, chatMessage.receivedTime)"
          [style.backgroundImage]="'url(' + chatImage.image + ')'"></div>
      </div>
    </div>
  </div>
</pushdr-consultation-card-dynamic>
