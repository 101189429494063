<a
  *ngIf="canTakeNotes$ | async"
  class="text-sm font-medium nav-item text-bluegrey-800 hover:bg-secondary hover:text-bluegrey-800"
  routerLink="/consultation/patient/consult/notes/nhs"
  routerLinkActive="active">
  Consultation notes
</a>

<div
  class="text-sm font-medium nav-item actions-menu"
  routerLinkActive="active"
  [routerLinkActiveOptions]="{ exact: true }">
  <span class="flex cursor-pointer">
    Actions
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
      class="pb-px my-auto ml-1 fill-current">
      <path
        d="M11.3334 6.00002L10.3934 5.06002L6.66675 8.78002V0.666687H5.33341V8.78002L1.61341 5.05335L0.666748 6.00002L6.00008 11.3334L11.3334 6.00002Z"></path>
    </svg>
  </span>
  <div class="right-0 z-20 w-64 overflow-hidden bg-white rounded shadow sub-menu">
    <div class="p-4 pt-3">
      <a
        *ngIf="canPrescribe$ | async"
        routerLinkActive="active"
        routerLink="/consultation/patient/consult/actions/prescribe">
        <span>Prescribe</span>
      </a>
      <a
        *ngIf="canTakeNotes$ | async"
        routerLinkActive="active"
        routerLink="/consultation/patient/consult/actions/send-a-task">
        <span>Send a task</span>
      </a>
      <a
        routerLink="/consultation/patient/consult/actions/contact-support"
        routerLinkActive="active">
        <span>Contact support</span>
      </a>
      <a
        *ngIf="(isInLiveConsultationState$ | async) && (hasTranslationEnabled$ | async)"
        routerLink="/consultation/patient/consult/actions/dial-interpreter"
        routerLinkActive="active">
        <span>Dial interpreter</span>
      </a>
      <a
        *ngIf="canWriteFitNote$ | async"
        routerLink="/consultation/patient/consult/actions/fit-note"
        routerLinkActive="active">
        <span>Write fit note</span>
      </a>
      <a
        *ngIf="canWriteReferral$ | async"
        routerLink="/consultation/patient/consult/actions/consultation-referral"
        routerLinkActive="active">
        <span>Write a referral</span>
      </a>
      <a
        routerLink="/consultation/patient/consult/actions/patient-email-communication"
        routerLinkActive="active">
        <span>Email patient</span>
      </a>
      <a
        routerLink="/consultation/patient/consult/actions/consultation-complete"
        [queryParams]="{ from: EndConsultationPlaceEnum.TOP_MENU }"
        routerLinkActive="active">
        <span>{{
          (isInEditConsultationState$ | async) ? 'Save and close' : 'End consultation'
        }}</span>
      </a>
    </div>
  </div>
</div>
