import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ConsultationState } from '@pushdr/clinicians/common';
import { PatientDetailsService } from '../../../../../services/patient-details/patient-details.service';
import { ModalService } from '@pushdr/common/overlay';
import { PatientDetailsV2 } from '@pushdr/common/types';
import { PatientAlert } from '@pushdr/doctors/data-access/doctors-api';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { IdentificationType, IdCheckService } from '../../../services/id-check/id-check.service';
import { PatientNavigationService } from '../../../services/patient-navigation/patient-navigation.service';

@Component({
  selector: 'pushdr-child-id-check-nhs',
  templateUrl: './child-id-check-nhs.component.html',
  styleUrls: ['./child-id-check-nhs.component.scss'],
})
export class ChildIdCheckNhsComponent implements OnInit {
  patient$: Observable<PatientDetailsV2>;
  nhsChildFormGroup: UntypedFormGroup;
  childIdentificationTypes$: Observable<IdentificationType[]>;
  genders = [
    { description: 'Male', value: 1 },
    { description: 'Female', value: 2 },
    { description: 'Unspecified', value: 3 },
  ];
  submitting = false;

  constructor(
    private patientDetails: PatientDetailsService,
    private patientNav: PatientNavigationService,
    private modal: ModalService,
    private idCheckService: IdCheckService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.childIdentificationTypes$ = this.idCheckService.childIdentificationTypes$;
    this.patient$ = this.patientDetails.details$.pipe(
      take(1),
      tap(() => {
        this.nhsChildFormGroup = this.fb.group({
          idTypeSelect: [null, [Validators.required]],
          firstName: [null, [Validators.required]],
          lastName: [null, [Validators.required]],
          dob: [null, [Validators.required]],
          gender: [null, [Validators.required]],
        });
      })
    );
  }

  onSubmitChildNHS() {
    if (this.submitting) return;
    this.submitting = true;

    const { firstName, lastName, gender, dob, idTypeSelect } = this.nhsChildFormGroup.value;
    this.idCheckService
      .confirmChildNhs$(firstName, lastName, gender, dob, idTypeSelect.IdentificationTypeId)
      .pipe(
        switchMap(() => this.patientDetails.alerts$),
        take(1)
      )
      .subscribe(
        alerts => this.exitIdChecks(alerts),
        err => {
          this.submitting = false;
          this.modal.error(err.message);
        }
      );
  }

  exitIdChecks(alerts: PatientAlert[]) {
    const nextConsultationState =
      alerts.length > 0 ? ConsultationState.CHECKING_ALERTS : ConsultationState.CONSULTING;
    this.patientDetails.clearCache();
    this.patientNav.gotoConsultationHome(nextConsultationState);
  }
}
