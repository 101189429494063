import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'pushdr-consultation-card',
  templateUrl: './consultation-card.component.html',
  styleUrls: ['./consultation-card.component.scss'],
})
export class ConsultationCardComponent {
  @Input() showHeader = true;
  @Input() greyHeader = true;
  @Input() extraCssClass = '';
  @Input() showFooter = false;
  @Input() bodyPadding = 'p-4';
  @Input() hasData = true;
  @Input() messageOveride = 'Loading';
  @Input() isEmpty = false;
  @Output() scrolledToEnd = new EventEmitter();

  @HostBinding('class.apply-scroll-fix')
  @Input()
  applyScrollFix = false;

  onScrolledToEnd() {
    this.scrolledToEnd.emit();
  }
}
