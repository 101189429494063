import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject, of } from 'rxjs';
import { debounceTime, mergeMap } from 'rxjs/operators';
import { ConsultationPrescriber } from '../../../../types/Consultation';
import { DoctorClaim } from '@pushdr/common/types';

const DEFAULT_VALUE: ConsultationPrescriber = {
  id: undefined,
  name: 'All clinicians',
};

@Component({
  selector: 'pushdr-clinicians-prescriber-search',
  templateUrl: './clinicians-prescriber-search.component.html',
  styleUrls: ['./clinicians-prescriber-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CliniciansPrescriberSearchComponent
  implements ControlValueAccessor, OnInit, OnChanges
{
  onTouched: () => void;

  @Input() doctor: DoctorClaim = null;
  @Input() prescribers = [];
  @Output() prescriberChange = new EventEmitter<any>();

  control = this._fb.control(DEFAULT_VALUE, Validators.required);

  get searchTerm$() {
    return this._searchTerm$.asObservable();
  }
  private _searchTerm$ = new BehaviorSubject<string>('');

  prescribers$: BehaviorSubject<ConsultationPrescriber[]> = new BehaviorSubject<
    ConsultationPrescriber[]
  >([]);
  constructor(private _fb: UntypedFormBuilder) {}

  buildPrescribersOptions(searchTerm = '') {
    const res = (this.prescribers || []).filter(
      (prescriber: ConsultationPrescriber) =>
        !searchTerm ||
        (searchTerm && searchTerm.toString().length < 2) ||
        (searchTerm && searchTerm.toString().length > 2 && prescriber.name.indexOf(searchTerm) > -1)
    );
    /*if (this.doctor) {
      res.unshift({id: this.doctor.DoctorId, name: `${this.doctor.Title} ${this.doctor.FirstName} ${this.doctor.LastName}`});
    }*/
    return of([DEFAULT_VALUE, ...(res || [])]);
  }

  ngOnInit() {
    this.searchTerm$
      .pipe(
        debounceTime(250),
        mergeMap(searchTerm => this.buildPrescribersOptions(searchTerm))
      )
      .subscribe(res => {
        this.prescribers$.next(res);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.prescribers &&
      changes.prescribers.currentValue !== changes.prescribers.previousValue
    ) {
      this.changeSearchTerm('');
      this.control.setValue(DEFAULT_VALUE, { emitEvent: false, onlySelf: true });
    }
  }

  registerOnChange(fn: (val: any) => void) {
    this.control.valueChanges.subscribe(fn);
  }

  writeValue(val: ConsultationPrescriber) {
    if (val) {
      this.control.setValue(val);
    }
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  changeSearchTerm(term: string) {
    this._searchTerm$.next(term);
  }

  onChange(value: ConsultationPrescriber) {
    this.prescriberChange.emit(value);
  }

  dummySearch() {
    return true;
  }
}
