import { Environment } from '../models';

const signalRv3 = 'https://cdnjs.cloudflare.com/ajax/libs/microsoft-signalr/3.1.3/signalr.min.js';

// a name by which we can reference the current envirnoment
const id = 'sit';

// prettier-ignore
export const environment: Environment = {
  id,
  version: 'localhost',
  testingMode: true,
  doctors: {
    api: 'https://sit-waf.internal.pushsvcs.com/doctor',
    newApi: 'https://sit-waf.internal.pushsvcs.com/newdoctor/api',
    recordsAPI: 'https://sit-waf.internal.pushsvcs.com/records/api/v2',
    sessionsAPI: 'https://sit-waf.internal.pushsvcs.com/doctorsessionsapi/api/v1',
    pharmaAPI: 'https://sit-waf.internal.pushsvcs.com/pharmaceutical/v1',
    pharmacyAPI: 'https://sit-waf.internal.pushsvcs.com/pharmacy/v1',
    prescriptionAPI: 'https://sit-waf.internal.pushsvcs.com/prescription/v1',
    mq: {
      domainv3: 'https://pdrsit-signalrhubapp-sit.azurewebsites.net/api?h=clin_cons',
      signalRv3,
    },
    rbac:'https://sit-waf.internal.pushsvcs.com/rbac/api',
    cardReaderEnv: ['INT', 'DEV'],
  },
  production: false,
  appInsights: {
    config: {
      instrumentationKey: '4acb6464-d86d-4d5f-97f0-9863826ef18a'
    }
  },
  google: {
    cliniciansGtm: 'GTM-NTSKBJZ'
  },
  sentry: {
    environment: id
  },
  zendesk: {
    key: 'c4bae40e-4d82-418e-a7d4-0170cc86c9c2',
  },
};
