import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Data,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { ConsultationStateService } from '@pushdr/clinicians/common';
import { Observable, of, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { PatientNavigationService } from '../services/patient-navigation/patient-navigation.service';

@Injectable({
  providedIn: 'root',
})
export class ConsultationStateGuard implements CanActivate {
  constructor(
    private consultationState: ConsultationStateService,
    private patientNav: PatientNavigationService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.canActivateShared(route.data, 'data.states');
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.canActivateShared(route.parent.data, 'parent.data.states');
  }

  private canActivateShared(data: Data, theType: string) {
    if (!data || typeof data.states === 'undefined') {
      return of(true);
    }
    const states = data.states;
    if (!Array.isArray(states)) {
      return this.throwError$(
        `ConsultationStateGuard => ${theType} must be an array of ConsultationState enum values`
      );
    }
    return this.consultationState.state$.pipe(
      switchMap(consultationState => {
        if (states.indexOf(consultationState) > -1) {
          return of(true);
        } else {
          return this.patientNav.homePath$.pipe(map(path => this.router.createUrlTree(path)));
        }
      })
    );
  }

  private throwError$(errorMessage: string) {
    console.error(errorMessage);
    return throwError(new Error(errorMessage));
  }
}
