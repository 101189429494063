import { Component } from '@angular/core';

import {
  ConsultationVisibilityService,
  VisibilityItem,
} from '../../services/consultation-visibility.service';

interface RecordMenuItem extends VisibilityItem {
  title: string;
  path: string;
}

@Component({
  selector: 'pushdr-consultation-menu',
  templateUrl: './consultation-menu.component.html',
  styleUrls: ['./consultation-menu.component.scss'],
})
export class ConsultationMenuComponent {
  menuItems$ = this.visibilityService.getVisibleItemsFrom(menuItems);

  constructor(private visibilityService: ConsultationVisibilityService) {}
}

const menuItems: RecordMenuItem[] = [
  {
    title: 'Summary',
    path: '/consultation/patient/consult/records/summary',
    visibility: { html: true },
  },
  {
    title: 'PD Notes',
    path: '/consultation/patient/consult/records/doctor-notes',
  },
  {
    title: 'Encounters',
    path: '/consultation/patient/consult/records/encounters/nhs',
    visibility: { html: true },
  },
  {
    title: 'Clinical items',
    path: '/consultation/patient/consult/records/clinical',
    visibility: { html: true },
  },
  {
    title: 'Problems',
    path: '/consultation/patient/consult/records/problems',
    visibility: { html: true },
  },
  {
    title: 'Medications',
    path: '/consultation/patient/consult/records/medications/nhs',
    visibility: { html: true, struct: false },
  },
  {
    title: 'Medications',
    path: '/consultation/patient/consult/records/detailed-medications',
    visibility: { struct: true },
  },
  {
    title: 'Allergies',
    path: '/consultation/patient/consult/records/allergies',
    visibility: { html: true, struct: false },
  },
  {
    title: 'Referrals',
    path: '/consultation/patient/consult/records/referrals',
    visibility: { html: true },
  },
  {
    title: 'Observations',
    path: '/consultation/patient/consult/records/observations',
    visibility: { html: true },
  },
  {
    title: 'Immunisations',
    path: '/consultation/patient/consult/records/immunisations',
    visibility: { html: true },
  },
  {
    title: 'Administration items',
    path: '/consultation/patient/consult/records/administration',
    visibility: { html: true },
  },
];
