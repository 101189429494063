import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { map } from 'rxjs/operators';

import { Referential } from '../models';

@Component({
  selector: 'pushdr-referential-information',
  templateUrl: './referential-information.component.html',
})
export class ReferentialInformationComponent implements OnChanges {
  @Input() referential!: Referential[];

  categoryControl = new FormControl(null);

  referentialWarnings$ = this.categoryControl.valueChanges.pipe(
    map(referentialData => referentialData ?? { data: [], category: '' }),
    map(({ data, category }) => data.filter(warning => hasCategory(warning, category)))
  );

  ngOnChanges(changes: SimpleChanges): void {
    if ('referential' in changes) {
      this.categoryControl.setValue(null);
    }
  }
}

function hasCategory(warning: { categories: { name: string }[] }, category: string): boolean {
  return !!warning.categories.find(x => x.name === category);
}
