import * as moment from 'moment';
import { Moment } from 'moment';
import { Observable } from 'rxjs';

class DateFixTools {
  static fixDate(strDate: any): Moment {
    return moment(strDate);
  }

  static fixDateInObj(item: any, propsToFix: string[]) {
    propsToFix.forEach(prop => {
      if (typeof item[prop] === 'string') {
        item[prop] = DateFixTools.fixDate(item[prop]);
      }
    });
  }

  static fixDateInArr(theArray: any[], propsToFix: string[]) {
    theArray.forEach(item => {
      DateFixTools.fixDateInObj(item, propsToFix);
    });
  }
}

export function fixDates(propsToFix: string[]) {
  return function <T>(source: Observable<T>): Observable<T> {
    return new Observable(subscriber => {
      source.subscribe({
        next(value) {
          if (value instanceof Array) {
            DateFixTools.fixDateInArr(value as any, propsToFix);
          } else {
            DateFixTools.fixDateInObj(value as any, propsToFix);
          }
          subscriber.next(value);
        },
        error(error) {
          subscriber.error(error);
        },
        complete() {
          subscriber.complete();
        },
      });
    });
  };
}
