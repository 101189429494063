<div class="c-modal__wrap c-modal__wrap--loader" [cdkTrapFocus]="ready$ | async">
  <h2>{{ data.header }}</h2>

  <div tabindex="0"></div>
  <div>
    <div class="c-modal__loader">
      <div class="o-loader" [style.minHeight]="'0px'">
        <div class="o-loader__item">Loading...</div>
      </div>
    </div>
  </div>

  <div *ngIf="data.bottomText">
    <h4>{{ data.bottomText }}</h4>
  </div>
</div>
