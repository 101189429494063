export enum Gender {
  MALE = 1,
  FEMALE = 2,
  UNSPECIFIED = 3,
}

export enum NhsGender {
  NotKnown = 0,
  Male = 1,
  Female = 2,
  NotSpecified = 3,
}

export const NhsGenderInfo: { [index: number]: { displayName: string } } = {
  [NhsGender.NotKnown]: { displayName: 'Not Known' },
  [NhsGender.Male]: { displayName: 'Male' },
  [NhsGender.Female]: { displayName: 'Female' },
  [NhsGender.NotSpecified]: { displayName: 'Not Specified' },
};
