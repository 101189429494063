import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Observable } from 'rxjs';
import { PharmaceuticalsApiService } from '@pushdr/prescription/api';
import {
  PharmaceuticalEquivalent,
  PharmaceuticalsMedicationDetails,
} from '@pushdr/prescription/api';
import { PrescriptionInputDataState, PrescriptionInputData } from '../models/PrescriptionInputData';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'pushdr-fdb-prescribe',
  templateUrl: './fdb-prescribe.component.html',
  styleUrls: ['./fdb-prescribe.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FdbPrescribeComponent implements OnInit {
  @Input() medicationCode: string;
  @Input() inputData: PrescriptionInputData = null;
  @Output() formChanged = new EventEmitter<PrescriptionInputDataState>();

  medicationData$: Observable<PharmaceuticalsMedicationDetails>;
  genericPicked: boolean;

  constructor(
    private pharmAPI: PharmaceuticalsApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.getPharmaDetails(this.medicationCode);
  }

  getPharmaDetails(snomedCode: string) {
    this.medicationData$ = this.pharmAPI.getPharmaceuticalBySnomedCode(snomedCode);
  }

  selectEquivalent(equivalent: PharmaceuticalEquivalent) {
    if (equivalent.isBrand === false) {
      this.genericPicked = true;
    } else {
      this.getPharmaDetails(equivalent.snomedCode);
    }
  }

  onFormChanged(formData: PrescriptionInputDataState) {
    this.formChanged.emit({ ...formData });
  }

  viewMedicationInfo(medication) {
    this.router.navigate(['../medication-info'], {
      relativeTo: this.activatedRoute,
      state: { medication },
    });
  }
}
