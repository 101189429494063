import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  AbstractControl,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'pushdr-input-with-character-count',
  templateUrl: './input-with-character-count.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputWithCharacterCountComponent),
      multi: true,
    },
  ],
})
export class InputWithCharacterCountComponent implements ControlValueAccessor, OnInit {
  @Input() label = '';
  @Input() max: number | null = null;
  @Input() optional = false;
  inputValue = '';
  @Input() control: AbstractControl;
  @Input() multiline = 0;
  @Input() placeholder = 'Type into here...';

  get maxLength(): string {
    if (this.max === null) {
      return '-';
    }
    return this.max.toString();
  }

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit() {
    if (!this.control) {
      this.control = this.fb.control('', !this.optional ? Validators.required : null);
    }
  }

  onTouched() {}

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  registerOnChange(fn: (val: unknown) => void) {
    this.control.valueChanges.subscribe(fn);
  }

  writeValue(val) {
    if (val) {
      this.control.setValue(val);
    }
  }
}
