<div class="relative flex flex-row h-full">
  <div class="flex flex-col flex-shrink-0 flex-grow-0" style="width: 190px">
    <div
      *ngFor="let tab of tabsArray"
      (click)="selectTab(tab)"
      [attr.data-cy]="tab.id + '_header'"
      class="block mt-3 text-left text-bluegrey-800 cursor-pointer"
      [ngClass]="{ 'font-bold': tab.active }">
      <i class="material-icons mr-2 mt-1 text-xs" [class.text-red-500]="tab.active">
        {{
          !noCheck && tab.checked && !tab.active
            ? 'check'
            : tab.active
            ? 'circle'
            : 'radio_button_unchecked'
        }}
      </i>
      {{ tab.header }}
    </div>
  </div>
  <div
    class="flex pl-3 pt-3 tabsContainer border-l border-grey-200 absolute top-0 bottom-0 right-0 overflow-scroll"
    style="left: 190px"
    #tabsContainer>
    <ng-content></ng-content>
  </div>
</div>
