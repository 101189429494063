import { Pipe, PipeTransform } from '@angular/core';
import { Prescription, PrescriptionStatusDescription } from '../types/Prescription';

@Pipe({ name: 'prescriptionStatusDescription' })
export class PrescriptionStatusDescriptionPipe implements PipeTransform {
  transform(prescription: Prescription) {
    return (
      PrescriptionStatusDescription[prescription.medicationStatus as unknown as string] || null
    );
  }
}
