import { Pipe, PipeTransform } from '@angular/core';
import { Dispenser } from '@pushdr/prescription/api';

@Pipe({
  name: 'dispenserAddress',
})
export class DispenserAddressPipe implements PipeTransform {
  transform(item: Dispenser): string {
    const addressParts = [...item.address.line, item.address.postcode].filter(f => f);
    if (!addressParts.length) {
      return 'Unknown address';
    }
    return addressParts.join(', ');
  }
}
