import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Consultation } from '../../../../types/Consultation';

@Component({
  selector: 'pushdr-clinicians-consultation-patient-details',
  templateUrl: './clinicians-consultation-patient-details.component.html',
  styleUrls: ['./clinicians-consultation-patient-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CliniciansConsultationPatientDetailsComponent {
  @Input() consultation: Consultation;

  constructor() {}
}
