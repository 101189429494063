import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ConsultationHistoryService } from '../../../../services/consultation-history/consultation-history.service';

@Component({
  selector: 'pushdr-notes-list',
  templateUrl: './notes-list.component.html',
  styleUrls: ['./notes-list.component.scss'],
})
export class NotesListComponent implements OnInit {
  previousConsultations$: Observable<any[]>;

  constructor(private consultationHistoryService: ConsultationHistoryService) {}

  ngOnInit() {
    this.previousConsultations$ = this.consultationHistoryService.previousConsultations$;
  }
}
