import { Component, Input } from '@angular/core';
import { HistoricConsultationNotes } from '@pushdr/doctors/data-access/doctors-api';

@Component({
  selector: 'pushdr-notes-text-view',
  templateUrl: './notes-text-view.component.html',
  styleUrls: ['./notes-text-view.component.scss'],
})
export class NotesTextViewComponent {
  @Input() consultationNotes: HistoricConsultationNotes;
}
