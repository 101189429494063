<pushdr-consultation-card
  [hasData]="stage !== 'Sending' && stage !== 'Loading'"
  [showFooter]="true"
  [messageOveride]="stage">
  <span header>Email patient</span>

  <ng-container body [ngSwitch]="true">
    <span *ngSwitchCase="stage === 'Sent'">
      <ng-container *ngTemplateOutlet="sent"></ng-container>
    </span>
    <span *ngSwitchDefault class="flex flex-col h-full">
      <ng-container *ngTemplateOutlet="ready"></ng-container>
    </span>
  </ng-container>
  <ng-container footer>
    <div class="flex justify-between w-full p-2 px-8">
      <button
        data-cy="back-btn"
        class="w-2/6 btn-outline btn btn-tiny"
        (click)="onClickBackToConsultation()">
        Back
      </button>
      <button
        data-cy="send-btn"
        *ngIf="stage === 'Ready'"
        class="w-2/6 btn btn-primary btn-tiny"
        [class.opacity-75]="!emailForm.valid"
        (click)="sendEmail()">
        Send
      </button>
    </div>
  </ng-container>
</pushdr-consultation-card>

<ng-template #sent>
  <div class="mt-4 text-center">
    <span class="p-1 mb-2 rounded bg-secondary material-icons">done</span>
    <p class="mb-4 text-base font-bold text-bluegrey-800">Sent successfully</p>
  </div>
</ng-template>

<ng-template #ready>
  <span *ngIf="patientDetails$ | async as patientDetails">
    <div class="flex justify-between pb-2 mb-4 font-light border-b border-grey-600 text-grey-700">
      <span>
        To:
        <span data-cy="email-to" class="ml-1 font-medium text-bluegrey-800">
          {{ patientDetails.Customer.Email }}
        </span>
      </span>
      <span
        class="text-bluegrey-600"
        tooltip-bottom="If this email is incorrect, please let the patient know to get in contact with customer services to get it updated.">
        Incorrect email?
      </span>
    </div>
    <div class="flex justify-between mt-10 mb-4 text-bluegrey-600">
      <span>Dear {{ patientDetails.Patient.Name }}</span>
      <span>{{ todaysDate | date: 'dd MMM yyyy' }}</span>
    </div>
  </span>
  <div class="flex-grow">
    <form class="flex flex-col h-full" [formGroup]="emailForm">
      <textarea
        #content
        type="text"
        name="emailContent"
        maxlength="1000"
        placeholder="Email content..."
        formControlName="content"
        class="self-stretch flex-grow m-0 pdr-input"
        data-cy="email-content"></textarea>
      <div class="flex justify-between mt-1">
        <span class="text-error">{{ errorMessage }}</span>
        <span class="text-sm text-bluegrey-600">{{ content.value?.trim().length }}/1000</span>
      </div>

      <div class="mb-10 text-bluegrey-600" *ngIf="doctorDetails$ | async as doctorDetails">
        <div class="mb-4">Kind regards,</div>
        <div>{{ doctorDetails.name }}</div>
      </div>

      <div class="mb-4">
        <div
          class="p-2 mb-1 border rounded text-bluegrey-800"
          [class.bg-information-light]="!submitted"
          [ngClass]="{
            'border-error bg-error-light': submitted && !emailForm.valid,
            'bg-information-light border-transparent': !submitted || emailForm.valid
          }">
          <label class="flex">
            <input
              #confirmed
              type="checkbox"
              [value]="true"
              name="emailConfirmed"
              formControlName="emailConfirmed"
              class="mt-1 ml-1 mr-3"
              data-cy="consent-chck" />
            I confirm I have got consent from the patient and confirmed their preferred email
            address
          </label>
        </div>
        <div *ngIf="submitted && !emailForm.valid" class="text-error">
          You must obtain consent before sending.
        </div>
      </div>
    </form>
  </div>
</ng-template>
