import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkMonitorResult } from '../../services/network-monitor/network-monitor.service';

@Component({
  selector: 'pushdr-network-status-indicator',
  templateUrl: './network-status-indicator.component.html',
  styleUrls: ['./network-status-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NetworkStatusIndicatorComponent {
  @Input() networkStatus$: Observable<NetworkMonitorResult>;
}
