import { Injectable, Type } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ConsultationDynamicGroupComponent as PanelComponent } from '../../components/consultation-layout/consultation-dynamic-group/consultation-dynamic-group.component';

@Injectable({
  providedIn: 'root',
})
export class ConsultationPanelService {
  private panelSubject = new BehaviorSubject<PanelComponent[]>([]);

  get panels$() {
    return this.panelSubject.asObservable();
  }

  get panels() {
    return this.panelSubject.value;
  }

  addPanel(panel: PanelComponent): void {
    if (!this.hasPanel(panel)) {
      const newPanels = this.panels.concat(panel);
      this.panelSubject.next(newPanels);
    }
  }

  hasPanel(panel: PanelComponent): boolean {
    return this.panels.includes(panel);
  }

  findPanelById(panelId: string): PanelComponent {
    return this.panels.find(panel => panel.panelId === panelId);
  }

  findPanelByComponent(component: Type<unknown>): PanelComponent {
    return this.panels.find(panel => panel.selectedCard?.component === component);
  }

  removePanel(panel: PanelComponent): void {
    const panelIndex = this.panels.indexOf(panel);
    if (panelIndex !== -1) {
      const newPanels = this.panels.slice();
      newPanels.splice(panelIndex, 1);
      this.panelSubject.next(newPanels);
    }
  }
}
