<pushdr-card [messageOveride]="messageOveride" [class]="extraCssClass" [hasData]="true">
  <section class="flex flex-col w-full h-full">
    <header
      *ngIf="showHeader"
      data-cy="header"
      class="relative z-10 flex justify-between px-5 py-3 text-xl bg-grey-100 text-bluegrey-800 shadow-header">
      <ng-container *ngIf="!!(availableCards$ | async) === false; else headForGroup">
        <ng-container *ngTemplateOutlet="headForNoGroup"></ng-container>
      </ng-container>
    </header>
    <article *ngIf="!hasData">
      <div class="flex items-center justify-center h-full">
        <pushdr-spinner-loader
          [message]="messageOveride + '...'"
          [minheight]="100"></pushdr-spinner-loader>
      </div>
    </article>
    <article
      data-cy="body"
      *ngIf="hasData"
      [pdScroll]="true"
      (scrolledToEnd)="onScrolledToEnd()"
      class="flex flex-col flex-auto flex-grow h-px overflow-y-auto text-sm leading-5 bg-white alert scrollbar"
      [ngClass]="bodyPadding"
      [class.justify-center]="isEmpty">
      <h5 *ngIf="isEmpty" class="text-center">There's nothing here</h5>
      <ng-content select="[body]"></ng-content>
    </article>
    <footer
      *ngIf="showFooter && hasData"
      data-cy="footer"
      class="flex flex-col items-center justify-end -mx-4">
      <div class="w-full h-px border-t-2 border-grey-300"></div>
      <ng-content select="[footer]"></ng-content>
    </footer>
  </section>
</pushdr-card>

<ng-template #headForNoGroup>
  <ng-container *ngTemplateOutlet="headerContent"></ng-container>
  <span class="flex justify-between">
    <div class="flex flex-col justify-center mr-20">
      <ng-container *ngTemplateOutlet="headerButtonsContent"></ng-container>
    </div>
  </span>
</ng-template>

<ng-template #headForGroup>
  <div class="flex" #panelSwitch>
    <div class="cursor-pointer" (click)="onPanelDdlToggle()">
      <ng-container *ngTemplateOutlet="headerContent"></ng-container>
    </div>
    <svg
      class="mt-2 ml-3 cursor-pointer"
      width="20"
      height="10"
      viewBox="0 0 16 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      (click)="onPanelDdlToggle()">
      <path d="M0.5 0.25L8 7.75L15.5 0.25H0.5Z" fill="#263237" />
    </svg>
    <div class="w-0 overflow-visible">
      <div
        class="absolute z-20 w-48 mt-6 -ml-24 overflow-hidden bg-white rounded shadow select-none"
        *ngIf="panelDdlOpen">
        <button
          *ngFor="let card of availableCards$ | async"
          (click)="onCardChange(card)"
          class="block w-full p-2 text-sm font-normal text-left bg-white outline-none text-bluegrey-800 hover:bg-grey-300 font-we hover:font-medium">
          {{ card.title }}
        </button>
      </div>
    </div>
  </div>
  <span class="flex justify-between">
    <div class="flex flex-col justify-center mr-2">
      <ng-container *ngTemplateOutlet="headerButtonsContent"></ng-container>
    </div>
  </span>
</ng-template>

<ng-template #headerContent>
  <ng-content select="[header]"></ng-content>
</ng-template>
<ng-template #headerButtonsContent>
  <ng-content select="[header-buttons]"></ng-content>
</ng-template>
