<div [@showNotes] class="flex flex-col w-2/3 max-w-5xl p-10 m-4 bg-white shadow">
  <h4 class="pb-1 font-normal border-b border-solid text-body-text border-bluegrey-200">
    Any additional notes?
  </h4>
  <p class="mt-4">
    If you wish to send any notes for our support team to action please include them here. If not,
    simply leave blank and press continue.
  </p>

  <div class="p-10 c-disrupt-reasons__textarea bg-bluegrey-100">
    <div class="flex flex-row items-center justify-center">
      <textarea
        [disabled]="loading"
        data-cy="textarea"
        rows="4"
        cols="50"
        placeholder="Provide details if necessary"
        type="text"
        (input)="onInputAdditionalNotes($event)"
        class="u-mb0@xs pdr-input"></textarea>

      <div class="ml-8">
        <button
          data-cy="submit-button"
          class="self-center w-64 p-4 text-white rounded bg-primary hover:bg-primary-hover"
          [ngClass]="
            loading
              ? 'bg-primary-disabled hover:bg-primary-hover-disabled '
              : 'bg-primary hover:bg-primary-hover'
          "
          (click)="onSubmitAdditionalNotes()">
          <span *ngIf="!loading">Continue to waiting room</span>
          <span class="loading" *ngIf="loading"></span>
        </button>
      </div>
    </div>
  </div>
</div>
