<div class="c-modal__wrap" [cdkTrapFocus]="ready$ | async">
  <h2>Success</h2>
  <div *ngIf="data.bottomText">
    <h4>{{ data.bottomText }}</h4>
  </div>

  <div class="modal__footer">
    <button class="c-btn c-btn--primary" (click)="close()">OK</button>
  </div>
</div>
