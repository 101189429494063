import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'pushdr-patient-id',
  templateUrl: './patient-id.component.html',
  styleUrls: ['./patient-id.component.scss'],
})
export class PatientIdComponent implements OnInit {
  @Input() base64Image: string;
  @Input() showFullScreenButton = true;

  isFullscreen: boolean;
  zoomPct: number;
  dragging = false;
  top = 0;
  left = 0;
  idWidth: number;
  idHeight: number;
  containerWidth: number;
  containerHeight: number;
  idScale: number;

  @Output() fullscreen = new EventEmitter<boolean>();

  constructor(private _ref: ElementRef) {}

  @HostListener('mousedown')
  onMouseDown() {
    this.dragging = true;
  }

  @HostListener('document:mouseup')
  onMouseUp() {
    this.dragging = false;
  }

  @HostListener('mousewheel', ['$event'])
  onMouseWheel(event: WheelEvent) {
    this.zoomTo(event.offsetX, event.offsetY, -event.deltaY / 10);
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.dragging) {
      this.top += event.movementY;
      this.left += event.movementX;
      this.checkBounds();
    }
  }

  ngOnInit() {
    if (this.base64Image === 'no-image') return;
    this.isFullscreen = false;
    this.resetPosition();
  }

  resetPosition() {
    this.zoomPct = 100;
    this.containerWidth = this._ref.nativeElement.offsetWidth;
    this.containerHeight = this._ref.nativeElement.offsetHeight;
    const i = new Image();
    i.onload = () => {
      this.setContraints(i.width, i.height);
      this.checkBounds();
    };
    i.src = this.base64Image;
  }

  setContraints(width: number, height: number) {
    this.idWidth = width;
    this.idHeight = height;
    if (this.containerWidth / this.idWidth > this.containerHeight / this.idHeight) {
      this.idScale = this.containerHeight / this.idHeight;
    } else {
      this.idScale = this.containerWidth / this.idWidth;
    }
  }

  checkBounds() {
    let leftPad = (this.containerWidth - (this.idScale * this.idWidth * this.zoomPct) / 100) / 2;
    if (leftPad < 0) {
      leftPad = 0;
    }

    const minLeft = this.containerWidth - (this.idWidth * this.zoomPct * this.idScale) / 100;
    if (this.left < minLeft) {
      this.left = minLeft;
    }

    const maxLeft = leftPad;
    if (this.left > maxLeft) {
      this.left = maxLeft;
    }

    let topPad = (this.containerHeight - (this.idScale * this.idHeight * this.zoomPct) / 100) / 2;
    if (topPad < 0) {
      topPad = 0;
    }

    const minTop = this.containerHeight - (this.idHeight * this.zoomPct * this.idScale) / 100;
    if (this.top < minTop) {
      this.top = minTop;
    }

    const maxTop = topPad;
    if (this.top > maxTop) {
      this.top = maxTop;
    }
  }

  zoomTo(x: number, y: number, pct = 10) {
    if (this.zoomPct <= 100 && pct < 0) {
      return;
    }
    const zoomFraction = (this.zoomPct + pct) / this.zoomPct - 1;

    this.left += (x - this.left) * -zoomFraction; // (this.zoomPct - (event.deltaY / 10 - this.zoomPct));
    this.top += (y - this.top) * -zoomFraction; //(this.zoomPct - (event.deltaY / 10 - this.zoomPct));
    this.zoomPct += pct;

    this.checkBounds();
  }
  zoomIn() {
    this.zoomTo(this.containerWidth / 2, this.containerHeight / 2, 20);
  }
  zoomOut() {
    this.zoomTo(this.containerWidth / 2, this.containerHeight / 2, -20);
  }

  toggleFullscreen() {
    this.isFullscreen = !this.isFullscreen;
    this.fullscreen.emit(this.isFullscreen);
    setTimeout(() => this.resetPosition());
  }
}
