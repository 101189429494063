export enum SurgeryType {
  PRIVATE = 2,
  TEST = 3,
  EMIS = 4,
  TPP = 5,
  HYBRID = 7,
  PDR = 8, // new private
}

export enum PartnerType {
  None = 0,
  NHS = 1,
  CORPORATE = 2,
  PDR = 3,
}

export enum GPShareStatus {
  DO_SHARE = 1,
  DO_NOT_SHARE = 2,
  NO_NHS_GP = 3,
  TBC = 4,
  VERIFYING = 5,
}
