import { Injectable } from '@angular/core';
import { isString } from 'lodash';

import { StorageService } from '@pushdr/common/utils';
import { PrescriptionInputData } from '@pushdr/prescription/fdb';
import { Dispenser } from '@pushdr/prescription/api';

export interface PrescriptionStorage {
  items: PrescriptionInputData[];
  dispenser: Dispenser;
  patientNotes: string;
}

@Injectable({
  providedIn: 'root',
})
export class PrescriptionStorageService {
  constructor(private storage: StorageService) {}

  restorePrescription(orderId: number): PrescriptionStorage {
    if (this.hasValidOrderIdInStorage(orderId)) {
      const items = this.loadFromStorage('prescriptionItems', isPrescriptionItems);
      const dispenser = this.loadFromStorage('prescriptionDispenser', isDispenser);
      const patientNotes = this.loadFromStorage('prescriptionPatientNotes', isString);
      return { items, dispenser, patientNotes };
    }
    return null;
  }

  storePrescription(orderId: number, prescription: PrescriptionStorage): void {
    this.storage.setSessionStorage('prescriptionOrderId', orderId);
    this.storage.setSessionStorage('prescriptionItems', prescription?.items ?? null);
    this.storage.setSessionStorage('prescriptionDispenser', prescription?.dispenser ?? null);
    this.storage.setSessionStorage('prescriptionPatientNotes', prescription?.patientNotes);
  }

  clear(): void {
    this.storage.deleteSessionStorage('prescriptionOrderId');
    this.storage.deleteSessionStorage('prescriptionItems');
    this.storage.deleteSessionStorage('prescriptionDispenser');
    this.storage.deleteSessionStorage('prescriptionPatientNotes');
  }

  private hasValidOrderIdInStorage(orderId: number) {
    const storedOrderId = this.storage.getSessionStorage('prescriptionOrderId');
    return storedOrderId && orderId === Number(storedOrderId);
  }

  private loadFromStorage<T>(sessionKey: string, validator: (value: unknown) => value is T): T {
    const storedData = this.storage.getSessionStorage(sessionKey, true);
    if (validator(storedData)) {
      return storedData;
    }
    return null;
  }
}

function isPrescriptionItems(items: unknown[]): items is PrescriptionInputData[] {
  return Array.isArray(items) && items.every(item => !!item.medicationCode);
}

function isDispenser(value: unknown): value is Dispenser {
  return value && Object.prototype.hasOwnProperty.call(value, 'ods');
}
