<ng-container
  *ngIf="medicationGroup && medicationGroup.medications && medicationGroup.medications.length > 0">
  <div class="bg-indigo-200 h-12 p-3">
    <h4 class="uppercase font-normal tracking-wider">{{ medicationGroup.groupName }}</h4>
  </div>
  <pushdr-medication-list-item
    *ngFor="let med of medicationGroup.medications; trackBy: trackByIndex"
    [medication]="med">
  </pushdr-medication-list-item>
</ng-container>
