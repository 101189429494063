import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CurrentOrderIdService {
  private orderIdSubject = new BehaviorSubject(0);

  get idChanges(): Observable<number> {
    return this.orderIdSubject.asObservable();
  }

  get id(): number {
    return this.orderIdSubject.value;
  }

  set id(id: number) {
    this.orderIdSubject.next(id);
  }
}
