import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { EnvironmentProxyService } from '@pushdr/environment';

export interface TopbarLinkedStep {
  path: string;
  title: string;
  icon?: string;
}

@Component({
  selector: 'pushdr-topbar-stepped-progress',
  templateUrl: './topbar-stepped-progress.component.html',
  styleUrls: ['./topbar-stepped-progress.component.scss'],
})
export class TopbarSteppedProgressComponent implements OnInit {
  @Input()
  steps: TopbarLinkedStep[] = [];
  steps$: Observable<TopbarLinkedStep[]>;
  noTouch = !this.envProxy.environment.testingMode;

  constructor(private router: Router, private envProxy: EnvironmentProxyService) {}

  ngOnInit() {
    this.createObservableStepsArray();
  }

  private createObservableStepsArray() {
    this.steps$ = this.router.events.pipe(
      startWith(new NavigationEnd(1, '', '')),
      filter(event => event instanceof NavigationEnd),
      map(() =>
        this.steps.map((step, index) => {
          step.icon = this.iconClass(index);
          return step;
        })
      )
    );
  }

  iconClass(index) {
    const activeIndex = this.activeIndex;
    switch (true) {
      case index < activeIndex:
        return 'completed';
      case index === activeIndex:
        return 'current';
      default:
        return 'next';
    }
  }

  get activeIndex() {
    return this.steps.findIndex(step => {
      return this.router.isActive(this.router.createUrlTree([step.path]), false);
    });
  }
}
