<div class="absolute inset-0 z-10 flex flex-col justify-end">
  <pushdr-consultation-chat
    [class.hidden]="!chatOpen"
    [config]="chatConfig$"
    (sendMessage)="sendChatMessage($event)"
    (enlargeImage)="enlargeImage($event.base64Image, $event.receivedTime)">
  </pushdr-consultation-chat>
  <div class="flex justify-between w-full text-white bg-black bg-black-gradient">
    <button
      (click)="toggleChat()"
      class="z-0 flex items-center justify-around w-20 h-10 pl-2 cursor-pointer"
      type="button">
      <span class="mt-1">Chat</span>
      <svg
        *ngIf="!newChatMessages || chatOpen; else chatNotification"
        class="inline w-4 h-4 mb-1 duration-100 transform rotate-180 fill-current"
        [class.rotate-180]="!chatOpen">
        <path d="M16 8L14.59 6.59L9 12.17V0H7V12.17L1.42 6.58L0 8L8 16L16 8Z"></path>
      </svg>
      <ng-template #chatNotification>
        <span
          class="flex flex-col justify-center w-6 h-6 pt-px text-sm rounded-chat round bg-primary"
          >{{ newChatMessages }}</span
        >
      </ng-template>
    </button>
    <div *ngIf="translatorState$ | async as status">
      <button
        *ngIf="
          status === translationCallStatus.ANSWERED || status === translationCallStatus.RINGING
        "
        tooltip-left="Connected"
        (click)="endTranslation()"
        class="z-0 flex items-center w-52 h-10 pr-2 cursor-pointer"
        type="button">
        Disconnect interpreter
        <img icon src="assets/icons/recording_live.svg" class="ml-2" />
      </button>
    </div>
  </div>
</div>
