import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { combineLatest, iif, merge, Observable, of, Subject } from 'rxjs';
import { filter, map, mapTo, switchMap } from 'rxjs/operators';

import {
  ClinicalDecisionSupportQuery as CdsQuery,
  ClinicalDecisionSupportService,
} from '@pushdr/prescription/fdb';

import { PharmaceuticalsMedication } from '@pushdr/prescription/api';

import { SnomedData } from '@pushdr/common/types';
import { ClinicalDecisionSupportWarnings as CdsWarnings } from '@pushdr/prescription/fdb';

import { PrescriptionWarningService } from './prescription-warning.service';
import { PrescriptionService } from './prescription.service';

@Injectable()
export class PrescriptionBuilderService {
  private selectSubject = new Subject<PharmaceuticalsMedication>();
  private selectedMedication: PharmaceuticalsMedication;

  readonly selectionChanges = this.selectSubject.asObservable();

  private snomedSelectedMedication$ = this.selectionChanges.pipe(
    filter(x => !!x?.medicationName && !!x?.snomedCode),
    map((x): SnomedData => ({ snomedName: x.medicationName, snomedCode: x.snomedCode }))
  );

  // Extends CDS query by selected medication
  readonly cdsQuery$ = combineLatest([
    this.prescriptionWarnings.cdsQuery$,
    this.snomedSelectedMedication$,
  ]).pipe(
    map(([cdsQuery, selectedMedication]): CdsQuery => {
      const prospectiveMedications = cdsQuery.prospectiveMedications.concat(selectedMedication);
      return { ...cdsQuery, prospectiveMedications };
    })
  );

  readonly warnings$ = merge(
    // Immediately clear-up warnings on next medication select
    this.selectionChanges.pipe(mapTo(null as CdsWarnings)),
    this.cdsQuery$.pipe(switchMap(cdsQuery => this.cds.getCdsWarnings(cdsQuery)))
  );

  constructor(
    private cds: ClinicalDecisionSupportService,
    private prescription: PrescriptionService,
    private prescriptionWarnings: PrescriptionWarningService
  ) {}

  selectMedication(medication: PharmaceuticalsMedication): void {
    this.selectSubject.next(medication);
    this.selectedMedication = medication;
  }

  confirmMedication(warningControl?: AbstractControl): Observable<boolean> {
    const medication = this.selectedMedication;
    const isHighRiskInvalid = medication?.isHighRisk && warningControl?.invalid;

    return iif(() => isHighRiskInvalid, of(null), of(medication)).pipe(
      switchMap(medication => {
        if (medication) {
          const reason = warningControl?.value ?? null;
          this.prescription.currentMedication = medication;
          this.prescription.currentOverriddenReason = reason;
        }
        return of(!!medication);
      })
    );
  }
}
