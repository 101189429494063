import { Injectable } from '@angular/core';
import { merge as deepmerge } from 'lodash';

import { StorageService } from '@pushdr/common/utils';
import { defaultEnvironment } from './environment-default';
import { Environment } from './models';

/**
 * A service which captures the environment in the app module
 * and can be used in shared services and components to access
 * environment settings you want from your application alone.
 *
 * To setup add a provider like this in your app.module
 * providers: [
 { provide: EnvironmentProxyService, useValue: new EnvironmentProxyService().setup(environment)}
 ],
 */
@Injectable({ providedIn: 'root' })
export class EnvironmentProxyService {
  private _environment = defaultEnvironment;

  constructor(private storage?: StorageService) {}

  patch(runtimeEnvironment: Environment) {
    if (runtimeEnvironment && typeof runtimeEnvironment === 'object') {
      this._environment = deepmerge(this._environment, runtimeEnvironment);
    }
    return this._environment;
  }

  setup(env: Environment) {
    if (env.production) {
      this.removeConsoleLogs();
    }
    this._environment = env;

    if (!env.production) this.applyReroutes();

    return this;
  }

  removeConsoleLogs() {
    window.console.log = function () {};
  }

  get environment(): Environment {
    if (!this._environment) {
      console.error('You have not provided an environment into the EnvironmentProxyService');
      this._environment = defaultEnvironment;
    }
    return this._environment;
  }

  private applyReroutes() {
    if (!this.storage) return;
    const apiAlts: [{ enabled: boolean; path: string[]; newValue: string }] =
      this.storage.getLocalStorage('ApiAlts', true);
    if (!apiAlts || !apiAlts.forEach) return;
    apiAlts.forEach(x => {
      if (x.enabled) {
        let pointer = this._environment as any;
        x.path.forEach(p => {
          if (pointer) {
            if (typeof pointer[p] === 'string') {
              pointer[p] = x.newValue;
              return;
            } else pointer = pointer[p];
          }
        });
      }
    });
  }
}
