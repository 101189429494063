<div class="flex flex-col justify-center ml-2 text-xs italic text-grey-700">
  <ng-container [ngSwitch]="state">
    <span *ngSwitchCase="AutoSaveState.Saving"> Saving... </span>
    <span *ngSwitchCase="AutoSaveState.Saved"> Saved </span>
    <span *ngSwitchDefault class="text-error">
      Error saving
      <button class="underline" (click)="retry()">Try again</button>
    </span>
  </ng-container>
</div>
