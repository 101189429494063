<input
  class="pdr-input pr-[48px]"
  required
  [formControl]="passwordControl"
  [attr.type]="showPassword ? 'password-show' : 'password'" />
<div class="absolute right-16 top-[10px]">
  <button type="button" tabindex="0" (click)="showPassword = !showPassword">
    <span class="material-icons-outlined align-middle text-bluegrey-600">
      {{ showPassword ? 'visibility' : 'visibility_off' }}
    </span>
  </button>
</div>
