<div class="bg-white h-full" [ngClass]="{ 'mt-20 py-10': (withoutHeader$ | async) }">
  <section class="text-center max-w-md m-auto" trackView="page not found">
    <header class="text-6xl text-primary">404</header>
    <h1 class="form-part__title" pdFocus="true">Oops, page not found...</h1>
    <p>
      It looks like the page you are looking for cannot be found or doesn't exist anymore, please
      get in contact with our customer experience team on
      <a href="tel:+443308084702">0330&nbsp;808&nbsp;4702</a>
    </p>
    <button
      class="btn btn-primary"
      data-cy="navigate-home"
      (click)="navigateToHome()"
      trackClick="home button">
      Back to home
    </button>
  </section>
</div>
