import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { concatMap, toArray } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ScriptInjectorService {
  constructor() {}

  injectScript(scriptPath: string) {
    return new Observable(function (observer) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = scriptPath;
      document.getElementsByTagName('head')[0].appendChild(script);

      script.onload = function (onloadResponse) {
        observer.next(onloadResponse);
        observer.complete();
      };
      script.onerror = function (err) {
        this.remove();
        observer.error(err);
      };
    });
  }

  injectScriptContent(scriptName: string, scriptContent: string) {
    const script = document.createElement('script');
    script.id = scriptName + '_SCRIPT';
    script.innerHTML = scriptContent;
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  removeScriptById(scriptId: string) {
    document.getElementById(scriptId).remove();
  }

  /** Files will be loaded in order */
  injectMultipleScripts(scriptPaths: string[]) {
    return from(scriptPaths).pipe(concatMap(this.injectScript), toArray());
  }

  removeScript(scriptToRemove: string) {
    const scripts = document.getElementsByTagName('script');
    for (let i = scripts.length; i >= 0; i--) {
      //search backwards within nodelist for matching elements to removeng serve
      if (
        scripts[i] &&
        scripts[i].getAttribute('src') !== null &&
        scripts[i].getAttribute('src').indexOf(scriptToRemove) !== -1
      ) {
        scripts[i].remove();
        return true;
      }
    }
  }

  removeMultipleScripts(scriptsToRemove: string[]) {
    for (let i = 0; i < scriptsToRemove.length; i++) {
      this.removeScript(scriptsToRemove[i]);
    }
    return true;
  }
}
