import { NgModule, ModuleWithProviders } from '@angular/core';
import { WINDOW_PROVIDER } from '../../utils/window/window.service';
import { TokenServiceConfig, TokenServiceOptions } from './token-config';
import { TokenService } from './token.service';

@NgModule()
export class TokenModule {
  static forRoot(config: TokenServiceConfig): ModuleWithProviders<TokenModule> {
    return {
      ngModule: TokenModule,
      providers: [
        TokenService,
        WINDOW_PROVIDER,
        {
          provide: TokenServiceOptions,
          useValue: config,
        },
      ],
    };
  }
}
