<p class="text-sm font-normal mb-2">Password requirements:</p>
<ng-container *ngFor="let rule of rules">
  <ng-template
    [ngTemplateOutlet]="ruleTemplate"
    [ngTemplateOutletContext]="{
      $implicit: rule.condition,
      isValid: !hasError(rule)
    }">
  </ng-template>
</ng-container>

<ng-template #ruleTemplate let-condition let-isValid="isValid">
  <div class="mb-2 font-light" [ngClass]="isValid ? 'text-green-500' : 'text-red-500'">
    <span class="text-sm">{{ condition }}</span>
    <span class="material-icons-outlined text-sm" [class.invisible]="!isValid">done</span>
  </div>
</ng-template>
