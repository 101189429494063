import { Injectable } from '@angular/core';
import { EnvironmentProxyService } from '@pushdr/environment';
import { HttpClient } from '@angular/common/http';
import {
  RestClient,
  RestErrorParserService,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';
import { PharmaceuticalsMedicationDetails } from './models/PharmaceuticalsMedicationDetails';
import { Observable } from 'rxjs';
import { PharmaceuticalsMedication } from './models/PharmaceuticalsMedication';
import { SnomedData } from '@pushdr/common/types';

@Injectable({
  providedIn: 'root',
})
export class PharmaceuticalsApiService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.doctors.pharmaAPI;
  }

  getPharmaceuticalsBySearchTerm(term = ''): Observable<PharmaceuticalsMedication[]> {
    return this.get(`?term=${term}`, {}, this.headerService.legacyHeadersWithCorrelationId);
  }

  getPharmaceuticalBySnomedCode(code: string): Observable<PharmaceuticalsMedicationDetails> {
    return this.get(`/${code}`, {}, this.headerService.legacyHeadersWithCorrelationId);
  }

  getClinicialDecisionSupport(cdsRequest: any) {
    return this.post('', cdsRequest, this.headerService.legacyHeadersWithCorrelationId);
  }

  getClinicialDecisionSupportWarningMatches(snomedData: SnomedData[]) {
    return this.post(
      '/SnomedCdsWarnings',
      snomedData,
      this.headerService.legacyHeadersWithCorrelationId
    );
  }
}
