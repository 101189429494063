<div class="p-3 shadow-header mb-3">
  <p class="mb-0 text-grey-700 text-lg">
    This medication was prescribed using the Push Consult platform.
  </p>
</div>
<div class="px-3 pb-3">
  <p class="text-grey-600 mb-2 menu-item">
    <b>Please note:</b> a duplicate instance of this medication may exist if the patient’s practice
    have manually added it to their own medical record database after recieving the consultation’s
    PDF summary.
  </p>
</div>
