import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfViewModalComponent } from './pdf-view-modal/pdf-view-modal.component';
import { A11yModule } from '@angular/cdk/a11y';
import { SpinnerLoaderModule } from '../spinner-loader/spinner-loader.module';

@NgModule({
  declarations: [PdfViewModalComponent],
  imports: [CommonModule, SpinnerLoaderModule, A11yModule],
})
export class ModalsModule {}
