import { Pipe, PipeTransform } from '@angular/core';
import { SurgeryType } from '@pushdr/common/types';

@Pipe({
  name: 'clinicianSystem',
  pure: true,
})
export class ClinicianSystemPipe implements PipeTransform {
  transform(type: SurgeryType | string[]): string {
    const label = Array.isArray(type)
      ? type.map(t => this.convertSurgeryType(parseInt(t, 10))).join('/')
      : this.convertSurgeryType(type);
    return label;
  }

  private convertSurgeryType(type: SurgeryType) {
    switch (type) {
      case SurgeryType.EMIS:
        return 'EMIS';
      case SurgeryType.TPP:
        return 'SystmOne';
      case SurgeryType.PRIVATE:
        return 'Private';
      default:
        return 'Other';
    }
  }
}
