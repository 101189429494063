import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PDServerError } from '@pushdr/common/types';
import { retryBackoff, RetryBackoffConfig } from 'backoff-rxjs';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export interface RestRetryBackoffConfig extends RetryBackoffConfig {
  retryOnStatusCodes: number[];
}

@Injectable({ providedIn: 'root' })
export class RestErrorParserService {
  errors$: Subject<PDServerError> = new Subject();
  errorRetryConfig: RestRetryBackoffConfig = {
    initialInterval: 500,
    maxRetries: 3,
    retryOnStatusCodes: [0, 500, 502, 503], //opinion based on https://stackoverflow.com/a/47841642
    backoffDelay: (iteration, initialInterval) =>
      Math.pow(1.5, iteration) * initialInterval - 0.5 * initialInterval,
  };

  constructor() {}

  pipeErrorHandlers<T>(request: Observable<T>, method) {
    return request.pipe(
      map(res => this.legacyErrorHandler(method, res)),
      retryBackoff({ ...this.errorRetryConfig, shouldRetry: error => this.shouldRetry(error) }),
      catchError(error => this.handleServerError(error))
    );
  }

  private shouldRetry(error) {
    if (this.isHttpError(error)) {
      return this.errorRetryConfig.retryOnStatusCodes.indexOf(parseInt(error.status, 10)) !== -1;
    }
    return false;
  }

  private legacyErrorHandler(method: string, response) {
    const resultParam = method.split('/').pop() + 'Result';
    if (
      response &&
      Object.prototype.hasOwnProperty.call(response, resultParam) &&
      typeof response[resultParam] === 'string'
    ) {
      const result = response[resultParam];
      const errorMarker = 'Error - ';
      if (result.indexOf(errorMarker) > -1) {
        throw new HttpErrorResponse({ error: result.replace(errorMarker, ''), status: 400 });
      }
    }
    // carry on with response if not caught
    return response;
  }

  private isHttpError(error: HttpErrorResponse) {
    return error.status !== undefined;
  }

  private handleServerError(error: HttpErrorResponse) {
    const message =
      this.extractErrorMessageFromOriginal(error) ||
      this.generateErrorMessageBasedOnStatus(error.status);
    const pd_error = this.errorFactory(error, message);
    this.errors$.next(pd_error);
    return throwError(pd_error);
  }

  private extractErrorMessageFromOriginal(error) {
    const objBody = error.json ? error.json() : error;
    return this.extractErrorMessage(objBody.error) || objBody.message || objBody.Message || '';
  }

  private extractErrorMessage(error) {
    if (!error) return '';
    switch (true) {
      case typeof error === 'string':
        return error;
      case !!error.Errors && !!error.Errors.length:
        return error.Errors[0].Message;
      case !!error.Message:
        return error.Message;
      case !!error.message:
        return error.message;
      case !!Array.isArray(error.errors):
        return this.extractErrorMessage(error.errors.shift());
      default:
        return '';
    }
  }

  private generateErrorMessageBasedOnStatus(status) {
    switch (status) {
      case 0:
        return 'Unknown Error';
      case 401:
      case 403:
        return 'Unauthorised - log in again';
      case 404:
        return 'Endpoint not found, please check your connection and try again.';
      case 500:
        return 'Something went wrong, please check your connection and try again.';
      default:
        return 'Server generated error';
    }
  }

  private errorFactory(
    error: HttpErrorResponse,
    message: string,
    header: string = ''
  ): PDServerError {
    return PDServerError.create(error, message, header);
  }
}

@Injectable({ providedIn: 'root' })
export class RestErrorParserServiceBypass extends RestErrorParserService {
  constructor() {
    super();
  }
  pipeErrorHandlers<T>(request: Observable<T>, method) {
    return request;
  }
}
