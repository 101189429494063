import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CurrentPartnerIdService } from '@pushdr/common/data-access/analytics';
import {
  ClinicalOutcome,
  ClinicalOutcomeResultTypeEnum,
  DiscontinuationOutcome,
  SuccessfulOutcome,
} from '@pushdr/doctors/data-access/doctors-api';
import { combineLatest, Observable } from 'rxjs';
import {
  catchError,
  finalize,
  map,
  publishReplay,
  refCount,
  startWith,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import {
  CliniciansEndConsultationService,
  EndConsultationPlaceEnum,
} from '../../../../../services/end-consultation/end-consultation.service';
import { ConsultationSummaryService } from '../../../../summary/services/consultation-summary/consultation-summary.service';
import { PatientNavigationService } from '../../../services/patient-navigation/patient-navigation.service';
import * as constants from './constants';

@Component({
  selector: 'pushdr-consultation-complete',
  templateUrl: './consultation-complete.component.html',
})
export class ConsultationCompleteComponent implements OnInit {
  constants = constants;

  ClinicalOutcomeResultTypeEnum = ClinicalOutcomeResultTypeEnum;
  SuccessfulOutcome = SuccessfulOutcome;
  DiscontinuationOutcome = DiscontinuationOutcome;

  successReasonSelection = new SelectionModel<ClinicalOutcome['id']>(true, []);
  clinicalOutcomes$: Observable<ClinicalOutcome[]> = null;
  from$: Observable<EndConsultationPlaceEnum> = null;
  hasReferralRequired$: Observable<boolean>;
  clinicianType$ = this.partnerId.idChanges.pipe(
    switchMap(partnerId => this.summaryService.getReferralTypes(partnerId))
  );

  completing = false;
  form: UntypedFormGroup = this.fb.group({
    reason: [null, Validators.required],
    reasonDiscontinuation: [null],
    reasonDisruption: [null],
    referralRequired: [null],
    referralDiscontinuation: [null],
  });

  constructor(
    public patientNav: PatientNavigationService,
    private summaryService: ConsultationSummaryService,
    private fb: UntypedFormBuilder,
    private endConsultationService: CliniciansEndConsultationService,
    private route: ActivatedRoute,
    private partnerId: CurrentPartnerIdService
  ) {}

  ngOnInit(): void {
    this.form.setValidators(this.extraFormValidators.bind(this));
    this.clinicalOutcomes$ = this.summaryService.getClinicalOutcomes().pipe(
      catchError(err => this.endConsultationService.handleNetworkErrors('load', err)),
      publishReplay(1),
      refCount()
    );
    this.from$ = this.route.queryParams.pipe(map(params => params.from));
    this.hasReferralRequired$ = combineLatest([
      this.form.valueChanges.pipe(startWith({})),
      this.clinicalOutcomes$,
    ]).pipe(map(([, outcomes]) => this.hasReferralRequiredSelected(outcomes)));
  }

  private hasReferralRequiredSelected(outcomes): boolean {
    const selectedOutcomes = this.getSelectedOutcomes(outcomes);
    return selectedOutcomes.some(selected => selected.referralRequired);
  }

  private getSelectedOutcomes(outcomes) {
    if (this.form.get('reason').value === ClinicalOutcomeResultTypeEnum.Success) {
      return outcomes.filter(outcomes => this.successReasonSelection.isSelected(outcomes.id));
    } else {
      return outcomes.filter(
        outcomes => outcomes.id === this.form.get('reasonDiscontinuation').value
      );
    }
  }

  toggleSuccessResult(id: ClinicalOutcome['id']) {
    this.successReasonSelection.toggle(id);
    this.form.updateValueAndValidity();
  }

  complete() {
    if (this.completing) {
      // Terminate, we're already working on this request!
      return;
    }
    this.completing = true;
    const reasonType: ClinicalOutcomeResultTypeEnum = this.form.get('reason').value;
    this.from$
      .pipe(
        take(1),
        withLatestFrom(this.clinicalOutcomes$),
        switchMap(([from, outcomes]) => {
          const { clinicalOutcomeIds, referralServiceId } = this.getClinicalOutcomeIds(
            this.form,
            outcomes
          );
          return this.endConsultationService
            .endConsultation(clinicalOutcomeIds, reasonType, from, referralServiceId)
            .pipe(take(1));
        }),
        finalize(() => (this.completing = false))
      )
      .subscribe();
  }

  private extraFormValidators(formGroup: UntypedFormGroup): ValidationErrors {
    switch (formGroup.get('reason').value) {
      case ClinicalOutcomeResultTypeEnum.Success:
        if (this.successReasonSelection.selected.length === 0) {
          return { noSuccessResultSelected: true };
        }
        if (this.successReasonSelection.isSelected(SuccessfulOutcome.ReferralRequired)) {
          if (formGroup.get('referralRequired').value === null) {
            return { noReferralResultSelected: true };
          }
        }
        break;
      case ClinicalOutcomeResultTypeEnum.Discontinuation:
        if (formGroup.get('reasonDiscontinuation').value == null) {
          return { noDiscontinuationResultSelected: true };
        }
        if (
          formGroup.get('reasonDiscontinuation').value === DiscontinuationOutcome.ReferralRequired
        ) {
          if (formGroup.get('referralDiscontinuation').value === null) {
            return { noReferralResultSelected: true };
          }
        }
        break;
      case ClinicalOutcomeResultTypeEnum.Disruption:
        if (formGroup.get('reasonDisruption').value == null) {
          return { noDisruptionResultSelected: true };
        }
        break;
    }
    return null;
  }

  private getClinicalOutcomeIds(
    formGroup: UntypedFormGroup,
    outcomes
  ): { clinicalOutcomeIds: number[]; referralServiceId: number } {
    const clinicalOutcomeIds = [];
    let referralServiceId: number;
    const hasReferralRequired = this.hasReferralRequiredSelected(outcomes);
    switch (formGroup.get('reason').value) {
      case ClinicalOutcomeResultTypeEnum.Success:
        if (this.successReasonSelection.selected.length > 0) {
          clinicalOutcomeIds.push(...this.successReasonSelection.selected);
        }
        if (hasReferralRequired) {
          referralServiceId = this.form.get('referralRequired').value;
        }
        break;
      case ClinicalOutcomeResultTypeEnum.Discontinuation:
        if (formGroup.get('reasonDiscontinuation').value != null) {
          clinicalOutcomeIds.push(formGroup.get('reasonDiscontinuation').value);
        }
        if (hasReferralRequired) {
          referralServiceId = this.form.get('referralDiscontinuation').value;
        }
        break;
      case ClinicalOutcomeResultTypeEnum.Disruption:
        if (formGroup.get('reasonDisruption').value != null) {
          clinicalOutcomeIds.push(formGroup.get('reasonDisruption').value);
        }
        break;
    }

    return { clinicalOutcomeIds, referralServiceId };
  }
}
