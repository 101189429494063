import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalService } from '@pushdr/common/overlay';
import { delay, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

export enum ChatType {
  PATIENT,
  DOCTOR,
}

export enum ChatMessageType {
  RECEIVED_MSG,
  SENT_MSG,
  IMAGE,
  SYSTEM,
  SENT_IMG,
}

export interface ChatMessageImage {
  id: string;
  image: string;
}

export class ChatMessage {
  constructor(
    public message: string,
    public sender: ChatMessageType = ChatMessageType.RECEIVED_MSG,
    public receivedTime: string,
    public image$?: Observable<ChatMessageImage>
  ) {}
}

export interface ConsultationChatConfig {
  chatType: number;
  isOpen$: Observable<boolean>;
  chatMessagesArr$: Observable<ChatMessage[]>;
}

@Component({
  selector: 'pushdr-consultation-chat',
  templateUrl: './consultation-chat.component.html',
  styleUrls: ['./consultation-chat.component.scss'],
})
export class ConsultationChatComponent implements OnInit {
  @Input() config: ConsultationChatConfig;
  @Output() sendMessage: EventEmitter<any> = new EventEmitter();
  @Output() enlargeImage: EventEmitter<{ base64Image: string; receivedTime: string }> =
    new EventEmitter();

  @ViewChild('chatScrollElement', { static: false }) chatScrollElement: ElementRef;
  @ViewChild('messageToSendElement', { static: false }) messageToSendElement: ElementRef;

  chatOpen = false;
  newChatMessages = 0;
  sendMessageForm: UntypedFormGroup;
  isPatient: boolean;

  private _scrollToBottom$ = new Subject<void>();

  constructor(private formBuilder: UntypedFormBuilder, private modal: ModalService) {}

  ngOnInit(): void {
    this.sendMessageForm = this.formBuilder.group({
      messageToSend: ['', [Validators.required]],
    });
    this.config.isOpen$.subscribe(() => this._scrollToBottom$.next());
    this._scrollToBottom$
      .pipe(
        delay(500),
        tap(() => this.scrollToBottom())
      )
      .subscribe();
    this.chatMessageQueue$();
    this.isPatient = this.config.chatType === 0;
  }

  sendChatMessage() {
    const msg = this.sendMessageForm.get('messageToSend').value;
    this.sendMessage.emit(msg);
    this.sendMessageForm.reset();
  }

  enlargeChatImage(base64Image: string, receivedTime: string) {
    this.enlargeImage.emit({ base64Image, receivedTime });
  }

  private chatMessageQueue$() {
    this.config.chatMessagesArr$.pipe(tap(() => this._scrollToBottom$.next())).subscribe();
  }

  trackByIndex(index: number, el: any) {
    return index;
  }

  private scrollToBottom(): void {
    if (this.chatScrollElement)
      this.chatScrollElement.nativeElement.scrollTop =
        this.chatScrollElement.nativeElement.scrollHeight;
    if (this.messageToSendElement) this.messageToSendElement.nativeElement.focus();
  }
}
