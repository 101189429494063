import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNhsNumber',
})
export class FormatNHSNumberPipe implements PipeTransform {
  transform(value: string, defaultValue = 'Undetermined'): string {
    if (value) {
      return value.replace(/([0-9]{3})([0-9]{3})(.*)/, '$1 $2 $3');
    }
    return defaultValue;
  }
}
