import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { tap, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PatientConsultationService } from '../../services/patient-consultation/patient-consultation.service';
import { ConsultationTranslationService } from '../../services/consultation-translation/consultation-translation.service';
import { ChatType, ChatMessage, ConsultationChatConfig } from '@pushdr/common/components';
import { ModalService } from '@pushdr/common/overlay';
import { CliniciansImageModalComponent } from '../image-modal/image-modal.component';
import { TranslatorDialState } from '@pushdr/common/types';
@Component({
  selector: 'pushdr-messenger-chat',
  templateUrl: './messenger-chat.component.html',
  styleUrls: ['./messenger-chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessengerChatComponent implements OnInit {
  translationCallStatus = TranslatorDialState;
  chatType = ChatType;
  chatOpen = false;
  newChatMessages = 0;
  chatConfig$: ConsultationChatConfig;
  chatMessagesArr$: Observable<ChatMessage[]>;
  translatorState$ = this.translator.currentState$;
  isOpen$: Observable<boolean>;

  constructor(
    private consultation: PatientConsultationService,
    private translator: ConsultationTranslationService,
    private modal: ModalService
  ) {}

  ngOnInit() {
    this.chatConfig$ = {
      chatType: ChatType.DOCTOR,
      isOpen$: this.consultation.onChatOpened$(),
      chatMessagesArr$: this.consultation.onChatUpdated$(),
    };
    this.consultation
      .onChatUpdated$()
      .pipe(tap(msgs => this.updateNewMessageCounter(!!msgs.length)))
      .subscribe();
  }

  endTranslation() {
    this.translator
      .disconnectTranslator()
      .pipe(
        take(1),
        tap(() => this.translator.currentState$.next(TranslatorDialState.CALL_NOT_STARTED))
      )
      .subscribe();
  }

  enlargeImage(base64Image: string, receivedTime: string) {
    const data = {
      base64Image: base64Image,
      receivedTime: receivedTime,
    };
    const options = { autoFocus: false };
    this.modal.showCustom(CliniciansImageModalComponent, data, void 0, options);
  }

  sendChatMessage(msg) {
    this.consultation.sendChatMessage(msg);
  }

  toggleChat() {
    this.consultation.toggleChat(!this.chatOpen);
    this.chatOpen = !this.chatOpen;
    this.updateNewMessageCounter(false);
  }

  private updateNewMessageCounter(newMessages) {
    this.newChatMessages = !this.chatOpen && newMessages ? ++this.newChatMessages : 0;
  }
}
