import { NgModule } from '@angular/core';
import { CliniciansIframeModalComponent } from './iframe-modal.component';
import { CommonModule } from '@angular/common';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
  imports: [CommonModule, A11yModule],
  declarations: [CliniciansIframeModalComponent],
  exports: [CliniciansIframeModalComponent],
})
export class CliniciansIframeModalModule {}
