import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpentokComponent } from './opentok.component';
import { VideoErrorModalComponent } from './components/video-error-modal/video-error-modal.component';
import { OpentokContainerComponent } from './components/opentok-container/opentok-container.component';

@NgModule({
  imports: [CommonModule],
  declarations: [OpentokComponent, VideoErrorModalComponent, OpentokContainerComponent],
  exports: [OpentokComponent, VideoErrorModalComponent, OpentokContainerComponent],
})
export class OpentokModule {}
