import { Injectable } from '@angular/core';
import {
  ConsultationState,
  ConsultationStateService,
  ConsultationStorageService,
  State,
} from '@pushdr/clinicians/common';
import {
  AnalyticsBusService,
  AnalyticsEvent,
  CurrentOrderIdService,
} from '@pushdr/common/data-access/analytics';
import { MessageQueue } from '@pushdr/common/utils';
import { EnvironmentProxyService } from '@pushdr/environment';
import { ApiDoctorsConsultation } from '@pushdr/doctors/data-access/doctors-api';
import { BehaviorSubject, merge, Observable, of, Subject, firstValueFrom } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { ChatMessage, ChatMessageImage, ChatMessageType } from '@pushdr/common/components';
import { TranslatorDialState } from '@pushdr/common/types';
import { ConsultationTranslationService } from '../consultation-translation/consultation-translation.service';
export enum PatientConsultationErrorCodes {
  // MQ-R
  MQ_FAILED_TO_INIT = 'message-queue:failed-to-init',
  MQ_COULD_NOT_RECONNECT = 'message-queue:failed-to-reconnect',
}

export interface PatientConsultationError {
  code: PatientConsultationErrorCodes;
  error?: PatientConsultationErrorCodes;
}

@Injectable({
  providedIn: 'root',
})
export class PatientConsultationService {
  private consultationError$ = new Subject<PatientConsultationError>();
  private chatMessage$: BehaviorSubject<ChatMessage[]> = new BehaviorSubject([]);
  private chatMessageImage$: BehaviorSubject<ChatMessageImage[]> = new BehaviorSubject([]);
  private isChatOpen$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private destroyed$ = new Subject<void>();

  constructor(
    private doctorMQ: MessageQueue,
    private envProxy: EnvironmentProxyService,
    private consultationApi: ApiDoctorsConsultation,
    private consultationStorage: ConsultationStorageService,
    private msgBus: AnalyticsBusService,
    private translator: ConsultationTranslationService,
    private consultationState: ConsultationStateService,
    private order: CurrentOrderIdService
  ) {}

  get error$() {
    return this.consultationError$.asObservable().pipe(takeUntil(this.destroyed$));
  }

  start(key: string, hash: string) {
    this.setupStorage(hash, key);
    this.startMessageQueue()
      .pipe(
        tap(() => this.setupAutoReconnect()),
        switchMap(() => this.doctorMQ.start()),
        tap(() =>
          this.msgBus.trackEvent(
            AnalyticsEvent.info('clinicians.consultation.mq.event', 'Started signal message queue')
          )
        ),
        tap(() => this.handleTranslatorCallUpdates()),
        switchMap(() => merge(this.receiveChatImage$(), this.receiveChatMessage$())),
        takeUntil(this.destroyed$)
      )
      .subscribe(
        chatMsg => {
          this.updateChat(chatMsg);
        },
        err =>
          this.emitError(
            'Message queue failed to init',
            PatientConsultationErrorCodes.MQ_FAILED_TO_INIT,
            err
          )
      );
  }

  stop() {
    this.msgBus.trackEvent(
      AnalyticsEvent.info('clinicians.consultation.stopped', 'Consultation stopped')
    );
    this.consultationState.state = ConsultationState.NOT_CONSULTING;
  }

  destroy() {
    this.msgBus.trackEvent(
      AnalyticsEvent.info('clinicians.consultation.exited', 'Exited consultation')
    );
    this.destroyed$.next();
    this.chatMessage$.next([]);
    this.doctorMQ.destroy();
  }

  toggleChat(isOpen: boolean) {
    this.isChatOpen$.next(isOpen);
  }

  onPatientConnected$() {
    return this.doctorMQ
      .onMessage('MSG:Patient Connected')
      .pipe(
        tap(() =>
          this.msgBus.trackEvent(
            AnalyticsEvent.info('clinicians.consultation.patient-connected', 'Patient connected')
          )
        )
      );
  }

  onPatientDisconnected$() {
    return merge([
      this.doctorMQ.onMessage('MSG:Patient Disconnected'),
      this.doctorMQ.onMessage('DisconnectDisrupted'),
    ]).pipe(
      tap(() =>
        this.msgBus.trackEvent(
          AnalyticsEvent.info(
            'clinicians.consultation.patient-disconnected',
            'Patient disconnected'
          )
        )
      )
    );
  }

  onChatUpdated$() {
    return this.chatMessage$.asObservable().pipe(
      takeUntil(this.destroyed$),
      tap(() =>
        this.msgBus.trackEvent(
          AnalyticsEvent.info('clinicians.consultation.chat-updated', 'Chat updated')
        )
      ),
      map(msgs => (msgs ? msgs : []))
    );
  }

  onChatOpened$() {
    return this.isChatOpen$.asObservable().pipe(
      takeUntil(this.destroyed$),
      tap(() =>
        this.msgBus.trackEvent(
          AnalyticsEvent.info('clinicians.consultation.chat-toggle', 'Doctor chat window opened')
        )
      )
    );
  }

  sendChatMessage(msg: string) {
    this.doctorMQ.send(msg);
    this.msgBus.trackEvent(
      AnalyticsEvent.info('clinicians.consultation.chat-message-sent', 'Doctor sent chat message')
    );
    this.updateChat(new ChatMessage(msg, ChatMessageType.SENT_MSG, new Date().toISOString()));
  }

  systemMessage(msg: string) {
    this.updateChat(new ChatMessage(msg, ChatMessageType.SYSTEM, new Date().toISOString()));
  }

  getChatImage$(id: string): Observable<ChatMessageImage> {
    return this.chatMessageImage$.pipe(
      map(imagesArr => imagesArr.find(image => image.id === id)),
      switchMap(image => {
        if (image === undefined) {
          return this.consultationApi.downloadImage(this.order.id, id).pipe(
            map(downloadedImage => {
              const newImg: ChatMessageImage = { id, image: downloadedImage };
              const nextChatMessageImageState = [...this.chatMessageImage$.value, newImg];
              this.chatMessageImage$.next(nextChatMessageImageState);
              return newImg;
            })
          );
        }
        return of(image);
      })
    );
  }

  private async setupStorage(hash: string, key: string) {
    this.msgBus.trackEvent(
      AnalyticsEvent.info(
        'clinicians.consultation.setup-storage',
        'Setting up storage for consultation'
      )
    );
    this.consultationStorage.startStorageForConsultation(hash, key);
    const initialChatState = await firstValueFrom(
      this.consultationStorage.getState$(State.MESSAGE)
    );

    const messagesWithImages = this.mapImagesToMessages(initialChatState);
    this.chatMessage$.next(messagesWithImages);
  }

  private updateChat(chatMsg: ChatMessage) {
    const nextMessageState = this.mapImagesToMessages([...this.chatMessage$.value, chatMsg]);
    this.chatMessage$.next(nextMessageState);
    this.consultationStorage.setState(
      State.MESSAGE,
      nextMessageState.map(o => ({
        sender: o.sender,
        message: o.message,
        receivedTime: o.receivedTime,
      }))
    );
  }

  private mapImagesToMessages(chatMessageArr: ChatMessage[]) {
    return chatMessageArr.map(m =>
      m.sender === ChatMessageType.IMAGE
        ? { ...m, image$: this.getChatImage$(m.message) }
        : { ...m }
    );
  }

  setTranslatorStatus(status: TranslatorDialState) {
    this.translator.currentState$.next(status);
  }

  handleTranslatorCallUpdates() {
    this.doctorMQ
      .onMessage('TRANSLATION_CALL:')
      .pipe(
        startWith('TRANSLATION_CALL:call_not_started'),
        distinctUntilChanged(),
        map(msg => msg.replace('TRANSLATION_CALL:', '')),
        pairwise(),
        tap((msg: [TranslatorDialState, TranslatorDialState]) => {
          if (
            [
              TranslatorDialState.FAILED,
              TranslatorDialState.CANCELLED,
              TranslatorDialState.TIMEOUT,
              TranslatorDialState.DISCONNECTED,
              TranslatorDialState.BUSY,
              TranslatorDialState.REJECTED,
              TranslatorDialState.UNANSWERED,
            ].includes(msg[1])
          ) {
            msg[1] = TranslatorDialState.FAILED;
          }
          if (
            [TranslatorDialState.ANSWERED, TranslatorDialState.CALL_NOT_STARTED].includes(msg[0]) &&
            msg[1] === TranslatorDialState.COMPLETED
          ) {
            this.setTranslatorStatus(TranslatorDialState.CALL_NOT_STARTED);
          } else {
            msg[1] !== TranslatorDialState.COMPLETED && this.setTranslatorStatus(msg[1]);
          }
        })
      )
      .subscribe();
  }

  private receiveChatImage$() {
    // TODO remove the typo when mobile clients update
    //MSG:imageReceived:
    return merge(
      this.doctorMQ
        .onMessage('MSG:imageReceived: ')
        .pipe(map(msg => msg.replace('MSG:imageReceived: ', ''))),
      this.doctorMQ
        .onMessage('MSG:imageRecieved: ')
        .pipe(map(msg => msg.replace('MSG:imageRecieved: ', '')))
    ).pipe(
      tap(() =>
        this.msgBus.trackEvent(
          AnalyticsEvent.info('clinicians.consultation.image-received', 'Image received')
        )
      ),
      map(
        (imageId: string) =>
          new ChatMessage(imageId, ChatMessageType.IMAGE, new Date().toISOString())
      )
    );
  }

  private receiveChatMessage$() {
    return this.doctorMQ.onMessage('MSG:').pipe(
      map(msg => msg.replace('MSG:', '')),
      filter(type =>
        ['Patient Connected', 'Patient Disconnected', 'imageReceived'].every(
          str => type.indexOf(str) !== 0
        )
      ),
      tap(() =>
        this.msgBus.trackEvent(
          AnalyticsEvent.info('clinicians.consultation.message-received', 'Chat message received')
        )
      ),
      map(
        (msg: string) =>
          new ChatMessage(msg, ChatMessageType.RECEIVED_MSG, new Date().toISOString())
      )
    );
  }

  private startMessageQueue() {
    this.msgBus.trackEvent(
      AnalyticsEvent.info(
        'clinicians.consultation.start-message-queue',
        'SignalR message queue started'
      )
    );
    const hubUrl = this.envProxy.environment.doctors.mq.domainv3;
    const $Url = this.envProxy.environment.doctors.mq.signalRv3;
    return this.doctorMQ.init({
      hubUrl,
      $Url,
    });
  }

  private setupAutoReconnect() {
    this.doctorMQ
      .onDisconnect()
      .pipe(
        tap(() =>
          this.msgBus.trackEvent(
            AnalyticsEvent.info(
              'clinicians.consultation.disconnected-from-signal',
              'Disconnected from SignalR'
            )
          )
        ),
        switchMap(() => this.doctorMQ.reconnect()),
        tap(() =>
          this.msgBus.trackEvent(
            AnalyticsEvent.info(
              'clinicians.consultation.connecting-to-signal',
              'Connecting to SignalR'
            )
          )
        )
      )
      .subscribe({
        error: () =>
          this.emitError(
            'Patient could not reconnect',
            PatientConsultationErrorCodes.MQ_COULD_NOT_RECONNECT
          ),
      });
  }

  private emitError(msg: string, code: PatientConsultationErrorCodes, error = null) {
    this.msgBus.trackEvent(AnalyticsEvent.error(`clinicians.consultation.error-thrown}`, msg));
    this.consultationError$.next({ code, error });
  }
}
