<pushdr-consultation-card
  [showFooter]="true"
  [hasData]="!!(warnings$ | async) || hasError"
  bodyPadding="p-0">
  <pushdr-prescription-card-header header></pushdr-prescription-card-header>

  <ng-container
    body
    *ngIf="{
      dispenser: dispenser$ | async,
      items: prescriptionItems$ | async,
      hasWarnings: hasWarnings$ | async
    } as prescription">
    <div class="p-5 shadow-header" *ngIf="prescription.dispenser || prescription.hasWarnings">
      <div class="flex justify-between p-3 bg-indigo-100 rounded">
        <div class="flex items-center w-full">
          <div class="mr-4 material-icons">add_location</div>
          <div>
            <div class="text-base">{{ prescription.dispenser?.name }}</div>
            <div class="font-light">{{ prescription.dispenser?.address?.line?.join(' ') }}</div>
          </div>
        </div>
        <div>
          <button
            data-cy="change-dispenser"
            class="p-3 px-5 mr-3 bg-white btn text-primary"
            (click)="proceedToDispenserSelect()">
            Change
          </button>
        </div>
      </div>
      <div
        *ngIf="prescription.hasWarnings && !!prescription.items?.length"
        class="flex justify-between p-3 my-2 bg-red-100 rounded">
        <div class="flex items-center w-full text-red-500">
          <div class="mr-4 material-icons">warning_amber</div>
          <div>
            <div class="text-base">Warnings accumulated</div>
          </div>
        </div>
        <div>
          <button
            data-cy="view-warnings"
            class="p-3 px-5 mr-3 bg-white btn text-primary"
            (click)="viewWarnings()">
            View
          </button>
        </div>
      </div>
      <div *ngIf="hasError">
        <p class="p-4 mt-4 border border-red-500 bg-red-100 text-red-500 rounded">
          Sorry, currently we are unable to provide support via
          <abbr title="Clinical Decision Support">CDS</abbr> channel. Please continue with the
          consultation, and use either EMIS/TPP system where necessary.
        </p>
      </div>
    </div>
    <div class="py-3">
      <div class="flex items-center justify-between px-5 mb-2">
        <div>
          <h3 class="mt-1 mb-1">Basket</h3>
        </div>
        <pushdr-patient-notes
          [notes]="patientNotes$ | async"
          (pdrEdit)="setPatientNotes()"></pushdr-patient-notes>
      </div>
      <article class="flex items-center basket-item" *ngFor="let item of prescription.items">
        <div class="flex-grow">
          <span class="line1"
            ><strong>{{ item.medicationName }}</strong>
            (
            <span *ngIf="item.duration">
              {{ item.duration }} days / {{ item.quantity }}
              {{ item.unitOfMeasure | toTitleCase }}(s)</span
            >
            <span *ngIf="!item.duration">
              {{ item.quantity }} {{ item.unitOfMeasure | toTitleCase }}(s)</span
            >
            )
          </span>
          <span class="line2"><label>Dose:</label> {{ item.dosage }}</span>
        </div>
        <div class="flex-grow-0 flex-shrink-0 action-buttons">
          <button
            class="p-1 ml-2 rounded hover:text-indigo-500 material-icons"
            routerLink="../specify-medication"
            [queryParams]="{ edit: item.medicationCode }">
            edit
          </button>
          <button
            class="p-1 ml-2 rounded hover:text-indigo-500 material-icons"
            (click)="removeDrug(item.medicationCode)">
            delete
          </button>
        </div>
      </article>
    </div>
  </ng-container>

  <ng-container
    footer
    *ngIf="{
      dispenser: dispenser$ | async,
      hasPrescriptionItems: hasPrescriptionItems$ | async
    } as data">
    <div class="flex mt-3 mb-3">
      <div class="flex flex-col items-center w-full">
        <div class="flex mt-3 mb-3">
          <button
            data-cy="add-another-drug-button"
            class="p-3 px-5 mr-3 btn text-primary border-primary"
            (click)="addAnotherDrug()">
            Add {{ data.hasPrescriptionItems ? 'another' : 'a' }} drug
          </button>
          <button
            data-cy="specify-button"
            class="p-3 px-5 text-white btn"
            *ngIf="!data.dispenser"
            [ngClass]="{
              'cursor-not-allowed bg-primary-disabled': !data.hasPrescriptionItems,
              'bg-primary': data.hasPrescriptionItems
            }"
            (click)="proceedToDispenserSelect()"
            [disabled]="data.hasPrescriptionItems ? null : true">
            Specify pharmacy search area
          </button>

          <button
            data-cy="issue-button"
            class="p-3 px-5 text-white btn bg-primary"
            *ngIf="data.hasPrescriptionItems && data.dispenser"
            (click)="issuePrescription()">
            Issue
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</pushdr-consultation-card>
