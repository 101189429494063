<ng-container>
  <pushdr-card [hasData]="!submitting">
    <section class="flex flex-col w-full h-full">
      <header class="relative z-10 p-4 text-xl bg-grey-100 text-bluegrey-800 shadow-header">
        Account holder identification
      </header>
      <article
        *ngIf="patient$ | async as consultation"
        class="flex flex-col h-full overflow-y-auto bg-white scrollbar">
        <section *ngIf="!bigImage" class="p-4 font-normal bg-information-light">
          <p class="mb-0 text-sm font-medium text-information">
            Please ensure the image below is a valid form of photo ID and the photograph matches the
            account holder that you are speaking with.
          </p>
        </section>
        <section class="p-4 text-sm">
          <div class="flex flex-row mb-4">
            <div class="inline-block mr-10">
              <span class="block mb-1 text-grey-500">Name</span>
              <span class="text-bluegrey-800">{{ consultation.Customer.Name }}</span>
            </div>
            <div class="inline-block mr-10">
              <span class="block mb-1 text-grey-500">Date of birth</span>
              <span class="text-bluegrey-800">
                {{ consultation.Customer.DateOfBirth + ' (' + consultation.Customer.Age + ')' }}
              </span>
            </div>
            <div class="inline-block mr-10">
              <span class="block mb-1 text-grey-500">Gender</span>
              <span class="text-bluegrey-800">{{ consultation.Customer.GenderLabel }}</span>
            </div>
          </div>
          <div class="flex flex-row">
            <div class="inline-block mr-10">
              <span class="block mb-1 text-grey-500">Address</span>
              <span class="block text-bluegrey-800">
                {{ consultation.Customer.Address.Line1 }} {{ consultation.Customer.Address.Line2 }}
              </span>
              <span class="block text-bluegrey-800">{{ consultation.Customer.Address.Line3 }}</span>
              <span class="block text-bluegrey-800">{{
                consultation.Customer.Address.PostTown
              }}</span>
              <span class="block text-bluegrey-800">{{
                consultation.Customer.Address.Postcode
              }}</span>
            </div>
            <div class="inline-block mr-10">
              <span class="block mb-1 text-grey-500">Registered GP Surgery</span>
              <span class="block text-bluegrey-800">
                {{ consultation.Customer.GpSummary.Name | uppercase }}
              </span>
            </div>
          </div>
        </section>
        <section
          class="px-4 py-2 mx-2 bg-cream"
          style="height: 245px"
          [style.height.px]="bigImage ? 328 : 245"
          *ngIf="idBase64Image$ | async as idImage; else loadingImage">
          <pushdr-patient-id
            [base64Image]="idImage"
            (fullscreen)="toggleVideoSize($event)"></pushdr-patient-id>
        </section>
        <div class="flex-grow w-full border-b border-solid border-grey-300"></div>
        <ng-container *ngIf="accountHolderIdentificationTypes$ | async as identificationTypes">
          <form
            *ngIf="accountHolderFormGroup"
            class="flex flex-row p-4 mb-2"
            [formGroup]="accountHolderFormGroup"
            (ngSubmit)="accountHolderFormGroup.valid && onSubmit(accountHolderFormGroup.value)">
            <div class="flex flex-row flex-grow">
              <label class="mr-6 text-sm text-body-text">
                Account holder ID type
                <select
                  formControlName="idTypeSelect"
                  class="block p-3 bg-white border border-solid rounded border-bluegrey-600">
                  <option value="null" disabled selected>Pick from dropdown</option>
                  <option *ngFor="let idType of identificationTypes" [ngValue]="idType">
                    {{ idType.Description }}
                  </option>
                </select>
              </label>
              <div
                class="flex-grow mr-6 text-sm font-extralight bg-white text-body-text"
                [class.hidden]="!canConsultChildNhs">
                <span class="block mt-1">Who will be consulting?</span>
                <div class="flex mt-3">
                  <label class="ml-0">
                    <input
                      type="radio"
                      name="consultationType"
                      formControlName="consultationType"
                      [value]="CustomerType.Adult"
                      data-cy="accountholder" />
                    Account Holder
                  </label>
                  <label class="ml-4">
                    <input
                      type="radio"
                      name="consultationType"
                      formControlName="consultationType"
                      [value]="CustomerType.Child"
                      data-cy="child" />
                    Child
                  </label>
                </div>
              </div>
            </div>
            <button
              class="self-end p-3 text-white rounded bg-primary hover:bg-primary-hover"
              [ngClass]="{
                'cursor-not-allowed bg-primary-disabled hover:bg-primary-disabled':
                  !accountHolderFormGroup.valid
              }"
              type="submit">
              Submit
            </button>
          </form>
        </ng-container>
      </article>
    </section>
  </pushdr-card>
</ng-container>

<ng-template #loadingImage>
  <section>
    <h3 class="m-4 loading">Loading uploaded image</h3>
  </section>
</ng-template>

<ng-template #loading>
  <pushdr-spinner-loader></pushdr-spinner-loader>
</ng-template>
