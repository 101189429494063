import { Inject, Injectable } from '@angular/core';
import { CardReaderService, CliniciansDoctorsDetailsService } from '@pushdr/clinicians/common';
import { PharmaceuticalsApiService } from '@pushdr/prescription/api';
import { PrescriptionInputData } from '@pushdr/prescription/fdb';
import { CurrentOrderIdService } from '@pushdr/common/data-access/analytics';
import { ModalService } from '@pushdr/common/overlay';
import { clog, ExtendedWindow, StorageService, WINDOW } from '@pushdr/common/utils';
import {
  ApiPrescriptionsService,
  PrescriptionCollection,
  PrescriptionCreateResponse,
  SignedPrescription,
  PrescriptionMedication,
  PrescriptionCreate,
} from '@pushdr/prescription/api';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, throwError } from 'rxjs';
import {
  catchError,
  concatMap,
  finalize,
  map,
  switchMap,
  take,
  tap,
  toArray,
  withLatestFrom,
} from 'rxjs/operators';
import { PrescriptionMapper } from '@pushdr/prescription/signing';
import { PrescriptionService } from './prescription.service';
import { PrescriptionDispenserService } from './prescription-dispenser.service';
import { PrescriptionBasketService } from './prescription-basket.service';
import {
  SigningPrescriptionModalComponent,
  SigningPrescriptionModalComponentArgs,
} from '@pushdr/prescription/signing';
import { PrescriptionProxy } from '../prescription-proxy';

@Injectable({
  providedIn: 'root',
})
export class PrescriptionSigningService {
  private _createPrescriptionResponse: PrescriptionCollection;
  get createPrescriptionResponse(): PrescriptionCollection {
    if (!this._createPrescriptionResponse) this.loadPrescriptionCreateResponses();
    if (!this.order.id || this.order.id !== this._createPrescriptionResponse?.orderId)
      this._createPrescriptionResponse = null;
    return this._createPrescriptionResponse;
  }
  set createPrescriptionResponse(value: PrescriptionCollection) {
    if (value) value.orderId = this.order.id;
    this._createPrescriptionResponse = value;
    this.savePrescriptionsCreateResponses();
  }

  constructor(
    private cardReaderService: CardReaderService,
    private apiPrescriptionsService: ApiPrescriptionsService,
    private prescriptionProxy: PrescriptionProxy,
    private doctor: CliniciansDoctorsDetailsService,
    private pharmAPI: PharmaceuticalsApiService,
    private modal: ModalService,
    private prescription: PrescriptionService,
    private dispenser: PrescriptionDispenserService,
    private basket: PrescriptionBasketService,
    private storage: StorageService,
    private order: CurrentOrderIdService,
    @Inject(WINDOW) private window: ExtendedWindow
  ) {}

  createPrescription$() {
    const { statusOutput, statusOp } = this.statusOutput();
    statusOutput.next({ title: 'Create Prescription', message: 'Aggregating data...' });

    const createPrescriptions$ = this.getPrescriptionDataAggregator().pipe(
      statusOp({ title: 'Create Prescription', message: 'Sending to Spine...' }),
      concatMap(createData => this.apiPrescriptionsService.create(createData)),
      toArray()
    );

    return createPrescriptions$.pipe(
      map((prescriptions): PrescriptionCollection => ({ prescriptions })),
      tap(collection => (this.createPrescriptionResponse = collection)),
      tap(() => setTimeout(() => statusOutput.complete(), 200)),
      catchError(error => {
        console.error(error);
        statusOutput.next({
          title: 'Error',
          error: error,
          message: error.message,
          closeButton: true,
        });
        return throwError(error);
      })
    );
  }

  issuePrescription$(pin: string) {
    const { statusOutput } = this.statusOutput();
    statusOutput.next({
      title: `Prescriptions`,
      message: 'Issuing prescription',
    });

    const prescriptions = this.createPrescriptionResponse.prescriptions;
    const issuePrescriptions$ = of(...prescriptions).pipe(
      concatMap(unsignedPrescription =>
        this.signPrescription(unsignedPrescription, pin).pipe(
          switchMap(signedPrescription => this.apiPrescriptionsService.issue(signedPrescription)),
          tap(() => this.removePrescriptionItems(unsignedPrescription))
        )
      ),
      toArray()
    );

    return issuePrescriptions$.pipe(
      tap(() => (this.createPrescriptionResponse = null)),
      tap(() =>
        statusOutput.next({
          title: `Prescriptions`,
          message: 'All prescriptions sent',
          closeButton: true,
        })
      ),
      catchError(error => {
        console.error(error);
        statusOutput.next({
          title: 'Error',
          error: error.error || error,
          message: error?.error?.message || error?.message,
          closeButton: true,
        });
        return throwError(error);
      })
    );
  }

  private removePrescriptionItems(prescription: PrescriptionCreateResponse): void {
    const idx = this.createPrescriptionResponse.prescriptions.findIndex(
      f => f.prescriptionId === prescription.prescriptionId
    );
    this.createPrescriptionResponse.prescriptions.splice(idx, 1);
    prescription.medications.forEach(({ medicationCode }) =>
      this.basket.removePrescriptionBySnomedCode(medicationCode)
    );
    this.savePrescriptionsCreateResponses();
  }

  private getPrescriptionDataAggregator() {
    const combinedData$ = forkJoin({
      card: this.cardReaderService.getCardData$(),
      patientDetails: this.prescriptionProxy.getPatientDetails(),
      doctor: this.doctor.details$,
    });

    return combineLatest([
      combinedData$,
      this.dispenser.dispenser$,
      this.dispenser.patientNotes$,
    ]).pipe(
      take(1),
      map(([details, dispenser, patientNotes]) =>
        PrescriptionMapper.mapPrescription(details, dispenser, patientNotes)
      ),
      withLatestFrom(this.prescription.getPrescriptions()),
      switchMap(([create, prescriptions]) =>
        // Fill the prescription POST body with medications
        of(...prescriptions.prescriptions).pipe(
          concatMap(prescription => this.mapMedications$(prescription.prescriptionItems)),
          map((medications): PrescriptionCreate => ({ ...create, medications }))
        )
      )
    );
  }

  private statusOutput() {
    const modalName = 'PrescriptionSigningProg';
    const statusOutput = new BehaviorSubject<SigningPrescriptionModalComponentArgs>({});
    this.modal.showCustom(
      SigningPrescriptionModalComponent,
      {
        modalArgs$: statusOutput.asObservable().pipe(finalize(() => this.modal.close(modalName))),
      },
      modalName
    );

    const statusOp =
      <T>(
        args: SigningPrescriptionModalComponentArgs
      ): ((source$: Observable<T>) => Observable<T>) =>
      source$ =>
        source$.pipe(tap(() => statusOutput.next(args)));
    return { statusOutput, statusOp };
  }

  signPrescription(
    unSPres: PrescriptionCreateResponse,
    pin: string
  ): Observable<SignedPrescription> {
    return this.cardReaderService.signPrescription$(unSPres.unsignedSignatureXml, pin).pipe(
      switchMap(signed => {
        if (!this.cardReaderService.validateCardEnvironment(signed.environment)) {
          return throwError({ error: { detail: 'Invalid Push Dr Smart Card Reader Environment' } });
        }
        return of(signed);
      }),
      map(signed => <SignedPrescription>{ ...unSPres, signature: signed.signature }),
      catchError(err => {
        clog(err);

        if (err.status === 0) {
          this.window.location.href = 'pushdrcard://';
          return throwError({
            message:
              'Push Dr Smart Card Reader is not running. \r\n\r\nIf installed it will now launch and you should try again.',
          });
        } else {
          return throwError({ message: err?.error?.detail });
        }
      })
    );
  }

  mapMedications$(medications: PrescriptionInputData[]): Observable<PrescriptionMedication[]> {
    const fillers = medications.map(m =>
      this.pharmAPI
        .getPharmaceuticalBySnomedCode(m.medicationCode)
        .pipe(map(x => PrescriptionMapper.mapMedication(m, x)))
    );
    return forkJoin(fillers);
  }

  private loadPrescriptionCreateResponses(): PrescriptionCollection {
    this._createPrescriptionResponse = this.storage.getSessionStorage(
      'PrescriptionCreateResponses',
      true
    );
    return this._createPrescriptionResponse;
  }

  private savePrescriptionsCreateResponses() {
    this.storage.setSessionStorage('PrescriptionCreateResponses', this._createPrescriptionResponse);
  }
}
