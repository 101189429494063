<div class="mt-8 text-left-important c-modal__wrap" [cdkTrapFocus]="ready$ | async">
  <h2>Good practice reminder</h2>
  <div class="modal__body">
    <p class="mb-5">
      Before consulting with patients via Push Doctor, consider GMC guidance on remote
      consultations.
    </p>
    <ul class="pl-3 text-sm font-light list-disc">
      <li class="mb-2">
        Ensure you can assess a patient's capacity and consider whether remote consultation is
        appropriate.
      </li>
      <li class="mb-2">
        Consider whether you require access to the patient's full medical record to make an adequate
        assessment.
      </li>
      <li class="mb-2">
        Consider the limitations of remote consultations when advising or prescribing treatment for
        a patient.
      </li>
      <li class="mb-2">
        Consider the need for further assessments, including physical examination and face-to-face
        assessments.
      </li>
    </ul>
  </div>
  <div class="modal__footer modal__footer--one-row">
    <button (click)="done(false)" class="c-btn c-btn--primary c-btn--white-bg u-mr16@xs">
      Cancel
    </button>
    <button (click)="done(true)" class="c-btn c-btn--primary" data-cy="btn-understand">
      I understand
    </button>
  </div>
</div>
