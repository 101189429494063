import { Pipe, PipeTransform } from '@angular/core';
import { PharmaceuticalsMedicationDetails } from '@pushdr/prescription/api';

@Pipe({
  name: 'medicationPackSizes',
})
export class MedicationPackSizesPipe implements PipeTransform {
  transform(med: PharmaceuticalsMedicationDetails, unitOfMeasure: string = ''): string {
    if (med && Object.prototype.hasOwnProperty.call(med, 'packSize') && med.packSize.length) {
      return (
        (med.packSize.filter(s => s.unitTypeCode === unitOfMeasure || !unitOfMeasure) || []).map(
          item => item.quantity
        ) || []
      )
        .filter(s => s)
        .join(', ');
    } else {
      return '';
    }
  }
}
