import { Component, Input } from '@angular/core';

@Component({
  selector: 'pushdr-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Input() hasData = false;
  @Input() hasShadow = true;
  @Input() hasRoundedCorners = true;
  @Input() backgroundColor = '#fff';
  @Input() messageOveride = 'Loading';
}
