import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'pushdr-patient-notes',
  templateUrl: './patient-notes.component.html',
  styleUrls: ['./patient-notes.component.scss'],
})
export class PatientNotesComponent {
  @Input() notes: string = null;
  @Output() pdrEdit = new EventEmitter<void>();

  get hasNotes(): boolean {
    return !!this.notes;
  }
}
