import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {
  AnalyticsBusService,
  AnalyticsEvent,
  LoggingEvent,
} from '@pushdr/common/data-access/analytics';
import * as moment from 'moment';
import { map, catchError } from 'rxjs/operators';

enum ResponseType {
  RESPONSE = 'RES',
  ERROR = 'ERR',
}

@Injectable({
  providedIn: 'root',
})
export class RestNetworkLoggingInterceptorService implements HttpInterceptor {
  constructor(private msgBus: AnalyticsBusService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<
    HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>
  > {
    const requestTime = moment();

    return next.handle(req).pipe(
      map((res: HttpEvent<any>) => {
        if (res instanceof HttpResponse) {
          this.log(
            requestTime,
            ResponseType.RESPONSE,
            res.url,
            res.headers['pdr-correlation-id'],
            res.status
          );
        }
        return res;
      }),
      catchError((error: HttpErrorResponse) => {
        this.log(
          requestTime,
          ResponseType.ERROR,
          error.url,
          error.headers['pdr-correlation-id'],
          error.status
        );
        return throwError(error);
      })
    );
  }

  log(
    requestTime: moment.Moment,
    type: ResponseType,
    url: string,
    correlationId?: string,
    status?: number
  ) {
    const responseTime = moment();
    this.msgBus.trackEvent(
      AnalyticsEvent.network(
        `${type}(${status || 'XXX'}): ${url}`,
        `CorrelationId: ${correlationId || 'No correlation Id'}`,
        {
          ResponseTime: `${responseTime.diff(requestTime, 'milliseconds')} ms`,
          RequestedAt: requestTime.toISOString(),
          ResponseAt: responseTime.toISOString(),
          type: type === ResponseType.ERROR ? LoggingEvent.Error : LoggingEvent.Info,
        }
      )
    );
  }
}
