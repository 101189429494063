import { Inject, Injectable, Optional } from '@angular/core';
import { ExtendedWindow, WINDOW } from '../../utils/window/window.service';
import { StorageService } from '../storage/storage.service';
import { TokenServiceConfig, TokenServiceOptions } from './token-config';

@Injectable({ providedIn: 'root' })
export class TokenRefreshService {
  private refreshTokenName = '';
  private domain = 'auto';

  constructor(
    private store: StorageService,
    @Optional() @Inject(WINDOW) private window?: ExtendedWindow,
    @Optional() @Inject(TokenServiceOptions) config?: TokenServiceConfig
  ) {
    if (config) {
      this.configure(config.tokenName, config.useHostName);
    }
  }

  configure(name: string, useHostName?: boolean) {
    this.refreshTokenName = name + '_refresher';
    this.domain = useHostName ? this.window.location.hostname : 'auto';
    return this;
  }

  exists() {
    return !!this.store.get(this.refreshTokenName);
  }

  set(refreshToken: string) {
    this.store.set(this.refreshTokenName, refreshToken, 0, 0, null, '/', this.domain);
  }

  delete() {
    this.store.delete(this.refreshTokenName);
  }

  get() {
    return this.store.get(this.refreshTokenName);
  }
}
