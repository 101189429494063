export enum PrescriptionStatus {
  Default = 0,
  Created = 1,
  SubmittedToEps = 2,
  SubmittedWithErrors = 3,
  SuccessfullyIssued = 4,
  TimedOut = 5,
  RejectedByEps = 6,
  CancellationRequested = 7,
  CancellationRejectedByEps = 9,
  Cancelled = 10,
  CancellationTimeout = 11,
  ManagedByPX = 12,
  ManuallyCancelled = 13,
}

export const PrescriptionStatusInfo: {
  [index: number]: {
    transitions?: PrescriptionStatus[];
    displayName: string;
    showCancellationReason?: boolean;
  };
} = {
  [PrescriptionStatus.Created]: { displayName: 'Created' },
  [PrescriptionStatus.SubmittedToEps]: { displayName: 'Submitted to EPS' },
  [PrescriptionStatus.SubmittedWithErrors]: { displayName: 'Submitted With Errors' },
  [PrescriptionStatus.SuccessfullyIssued]: { displayName: 'Successfully Issued' },
  [PrescriptionStatus.TimedOut]: {
    displayName: 'Timed Out',
    transitions: [PrescriptionStatus.ManagedByPX],
  },
  [PrescriptionStatus.RejectedByEps]: {
    displayName: 'Rejected By EPS',
    transitions: [PrescriptionStatus.ManagedByPX],
  },
  [PrescriptionStatus.CancellationRequested]: { displayName: 'Cancellation Requested' },
  [PrescriptionStatus.CancellationRejectedByEps]: {
    displayName: 'Cancellation Rejected By EPS',
    transitions: [PrescriptionStatus.ManuallyCancelled],
    showCancellationReason: true,
  },
  [PrescriptionStatus.Cancelled]: { displayName: 'Cancelled', showCancellationReason: true },
  [PrescriptionStatus.CancellationTimeout]: {
    displayName: 'Cancellation Timeout',
    transitions: [PrescriptionStatus.ManuallyCancelled],
    showCancellationReason: true,
  },
  [PrescriptionStatus.ManagedByPX]: { displayName: 'Managed By PX' },
  [PrescriptionStatus.ManuallyCancelled]: {
    displayName: 'Manually Cancelled',
    showCancellationReason: true,
  },
};
