import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Route, RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  CliniciansCommonModule,
  ConsultationState,
  DiagnosisModule,
} from '@pushdr/clinicians/common';
import {
  CommonComponentsModule,
  DatePickerModule,
  FormComponentsModule,
  InputWithCharacterCountModule,
  OpentokModule,
  SpinnerLoaderModule,
} from '@pushdr/common/components';
import { CommonOverlayModule } from '@pushdr/common/overlay';
import { CommonPipesModule, ScrollModule, VarModule } from '@pushdr/common/utils';
import { InlineSVGModule } from 'ng-inline-svg';
import { AcuteMedicationsComponent } from './components/acute-medications/acute-medications.component';
import { AllergiesListComponent } from './components/allergies-list/allergies-list.component';
import { AllergiesComponent } from './components/allergies/allergies.component';
import { AllergyDetailsComponent } from './components/allergy-details/allergy-details.component';
import { CloseEditConsultationModalComponent } from './components/close-edit-consultation-modal/close-edit-consultation-modal.component';
import { ConsultationFooterComponent } from './components/consultation-footer/consultation-footer.component';
import { ConsultationIncompleteActionsModalComponent } from './components/consultation-incomplete-actions-modal/consultation-incomplete-actions-modal.component';
import { ConsultationCardDynamicComponent } from './components/consultation-layout/consultation-card-dynamic/consultation-card-dynamic.component';
import { ConsultationDynamicGroupComponent } from './components/consultation-layout/consultation-dynamic-group/consultation-dynamic-group.component';
import { ConsultationPopoverComponent } from './components/consultation-popover/consultation-popover.component';
import { GpResourcesCardComponent } from './components/gp-resources-card/gp-resources-card.component';
import { CliniciansImageModalComponent } from './components/image-modal/image-modal.component';
import { KeyInformationComponent } from './components/key-information/key-information.component';
import { MedicationGroupComponent } from './components/medication-group/medication-group.component';
import { MedicationIssuesItemComponent } from './components/medication-issues-item/medication-issues-item.component';
import { MedicationIssuesComponent } from './components/medication-issues/medication-issues.component';
import { MedicationListItemComponent } from './components/medication-list-item/medication-list-item.component';
import { MedicationNotesComponent } from './components/medication-notes/medication-notes.component';
import { MedicationPdNoteComponent } from './components/medication-pd-note/medication-pd-note.component';
import { MessengerChatComponent } from './components/messenger-chat/messenger-chat.component';
import { PatientIsDeadModalComponent } from './components/patient-is-dead-modal/patient-is-dead-modal.component';
import { PatientWarningComponent } from './components/patient-warning/patient-warning.component';
import { PatientReceivedImagesComponent } from './components/received-images/received-images.component';
import { RepeatMedicationsComponent } from './components/repeat-medications/repeat-medications.component';
import { ConsultationStateGuard } from './guards/consultation-state.guard';
import { PatientComponent } from './patient.component';
import { CountMedicationsPipe } from './pipes/count-medications.pipe';
import { FindArrayItemPipe } from './pipes/find-array-item.pipe';
import { FormatNHSNumberPipe } from './pipes/format-nhs-number.pipe';
import { GetAllergyOnsetDateTimePipe } from './pipes/get-allergy-onset-date-time.pipe';
import { GetCodingTextPipe } from './pipes/get-coding-text.pipe';
import { CliniciansPatientEmailCommunicationComponent } from './routes/actions/clinicians-patient-email-communication/clinicians-patient-email-communication.component';
import { ConsultationDialTranslatorComponent } from './routes/actions/consultation-dial-translator/consultation-dial-translator.component';
import { ConsultationFitNoteComponent } from './routes/actions/consultation-fit-note/consultation-fit-note.component';
import { ConsultationReferralComponent } from './routes/actions/consultation-referral/consultation-referral.component';
import { CliniciansContactSupportComponent } from './routes/actions/contact-support/contact-support.component';
import { SendATaskComponent } from './routes/actions/send-a-task/send-a-task.component';
import { EmergencyPatientComponent } from './routes/emergency/emergency-patient/emergency-patient.component';
import { ConfirmAlertsComponent } from './routes/id/alerts/confirm-alerts.component';
import { ChildIdModalComponent } from './routes/id/checks/components/child-id-modal/child-id-modal.component';
import { PatientIdComponent } from './routes/id/checks/components/patient-id/patient-id.component';
import { IdChecksComponent } from './routes/id/checks/id-checks.component';
import { NotesCaptureComponent } from './routes/notes/nhs/notes-capture/notes-capture.component';
import { NotesDiagnosisCaptureComponent } from './routes/notes/nhs/notes-diagnosis-capture/notes-diagnosis-capture.component';
import { NotesListComponent } from './routes/notes/nhs/notes-list/notes-list.component';
import { NotesPdfViewModalComponent } from './routes/notes/nhs/notes-pdf-view-modal/notes-pdf-view-modal.component';
import { NotesPdfViewComponent } from './routes/notes/nhs/notes-pdf-view/notes-pdf-view.component';
import { NotesTextViewComponent } from './routes/notes/nhs/notes-text-view/notes-text-view.component';
import { NotesViewerComponent } from './routes/notes/nhs/notes-viewer/notes-viewer.component';
import { PatientAdministrationComponent } from './routes/records/patient-administration/patient-administration.component';
import { PatientAllergiesComponent } from './routes/records/patient-allergies/patient-allergies.component';
import { PatientClinicalComponent } from './routes/records/patient-clinical/patient-clinical.component';
import { PatientEncountersNhsComponent } from './routes/records/patient-encounters/nhs/patient-encounters-nhs.component';
import { PatientImmunisationsComponent } from './routes/records/patient-immunisations/patient-immunisations.component';
import { PatientMedicationsInformationComponent } from './routes/records/patient-medication-information/nhs/patient-medications-information.component';
import { PatientObservationsComponent } from './routes/records/patient-observations/patient-observations.component';
import { PatientProblemsComponent } from './routes/records/patient-problems/patient-problems.component';
import { PatientRecordsTableComponent } from './routes/records/patient-records-table/patient-records-table/patient-records-table.component';
import { PatientReferralsComponent } from './routes/records/patient-referrals/patient-referrals.component';
import { PatientStructuredMedicationComponent } from './routes/records/patient-structured-medication/patient-structured-medication.component';
import { PatientSummaryComponent } from './routes/records/patient-summary/patient-summary.component';
import { SafeguardingInfoComponent } from './routes/safeguarding/safeguarding-info/safeguarding-info.component';
import { SafeguardingStepsComponent } from './routes/safeguarding/safeguarding-steps/safeguarding-steps.component';
import { ConsultationCompleteComponent } from './routes/actions/consultation-complete/consultation-complete.component';
import { ClinicalOutcomeFilterPipe } from './pipes/clinical-outcome-filter.pipe';
import { ArrayGroupByPipe } from './pipes/array-group-by.pipe';
import { ChildIdCheckComponent } from './routes/id/child-id-check/child-id-check.component';
import { ChildIdCheckNhsComponent } from './routes/id/child-id-check-nhs/child-id-check-nhs.component';
import { AutoSaveStatusComponent } from './components/auto-save-status/auto-save-status.component';
import { VideoChatComponent } from './components/video-chat/video-chat.component';
import { EndVideoSessionModalComponent } from './components/end-video-session-modal/end-video-session-modal.component';

export const cliniciansPatientConsultingRoutes: Route[] = [
  {
    path: '',
    redirectTo: 'id',
    pathMatch: 'full',
  },
  {
    path: 'id',
    children: [
      { path: '', redirectTo: 'check', pathMatch: 'full' },
      {
        path: 'check',
        component: IdChecksComponent,
        canActivate: [ConsultationStateGuard],
        data: {
          states: [
            ConsultationState.NOT_CONSULTING,
            ConsultationState.MEET_PATIENT,
            ConsultationState.VERIFYING_IDENTITY,
          ],
        },
      },
      {
        path: 'child-id-check',
        component: ChildIdCheckComponent,
        canActivate: [ConsultationStateGuard],
        data: {
          states: [
            ConsultationState.NOT_CONSULTING,
            ConsultationState.MEET_PATIENT,
            ConsultationState.VERIFYING_IDENTITY,
          ],
        },
      },
      {
        path: 'child-id-check-nhs',
        component: ChildIdCheckNhsComponent,
        canActivate: [ConsultationStateGuard],
        data: {
          states: [
            ConsultationState.NOT_CONSULTING,
            ConsultationState.MEET_PATIENT,
            ConsultationState.VERIFYING_IDENTITY,
          ],
        },
      },
      {
        path: 'alerts',
        component: ConfirmAlertsComponent,
        canActivate: [ConsultationStateGuard],
        data: {
          states: [ConsultationState.CHECKING_ALERTS],
        },
      },
    ],
  },
  {
    path: 'consult',
    children: [
      {
        path: 'default',
        component: KeyInformationComponent,
        canActivate: [ConsultationStateGuard],
        data: {
          states: [ConsultationState.CONSULTING],
          cssClass: 'default',
        },
      },
      {
        path: 'emergency',
        data: {
          cssClass: 'emergency',
        },
        component: EmergencyPatientComponent,
      },
      {
        path: 'actions',
        children: [
          { path: '', redirectTo: 'contact-support', pathMatch: 'full' },
          {
            path: 'send-a-task',
            component: SendATaskComponent,
            canActivate: [ConsultationStateGuard],
            data: {
              states: [ConsultationState.CONSULTING],
            },
          },
          {
            path: 'prescribe',
            loadChildren: () =>
              import('@pushdr/prescription/shell').then(m => m.PrescriptionShellModule),
            data: {
              states: [ConsultationState.CONSULTING],
              cssClass: 'prescribe',
            },
            canActivate: [ConsultationStateGuard],
          },
          {
            path: 'contact-support',
            component: CliniciansContactSupportComponent,
            canActivate: [ConsultationStateGuard],
            data: {
              states: [
                ConsultationState.VERIFYING_IDENTITY,
                ConsultationState.CHECKING_ALERTS,
                ConsultationState.CONSULTING,
                ConsultationState.EDITING,
              ],
            },
          },
          {
            path: 'patient-email-communication',
            component: CliniciansPatientEmailCommunicationComponent,
            canActivate: [ConsultationStateGuard],
            data: {
              states: [ConsultationState.CONSULTING, ConsultationState.EDITING],
            },
          },
          {
            path: 'consultation-referral',
            component: ConsultationReferralComponent,
            canActivate: [ConsultationStateGuard],
            data: {
              states: [ConsultationState.CONSULTING, ConsultationState.EDITING],
            },
          },
          {
            path: 'dial-interpreter',
            component: ConsultationDialTranslatorComponent,
            canActivate: [ConsultationStateGuard],
            data: {
              states: [ConsultationState.CONSULTING],
            },
          },
          {
            path: 'fit-note',
            component: ConsultationFitNoteComponent,
            canActivate: [ConsultationStateGuard],
            data: {
              states: [ConsultationState.CONSULTING, ConsultationState.EDITING],
            },
          },
          {
            path: 'consultation-complete',
            component: ConsultationCompleteComponent,
            canActivate: [ConsultationStateGuard],
            data: {
              states: [
                ConsultationState.CONSULTING,
                ConsultationState.EDITING,
                ConsultationState.VERIFYING_IDENTITY,
              ],
            },
          },
        ],
      },
      {
        path: 'safeguarding',
        data: {
          cssClass: 'safeguarding',
        },
        children: [
          { path: '', redirectTo: 'info', pathMatch: 'full' },
          { path: 'info', component: SafeguardingInfoComponent },
          { path: 'steps', component: SafeguardingStepsComponent },
        ],
      },
      {
        path: 'notes/nhs',
        canActivate: [ConsultationStateGuard],
        data: {
          states: [ConsultationState.CONSULTING],
          cssClass: 'notes',
        },
        children: [
          { path: '', redirectTo: 'capture', pathMatch: 'full' },
          { path: 'capture', component: NotesCaptureComponent },
          { path: 'list', component: NotesListComponent },
          { path: 'view/:id', component: NotesViewerComponent },
        ],
      },
      {
        path: 'records',
        canActivate: [ConsultationStateGuard],
        data: {
          states: [ConsultationState.CONSULTING],
          cssClass: 'records',
        },
        children: [
          { path: '', redirectTo: 'summary', pathMatch: 'full' },
          { path: 'summary', component: PatientSummaryComponent },
          { path: 'encounters/nhs', component: PatientEncountersNhsComponent },
          { path: 'clinical', component: PatientClinicalComponent },
          { path: 'problems', component: PatientProblemsComponent },
          { path: 'allergies', component: PatientAllergiesComponent },
          { path: 'detailed-medications', component: PatientStructuredMedicationComponent },
          { path: 'medications/nhs', component: PatientMedicationsInformationComponent },
          { path: 'referrals', component: PatientReferralsComponent },
          { path: 'observations', component: PatientObservationsComponent },
          { path: 'immunisations', component: PatientImmunisationsComponent },
          { path: 'administration', component: PatientAdministrationComponent },
          {
            path: 'doctor-notes',
            loadChildren: () =>
              import('@pushdr/consultation/doctor-notes').then(m => m.DoctorNotesModule),
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    CliniciansCommonModule,
    SpinnerLoaderModule,
    OpentokModule,
    A11yModule,
    CommonPipesModule,
    CommonOverlayModule,
    FormsModule,
    DiagnosisModule,
    NgSelectModule,
    FormComponentsModule,
    InlineSVGModule.forRoot(),
    VarModule,
    DatePickerModule,
    InputWithCharacterCountModule,
    CommonComponentsModule,
    ScrollModule,
  ],
  declarations: [
    CountMedicationsPipe,
    FindArrayItemPipe,
    GetCodingTextPipe,
    MedicationPdNoteComponent,
    ConsultationFooterComponent,
    PatientComponent,
    CliniciansContactSupportComponent,
    CliniciansImageModalComponent,
    MessengerChatComponent,
    IdChecksComponent,
    ConfirmAlertsComponent,
    KeyInformationComponent,
    AllergiesComponent,
    AcuteMedicationsComponent,
    RepeatMedicationsComponent,
    NotesDiagnosisCaptureComponent,
    PatientSummaryComponent,
    PatientEncountersNhsComponent,
    PatientClinicalComponent,
    PatientReceivedImagesComponent,
    PatientAllergiesComponent,
    PatientProblemsComponent,
    PatientReferralsComponent,
    PatientObservationsComponent,
    PatientImmunisationsComponent,
    PatientAdministrationComponent,
    PatientIdComponent,
    NotesViewerComponent,
    NotesListComponent,
    NotesTextViewComponent,
    NotesPdfViewComponent,
    NotesPdfViewModalComponent,
    NotesCaptureComponent,
    SafeguardingInfoComponent,
    SafeguardingStepsComponent,
    EmergencyPatientComponent,
    PatientRecordsTableComponent,
    GpResourcesCardComponent,
    GetAllergyOnsetDateTimePipe,
    AllergyDetailsComponent,
    AllergiesListComponent,
    PatientStructuredMedicationComponent,
    MedicationGroupComponent,
    MedicationListItemComponent,
    MedicationIssuesComponent,
    MedicationIssuesItemComponent,
    CliniciansPatientEmailCommunicationComponent,
    ConsultationReferralComponent,
    MedicationNotesComponent,
    PatientWarningComponent,
    ConsultationFitNoteComponent,
    PatientMedicationsInformationComponent,
    CloseEditConsultationModalComponent,
    ChildIdModalComponent,
    ChildIdCheckComponent,
    ChildIdCheckNhsComponent,
    ConsultationDynamicGroupComponent,
    ConsultationCardDynamicComponent,
    ConsultationIncompleteActionsModalComponent,
    ConsultationDialTranslatorComponent,
    PatientIsDeadModalComponent,
    SendATaskComponent,
    ConsultationPopoverComponent,
    FormatNHSNumberPipe,
    ConsultationCompleteComponent,
    ClinicalOutcomeFilterPipe,
    ArrayGroupByPipe,
    AutoSaveStatusComponent,
    VideoChatComponent,
    EndVideoSessionModalComponent,
  ],
})
export class CliniciansPatientModule {}
