import { Pipe, PipeTransform, TrackByFunction } from '@angular/core';

interface TrackByFunctionCache {
  [propertyName: string]: <T>(index: number, item: T) => any;
}
const trackByFunctionCache: TrackByFunctionCache = Object.create(null);

@Pipe({
  name: 'trackByProperty',
  pure: true,
})
export class TrackByPropertyPipe implements PipeTransform {
  transform<T>(property: string): TrackByFunction<T> {
    if (!trackByFunctionCache[property]) {
      trackByFunctionCache[property] = (index: number, item): T => item[property];
    }
    return trackByFunctionCache[property];
  }
}
