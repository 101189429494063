<pushdr-consultation-card
  [hasData]="
    currentStage !== Stage.SENDING && (!!fitNoteExists ? currentStage !== Stage.LOADING : true)
  "
  [showFooter]="true"
  [messageOveride]="currentStage">
  <span header>
    <span class="flex justify-between">
      <span>Write fit note</span>
      <div class="flex" [ngSwitch]="true">
        <span>{{ todaysDate | date: 'dd MMM yyyy' }}</span>
        <div class="flex flex-col justify-center ml-2 text-xs italic text-grey-700">
          <span class *ngSwitchCase="updating && !errorUpdating"> Saving... </span>
          <span class *ngSwitchCase="!updating && !errorUpdating"> Saved </span>
          <span class *ngSwitchDefault class="text-error">
            Error saving
            <button class="underline" (click)="manualSave()">Try again</button>
          </span>
        </div>
      </div>
    </span>
  </span>

  <ng-container body [ngSwitch]="currentStage">
    <span *ngSwitchCase="Stage.SENT">
      <ng-container *ngTemplateOutlet="sent"></ng-container>
    </span>
    <span *ngSwitchDefault class="flex flex-col h-full">
      <ng-container *ngTemplateOutlet="ready"></ng-container>
    </span>
  </ng-container>
  <ng-container footer>
    <div class="flex justify-between w-full p-2 px-8">
      <button
        data-cy="back-btn"
        class="w-2/6 btn-outline btn btn-tiny"
        (click)="onClickBackToConsultation()">
        Back
      </button>
      <button
        data-cy="send-btn"
        *ngIf="currentStage === Stage.READY"
        [disabled]="!fitNoteExists"
        class="w-2/6 btn btn-primary btn-tiny"
        [class.opacity-75]="!fitNoteForm.valid"
        (click)="sendFitNote()">
        Send
      </button>
    </div>
  </ng-container>
</pushdr-consultation-card>

<ng-template #sent>
  <div class="mt-4 text-center">
    <span class="p-1 mb-2 rounded bg-secondary material-icons">done</span>
    <p class="mb-4 text-base font-bold">Sent successfully</p>
  </div>
</ng-template>

<ng-template #ready>
  <span *ngIf="patientDetails$ | async as patientDetails" class="text-bluegrey-800">
    <div class="flex justify-between pb-2 mb-4 font-light border-b border-grey-600 text-grey-700">
      <span>
        To:
        <span data-cy="email-to" class="ml-1 font-medium">
          {{ patientDetails.Customer.Email }}
        </span>
      </span>
      <span
        class="text-bluegrey-600"
        tooltip-bottom="If this email is incorrect, please let the patient know to get in contact with customer services to get it updated.">
        Incorrect email?
      </span>
    </div>

    <div *ngIf="!fitNoteExists" class="flex">
      <p class="m-0">Is the email address "{{ patientDetails.Customer.Email }}" correct?</p>
      <label class="ml-2">
        <input
          type="radio"
          (ngModelChange)="createFitNote($event)"
          name="emailCorrect"
          [value]="true"
          [(ngModel)]="emailCorrect"
          data-cy="email-correct-yes" />
        Yes
      </label>
      <label class="ml-2">
        <input
          type="radio"
          (ngModelChange)="createFitNote($event)"
          name="emailCorrect"
          [value]="false"
          [(ngModel)]="emailCorrect" />
        No
      </label>
    </div>
    <div *ngIf="emailCorrect === false && !fitNoteExists">
      <p class="text-error">
        Please do not issue the fit note if the patients email address is incorrect and contact
        customer service
      </p>
    </div>

    <ng-container *ngIf="fitNoteExists">
      <div *ngIf="errorMessage" class="mt-5 text-error">
        {{ errorMessage }}
      </div>
      <div class="mt-5 mb-4">
        In the case of
        <span class="ml-2 text-indigo-700">{{ patientDetails.Patient.Name }}</span>
      </div>
    </ng-container>
  </span>
  <div class="flex-grow" *ngIf="fitNoteExists">
    <form class="flex flex-col h-full" [formGroup]="fitNoteForm">
      <div class="mb-8">
        <label class="block mb-2 text-sm text-gray-700" for="packSize"> Upon assessment... </label>
        <textarea
          #assessment
          type="text"
          name="assessment"
          maxlength="190"
          formControlName="Condition"
          class="self-stretch flex-grow m-0 pdr-input"
          data-cy="assessment"></textarea>
        <div class="flex justify-between mt-1">
          <span>
            <span
              *ngIf="submitted && fitNoteForm.get('Condition').hasError('required')"
              class="text-error">
              Assessment required
            </span>
          </span>
          <span class="text-sm text-bluegrey-600">{{ assessment.value?.trim().length }}/190</span>
        </div>
      </div>

      <div class="mb-8">
        <label class="block mb-2 text-sm text-gray-700" for="FitForWork">
          As such I advise this
        </label>
        <ng-select
          formControlName="FitForWork"
          name="FitForWork"
          placeholder="Select an option"
          aria-live="polite"
          [clearable]="false">
          <ng-option *ngFor="let option of fitForWorkOptions; let i = index" [value]="option.value">
            {{ option.description }}
          </ng-option>
        </ng-select>
        <div *ngIf="submitted && fitNoteForm.get('FitForWork').value === null" class="text-error">
          You must select an option
        </div>
      </div>

      <div *ngIf="fitNoteForm.get('FitForWork').value === true" class="flex mb-8">
        <label class="block text-sm text-gray-700" for="advice"> They may benefit from </label>
        <span class="flex flex-col mt-1 ml-4">
          <label>
            <input
              type="checkbox"
              formControlName="PhasedReturn"
              [value]="true"
              class="mb-4 mr-3" />
            <span>a phased return to work</span>
          </label>
          <label>
            <input
              type="checkbox"
              formControlName="AlteredHours"
              [value]="true"
              class="mb-4 mr-3" />
            <span>altered hours</span>
          </label>
          <label>
            <input
              type="checkbox"
              formControlName="AmendedDuties"
              [value]="true"
              class="mb-4 mr-3" />
            <span>amended duties</span>
          </label>
          <label>
            <input
              type="checkbox"
              formControlName="WorkplaceAdaptation"
              [value]="true"
              class="mb-4 mr-3" />
            <span>workplace adaptations</span>
          </label>
        </span>
      </div>

      <div class="mb-8">
        <div class="p-2 mb-1">
          <label class="flex my-3">
            <span class>
              This will be the case for
              <input
                #confirmed
                type="number"
                name="emailConfirmed"
                formControlName="TimeOffDays"
                class="w-10 p-2 mx-3 text-center border rounded"
                data-cy="consent-chck" />
              days
            </span>
          </label>
          <div
            *ngIf="submitted && fitNoteForm.get('TimeOffDays').hasError('required')"
            class="text-error">
            Duration is required
          </div>
          <div
            *ngIf="
              submitted &&
              (fitNoteForm.get('TimeOffDays').hasError('min') ||
                fitNoteForm.get('TimeOffDays').hasError('max'))
            "
            class="text-error">
            Duration must be between 1 and 1000 days
          </div>
          <label>
            I will not need to assess their fitness for work again at the end of this period
          </label>
        </div>
      </div>

      <div class="mb-8">
        <label class="block mb-2 text-sm text-gray-700" for="packSize">
          Comments including functional effects of their condition
        </label>
        <textarea
          #comments
          type="text"
          name="comments"
          maxlength="190"
          formControlName="Comments"
          class="self-stretch flex-grow m-0 pdr-input"
          data-cy="comments"></textarea>
        <div class="flex justify-between mt-1">
          <span>
            <span
              *ngIf="submitted && fitNoteForm.get('Comments').hasError('required')"
              class="text-error">
              Comments required
            </span>
          </span>
          <span class="text-sm text-bluegrey-600">{{ comments.value?.trim().length }}/190</span>
        </div>
      </div>

      <div class="mb-8" *ngIf="doctorDetails$ | async as doctorDetails">
        <div>Kinds regards,</div>
        <div class="p-2 mb-1">
          <label class="flex">
            <div class="flex flex-col justify-center">Signed:</div>
            <input
              #signed
              type="checkbox"
              [value]="true"
              name="signed"
              formControlName="signed"
              [class.signed]="fitNoteForm.get('signed').value === true"
              class="w-64 mt-1 ml-1 mr-3 font-bold text-center border rounded appearance-none cursor-pointer click-to-sign pdr-input" />
            <div class="flex flex-col justify-between">
              <span>{{ doctorDetails.name }}</span>
              <span>{{ todaysDate | date: 'dd MMM yyyy' }}</span>
            </div>
          </label>
        </div>
        <div *ngIf="submitted && fitNoteForm.get('signed').hasError('required')" class="text-error">
          You must sign before sending.
        </div>
      </div>

      <div class="mb-8">
        <div class="p-2 mb-1 border rounded">
          <label class="flex">
            <input
              #confirmed
              type="checkbox"
              [value]="true"
              name="emailConfirmed"
              formControlName="emailConfirmed"
              class="mt-1 ml-1 mr-3"
              data-cy="consent-chck" />
            I confirm I have got consent from the patient and confirmed their preferred email
            address
          </label>
        </div>
        <div
          *ngIf="submitted && fitNoteForm.get('emailConfirmed').hasError('required')"
          class="text-error">
          You must obtain consent before sending.
        </div>
      </div>
    </form>
  </div>
</ng-template>
