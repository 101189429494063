<div class="p-3 border-b border-grey-100">
  <div class="flex mb-3">
    <div
      class="w-1/4"
      *ngIf="medicationType === 'repeat' || medicationType === 'repeat-dispensing'">
      <p class="font-light text-grey-700 mb-0 text-base">Issue</p>
      <p class="mb-0 text-base">
        <ng-content select="[issue]"></ng-content>
      </p>
    </div>

    <div
      class="w-1/4"
      *ngIf="medicationType === 'repeat' || medicationType === 'repeat-dispensing'">
      <p class="font-light text-grey-700 mb-0 text-base">Valid from</p>
      <p class="mb-0 text-base">
        {{ issue.dispenseRequest.validityPeriod.start | isoDate: 'DD MMM YYYY':'-' }}
      </p>
    </div>

    <div
      class="w-1/4"
      *ngIf="medicationType === 'repeat' || medicationType === 'repeat-dispensing'">
      <p class="font-light text-grey-700 mb-0 text-base">Valid to</p>
      <p class="mb-0 text-base">
        {{ issue.dispenseRequest.validityPeriod.end | isoDate: 'DD MMM YYYY':'-' }}
      </p>
    </div>

    <div
      class="w-1/4"
      *ngIf="medicationType === 'repeat' || medicationType === 'repeat-dispensing'">
      <p class="font-light text-grey-700 mb-0 text-base">Authored On</p>
      <p class="mb-0 text-base">{{ issue.authoredOn | isoDate: 'DD MMM YYYY':'-' }}</p>
    </div>
  </div>

  <div class="flex mb-3">
    <div
      class="w-1/2"
      *ngIf="medicationType === 'repeat' || medicationType === 'repeat-dispensing'">
      <p class="font-light text-grey-700 mb-0 text-base">Quanity / instruction</p>
      <p class="mb-0 text-base">
        {{ issue.dispenseRequest?.quantity.value }}
        {{
          (
            issue.dispenseRequest?.quantity?.extension
            | findArrayItem: 'url':'MedicationQuantityText'
          )?.valueString
        }}
        {{ ' - ' + issue.dosageInstruction[0]?.text }}
      </p>
    </div>

    <div class="w-1/2">
      <p class="font-light text-grey-700 mb-0 text-base">Patient instruction</p>
      <p class="mb-0 text-base">{{ issue.dispenseRequest?.patientInstruction || 'N/a' }}</p>
    </div>
  </div>

  <div class="flex">
    <div
      class="w-1/2"
      *ngIf="medicationType === 'repeat' || medicationType === 'repeat-dispensing'">
      <p class="font-light text-grey-700 mb-0 text-base">Expected supply duration</p>
      <p class="mb-0 text-base">
        {{ issue.dispenseRequest?.expectedSupplyDuration.value }}
        {{ issue.dispenseRequest?.expectedSupplyDuration.unit + '(s)' }}
      </p>
    </div>
  </div>
</div>
