import { Component, Input } from '@angular/core';

@Component({
  selector: 'pushdr-toggle-header',
  templateUrl: './toggle-header.component.html',
  styleUrls: ['./toggle-header.component.scss'],
})
export class ToggleHeaderComponent {
  @Input() isAlwaysOpen: boolean;
  @Input() icon: string;
  @Input() label: string;
  @Input() color?: string;
  @Input() bg: string;

  constructor() {}
}
