<div [@showContent] *ngIf="!isLoading; else loading">
  <h1 class="text-xl font-normal mb-2 to-bluegrey-800">Forgot Your Password?</h1>
  <p class="to-bluegrey-800">Please enter your email address.</p>
  <form [formGroup]="resetPasswordForm" (ngSubmit)="submit()">
    <div
      class="mb-9"
      [class.pdr-input--error]="
        resetPasswordForm.get('email').errors && resetPasswordForm.get('email').touched
      ">
      <label class="to-bluegrey-800 required">Email address</label>
      <input class="pdr-input" type="email" name="email" formControlName="email" />

      <p [ngSwitch]="true" class="mb-2 mt-2 text-sm text-error">
        <ng-template
          [ngSwitchCase]="
            resetPasswordForm.get('email').hasError('invalidAccount') ||
            (resetPasswordForm.get('email').hasError('email') &&
              resetPasswordForm.get('email').touched)
          ">
          Please check your email address.
        </ng-template>
        <ng-template
          [ngSwitchCase]="
            resetPasswordForm.get('email').hasError('required') &&
            resetPasswordForm.get('email').touched
          ">
          Please enter your email address.
        </ng-template>
      </p>
    </div>
    <div class="text-center mb-1">
      <button
        class="w-full btn btn-primary tracking-widest"
        type="submit"
        [disabled]="resetPasswordForm.invalid">
        Submit
      </button>
    </div>
    <p class="text-center mt-6"><a [routerLink]="['/auth/login']">Back</a></p>
  </form>
</div>

<ng-template #loading>
  <div class="flex justify-center block w-full h-48">
    <pushdr-spinner-loader></pushdr-spinner-loader>
  </div>
</ng-template>
