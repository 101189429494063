import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CurrentPartnerIdService {
  private partnerIdSubject = new BehaviorSubject(null);

  get idChanges(): Observable<string> {
    return this.partnerIdSubject.asObservable();
  }

  get id(): string {
    return this.partnerIdSubject.value;
  }

  set id(id: string) {
    this.partnerIdSubject.next(id);
  }
}
