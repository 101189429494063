import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getAllergyOnsetDateTime',
})
export class GetAllergyOnsetDateTimePipe implements PipeTransform {
  transform(allergy: any): any {
    return allergy.onsetDateTime || (allergy.reaction ? allergy.reaction[0].onset : '');
  }
}
