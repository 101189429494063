import { Injectable } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { AnalyticsService, AnalyticsUser } from '@pushdr/common/data-access/analytics';
import { PartnerType } from '@pushdr/common/types';
import { retryCache } from '@pushdr/common/utils';
import {
  ApiDoctorsAccount,
  ApiDoctorsConsultation,
  DoctorDetails,
} from '@pushdr/doctors/data-access/doctors-api';
import { retryBackoff } from 'backoff-rxjs';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  map,
  publishReplay,
  refCount,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { defaultProfileImage } from '../../assets/default-clinician-profile-img';

@Injectable({
  providedIn: 'root',
})
export class CliniciansDoctorsDetailsService {
  private _details$: Observable<DoctorDetails>;
  private _partnerConfiguration$: Observable<any>;

  private _refreshProfileImage$ = new Subject<boolean>();
  private _profileImage$: Observable<SafeStyle>;

  defaultImage = defaultProfileImage;
  defaultAboutMeText = 'Hi, welcome to Push Doctor.';

  constructor(
    private api: ApiDoctorsAccount,
    private consultationApi: ApiDoctorsConsultation,
    private sanitizer: DomSanitizer,
    private analytics: AnalyticsService
  ) {}

  get profileImage$() {
    return this._refreshProfileImage$.asObservable().pipe(
      startWith(true),
      switchMap(() => this.getCachedProfileImage$())
    );
  }

  refreshProfileImage() {
    this._profileImage$ = null;
    this._refreshProfileImage$.next(true);
  }

  lastConsultationDetails$() {
    return this.consultationApi.getConsultationDetails().pipe(
      map(details => ({
        type: details.Partner !== null ? details.Partner.PartnerType : PartnerType.PDR,
        isEditable: details.IsEditable && details.SurgeryLineId !== 0,
      })), // TODO: Currently nothing is returned for private.  This is a bit brittle though defaulting to PRD.
      retryCache()
    );
  }

  uploadProfileImage$(base64Image: string): Observable<boolean> {
    return this.api.uploadProfileImage(base64Image.split(',').pop()).pipe(
      map(res => res),
      tap(() => this.refreshProfileImage())
    );
  }

  private getCachedProfileImage$() {
    if (!this._profileImage$) {
      this._profileImage$ = this.api.getProfileImage().pipe(
        retryBackoff({
          initialInterval: 10000,
          maxRetries: 5,
          shouldRetry: () => true,
        }),
        catchError(() => of(this.defaultImage)),
        map(profileImage =>
          profileImage
            ? this.sanitizer.bypassSecurityTrustStyle(
                `url(${'data:image/png;base64,' + profileImage})`
              )
            : ''
        ),
        publishReplay(1),
        refCount()
      );
    }
    return this._profileImage$;
  }

  private groupBy = (xs, key) => {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  get details$(): Observable<DoctorDetails> {
    if (!this._details$) {
      this._details$ = this.api.getDoctorDetails().pipe(
        map(res => DoctorDetails.fromApi(res)), // TODO push this down into api service when we get rid of element
        tap(res => this.analytics.setUser(new AnalyticsUser(res.GMC))), // TODO push this down into api service when we get rid of element
        retryBackoff({
          initialInterval: 10000,
          maxRetries: 5,
          shouldRetry: () => true,
        }),
        catchError(() => of(undefined)),
        publishReplay(1),
        refCount()
      );
    }
    return this._details$;
  }

  partnerConfig$(): Observable<any[]> {
    if (!this._partnerConfiguration$) {
      this._partnerConfiguration$ = this.api.getPartnerConfiguration().pipe(retryCache());
    }
    return this._partnerConfiguration$;
  }

  partnerConfigByTypes$() {
    return this.partnerConfig$().pipe(map(x => this.groupBy(x, 'partnerType')));
  }

  partnerTypes$(): Observable<number[]> {
    return this.partnerConfig$().pipe(
      map(x =>
        x
          .map(u => u.partnerType)
          .reduce((un, u) => {
            if (un.indexOf(u) < 0) un.push(u);
            return un;
          }, [])
      )
    );
  }

  updateAboutMe$(aboutText: string): Observable<boolean> {
    return this.api.updateAboutMe(aboutText).pipe(map(res => res));
  }

  clearDetailsCache() {
    this._details$ = null;
  }

  // eslint-disable-next-line
  check(): Promise<[boolean | string, any]> {
    const detailsDefaulter$ = this.details$.pipe(
      switchMap(details =>
        details && details.aboutYou
          ? of(details.aboutYou)
          : this.updateAboutMe$(this.defaultAboutMeText)
      )
    );
    const profileImageDefaulter$ = this.profileImage$.pipe(
      switchMap(profileImage =>
        profileImage ? of(profileImage) : this.uploadProfileImage$(this.defaultImage)
      )
    );

    return forkJoin([detailsDefaulter$, profileImageDefaulter$]).toPromise();
  }
}
