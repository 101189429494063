import {
  Component,
  AfterViewInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  Renderer2,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';

@Component({
  selector: 'pushdr-tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabGroupComponent implements AfterViewInit {
  @Input() noCheck = false;
  @Output() activateTab = new EventEmitter<string>();
  @ViewChild('tabsContainer', { static: false }) tabContainerElement: ElementRef;
  tabsArray = [];
  activeTabId: string;

  constructor(private renderer: Renderer2, private cdRef: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.setTabsArray();
    this.setActiveTab();
    this.cdRef.detectChanges();
  }

  setTabsArray() {
    this.tabsArray = Array.from(this.tabContainerElement.nativeElement.children).map(
      (template: HTMLElement, index: number) => ({
        id:
          template.getAttribute('pdTabId') ||
          template.getAttribute('ng-reflect-pd-tab-id') ||
          index.toString(),
        header:
          template.getAttribute('pdTabView') || template.getAttribute('ng-reflect-pd-tab-view'),
        template,
        active: false,
      })
    );
  }

  setActiveTab() {
    const activeTab =
      this.tabsArray.find(tab => tab.template.getAttribute('active') !== null) || this.tabsArray[0];
    this.selectTab(activeTab);
  }

  selectTab(selectedTab) {
    if (selectedTab.id !== this.activeTabId) {
      this.tabsArray.forEach(tab => {
        this.renderer.removeAttribute(tab.template, 'active');
        tab.active = false;
      });
      this.renderer.setAttribute(selectedTab.template, 'active', '');
      this.renderer.setAttribute(selectedTab.template, 'checked', '');
      selectedTab.active = true;
      selectedTab.checked = true;
      this.activeTabId = selectedTab.id;
      this.activateTab.emit(selectedTab.id);
    }
  }
}
