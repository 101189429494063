import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConsultationState, ConsultationStateService } from '@pushdr/clinicians/common';
import {
  CustomerType,
  Gender,
  PartnerType,
  PatientDetailsV2,
  SurgeryType,
} from '@pushdr/common/types';
import { CanComponentDeactivate } from '@pushdr/common/utils';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PatientDetailsService } from '../../services/patient-details/patient-details.service';

@Component({
  selector: 'pushdr-meet-the-patient',
  templateUrl: './meet-the-patient.component.html',
  styleUrls: ['./meet-the-patient.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeetThePatientComponent implements OnInit, CanComponentDeactivate {
  details$: Observable<PatientDetailsV2>;

  isEmis = false;

  Gender = Gender;
  PartnerType = PartnerType;
  CustomerType = CustomerType;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private patientDetails: PatientDetailsService,
    private consultationState: ConsultationStateService
  ) {}

  ngOnInit() {
    this.details$ = this.patientDetails.details$.pipe(
      tap(
        details => (this.isEmis = details?.Patient?.GpSummary?.SurgeryTypeId === SurgeryType.EMIS)
      )
    );
    this.consultationState.state = ConsultationState.PREVIEW_PATIENT;
  }

  canDeactivate() {
    return this.consultationState.state === ConsultationState.MEET_PATIENT;
  }

  goAndMeetPatientInConsultation() {
    this.consultationState.state = ConsultationState.MEET_PATIENT;
    this.router.navigate(['../patient'], { relativeTo: this.route });
  }
}
