import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pushdr-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordInputComponent),
      multi: true,
    },
  ],
})
export class PasswordInputComponent implements OnInit, OnDestroy, ControlValueAccessor {
  passwordControl = new UntypedFormControl('');
  showPassword = false;

  private onChange: (value: string) => void = null;

  @HostListener('focusout')
  private onTouched: () => void = null;

  private valueChanges: Subscription;

  ngOnInit(): void {
    this.valueChanges = this.passwordControl.valueChanges.subscribe({
      next: value => this.onChange?.(value),
    });
  }

  ngOnDestroy(): void {
    this.valueChanges?.unsubscribe();
    this.valueChanges = null;
  }

  writeValue(value: string): void {
    this.passwordControl.setValue(value);
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
