export interface CDSWarning {
  text: string;
  severity: string;
  categories: Array<{ name: string; value: number }>;
  alertRelevanceType?: string;
  conditionAlertSeverity?: string;
  highlightWarning?: boolean;
}

export interface Referential {
  name: string;
  data: CDSWarning[];
  categories: string[];
}

export type AlertRelevanceType = 'UnRelated' | 'Related' | 'Specific';
export type ConditionAlertSeverityType = 'Contraindication' | 'Precaution';

export class ClinicalDecisionSupportWarnings {
  response: any;
  warnings: { [key: string]: CDSWarning[] };
  warningsArr;
  referentialInformation: { [key: string]: CDSWarning[] };
  referentialInformationArr: Referential[];
  patientChecks: Map<string, CDSWarning[]>;
  patientChecksArr: [string, CDSWarning[]][];
  drugInteractions: Map<string, CDSWarning[]>;
  drugInteractionsArr: [string, CDSWarning[]][];

  get length() {
    return this.warnings.length;
  }

  private initialised = false;

  private readonly MEDICINE_SAFETY_MESSAGE_SEVERITY = 'HIGH';
  private readonly DRUG_DOUBLINGS_SEVERITY = 'HIGH';
  private readonly DRUG_EQUIVALENCES_SEVERITY = 'HIGH';
  private readonly DRUG_INTERACTIONS_SEVERITY = 'CUSTOM';
  private readonly DRUG_SENSITIVITY_SEVERITY = 'HIGH';
  private readonly SCREENING_ERRORS_SEVERITY = 'CUSTOM';
  private readonly PATIENT_CHECKS_SEVERITY = 'CUSTOM';
  private readonly DUPLICATE_THERAPIES_SEVERITY = 'HIGH';
  private readonly ADDITIONAL_WARNINGS_SEVERITY = 'HIGH';
  private readonly ConditionAlertSeverityTypes: ConditionAlertSeverityType[] = [
    'Contraindication',
    'Precaution',
  ];
  private readonly AlertRelevanceTypes: AlertRelevanceType[] = ['UnRelated', 'Related', 'Specific'];

  static fromClinicialDecisionSupportResponse(response) {
    const warnings = new ClinicalDecisionSupportWarnings();

    warnings.initialiseWarnings(response);

    return warnings;
  }

  getWarningsBySeverity(severity: string) {
    return this.warningsArr.filter(warning => warning.severity === severity);
  }

  initialiseWarnings(response) {
    if (!this.initialised) {
      this.initialised = true;
      this.response = response;

      this.warnings = {
        medicineSafetyMessages: this.createMedicineSafetyMessagesWarning(
          response.medicineSafetyMessages || []
        ),
        drugDoublings: this.createDrugDoublingsWarnings(response.drugDoublings),
        drugEquivalences: this.createDrugEquivalencesWarnings(response.drugEquivalences),
        drugInteractions: this.createDrugInteractionsWarnings(response.drugInteractions),
        drugSensitivities: this.createDrugSensitivitiesWarnings(response.drugSensitivities),
        duplicateTherapies: this.createDuplicateTherapiesWarnings(response.duplicateTherapies),
        patientConditions: this.createPatientChecksWarnings(response.patientChecks),
        screeningErrors: this.createScreeningErrorsWarnings(response.screeningErrors),
      };
      this.referentialInformation = {
        medicationWarnings: this.createAdditionalWarnings(response.warnings),
      };
      this.referentialInformationArr = Object.keys(this.referentialInformation).reduce(
        (prev, key) => {
          if (this.referentialInformation[key].length > 0) {
            prev.push({
              name: this.convertCamelCaseToSentanceCase(key),
              data: this.referentialInformation[key],
              categories: this.getOrdereredCategories(this.referentialInformation[key]),
            });
          }
          return prev;
        },
        []
      );
      this.patientChecks = this.getPatientChecks(this.warnings.patientConditions);
      this.patientChecksArr = this.patientChecks && Array.from(this.patientChecks.entries());
      this.drugInteractions = this.getDrugInteractions(this.warnings.drugInteractions);
      this.drugInteractionsArr =
        this.drugInteractions && Array.from(this.drugInteractions.entries());
      this.warningsArr = Object.keys(this.warnings).reduce((prev, key) => {
        if (this.warnings[key].length > 0) {
          prev.push({ name: this.convertCamelCaseToSentanceCase(key), data: this.warnings[key] });
        }
        return prev;
      }, []);
    } else {
      throw Error('ClinicalDecisionSupportWarnings has already initialised');
    }
  }

  private getDrugInteractions(drugInteractions: CDSWarning[]) {
    return drugInteractions.reduce((interactions, currentInteraction) => {
      if (interactions.has(currentInteraction.severity)) {
        const currentData = interactions.get(currentInteraction.severity);
        interactions.set(currentInteraction.severity, [currentInteraction, ...currentData]);
      } else {
        interactions.set(currentInteraction.severity, [currentInteraction]);
      }
      return interactions;
    }, new Map<string, CDSWarning[]>());
  }

  private getPatientChecks(patientConditions: CDSWarning[]) {
    return patientConditions.reduce((conditions, currentWarning) => {
      const { conditionAlertSeverity, alertRelevanceType } = currentWarning.categories.reduce(
        (types, cat) => {
          if (
            this.ConditionAlertSeverityTypes.indexOf(cat.name as ConditionAlertSeverityType) > -1
          ) {
            types.conditionAlertSeverity = cat.name;
          } else if (this.AlertRelevanceTypes.indexOf(cat.name as AlertRelevanceType) > -1) {
            types.alertRelevanceType = cat.name;
          }
          return types;
        },
        { conditionAlertSeverity: 'Unknown', alertRelevanceType: 'Unknown' }
      );
      const name = `${conditionAlertSeverity} - ${alertRelevanceType}`;
      if (conditions.has(name)) {
        const currentData = conditions.get(name);
        conditions.set(name, [currentWarning, ...currentData]);
      } else {
        conditions.set(name, [currentWarning]);
      }
      return conditions;
    }, new Map<string, CDSWarning[]>());
  }

  private getOrdereredCategories(data: CDSWarning[]) {
    return Array.from(
      data.reduce((categorySet, currentData) => {
        currentData.categories.forEach(cat => {
          categorySet.add(cat.name);
        });
        return categorySet;
      }, new Set())
    ).sort();
  }

  private convertCamelCaseToSentanceCase(str) {
    const result = str.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  private createAdditionalWarnings(warnings: any[]) {
    return warnings.map(warning => ({
      text: warning.text,
      severity: this.ADDITIONAL_WARNINGS_SEVERITY,
      categories: warning.categories,
    }));
  }

  private createScreeningErrorsWarnings(screeningErrors: any[]) {
    return screeningErrors.map(screeningError => ({
      text: screeningError.description,
      severity: this.getSeverityFromWarning(screeningError.errorSeverity.value),
      categories: [{ name: 'Screening error', value: null }],
    }));
  }

  private createPatientChecksWarnings(patientChecks: any[]): CDSWarning[] {
    return patientChecks.map(patientCheck => ({
      text: patientCheck.fullAlertMessage,
      severity: this.getSeverityFromWarning(patientCheck.conditionAlertSeverity.value),
      highlightWarning: this.getHighlightState(
        patientCheck.alertRelevanceType.value,
        patientCheck.conditionAlertSeverity.value,
        patientCheck.fullAlertMessage
      ),
      categories: [
        { name: patientCheck.alertRelevanceType.name, value: null },
        { name: patientCheck.conditionAlertSeverity.name, value: null },
      ],
    }));
  }

  private createDuplicateTherapiesWarnings(duplicateTherapies: any[]) {
    return duplicateTherapies.map(duplicateTherapy => ({
      text: duplicateTherapy.fullAlertMessage,
      severity: this.DUPLICATE_THERAPIES_SEVERITY,
      categories: [{ name: 'Duplicate therapy', value: null }],
    }));
  }

  private createDrugSensitivitiesWarnings(drugSensitivities: any[]) {
    return drugSensitivities.map(drugSensitivity => ({
      text: drugSensitivity.fullAlertMessage,
      severity: this.DRUG_SENSITIVITY_SEVERITY,
      categories: [{ name: 'Drug sensitivity', value: null }],
    }));
  }

  private createDrugInteractionsWarnings(drugInteractions: any[]) {
    return drugInteractions.map(drugInteraction => ({
      text: drugInteraction.fullAlertMessage,
      severity: this.getSeverityFromWarning(drugInteraction.alertSeverity.value),
      categories: [{ name: 'Drug interactions', value: null }],
    }));
  }

  private getSeverityFromWarning(alertSeverityValue) {
    if (alertSeverityValue === 4) {
      return 'High';
    } else if (alertSeverityValue === 3) {
      return 'Significant';
    } else if (alertSeverityValue === 2) {
      return 'Moderate';
    } else if (alertSeverityValue === 1) {
      return 'Low';
    } else {
      return 'Unknown';
    }
  }

  private createDrugEquivalencesWarnings(drugEquivalences: any[]) {
    return drugEquivalences.map(drugEquivalence => ({
      text: drugEquivalence.fullAlertMessage,
      severity: this.DRUG_EQUIVALENCES_SEVERITY,
      categories: [{ name: 'Drug equivalences', value: null }],
    }));
  }

  private createDrugDoublingsWarnings(drugDoublings: any[]) {
    return drugDoublings.map(drugDoubling => ({
      text: drugDoubling.fullAlertMessage,
      severity: this.DRUG_DOUBLINGS_SEVERITY,
      categories: [{ name: 'Drug doubling', value: null }],
    }));
  }

  private createMedicineSafetyMessagesWarning(medicineSafetyMessages: any[]) {
    return medicineSafetyMessages.map(safetyMessage => ({
      text: safetyMessage.safetyMessage,
      severity: this.MEDICINE_SAFETY_MESSAGE_SEVERITY,
      categories: [{ name: 'Medicine safety message', value: null }],
    }));
  }

  /**
   * Returns a boolean indicating whether the warning should be 'highlighted' (bold).
   * This ideally would be returned from the API. Any further extensions to this should be moved to
   * */
  private getHighlightState(
    alertRelevanceType: number,
    conditionAlertSeverity: number,
    alertMessage: string
  ): boolean {
    if (
      alertRelevanceType === 3 &&
      conditionAlertSeverity === 1 &&
      alertMessage.includes(
        'has the contraindication: Patients not compliant with the Pregnancy Prevention Programme'
      )
    ) {
      return true;
    }

    return false;
  }
}
