import { Component, Input } from '@angular/core';
import { MedicationTypes } from '@pushdr/common/types';

@Component({
  selector: 'pushdr-medication-issues',
  templateUrl: './medication-issues.component.html',
  styleUrls: ['./medication-issues.component.scss'],
})
export class MedicationIssuesComponent {
  @Input() issuesAllowed: number;
  @Input() set issues(issues: any) {
    this._issues = issues.reduce((issuedMeds, issue) => {
      if (issue.intent !== 'plan') {
        issuedMeds.push(issue);
      }
      return issuedMeds;
    }, []);
  }
  get issues() {
    return this._issues;
  }
  @Input() medicationType: MedicationTypes;

  private _issues: any[];
}
