import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PresurgeryService } from '../../services/patientapp-pre-surgery.service';

@Component({
  selector: 'pushdr-presurgery',
  templateUrl: './pre-surgery.component.html',
  styleUrls: ['./pre-surgery.component.scss'],
})
export class PresurgeryComponent implements OnInit {
  stage$ = this.preSurgeryService.getStage$();
  hasError$ = this.preSurgeryService.hasError$();

  constructor(
    private preSurgeryService: PresurgeryService,
    private router: Router,
    private activedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.router.navigate(['./start'], { relativeTo: this.activedRoute });
  }
}
