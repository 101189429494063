import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { PatientRecordsService } from '../../services/patient-records/patient-records.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pushdr-repeat-medications',
  templateUrl: './repeat-medications.component.html',
  styleUrls: ['./repeat-medications.component.scss'],
})
export class RepeatMedicationsComponent implements OnInit {
  medications$: Observable<any[]>;

  constructor(private record: PatientRecordsService) {}

  ngOnInit() {
    this.medications$ = this.record
      .getStructuredPatientRecord()
      .pipe(
        map(record => record.displayedMedications.filter(medInfo => medInfo.type === 'repeat'))
      );
  }
}
