<div class="c-modal__wrap c-modal__wrap--end" [cdkTrapFocus]="ready$ | async">
  <h2>Incomplete actions detected</h2>

  <p>
    The following actions have been started but left incomplete, do you wish to complete them or end
    consultation?
  </p>

  <div class="w-56 mx-auto mb-8">
    <ng-container *ngIf="!data.notes?.success">
      <label class="text-bluegrey-600 block text-left mb-2">Mandatory</label>
      <div class="bg-grey-100 rounded-sm text-red-500 pt-3 pb-2">Consultation notes</div>
    </ng-container>

    <ng-container *ngIf="!data.prescription?.success">
      <label class="text-bluegrey-600 block text-left mb-2 mt-3">Optional</label>
      <div class="bg-grey-100 rounded-sm pt-3 pb-2">Prescription</div>
    </ng-container>
  </div>

  <button
    class="p-4 pl-6 pr-6 border border-solid rounded hover:bg-grey-100 md:mr-2"
    (click)="done(true)"
    [disabled]="!data.notes?.success"
    [ngClass]="{
      'bg-grey-100 border-grey-500 text-grey-500': !data.notes?.success,
      'border-primary text-primary bg-white': data.notes?.success
    }">
    End consultation
  </button>

  <button
    class="p-4 pl-6 pr-6 text-white rounded bg-primary hover:bg-primary-hover md:ml-2"
    (click)="done(false)">
    Complete actions
  </button>
</div>
