import { timer } from 'rxjs';
import { take, map } from 'rxjs/operators';

export interface GPResource {
  icon: string;
  title: string;
  label: string;
  link: string;
}

export const resources: GPResource[] = [
  {
    icon: 'book',
    title: 'Patient guidance',
    label: 'https://www.patient.info',
    link: 'https://www.patient.info',
  },
  {
    icon: 'book',
    title: 'British National Formulary',
    label: 'https://bnf.nice.org.uk',
    link: 'https://bnf.nice.org.uk',
  },
  {
    icon: 'book',
    title: 'MedCalc statistical tools',
    label: 'https://www.medcalc.org/calc',
    link: 'https://www.medcalc.org/calc',
  },
  {
    icon: 'picture_as_pdf',
    title: 'Summary of antimicrobial prescribing guidance - NICE',
    label:
      'https://www.nice.org.uk/Media/Default/About/what-we-do/NICE-guidance/antimicrobial%20guidance/summary-antimicrobial-prescribing-guidance.pdf',
    link: 'https://www.nice.org.uk/Media/Default/About/what-we-do/NICE-guidance/antimicrobial%20guidance/summary-antimicrobial-prescribing-guidance.pdf',
  },
  {
    icon: 'book',
    title: 'Suspected cancer: recognition and referral - NICE',
    label: 'https://www.nice.org.uk/guidance/ng12',
    link: 'https://www.nice.org.uk/guidance/ng12',
  },
  {
    icon: 'book',
    title: 'Clinical knowledge summaries',
    label: 'https://cks.nice.org.uk',
    link: 'https://cks.nice.org.uk',
  },
  {
    icon: 'book',
    title: 'Exercises to manage joint pain',
    label: 'https://www.versusarthritis.org',
    link: 'https://www.versusarthritis.org/about-arthritis/managing-symptoms/exercise/exercises-to-manage-pain/',
  },
  {
    icon: 'book',
    title: "Covid-19: Guidance for GP's",
    label: 'https://www.gov.uk/government/collections/wuhan-novel-coronavirus',
    link: 'https://www.gov.uk/government/collections/wuhan-novel-coronavirus',
  },
  {
    icon: 'book',
    title: 'Find local psychological therapy services',
    label: 'https://www.nhs.uk/service-search/find-a-psychological-therapies-service/ ',
    link: 'https://www.nhs.uk/service-search/find-a-psychological-therapies-service/ ',
  },
  {
    icon: 'book',
    title: 'Find local walk-in services',
    label: 'https://www.nhs.uk/service-search/other-services/Walk-in%20centre/LocationSearch/663',
    link: 'https://www.nhs.uk/service-search/other-services/Walk-in%20centre/LocationSearch/663',
  },
];

export const resources$ = timer(200).pipe(
  take(1),
  map(() => resources)
);
