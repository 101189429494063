import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { PrescriptionsManagementService } from '../../services/prescriptions-management.service';
import { Consultation } from '../../types/Consultation';
import { ModalService } from '@pushdr/common/overlay';
import { CancelPrescriptionModalComponent } from './components/cancel-prescription-modal/cancel-prescription-modal.component';
import { PrescriptionCancelResult } from '../../types/Prescription';

@Component({
  selector: 'pushdr-clinicians-consultation-details',
  templateUrl: './clinicians-consultation-details.component.html',
  styleUrls: ['./clinicians-consultation-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CliniciansConsultationDetailsComponent implements OnInit {
  private _isLoading$ = new BehaviorSubject<boolean>(true);
  isLoading$ = this._isLoading$.asObservable();
  private _hasError$ = new BehaviorSubject<boolean>(false);
  hasError$ = this._hasError$.asObservable();
  private _consultation$ = new BehaviorSubject<Consultation | null>(null);
  consultation$ = this._consultation$.asObservable();

  selectedItems: Set<string> = new Set();

  get canCancel$() {
    return this.prescriptionService.canCancel$();
  }
  get canView$() {
    return this.prescriptionService.canView$();
  }

  constructor(
    private route: ActivatedRoute,
    readonly router: Router,
    readonly prescriptionService: PrescriptionsManagementService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    this.selectedItems.clear();
    this.prescriptionService
      .searchByConsultationId(this.route.snapshot.paramMap.get('id'))
      .subscribe(res => {
        this._isLoading$.next(res.isLoading);
        this._hasError$.next(res.hasError);
        if (!res.hasError) {
          if (res.consultations.length > 0) {
            this._consultation$.next(res.consultations[0]);
          } else {
            // TODO show error? redirect?
          }
        }
      });
  }

  onClickBackToConsultationsList() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  onClickCancelSelected() {
    this.modalService
      .showCustom(CancelPrescriptionModalComponent, { items: this.selectedItems })
      .subscribe((result: PrescriptionCancelResult) => {
        if (result !== PrescriptionCancelResult.Aborted) {
          this.refresh();
        }
      });
  }
}
