import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  RestClient,
  RestErrorParserServiceBypass,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';
import { EnvironmentProxyService } from '@pushdr/environment';
import { Observable } from 'rxjs';
import { Rbac } from '.';

@Injectable({
  providedIn: 'root',
})
export class ApiRbacService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserServiceBypass,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.doctors.rbac + '/';
    // return 'http://localhost:7071/api/';
  }

  // Later this will take a token and not the SAML
  processSaml$(saml: string, ticket: string): Observable<Rbac> {
    // When this is a token it can be a get.
    return this.post('processsaml', { saml, ticket }, this.headerService.authorisedHeadersLegacy);
  }
}
