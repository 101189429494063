import { Injectable } from '@angular/core';
import { ModalService } from '@pushdr/common/overlay';
import { ApiDoctorsAccount, DoctorsBulletin } from '@pushdr/doctors/data-access/doctors-api';
import { merge, Observable, Subject } from 'rxjs';
import { mergeMap, publishReplay, refCount, take, tap } from 'rxjs/operators';
import { CliniciansBulletinsViewerModalComponent } from './bulletins-viewer-modal/bulletins-viewer-modal.component';

export const DEFAULT_BULLETIN_TTL = 15 * 60 * 1000; //15 mins

@Injectable({ providedIn: 'root' })
export class CliniciansBulletinsService {
  bulletins$: Observable<DoctorsBulletin[]>;
  unreadBulletins$: Observable<DoctorsBulletin[]>;

  private reloadBulletins$ = new Subject<void>();

  constructor(private api: ApiDoctorsAccount, private modal: ModalService) {}

  check(ttl: number = DEFAULT_BULLETIN_TTL): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getUnreadBulletins(ttl).subscribe({
        next: results => {
          if (results.length) {
            this.modal.showCustom(CliniciansBulletinsViewerModalComponent).subscribe(() => {
              resolve();
            });
          } else {
            console.log('no unread bulletins');
            resolve();
          }
        },
      });
    });
  }

  getUnreadBulletins(ttl: number = DEFAULT_BULLETIN_TTL) {
    if (!this.unreadBulletins$) {
      this.unreadBulletins$ = this.api
        .getBulletins()
        .pipe(publishReplay(1, ttl), refCount(), take(1));
    }
    return this.unreadBulletins$;
  }

  getReadBulletins() {
    if (!this.bulletins$) {
      const cachedRead$ = this.api.getReadBulletins().pipe(publishReplay(1), refCount());
      const reloadedRead$ = this.reloadBulletins$.pipe(
        mergeMap(() => this.api.getReadBulletins()),
        tap(() => this.flushReadMemCache())
      );

      this.bulletins$ = merge(reloadedRead$, cachedRead$);
    }
    return this.bulletins$;
  }

  markBulletinAsRead(id: number) {
    return this.api.confirmBulletins(id).pipe(
      tap(() => {
        this.reloadBulletins$.next();
      })
    );
  }

  flushUnreadMemCache() {
    console.log('flushed unread mem cache');
    this.unreadBulletins$ = null;
  }

  flushReadMemCache() {
    console.log('flushed read mem cache');
    this.bulletins$ = null;
  }
}
