<div [@showContent]>
  <h1 class="text-xl font-normal mb-2 to-bluegrey-800">Need to contact us?</h1>
  <p>
    If you are having issues logging in, have you tried resetting your password? Simply click
    <a [routerLink]="['/auth/forgot-password']">Forgotten Password</a>.
  </p>
  <p>For continued issues please contact our Doctor Support team on this number:</p>
  <p><a class="font-light" href="tel:03308084702">0330 808 4702</a></p>
  <p><a [routerLink]="['/auth/login']">Back to login page</a></p>
</div>
