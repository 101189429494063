<section class="pre-surgery-container c-card">
  <div class="pre-surgery-progress-container-content">
    <div class="pre-surgery-progress-container-inner">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="pre-surgery-progress-container-stage u-bg-light">
    <div class="pre-surgery-progress-container-inner">
      <pushdr-stages-status [progress]="stage$ | async" [hasError]="hasError$ | async">
      </pushdr-stages-status>
    </div>
  </div>
</section>
