import { PASSWORD_LENGTH_MIN } from '@pushdr/assets';

export type PasswordConditionMessages =
  | `Should be between 8 and 128 characters long`
  | `Should be between 10 and 128 characters long`
  | 'Should have at least 1 uppercase letter'
  | 'Should have at least 1 lowercase letter'
  | 'Should have at least 1 number'
  | 'Should have at least 1 special character (! @ # $ % ^ & * ( ) - _ = + \\ | [ ] { } ; : / ? .)'
  | `Cannot contain '<' or '>'`;

type PasswordConditionMessagesMap = {
  [key in PasswordConditionMessages]: MappedPasswordConditions;
};

const lengthRegex = (length: string) => new RegExp(`.{${length},}`);

class PasswordConditionsMap {
  static readonly LENGTH = new PasswordConditionsMap({
    innerHTML: `<span class="password-help-highlight">8 characters</span> minimum
    <span class="check-icon"><i class="material-icons">check_circle</i></span>`,
    conditionToMatch: lengthRegex('8'),
    errorConditionToMatch: null,
    displayPosition: 1,
    dataCy: 'password-tip-length',
    matched: false,
    errorMatched: false,
    isErrorCondition: false,
  });
  static readonly LENGTH10 = new PasswordConditionsMap({
    innerHTML: `<span class="password-help-highlight">${PASSWORD_LENGTH_MIN} characters</span> minimum
    <span class="check-icon"><i class="material-icons">check_circle</i></span>`,
    conditionToMatch: lengthRegex(`${PASSWORD_LENGTH_MIN}`),
    errorConditionToMatch: null,
    displayPosition: 1,
    dataCy: 'password-tip-length',
    matched: false,
    errorMatched: false,
    isErrorCondition: false,
  });
  static readonly UPPERCASE = new PasswordConditionsMap({
    innerHTML: `<span class="password-help-highlight">One</span> uppercase
    <span class="check-icon"><i class="material-icons">check_circle</i></span>`,
    conditionToMatch: /[A-Z]/,
    errorConditionToMatch: null,
    displayPosition: 2,
    dataCy: 'password-tip-uppercase',
    matched: false,
    errorMatched: false,
    isErrorCondition: false,
  });
  static readonly LOWERCASE = new PasswordConditionsMap({
    innerHTML: `<span class="password-help-highlight">One</span> lowercase
    <span class="check-icon"><i class="material-icons">check_circle</i></span>`,
    conditionToMatch: /[a-z]/,
    errorConditionToMatch: null,
    displayPosition: 3,
    dataCy: 'password-tip-lowercase',
    matched: false,
    errorMatched: false,
    isErrorCondition: false,
  });
  static readonly NUMBER = new PasswordConditionsMap({
    innerHTML: `<span class="password-help-highlight">One</span> number
    <span class="check-icon"><i class="material-icons">check_circle</i></span>`,
    conditionToMatch: /[0-9]/,
    errorConditionToMatch: null,
    displayPosition: 4,
    dataCy: 'password-tip-number',
    matched: false,
    errorMatched: false,
    isErrorCondition: false,
  });
  static readonly SPECIAL_CHAR = new PasswordConditionsMap({
    innerHTML: `<span class="password-help-highlight">One</span> special character
    <span class="check-icon"><i class="material-icons">check_circle</i></span>`,
    conditionToMatch: /[/\\!$%^&*()\-_+|={}[:;?.@#\]]/,
    errorConditionToMatch: /[<>]/,
    displayPosition: 5,
    dataCy: 'password-tip-special-char',
    matched: false,
    errorMatched: false,
    isErrorCondition: false,
  });
  static readonly SCRIPT_TAGS = new PasswordConditionsMap({
    innerHTML: `We don't accept < or >, please choose another`,
    conditionToMatch: null,
    errorConditionToMatch: /[<>]/,
    displayPosition: 0,
    dataCy: 'password-tip-unallowed-chars',
    matched: false,
    errorMatched: false,
    isErrorCondition: true,
  });

  // Private to disallow creating other instances of this type
  private constructor(readonly value: MappedPasswordConditions) {}

  toString() {
    return this.value;
  }
}

export class MappedPasswordConditions {
  innerHTML: string;
  conditionToMatch: RegExp | null;
  errorConditionToMatch: RegExp | null;
  displayPosition: number;
  dataCy: string;
  matched: boolean;
  errorMatched: boolean;
  isErrorCondition: boolean;

  constructor(data: any) {
    this.innerHTML = data.innerHTML;
    this.conditionToMatch = data.conditionToMatch;
    this.errorConditionToMatch = data.errorConditionToMatch;
    this.displayPosition = data.displayPosition;
    this.dataCy = data.dataCy;
    this.matched = data.matched;
    this.errorMatched = data.errorMatched;
    this.isErrorCondition = data.isErrorCondition;
  }
}

export const passwordConditionMessagesMap: PasswordConditionMessagesMap = {
  'Should be between 8 and 128 characters long': PasswordConditionsMap.LENGTH.value,
  'Should be between 10 and 128 characters long': PasswordConditionsMap.LENGTH10.value,
  'Should have at least 1 uppercase letter': PasswordConditionsMap.UPPERCASE.value,
  'Should have at least 1 lowercase letter': PasswordConditionsMap.LOWERCASE.value,
  'Should have at least 1 number': PasswordConditionsMap.NUMBER.value,
  'Should have at least 1 special character (! @ # $ % ^ & * ( ) - _ = + \\ | [ ] { } ; : / ? .)':
    PasswordConditionsMap.SPECIAL_CHAR.value,
  "Cannot contain '<' or '>'": PasswordConditionsMap.SCRIPT_TAGS.value,
};
