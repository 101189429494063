<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
<div class="pushdr-element">
  <ng-container *ngTemplateOutlet="listOfBulletins"></ng-container>
</div>

<ng-template #listOfBulletins>
  <div *ngIf="bulletins$ | async as bulletins; else loading">
    <ng-container *ngIf="bulletins | fullTextSearch: query:'heading' as filteredBulletins">
      <ul *ngIf="filteredBulletins?.length; else noBulletins" class="c-bulletins-list">
        <li
          *ngFor="let bulletin of filteredBulletins"
          class="c-bulletins-list__item"
          (click)="selectBulletin(bulletin)"
          (keyup.enter)="selectBulletin(bulletin)"
          role="button"
          tabindex="0">
          <h3 class="c-bulletins-list__heading">{{ bulletin.heading }}</h3>
          <p *ngIf="bulletin.readDate" class="c-bulletins-list__created-date">
            <i class="material-icons">calendar_today</i>
            {{ bulletin.readDate }}
          </p>
        </li>
      </ul>
      <ng-template #noBulletins>
        <p class="p-4 m-0 text-center border border-dashed border-grey-300">
          <span *ngIf="query?.length; else noQuery"
            >Sorry your search has not returned any results.</span
          >
          <ng-template #noQuery data-cy="no-bulletins"
            >Sorry there are no bulletins currently available.</ng-template
          >
        </p>
      </ng-template>
    </ng-container>
  </div>
</ng-template>

<ng-template #loading>
  <pushdr-spinner-loader minHeight="0"></pushdr-spinner-loader>
</ng-template>
