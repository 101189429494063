import * as moment from 'moment';
import { Moment } from 'moment';
import { timer } from 'rxjs';
import { map, take } from 'rxjs/operators';
/*
{
  title: ``,
  body: ``
  date: moment('16-05-2020', 'DD-MM-YYYY'),
  }
*/

export interface ChangeLog {
  title: string;
  body: string;
  date: Moment;
}

export const changes: ChangeLog[] = [
  {
    title: `Push Consult has arrived`,
    body: `We've been working hard to update our old doctors application. A long time overdue,
    we've tirelessly researched and developed that which you see before you. Over the coming months
    this application will continue to evolve as we add more and more features and integrate with NHS digital services.`,
    date: moment('16-05-2020', 'DD-MM-YYYY'),
  },
  {
    title: `Fixes and video size improvements`,
    body: `Some general fixes and improvements made to the application. A common trend of feedback was increase the video size, so you can now full screen the video while in consultation to improve visual examinations when needed. Press the icon in the top right hand corner of the patients video panel.`,
    date: moment('01-08-2020', 'DD-MM-YYYY'),
  },
  {
    title: `Speed checker added`,
    body: `On the left hand side you will now see a network icon which will tell the most recent
     speed test result (by hovering over it). You can run the test any time manually but it will run periodically by itself as well. There are three result going from poor to good. Please cite this result when reporting
     performance issues with any part of the application.`,
    date: moment('06-08-2020', 'DD-MM-YYYY'),
  },
  {
    title: `Email a patient`,
    body: `You can now email your patients with relevant and useful information (this can include URLs) during their consultation for a patient to read at a later time.
     This functionality can be found by opening the "Actions" menu while in consultation.`,
    date: moment('03-09-2020', 'DD-MM-YYYY'),
  },
  {
    title: `Update to interface for private consultations`,
    body: `You can now move a Problem saved on the patient's record as an 'Active' problem to 'Past' problems. To do this, click the three dot symbol on the entry and select 'Move to: Past problems'. <br>
    You can also add a new Problem to the record as a 'Past' problem. The Problem type needs to be set as either 'Active' or 'Past' before clicking 'Add to Record' at the bottom of this section. <br>
    You can also now change an Allergy/Sensitivity already saved on the record from 'Active' to 'Resolved'. To do this, click the three dot symbol next to 'Status', select 'Resolved', enter the reason for doing so (this is mandatory) and press submit.`,
    date: moment('23-04-2021', 'DD-MM-YYYY'),
  },
  {
    title: `NHS diagnosis capture`,
    body: `As an interim solution to assist us with our medical governance and audits, we would like to capture the diagnosis of this consultation using the input box.
    The Push Consult diagnosis list will differ from that in EMIS/TPP, so please just select the closest match.`,
    date: moment('20-04-2021', 'DD-MM-YYYY'),
  },
  {
    title: `Update to interface for private consultations`,
    body: `You can now move a Problem saved on the patient's record as an 'Active' problem to 'Past' problems. To do this, click the three dot symbol on the entry and select 'Move to: Past problems'. <br>
    You can also add a new Problem to the record as a 'Past' problem. The Problem type needs to be set as either 'Active' or 'Past' before clicking 'Add to Record' at the bottom of this section. <br>
    You can also now change an Allergy/Sensitivity already saved on the record from 'Active' to 'Resolved'. To do this, click the three dot symbol next to 'Status', select 'Resolved', enter the reason for doing so (this is mandatory) and press submit.`,
    date: moment('23-04-2021', 'DD-MM-YYYY'),
  },
  {
    title: `NHS diagnosis capture`,
    body: `As an interim solution to assist us with our medical governance and audits, we would like to capture the diagnosis of this consultation using the input box.
    The Push Consult diagnosis list will differ from that in EMIS/TPP, so please just select the closest match.`,
    date: moment('20-04-2021', 'DD-MM-YYYY'),
  },
  {
    title: `Update to interface for private consultations`,
    body: `You can now move a Problem saved on the patient's record as an 'Active' problem to 'Past' problems. To do this, click the three dot symbol on the entry and select 'Move to: Past problems'. <br>
    You can also add a new Problem to the record as a 'Past' problem. The Problem type needs to be set as either 'Active' or 'Past' before clicking 'Add to Record' at the bottom of this section. <br>
    You can also now change an Allergy/Sensitivity already saved on the record from 'Active' to 'Resolved'. To do this, click the three dot symbol next to 'Status', select 'Resolved', enter the reason for doing so (this is mandatory) and press submit.`,
    date: moment('23-04-2021', 'DD-MM-YYYY'),
  },
  {
    title: `Read Records`,
    body: `Clinicians working on our NHS GP service now have the ability to view a patient's NHS medical record within the consultation interface.
    <br>
    For more details please see our recent email and bulletin.`,
    date: moment('19-05-2021', 'DD-MM-YYYY'),
  },
  {
    title: `Push Doctor Support Chat`,
    body: `We have now added chat support to Push Consult. This is available in the left side menu allowing you to easily chat with our support team.`,
    date: moment('12-12-2022', 'DD-MM-YYYY'),
  },
];

// TODO: delete when we have an API for this
export const changes$ = timer(200).pipe(
  take(1),
  map(() => changes.reverse())
);
