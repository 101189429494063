import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl, Validators } from '@angular/forms';
import { retry, tap } from 'rxjs/operators';

import { PharmaceuticalsMedication } from '@pushdr/prescription/api';
import { PrescriptionBuilderService } from '../services/prescription-builder.service';

@Component({
  selector: 'pushdr-select-medication',
  templateUrl: './select-medication.component.html',
  styleUrls: ['./select-medication.component.scss'],
  providers: [PrescriptionBuilderService],
})
export class SelectMedicationComponent {
  warnings$ = this.prescriptionBuilder.warnings$.pipe(
    tap({
      next: () => this.markAsWithError(false),
      error: () => this.markAsWithError(true),
    }),
    retry()
  );

  showFooter$ = this.prescriptionBuilder.selectionChanges;

  warningsLoading = false;
  viewedAllWarnings = false;
  hasWarningsError = false;

  showOverrideWarning = false;
  overrideWarningControl = new UntypedFormControl('', [Validators.required]);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private prescriptionBuilder: PrescriptionBuilderService
  ) {}

  medicationChanged(medication: PharmaceuticalsMedication) {
    if (medication) {
      this.setMedication(medication);
      this.warningsLoading = true;
    } else {
      this.clearMedication();
    }
  }

  private setMedication(medication: PharmaceuticalsMedication): void {
    this.prescriptionBuilder.selectMedication(medication);
    this.showOverrideWarning = false;
    this.viewedAllWarnings = false;
    this.overrideWarningControl.reset('', { emitEvent: false });
  }

  private clearMedication(): void {
    this.setMedication(null);
  }

  acceptWarningsAndProceed(): void {
    const warningControl = this.overrideWarningControl;
    this.prescriptionBuilder.confirmMedication(warningControl).subscribe({
      next: confirmed => this.confirmMedicationAndNavigate(confirmed),
    });
  }

  private confirmMedicationAndNavigate(confirmed: boolean): void {
    if (confirmed) {
      this.router.navigate(['../specify-medication'], { relativeTo: this.route });
    } else {
      // High risk medication, show warning override instead
      this.showOverrideWarning = true;
    }
  }

  private markAsWithError(hasError: boolean): void {
    this.viewedAllWarnings = hasError;
    this.hasWarningsError = hasError;
    this.warningsLoading = false;
  }
}
