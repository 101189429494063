<div class="pushdr-element">
  <ng-container *ngIf="bulletins; else loading">
    <div class="c-document-viewer" *ngIf="currentBulletin">
      <div class="c-document-viewer__header">
        <h3 class="mb-0 vertical-pipe">{{ currentBulletin.heading }}</h3>
        <p class="mb-0 vertical-pipe">
          <i class="align-text-bottom material-icons">calendar_today</i>
          {{ currentBulletin.dateStamp }}
        </p>
        <p class="mb-0">{{ currentBulletinIndex + 1 }} of {{ bulletins.length }}</p>
      </div>
      <div class="c-document-viewer__content">
        <iframe
          *ngIf="trustedUrl"
          class="c-document-viewer__iframe"
          [src]="trustedUrl"
          title="{{ currentBulletin.heading }}"></iframe>
        <div class="w-1/5 pl-2 text-center c-document-viewer__action" *ngIf="needsAction">
          <div
            class="block mb-0 text-left o-input"
            [class.o-input--error]="submitted && !isChecked">
            <p class="p-4 rounded bg-information-light">
              Read the bulletin then mark it as read using the checkbox.
            </p>
            <input
              tabindex="0"
              class="o-input__input"
              type="checkbox"
              id="checkbox_1"
              (input)="checkboxInput()"
              [checked]="isChecked" />
            <label class="block o-input__label" for="checkbox_1"> I have read this bulletin </label>
          </div>
          <button
            class="min-w-full c-btn c-btn--primary c-btn--tiny"
            (click)="submit()"
            data-cy="continue-button">
            Continue
          </button>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #loading>
    <pushdr-spinner-loader></pushdr-spinner-loader>
  </ng-template>
</div>
