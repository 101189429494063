<div class="c-card--clinicians-wrapper">
  <div class="relative c-card c-card--clinicians">
    <ng-container
      *ngIf="{ today: (todaysSessions$ | async), current: (nextOrCurrent$ | async) } as sessions">
      <div
        *ngIf="sessions.today; else loading"
        class="flex flex-col h-full max-h-full overflow-y-auto scrollbar">
        <div class="flex flex-row items-center mx-32 my-20">
          <img
            src="/assets/images/logosvg/push-doctor-shield-black.svg"
            alt="Push Doctor Logo"
            class="mr-4"
            width="100px"
            height="100px" />
          <h2
            *ngIf="sessions.current; else titleWhenNoCurrentSession"
            [ngSwitch]="sessions.current.status"
            class="mb-0">
            <span *ngSwitchCase="ClinicianSessionStatus.PENDING"
              >Time to begin your session...</span
            >
            <span *ngSwitchCase="ClinicianSessionStatus.IN_PROGRESS">Welcome back...</span>
            <span *ngSwitchCase="ClinicianSessionStatus.ON_BREAK">Welcome back...</span>
            <span *ngSwitchDefault>All sessions completed today...</span>
          </h2>
          <ng-template #titleWhenNoCurrentSession>
            <h2 class="mb-0">
              No sessions
              <span>
                {{ sessions?.today?.length ? 'remaining' : 'scheduled' }}
              </span>
              today...
            </h2>
          </ng-template>
        </div>
        <div class="flex flex-row justify-center flex-grow w-full pt-20 bg-cream">
          <table class="w-full mx-32 border-none table-fixed">
            <thead>
              <tr style="border-bottom: 1px solid #dadada">
                <th class="w-3/12 px-4 pt-4 pb-2 text-left">Todays session's</th>
                <th class="w-2/12 px-4 pt-4 pb-2 text-left">Type</th>
                <th class="w-2/12 px-4 pt-4 pb-2 text-left">Status</th>
                <th class="w-2/12 px-4 pt-4 pb-2 text-center">
                  <!-- Secondary CTA -->
                </th>
                <th class="w-2/12 px-4 pt-4 pb-2 text-center">
                  <!-- Primary CTA -->
                </th>
              </tr>
            </thead>
            <ng-container *ngIf="sessions?.today; else noSessions">
              <tbody *ngIf="sessions?.today?.length; else noSessions">
                <tr *ngFor="let session of sessions.today">
                  <td class="px-4 pt-8 font-light">
                    {{ session.startTime | date: 'h:mma' | lowercase }} -
                    {{ session.endTime | date: 'h:mma' | lowercase }}
                  </td>
                  <td class="px-4 pt-8 font-light">
                    {{ session?.tags?.SurgeryType | clinicianSystem }}
                  </td>
                  <td class="px-4 pt-8 font-light">{{ status(session) | titlecase }}</td>
                  <td class="px-4 pt-8 font-light">
                    <button
                      *ngIf="showEnd(session)"
                      class="w-24 px-0 text-xs leading-3 btn btn-outline"
                      (click)="endConsulting(session.id)">
                      End
                    </button>
                    <button
                      *ngIf="showStart(session, sessions.current)"
                      class="w-24 px-0 text-xs leading-3 btn btn-primary"
                      (click)="startConsulting(session.id)">
                      Begin
                    </button>
                    <button
                      *ngIf="showResume(session)"
                      class="w-24 px-0 text-xs leading-3 btn btn-primary"
                      (click)="resumeConsulting()">
                      Resume
                    </button>
                  </td>
                </tr>
              </tbody>
            </ng-container>
            <ng-template #noSessions>
              <tbody>
                <p class="px-4 py-8 text-base font-light">None scheduled</p>
              </tbody>
            </ng-template>
          </table>
        </div>
        <div class="flex flex-row justify-center flex-grow w-full pt-20 bg-cream">
          <p class="italic">
            If the schedule displayed above does not reflect your expectations please contact us
          </p>
        </div>
      </div>
    </ng-container>
  </div>
  <ng-template #loading>
    <div class="block w-full">
      <pushdr-spinner-loader message="Fetching sessions" [minheight]="300"></pushdr-spinner-loader>
    </div>
  </ng-template>
</div>
