import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { CliniciansAuthService } from '@pushdr/clinicians/common';

@Injectable({
  providedIn: 'root',
})
export class HasPasswordStoredGuard implements CanActivate {
  constructor(private auth: CliniciansAuthService, private router: Router) {}

  canActivate(): boolean | UrlTree {
    if (this.auth.password) {
      return true;
    } else {
      this.router.navigate(['/auth/login']);
      return false;
    }
  }
}
