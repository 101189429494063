<div class="mb-3 p-2">
  <label class="block text-gray-700 text-sm font-bold mb-2" for="medications-equivalents">
    <ng-container *ngIf="genericAllowed">
      It is recommended that you select a branded product for this medication:
    </ng-container>
    <ng-container *ngIf="!genericAllowed">
      This medication can only be prescribed as a branded product, please select one below.
    </ng-container>
  </label>
  <ng-select
    [items]="medications"
    bindLabel="medicationName"
    notFoundText="No matching medications"
    [formControl]="eqControl"
    id="medications-equivalents"
    placeholder="Select a brand"
    aria-live="polite"
    [searchFn]="dummySearch"
    (change)="onChange($event)">
    <ng-template ng-option-tmp let-item="item">
      <p class="mb-0">{{ item.medicationName }}</p>
    </ng-template>
  </ng-select>
</div>
