import { Component } from '@angular/core';

@Component({
  selector: 'pushdr-clinicians-profile',
  templateUrl: './clinicians-profile.component.html',
  styleUrls: ['./clinicians-profile.component.scss'],
})
export class CliniciansProfileComponent {
  constructor() {}
}
