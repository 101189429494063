import { Injectable } from '@angular/core';
import { combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import {
  ClinicalDecisionSupportQuery as CdsQuery,
  ClinicalDecisionSupportService,
} from '@pushdr/prescription/fdb';

import { Gender, mapSnomedData } from '@pushdr/common/types';

import { PrescriptionBasketService } from './prescription-basket.service';
import { PrescriptionProxy } from '../prescription-proxy';

@Injectable({
  providedIn: 'root',
})
export class PrescriptionWarningService {
  private snomedPrescription$ = this.basket.prescriptions$.pipe(
    map(prescriptions => prescriptions.map(mapSnomedData))
  );

  // Collect all medications from NHS and EPS records
  private snomedMedications$ = combineLatest([
    this.prescriptionProxy.getStructuredRecords(),
    this.prescriptionProxy.getCurrentAcuteMedications(),
  ]).pipe(
    map(([records, epsMedications]) => {
      const snomedNhs = records.snomedActiveMedications;
      const snomedEps = epsMedications.map(mapSnomedData);
      return [...snomedNhs, ...snomedEps];
    })
  );

  readonly cdsQuery$ = combineLatest([
    this.prescriptionProxy.getStructuredRecords(),
    this.prescriptionProxy.getPatientDetails(),
    this.snomedPrescription$,
    this.snomedMedications$,
  ]).pipe(
    map(
      ([records, details, snomedPrescription, snomedMedications]): CdsQuery => ({
        dateOfBirth: records?.patient?.birthDate ?? details?.Patient?.DateOfBirth,
        gender: records?.patient?.gender ?? mapGender(details?.Patient?.Gender),
        prospectiveMedications: snomedPrescription,
        currentMedications: snomedMedications,
        conditions: records.snomedConditions,
        allergies: records.snomedActiveAllergies,
      })
    )
  );

  readonly warnings$ = this.cdsQuery$.pipe(
    switchMap(cdsQuery => this.cds.getCdsWarnings(cdsQuery))
  );

  constructor(
    private prescriptionProxy: PrescriptionProxy,
    private basket: PrescriptionBasketService,
    private cds: ClinicalDecisionSupportService
  ) {}
}

function mapGender(gender: Gender): string {
  // Maps PushDr's gender to CDS compatible one
  return ['', 'male', 'female'][gender];
}
