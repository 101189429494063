<pushdr-consultation-card [showFooter]="true" *ngIf="currentState$ | async as state">
  <span header>Dial an interpreter</span>
  <div class="flex h-full" body>
    <div
      *ngIf="
        state === translatorDialState.CONNECTING ||
        state === translatorDialState.RINGING ||
        state === translatorDialState.ANSWERED
      "
      class="flex flex-col items-center justify-between w-full h-full">
      <div></div>
      <div class="flex flex-col items-center justify-between">
        <i
          *ngIf="state === translatorDialState.RINGING || state === translatorDialState.CONNECTING"
          class="bg-indigo-500 p-4 text-white rounded-full material-icons text-5xl m-4"
          >call_end</i
        >
        <i
          *ngIf="state === translatorDialState.ANSWERED"
          class="material-icons success-icon m-4 text-teal-500"
          >check_circle_outline</i
        >
        <span class="m-4"
          >{{
            state === translatorDialState.RINGING
              ? 'Dialing'
              : state === translatorDialState.CONNECTING
              ? 'Connecting to'
              : 'Connected to'
          }}
          Language Line</span
        >
        <span
          >Please quote the below access code
          {{
            state === translatorDialState.CONNECTING || state === translatorDialState.RINGING
              ? 'once connected'
              : ''
          }}:</span
        >
        <span class="tracking-widest text-3xl mt-8">{{ accessCode$ | async }}</span>
      </div>
      <div
        class="flex flex-col w-full justify-between items-center text-center"
        [style.visibility]="state === translatorDialState.ANSWERED ? 'visible' : 'hidden'">
        <span class="m-4"
          >Once an interpreter is connected, click below to resume consultation.</span
        >
        <span class="m-4"
          >Please confirm and record patient consent, interpreter name and interpreter ID code in
          consultation notes</span
        >
      </div>
    </div>
    <div
      *ngIf="state === translatorDialState.FAILED"
      class="flex items-center h-full w-full justify-center">
      <div class="flex flex-col items-center">
        <img src="assets/icons/error_outline_red.svg" alt="error" />
        <span class="my-8">Sorry, we couldn’t connect you. Please dial again</span>
      </div>
    </div>
    <div
      class="flex flex-col justify-between items-center w-full h-full"
      *ngIf="state === translatorDialState.CALL_NOT_STARTED">
      <ng-select
        class="w-full"
        placeholder="Search languages"
        notFoundText="No matching languages"
        aria-live="polite"
        bindLabel="LanguageName"
        [formControl]="control"
        #ngSelect>
        <ng-option
          *ngFor="let language of languages$ | async; trackBy: 'LanguageCode' | trackByProperty"
          [value]="language"
          >{{ language.LanguageName }}</ng-option
        >
      </ng-select>
    </div>
  </div>
  <div footer>
    <button data-cy="back" class="m-5 btn btn-outline" (click)="onClickBackToConsultation()">
      Back to consultation
    </button>
    <button
      *ngIf="
        (state === translatorDialState.FAILED || state === translatorDialState.CALL_NOT_STARTED) &&
        control.value
      "
      class="btn btn-primary ml-8"
      (click)="dialTranslator()">
      Dial interpreter
    </button>
  </div>
</pushdr-consultation-card>
