import { Component, OnInit } from '@angular/core';
import { AnalyticsBusService, AnalyticsEvent } from '@pushdr/common/data-access/analytics';
import { PatientDetailsV2 } from '@pushdr/common/types';
import { CurrentOrderIdService } from '@pushdr/common/data-access/analytics';
import { ApiDoctorsEmergencyService } from '@pushdr/doctors/data-access/doctors-api';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConsultationFeatureService } from '../../../../../services/consultation-feature/consultation-feature.service';
import { PatientDetailsService } from '../../../../../services/patient-details/patient-details.service';
import { PatientNavigationService } from '../../../services/patient-navigation/patient-navigation.service';

@Component({
  selector: 'pushdr-emergency-patient',
  templateUrl: './emergency-patient.component.html',
  styleUrls: ['./emergency-patient.component.scss'],
})
export class EmergencyPatientComponent implements OnInit {
  details$: Observable<PatientDetailsV2>;
  isChildConsultation$: Observable<boolean>;

  constructor(
    private patient: PatientDetailsService,
    private patientNav: PatientNavigationService,
    private emergencyApi: ApiDoctorsEmergencyService,
    private msgBus: AnalyticsBusService,
    private features: ConsultationFeatureService,
    private order: CurrentOrderIdService
  ) {}

  ngOnInit() {
    this.isChildConsultation$ = this.features.isConsultingWithChild$;
    this.details$ = forkJoin([
      this.emergencyApi.patientNeedsImmediateHelp(this.order.id),
      this.patient.details$,
    ]).pipe(
      map(res => {
        const [response, details] = res;
        this.msgBus.trackEvent(
          AnalyticsEvent.info('consultation.emergency', `patient:${details.Patient.Id}`)
        );
        return details;
      })
    );
  }

  onClickBackToConsultation() {
    this.patientNav.gotoConsultationHome();
  }
}
