import { Injectable, Type } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConsultationState, ConsultationStateService } from '@pushdr/clinicians/common';
import { ConsultationFeatureService } from '../../../../services/consultation-feature/consultation-feature.service';
import { ConsultationPanelService } from './consultation-panel.service';

export enum PatientGridLayout {
  PDR = 'pdr',
  PDR_EDIT = 'pdr-edit',
  GPC_STRUCTURED = 'gpc-structured',
  GPC_UNSTRUCTURED = 'gpc-unstructured',
  NHS_EMIS_TPP = 'nhs-emis-tpp',
  DEFAULT = 'default',
}

export enum PanelIds {
  INFO1 = 'info1',
  INFO2 = 'info2',
  INFO3 = 'info3',
  INFO4 = 'info4',
}

export enum LayoutVariations {
  DEFAULT = '',
  LARGE_OUTLET = 'large-main-panel',
}

@Injectable({
  providedIn: 'root',
})
export class ConsultationLayoutService {
  layoutInfoSnapshot: PatientGridLayout;
  layoutVariation$ = new BehaviorSubject<string>('');

  constructor(
    private consultationFeatures: ConsultationFeatureService,
    private consultationState: ConsultationStateService,
    private panelService: ConsultationPanelService
  ) {
    this.layoutInfo$().subscribe(x => (this.layoutInfoSnapshot = x));
  }

  setGridLayoutVariation(variations: LayoutVariations) {
    this.layoutVariation$.next(variations);
  }

  resetGridLayoutVariation() {
    this.layoutVariation$.next(LayoutVariations.DEFAULT);
  }

  ensureCardVisible(component: Type<unknown>, panelId: PanelIds): boolean {
    const isLoaded = !!this.panelService.findPanelByComponent(component);
    if (!isLoaded) {
      // Defer card load as some of the components invoke the method on ngOnInit
      // Allows Change Detector to re-run on production environment
      setTimeout(() => this.loadCard(component, panelId));
    }
    return isLoaded;
  }

  loadCard(component: Type<unknown>, panelId: string) {
    const foundPanel = this.panelService.findPanelById(panelId);
    foundPanel?.changeToComponent(component);
  }

  layoutInfo$() {
    return combineLatest([
      this.consultationFeatures.canSeeRecordsStructured$,
      this.consultationFeatures.canSeeRecordsHTML$,
      this.consultationState.state$,
    ]).pipe(
      map(([canSeeRecordsStructured, canSeeRecordsHTML, state]) => ({
        canSeeStructRecords: canSeeRecordsStructured && state === ConsultationState.CONSULTING,
        canSeeHtmlRecords: canSeeRecordsHTML && state === ConsultationState.CONSULTING,
        state,
      })),
      map(({ canSeeStructRecords, canSeeHtmlRecords, state }) => {
        switch (true) {
          case state === ConsultationState.EDITING:
            return PatientGridLayout.PDR_EDIT;
          case canSeeStructRecords:
            return PatientGridLayout.GPC_STRUCTURED;
          case canSeeHtmlRecords:
            return PatientGridLayout.GPC_UNSTRUCTURED;
          default:
            return PatientGridLayout.DEFAULT;
        }
      })
    );
  }
}
