import { Directive, HostListener, Input, NgModule } from '@angular/core';
import { CopyToClipboardService } from './copy-to-clipboard.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[clipboard]',
})
export class ClipboardDirective {
  @Input() clipboard: string;

  constructor(private copyToClipboardService: CopyToClipboardService) {
    this.clipboard = '';
  }

  @HostListener('click', [])
  copyToClipboard(): void {
    this.copyToClipboardService.copy(this.clipboard);
  }
}

@NgModule({
  declarations: [ClipboardDirective],
  exports: [ClipboardDirective],
})
export class ClipboardModule {}
