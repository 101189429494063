import { Injectable } from '@angular/core';
import { from, Subject, combineLatest, ReplaySubject, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import * as CryptoJS from 'crypto-js';

export enum State {
  MESSAGE = 'messageState',
  EMAIL = 'emailState',
  REFERRAL = 'referralState',
  FIT_NOTE = 'fitNoteState',
}

interface UpdateState {
  state: State;
  nextState: any;
}

@Injectable({
  providedIn: 'root',
})
export class ConsultationStorageService {
  private storeName = 'Consultations';
  private patientId: string;
  private encryption: string;
  private storeReady$ = new ReplaySubject<void>(1);
  private messageState$ = new Subject<UpdateState>();
  private errors$ = new Subject<any>();
  private dbEnabled = true;

  constructor(private dbService: NgxIndexedDBService) {}

  startStorageForConsultation(encryption: string, patientId: string) {
    if (!patientId || !this.dbEnabled) {
      this.errors$.next('Consultation DB storage skipped... no patient id or db has issues');
      return;
    }
    this.encryption = encryption;
    this.patientId = patientId;
    this.updateOnStateChange();
    this.storeReady$.next();
    this.storeReady$.complete();
  }

  onError() {
    return this.errors$.asObservable();
  }

  clearAllStorage() {
    from(this.db.clear(this.storeName)).subscribe({
      error: err => this.disableAndReportError(err),
    });
  }

  setState(state: State, nextState: any) {
    this.messageState$.next({ state: state, nextState });
  }

  getState$(state: State) {
    return this.storeReady$.pipe(
      switchMap(() =>
        from(this.db.getByID(this.storeName, this.patientId + state)).pipe(
          catchError(err => {
            this.disableAndReportError(err);
            return of({});
          }),
          map((store: any) => (store && store.state ? this.decrypt(store.state) : []))
        )
      )
    );
  }

  private get db() {
    return this.dbEnabled
      ? this.dbService
      : {
          clear: () => Promise.resolve(),
          getByID: () => Promise.resolve({}),
          update: () => Promise.resolve(),
          deleteDatabase: () => Promise.resolve(),
        };
  }

  private updateOnStateChange(): void {
    combineLatest([this.messageState$, this.storeReady$])
      .pipe(
        switchMap(([updateState]) => {
          return from(
            this.db.update(this.storeName, {
              id: this.patientId + updateState.state,
              state: this.encrypt(updateState.nextState),
            })
          );
        })
      )
      .subscribe({
        error: err => this.disableAndReportError(err),
      });
  }

  private disableAndReportError(err) {
    this.dbEnabled = false;
    this.errors$.next(err);
  }

  private encrypt(data) {
    if (this.encryption)
      return CryptoJS.AES.encrypt(JSON.stringify(data), this.encryption).toString();
    return data;
  }

  private decrypt(data) {
    if (this.encryption)
      try {
        return JSON.parse(CryptoJS.AES.decrypt(data, this.encryption).toString(CryptoJS.enc.Utf8));
      } catch (e) {
        return null;
      }

    return data;
  }
}
