import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, of } from 'rxjs';
import { filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { PrescriptionBasketService } from '../services/prescription-basket.service';
import { PrescriptionDispenserService } from '../services/prescription-dispenser.service';
import { PrescriptionSigningService } from '../services/prescription-signing.service';
import { PrescriptionWarningService } from '../services/prescription-warning.service';
import { PrescriptionService } from '../services/prescription.service';

@Component({
  selector: 'pushdr-review-prescription',
  templateUrl: './review-prescription.component.html',
  styleUrls: ['./review-prescription.component.scss'],
})
export class ReviewPrescriptionComponent implements OnInit {
  warnings$ = this.prescriptionWarning.warnings$.pipe(
    shareReplay({ bufferSize: 1, refCount: true }),
    tap({ error: () => (this.hasError = true) })
  );

  hasWarnings$ = this.warnings$.pipe(map(({ warningsArr }) => warningsArr?.length > 0));

  dispenser$ = this.prescriptionDispenser.dispenser$;
  patientNotes$ = this.prescriptionDispenser.patientNotes$;
  prescriptionItems$ = this.prescriptionBasket.prescriptions$;
  hasPrescriptionItems$ = this.prescriptionBasket.hasPrescriptions$;

  hasError = false;

  constructor(
    private prescription: PrescriptionService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private prescriptionBasket: PrescriptionBasketService,
    private prescriptionDispenser: PrescriptionDispenserService,
    private prescriptionSigning: PrescriptionSigningService,
    private prescriptionWarning: PrescriptionWarningService
  ) {}

  ngOnInit(): void {
    const hasInvalidBasket$ = combineLatest([this.dispenser$, this.prescriptionItems$]).pipe(
      take(1),
      switchMap(([currentDispenser, prescriptionItems]) => {
        if (!currentDispenser || !prescriptionItems?.length) {
          this.prescription.clearPrescription();
          return of(true);
        }
        return of(false);
      })
    );

    const redirectTo = ['../select-medication'];
    hasInvalidBasket$.pipe(filter(shouldRedirect => shouldRedirect)).subscribe({
      next: () => this.router.navigate(redirectTo, { relativeTo: this.activatedRoute }),
    });
  }

  removeDrug(snomedCode: string) {
    this.prescriptionBasket.removePrescriptionBySnomedCode(snomedCode);
  }

  addAnotherDrug() {
    this.router.navigate(['../select-medication'], { relativeTo: this.activatedRoute });
  }

  proceedToDispenserSelect() {
    this.router.navigate(['../select-dispenser'], { relativeTo: this.activatedRoute });
  }

  issuePrescription() {
    this.prescriptionSigning
      .createPrescription$()
      .pipe(take(1))
      .subscribe({
        next: () =>
          this.router.navigate(['../sign-prescription'], { relativeTo: this.activatedRoute }),
      });
  }

  setPatientNotes() {
    this.router.navigate(['../set-patient-notes'], { relativeTo: this.activatedRoute });
  }

  viewWarnings() {
    this.router.navigate(['../accumulated-warnings'], { relativeTo: this.activatedRoute });
  }
}
