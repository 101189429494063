<div class="c-card--clinicians-wrapper">
  <div class="relative c-card c-card--clinicians">
    <div
      class="flex flex-col h-full max-h-full w-full overflow-y-auto scrollbar c-card--clinicians-inner">
      <div
        *ngIf="activeSessionShouldEnd()"
        class="absolute top-0 left-0 right-0 px-5 py-4 bg-information-light text-information">
        Your scheduled session time has been reached, please end it when you are ready
        <button
          class="float-right text-primary hover:text-primary-hover"
          (click)="endActiveSession()">
          End this session
        </button>
      </div>
      <div class="flex flex-col justify-center flex-grow w-full pt-12">
        <ng-container *ngIf="connectingToPatient">
          <div class="text-center" data-cy="section-connecting-to-patient">
            <h2 class="mb-4">Connecting...</h2>
          </div>
        </ng-container>
        <ng-container *ngIf="!connectingToPatient">
          <ng-container *ngIf="patientCount === null; then findingPatients"></ng-container>
          <ng-container *ngIf="patientCount === 0; then noPatients"></ng-container>
          <ng-container *ngIf="patientCount > 0; then foundPatients"></ng-container>
          <ng-template #findingPatients>
            <div class="text-center" data-cy="section-finding-patients">
              <h2 class="mb-4">Finding your next patient...</h2>
              <pushdr-person-icon
                [isSearching]="clinicianState !== clinicianAwayState.AWAY_MANUAL && !patientCount"
                [isFound]="(patientCount > 0 && clinicianState !== null) || connectingToPatient">
              </pushdr-person-icon>
            </div>
          </ng-template>
          <ng-template #noPatients>
            <div class="text-center" data-cy="section-no-patients">
              <h2 class="mb-4">There are no patients at this time</h2>
              <p class="text-center">
                Please wait and be ready to collect a patient when they become available.
              </p>
              <pushdr-person-icon
                [isSearching]="clinicianState !== clinicianAwayState.AWAY_MANUAL && !patientCount"
                [isFound]="(patientCount > 0 && clinicianState !== null) || connectingToPatient">
              </pushdr-person-icon>
              <button *ngIf="clinicianIsAway" class="btn btn-primary" (click)="unAway()">
                I'm back
              </button>
            </div>
          </ng-template>
          <ng-template #foundPatients>
            <div class="text-center" data-cy="section-found-patients">
              <h2 class="mb-8">A patient is ready to see you</h2>
              <pushdr-person-icon
                [isSearching]="clinicianState !== clinicianAwayState.AWAY_MANUAL && !patientCount"
                [isFound]="(patientCount > 0 && clinicianState !== null) || connectingToPatient">
              </pushdr-person-icon>
              <button class="btn btn-primary" (click)="sendClinicianWhereTheyShouldBe()">
                Start consultation
              </button>
            </div>
          </ng-template>
        </ng-container>
      </div>
      <pushdr-session-stats [session]="activeSession"></pushdr-session-stats>
      <div class="flex flex-row justify-center w-full h-48 pt-12 bg-cream">
        <div class="text-center" *ngIf="clinicianIsAway">
          <h3 class="mb-2 text-indigo-700" [class.text-information]="longAway">
            Away for {{ timerText$ | async }}
          </h3>
          <ng-container [ngSwitch]="true">
            <p *ngSwitchCase="longAway" class="text-sm leading-7 text-center">
              We understand you need breaks, however you’ve now been away over 10 minutes
              <br />
              <a class="font-normal text-indigo-500" href="mailto:doctorsupport@pushdr.com"
                >Tell us if you have an issue</a
              >
            </p>
            <p
              *ngSwitchCase="!longAway && clinicianState === clinicianAwayState.AWAY_MANUAL"
              class="text-sm leading-7 text-center">
              We understand you need breaks, when you are ready, simply start
              <br />
              the consultation if one is available
            </p>
            <p
              *ngSwitchCase="!longAway && clinicianState === clinicianAwayState.AWAY_IDLE"
              class="text-sm leading-7 text-center">
              We assume you're not at your screen and have set you to away
              <br />
              When you return, start the consultation if one is available
            </p>
          </ng-container>
        </div>
        <div class="text-center">
          <button
            class="m-0 btn btn-outline"
            *ngIf="!clinicianIsAway && !connectingToPatient"
            (click)="away()">
            Set status to away
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
