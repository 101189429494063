import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SimpleModalModule } from 'ngx-simple-modal';

import { CliniciansCommonModule } from '@pushdr/clinicians/common';
import { DoctorNotesPipesModule } from './pipes/doctor-notes-pipes.module';
import { DoctorNotesRoutingModule } from './doctor-notes-routing.module';
import { DoctorNotesComponent } from './doctor-notes/doctor-notes.component';
import { DoctorNotesListComponent } from './doctor-notes-list/doctor-notes-list.component';
import { DoctorNotesListItemComponent } from './doctor-notes-list-item/doctor-notes-list-item.component';

@NgModule({
  imports: [
    CommonModule,
    CliniciansCommonModule,
    DoctorNotesPipesModule,
    DoctorNotesRoutingModule,
    // Lazy loaded modules require SimpleModalModule setting every time :(
    SimpleModalModule.forRoot(
      { container: document.body },
      {
        closeOnEscape: false,
        closeOnClickOutside: false,
        wrapperDefaultClasses: 'c-modal',
        bodyClass: 'js-modal-open',
        wrapperClass: 'c-modal--is-visible',
        animationDuration: 300,
        autoFocus: true,
      }
    ),
  ],
  declarations: [DoctorNotesComponent, DoctorNotesListComponent, DoctorNotesListItemComponent],
})
export class DoctorNotesModule {}
