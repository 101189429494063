<div class="w-full h-full p-2">
  <pushdr-consultation-card
    [greyHeader]="false"
    [hasData]="(isLoading$ | async) === false"
    bodyPadding="pt-0">
    <div header class="history-card-header">
      <div class="pt-5 pb-3 mx-5 text-xl border-b border-bluegrey-300 text-bluegrey-800">
        Consultation History
      </div>
      <div class="mx-5">
        <form
          *ngIf="(isLoading$ | async) === false"
          class="flex justify-between my-5 text-base"
          [formGroup]="filtersForm">
          <div class="flex justify-start w-3/4">
            <div class="w-1/5 mr-5">
              <pushdr-input-with-icon>
                <input
                  type="date"
                  input-field
                  name="date"
                  formControlName="date"
                  placeholder="Start date"
                  class="pl-4 pr-4 mt-1 mb-1"
                  id="date"
                  aria-required="true"
                  data-cy="prescriptions-date-filter"
                  autocomplete="off" />
              </pushdr-input-with-icon>
            </div>
            <div class="w-2/5 mr-5">
              <pushdr-input-with-icon>
                <input
                  input-field
                  name="quickFilter"
                  formControlName="quickFilter"
                  placeholder="Filter by name / NHS number / Prescription ID"
                  class="w-full pl-4 pr-4 m-1 mr-8"
                  id="quickFilter"
                  aria-required="true"
                  data-cy="prescriptions-data-quick-filter"
                  autocomplete="off" />
                <i input-icon class="material-icons search" data-cy="search"> search </i>
              </pushdr-input-with-icon>
            </div>
            <div class="w-1/5 pt-2 mr-5" [class.hidden]="filtersForm.get('myPatientsOnly')?.value">
              <pushdr-clinicians-prescriber-search
                [doctor]="doctor.get()"
                [prescribers]="prescribers$ | async"
                (prescriberChange)="filterByPrescriber($event)">
              </pushdr-clinicians-prescriber-search>
            </div>
          </div>
          <div class="flex items-center pt-2 min-w-240">
            <label class="flex-grow-0 text-base" for="myPatientsOnly">
              <input
                type="checkbox"
                [value]="true"
                formControlName="myPatientsOnly"
                name="myPatientsOnly"
                id="myPatientsOnly"
                data-cy="myPatientsOnly-chck"
                class="mt-1 ml-1 mr-1" />
              Filter - only patients I’ve seen
            </label>
          </div>
        </form>
      </div>
      <div class="flex items-stretch pr-4 mt-10">
        <table class="w-full">
          <thead>
            <tr class="text-sm leading-none text-left text-grey-700">
              <th class="w-1/12 p-3 pl-5 font-medium whitespace-nowrap">Time</th>
              <th class="w-2/12 p-3 font-medium whitespace-nowrap">Consulting clinician</th>
              <th class="w-2/12 p-3 font-medium whitespace-nowrap">Patient name</th>
              <th class="w-1/12 p-3 font-medium whitespace-nowrap">D.O.B.</th>
              <th class="w-1/12 p-3 font-medium whitespace-nowrap">NHS number</th>
              <th class="p-3 font-medium whitespace-nowrap">Outcomes</th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
    <div body class="overflow-y-scroll">
      <table class="w-full">
        <tbody class="">
          <ng-template #noRecords>
            <tr>
              <td colspan="100%" class="p-3">
                {{
                  (hasError$ | async)
                    ? 'Error loading data'
                    : 'No consultations found for this search criteria'
                }}
              </td>
            </tr>
          </ng-template>
          <ng-container
            *ngIf="
              consultations$
                | async
                | filterByPatient: filtersForm.get('quickFilter').value
                | filterByPrescriber: filtersForm.get('prescriberId').value as data
            ">
            <ng-container *ngIf="data.length; else noRecords">
              <tr
                *ngFor="let consultation of data"
                class="font-light text-left text-gray-700 border-b border-bluegrey-300 hover:bg-indigo-100"
                [routerLink]="['./' + consultation.consultationId]">
                <td class="w-1/12 p-3 pl-5 whitespace-nowrap">
                  {{ consultation.createdDate | britishDate: 'hh:mm A' }}
                </td>
                <td
                  class="w-2/12 p-3 whitespace-nowrap"
                  [class.font-bold]="consultation.prescriberId === doctor.get()?.DoctorId">
                  {{ consultation.prescriberName }}
                </td>
                <td class="w-2/12 p-3 font-medium text-indigo-700 whitespace-nowrap">
                  {{ consultation.patientName }} {{ consultation.patientSurname }}
                </td>
                <td class="w-1/12 p-3 whitespace-nowrap">
                  {{ consultation.patientDateOfBirth | date: 'mediumDate' }}
                </td>
                <td class="w-1/12 p-3 whitespace-nowrap">
                  {{ consultation.nhsNumber | formatNhsNumber }}
                </td>
                <td class="p-3 whitespace-nowrap">
                  <a
                    class="text-bluegrey-600"
                    [class.text-red-300]="consultation.hasErrorInPrescriptions"
                    tabindex="2"
                    [inlineSVG]="'/assets/icons/pill.svg'"
                    [replaceContents]="false">
                  </a>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </div>
  </pushdr-consultation-card>
</div>
