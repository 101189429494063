import { Injectable } from '@angular/core';
import {
  ApiDoctorsSessionsService as ApiClinicianssSessionsService,
  DoctorSession as ClinicianSession,
  DoctorSessionStatus as ClinicianSessionStatus,
} from '@pushdr/doctors/data-access/doctors-api';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Observable, Subject, timer, ReplaySubject } from 'rxjs';
import { map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ClinicianSessionsService {
  sessions$ = new ReplaySubject<ClinicianSession[]>(1);
  private from: Moment = moment().startOf('day');
  private to: Moment = moment().endOf('day');

  constructor(private sessionApi: ApiClinicianssSessionsService) {}

  pollSessionsUntil(
    until$: Subject<void>,
    milliseconds: number = 30000,
    from = moment().startOf('day'),
    to = moment().endOf('day')
  ) {
    this.setRange(from, to);
    timer(milliseconds, milliseconds)
      .pipe(
        takeUntil(until$),
        mergeMap(() => this.getSessions())
      )
      .subscribe({
        error: err => {
          console.error(err);
        },
      });
  }

  setRange(from: Moment, to: Moment) {
    this.from = from;
    this.to = to;
    this.getSessions().subscribe();
  }

  sessionsOrdered$(): Observable<ClinicianSession[]> {
    return this.sessions$.pipe(
      map(sessions => [...sessions.sort((a, b) => a.startTime.diff(b.startTime))])
    );
  }

  todaysSessions$(): Observable<ClinicianSession[]> {
    return this.sessionsOrdered$().pipe(
      map(sessions => sessions.filter(session => moment().isSame(session.endTime, 'day')))
    );
  }

  activeSession$(): Observable<ClinicianSession> {
    return this.sessions$.pipe(
      map(sessions => sessions.filter(session => this.isActive(session))),
      map(sessions => [...sessions.sort((a, b) => a.startTime.diff(b.startTime))]),
      map(sessions => sessions.shift())
    );
  }

  incompleteActiveSessions$(): Observable<ClinicianSession[]> {
    return this.sessions$.pipe(
      map(sessions => sessions.filter(session => this.isActive(session))),
      map(sessions => sessions.filter(session => this.isLateToEnd(session)))
    );
  }

  nextOrCurrent$(): Observable<ClinicianSession> {
    return this.sessions$.pipe(
      map(sessions => sessions.filter(session => !this.hasEnded(session))),
      map(sessions => sessions.filter(session => !this.isLateToEnd(session))),
      tap(sessions =>
        console.log(
          'isLateToEnd',
          sessions.map(s => s.id)
        )
      ),
      map(sessions => [...sessions.sort((a, b) => a.startTime.diff(b.startTime))]),
      map(sessions => sessions.shift())
    );
  }

  isActive(session: ClinicianSession): boolean {
    return [ClinicianSessionStatus.IN_PROGRESS, ClinicianSessionStatus.ON_BREAK].includes(
      session.status
    );
  }

  hasEnded(session: ClinicianSession): boolean {
    return session.status === ClinicianSessionStatus.COMPLETED;
  }

  isLateToStart(session: ClinicianSession): boolean {
    return moment().isAfter(session.startTime) && !this.isActive(session);
  }

  isLateToEnd(session: ClinicianSession): boolean {
    return moment().isAfter(session.endTime) && !this.hasEnded(session);
  }

  startSession(sessionId: string) {
    return this.startSession$(sessionId).toPromise();
  }

  endSession(sessionId: string) {
    return this.endSession$(sessionId).toPromise();
  }

  startBreak(sessionId: string) {
    return this.startBreak$(sessionId).toPromise();
  }

  endBreak(sessionId: string) {
    return this.endBreak$(sessionId).toPromise();
  }

  startSession$(sessionId: string) {
    return this.sessionApi.startSession(sessionId).pipe(switchMap(() => this.getSessions()));
  }

  endSession$(sessionId: string) {
    return this.sessionApi.endSession(sessionId).pipe(switchMap(() => this.getSessions()));
  }

  startBreak$(sessionId: string) {
    return this.sessionApi.startBreak(sessionId).pipe(switchMap(() => this.getSessions()));
  }

  endBreak$(sessionId: string) {
    return this.sessionApi.endBreak(sessionId).pipe(switchMap(() => this.getSessions()));
  }

  private getSessions() {
    return this.sessionApi
      .getSessions(this.from, this.to)
      .pipe(tap(sessions => this.sessions$.next(sessions)));
  }
}
