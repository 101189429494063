import { Injectable } from '@angular/core';
import {
  ApiDoctorsConsultationDocumentsService,
  ApiDoctorsPatientService,
  ApiDoctorsConsultationNotesService,
} from '@pushdr/doctors/data-access/doctors-api';
import { Observable, zip, forkJoin, throwError } from 'rxjs';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { retryBackoff } from 'backoff-rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CurrentOrderIdService } from '@pushdr/common/data-access/analytics';

export interface HistoricConsultationSummary {
  intConsultationID: number;
  objLetters: any[];
  objPrescriptions: any[];
  objSickNotes: any[];
  strConsultationDate: string;
  reportGuid: string;
}

export interface HistoricConsultationExtended<T> extends HistoricConsultationSummary {
  notes: T;
}

@Injectable({
  providedIn: 'root',
})
export class ConsultationHistoryService {
  private _previousConsultations$: Observable<HistoricConsultationSummary[]>;

  constructor(
    private consultationDocumentsApi: ApiDoctorsConsultationDocumentsService,
    private consultationNotesApi: ApiDoctorsConsultationNotesService,
    private consultationReportsApi: ApiDoctorsPatientService,
    private sanitizer: DomSanitizer,
    private order: CurrentOrderIdService
  ) {
    // Mitigates caching issues carried into next consultation
    this.order.idChanges.subscribe(() => this.clearCache());
  }

  get previousConsultations$(): Observable<HistoricConsultationSummary[]> {
    if (!this._previousConsultations$) {
      this._previousConsultations$ = zip(
        this.consultationReportsApi.getPreviousConsultationReports(),
        this.consultationDocumentsApi.getHistoricConsultationSummary(this.order.id)
      ).pipe(
        map(([reports, infos]) =>
          infos.map(info => ({
            ...info,
            reportGuid: this.getMatchingReportGuid(reports, info.intConsultationID),
          }))
        ),
        map(previousConsultations =>
          this.removeCurrentConsultationFromHistory(previousConsultations)
        ),
        retryBackoff({
          initialInterval: 5000,
          maxRetries: 5,
          shouldRetry: () => true,
        }),
        publishReplay(1),
        refCount()
      );
    }
    return this._previousConsultations$;
  }

  getNotes(id: number): Observable<any> {
    return this.consultationNotesApi.getConsultationNote(id);
  }

  getHistoricConsultation$<T>(id: number): Observable<HistoricConsultationExtended<T>> {
    const notes$ = this.getNotes(id);
    return forkJoin([
      this.previousConsultations$.pipe(
        map(consultations =>
          consultations.find(consultation => consultation.intConsultationID === id)
        )
      ),
      notes$,
    ]).pipe(
      map(([consultation, consultationNotes]) => ({
        ...consultation,
        notes: consultationNotes,
      }))
    );
  }

  getConsultationReport$(reportGuid): Observable<SafeResourceUrl> {
    if (!reportGuid) {
      return throwError(new Error('Invalid reportGuid'));
    }
    return this.getPdf$(reportGuid).pipe(
      map(pdf =>
        this.sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,' + pdf)
      )
    );
  }

  clearCache() {
    this._previousConsultations$ = null;
  }

  private getPdf$(reportGuid) {
    return this.consultationReportsApi
      .getConsultationReport(reportGuid)
      .pipe(map(res => res.pdfBase64));
  }

  private removeCurrentConsultationFromHistory(consultations: any[]) {
    return consultations.splice(1, consultations.length - 1);
  }

  private getMatchingReportGuid(reports: any[], consultationId: number) {
    const consultationReport = reports.find(
      report => report.orderId === consultationId && report.pdfShouldExist
    );
    return consultationReport ? consultationReport.requestGuid : null;
  }
}
