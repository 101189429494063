import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import {
  ENDORSEMENTS_VOCABULARY,
  PharmaceuticalsMedicationDetails,
  FLAVOUR_FLAG_PHRASE,
  CONTRACEPTIVE_EXCEPTIONS,
  EndorsmentCodes,
} from '@pushdr/prescription/api';
import { PrescriptionInputDataState, PrescriptionInputData } from '../models/PrescriptionInputData';

@Component({
  selector: 'pushdr-prescription-form',
  templateUrl: './prescription-form.component.html',
  styleUrls: ['./prescription-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrescriptionFormComponent implements OnInit, OnDestroy {
  @Input() inputData: PrescriptionInputData = null;
  @Input() isGeneric = false;
  @Input() medication: PharmaceuticalsMedicationDetails;
  @Output() formChanged = new EventEmitter<PrescriptionInputDataState>();
  changeSubscription: Subscription;

  subscription: Subscription;
  prescriptionForm: UntypedFormGroup;

  endorsementsVocabulary = [];
  medicationEndorsementsCodes: string[] = [];

  endorsementCodeACBS = EndorsmentCodes.acbsProduct;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit() {
    this.endorsementsVocabulary = ENDORSEMENTS_VOCABULARY.filter(flag => {
      switch (flag.code) {
        case EndorsmentCodes.flavour:
          return this.medication?.medicationName?.indexOf(FLAVOUR_FLAG_PHRASE) > -1;
        case EndorsmentCodes.acbsProduct:
          return !!this.medication?.endorsements?.find(
            f => f.endorsementCode === EndorsmentCodes.acbsProduct
          );
        default:
          return true;
      }
    });

    this.medicationEndorsementsCodes = this.medication.endorsements.map(endorsement =>
      endorsement.endorsementCode.toUpperCase()
    );

    const selectedEnd = this.endorsementsVocabulary.map(
      flag =>
        this.medicationEndorsementsCodes.includes(flag.code) &&
        flag.checked &&
        (flag.code !== EndorsmentCodes.contraceptive ||
          (flag.code === EndorsmentCodes.contraceptive &&
            !CONTRACEPTIVE_EXCEPTIONS.includes(this.medication?.snomedCode)))
    );

    const quantityValidators = [
      Validators.required,
      Validators.min(0.01),
      Validators.max(10000),
      // Allow up to 2 digits after decimal point
      Validators.pattern(/^\d+\.?\d{0,2}$/),
    ];

    this.prescriptionForm = this.fb.group({
      dosage: ['', Validators.compose([Validators.required, Validators.maxLength(500)])],
      duration: [
        null,
        Validators.compose([Validators.min(0), Validators.max(183), Validators.pattern(/^\d+$/)]),
      ],
      quantity: [null, Validators.compose(quantityValidators)],
      endorsements: this.fb.array(selectedEnd),
      pharmacyNote: [''],
      unitOfMeasure: [this.medication?.unitsOfMeasure[0]?.unitTypeCode],
    });

    this.changeSubscription = this.prescriptionForm.valueChanges.subscribe(value => {
      const foundUnitOfMeasure = this.medication.unitsOfMeasure.find(
        s => s.unitTypeCode === value.unitOfMeasure
      );
      const event = <PrescriptionInputDataState>{
        valid: this.prescriptionForm.valid,
        form: <PrescriptionInputData>{
          ...value,
          // replace checked flags with their codes
          endorsements: value.endorsements
            .map((flag, i) => (flag ? this.endorsementsVocabulary[i].code : false))
            .filter(f => f),
          medicationName: this.medication.medicationName,
          medicationCode: this.medication.snomedCode,
          unitOfMeasure: foundUnitOfMeasure?.unitTypeDescription,
          controlledScheduleNumber: this.medication.controlledScheduleNumber,
        },
      };
      this.formChanged.emit(event);
    });
    if (this.inputData) {
      this.populateInputData();
    }
  }

  ngOnDestroy() {
    this.changeSubscription.unsubscribe();
  }

  getEndorsementsFormArray() {
    return this.prescriptionForm.get('endorsements') as UntypedFormArray;
  }

  private async populateInputData() {
    // Waits for next tick to trigger the formChanged event outside of ngOnInit.
    await Promise.resolve();
    const endorsements = this.endorsementsVocabulary.map(item =>
      this.inputData.endorsements.includes(item.code)
    );
    this.prescriptionForm.reset({
      dosage: this.inputData?.dosage,
      duration: this.inputData?.duration,
      quantity: this.inputData?.quantity,
      pharmacyNote: this.inputData?.pharmacyNote,
      unitOfMeasure: this.medication?.unitsOfMeasure[0]?.unitTypeCode,
      endorsements,
    });
  }
}
