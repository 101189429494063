import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { PrescribedMedication, PrescriptionStatus } from '@pushdr/common/types';

@Component({
  selector: 'pushdr-medication-list-item',
  templateUrl: './medication-list-item.component.html',
  styleUrls: ['./medication-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MedicationListItemComponent {
  PrescriptionStatus = PrescriptionStatus;

  @Input() medication: PrescribedMedication = null;

  expanded = false;

  constructor(private changeDetector: ChangeDetectorRef) {}

  toggleDetails(): void {
    this.expanded = !this.expanded;
    this.changeDetector.markForCheck();
  }
}
