import { Component, OnInit } from '@angular/core';
import { AbstractModal } from '@pushdr/common/overlay';

export interface ImageModal {
  base64Image: string;
  receivedTime: string;
}

@Component({
  selector: 'pushdr-clinicians-iframe-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss'],
})
export class CliniciansImageModalComponent extends AbstractModal<ImageModal> implements OnInit {
  imageUrl = '';
  receivedTime = '';

  constructor() {
    super();
  }

  ngOnInit() {
    this.imageUrl = this.data.base64Image;
    this.receivedTime = this.data.receivedTime;
  }

  output() {
    this.close();
  }
}
