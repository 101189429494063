<div
  class="p-4 cursor-pointer border-b-2 border-grey-100"
  (click)="toggleDetails()"
  [ngClass]="{ 'bg-grey-100': expanded, 'hover:bg-indigo-100': !expanded }"
  *ngIf="medication">
  <div class="flex pb-3 flex-row justify-between">
    <div class="flex flex-col flex-1">
      <div class="flex flex-row">
        <h4 class="mr-4 font-normal text-base flex items-center">
          <span class="mt-1">{{ medication.medicationName }}</span>
          <div class="flex items-center leading-none">
            <a
              *ngIf="
                medication.statement &&
                medication.patientNotes &&
                medication.patientNotes.length > 0
              "
              class="menu-item ml-2"
              style="line-height: 0">
              <span class="material-icons text-bluegrey-600 pt-1 text-base">comment</span>
              <pushdr-medication-notes
                [notes]="medication.statement.note"
                class="sub-menu"></pushdr-medication-notes>
            </a>
            <a
              *ngIf="medication.isPd"
              class="menu-item rounded text-orange-500 bg-orange-300 p-1 px-3 ml-2">
              <span class="text-sm whitespace-nowrap">PD ℞</span>
              <pushdr-medication-pd-note class="sub-menu"></pushdr-medication-pd-note>
            </a>
          </div>
        </h4>
      </div>

      <div class="flex flex-row text-sm gap-3">
        <div
          *ngIf="
            medication.statement &&
            (medication.type === 'repeat' || medication.type === 'repeat-dispensing')
          ">
          <p class="font-light text-grey-700 mb-0">Last Issued</p>
          <p class="mb-0 text-base">
            {{
              (
                medication.statement.extension
                | findArrayItem: 'url':'MedicationStatementLastIssueDate'
              )?.valueDateTime | isoDate: 'DD-MM-YYYY':'Not Issued'
            }}
          </p>
        </div>

        <div>
          <p class="font-light text-grey-700 mb-0">Start Date</p>
          <p class="mb-0 text-base">{{ medication.startDate | isoDate: 'DD-MM-YYYY' }}</p>
        </div>

        <ng-container
          *ngIf="medication.type === 'acute' || medication.type === 'acute-handwritten'">
          <div *ngIf="medication.daysDuration">
            <p class="font-light text-grey-700 mb-0">Duration</p>
            <p class="mb-0 text-base">
              {{ medication.daysDuration }} {{ medication.durationUnit || 'day(s)' }}
            </p>
          </div>

          <div>
            <p class="font-light text-grey-700 mb-0">End Date</p>
            <p class="mb-0 text-base">{{ medication.endDate | isoDate: 'DD MMM YYYY' }}</p>
          </div>
        </ng-container>

        <ng-container
          *ngIf="
            medication.requests &&
            (medication.type === 'repeat' || medication.type === 'repeat-dispensing')
          ">
          <div
            *ngIf="
              (medication.requests | findArrayItem: 'intent':'plan')?.extension
                | findArrayItem: 'url':'MedicationRepeatInformation' as repeatInfo
            "
            class="w-1/4">
            <p class="font-light text-grey-700 mb-0">Issued</p>
            <p class="mb-0 text-base">
              <span>{{
                (
                  repeatInfo.extension | findArrayItem: 'url':'numberOfRepeatPrescriptionsIssued'
                )?.valuePositiveInt?.toString() ||
                  (
                    repeatInfo.extension | findArrayItem: 'url':'numberOfRepeatPrescriptionsIssued'
                  )?.valueUnsignedInt?.toString()
              }}</span>
              <span
                *ngIf="
                  (
                    repeatInfo.extension | findArrayItem: 'url':'numberOfRepeatPrescriptionsAllowed'
                  )?.valuePositiveInt?.toString() ||
                  (
                    repeatInfo.extension | findArrayItem: 'url':'numberOfRepeatPrescriptionsAllowed'
                  )?.valueUnsignedInt?.toString()
                ">
                of
                {{
                  (
                    repeatInfo.extension | findArrayItem: 'url':'numberOfRepeatPrescriptionsAllowed'
                  )?.valuePositiveInt?.toString() ||
                    (
                      repeatInfo.extension
                      | findArrayItem: 'url':'numberOfRepeatPrescriptionsAllowed'
                    )?.valueUnsignedInt?.toString()
                }}
              </span>
            </p>
          </div>
          <div class="w-1/4">
            <p class="font-light text-grey-700 mb-0">Review Date</p>
            <p class="mb-0 text-base">
              {{
                (
                  (
                    (medication.requests | findArrayItem: 'intent':'plan').extension
                    | findArrayItem: 'url':'MedicationRepeatInformation'
                  )?.extension | findArrayItem: 'url':'authorisationExpiryDate'
                )?.valueDateTime | isoDate: 'DD MMM YYYY':'N/a'
              }}
            </p>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="flex-none self-center">
      <i
        class="material-icons transform text-4xl"
        style="width: 50px"
        [ngClass]="{ 'text-grey-600': !expanded }"
        >{{ expanded ? 'expand_less' : 'expand_more' }}</i
      >
    </div>
  </div>

  <div *ngIf="expanded">
    <div class="flex mt-3 mb-3">
      <ng-container *ngIf="medication.quantity && medication.quantity > 0">
        <div class="w-1/2">
          <p class="font-light text-grey-700 mb-0">Quantity / instruction</p>
          <p class="mb-0 text-base">
            {{ medication.quantity }}
            {{ medication.unitTypeDescription }}
            {{ ' - ' + (medication.dosage || 'N/a') }}
          </p>
        </div>

        <div class="w-1/2">
          <p class="font-light text-grey-700 mb-0">Patient instruction</p>
          <p class="mb-0 text-base">{{ medication.patientNotes || 'N/a' }}</p>
        </div>
      </ng-container>
    </div>

    <div class="flex mb-3">
      <div class="w-1/4" *ngIf="medication.statement">
        <p class="font-light text-grey-700 mb-0">Date Recorded</p>
        <p class="mb-0 text-base">
          {{ medication.statement.dateAsserted | isoDate: 'DD MMM YYYY':'N/a' }}
        </p>
      </div>

      <ng-container *ngIf="medication.requests && medication.medicationStatus === 'stopped'">
        <div class="w-1/4">
          <p class="font-light text-grey-700 mb-0">Stopped reason</p>
          <p class="mb-0 text-base">
            {{
              (
                (
                  (medication.requests | findArrayItem: 'intent':'plan')?.extension
                  | findArrayItem: 'url':'MedicationStatusReason'
                ).extension | findArrayItem: 'url':'statusReason'
              )?.valueCodeableConcept | getCodingText: 'N/a'
            }}
          </p>
        </div>
      </ng-container>

      <ng-container *ngIf="medication.requests && medication.statement.status !== 'stopped'">
        <div class="w-1/4">
          <p class="font-light text-grey-700 mb-0">Reason</p>
          <p class="mb-0 text-base">
            {{
              (
                (
                  (medication.requests | findArrayItem: 'intent':'plan')?.extension
                  | findArrayItem: 'url':'MedicationStatusReason'
                ).extension | findArrayItem: 'url':'statusReason'
              )?.valueCodeableConcept | getCodingText: 'N/a'
            }}
          </p>
        </div>
      </ng-container>

      <div class="w-1/4" *ngIf="medication.statement">
        <p class="font-light text-grey-700 mb-0">Dosage last changed</p>
        <p class="mb-0 text-base">
          {{
            (medication.statement.extension | findArrayItem: 'url':'dosageLastChanged')
              ?.valueDateTime | isoDate: 'DD MMM YYYY':'N/a'
          }}
        </p>
      </div>
    </div>
    <pushdr-medication-issues
      *ngIf="medication.requests && medication.requests.length > 1"
      [issues]="medication.requests"
      [medicationType]="medication.type"
      [issuesAllowed]="
        (
          (
            (medication.requests | findArrayItem: 'intent':'plan')?.extension
            | findArrayItem: 'url':'MedicationRepeatInformation'
          ).extension | findArrayItem: 'url':'numberOfRepeatPrescriptionsAllowed'
        )?.valuePositiveInt ||
        (
          (
            (medication.requests | findArrayItem: 'intent':'plan')?.extension
            | findArrayItem: 'url':'MedicationRepeatInformation'
          ).extension | findArrayItem: 'url':'numberOfRepeatPrescriptionsAllowed'
        )?.valueUnsignedInt
      ">
    </pushdr-medication-issues>
  </div>
</div>
