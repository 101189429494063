import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { PatientMedicationGroup, PatientStructuredRecords } from '@pushdr/common/types';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, publishReplay, refCount, tap } from 'rxjs/operators';
import { MedicationsService } from '../../../services/patient-records/medications.service';
import { PatientRecordsService } from '../../../services/patient-records/patient-records.service';

enum MedicationViewType {
  past = 'Past',
  current = 'Current',
}

@Component({
  selector: 'pushdr-patient-structured-medication',
  templateUrl: './patient-structured-medication.component.html',
  styleUrls: ['./patient-structured-medication.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientStructuredMedicationComponent implements OnInit {
  currentView: MedicationViewType = MedicationViewType.current;
  activeMedicationGroups$: Observable<PatientMedicationGroup[]>;
  pastMedicationGroups$: Observable<PatientMedicationGroup[]>;
  patientRecord$: Observable<PatientStructuredRecords>;
  patientWarning$: Observable<string>;
  pageSize$: { [key in MedicationViewType]: BehaviorSubject<number> } = {
    Past: new BehaviorSubject(20),
    Current: new BehaviorSubject(20),
  };
  activeCount = 0;
  pastCount = 0;

  medicationViewType = MedicationViewType;

  constructor(
    private patientRecordsService: PatientRecordsService,
    private medicationsService: MedicationsService
  ) {}

  ngOnInit() {
    this.patientRecord$ = this.patientRecordsService
      .getStructuredPatientRecord()
      .pipe(publishReplay(1), refCount());

    this.setupMedicationTypesObs();
    // this.setupBannerWarningsObs();
  }

  setupBannerWarningsObs() {
    this.patientWarning$ = this.patientRecord$.pipe(map(record => record.patientWarning));
  }

  setupMedicationTypesObs() {
    const medicationInformationActive$ = this.medicationsService
      .getAcuteMedicationsPagedActive$(this.pageSize$[MedicationViewType.current], of(1))
      .pipe(tap(x => (this.activeCount = x.itemsCount)));
    const medicationInformationPast$ = this.medicationsService
      .getAcuteMedicationsPagedPast$(this.pageSize$[MedicationViewType.past], of(1))
      .pipe(tap(x => (this.pastCount = x.itemsCount)));

    this.activeMedicationGroups$ = medicationInformationActive$.pipe(
      map(medications => {
        const acute: PatientMedicationGroup = {
          groupName: 'Acute',
          medications: medications.items.filter(
            med => med.type === 'acute' || med.type === 'acute-handwritten'
          ),
        };
        const repeat: PatientMedicationGroup = {
          groupName: 'Repeat',
          medications: medications.items.filter(med => med.type === 'repeat'),
        };
        const repeatDisp: PatientMedicationGroup = {
          groupName: 'Repeat Dispensing',
          medications: medications.items.filter(med => med.type === 'repeat-dispensing'),
        };
        return [acute, repeat, repeatDisp];
      })
    );

    this.pastMedicationGroups$ = medicationInformationPast$.pipe(
      map(medications => {
        const stopped: PatientMedicationGroup = {
          groupName: 'Stopped',
          medications: medications.items.filter(med => med.medicationStatus === 'stopped'),
        };
        const acute: PatientMedicationGroup = {
          groupName: 'Completed Acute',
          medications: medications.items.filter(
            med => med.type === 'acute' || med.type === 'acute-handwritten'
          ),
        };
        const repeat: PatientMedicationGroup = {
          groupName: 'Completed Repeat',
          medications: medications.items.filter(med => med.type === 'repeat'),
        };
        const repeatDisp: PatientMedicationGroup = {
          groupName: 'Completed Repeat Dispensing',
          medications: medications.items.filter(med => med.type === 'repeat-dispensing'),
        };
        return [stopped, acute, repeat, repeatDisp];
      })
    );
  }

  setView(type: MedicationViewType) {
    this.currentView = type;
  }

  onScrolledToEnd() {
    (p => p.next(p.value + 20))(this.pageSize$[this.currentView]);
  }

  trackByGroupName(index: number, group: PatientMedicationGroup): string {
    return group.groupName;
  }
}
