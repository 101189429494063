import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { SnomedData } from '@pushdr/common/types';

@Component({
  selector: 'pushdr-allergies-list',
  templateUrl: './allergies-list.component.html',
  styleUrls: ['./allergies-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AllergiesListComponent {
  @Input() allergies: any[];
  @Input() cdsWarnings: SnomedData[] = [];
  @Output() viewAllergyDetails: EventEmitter<any> = new EventEmitter();

  shouldShowIcon(allergy) {
    return (
      this.cdsWarnings &&
      this.cdsWarnings.find(snomedData => {
        const code =
          allergy && allergy.code && allergy.code.coding[0] && allergy.code.coding[0].code;
        return snomedData.snomedCode === code;
      })
    );
  }
}
