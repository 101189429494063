import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ClinicianType } from '@pushdr/common/types';
import { CliniciansDoctorsDetailsService } from '@pushdr/clinicians/common';

@Injectable({
  providedIn: 'root',
})
export class ConsultationRoleService {
  constructor(private doctorDetails: CliniciansDoctorsDetailsService) {}

  get role$(): Observable<ClinicianType> {
    return this.doctorDetails.details$.pipe(
      map(details => details?.clinicianType ?? ClinicianType.Unknown)
    );
  }

  hasRole(role: ClinicianType): Observable<boolean>;
  hasRole(roles: ClinicianType[]): Observable<boolean>;
  hasRole(roleOrRoles: ClinicianType | ClinicianType[]): Observable<boolean> {
    const roles = [].concat(roleOrRoles);
    return this.role$.pipe(map(role => roles.includes(role)));
  }
}
