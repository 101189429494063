import { Component } from '@angular/core';
import { ModalService } from '@pushdr/common/overlay';
import { CliniciansIframeModalComponent } from '../../iframe-modal';
import {
  ApiDoctorsConsultation as ApiCliniciansConsultation,
  ClinicianConsultationStatus,
} from '@pushdr/doctors/data-access/doctors-api';
import { CliniciansAuthService } from '../../../services/clinician-auth/clinician-auth.service';

@Component({
  selector: 'pushdr-profile-sub-menu',
  templateUrl: './profile-sub-menu.component.html',
  styleUrls: ['./profile-sub-menu.component.scss'],
})
export class ProfileSubMenuComponent {
  constructor(
    private authService: CliniciansAuthService,
    private modal: ModalService,
    private clinicianConsultationStatus: ApiCliniciansConsultation
  ) {}

  logOut() {
    this.modal
      .confirm('Log out', 'Are you sure you want to log out?', 'Log out')
      .subscribe(signOut => {
        if (signOut) {
          this.clinicianConsultationStatus
            .updateStatus(ClinicianConsultationStatus.UNAVAILABLE)
            .toPromise()
            .then(() => this.authService.logout());
        }
      });
  }

  openTermsModal() {
    return this.modal.showCustom(CliniciansIframeModalComponent, {
      title: 'Terms and conditions',
      linkToDocument: 'https://www.pushdoctor.co.uk/doctor/terms',
    });
  }
}
