import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RefreshOnRedirectGuard implements CanDeactivate<unknown> {
  private window = this.document.defaultView;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): boolean {
    if (nextState?.url) {
      this.window.location.href = nextState.url;
      return false;
    }

    return true;
  }
}
