import { Component } from '@angular/core';
import { AbstractModal } from '../abstract-modal';

interface GenericModalButton {
  id: string;
  caption: string;
  type?: string;
}

export interface GenericModalParams {
  header?: string;
  message?: string;
  htmlBody?: string;
  className?: string;
  closeButton?: boolean;
  confirmationButton?: boolean;
  buttons?: Array<GenericModalButton>;
  bottomText?: string;
  enableBackgroundClicks?: boolean;
  longModal?: boolean;
}

@Component({
  selector: 'pushdr-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: [],
})
export class GenericModalComponent extends AbstractModal<GenericModalParams> {}
