import { Component, DoCheck, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'pushdr-input-with-icon',
  templateUrl: './input-with-icon.component.html',
  styleUrls: ['./input-with-icon.component.scss'],
})
export class InputWithIconComponent implements DoCheck {
  @ViewChild('inputWrapperDiv', { static: true }) inputWrapper: ElementRef;

  isDateInput = false;

  ngDoCheck() {
    this.isDateInput = !!this.inputWrapper.nativeElement.querySelector('input[type="date"]');
  }

  focusInput() {
    this.inputWrapper.nativeElement.querySelector('input').focus();
  }
}
