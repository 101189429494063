<ng-container
  *ngIf="{
    activeGroups: activeMedicationGroups$ | async,
    pastGroups: pastMedicationGroups$ | async,
    patientWarning: patientWarning$ | async
  } as data">
  <pushdr-consultation-card
    [hasData]="!!(data.activeGroups && data.pastGroups)"
    (scrolledToEnd)="onScrolledToEnd()">
    <div header>
      <span>Medications</span>
      <div class="flex justify-center -mb-3 text-base font-normal text-grey-700">
        <a
          class="pb-1 mr-5 border-teal-500 cursor-pointer hover:border-b-4"
          [ngClass]="{ 'border-b-4 text-bluegrey-800': currentView === 'Current' }"
          (click)="setView(medicationViewType.current)"
          >Current ({{ activeCount }})</a
        >
        <a
          class="pb-1 ml-5 border-teal-500 cursor-pointer hover:border-b-4"
          [ngClass]="{ 'border-b-4 text-bluegrey-800': currentView === 'Past' }"
          (click)="setView(medicationViewType.past)"
          >Past ({{ pastCount }})</a
        >
      </div>
    </div>
    <ng-container body>
      <pushdr-patient-warning
        class="-mt-4 -ml-4 -mr-4"
        [warning]="data.patientWarning"
        *ngIf="data.patientWarning">
      </pushdr-patient-warning>
      <div
        class="-m-4"
        *ngIf="
          currentView === 'Current'
            ? data.activeGroups
            : currentView === 'Past'
            ? data.pastGroups
            : [] as currentGroups
        ">
        <pushdr-medication-group
          *ngFor="let currentMedGroup of currentGroups; trackBy: trackByGroupName"
          [medicationGroup]="currentMedGroup">
        </pushdr-medication-group>
      </div>
    </ng-container>
  </pushdr-consultation-card>
</ng-container>
