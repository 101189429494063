import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrescriptionShellRoutingModule } from './prescription-shell-routing.module';
import { AccumulatedWarningsComponent } from './accumulated-warnings/accumulated-warnings.component';
import { MedicationInfoComponent } from './medication-info/medication-info.component';
import { OverrideWarningComponent } from './components/override-warning/override-warning.component';
import { PatientNotesComponent } from './components/patient-notes/patient-notes.component';
import { PrescriptionCardHeaderComponent } from './components/prescription-card-header/prescription-card-header.component';
import { ReviewPrescriptionComponent } from './review-prescription/review-prescription.component';
import { SelectDispenserComponent } from './select-dispenser/select-dispenser.component';
import { SelectMedicationComponent } from './select-medication/select-medication.component';
import { SetPatientNotesComponent } from './set-patient-notes/set-patient-notes.component';
import { SignPrescriptionComponent } from './sign-prescription/sign-prescription.component';
import { SpecifyMedicationComponent } from './specify-medication/specify-medication.component';
import { CliniciansCommonModule } from '@pushdr/clinicians/common';
import { CommonComponentsModule } from '@pushdr/common/components';
import { CommonPipesModule } from '@pushdr/common/utils';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { PrescriptionDispenserModule } from '@pushdr/prescription/dispenser';
import { PrescriptionFdbModule } from '@pushdr/prescription/fdb';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    PrescriptionShellRoutingModule,
    CliniciansCommonModule,
    CommonPipesModule,
    RouterModule,
    PrescriptionFdbModule,
    CommonComponentsModule,
    NgSelectModule,
    ReactiveFormsModule,
    FormsModule,
    PrescriptionDispenserModule,
  ],
  declarations: [
    AccumulatedWarningsComponent,
    MedicationInfoComponent,
    ReviewPrescriptionComponent,
    SelectMedicationComponent,
    SetPatientNotesComponent,
    SignPrescriptionComponent,
    SpecifyMedicationComponent,
    OverrideWarningComponent,
    PatientNotesComponent,
    PrescriptionCardHeaderComponent,
    SelectDispenserComponent,
  ],
})
export class PrescriptionShellModule {}
