import { Component } from '@angular/core';
import { TopbarLinkedStep } from '@pushdr/clinicians/common';

@Component({
  selector: 'pushdr-consultation-setup-progress',
  templateUrl: './consultation-setup-progress.component.html',
  styleUrls: ['./consultation-setup-progress.component.scss'],
})
export class ConsultationSetupProgressComponent {
  progressSteps: TopbarLinkedStep[] = [
    { title: 'Tech checks', path: '/consultation/tech-checks' },
    { title: 'Waiting room', path: '/consultation/waiting-room' },
    { title: 'Meet patient', path: '/consultation/meet-patient' },
    { title: 'Identification', path: '/consultation/patient/id' },
    { title: 'See records', path: '/consultation/patient/consult' },
    { title: 'Consultation summary', path: '/consultation/summary' },
  ];
}
