import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbstractModal } from '@pushdr/common/overlay';
import { StorageService } from '@pushdr/common/utils';
import {
  ConsultationState,
  ConsultationStateService,
} from '../../../../services/consultation-state/consultation-state.service';
import { apolloHtmlData } from './data/apolloHtmlData';
import { mockStructured } from './data/apolloStructuredData';
import { notesPdf } from './data/largePdf';
import { pdsCudmore } from './data/consultationDetailsWithNhs';
import { fitNotPdfData } from './data/fitNotePdfData';
import { prescription, prescriptionDispenser } from './data/prescription';
import { getSaml, smartCardResponse, ticketResponse } from './data/smartCardResponse';
import { CardToken } from '@pushdr/doctors/data-access/doctors-api';
import * as _ from 'lodash';
import { CurrentOrderIdService } from '@pushdr/common/data-access/analytics';

@Component({
  selector: 'pushdr-dev-tools',
  templateUrl: './dev-tools.component.html',
  styleUrls: ['./dev-tools.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevToolsComponent extends AbstractModal<any, boolean> implements OnInit {
  mocks: any;
  featureOverrides: any;

  mocks1Buttons = [
    this.privateUser,
    this.apolloUser,
    this.notApolloUser,
    this.apolloUserIsSensitive,
    this.apolloUserIsNotSensitive,
    this.patientIsDead,
    this.addFakeNhsNoAndDob,
    this.structuredApolloData,
    this.htmlApolloData,
    this.addFitNoteAndReferralLetterIds,
    this.fakeFitNotePdfs,
    this.fakeReferralLetterPdfs,
    this.smartCardOdsCode,
    this.smartCardOdsCodeWhitehallSurgery,
    this.smartCardOnlyView,
  ];

  mocks2Buttons = [
    // this.smartCardOdsCode,
    // this.smartCardOdsCodeWhitehallSurgery,
    // this.smartCardOnlyView,
    this.fullSmartMock,
    this.fullSmartMockNotR8000Selected,
    this.fullSmartMockR8000SelectedDH6,
    this.fullSmartMockNotR8000SelectedDH6,

    this.patientDbPracticeToB86071,
    this.mockRbac,
    this.isEpsEnabled,
    this.isEpsDisabled,
    this.removeSignatureFromPrescriptionIssue,
    this.invalidatePdsResponse,
  ];

  featureButtons = [this.surgeryMustMatch];

  altEndpoints: { label: string; path: string[]; enabled?: boolean; newValue?: string }[] = [
    {
      label: 'Prescriptions API',
      path: ['doctors', 'prescriptionAPI'],
      newValue: 'https://localhost:5001/v1',
    },
    { label: 'Doctors API', path: ['doctors', 'api'], newValue: 'http://localhost:3929' },
  ];
  reroutes: { fromUrl: string; toUrl: string; enabled: boolean }[];

  constructor(
    private storage: StorageService,
    private consultationState: ConsultationStateService,
    private order: CurrentOrderIdService
  ) {
    super();
  }

  ngOnInit() {
    this.mocks = this.storage.getLocalStorage('ApiMocks', true);
    this.featureOverrides = this.storage.getLocalStorage('FeatureOverrides', true);
    if (!(this.mocks instanceof Object)) this.mocks = {};
    if (!(this.featureOverrides instanceof Object)) this.featureOverrides = {};
    this.loadAltApis();
    this.loadReroutes();
  }

  private loadAltApis() {
    const apiAlts = this.storage.getLocalStorage('ApiAlts', true);
    if (!apiAlts || !apiAlts.find) return;
    this.altEndpoints.forEach(x => {
      const match = apiAlts.find(a => a.label === x.label);
      if (match) {
        x.newValue = match.newValue;
        x.enabled = match.enabled;
      }
    });
  }

  private loadReroutes() {
    this.reroutes = this.storage.getLocalStorage('ApiReroutes', true) || [];
    if (!this.reroutes || !this.reroutes.length) {
      this.reroutes = [
        {
          fromUrl:
            'https://sit-waf.internal.pushsvcs.com/doctor/consultation.svc/consultationW/EndSession',
          toUrl: 'http://localhost:3929/consultation.svc/consultationW/EndSession',
          enabled: false,
        },
        {
          fromUrl:
            'https://sit-waf.internal.pushsvcs.com/doctor/consultationnotes.svc/consultationnotesW/',
          toUrl: 'http://localhost:3929/consultationnotes.svc/consultationnotesW/',
          enabled: false,
        },
        {
          fromUrl:
            'https://sit-waf.internal.pushsvcs.com/doctor/consultation.svc/consultationW/GetConsultationDetails',
          toUrl: 'http://localhost:3929/consultation.svc/consultationW/GetConsultationDetails',
          enabled: false,
        },
        {
          fromUrl: 'https://sit-waf.internal.pushsvcs.com/rbac/api',
          toUrl: 'http://localhost:7071/api',
          enabled: false,
        },
        {
          fromUrl: 'https://sit-waf.internal.pushsvcs.com/prescription/v1',
          toUrl: 'https://localhost:5001/v1',
          enabled: false,
        },
      ];
    }
  }

  addReroute() {
    this.reroutes.push({ fromUrl: '', toUrl: '', enabled: true });
  }

  saveReroute() {
    this.storage.setLocalStorage('ApiReroutes', this.reroutes);
  }

  deleteReroute(reroute: any) {
    const delIdx = this.reroutes.findIndex(f => f === reroute);
    this.reroutes.splice(delIdx, 1);
  }

  saveAltApis() {
    const apiAlts = this.altEndpoints.filter(f => f.enabled);
    this.storage.setLocalStorage('ApiAlts', apiAlts);
  }

  reload() {
    window.location.reload();
  }

  gotoConsultation() {
    this.consultationState.state = ConsultationState.CONSULTING;
    window.location.href = '/consultation/patient/consult/default';
  }

  skipChecks() {
    window.location.href = '/consultation/waiting-room/welcome';
  }

  addPrescriptionItems() {
    this.storage.setSessionStorage('prescriptionOrderId', this.order.id);
    this.storage.setSessionStorage('prescriptionItems', prescription);
    this.storage.setSessionStorage('prescriptionDispenser', prescriptionDispenser);
  }

  // Mock Functions

  get previewNotes() {
    return this.mockSwitch('previewNotes', '/api/v2/report/preview', 'Mock preview notes', {
      pdfBase64: notesPdf,
    });
  }

  get privateUser() {
    return this.mockSwitch('privateUser', '/GetConsultationDetails', 'Mock as private user', {
      GetConsultationDetailsResult: { Partner: { PartnerType: 3 } },
    });
  }

  get apolloUser() {
    return this.mockSwitch(
      'apolloUser',
      '/consultation.svc/consultationW/GetConsultationDetails',
      'Apollo user',
      pdsCudmore
    );
  }

  get patientDbPracticeToB86071() {
    return this.mockSwitch(
      'patientDbPracticeToB86071',
      '/consultation.svc/consultationW/GetConsultationDetails',
      'DB Surgery ODS to B86071',
      {
        GetConsultationDetailsResult: {
          Patient: {
            GpSummary: {
              OdsCode: 'B86071',
            },
          },
        },
      }
    );
  }

  get notApolloUser() {
    return this.mockSwitch(
      'notApolloUser',
      '/consultation.svc/consultationW/GetConsultationDetails',
      'Not Apollo user',
      {
        GetConsultationDetailsResult: {
          Patient: {
            NhsInformation: {
              ErrorResponse: null,
              HttpStatusCode: 404,
              SuccessResponse: null,
            },
          },
        },
      }
    );
  }

  get apolloUserIsSensitive() {
    return this.mockSwitch(
      'apolloUserIsSensitive',
      '/consultation.svc/consultationW/GetConsultationDetails',
      'Sensitive user',
      {
        GetConsultationDetailsResult: {
          Patient: { NhsInformation: { SuccessResponse: { SensitivePatient: true } } },
        },
      }
    );
  }

  get apolloUserIsNotSensitive() {
    return this.mockSwitch(
      'apolloUserIsNotSensitive',
      '/consultation.svc/consultationW/GetConsultationDetails',
      'Mock as not sensitive user',
      {
        GetConsultationDetailsResult: {
          Patient: { NhsInformation: { SuccessResponse: { SensitivePatient: false } } },
        },
      }
    );
  }

  get patientIsDead() {
    return this.mockSwitch(
      'patientIsDead',
      '/consultation.svc/consultationW/GetConsultationDetails',
      '&#128123; Kill patient!',
      {
        GetConsultationDetailsResult: {
          Patient: {
            NhsInformation: {
              ErrorResponse: null,
              HttpStatusCode: 200,
              SuccessResponse: {
                DateOfBirth: '2015-04-16',
                DateOfDeath: '2015-04-16',
              },
            },
          },
        },
      }
    );
  }

  get isEpsEnabled() {
    return this.mockSwitch(
      'isEpsEnabled',
      '/consultation.svc/consultationW/GetConsultationDetails',
      'EPS Enabled',
      {
        GetConsultationDetailsResult: {
          Partner: { EpsEnabled: true },
        },
      }
    );
  }

  get isEpsDisabled() {
    return this.mockSwitch(
      'isEpsDisabled',
      '/consultation.svc/consultationW/GetConsultationDetails',
      'EPS Disabled',
      {
        GetConsultationDetailsResult: {
          Partner: { EpsEnabled: false },
        },
      }
    );
  }

  get addFakeNhsNoAndDob() {
    return this.mockSwitch(
      'addFakeNhsNoAndDob',
      '/consultation.svc/consultationW/GetConsultationDetails',
      'Fake NHS no + DOB for Create Prescription',
      {
        GetConsultationDetailsResult: {
          Patient: {
            Age: 999,
            DateOfBirth: '1963-04-15',
            NhsInformation: {
              SuccessResponse: {
                DateOfBirth: '1963-04-15',
                NhsNumber: '9689151681',
              },
            },
          },
        },
      }
    );
  }

  get smartCardOdsCode() {
    return this.mockSwitch(
      'smartCardOdsCode',
      '/processsaml',
      'Smart Card to "Woodlands Road Surgery"',
      {
        sessions: [
          { orgCode: 'A81004' },
          { orgCode: 'A81004' },
          { orgCode: 'A81004' },
          {
            sessionId: '555030370104',
            orgCode: 'DH6',
            nhsJobRoleCode: 'S0010:G0020:R0260',
            active: true,
            role: 'R8000',
            permissions: [
              'prescription/sign',
              'prescription/create',
              'prescription/cancel',
              'prescription/view',
            ],
          },
        ],
      }
    );
  }

  get smartCardOdsCodeWhitehallSurgery() {
    return this.mockSwitch(
      'smartCardOdsCodeWhitehallSurgery',
      '/processsaml',
      'Smart Card to "Whitehall Surgery"',
      {
        sessions: [{ orgCode: 'B86071' }, { orgCode: 'B86071' }, { orgCode: 'B86071' }],
      }
    );
  }

  get smartCardOnlyView() {
    return this.mockSwitch(
      'smartCardOnlyView',
      '/processsaml',
      'Smart Card View Prescription Only',
      {
        sessions: [
          { orgCode: '000' },
          { orgCode: '000' },
          { orgCode: '000' },
          {
            sessionId: '555030370104',
            orgCode: 'DH6',
            nhsJobRoleCode: 'S0010:G0020:R0260',
            active: true,
            role: 'R8000',
            permissions: ['prescription/view'],
          },
        ],
      }
    );
  }

  get mockRbac() {
    return this.mockSwitch('mockRbac', '/processsaml', 'RBAC Response', smartCardResponse, true);
  }

  get fullSmartMock() {
    const selectedId = '555258017111';
    const ticket = <CardToken>_.cloneDeep(ticketResponse);
    ticket.saml = getSaml(selectedId, [
      { role: 'R8000', sessionId: selectedId, ods: 'B86071' },
      { role: 'R8000', sessionId: '555258017109', ods: 'B86071' },
      { role: 'R2224', sessionId: '555258017101', ods: 'B86071' },
    ]);
    return this.mockSwitch(
      'fullSmartMock',
      '/ticket',
      'Full smart card R8000 selected (B86071)',
      ticket,
      true
    );
  }

  get fullSmartMockNotR8000Selected() {
    const selectedId = '555258017111';
    const ticket = <CardToken>_.cloneDeep(ticketResponse);
    ticket.saml = getSaml(selectedId, [
      { role: 'R8000', sessionId: '555258017108', ods: 'B86071' },
      { role: 'R8000', sessionId: '555258017109', ods: 'B86071' },
      { role: 'R1111', sessionId: selectedId, ods: 'B86071' },
    ]);
    return this.mockSwitch(
      'fullSmartMockNotR8000Selected',
      '/ticket',
      'Full smart card R8000 not selected (B86071)',
      ticket,
      true
    );
  }

  get fullSmartMockR8000SelectedDH6() {
    const selectedId = '555258017111';
    const ticket = <CardToken>_.cloneDeep(ticketResponse);
    ticket.saml = getSaml(selectedId, [
      { role: 'R8000', sessionId: selectedId, ods: 'DH6' },
      { role: 'R8000', sessionId: '555258017109', ods: 'DH6' },
      { role: 'R1111', sessionId: '555258017101', ods: 'DH6' },
    ]);
    return this.mockSwitch(
      'fullSmartMockR8000SelectedDH6',
      '/ticket',
      'Full smart card R8000 selected (DH6)',
      ticket,
      true
    );
  }

  get fullSmartMockNotR8000SelectedDH6() {
    const selectedId = '555258017111';
    const ticket = <CardToken>_.cloneDeep(ticketResponse);
    ticket.saml = getSaml(selectedId, [
      { role: 'R8000', sessionId: '555258017108', ods: 'DH6' },
      { role: 'R8000', sessionId: '555258017109', ods: 'DH6' },
      { role: 'R1111', sessionId: selectedId, ods: 'DH6' },
    ]);
    return this.mockSwitch(
      'fullSmartMockNotR8000SelectedDH6',
      '/ticket',
      'Full smart card R8000 not selected (DH6)',
      ticket,
      true
    );
  }

  get structuredApolloData() {
    return this.mockSwitch(
      'structuredApolloData',
      '/records/api/v2/structured/',
      'Mock as apollo structured data',
      mockStructured,
      true
    );
  }

  get htmlApolloData() {
    return this.mockSwitch(
      'htmlApolloData',
      '/records/api/v2/html/',
      'Mock as apollo HTML data',
      apolloHtmlData,
      true
    );
  }

  get addFitNoteAndReferralLetterIds() {
    return this.mockSwitch(
      'addFitNoteAndReferralLetterIds',
      '/consultationnotesprivateW/GetConsultationNoteList',
      'Add ref and fit note Ids to encounters',
      {
        ConsultationNoteList: [
          {
            Diagnosis: { Diagnosis: 'Diagnosis Murder' },
            ReferralLetterIdList: ['234234', '234234'],
            FitNoteIdList: ['234234', '234234'],
          },
          {
            Diagnosis: { Diagnosis: 'Nasty Ooze' },
            ReferralLetterIdList: ['234234'],
            FitNoteIdList: ['234234'],
          },
          { Diagnosis: { Diagnosis: 'Acute Hygienic Arrest ' }, FitNoteIdList: ['234234'] },
          { Diagnosis: { Diagnosis: 'Biscuit Poisoning' }, ReferralLetterIdList: ['234234'] },
        ],
      }
    );
  }

  get fakeFitNotePdfs() {
    return this.mockSwitch(
      'fakeFitNotePdfs',
      '/DownloadFitNotePdf',
      'Fake all fit note PDF downloads',
      {
        DownloadFitNotePdfResult: {
          DocBase64: fitNotPdfData,
        },
      },
      true
    );
  }

  get fakeHasNhsResponse() {
    return this.mockSwitch(
      'fakeHasNhsResponse',
      '/DownloadFitNotePdf',
      'Fake all HTML Apollo data',
      { apolloHtmlData },
      true
    );
  }

  get fakeHtmlDataDownload() {
    return this.mockSwitch(
      'fakeHtmlDataDownload',
      '/DownloadFitNotePdf',
      'Fake all HTML Apollo data',
      apolloHtmlData,
      true
    );
  }

  get fakeReferralLetterPdfs() {
    return this.mockSwitch(
      'fakeReferralLetterPdfs',
      '/DownloadReferralLetterPdf',
      'Fake all referral letter PDF downloads',
      {
        DownloadReferralLetterPdfResult: {
          DocBase64: fitNotPdfData,
        },
      },
      true
    );
  }

  get followUpActionsGetMock() {
    return this.mockSwitch(
      'followUpActionsGetMock',
      '/consultationnotes.svc/consultationnotesW/GetFollowUpActions',
      'Follow up actions get data',
      {
        GetFollowUpActionsResult: {
          FollowUpActions: 'Do something now',
          Urgent: true,
        },
      },
      true
    );
  }

  get removeSignatureFromPrescriptionIssue() {
    return this.mockSwitch(
      'removeSignatureFromPrescriptionIssue',
      '/prescription/v1/issue',
      'Remove signature from prescription issue',
      {},
      false,
      {
        prescription: {
          signature: {
            signatureValue: '',
          },
        },
      }
    );
  }

  get invalidatePdsResponse() {
    return this.mockSwitch(
      'invalidatePdsResponse',
      '/consultation.svc/consultationW/GetConsultationDetails',
      'Invalidate PDS response',
      {
        GetConsultationDetailsResult: {
          Patient: {
            NhsInformation: {
              HttpStatusCode: 404,
              ErrorResponse: {},
              SuccessResponse: null,
            },
          },
        },
      }
    );
  }
  // End Mock Functions

  // Features

  get surgeryMustMatch() {
    return this.featureSwitch('clapp_surgery_must_match_pds', 'Patient Surgery Must Match PDS', {
      isActive: true,
      configuration: { config: { sit: '', local: '' } },
    });
  }
  // End Features

  clearAllMocks() {
    this.storage.setLocalStorage('ApiMocks', null);
    this.storage.setLocalStorage('FeatureOverrides', null);
    this.mocks = {};
    this.featureOverrides = {};
  }

  private mockSwitch(
    name: string,
    url: string,
    buttonText: string,
    patch: any,
    preventApiCall: boolean = false,
    requestPatch?: any
  ) {
    return this.drySwitch(
      name,
      buttonText,
      { dataPatch: patch, requestPatch, preventApiCall, url: url },
      () => this.mocks,
      'ApiMocks'
    );
  }

  private featureSwitch(featureName: string, buttonText: string, overrideVal: any) {
    return this.drySwitch(
      featureName,
      buttonText,
      overrideVal,
      () => this.featureOverrides,
      'FeatureOverrides'
    );
  }

  private drySwitch(
    name: string,
    buttonText: string,
    value: any,
    collection: () => any[],
    storageName: string
  ) {
    return {
      buttonText,
      enabled: () => collection() && collection()[name],
      toggle: () => {
        if (collection()[name]) {
          delete collection()[name];
        } else {
          collection()[name] = value;
        }
        this.storage.setLocalStorage(storageName, collection());
      },
    };
  }
}
