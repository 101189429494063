import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  RestClient,
  RestErrorParserService,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';
import { toCamelCase } from '@pushdr/common/utils';
import { EnvironmentProxyService } from '@pushdr/environment';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

export interface FollowUpActions {
  followUpActions: string;
  urgent: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ApiFollowUpService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.doctors.api + '/consultationnotes.svc/consultationnotesW/';
  }

  getFollowUpActions$(orderId: number): Observable<FollowUpActions> {
    return this.post(
      'GetFollowUpActions',
      { orderId },
      this.headerService.authorisedHeadersLegacy
    ).pipe(pluck('GetFollowUpActionsResult'), toCamelCase());
  }

  saveFollowUpActions$(orderId: number, followUpActions: FollowUpActions): Observable<boolean> {
    return this.post(
      'SaveFollowUpActions',
      { ...followUpActions, orderId },
      this.headerService.authorisedHeadersLegacy
    ).pipe(pluck('SaveFollowUpActionsResult'));
  }
}
