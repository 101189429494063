import { Injectable } from '@angular/core';
import { CustomerType, PatientDetailsV2 } from '@pushdr/common/types';
import {
  CurrentOrderIdService,
  CurrentPartnerIdService,
} from '@pushdr/common/data-access/analytics';
import {
  ApiDoctorsConsultation,
  ExtendedNhsPatientDetails,
  NextPatientConfig,
  NhsPatientDetails,
  PatientAlert,
} from '@pushdr/doctors/data-access/doctors-api';
import { Observable } from 'rxjs';
import { map, publishReplay, refCount, shareReplay, take, tap } from 'rxjs/operators';

export interface PatientDetails extends NhsPatientDetails, ExtendedNhsPatientDetails {}

@Injectable({
  providedIn: 'root',
})
export class PatientDetailsService {
  private _details$: Observable<PatientDetailsV2>;
  private _config$: Observable<NextPatientConfig>;
  private _alerts$: Observable<PatientAlert[]>;

  constructor(
    private api: ApiDoctorsConsultation,
    private order: CurrentOrderIdService,
    private partnerId: CurrentPartnerIdService
  ) {}

  get details$(): Observable<PatientDetailsV2> {
    if (!this._details$) {
      this._details$ = this.api.getConsultationDetails().pipe(
        tap(details => this.idSetUp(details.Surgery.OrderId, details.Partner.Id)),
        shareReplay({ bufferSize: 1, refCount: true })
      );
    }
    return this._details$;
  }

  get patientIsDead$(): Observable<boolean> {
    return this.details$.pipe(
      map(pat => pat.Patient?.NhsInformation?.SuccessResponse?.DateOfDeath != null)
    );
  }

  get patientIsChild$(): Observable<boolean> {
    return this.details$.pipe(map(pat => pat.Patient?.CustomerType === CustomerType.Child));
  }

  get foundPDS$() {
    return this.details$.pipe(
      map(details => details.Patient.NhsInformation.HttpStatusCode === 200)
    );
  }

  get config$() {
    if (!this._config$) {
      this._config$ = this.api
        .getNextPatientConfig()
        .pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }
    return this._config$;
  }

  get alerts$() {
    if (!this._alerts$) {
      this._alerts$ = this.api
        .getPatientAlerts(this.order.id)
        .pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }
    return this._alerts$;
  }

  getCustomerId$() {
    return this.details$.pipe(
      take(1),
      map(x => x.Patient.Id)
    );
  }

  clearCache() {
    this._config$ = null;
    this._details$ = null;
    this._alerts$ = null;
  }

  private idSetUp(orderId: number, partnerId: string) {
    this.order.id = orderId;
    this.partnerId.id = partnerId;
  }
}
