import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';
import { AccumulatedWarningsComponent } from './accumulated-warnings/accumulated-warnings.component';
import { MedicationInfoComponent } from './medication-info/medication-info.component';
import { ReviewPrescriptionComponent } from './review-prescription/review-prescription.component';
import { SelectDispenserComponent } from './select-dispenser/select-dispenser.component';
import { SelectMedicationComponent } from './select-medication/select-medication.component';
import { SetPatientNotesComponent } from './set-patient-notes/set-patient-notes.component';
import { SignPrescriptionComponent } from './sign-prescription/sign-prescription.component';
import { SpecifyMedicationComponent } from './specify-medication/specify-medication.component';

const routes: Route[] = [
  { path: '', redirectTo: 'review-prescription', pathMatch: 'full' },
  {
    path: 'select-medication',
    component: SelectMedicationComponent,
  },
  {
    path: 'specify-medication',
    component: SpecifyMedicationComponent,
  },
  {
    path: 'review-prescription',
    component: ReviewPrescriptionComponent,
  },
  {
    path: 'select-dispenser',
    component: SelectDispenserComponent,
  },
  {
    path: 'sign-prescription',
    component: SignPrescriptionComponent,
  },
  {
    path: 'set-patient-notes',
    component: SetPatientNotesComponent,
  },
  {
    path: 'accumulated-warnings',
    component: AccumulatedWarningsComponent,
  },
  {
    path: 'medication-info',
    component: MedicationInfoComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PrescriptionShellRoutingModule {}
