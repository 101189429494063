import { Injectable } from '@angular/core';
import { CurrentOrderIdService } from '@pushdr/common/data-access/analytics';
import { TranslatorDialState } from '@pushdr/common/types';
import { ApiDoctorsConsultation } from '@pushdr/doctors/data-access/doctors-api';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PatientDetailsService } from '../../../../services/patient-details/patient-details.service';
@Injectable({
  providedIn: 'root',
})
export class ConsultationTranslationService {
  private _languagesLoaded = false;

  currentState$ = new BehaviorSubject(TranslatorDialState.CALL_NOT_STARTED);

  languagesList$ = new BehaviorSubject([]);

  get surgeryAccessCodeForTranslation$(): Observable<number> {
    return this.patientDetailsService.details$.pipe(
      map(details => details.ConsultationConfig.TranslatorService.AccessCode)
    );
  }

  constructor(
    private patientDetailsService: PatientDetailsService,
    private consultationApi: ApiDoctorsConsultation,
    private order: CurrentOrderIdService
  ) {}

  disconnectTranslator() {
    return this.consultationApi.disconnectTranslator(this.order.id);
  }

  initLanguages() {
    if (!this._languagesLoaded) {
      this.consultationApi.getTranslatorLanguages().subscribe(languages => {
        this.languagesList$.next(languages);
        this._languagesLoaded = true;
      });
    }
  }
}
