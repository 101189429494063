import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AbstractModal } from '@pushdr/common/overlay';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export interface Status {
  text?: string;
  completed?: boolean;
}
export class SigningPrescriptionModalComponentArgs {
  title?: string;
  message?: string;
  closeButton?: boolean;
  okButton?: boolean;
  error?: { errors: [{ message: string }] };
}
@Component({
  selector: 'pushdr-signing-prescription-modal',
  templateUrl: './signing-prescription-modal.component.html',
  styleUrls: ['./signing-prescription-modal.component.scss'],
})
export class SigningPrescriptionModalComponent
  extends AbstractModal<any, boolean>
  implements OnInit
{
  title = 'Signing...';
  modalArgs$: Observable<SigningPrescriptionModalComponentArgs>;
  args: SigningPrescriptionModalComponentArgs;

  ngOnInit() {
    this.modalArgs$ = (<Observable<SigningPrescriptionModalComponentArgs>>(
      this.data.modalArgs$
    )).pipe(tap(x => (this.args = x)));

    this.closer.subscribe({
      next: x => {
        if (x) this.close();
      },
    });
  }
}
