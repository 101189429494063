<div class="c-modal__wrap" [cdkTrapFocus]="ready$ | async">
  <h2 class="mb-8 leading-8 text-center">
    Please assure yourself of the child’s ID and their relationship with the adult
  </h2>
  <p class="text-sm text-left">
    It is your responsibility to do so, if any doubt remains, please consider:
  </p>
  <ul class="px-3 pb-8 text-sm font-light leading-6 text-left list-disc">
    <li class="mb-4">Comparing the surname and/or registered address of the child and adult</li>
    <li class="mb-4">Arranging a face-to-face consultation</li>
    <li class="mb-4">Potential safeguarding risks</li>
  </ul>
  <div class="modal__footer">
    <button
      data-cy="confirm-button"
      class="m-auto text-white btn bg-primary hover:bg-primary-hover"
      (click)="done(true)">
      I understand
    </button>
  </div>
</div>
