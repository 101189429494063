<pushdr-consultation-card [hasData]="true" [showFooter]="true">
  <span class="text-information" header> Safeguarding process </span>
  <ng-container body>
    <div class="flex flex-row" *ngFor="let item of sectionsToShow; index as i">
      <div class="h-12 px-2 py-4 mr-4 text-3xl border border-solid rounded border-body-text">
        <div class="w-8 text-center">{{ i + 1 }}.</div>
      </div>
      <div>
        <ng-container *ngTemplateOutlet="item"></ng-container>
      </div>
    </div>

    <ng-template #referToDocs>
      <p class="mb-2 font-normal">
        If needed, refer to the following documents located in the help section which can be
        accessed using the ‘Help’ tab on the right side of this screen.
      </p>
      <p class="mb-4">
        - Process for Push Doctor clinicians with safeguarding concerns
        <br />
        - Adult Safeguarding policy
        <br />
        - Child Safeguarding Policy
      </p>
    </ng-template>

    <ng-template #locateRefMeth>
      <p class="mb-4 font-normal">
        Locate the appropriate safeguarding referral method by web searching for this account
        holder’s local health authority or their local safeguarding department website:
      </p>
      <div class="flex flex-row items-center mb-4">
        <span class="font-medium text-information">Account holder address:</span>
        <span class="ml-5 text-body-text" *ngIf="patientDetails$ | async as patient">
          <p class="mb-0">
            {{ patient.Patient.Address.Line1 + ' ' + patient.Patient.Address.Line2 }}
          </p>
          <p class="mb-0">{{ patient.Patient.Address.Line3 }}</p>
          <p class="mb-0">{{ patient.Patient.Address.PostTown }}</p>
          <p class="mb-0">{{ patient.Patient.Address.Postcode }}</p>
        </span>
      </div>
      <p class="mb-2">
        If you are not sure which template/form to use, you must contact the account holders
        practice or relevant safeguarding department for further guidance.
      </p>
      <p class="mb-4 font-normal">
        Complete the referral with an appropriate level of detail and immediately submit to the
        identified authority.
      </p>
    </ng-template>

    <ng-template #addPatientsPractice>
      <p class="mb-2 font-normal">
        You must also send the account holder's practice an urgent task to the 'PD GP Admin' task
        group to notify the practice that a referral has been made.
      </p>
      <p class="mb-4">This is not to be used to ask them to make a referral on your behalf.</p>
    </ng-template>

    <ng-template #sendAccount>
      <p class="mb-2 font-normal">
        Please then complete the separate Push Doctor safeguarding notification form by clicking the
        button below:
      </p>
      <a
        class="inline-block p-4 mb-2 btn btn-primary hover:no-underline"
        href="https://storage.googleapis.com/bulletins/Push%20Doctor%20safeguarding%20referral%20internal%20notification%20form.docx"
        target="_blank">
        Open form
      </a>
      <p class="mb-4">
        <span class="font-medium">This is for internal use only</span>
        and must be done in addition to any paperwork you submit to the relevant local authority
        safeguarding team.
        <br />
        Send this to
        <a href="mailto:safeguarding@pushdr.com" target="_blank"> safeguarding@pushdr.com </a>
        .
      </p>
    </ng-template>
  </ng-container>
  <ng-container footer>
    <div class="flex flex-row">
      <button
        class="p-4 m-5 text-sm btn btn-outline sm:m-1 xl:m-5"
        (click)="onClickBackToConsultation()">
        Back to consultation
      </button>
      <button
        class="p-4 text-sm btn btn-error sm:mt-2 sm:m-1 xl:m-5"
        (click)="onGotoPatientEmergency()">
        Emergency
      </button>
    </div>
  </ng-container>
</pushdr-consultation-card>
