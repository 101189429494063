import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pushdr-surgery-not-found',
  templateUrl: './not-found.component.html',
})
export class NotFoundComponent implements OnInit {
  withoutHeader$: Observable<boolean>;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.withoutHeader$ = this.route.data.pipe(map(data => data.withoutHeader));
  }

  navigateToHome() {
    this.router.navigate(['']);
  }
}
