import { ClinicalOutcomeResultTypeEnum } from '@pushdr/doctors/data-access/doctors-api';

export const ClinicalOutcomeLabelMap = new Map<ClinicalOutcomeResultTypeEnum, string>([
  [ClinicalOutcomeResultTypeEnum.Success, 'It has successfully reached an end outcome'],
  [
    ClinicalOutcomeResultTypeEnum.Discontinuation,
    'It needs to be discontinued altogether (patient won’t return to waiting room)',
  ],
  [
    ClinicalOutcomeResultTypeEnum.Disruption,
    'Disrupt temporarily (send patient back to waiting room)',
  ],
]);

export const ClinicalOutcomeList = Object.values(ClinicalOutcomeResultTypeEnum).filter(
  item => !isNaN(+item)
);
