import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractModal } from '@pushdr/common/overlay';

@Component({
  selector: 'pushdr-end-session-modal',
  templateUrl: './end-session-modal.component.html',
  styleUrls: ['./end-session-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EndSessionModalComponent extends AbstractModal<any, boolean> {}
