<pushdr-card [hasData]="true">
  <section class="flex flex-col w-full h-full">
    <header class="flex justify-between pt-5 pb-3 mx-5 text-xl font-light border-b border-grey-300">
      <div class="flex flex-col justify-center">Bulletins</div>
      <input
        #queryBulletins
        type="search"
        [(ngModel)]="query"
        placeholder="Search"
        class="p-2 text-sm border rounded border-bluegrey-500" />
    </header>
    <article class="flex-auto h-px px-5 py-3 overflow-y-auto scrollbar">
      <pushdr-clinicians-bulletins-list [query]="query"></pushdr-clinicians-bulletins-list>
    </article>
  </section>
</pushdr-card>
