import { Component } from '@angular/core';

@Component({
  selector: 'pushdr-bulletins-card',
  templateUrl: './bulletins-card.component.html',
  styleUrls: ['./bulletins-card.component.scss'],
})
export class BulletinsCardComponent {
  query: string;
}
