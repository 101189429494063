import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AnalyticsBusService } from '../analytics-bus/analytics-bus.service';
import { AnalyticsEvent, AnalyticsUser } from '../analytics-types';

@Injectable({
  providedIn: 'root',
})
export abstract class AnalyticsService {
  brazeInitialized$: Subject<any>;
  fireBaseInitialized$: Subject<any>;
  constructor(protected bus: AnalyticsBusService) {}

  abstract initialise(appIdentifier: string);

  trackEvent(event: AnalyticsEvent) {
    this.bus.trackEvent(event);
  }

  setUser(user: AnalyticsUser) {
    this.bus.setUser(user);
  }
}
