import { Component, OnInit } from '@angular/core';
import { AbstractModal } from '@pushdr/common/overlay';
import { errorCoreApi } from '@pushdr/common/types';
import { CardStatuses } from '@pushdr/doctors/data-access/doctors-api';

@Component({
  selector: 'pushdr-edit-consultation-warning',
  templateUrl: './card-reader-error-modal.component.html',
  styleUrls: ['./card-reader-error-modal.component.scss'],
})
export class CardReaderErrorModalComponent
  extends AbstractModal<{ errorType: string; error: errorCoreApi }, boolean>
  implements OnInit
{
  title = 'NHS Smart Card';
  errorType: string;
  message: string;

  ngOnInit() {
    this.errorType = this.data.errorType;

    switch (this.errorType) {
      case CardStatuses[CardStatuses.InvalidEnvironment]:
        this.title = 'Environment Setup Error';
        this.message = `Invalid Push Dr Smart Card Reader's Environment. Please make sure you have installed the latest version of the card reader software. If the problem persists, please contact our operations team.`;
        break;
      case CardStatuses[CardStatuses.RbacServiceDown]:
        this.title = 'RBAC Error';
        this.message = `There is a problem connecting to the RBAC service.`;
        break;
      case CardStatuses[CardStatuses.IdentityAgentNotRunning]:
        this.title = 'NHS Identity Agent is not running.';
        this.message = `Please make sure you have installed NHS Identity Agent and make sure it is currently running.`;
        break;
      case CardStatuses[CardStatuses.VpnNotConnected]:
        this.title = 'VPN Not Connected';
        this.message = `You must be connected to the NHS VPN in order to use your smartcard.`;
        break;
      case CardStatuses[CardStatuses.NoCardsFound]:
        this.title = 'Please insert a NHS Smartcard';
        this.message = `You NHS smart card does not appear to be inserted or you did not enter the correct pin.`;
        break;
      case CardStatuses[CardStatuses.ServerNotRunning]:
        this.message = 'Push Dr card reader software is not running.  Please launch it.';
        break;
      case CardStatuses[CardStatuses.SmartCardRevoked]:
        this.title = 'NHS Smart Card Revoked';
        this.message = 'Your smartcard has been revoked. Please contact our operations team.';
        break;
      case CardStatuses[CardStatuses.CertificateExpired]:
        this.title = 'NHS Smart Card Expired';
        this.message = 'Your smartcard has expired. Please contact our operations team.';
        break;
      case CardStatuses[CardStatuses.CertificateInFuture]:
        this.title = 'NHS Smart Card Issue';
        this.message =
          'Your smartcard is set to start in future. Please contact our operations team.';
        break;
      case CardStatuses[CardStatuses.TokenNotPresent]:
        this.title = 'NHS Smart Card Issue';
        this.message = 'There was a problem reading your Smartcard. Please remove and reinsert it.';
        break;
      default:
        this.message = `There is a problem using your NHS smart card. (${this.errorType})`;
        break;
    }
  }
}
