<div class="c-modal__wrap" *ngIf="modalArgs$ | async">
  <h3 class="mb-8 -mt-6 text-left">{{ args.title }}</h3>

  <div class="mb-3 text-left whitespace-pre-wrap" *ngIf="args.message">{{ args.message }}</div>
  <div class="flex-row justify-end mt-8 -mb-6 -mr-6 sm:block md:flex" *ngIf="args.closeButton">
    <button
      class="p-4 pl-6 pr-6 text-white rounded bg-error hover:bg-red-300 md:ml-2"
      (click)="done(true)">
      Close
    </button>
  </div>
</div>
