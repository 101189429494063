<ng-select
  placeholder="Pick a reason"
  aria-live="polite"
  [items]="reasons$ | async"
  [searchFn]="dummySearch"
  bindValue="Code"
  bindLabel="Text"
  [notFoundText]="'No matches'"
  [formControl]="control"
  (keyup)="changeSearchTerm($event.target.value)"
  (change)="onChange($event)"
  [clearable]="false"
  [markFirst]="true">
  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
    <div class="flex items-center">
      <div class="flex-grow mr-2">
        <p class="mb-0 mr-1 whitespace-normal">
          {{ item.Text }}
        </p>
      </div>
    </div>
  </ng-template>
</ng-select>
