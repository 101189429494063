import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { AbstractControl, UntypedFormControl, ValidationErrors, Validators } from '@angular/forms';

import { PasswordRule } from '../password-input-tooltip/models';
import * as CustomValidators from './validators';

const defaultPasswordRules: PasswordRule[] = [
  { condition: '10 characters', error: 'minlength' },
  { condition: 'one uppercase letter', error: 'uppercaseLetterRequired' },
  { condition: 'one lowercase letter', error: 'lowercaseLetterRequired' },
  { condition: 'one number', error: 'oneNumberRequired' },
  { condition: 'one symbol (except "<>")', error: 'oneSymbolRequired' },
];

@Component({
  selector: 'pushdr-password-input-with-rules',
  templateUrl: './password-input-with-rules.component.html',
  styleUrls: ['./password-input-with-rules.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordInputWithRulesComponent {
  @Input() formControlProxy: AbstractControl = null;
  @Input() rules = defaultPasswordRules;
  @Input() tooltipPosition: 'left' | 'right' = 'right';

  // Used to show error on password mismatch
  @Input() hasMatch = true;

  showTooltip = false;

  get errors(): ValidationErrors {
    return this.formControlProxy?.errors;
  }

  get formControl(): UntypedFormControl {
    if (this.formControlProxy instanceof UntypedFormControl) {
      return this.formControlProxy;
    }
    return null;
  }

  get isInvalid(): boolean {
    const hasError = this.formControl.errors || !this.hasMatch;
    return hasError && this.formControl.touched;
  }

  get thumbPosition(): 'left' | 'right' {
    // Map tooltip thumb position in mirrored order
    const thumbClassMapping = { left: 'right', right: 'left' } as const;
    return thumbClassMapping[this.tooltipPosition];
  }

  static createPasswordControl(): AbstractControl {
    return new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(100),
      CustomValidators.minLengthInclusive(10),
      CustomValidators.uppercaseLetterRequired,
      CustomValidators.lowercaseLetterRequired,
      CustomValidators.oneNumberRequired,
      CustomValidators.oneSymbolRequired,
    ]);
  }
}
