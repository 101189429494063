import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ConsultationState, ConsultationStateService } from '@pushdr/clinicians/common';
import { combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ConsultationFeatureService } from '../../../../services/consultation-feature/consultation-feature.service';
import { WINDOW, ExtendedWindow } from '@pushdr/common/utils';

@Injectable({
  providedIn: 'root',
})
export class PatientNavigationService {
  constructor(
    private consultationFeatures: ConsultationFeatureService,
    private consultationState: ConsultationStateService,
    private router: Router,
    @Inject(WINDOW) private window: ExtendedWindow
  ) {}

  gotoConsultationHome(state: ConsultationState = this.consultationState.state) {
    if (this.consultationState.state !== state) {
      this.consultationState.state = state;
    }

    this.homePath$.subscribe(path => {
      this.router.navigate(path);
    });
  }

  get homePath$() {
    return combineLatest([
      this.consultationState.state$,
      this.consultationFeatures.canSeeRecordsHTML$,
    ]).pipe(
      take(1),
      map(([state, canSeeRecords]) => {
        switch (state) {
          case ConsultationState.VERIFYING_IDENTITY:
            return ['/consultation/patient/id/check'];
          case ConsultationState.CHECKING_ALERTS:
            return ['/consultation/patient/id/alerts'];
          case ConsultationState.CONSULTING:
            return canSeeRecords
              ? ['/consultation/patient/consult/records']
              : ['/consultation/patient/consult/default'];
          case ConsultationState.EDITING:
            return ['/consultation/patient/consult/default'];
          default:
            return ['/consultation/patient/id/check'];
        }
      })
    );
  }

  gotoPatientEmergency() {
    return this.router.navigate(['/consultation/patient/consult/emergency']);
  }

  gotoPatientSafeGuarding() {
    return this.router.navigate(['/consultation/patient/consult/safeguarding']);
  }

  gotoWaitingRoom() {
    // TODO: go back to using router after v3 signalR is in and if signalR disconnects properly
    // still some services holding onto data into next consultation if new page load does not happen, patient-details.service
    this.window.location.href = '/consultation/waiting-room/active';
    //return this.router.navigate(['/consultation/waiting-room/active']);
  }

  gotoDashboard() {
    // To clear persisted service state after edit previous consultation
    this.window.location.href = '/home/dashboard';
  }

  gotoNotesCapture() {
    return this.router.navigate(['/consultation/patient/consult/notes/nhs/capture']);
  }

  gotoToAdditionalNotes() {
    return this.router.navigate(['/consultation/summary/additional-notes']);
  }

  gotoPrescription() {
    return this.router.navigate([
      'consultation/patient/consult/actions/prescribe/review-prescription',
    ]);
  }
}
