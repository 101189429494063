import { Pipe, PipeTransform } from '@angular/core';

interface GroupedItems<T> {
  group: string;
  items: T[];
}

@Pipe({
  name: 'arrayGroupBy',
})
export class ArrayGroupByPipe implements PipeTransform {
  transform<T>(arr: T[], property: string): GroupedItems<T>[] {
    return arr.reduce((acc: GroupedItems<T>[], current: T) => {
      let groupedItem = acc.find(item => item.group === current[property]);
      if (!groupedItem) {
        groupedItem = { group: current[property], items: [current] };
        acc.push(groupedItem);
      } else {
        groupedItem.items.push(current);
      }
      return acc;
    }, []);
  }
}
