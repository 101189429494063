import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { timer, Observable } from 'rxjs';
import { take, map, finalize } from 'rxjs/operators';

@Component({
  selector: 'pushdr-countdown-icon',
  templateUrl: './countdown-icon.component.html',
  styleUrls: ['./countdown-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountdownIconComponent implements OnInit {
  @Input() seconds = 5;
  @Output() completed = new EventEmitter();
  timeLeft$: Observable<number>;

  ngOnInit() {
    if (this.seconds > 0) {
      const startTime = Math.floor(this.seconds);
      this.timeLeft$ = timer(0, 1000).pipe(
        take(startTime + 1),
        map(increment => this.seconds - increment),
        finalize(() => this.completed.emit())
      );
    }
  }
}
