<div class="c-modal__wrap" [cdkTrapFocus]="ready$ | async">
  <button *ngIf="data.closeButton" (click)="close()" class="c-modal__close-btn">
    <img src="assets/icons/close-white.svg" alt="close modal" />
    <span class="u-sr-only">Close modal</span>
  </button>

  <h2>
    {{ data.header }}
  </h2>

  <div *ngIf="data.message" class="modal__body">
    <p>{{ data.message }}</p>
  </div>

  <div *ngIf="data.htmlBody" class="modal__body">
    <div [innerHTML]="data.htmlBody"></div>
  </div>

  <div *ngIf="data.bottomText">
    <h4>{{ data.bottomText }}</h4>
  </div>

  <div class="modal__footer">
    <div *ngFor="let button of data.buttons">
      <button [class]="'c-btn c-btn--' + (button.type || 'primary')" (click)="done(button.id)">
        {{ button.caption }}
      </button>
    </div>
    <button *ngIf="data.confirmationButton" class="c-btn c-btn--primary" (click)="close()">
      OK
    </button>
  </div>
</div>
