<div class="search-medication-component" *ngIf="{ meds: medications$ | async } as data">
  <ng-select
    placeholder="Search medications"
    aria-live="polite"
    clearAllText="Clear"
    [items]="data.meds"
    [searchFn]="dummySearch"
    bindLabel="medicationName"
    [notFoundText]="hasError ? 'Medication search failed.' : noMatchesText"
    [formControl]="control"
    (keyup)="changeSearchTerm($any($event?.target)?.value)"
    (change)="onChange($event)"
    (focus)="onTouched()"
    [class.custom-clear-all-text]="customClearTextApplied$ | async"
    [class.custom-no-match-text]="customNoMatchTextApplied$ | async"
    [class.validation-error]="validationError"
    [loading]="isLoading"
    #ngSelect>
    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
      <div class="flex items-center">
        <p class="mb-0 whitespace-normal">
          {{ item.medicationName }}
          <span *ngIf="item.unlicensed">(unlicensed)</span>
        </p>
        <div class="flex ml-auto justify-content-between items-center">
          <div *ngIf="item.isHighRisk" class="ml-5 relative">
            <i class="material-icons text-red-500 showNextHover" (mouseover)="setPos($event)"
              >warning_amber</i
            >
            <div>
              High risk drug category<br />
              Proceed with extra caution
            </div>
          </div>
          <div *ngIf="item.shouldReportAdverseEffect" class="ml-5 relative">
            <span class="showNextHover font-bold" (mouseover)="setPos($event)">AE</span>
            <div>
              Black Triangle drug. Report adverse effects<br />
              via the MHRA Yellow Card service
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-select>
</div>

<p *ngIf="hasError" class="mt-2 text-red-500">
  Medication search has failed, please continue prescription in either EMIS/TPP system.
</p>
