<pushdr-consultation-card [hasData]="true" bodyPadding="p-0">
  <pushdr-prescription-card-header header></pushdr-prescription-card-header>

  <ng-container body>
    <pushdr-alert-bar type="warning"
      >Inform your patient that this pharmacy selection will not override where they collect future
      electronic prescriptions from.</pushdr-alert-bar
    >
    <div class="px-4">
      <div class="pt-3 pb-3 dispenser-search">
        <div class="flex mb-1">
          <span class="flex-grow"> Search for pharmacies near patients location </span>
          <label class="flex-grow-0">
            <input
              #nowOpen
              type="checkbox"
              [value]="true"
              [(ngModel)]="openDispensersOnly"
              name="nowOpenOnly"
              data-cy="openNow-chck"
              class="mt-1 ml-1 mr-1" />
            Currently open
          </label>
        </div>
        <pushdr-prescribing-dispenser-search
          (dispenserChange)="dispenserChanged($event)"
          [openOnly]="openDispensersOnly">
        </pushdr-prescribing-dispenser-search>
      </div>
    </div>
  </ng-container>
</pushdr-consultation-card>
