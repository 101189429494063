import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentOrderIdService } from '@pushdr/common/data-access/analytics';
import { Gender } from '@pushdr/common/types';
import {
  ApiDoctorsConsultation,
  ApiDoctorsConsultationNew,
} from '@pushdr/doctors/data-access/doctors-api';
import { Observable, of } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';

export enum ConsultationType {
  AccountHolder = 0,
  Child = 1,
}

export interface IdentificationType {
  Description: string;
  FurtherInformationRequired: boolean;
  IdentificationTypeId: number;
  ReferenceRequired: boolean;
  SupportedAgeTypes: ConsultationType[];
}

@Injectable({
  providedIn: 'root',
})
export class IdCheckService {
  constructor(
    private api: ApiDoctorsConsultation,
    private order: CurrentOrderIdService,
    private newApi: ApiDoctorsConsultationNew
  ) {}

  get accountHolderIdentificationTypes$(): Observable<IdentificationType[]> {
    return this.idTypes$.pipe(this.filterByConsultationType(ConsultationType.AccountHolder));
  }

  get childIdentificationTypes$(): Observable<IdentificationType[]> {
    return this.idTypes$.pipe(this.filterByConsultationType(ConsultationType.Child));
  }

  getIdDocument$() {
    return this.api.getIdentityDocument(this.order.id).pipe(
      catchError(() => {
        return of('');
      }),
      map(img => (img ? 'data:image/jpeg;base64,' + img : 'no-image'))
    );
  }

  confirmPatient$(identificationTypeId: number) {
    return this.api.confirmPatient(this.order.id, identificationTypeId);
  }

  confirmChildNhs$(
    firstName: string,
    lastName: string,
    gender: Gender,
    dob: Date,
    identificationTypeId: number
  ) {
    return this.api.createChildVerification(
      this.order.id,
      firstName,
      lastName,
      dob,
      gender,
      identificationTypeId
    );
  }

  confirmChild$(patientId: number, accountHolderId: number, idType: number) {
    return this.newApi.customerRelationVerification(
      this.order.id,
      idType,
      patientId,
      accountHolderId
    );
  }

  private get idTypes$(): Observable<IdentificationType[]> {
    return this.api.getIdentificationTypes().pipe(publishReplay(1), refCount());
  }

  private filterByConsultationType = (consultationType: ConsultationType) =>
    map((types: IdentificationType[]) =>
      types.filter(type => type.SupportedAgeTypes.indexOf(consultationType) > -1)
    );
}
