<div class="c-modal__wrap">
  <h2 *ngIf="isRecording">Do you want to end the recording?</h2>
  <h2 *ngIf="!isRecording">Do you want to end video session?</h2>

  <div class="flex justify-center gap-8">
    <button
      class="w-32 h-12 py-3 px-8 text-sm bg-transparent hover:bg-indigo-100 text-indigo-500 border border-indigo-500 rounded"
      (click)="done(false)">
      No
    </button>
    <button
      class="w-32 h-12 py-3 px-8 text-sm bg-red-500 hover:bg-red-300 text-white border border-red-500 hover:border-red-300 rounded"
      (click)="done(true)">
      Yes
    </button>
  </div>
</div>
