<ng-container *ngIf="patient$ | async as consultation">
  <pushdr-card [hasData]="!submitting">
    <section
      *ngIf="childIdentificationTypes$ | async as identificationTypes"
      class="flex flex-col w-full h-full">
      <header class="relative z-10 p-4 text-xl bg-grey-100 text-bluegrey-800 shadow-header">
        Child verification
      </header>

      <form
        *ngIf="childFormGroup"
        [formGroup]="childFormGroup"
        (ngSubmit)="childFormGroup.valid && onSubmitChild()"
        class="flex flex-col h-full overflow-y-auto bg-white scrollbar">
        <section class="p-4 text-sm font-normal bg-information-light text-information">
          <p class="mb-0 font-medium text-information">
            Ask the account holder to hold up the child’s passport or birth certificate to the
            camera, then carefully compare against the details provided below to verify their
            identity.
          </p>
        </section>
        <section class="p-4 text-sm">
          <div class="flex flex-row mb-4">
            <div class="inline-block mr-10">
              <span class="block mb-1 text-grey-500">Name</span>
              <span class="text-bluegrey-800">{{ consultation.Patient.Name }}</span>
            </div>
            <div class="inline-block mr-10">
              <span class="block mb-1 text-grey-500">Date of birth</span>
              <span class="text-bluegrey-800">
                {{ consultation.Patient.DateOfBirth + ' (' + consultation.Patient.Age + ')' }}
              </span>
            </div>
            <div class="inline-block mr-10">
              <span class="block mb-1 text-grey-500">Gender</span>
              <span class="text-bluegrey-800">{{ consultation.Patient.GenderLabel }}</span>
            </div>
            <div class="inline-block mr-10">
              <span class="block mb-1 text-grey-500">NHS number</span>
              <span class="text-bluegrey-800">{{ consultation.Patient.NhsNumber }}</span>
            </div>
          </div>

          <div class="flex flex-row mb-4">
            <div class="inline-block mr-10">
              <span class="block mb-1 text-grey-500">Address</span>
              <span class="block text-bluegrey-800">
                {{ consultation.Patient.Address.Line1 }} {{ consultation.Patient.Address.Line2 }}
              </span>
              <span class="block text-bluegrey-800">{{ consultation.Patient.Address.Line3 }}</span>
              <span class="block text-bluegrey-800">{{
                consultation.Patient.Address.PostTown
              }}</span>
              <span class="block text-bluegrey-800">{{
                consultation.Patient.Address.Postcode
              }}</span>
            </div>
            <div class="inline-block mr-10">
              <span class="block mb-1 text-grey-500">Parent / Legal guardian's GP surgery</span>
              <span class="block text-bluegrey-800">
                {{ consultation.Customer.GpSummary.Name | uppercase }}
              </span>
            </div>
          </div>
        </section>

        <section class="flex flex-col flex-grow p-4">
          <label class="mb-6 mr-6 text-sm text-body-text">
            Child ID type
            <select
              formControlName="idTypeSelect"
              class="block p-3 bg-white border border-solid rounded border-bluegrey-600">
              <option value="null" disabled selected>Pick from dropdown</option>
              <option *ngFor="let idType of identificationTypes" [ngValue]="idType">
                {{ idType.Description }}
              </option>
            </select>
          </label>

          <p class="mb-6">
            <strong>No ID docs? – Select ‘verification questions’ </strong> on the dropdown above,
            use the child’s NHS record to ask the account holder 2-3 questions about it. eg. What
            was their last prescription?
          </p>

          <label
            for="recordCheckConfirm"
            class="flex gap-2 p-5 mb-2 font-medium border border-bluegrey-400">
            <input
              id="recordCheckConfirm"
              type="checkbox"
              class="flex self-center -mt-1 transform scale-125"
              formControlName="recordCheckConfirm" />
            I confirm that I’ve seen the correct child’s summary care record
          </label>

          <label
            for="sameSurgeryConfirm"
            class="flex gap-2 p-5 mb-2 font-medium border border-bluegrey-400">
            <input
              id="sameSurgeryConfirm"
              type="checkbox"
              class="flex mt-1 transform scale-125"
              formControlName="sameSurgeryConfirm" />
            I confirm the child is registered at the same GP surgery as the Parent / Legal guardian
          </label>
        </section>

        <div class="flex-grow w-full border-b border-solid border-grey-300"></div>
        <div class="flex flex-row justify-end p-2">
          <button
            class="p-3 text-white rounded bg-primary hover:bg-primary-hover"
            [ngClass]="{
              'cursor-not-allowed bg-primary-disabled hover:bg-primary-disabled':
                !childFormGroup.valid
            }"
            type="submit">
            Submit
          </button>
        </div>
      </form>
    </section>
  </pushdr-card>
</ng-container>

<ng-template #loading>
  <pushdr-spinner-loader></pushdr-spinner-loader>
</ng-template>
