import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, takeUntil } from 'rxjs/operators';
import { AnalyticsBusService } from '../analytics-bus/analytics-bus.service';
import { AnalyticsIntegrationConfig } from '../analytics-types';
import { LogService } from './log.service';
import { StorageService } from '@pushdr/common/utils';

@Injectable({
  providedIn: 'root',
})
export class PushDoctorLogsService implements OnDestroy {
  private config: AnalyticsIntegrationConfig;
  private ngUnsubscribe$ = new Subject<void>();
  userid: string;
  alwaysStore = false;

  constructor(
    private bus: AnalyticsBusService,
    private logger: LogService,
    private storage: StorageService
  ) {}

  initialise(config: AnalyticsIntegrationConfig) {
    this.config = config;
    this.subscribeToUser();
    this.subscribeToEvents();
    this.userid = this.storage.get('pdr_uid');
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  private subscribeToEvents() {
    this.bus.eventBus$
      .pipe(
        filter(event => this.config.events.includes(event.action)),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe(event => this.parseLogEvent(event));
  }

  private parseLogEvent(event) {
    const message = event.properties.message || JSON.stringify(event.properties);
    if (message) {
      const target = event.view ? `${event.target}:${event.view}` : event.target;
      this.log(target, {
        message,
        type: event.properties.type,
        store: this.alwaysStore,
      });
    }
  }

  private subscribeToUser() {
    const user$ = this.bus.userBus$.pipe(shareReplay(1));

    user$
      .pipe(
        map(user => user.id as string),
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe(userId => {
        if (userId) {
          this.storage.set('pdr_uid', userId);
          this.userid = userId;
        }
      });
  }

  private log(target: string, { type, message, store }) {
    const typeOrPath = type || `URL: ${window.location.href}`;
    const messageString = typeof message === 'string' ? message : JSON.stringify(message);
    const userIdMessaged = this.userid ? `${this.userid}: ${messageString}` : messageString;
    this.logger.log(target, userIdMessaged, typeOrPath, store);
  }
}
