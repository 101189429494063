import { NgModule } from '@angular/core';
import { MeetThePatientComponent } from './meet-the-patient.component';
import { CountdownIconComponent } from './components/countdown-icon/countdown-icon.component';
import { CommonModule } from '@angular/common';
import { CliniciansCommonModule } from '@pushdr/clinicians/common';

@NgModule({
  declarations: [MeetThePatientComponent, CountdownIconComponent],
  imports: [CommonModule, CliniciansCommonModule],
})
export class MeetThePatientModule {}
