import { Injectable } from '@angular/core';
import { ClinicianAnalyticsService } from '@pushdr/clinicians/common';
import { AnalyticsEvent } from '@pushdr/common/data-access/analytics';
import {
  PatientHTMLRecords,
  PatientStructuredRecords,
  RecordsSectionTypes,
} from '@pushdr/common/types';
import * as moment from 'moment';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ConsultationFeatureService } from '../../../../services/consultation-feature/consultation-feature.service';
import { PatientRecordsBaseService } from './patient-records-base.service';

@Injectable({
  providedIn: 'root',
})
export class PatientRecordsService {
  patientNhsNumber$: Observable<string>;
  patientOdsCode$: Observable<string>;

  constructor(
    private analytics: ClinicianAnalyticsService,
    private consultationFeatures: ConsultationFeatureService,
    private patientRecords: PatientRecordsBaseService
  ) {}

  initialise() {
    this.patientNhsNumber$ = this.patientRecords.patientNhsNumber$;
    this.patientOdsCode$ = this.patientRecords.patientOdsCode$;
    const initialisationTime = moment();
    this.getRecord().subscribe({
      next: () => {
        const timeToInit = moment().diff(initialisationTime);
        this.trackRecordInit(timeToInit);
      },
      error: error => {
        const timeToInit = moment().diff(initialisationTime);
        this.trackRecordInit(timeToInit, error.message, error.status);
      },
    });
  }

  getRecordsSection(recordsSectionType: RecordsSectionTypes) {
    return this.patientRecords
      .getPatientRecord()
      .pipe(map(records => records.getSection(recordsSectionType)));
  }

  getStructuredPatientRecord() {
    return this.patientRecords.getStructuredPatientRecord();
  }

  private trackRecordInit(timeToInit = 0, message = '', statusCode = null) {
    const event = new AnalyticsEvent({
      view: 'consultation',
      action: 'log',
      properties: {
        timeToInit,
        message,
        statusCode,
      },
    });
    this.analytics.trackEvent(event);
  }

  private getRecord(): Observable<PatientStructuredRecords | PatientHTMLRecords> {
    return combineLatest([
      this.consultationFeatures.canSeeRecordsStructured$,
      this.consultationFeatures.makeStructuredRecordsCall$,
    ]).pipe(
      switchMap(([canSeeRecordsStructured, makeStructuredRecordsCall]) => {
        if (canSeeRecordsStructured || makeStructuredRecordsCall) {
          return this.getStructuredPatientRecord();
        } else {
          return this.patientRecords.getPatientRecordsFromApi();
        }
      })
    );
  }
}
