export enum ButtonTypesEnum {
  primary = 'primary',
  danger = 'danger',
}

interface ModalButton {
  caption: string;
  callback?: () => void;
  type?: string;
  cssClass?: string;
}

export interface LegacyModalParams {
  header?: string;
  message?: string;
  htmlBody?: string;
  className?: string;
  closeButton?: boolean;
  confirmationButton?: boolean;
  buttons?: Array<ModalButton>;
  bottomText?: string;
  enableBackgroundClicks?: boolean;
  longModal?: boolean;
}
