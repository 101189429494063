import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ConsultationState } from '@pushdr/clinicians/common';
import { PatientDetailsService } from '../../../../../services/patient-details/patient-details.service';
import { PatientNavigationService } from '../../../services/patient-navigation/patient-navigation.service';

@Component({
  selector: 'pushdr-confirm-alerts',
  templateUrl: './confirm-alerts.component.html',
  styleUrls: ['./confirm-alerts.component.scss'],
})
export class ConfirmAlertsComponent {
  alerts$ = this.patientDetails.alerts$;
  readCheckboxControl: UntypedFormControl = new UntypedFormControl(false);

  constructor(
    private patientDetails: PatientDetailsService,
    private patientNav: PatientNavigationService
  ) {}

  onConfirmTerms() {
    this.patientNav.gotoConsultationHome(ConsultationState.CONSULTING);
  }
}
