import { Injectable, OnDestroy } from '@angular/core';
import {
  ApplicationInsights,
  ITelemetryItem,
  IEventTelemetry,
  Snippet,
} from '@microsoft/applicationinsights-web';
import { Subject } from 'rxjs';
import { takeUntil, filter, shareReplay, map, distinctUntilChanged } from 'rxjs/operators';
import { AnalyticsEvent, AnalyticsIntegrationConfig } from '../analytics-types';
import { AnalyticsBusService } from '../analytics-bus/analytics-bus.service';

export class InsightsTelemetry implements IEventTelemetry {
  name: string;
  properties: {
    [key: string]: string | number | boolean;
  };
  measurements: {
    [key: string]: number;
  };

  constructor(data: AnalyticsEvent) {
    this.name = data.action || '';
    this.properties = { ...data.properties, target: data.target, view: data.view };
    this.measurements = {};
  }
}

export class InsightsViewTelemetry {
  uri: string;

  constructor(data: AnalyticsEvent) {
    this.uri = data.properties.url || '';
  }
}

@Injectable({
  providedIn: 'root',
})
export class InsightsService implements OnDestroy {
  private appInsights: ApplicationInsights;
  private config: AnalyticsIntegrationConfig;
  private ngUnsubscribe$ = new Subject<void>();

  constructor(private bus: AnalyticsBusService) {}

  initialise(
    config: AnalyticsIntegrationConfig,
    appInsightsConfig: Snippet,
    appIdentifier: string
  ) {
    if (
      appInsightsConfig &&
      appInsightsConfig.config &&
      appInsightsConfig.config.instrumentationKey &&
      !this.appInsights
    ) {
      this.config = config;
      this.appInsights = new ApplicationInsights(appInsightsConfig);
      this.appInsights.loadAppInsights();
      this.setCustomCloudRoleName(appIdentifier);
      this.subscribeToUser();
      this.subscribeToEvents();
    } else {
      throw new Error('Application Insights failed to initialise or was already initialised.');
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  private subscribeToEvents() {
    this.bus.eventBus$
      .pipe(
        filter(event => this.config.events.indexOf(event.action) > -1),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe(event => {
        if (event.action === 'router') {
          const telemetry = new InsightsViewTelemetry(event);
          this.appInsights.trackPageView(telemetry);
        } else {
          const telemetry = new InsightsTelemetry(event);
          this.appInsights.appInsights.trackEvent(telemetry);
        }
      });
  }

  private subscribeToUser() {
    const user$ = this.bus.userBus$.pipe(shareReplay(1));

    user$
      .pipe(
        map(user => user.id),
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((userId: string) => {
        if (userId) {
          this.setUserId(userId);
        } else {
          this.clearUserId();
        }
      });
  }

  private setUserId(userId: string) {
    this.appInsights.setAuthenticatedUserContext(userId);
  }

  private clearUserId() {
    this.appInsights.clearAuthenticatedUserContext();
  }

  private setCustomCloudRoleName(appIdentifier: string) {
    this.appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
      envelope.tags['ai.device.roleName'] = appIdentifier;
    });
  }
}
