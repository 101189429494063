import { Component } from '@angular/core';
import { PatientNavigationService } from '../../../services/patient-navigation/patient-navigation.service';

@Component({
  selector: 'pushdr-clinicians-contact-support',
  templateUrl: './contact-support.component.html',
  styleUrls: ['./contact-support.component.scss'],
})
export class CliniciansContactSupportComponent {
  constructor(private patientNav: PatientNavigationService) {}

  onClickBackToConsultation() {
    this.patientNav.gotoConsultationHome();
  }
}
