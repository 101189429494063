<pushdr-consultation-card
  [hasData]="!!(warnings$ | async) || hasError"
  [showFooter]="true"
  bodyPadding="p-0 relative">
  <pushdr-prescription-card-header header></pushdr-prescription-card-header>
  <div body class="h-full relative">
    <pushdr-fdb-clinical-decision-support
      *ngIf="!hasError; else hasErrorTmpl"
      [warnings]="warnings$ | async"
      [hideReferentialInfo]="true">
    </pushdr-fdb-clinical-decision-support>
    <ng-template #hasErrorTmpl>
      <p class="p-5">
        Sorry, currently we are unable to provide support via
        <abbr title="Clinical Decision Support">CDS</abbr> channel.<br />
        Please carry on with the consultation, and use EMIS/TPP system where it's necessary.
      </p>
    </ng-template>
  </div>
  <div footer class="flex mt-3 mb-3">
    <div class="flex flex-col items-center w-full">
      <button
        routerLink="../review-prescription"
        data-cy="back-to-basket-button"
        class="py-3 px-5 btn bg-primary text-white">
        Back to basket
      </button>
    </div>
  </div>
</pushdr-consultation-card>
