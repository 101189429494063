<pushdr-toggle-header
  bg="bg-red-100"
  color="text-red-500"
  label="Warnings"
  icon="warning_amber"
  (click)="pdrReturn.emit()">
</pushdr-toggle-header>
<div class="flex flex-col justify-start w-full p-5">
  <p class="text-red-500 font-medium">
    Prescribing this medication poses a significant risk. Please provide a reason for overriding
    these warnings.
  </p>
  <pushdr-input-with-character-count
    label="Override reason"
    [control]="control"
    [multiline]="4"
    [max]="10000">
  </pushdr-input-with-character-count>
</div>
