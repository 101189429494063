import { Component, OnInit } from '@angular/core';
import { SafeStyle } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ModalService } from '@pushdr/common/overlay';
import { PartnerType } from '@pushdr/common/types';
import { StorageService } from '@pushdr/common/utils';
import { EnvironmentProxyService } from '@pushdr/environment';
import { slideInLeftAnimation } from 'angular-animations';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import {
  ConsultationState,
  ConsultationStateService,
} from '../../../services/consultation-state/consultation-state.service';
import { CliniciansDoctorsDetailsService } from '../../../services/doctor-details/doctor-details.service';
import {
  NetworkMonitorResult,
  NetworkMonitorService,
} from '../../../services/network-monitor/network-monitor.service';
import { DELAY3, DURATION4 } from '../../../utils/anims/animation-consts';
import { DevToolsComponent } from './dev-tools/dev-tools.component';
import { EditConsultationWarningComponent } from './edit-consultation-warning/edit-consultation-warning.component';
interface FeatureState {
  visible?: boolean;
  enabled?: boolean;
}
interface Features {
  lastConEdit?: FeatureState;
  canPrescribe?: FeatureState;
  consultationHistory: FeatureState;
}
@Component({
  selector: 'pushdr-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'],
  animations: [slideInLeftAnimation({ duration: DURATION4 })],
})
export class SidebarMenuComponent implements OnInit {
  static firedOnce = false;
  shownOnce = false;

  networkStatus$: Observable<NetworkMonitorResult>;
  profileImage$: Observable<SafeStyle>;
  state$: Observable<ConsultationState>;
  ConsultationState: typeof ConsultationState = ConsultationState;
  showLastConEdit: boolean;
  features$: Observable<Features>;
  devToolsEnabled = false;

  get activeMocks() {
    const apiMocks = this.storage.getLocalStorage('ApiMocks', true);
    return apiMocks && Object.keys(apiMocks).length > 0;
  }

  constructor(
    private storage: StorageService,
    private modal: ModalService,
    private profile: CliniciansDoctorsDetailsService,
    private consultationState: ConsultationStateService,
    private networkMonitorService: NetworkMonitorService,
    protected proxy: EnvironmentProxyService,
    private router: Router
  ) {}

  ngOnInit() {
    this.networkStatus$ = this.networkMonitorService.result$;
    this.profileImage$ = this.profile.profileImage$;
    this.state$ = this.consultationState.state$;
    this.animateInFirstTimeOnly();
    this.devToolsEnabled = !this.proxy.environment.production;

    this.features$ = this.profile.partnerTypes$().pipe(
      mergeMap(x =>
        this.profile.lastConsultationDetails$().pipe(map(l => ({ pTypes: x, lastConDet: l })))
      ),
      map(x => {
        const hasPdr = x.pTypes.includes(PartnerType.PDR);
        return <Features>{
          lastConEdit: {
            visible: hasPdr,
            enabled: x.lastConDet.type === PartnerType.PDR && x.lastConDet.isEditable,
          },
          consultationHistory: {
            visible: true,
            enabled: true,
          },
        };
      })
    );
  }

  editLast($event: MouseEvent, enabled: boolean) {
    if (!enabled) return;
    this.modal.showCustom(EditConsultationWarningComponent).subscribe(x => {
      if (x) {
        this.consultationState.state = ConsultationState.EDITING;
        this.router.navigate(['/consultation/patient/consult/default']);
      }
    });
    $event.stopPropagation();
    $event.preventDefault();
  }

  onShowDevTools() {
    this.modal.showCustom(DevToolsComponent).subscribe(x => {
      if (x) {
        this.consultationState.state = ConsultationState.EDITING;
        this.router.navigate(['/consultation/patient/consult/default']);
      }
    });
  }

  // TODO: work around this later but since its used cross sections
  // this was the best i could consider
  animateInFirstTimeOnly() {
    if (!SidebarMenuComponent.firedOnce) {
      setTimeout(() => {
        this.shownOnce = true;
      }, DELAY3);
      SidebarMenuComponent.firedOnce = true;
    } else {
      this.shownOnce = true;
    }
  }

  runNetworkTest() {
    this.networkMonitorService.runTest();
  }

  isConsulting(state: ConsultationState) {
    return [
      ConsultationState.CHECKING_ALERTS,
      ConsultationState.CONSULTING,
      ConsultationState.MEET_PATIENT,
      ConsultationState.PREVIEW_PATIENT,
      ConsultationState.VERIFYING_IDENTITY,
    ].some(f => f === state);
  }
}
