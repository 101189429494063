<pushdr-consultation-card
  [hasData]="!!(notes$ | async)"
  (scrolledToEnd)="loadMoreNotes()"
  [showFooter]="true">
  <span header>Push Doctor Notes</span>
  <ng-container body>
    <pushdr-doctor-notes-list class="-m-4" [notes]="notes$ | async"></pushdr-doctor-notes-list>
  </ng-container>
  <ng-container footer>
    <button class="w-2/5 p-4 m-5 btn btn-outline" [routerLink]="homePath$ | async">
      Back to consultation
    </button>
  </ng-container>
</pushdr-consultation-card>
