<div
  class="absolute inset-0 overflow-x-hidden overflow-y-auto"
  [ngStyle]="{ 'background-color': backgroundColor }"
  [class.rounded]="hasRoundedCorners"
  [class.shadow-md]="hasShadow">
  <ng-content *ngIf="hasData; else loading" #content></ng-content>
</div>

<ng-template #loading>
  <div class="flex items-center justify-center h-full">
    <pushdr-spinner-loader
      [message]="messageOveride + '...'"
      [minheight]="100"></pushdr-spinner-loader>
  </div>
</ng-template>
