<div class="flex flex-col justify-start absolute top-0 bottom-0 w-full">
  <ng-container *ngIf="warnings?.warningsArr?.length > 0; else noWarnings">
    <div class="w-full toggle" [class.active]="activeTab === 0">
      <pushdr-toggle-header
        bg="bg-red-100"
        color="text-red-500"
        icon="warning_amber"
        [label]="hideReferentialInfo ? 'Warnings accumulated' : 'Warnings'"
        [isAlwaysOpen]="hideReferentialInfo"
        [class.active]="activeTab === 0"
        (click)="activeTab = 0"></pushdr-toggle-header>
      <div class="body w-full overflow-auto">
        <pushdr-warning-review
          [warnings]="warnings"
          [hideCheck]="hideReferentialInfo"
          (pdrReviewed)="pdrViewed.emit()">
        </pushdr-warning-review>
      </div>
    </div>
  </ng-container>
  <ng-template #noWarnings>
    <p class="p-5 text-center" [class.flex-grow]="activeTab === 0">
      There are no warnings for this medication
    </p>
  </ng-template>
  <ng-container *ngIf="!hideReferentialInfo">
    <ng-container *ngIf="hasReferentialInformation; else noReferentialInfo">
      <div class="w-full toggle" [class.active]="activeTab === 1">
        <pushdr-toggle-header
          bg="bg-bluegrey-100 hover:bg-indigo-100"
          color="text-bluegrey-800"
          icon="error_outline"
          label="Referential Information"
          [class.active]="activeTab === 1"
          (click)="activeTab = 1"></pushdr-toggle-header>
        <div class="body w-full">
          <pushdr-referential-information [referential]="warnings.referentialInformationArr">
          </pushdr-referential-information>
        </div>
      </div>
    </ng-container>
    <ng-template #noReferentialInfo>
      <p class="text-center pt-2 mb-2 border-t border-grey-200">
        There is no referential information for this medication
      </p>
    </ng-template>
  </ng-container>
</div>
