import { Pipe, PipeTransform } from '@angular/core';

import { Consultation } from '../types/Consultation';

@Pipe({ name: 'filterByPatient' })
export class ConsultationsFilterByPatientPipe implements PipeTransform {
  transform(allConsultations: Consultation[], quickFilter: string = '') {
    if (!allConsultations) {
      return [];
    }
    return allConsultations.filter((c: Consultation) => {
      if (!(quickFilter && quickFilter.length > 1)) {
        return true;
      }
      const checkFields = [c.nhsNumber, c.patientName, c.patientSurname];
      const matchByPatient = checkFields.reduce(
        (result, fieldValue) =>
          result || fieldValue.toLowerCase().indexOf(quickFilter.toLowerCase()) > -1,
        false
      );
      const matchByPrescriptionId = c.prescriptions
        .map(p => p.prescriptionId)
        .reduce((result, id) => result || id.toLowerCase() === quickFilter.toLowerCase(), false);

      return matchByPatient || matchByPrescriptionId;
    });
  }
}
