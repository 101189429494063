<nav class="flex flex-row items-center w-full h-full bg-white">
  <div class="flex flex-grow h-full ml-2 text-center menu">
    <ng-container *ngFor="let recordItem of menuItems$ | async">
      <a
        class="text-sm font-medium nav-item text-bluegrey-800 hover:bg-secondary hover:text-bluegrey-800"
        routerLinkActive="active"
        [routerLink]="recordItem.path">
        {{ recordItem.title }}
      </a>
    </ng-container>
  </div>
  <div class="flex justify-end h-full">
    <pushdr-consultation-menu-actions></pushdr-consultation-menu-actions>
  </div>
</nav>
