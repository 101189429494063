import { Component, OnInit, Input } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SafeResourceUrl } from '@angular/platform-browser';
import { ConsultationHistoryService } from '../../../../services/consultation-history/consultation-history.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'pushdr-notes-pdf-view',
  templateUrl: './notes-pdf-view.component.html',
  styleUrls: ['./notes-pdf-view.component.scss'],
})
export class NotesPdfViewComponent implements OnInit {
  @Input() reportGuid: string;

  pdfReport$: Observable<SafeResourceUrl>;

  constructor(private consultationHistoryService: ConsultationHistoryService) {}

  ngOnInit() {
    this.pdfReport$ = this.consultationHistoryService
      .getConsultationReport$(this.reportGuid)
      .pipe(catchError(err => of(false)));
  }
}
