import {
  Component,
  EventEmitter,
  Input,
  forwardRef,
  OnInit,
  Output,
  HostBinding,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { of, Subject } from 'rxjs';
import { catchError, debounceTime, map, mergeMap, startWith } from 'rxjs/operators';
import { Dispenser, DispensersApiService } from '@pushdr/prescription/api';

interface MappedData<T> {
  values: Array<T>;
  isValid: boolean;
  isLoading: boolean;
}

const DEFAULT_VALUES: MappedData<Dispenser> = {
  values: [],
  isValid: true,
  isLoading: false,
};

const DEFAULT_VALUE = null;
@Component({
  selector: 'pushdr-prescribing-dispenser-search',
  templateUrl: './prescribing-dispenser-search.component.html',
  styleUrls: ['./prescribing-dispenser-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PrescribingDispenserSearchComponent),
      multi: true,
    },
  ],
})
export class PrescribingDispenserSearchComponent
  implements ControlValueAccessor, OnInit, OnChanges
{
  onTouched: () => void;

  dispensers$: Subject<MappedData<Dispenser>> = new Subject<MappedData<Dispenser>>();

  @Input() openOnly = false;
  @Output() dispenserChange = new EventEmitter<Dispenser>();

  @HostBinding('class.--validation-error') @Input() validationError = false;
  control = this._fb.control(DEFAULT_VALUE, Validators.required);

  get searchTerm$() {
    return this._searchTerm$.asObservable();
  }
  private _searchTerm$ = new Subject<string>();

  constructor(private dispensersAPI: DispensersApiService, private _fb: UntypedFormBuilder) {}

  ngOnInit() {
    this.searchTerm$
      .pipe(
        debounceTime(250),
        mergeMap(searchTerm => {
          searchTerm = searchTerm.trim();
          const postCodeRegexp =
            '(([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\\s?[0-9][A-Za-z]{2}))';
          const validPostCodeExp = new RegExp(postCodeRegexp);
          const postCodeOnlyExp = new RegExp(`^${postCodeRegexp}$`);
          const postCodeWithNameExp = new RegExp(
            `(([A-Za-z0-9]+) )*(${postCodeRegexp})+( ([A-Za-z0-9]+))*`
          );
          const isLengthOk = searchTerm && searchTerm.toString().length > 2;

          let isValid = !searchTerm || (isLengthOk && validPostCodeExp.test(searchTerm));
          const isZipOnly = !searchTerm || (isLengthOk && postCodeOnlyExp.test(searchTerm));

          let pharmacyNameCombined: string;
          let zip = null;

          if (isValid) {
            const parts = (isValid && searchTerm.match(postCodeWithNameExp)) || [];
            zip = parts[3];
            pharmacyNameCombined = [parts[2], parts[15]].filter(s => s).join(' ');
            if (!(zip && zip.length > 2 && postCodeOnlyExp.test(zip))) {
              isValid = false;
            }
          } else {
            pharmacyNameCombined = searchTerm;
          }

          const isPharmacyNameLengthOk = pharmacyNameCombined.length >= 2;

          if (isValid && isLengthOk && (isZipOnly || (!isZipOnly && isPharmacyNameLengthOk))) {
            const queryRes =
              isZipOnly || !isPharmacyNameLengthOk
                ? this.dispensersAPI.getDispensersByZip(zip, this.openOnly)
                : this.dispensersAPI.getDispensersByName(pharmacyNameCombined, zip);
            return queryRes.pipe(
              catchError(() => {
                //we have to make it manual, as process is stopped here
                this.dispensers$.next({
                  values: [],
                  isValid,
                  isLoading: false,
                });
                return [];
              }),
              map(values => {
                return {
                  values: values,
                  isValid,
                  isLoading: false,
                };
              }),
              startWith({
                values: [],
                isValid,
                isLoading: true,
              })
            );
          } else {
            return of({ ...DEFAULT_VALUES, isValid });
          }
        })
      )
      .subscribe(res => this.dispensers$.next(res));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.openOnly && changes.openOnly.currentValue !== changes.openOnly.previousValue) {
      this.changeSearchTerm('');
      this.control.setValue(DEFAULT_VALUE);
    }
  }

  registerOnChange(fn: (val: unknown) => void) {
    this.control.valueChanges.subscribe(fn);
  }

  writeValue(val: Dispenser) {
    if (val) {
      this.control.setValue(val);
    }
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  changeSearchTerm(term: string) {
    this._searchTerm$.next(term);
  }

  onChange(value: Dispenser) {
    this.dispenserChange.emit(value);
  }

  dummySearch() {
    return true;
  }
}
