import { Subject, Observable } from 'rxjs';

export abstract class MessageQueue {
  protected ngUnsubscribe$ = new Subject<void>();

  abstract init(connectionConfig?: any): Observable<any>;
  abstract start(): Observable<any>;
  abstract stop(): Observable<any>;
  abstract destroy(): Observable<any>;

  abstract reconnect(): Observable<any>;
  abstract send(msg: string): void;

  abstract onMessage(filter: ((msg: string) => boolean) | string | void): Observable<string>;

  abstract onError(): Observable<any>;
  abstract onDisconnect(): Observable<any>;
  abstract onReconnect(): Observable<any>;

  protected unsubscribe() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
