<pushdr-consultation-card [hasData]="true" [showFooter]="true">
  <pushdr-prescription-card-header header></pushdr-prescription-card-header>

  <ng-container body>
    <pushdr-input-with-character-count
      [optional]="true"
      [multiline]="6"
      [max]="250"
      [control]="patientNotesControl"
      label="Add patient note">
    </pushdr-input-with-character-count>
  </ng-container>
  <ng-container footer>
    <div class="flex mt-3 mb-3">
      <div class="flex flex-col items-center w-full">
        <div class="flex mt-3 mb-3">
          <button
            data-cy="add-another-drug-button"
            class="p-3 px-5 mr-3 btn text-primary border-primary"
            (click)="navigateToBasket()">
            Back
          </button>
          <button
            data-cy="specify-button"
            class="p-3 px-5 text-white btn bg-primary"
            (click)="setNote()">
            Proceed
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</pushdr-consultation-card>
