import { Component, ElementRef, OnInit } from '@angular/core';
import {
  AddressLinesTownPC,
  NhsAddress,
  NhsGenderInfo,
  PatientDetailsV2,
  SurgeryType,
} from '@pushdr/common/types';
import * as moment from 'moment';
import { map } from 'rxjs';

import { ConsultationFeatureService } from '../../../../services/consultation-feature/consultation-feature.service';
import { PatientDetailsService } from '../../../../services/patient-details/patient-details.service';

@Component({
  selector: 'pushdr-key-information',
  templateUrl: './key-information.component.html',
  styleUrls: ['./key-information.component.scss'],
})
export class KeyInformationComponent implements OnInit {
  keyInfoViewModel$ = this.patient.details$.pipe(map(x => this.mapDataFor(x)));

  isConsultingWithChild$ = this.consultationFeatures.isConsultingWithChild$;
  alerts$ = this.patient.alerts$;

  liteMode = false;
  showingAlerts = false;

  constructor(
    private patient: PatientDetailsService,
    private hostElement: ElementRef,
    private consultationFeatures: ConsultationFeatureService
  ) {}

  ngOnInit() {
    const hostComputedStyles = getComputedStyle(this.hostElement.nativeElement);
    this.liteMode = hostComputedStyles.gridArea.indexOf('detail') > -1;
  }

  private mapDataFor({ Patient }: PatientDetailsV2): KeyInformation {
    const pds = Patient?.NhsInformation?.SuccessResponse;
    const names = pds ? [...pds.GivenNames, pds.FamilyName] : [Patient?.Name];

    return {
      child: this.mapChildInfo(Patient),
      name: names.join(' '),
      dateOfBirth: pds?.DateOfBirth ?? Patient?.DateOfBirth,
      email: Patient?.Email,
      phone: Patient?.Phone,
      age: moment().diff(pds?.DateOfBirth ?? Patient?.DateOfBirth, 'years'),
      nhsNumber: pds?.NhsNumber ?? Patient?.NhsNumber,
      genderLabel: NhsGenderInfo[pds?.Gender]?.displayName ?? Patient?.GenderLabel,
      patientAddress: pds?.PatientAddress
        ? this.fromNhsAddress(pds.PatientAddress)
        : this.fromPdAddress(Patient?.Address),
      surgeryTypeId: Patient?.GpSummary?.SurgeryTypeId,
      surgeryCode: Patient?.GpSummary?.SurgeryCode,
      partner: {
        odsCode: pds?.OrganisationDataServiceCode ?? Patient?.GpSummary?.OdsCode,
        name: pds?.PracticeName ?? Patient?.GpSummary?.Name,
        address: pds?.PracticeAddress
          ? this.fromNhsAddress(pds.PracticeAddress)
          : this.fromPdAddress(Patient?.GpSummary?.Address),
      },
      isEmis: Patient?.GpSummary?.SurgeryTypeId === SurgeryType.EMIS,
    };
  }

  private mapChildInfo = (Patient: PatientDetailsV2['Patient']): KeyInformationChild => ({
    name: `${Patient?.ChildVerification?.FirstName} ${Patient?.ChildVerification?.LastName}`,
    dob: Patient?.ChildVerification?.DateOfBirth,
    gender: this.getGenderName(Patient.ChildVerification?.Gender),
  });

  private fromPdAddress = (p: AddressLinesTownPC) =>
    [p.Line1, p.Line2, p.Line3, p.Line4, p.Line5, p.PostTown, p.Postcode]
      .filter(f => f)
      .join('\r\n');

  private fromNhsAddress = (p: NhsAddress) =>
    [...p.AddressLines, p.Postcode].filter(f => f).join('\r\n');

  getGenderName(gender: number) {
    switch (gender) {
      case 1:
        return 'MALE';
      case 2:
        return 'FEMALE';
      case 3:
        return 'UNSPECIFIED';
    }
    return 'UNKNOWN';
  }

  showAlerts() {
    this.showingAlerts = true;
  }

  hideAlerts() {
    this.showingAlerts = false;
  }
}
export interface KeyInformation {
  child: KeyInformationChild;
  partner: KeyInformationPartner;
  email: string;
  phone: string;
  name: string;
  dateOfBirth: string;
  age: number;
  genderLabel: string;
  patientAddress: string;
  nhsNumber: string;
  surgeryTypeId: number;
  surgeryCode: string;
  isEmis: boolean;
}
export interface KeyInformationChild {
  name: string;
  dob: string;
  gender: string;
}

export interface KeyInformationPartner {
  address: string;
  name: string;
  odsCode: string;
}
