import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';

export interface DatePickerDay {
  value: number;
  available: boolean;
  isToday: boolean;
  isPast: boolean;
  isHighlighted: boolean;
}

@Component({
  selector: 'pushdr-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit, OnChanges {
  @Input() canChangeNavMonthPredicate: (dayIndex: number, date: Moment) => boolean;
  @Input() isAvailablePredicate: (moment: Moment) => boolean;
  @Input() selectOnFocus = true;
  @Input() deselectOnBlur = true;
  @Input() navDate: Moment = moment().startOf('day');
  @Input() highlightDates: Moment[] = [];
  @Input() selectedDate: Moment;

  private _maxDate: Moment;
  @Input()
  set maxDate(value) {
    console.log(value);
    if (value && typeof value === 'string') this._maxDate = moment(value);
    else this._maxDate = value;
    this.makeGrid();
  }
  get maxDate() {
    return this._maxDate;
  }

  @Output() emitChangedMonth = new EventEmitter<Moment>();
  @Output() emitSelectedDate = new EventEmitter<Moment>();

  weekDaysHeaderArr: Array<string> = [];
  gridArr: Array<DatePickerDay> = [];

  ngOnInit() {
    this.makeHeader();
    this.makeGrid();
  }

  ngOnChanges(changes: SimpleChanges) {
    const gridDirty =
      this.navDate &&
      (changes.appointments || changes.navDate || changes.selectDay || changes.highlightDates);
    if (gridDirty) {
      this.makeGrid();
    }
  }

  onFocus(day: DatePickerDay) {
    if (this.selectOnFocus) {
      this.selectDay(day);
    }
  }

  onBlur() {
    if (this.deselectOnBlur) {
      this.unSelectDay();
    }
  }

  changeNavMonth(num: number) {
    if (this.canChangeNavMonth(num)) {
      this.navDate.add(num, 'month');
      this.makeGrid();
      this.emitChangedMonth.emit(this.navDate);
    }
  }

  canChangeNavMonth(num: number) {
    if (this.canChangeNavMonthPredicate) {
      const clonedDate = moment(this.navDate);
      return this.canChangeNavMonthPredicate(num, clonedDate);
    } else {
      return true;
    }
  }

  private selectDay(day: DatePickerDay) {
    if (day && day.available) {
      this.selectedDate = this.dateFromNum(day.value, this.navDate);
      this.emitSelectedDate.emit(this.selectedDate);
    }
  }

  private unSelectDay() {
    this.selectedDate = null;
    this.emitSelectedDate.emit(null);
  }

  private makeHeader() {
    const weekDaysArr: Array<number> = [0, 1, 2, 3, 4, 5, 6];
    this.weekDaysHeaderArr = weekDaysArr.map(day =>
      moment().weekday(day).format('dd').toUpperCase()
    );
  }

  private makeGrid() {
    this.gridArr = [];

    const firstDayDate = moment(this.navDate).startOf('month');
    const initialEmptyCells = firstDayDate.weekday();
    const lastDayDate = moment(this.navDate).endOf('month');
    const lastEmptyCells = 6 - lastDayDate.weekday();
    const daysInMonth = this.navDate.daysInMonth();
    const arrayLength = initialEmptyCells + lastEmptyCells + daysInMonth;

    for (let i = 0; i < arrayLength; i++) {
      let value, available, isToday, isPast, isHighlighted;
      if (i < initialEmptyCells) {
        value = moment(firstDayDate)
          .add(i - initialEmptyCells, 'day')
          .date();
        available = false;
        isHighlighted = false;
      } else if (i > initialEmptyCells + daysInMonth - 1) {
        value = moment(firstDayDate)
          .add(i - (initialEmptyCells + daysInMonth), 'day')
          .date();
        available = false;
        isHighlighted = false;
      } else {
        value = i - initialEmptyCells + 1;
        isToday = moment(this.navDate).date(value).isSame(new Date(), 'day');
        isPast = moment(this.navDate).date(value).isBefore(new Date(), 'day');
        if (this.maxDate && moment(this.navDate).date(value) > this.maxDate) {
          available = false;
          isHighlighted = false;
        } else {
          available = this.isAvailable(i - initialEmptyCells + 1);
          isHighlighted = this.isHighlighted(value);
        }
      }
      const day = {
        value,
        available,
        isToday,
        isPast,
        isHighlighted,
      };
      this.gridArr.push(day);
    }
  }

  private isAvailable(num: number): boolean {
    if (this.isAvailablePredicate) {
      const dateToCheck = this.dateFromNum(num, this.navDate);
      return this.isAvailablePredicate(dateToCheck);
    } else {
      return true;
    }
  }

  private isHighlighted(num: number): boolean {
    const dateToCheck = this.dateFromNum(num, this.navDate);
    return (
      this.highlightDates && !!this.highlightDates.find(date => date.isSame(dateToCheck, 'date'))
    );
  }

  private dateFromNum(num: number, referenceDate: any): Moment {
    return moment(referenceDate).date(num);
  }
}
