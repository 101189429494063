<ng-select
  placeholder="Search by prescriber"
  aria-live="polite"
  [items]="prescribers$ | async"
  [searchFn]="dummySearch"
  bindLabel="name"
  [notFoundText]="'No matching prescribers'"
  [formControl]="control"
  (keyup)="changeSearchTerm($event.target.value)"
  (change)="onChange($event)"
  [clearable]="false"
  [markFirst]="true">
  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
    <div class="flex items-center">
      <div class="flex-grow mr-2">
        <p class="mb-0 whitespace-normal font-bold mr-1">
          {{ item.name }}
        </p>
      </div>
    </div>
  </ng-template>
</ng-select>
