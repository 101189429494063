<div class="mt-8 c-modal__wrap c-modal__wrap--40" [cdkTrapFocus]="ready$ | async">
  <div class="modal__body">
    <h2>Your session is ready to be completed</h2>
  </div>
  <div class="flex flex-row">
    <button
      (click)="done(true)"
      class="block w-full mx-2 btn btn-primary"
      data-cy="btn-end-session">
      End session
    </button>
    <button (click)="done(false)" class="block w-full mx-2 btn btn-outline">
      Continue session
    </button>
  </div>
</div>
