import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FdbPrescribeComponent } from './fdb-prescribe/fdb-prescribe.component';
import { EquivalentsSelectComponent } from './equivalents-select/equivalents-select.component';
import { PrescriptionFormComponent } from './prescription-form/prescription-form.component';
import { FdbSearchMedicationsComponent } from './fdb-search-medications/fdb-search-medications.component';
import { QuantityInWordsPipe } from './pipes/quantity-in-words.pipe';
import { MedicationPackSizesPipe } from './pipes/medication-pack-sizes.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonComponentsModule, SpinnerLoaderModule } from '@pushdr/common/components';
import { CommonPipesModule } from '@pushdr/common/utils';
import { ClinicalDecisionSupportModule } from './clinical-decision-support/clinical-decision-support.module';
import { SimpleModalModule } from 'ngx-simple-modal';

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    ReactiveFormsModule,
    FormsModule,
    CommonComponentsModule,
    SpinnerLoaderModule,
    CommonPipesModule,
    // Lazy loaded modules require SimpleModalModule setting every time :(
    SimpleModalModule.forRoot(
      { container: document.body },
      {
        closeOnEscape: false,
        closeOnClickOutside: false,
        wrapperDefaultClasses: 'c-modal',
        bodyClass: 'js-modal-open',
        wrapperClass: 'c-modal--is-visible',
        animationDuration: 300,
        autoFocus: true,
      }
    ),
  ],
  declarations: [
    FdbPrescribeComponent,
    EquivalentsSelectComponent,
    PrescriptionFormComponent,
    FdbSearchMedicationsComponent,
    QuantityInWordsPipe,
    MedicationPackSizesPipe,
  ],
  exports: [FdbSearchMedicationsComponent, FdbPrescribeComponent, ClinicalDecisionSupportModule],
})
export class PrescriptionFdbModule {}
