<pushdr-consultation-card [hasData]="true" [showFooter]="true">
  <span class="text-information" header> Emergency recorded </span>
  <ng-container body>
    <p>
      You just notified us informing of an
      <strong>
        emergency or a serious threat to the well being of the patient you are connected to
      </strong>
      on Push Doctor. If this is correct, please maintain connection with the patient and dial
      <strong>999</strong>
      from the nearest phone.
    </p>
    <p>
      For future reference, please only use the ‘Emergency’ button when you believe there is an
      <strong>emergency situation</strong>
      concerning the patient that you are connected with.
    </p>
    <ng-container *ngIf="details$ | async as details; else loading">
      <ng-container *ngIf="isChildConsultation$ | async">
        <p class="mt-4 mb-2 underline">Child details</p>
        <div class="w-full p-4 rounded bg-bluegrey-200">
          <div data-cy="patient-details-container" class="flex flex-col flex-grow">
            <div class="flex flex-wrap">
              <div class="inline-block mb-4 mr-4 detail-block">
                <span class="block mb-1 font-light title text-grey-500">Name</span>
                <span class="font-medium detail text-bluegrey-800"
                  >{{ details?.Patient.ChildVerification?.FirstName }}
                  {{ details?.Patient.ChildVerification?.LastName }}</span
                >
              </div>
              <div class="inline-block mb-4 mr-4 detail-block">
                <span class="block mb-1 font-light title text-grey-500">Date of birth</span>
                <span class="detail text-bluegrey-800">
                  {{ details?.Patient.ChildVerification.DateOfBirth }}
                </span>
              </div>
              <div class="inline-block mb-4 mr-4 detail-block">
                <span class="block mb-1 font-light title text-grey-500">Gender</span>
                <span
                  class="detail text-bluegrey-800"
                  *ngIf="details?.Patient.ChildVerification?.Gender === 1"
                  >MALE</span
                >
                <span
                  class="detail text-bluegrey-800"
                  *ngIf="details?.Patient.ChildVerification?.Gender === 2"
                  >FEMALE</span
                >
                <span
                  class="detail text-bluegrey-800"
                  *ngIf="details?.Patient.ChildVerification?.Gender === 3"
                  >UNSPECIFIED</span
                >
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <p class="my-4 underline">Account holder's details</p>
      <div class="w-full p-4 rounded bg-bluegrey-200">
        <div data-cy="patient-details-container" class="flex flex-col flex-grow">
          <div class="flex flex-wrap">
            <div class="inline-block mb-4 mr-4 detail-block">
              <span class="block mb-1 font-light title text-grey-500">Name</span>
              <span class="font-medium detail text-bluegrey-800">{{ details?.Patient.Name }}</span>
            </div>
            <div class="inline-block mb-4 mr-4 detail-block">
              <span class="block mb-1 font-light title text-grey-500">Date of birth (Age)</span>
              <span class="detail text-bluegrey-800">
                {{ details?.Patient.DateOfBirth }} ({{ details?.Patient.Age }})
              </span>
            </div>
            <div class="inline-block mb-4 mr-4 detail-block">
              <span class="block mb-1 font-light title text-grey-500">Gender</span>
              <span class="detail text-bluegrey-800">{{ details?.Patient.GenderLabel }}</span>
            </div>
            <div class="inline-block mb-4 mr-4 detail-block">
              <span class="block mb-1 font-light title text-grey-500">Mobile</span>
              <span class="detail text-bluegrey-800">{{ details?.Patient.Phone }}</span>
            </div>

            <div class="block w-full mb-4 mr-4 detail-block address-block">
              <span class="block mb-1 font-light title text-grey-500">Home Address</span>
              <span class="block detail text-bluegrey-800">
                {{ details?.Patient.Address.Line1 }} {{ details?.Patient.Address.Line2 }}
              </span>
              <span class="block detail text-bluegrey-800">{{
                details?.Patient.Address.Line3
              }}</span>
              <span class="block detail text-bluegrey-800">{{
                details?.Patient.Address.PostTown
              }}</span>
              <span class="block detail text-bluegrey-800">{{
                details?.Patient.Address.Postcode
              }}</span>
            </div>
            <div class="inline-block mb-4 mr-4 detail-block">
              <span class="block mb-1 font-light title text-grey-500">NHS Number</span>
              <span class="detail text-bluegrey-800">
                {{ details?.Patient.NhsInformation.SuccessResponse?.NhsNumber || 'Undetermined' }}
              </span>
            </div>
          </div>
          <div class="flex flex-wrap">
            <div class="block mb-4 mr-4 detail-block">
              <span class="block mb-1 font-light title text-grey-500">Partner name</span>
              <span class="detail text-bluegrey-800">{{ details?.Partner?.Name || 'None' }}</span>
            </div>
            <div class="block mb-4 mr-4 detail-block">
              <span class="block mb-1 font-light title text-grey-500">ODS</span>
              <span class="detail text-bluegrey-800">
                {{ details?.Patient.GpSummary?.OdsCode || 'None' }}
              </span>
            </div>
            <div class="block mb-4 mr-4 detail-block address-block">
              <span class="block mb-1 font-light title text-grey-500">GP address</span>
              <span class="block detail text-bluegrey-800">
                {{ details?.Patient.GpSummary?.Name }}
              </span>
              <span class="block detail text-bluegrey-800">
                {{ details.Patient.GpSummary?.Address.Line1 }}
                {{ details.Patient.GpSummary?.Address.Line2 }}
              </span>
              <span class="block detail text-bluegrey-800">{{
                details.Patient.GpSummary?.Address.Line3
              }}</span>
              <span class="block detail text-bluegrey-800">{{
                details.Patient.GpSummary?.Address.PostTown
              }}</span>
              <span class="block detail text-bluegrey-800">{{
                details.Patient.GpSummary?.Address.Postcode
              }}</span>
            </div>
            <div
              *ngIf="details?.Patient.NhsInformation?.SuccessResponse?.OrganisationDataServiceCode"
              class="block mb-4 mr-4 detail-block">
              <span class="block mb-1 font-light title text-grey-500">NHS ODS</span>
              <span class="detail text-bluegrey-800">
                {{ details?.Patient.NhsInformation?.SuccessResponse?.OrganisationDataServiceCode }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-container footer>
    <button class="w-2/5 p-4 m-5 btn btn-outline" (click)="onClickBackToConsultation()">
      Back to consultation
    </button>
  </ng-container>
</pushdr-consultation-card>

<ng-template #loading>Loading patient details</ng-template>
