import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { SpinnerLoaderModule } from '@pushdr/common/components';
import { ClinicalDecisionSupportComponent } from './clinical-decision-support.component';
import { ReferentialInformationComponent } from './referential-information/referential-information.component';
import { TabGroupComponent } from './tab-group/tab-group.component';
import { ToggleHeaderComponent } from './toggle-header/toggle-header.component';
import { WarningReviewComponent } from './warning-review/warning-review.component';

@NgModule({
  declarations: [
    ClinicalDecisionSupportComponent,
    TabGroupComponent,
    ToggleHeaderComponent,
    WarningReviewComponent,
    ReferentialInformationComponent,
  ],
  imports: [CommonModule, ReactiveFormsModule, NgSelectModule, SpinnerLoaderModule],
  exports: [ClinicalDecisionSupportComponent, ToggleHeaderComponent],
})
export class ClinicalDecisionSupportModule {}
