import { Component } from '@angular/core';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConsultationState, ConsultationStateService } from '@pushdr/clinicians/common';
import { EndConsultationPlaceEnum } from '../../services/end-consultation/end-consultation.service';
import { ConsultationFeatureService } from '../../services/consultation-feature/consultation-feature.service';

@Component({
  selector: 'pushdr-consultation-menu-actions',
  templateUrl: './consultation-menu-actions.component.html',
  styleUrls: ['./consultation-menu-actions.component.scss'],
})
export class ConsultationMenuActionsComponent {
  EndConsultationPlaceEnum = EndConsultationPlaceEnum;

  isInLiveConsultationState$ = this.consultationState.state$.pipe(
    map(state => state === ConsultationState.CONSULTING)
  );

  isInEditConsultationState$ = this.consultationState.state$.pipe(
    map(state => state === ConsultationState.EDITING)
  );

  canTakeNotes$ = this.consultationFeature.canTakeNotes$;
  canPrescribe$ = this.consultationFeature.canPrescribe$;
  canSeeHtmlRecords$ = this.consultationFeature.canSeeRecordsHTML$;
  canSeeStructRecords$ = this.consultationFeature.canSeeRecordsStructured$;
  canWriteFitNote$ = this.consultationFeature.canWriteFitNote$;
  canWriteReferral$ = this.consultationFeature.canWriteReferral$;

  hasTranslationEnabled$ = this.consultationFeature.hasTranslationServiceEnabled$;

  constructor(
    private consultationState: ConsultationStateService,
    private consultationFeature: ConsultationFeatureService
  ) {}
}
