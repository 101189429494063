import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'britishDate',
  pure: false,
})
export class BritishDatePipe implements PipeTransform {
  transform(value: Date, format: string = 'mediumDate'): string {
    if (value) {
      return moment.tz(value, 'UTC').tz('Europe/London').format(format);
    } else {
      return '';
    }
  }
}
