import { Injectable } from '@angular/core';
import { ModalService } from '@pushdr/common/overlay';
import { ApiDoctorsAccount } from '@pushdr/doctors/data-access/doctors-api';
import { Observable } from 'rxjs';
import { publishReplay, refCount, switchMap, take } from 'rxjs/operators';
import { CliniciansIframeModalComponent } from '../../components/iframe-modal';

export const DEFAULT_TERMS_TTL = 60 * 60 * 1000; //1hr

@Injectable({
  providedIn: 'root',
})
export class CliniciansTermsCheckerService {
  terms$: Observable<boolean>;

  constructor(private api: ApiDoctorsAccount, private modal: ModalService) {}

  check(ttl: number = DEFAULT_TERMS_TTL): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getUnreadTerms(ttl).subscribe({
        next: terms => {
          if (terms) {
            this.showTermsModal()
              .pipe(switchMap(() => this.api.confirmTerms()))
              .subscribe(() => {
                this.terms$ = null;
                resolve();
              });
          } else {
            console.log('no terms');
            resolve();
          }
        },
      });
    });
  }

  getUnreadTerms(ttl: number = DEFAULT_TERMS_TTL) {
    if (!this.terms$) {
      this.terms$ = this.api.checkForTerms().pipe(publishReplay(1, ttl), refCount(), take(1));
    }
    return this.terms$;
  }

  showTermsModal() {
    return this.modal.showCustom(CliniciansIframeModalComponent, {
      title: 'Terms and conditions',
      linkToDocument: 'https://www.pushdoctor.co.uk/doctor/terms',
    });
  }
}
