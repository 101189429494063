import { Injectable, OnDestroy } from '@angular/core';
import { Subject, ReplaySubject } from 'rxjs';
import { filter, takeUntil, shareReplay, map, distinctUntilChanged } from 'rxjs/operators';
import { StorageService } from '@pushdr/common/utils';
import { AnalyticsIntegrationConfig } from '../analytics-types';
import { AnalyticsBusService } from '../analytics-bus/analytics-bus.service';

export interface ErrorLog {
  target: string;
  userIdMessaged: string;
  properties: any;
}

@Injectable({
  providedIn: 'root',
})
export class ErrorLogsService implements OnDestroy {
  userid: string;

  private _logs = new Array<ErrorLog>();
  private _config: AnalyticsIntegrationConfig;
  private _logs$ = new ReplaySubject<ErrorLog[]>(1);
  private _ngUnsubscribe$ = new Subject<void>();

  constructor(private bus: AnalyticsBusService, private storage: StorageService) {}

  initialise(config: AnalyticsIntegrationConfig) {
    this._config = config;
    this.subscribeToUser();
    this.subscribeToEvents();
    this.userid = this.storage.get('pdr_uid');
  }

  get logs$() {
    return this._logs$.asObservable();
  }

  clear() {
    this._logs = [];
    this._logs$.next(this._logs);
  }

  ngOnDestroy() {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }

  private subscribeToEvents() {
    this.bus.eventBus$
      .pipe(
        filter(event => this._config.events.includes(event.action)),
        takeUntil(this._ngUnsubscribe$)
      )
      .subscribe(event => this.parseLogEvent(event));
  }

  private parseLogEvent(event) {
    const message = event.properties.message || JSON.stringify(event.properties);
    if (message) {
      const target = event.view ? `${event.target}:${event.view}` : event.target;
      this.log(target, {
        message,
        properties: event.properties ? JSON.stringify(event.properties) : '',
      });
    }
  }

  private subscribeToUser() {
    const user$ = this.bus.userBus$.pipe(shareReplay(1));

    user$
      .pipe(
        map(user => user.id as string),
        distinctUntilChanged(),
        takeUntil(this._ngUnsubscribe$)
      )
      .subscribe(userId => {
        if (userId) {
          this.storage.set('pdr_uid', userId);
          this.userid = userId;
        }
      });
  }

  private log(target: string, { properties, message }) {
    const messageString = typeof message === 'string' ? message : JSON.stringify(message);
    const userIdMessaged = this.userid ? `${this.userid}: ${messageString}` : messageString;
    if (this._logs.length === 500) {
      // to help keep downloaded logs relevant to the issue and to stop csv download from failing for being too large
      this._logs.shift();
    }
    this._logs.push({ target, userIdMessaged, properties });
    this._logs$.next(this._logs);
  }
}
