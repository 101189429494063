import { Injectable } from '@angular/core';
import { ApiDoctorsConsultation as ApiCliniciansConsultation } from '@pushdr/doctors/data-access/doctors-api';
import { zip } from 'rxjs';
import { StorageService } from '@pushdr/common/utils';
import { CurrentOrderIdService } from '@pushdr/common/data-access/analytics';
import { ApiDoctorsConsultationNew } from '@pushdr/doctors/data-access/doctors-api';

@Injectable({
  providedIn: 'root',
})
export class ConsultationSummaryService {
  constructor(
    private api: ApiCliniciansConsultation,
    private apiNew: ApiDoctorsConsultationNew,
    private storage: StorageService,
    private order: CurrentOrderIdService
  ) {}

  set otherReasonText(txt: string) {
    this.storage.setSessionStorage('_otherReasonText_', txt);
  }

  get otherReasonText() {
    return this.storage.getSessionStorage('_otherReasonText_', true);
  }

  getClinicalOutcomes() {
    return this.apiNew.getClinicalOutcomes();
  }

  getReferralTypes(partnerId: string) {
    return this.apiNew.getReferralTypes(partnerId);
  }

  createClinicalOutcome(
    clinicalOutcomeIds: number[],
    referralServiceId: number,
    consultationId: number
  ) {
    return this.apiNew.createClinicalOutcome(clinicalOutcomeIds, referralServiceId, consultationId);
  }

  sendClericalNote(clericalNote: string) {
    return zip(this.api.sendClericalNote(this.order.id, clericalNote));
  }
}
