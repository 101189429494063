import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericModalComponent } from './modal/generic-modal/generic-modal.component';
import { SimpleModalModule } from 'ngx-simple-modal';
import { ModalService } from './modal/modal.service';
import { SuccessModalComponent } from './modal/success-modal/success-modal.component';
import { AcknowledgeModalComponent } from './modal/acknowledge-modal/acknowledge-modal.component';
import { ConfirmModalComponent } from './modal/confirm-modal/confirm-modal.component';
import { ErrorModalComponent } from './modal/error-modal/error-modal.component';
import { LoaderComponent } from './modal/loader/loader.component';
import { A11yModule } from '@angular/cdk/a11y';
import { FormGroupModalComponent } from './modal/form-group-modal/form-group-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonPipesModule, VarModule } from '@pushdr/common/utils';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    SimpleModalModule,
    A11yModule,
    FormsModule,
    ReactiveFormsModule,
    CommonPipesModule,
    VarModule,
    NgSelectModule,
  ],
  providers: [ModalService],
  declarations: [
    GenericModalComponent,
    SuccessModalComponent,
    AcknowledgeModalComponent,
    ConfirmModalComponent,
    ErrorModalComponent,
    LoaderComponent,
    FormGroupModalComponent,
  ],
  exports: [
    GenericModalComponent,
    SuccessModalComponent,
    AcknowledgeModalComponent,
    ConfirmModalComponent,
    ErrorModalComponent,
    LoaderComponent,
    SimpleModalModule,
    FormGroupModalComponent,
  ],
})
export class CommonOverlayModule {}
