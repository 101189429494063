import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DoctorSession as ClinicianSession } from '@pushdr/doctors/data-access/doctors-api';
import * as moment from 'moment';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { formattedDurationHHMMSS } from '../../utils/time-since-as-string';

export interface ClinicianStats {
  currentTime: string;
  affix: string;
  surgeryTypes: string[];
  percent: number;
}

@Component({
  selector: 'pushdr-session-stats',
  templateUrl: './session-stats.component.html',
  styleUrls: ['./session-stats.component.scss'],
})
export class SessionStatsComponent implements OnInit, OnDestroy {
  @Input() session: ClinicianSession;

  stats: ClinicianStats = null;
  private ngUnsubscribe$ = new Subject<void>();
  private readonly timerViewUpdateFrequency = 1000;

  ngOnInit() {
    this.startTimer();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  private startTimer() {
    timer(0, this.timerViewUpdateFrequency)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe({
        next: () => {
          if (!this.session) return;
          const isEarly = moment().isBefore(this.session.startTime);
          const isFinished = moment().isAfter(this.session.endTime);

          const sessionLengthTime = formattedDurationHHMMSS(
            this.session.startTime,
            this.session.endTime
          );

          const currentTime = isEarly
            ? sessionLengthTime
            : isFinished
            ? '0:00:00'
            : formattedDurationHHMMSS(moment(), this.session.endTime);

          const affix = isEarly
            ? `(Starts in ${moment
                .duration(this.session.startTime.diff(moment()))
                .humanize(false)})`
            : isFinished
            ? `(Ended ${moment.duration(moment().diff(this.session.endTime)).humanize()} ago)`
            : '';

          this.stats = {
            currentTime,
            affix,
            percent: this.percentComplete(),
            surgeryTypes: this.session.tags.SurgeryType,
          };
        },
      });
  }

  private percentComplete() {
    if (!this.session) return 0;

    const max = this.session.endTime.diff(this.session.startTime, 'seconds');
    const elapsed = Math.min(max, Math.max(0, moment().diff(this.session.startTime, 'seconds')));
    return (elapsed / max) * 100;
  }
}
