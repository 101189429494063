import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { CliniciansAuthService } from '@pushdr/clinicians/common';
import { DoctorInfoService } from '@pushdr/common/utils';

@Injectable({
  providedIn: 'root',
})
export class HasNoAuthGuard implements CanActivate {
  constructor(
    private auth: CliniciansAuthService,
    private doctorInfo: DoctorInfoService,
    private router: Router
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (
      !this.auth.hasAuth() ||
      !this.doctorInfo.get()?.LastPasswordUpdate ||
      this.doctorInfo.getPasswordResetNeeded()
    ) {
      return true;
    } else {
      return this.router.createUrlTree(['/home']);
    }
  }
}
