<div [@showContent]>
  <h4>Verify mobile number</h4>
  <p>Please enter the 4 digit security code that we have just sent to your mobile device.</p>
  <form [formGroup]="secureMobile" class="mb-4">
    <div class="mb-2">
      <label class="block mb-1 text-base" for="ipt-code"> Security code </label>
      <div
        class="pdr-input--security-code-wrapper"
        [class.pdr-input--error]="invalidCode"
        [class.success]="success"
        id="ipt-code">
        <input
          formControlName="securityCode1"
          name="securityCode1"
          class="pdr-input pdr-input--security-code"
          type="number"
          minlength="1"
          maxlength="1"
          (click)="handleClickToFocusOnInput($event)"
          (input)="handleInput($event)" />
        <input
          formControlName="securityCode2"
          name="securityCode2"
          class="pdr-input pdr-input--security-code"
          type="number"
          minlength="1"
          maxlength="1"
          (click)="handleClickToFocusOnInput($event)"
          (input)="handleInput($event)" />
        <input
          formControlName="securityCode3"
          name="securityCode3"
          class="pdr-input pdr-input--security-code"
          type="number"
          minlength="1"
          maxlength="1"
          (click)="handleClickToFocusOnInput($event)"
          (input)="handleInput($event)" />
        <input
          formControlName="securityCode4"
          name="securityCode4"
          class="pdr-input pdr-input--security-code"
          type="number"
          minlength="1"
          maxlength="1"
          (click)="handleClickToFocusOnInput($event)"
          (input)="handleInput($event)" />
      </div>
      <p *ngIf="invalidCode" class="mt-2 text-sm font-normal text-error">Invalid code</p>
    </div>
  </form>
  <button
    class="px-0 py-1 text-sm text-indigo-700 btn hover:underline"
    *ngIf="!textResent"
    (click)="resendCode($event)">
    Not received a code? Click here
  </button>
  <p *ngIf="textResent" class="px-0 py-1 text-sm font-normal text-indigo-700">
    Code has been sent to your device
  </p>
</div>
