<pushdr-card [hasData]="true" [class.video-fullscreen]="fullScreenVideo">
  <section class="flex flex-col w-full h-full">
    <header
      *ngIf="patient$ | async as patient"
      class="z-10 flex justify-between px-5 py-3 text-xl bg-grey-100 shadow-header text-bluegrey-800">
      {{ patient.Patient.Name }}
      <span class="flex items-center">
        {{ formattedCounter$ | async }}
        <span
          class="block ml-4 -mt-1 text-lg cursor-pointer material-icons"
          [title]="fullScreenVideo ? 'Minimise' : 'Full screen'"
          (click)="toggleFullscreen()">
          {{ fullScreenVideo ? 'close_fullscreen' : 'open_in_full' }}
        </span>
        <span
          *ngIf="lostConnection"
          class="block ml-4 -mt-1 text-lg cursor-pointer material-icons"
          title="End Video Session"
          (click)="endVideoSession(patient.IsCallRecordingEnabled)">
          close
        </span>
      </span>
    </header>
    <article
      *ngIf="videoSession$ | async as videoSession"
      class="relative flex-auto h-px overflow-y-auto bg-bluegrey-800 video-watermark scrollbar"
      [ngSwitch]="videoSession.status">
      <ng-container *ngSwitchCase="'connected'">
        <pushdr-opentok
          [videoParameters]="videoSession.credentials"
          (errored)="logError($event)"
          (participants)="handlePartipantCountChange($event)">
        </pushdr-opentok>
        <div
          *ngIf="participants < 2"
          class="absolute inset-0 flex flex-col items-center justify-center w-full h-full">
          <ng-container *ngIf="!connectedToPatient">
            <span
              class="mb-6 text-2xl font-medium text-center text-secondary connecting-msg loading"
              >Waiting for patient to connect</span
            >
            <p class="w-2/3 text-center text-bluegrey-100">
              If no patient connects please disrupt the consultation
              and&nbsp;return&nbsp;to&nbsp;the&nbsp;waiting&nbsp;room
            </p>
          </ng-container>

          <ng-container *ngIf="connectedToPatient">
            <h3 class="reconnecting-msg text-secondary loading">The patient has disconnected</h3>
            <p class="w-2/3 text-center text-bluegrey-100">
              Please complete any remaining administrative tasks before you end the consultation
            </p>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <div class="absolute inset-0 flex flex-col items-center justify-center w-full h-full">
          <h3 class="reconnecting-msg text-secondary" [ngSwitch]="isRecording$ | async">
            <span *ngSwitchCase="true">Video recording ended</span>
            <span *ngSwitchDefault>Video session ended</span>
          </h3>
        </div>
      </ng-container>
      <pushdr-messenger-chat></pushdr-messenger-chat>
    </article>
  </section>
</pushdr-card>
