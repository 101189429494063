import { retryBackoff } from 'backoff-rxjs';
import * as moment from 'moment';
import { concat, Observable, pipe } from 'rxjs';
import { debounceTime, map, publishReplay, refCount, switchMap, take, tap } from 'rxjs/operators';

export function retryDefault() {
  return pipe(
    retryBackoff({
      initialInterval: 2000,
      maxRetries: 3,
      shouldRetry: () => true,
    })
  );
}

export function retryCache() {
  return pipe(retryDefault(), publishReplay(1), refCount());
}

export function cache<t>() {
  return pipe(publishReplay<t>(1), refCount());
}

export const dateSortFunc =
  <T>(sortField: (value: T) => string | Date | moment.Moment, descending: boolean = false) =>
  (entity1: T, entity2: T) =>
    +moment(sortField(entity1)).isAfter(moment(sortField(entity2))) ^ +descending ? 0 : -1;

export const sortDate = <T>(
  sortField: (value: T) => string | Date | moment.Moment,
  descending: boolean = false
) => pipe(map((x: Array<T>) => x.sort(dateSortFunc(sortField, descending))));

export interface PagedData<T> {
  itemsCount: number;
  pageCount: number;
  items: Array<T>;
}

export function paging<T>(
  pageSize$: Observable<number>,
  page$: Observable<number>
): (source$: Observable<Array<T>>) => Observable<PagedData<T>> {
  return source$ =>
    source$.pipe(
      switchMap((items: Array<T>) => pageSize$.pipe(map(pageSize => ({ pageSize, items })))),
      switchMap(result =>
        page$.pipe(
          map(page => {
            const rowFrom = (page - 1) * result.pageSize;
            const rowTo = rowFrom + result.pageSize;
            return { page, items: result.items, rowFrom, rowTo, pageSize: result.pageSize };
          })
        )
      ),
      map(
        x =>
          <PagedData<T>>{
            itemsCount: x.items.length,
            pageCount: Math.floor(x.items.length / x.pageSize),
            items: x.items.slice(x.rowFrom, x.rowTo),
          }
      )
    );
}

export const filterArray = <T>(filter: (value: T) => boolean) =>
  pipe(map((x: Array<T>) => x.filter(filter)));

export const log = () => pipe(tap(clog));

export const clog = <T>(x: T) => console.log(x, true);
