import { Component, OnInit } from '@angular/core';
import { QuestionAnswer, questionAnswers$ } from './faq-card-question-answers';
import { Observable } from 'rxjs';

@Component({
  selector: 'pushdr-faq-card',
  templateUrl: './faq-card.component.html',
  styleUrls: ['./faq-card.component.scss'],
})
export class FAQCardComponent implements OnInit {
  questionAnswers$: Observable<QuestionAnswer[]>;

  ngOnInit() {
    this.questionAnswers$ = questionAnswers$;
  }
}
