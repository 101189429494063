import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { ApiPrescriptionsService } from '@pushdr/prescription/api';
import { PrescriptionStatus } from '@pushdr/common/types';

@Injectable({
  providedIn: 'root',
})
export class PrescriptionStatusService {
  private hasPrescribedSubject = new BehaviorSubject(false);

  readonly hasPrescribed$ = this.hasPrescribedSubject.asObservable();

  constructor(private prescriptionApi: ApiPrescriptionsService) {}

  updatePrescribedStatus(orderId: number): void {
    this.fetchPrescriptionStatus(orderId).subscribe({
      next: result => this.hasPrescribedSubject.next(result),
    });
  }

  markAsPrescribed(): void {
    this.hasPrescribedSubject.next(true);
  }

  private fetchPrescriptionStatus(orderId: number) {
    return this.prescriptionApi.hasPrescribed(orderId).pipe(
      map(items => !!items?.some(item => item.status > PrescriptionStatus.Created)),
      catchError(() => of(false)),
      take(1)
    );
  }
}
