<div class="overflow-visible c-modal__wrap c-modal__wrap--end">
  <form
    [formGroup]="cancellationForm"
    autocomplete="on"
    (ngSubmit)="cancellationForm.valid && onSubmit()">
    <h2>Please select a reason for cancellation</h2>
    <div class="text-left">
      <label class="mb-4">
        Cancellation reason
        <pushdr-cancel-reason-select
          [control]="
            $any(cancellationForm).get('cancellationReason')
          "></pushdr-cancel-reason-select>
      </label>
      <div class="mt-8">
        <pushdr-input-with-character-count
          [control]="cancellationForm.get('accompanyingDescription')"
          label="Accompanying Description"
          placeholder="Provide more detail"
          [optional]="true"
          [multiline]="3"
          [max]="250">
        </pushdr-input-with-character-count>
      </div>
      <div class="my-8">
        <p class="flex items-end mb-1">
          <span>Will the patient require a new prescription?</span>
          <span
            class="flex items-center ml-2"
            tooltip-top="Currently we require that a patient attends a new consultation to enable any new medication item(s) to be prescribed. Our patient experience team will book them a new appointment if needed.">
            <i class="icon material-icons text-grey-500">error</i>
          </span>
        </p>
        <small>If yes, this requires a new consultation</small>
        <div class="flex mt-1">
          <label class="flex items-center">
            <input type="radio" [value]="true" formControlName="newPrescriptionRequired" />
            <span class="mt-1 ml-2">Yes</span>
          </label>
          <label class="flex items-center ml-8">
            <input type="radio" [value]="false" formControlName="newPrescriptionRequired" />
            <span class="mt-1 ml-2">No</span>
          </label>
        </div>
      </div>
    </div>
    <button
      type="button"
      class="p-4 pl-6 pr-6 border border-solid rounded hover:bg-grey-100 md:mr-2 lg:mr-5"
      (click)="done(0)">
      Back
    </button>
    <button
      type="submit"
      class="p-4 pl-6 pr-6 text-white rounded bg-primary hover:bg-primary-hover md:ml-2 lg:ml-5"
      [class.bg-primary-disabled]="!cancellationForm.valid"
      [class.hover:bg-primary-disabled]="!cancellationForm.valid"
      [disabled]="!cancellationForm.valid || submitting">
      Cancel {{ items.size }} item{{ items.size > 1 ? '(s)' : '' }}
    </button>
  </form>
  <ng-container *ngIf="submitting">
    <div class="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center">
      <pushdr-spinner-loader message="Cancelling..." [minheight]="100"></pushdr-spinner-loader>
    </div>
  </ng-container>
</div>
