<pushdr-password-input
  [formControl]="formControl"
  [class.pdr-input--error]="isInvalid"
  (focusin)="showTooltip = true"
  (focusout)="showTooltip = false">
</pushdr-password-input>

<p class="mb-0 text-red-500" *ngIf="isInvalid">
  <span *ngIf="formControl.hasError('required'); else mismatchTmpl">A password is required.</span>
  <ng-template #mismatchTmpl>
    <span *ngIf="!hasMatch; else tooLongTmpl">Password doesn't match.</span>
  </ng-template>
  <ng-template #tooLongTmpl>
    <span *ngIf="formControl.hasError('maxlength'); else notMetTmpl">Password is too long.</span>
  </ng-template>
  <ng-template #notMetTmpl>
    <span *ngIf="formControl.errors">Requirements not met.</span>
  </ng-template>
</p>

<pushdr-password-input-tooltip
  *ngIf="showTooltip"
  class="absolute z-10 -top-[46px]"
  [ngClass]="{
    'mr-[17px] right-[100%]': tooltipPosition === 'left',
    'ml-[17px] left-[100%]': tooltipPosition === 'right'
  }"
  [thumbPosition]="thumbPosition"
  [rules]="rules"
  [errors]="errors">
</pushdr-password-input-tooltip>
