import { Component, OnInit } from '@angular/core';
import { ConsultationStateService } from '@pushdr/clinicians/common';

@Component({
  selector: 'pushdr-clinicians-home',
  template: `
    <main>
      <pushdr-sidebar-menu></pushdr-sidebar-menu>
      <section><router-outlet></router-outlet></section>
    </main>
  `,
  styleUrls: ['./clinicians-home.component.scss'],
})
export class CliniciansHomeComponent implements OnInit {
  constructor(private consultState: ConsultationStateService) {}

  ngOnInit() {
    this.consultState.clearConsultationState();
  }
}
