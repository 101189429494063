import { Injectable } from '@angular/core';
import { EnvironmentProxyService } from '@pushdr/environment';
import { HttpClient } from '@angular/common/http';
import {
  RestClient,
  RestErrorParserService,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface DispenserOpenTime {
  open: string;
  close: string;
}

export type DispenserWorkDay = DispenserOpenTime[] | null;

export interface DispenserOpenTimeSummary {
  open_247: boolean;
  bank_holiday?: DispenserOpenTime[];
  specified_date?: Record<string, DispenserOpenTime[]>;
  sun: DispenserWorkDay;
  mon: DispenserWorkDay;
  tue: DispenserWorkDay;
  wed: DispenserWorkDay;
  thu: DispenserWorkDay;
  fri: DispenserWorkDay;
  sat: DispenserWorkDay;
}

interface DispenserAddress {
  line: string[];
  postcode: string;
}

interface PatientContact {
  tel: string;
}

interface PrescriberContact {
  tel: string;
  fax: string;
}

export interface Dispenser {
  service_type: string;
  fax: string;
  name: string;
  address: DispenserAddress;
  distance: number;
  ods: string;
  patient_contact: PatientContact;
  prescriber_contact: PrescriberContact;
  opening?: DispenserOpenTimeSummary;
}

export interface DispensersResponse {
  result: Dispenser[];
}
@Injectable({
  providedIn: 'root',
})
export class DispensersApiService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.doctors.pharmacyAPI;
  }

  getDispensersByName(name = '', zip = ''): Observable<Dispenser[]> {
    return this.get(
      `/pharmacy/search/byNameAndPostCode/${name}${zip ? `/${zip}` : ''}`,
      {},
      this.headerService.legacyHeadersWithCorrelationId
    ).pipe(map((data: DispensersResponse) => data.result || []));
  }

  getDispensersByZip(zip = '', openOnly = false): Observable<Dispenser[]> {
    const timeframe = openOnly ? 2 : 7 * 24;
    return this.get(
      `/pharmacy/search/byPostCodeAndTimeFrame/${zip}/${timeframe}`,
      {},
      this.headerService.legacyHeadersWithCorrelationId
    ).pipe(map((data: DispensersResponse) => data.result || []));
  }
}
