<div class="pd-box modal">
  <header>NHS records show patient as deceased</header>
  <p>
    Please verify their ID and if there are inconsistencies, the patient will need to update their
    Push Doctor account details and book another appointment.
  </p>
  <p>If their details are correct, they will need to contact their NHS GP to amend this error.</p>
  <div class="actions">
    <button class="btn-primary" (click)="done(false)">I acknowledge this</button>
  </div>
</div>
