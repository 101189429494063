import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StagesStatusComponent } from './components/stages-status/stages-status.component';
import { CameraCheckComponent } from './routes/camera-check/camera-check.component';
import { MicCheckComponent } from './routes/mic-check/mic-check.component';
import { SoundCheckComponent } from './routes/sound-check/sound-check.component';
import { SpeedCheckComponent } from './routes/speed-check/speed-check.component';
import { StartComponent } from './routes/start/start.component';
import { AnalyticsDirectivesModule } from '@pushdr/common/data-access/analytics';
import { PresurgeryComponent } from './routes/pre-surgery/pre-surgery.component';
import { PresurgeryService } from './services/patientapp-pre-surgery.service';
import { RouterModule } from '@angular/router';
import { LottieModule } from 'ngx-lottie';

@NgModule({
  imports: [CommonModule, AnalyticsDirectivesModule, LottieModule, RouterModule],
  declarations: [
    StartComponent,
    PresurgeryComponent,
    MicCheckComponent,
    CameraCheckComponent,
    SoundCheckComponent,
    SpeedCheckComponent,
    StagesStatusComponent,
    StartComponent,
  ],
  providers: [PresurgeryService],
})
export class PreSurgeryModule {}
