import { Pipe, PipeTransform } from '@angular/core';
import { Prescription, PrescriptionStatus } from '../types/Prescription';

@Pipe({ name: 'prescriptionStatusName' })
export class PrescriptionStatusNamePipe implements PipeTransform {
  transform(prescription: Prescription) {
    const status = prescription.medicationStatus || prescription.status;
    return PrescriptionStatus[status as any]?.toString()?.replace(/_/g, ' ') || '';
  }
}
