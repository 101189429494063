export const pdsCudmore = {
  GetConsultationDetailsResult: {
    Patient: {
      NhsInformation: {
        ErrorResponse: null,
        HttpStatusCode: 200,
        SuccessResponse: {
          DateOfBirth: '1991-04-11',
          FamilyName: 'CUDMORE',
          Gender: '2',
          GivenNames: ['MALEAH'],
          NhsNumber: '9450038082',
          OrganisationDataServiceCode: 'A86005',
          Telephone: null,
          Title: 'MS',
          SensitivePatient: false,
        },
      },
    },
  },
};
