<div class="grid grid-cols-[repeat(3,minmax(0,1fr))_2.25rem] gap-4 mb-5">
  <div class="col-span-1 break-words">
    <p class="mb-0 text-bluegrey-700">{{ note.Datestamp | date: 'h:mma' | lowercase }}</p>
    <p class="mb-0">{{ note.Datestamp | date: 'd-MMM-YYYY' }}</p>
  </div>
  <div *ngIf="note.clinician as c" class="col-span-2 break-words">
    <p class="mb-0 text-bluegrey-700">
      {{ c.DoctorLastName }}, {{ c.DoctorFirstName }} ({{ c.Title }}) -
      <abbr [title]="c.ClinicianType | clinicianTypeName">{{
        c.ClinicianType | clinicianTypeAbbreviation
      }}</abbr>
    </p>
    <p class="mb-0">{{ note.BookingSource }}</p>
  </div>
  <div class="flex items-center">
    <i class="material-icons transform text-4xl" [class.text-grey-600]="!showDetails">{{
      showDetails ? 'expand_less' : 'expand_more'
    }}</i>
  </div>
</div>

<div *ngIf="showDetails" class="mb-5 p-5 bg-white rounded-md">
  <div class="mb-4">
    <p class="mb-2 font-bold">Presenting case & examination</p>
    <p class="m-0">
      <ng-template [ngIf]="note.Examination" [ngIfElse]="noNote">{{
        note.Examination
      }}</ng-template>
    </p>
  </div>
  <div class="mb-4">
    <p class="mb-2 font-bold">Management plan</p>
    <p class="m-0">
      <ng-template [ngIf]="note.ManagementPlan" [ngIfElse]="noNote">{{
        note.ManagementPlan
      }}</ng-template>
    </p>
  </div>
  <div>
    <p class="mb-2 font-bold">Safety netting</p>
    <p class="m-0">
      <ng-template [ngIf]="note.SafetyNetting" [ngIfElse]="noNote">{{
        note.SafetyNetting
      }}</ng-template>
    </p>
  </div>
</div>

<ng-template #noNote>
  <span class="text-bluegrey-700">Note is not available</span>
</ng-template>
