import { Injectable, InjectionToken, Inject } from '@angular/core';
import * as moment from 'moment';
import { of, Observable } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { WINDOW, ExtendedWindow } from '@pushdr/common/utils';

export interface LogServiceEntry {
  strApplication: string;
  strFunction: string;
  strType: string;
  strMessage: string;
  timestamp: string;
}

export interface LogStorageConfig {
  applicationName: string;
  get: (key: string, asObject?: boolean) => any;
  set: (key: any, objectToStore: any) => void;
  delete: (key: string) => any;
  emitStored: (arrLogs: LogServiceEntry[]) => Observable<any>;
  emit: (log: LogServiceEntry) => Observable<any>;
}

export const LOG_CONFIG = new InjectionToken<LogStorageConfig>('LogService Storage Abstraction');

@Injectable({
  providedIn: 'root',
})
export class LogService {
  private keyName = 'key';

  constructor(
    @Inject(LOG_CONFIG) private logStorageConfig: LogStorageConfig,
    @Inject(WINDOW) private window: ExtendedWindow
  ) {}

  get numberOfUnsentLogs() {
    const storedKey = +this.logStorageConfig.get(this.keyName);
    const key = isNaN(storedKey) || storedKey < 0 ? 0 : storedKey;
    return key;
  }

  log(target: string, message: any, type?: string, store = true) {
    const objLog: LogServiceEntry = {
      strApplication: this.logStorageConfig.applicationName,
      strFunction: target,
      strType: type ? type : 'URL: ' + this.window.location.href,
      strMessage: JSON.stringify(message),
      timestamp: moment().utc().toISOString(),
    };

    if (store) {
      const nextKey = this.numberOfUnsentLogs;
      this.logStorageConfig.set(nextKey, objLog);
      this.logStorageConfig.set(this.keyName, nextKey + 1);
    } else {
      this.logStorageConfig.emit(objLog);
    }
  }

  uploadLogStore() {
    const logCount: any = this.numberOfUnsentLogs;
    if (!logCount) {
      return of([]);
    }
    const arrayOfLogs = this.cutLogsFromStorage();

    return this.logStorageConfig.emitStored(arrayOfLogs).pipe(
      take(1),
      catchError(err => {
        console.warn('Failed to upload logs from local storage, they have been removed anyway');
        return of(null);
      })
    );
  }

  /**
   * cut as in cut and paste - removes the copies from storage as it
   * builds an array of valid objects to send.
   */
  private cutLogsFromStorage(): LogServiceEntry[] {
    // eslint-disable-next-line prefer-spread
    const arr = Array.apply(null, { length: this.numberOfUnsentLogs })
      .map((v, i) => {
        const objLog = this.logStorageConfig.get(i.toString(), true);
        this.logStorageConfig.delete(i.toString());
        return this.isValidLogObject(objLog) ? objLog : null;
      })
      .filter(ol => !!ol);
    this.logStorageConfig.delete(this.keyName);
    return arr;
  }

  private isValidLogObject(objLog: LogServiceEntry) {
    return (
      objLog &&
      typeof objLog.strApplication !== 'undefined' &&
      typeof objLog.strFunction !== 'undefined' &&
      typeof objLog.strType !== 'undefined' &&
      typeof objLog.strMessage !== 'undefined' &&
      typeof objLog.timestamp !== 'undefined'
    );
  }
}
