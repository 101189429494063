import { Component } from '@angular/core';
import { AbstractModal } from '@pushdr/common/overlay';

export interface EndVideoSessionData {
  isRecording?: boolean;
}

@Component({
  selector: 'pushdr-end-video-session-modal',
  templateUrl: './end-video-session-modal.component.html',
})
export class EndVideoSessionModalComponent extends AbstractModal<EndVideoSessionData, boolean> {
  get isRecording(): boolean {
    return this.data?.isRecording ?? false;
  }
}
