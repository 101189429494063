<div class="pre-surgery-feedback" data-cy="feedback" *ngIf="state">
  <div
    class="c-notification"
    [ngClass]="{
      'c-notification__info': state.indexOf('Info') > -1,
      'c-notification__warning': state.indexOf('Warning') > -1,
      'c-notification__error': state.indexOf('Error') > -1
    }"
    [ngSwitch]="state">
    <ng-container *ngSwitchCase="PresurgeryStateEnum.INITIALISED">
      <h3 class="c-notification__title">Let's get you set up</h3>
      <p class="u-mb0@xs">
        Follow the instructions to make sure your device is ready for your consultation
      </p>
    </ng-container>
  </div>

  <div class="pre-surgery-progression"></div>

  <div class="pre-surgery-progression">
    <div class="pre-surgery-buttons">
      <button
        class="c-btn c-btn--primary u-mr0@xs"
        (click)="next()"
        data-cy="next"
        trackClick="yes">
        Start device checks
      </button>
    </div>
  </div>
</div>
