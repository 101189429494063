import { Gender, NhsGender } from '../nhs';
import { PartnerType } from '../doctors';
import { of } from 'rxjs';
import { ClinicianType } from '../doctors';
import {
  CustomerType,
  HealthRecordShareStatus,
  PatientDetailsV2,
  TranslatorDialState,
} from '../doctors';
import { GPShareStatus } from '../doctors';

export const ModalServiceStub = {
  showLoader: () => of({}),
  confirm: () => of({}),
  success: () => of({}),
  acknowledge: () => of({}),
  close: () => of({}),
  error: () => of({}),
};

let store = {};
export const storageServiceStub = {
  deleteSessionStorage: () => {
    store = {};
  },
  setLocalStorage: (key: string, value: any) => {
    store[key] = JSON.stringify(value);
  },
  getLocalStorage: () => ({}),
  getSessionStorage: (key: string, returnAsJSON = false): any => {
    return returnAsJSON
      ? JSON.parse(key in store ? store[key] : null)
      : key in store
      ? store[key]
      : null;
  },
  setSessionStorage: (key: string, value: any) => {
    store[key] = JSON.stringify(value);
  },
  set: () => {},
  get: () => {},
  delete: () => {},
};
export const webrtcDevicesetupServiceStub = {
  detachAllStreams: () => ({}),
  getUserMediaStream: () =>
    of({
      permissions: {
        videoEnabled: true,
      },
    }),
};
export const featureToggleServiceStub = {
  get: () => {},
  isActive: () => true,
  track: () => ({}),
  configuration: () => ({}),
};
export const systemCheckServiceStub = {
  isCompatible: true,
  isBrowserCompatible: true,
  isWindows: true,
  browser: '',
  OS: '',
  userAgent: '',
};
export const documentStub = {
  location: {
    href: 'test',
  },
  hasFocus: () => {},
  querySelectorAll: () => {},
};
export const networkSpeedServiceStub = {
  start: () => of({ speed: 0, ping: 0 }),
};

const mockNotification = function () {
  return this;
};
mockNotification.permission = 'default';
mockNotification.requestPermission = () => new Promise(() => mockNotification.permission);

export const windowStub = {
  scrollTo: () => {},
  location: {
    href: '',
    pathname: '',
    origin: '',
    reload: () => {},
  },
  open: () => {},
  Notification: mockNotification,
  AudioContext: function () {
    this.createAnalyser = function () {
      this.fftSize = 0;
      this.frequencyBinCount = 0;
      this.getByteTimeDomainData = () => {};
      return this;
    };
    this.createMediaStreamSource = function () {
      this.connect = () => {};
      return this;
    };
    return this;
  },
  webkitAudioContext: function () {
    return this;
  },
  Intercom: () => {},
  confirm: () => {},
  console: {
    log: () => {},
  },
  sessionStorage: {
    setItem: () => {},
    removeItem: () => {},
  },
  URL: {
    createObjectURL: () => {},
  },
  gapi: {
    auth2: {
      getAuthInstance: function () {
        return Promise.resolve({
          grantOfflineAccess: a => {
            return { code: 'fake-token' };
          },
        });
      },
    },
  },
  FB: {
    login: res => ({ authResponse: true }),
  },
  history: {
    replaceState() {
      return true;
    },
  },
};
export const browserDetectorServiceStub = {
  isIE10: () => ({}),
};

export const getConsultationDetailsStub: PatientDetailsV2 = {
  ConsultationConfig: {
    ChildConsultation: { IsEnabled: true },
    TranslatorService: { IsEnabled: true },
    TranslatorCallStatus: { Status: TranslatorDialState.ANSWERED },
  },
  Partner: {
    Id: '123',
    Name: 'O999',
    PartnerType: PartnerType.NHS,
    ClinicianType: ClinicianType.GeneralPractitioner,
  },
  Customer: {
    CustomerType: CustomerType.Adult,
    Address: {
      Line1: '',
      Line2: '2',
      Line3: '',
      Line4: 'ROAD CLOSE',
      Line5: '',
      Postcode: 'RG40 2PZ',
    },
    Age: 33,
    DateOfBirth: '1991-11-03',
    Email: 'test@test.com',
    FirstName: 'Matt',
    Gender: 1,
    GenderLabel: Gender[Gender.MALE] as string,
    GpShareStatus: GPShareStatus.DO_NOT_SHARE,
    HealthRecordShareStatus: HealthRecordShareStatus.DO_NOT_SHARE,
    GpSummary: {
      Address: {
        Line1: 'Normanby Medical Centre',
        Line2: 'Low Grange Hv,normanby Rd',
        Line3: 'Middlesbrough',
        Line4: 'Cleveland',
        Line5: null,
        Postcode: 'TS6 6TD',
      },
      Id: 19,
      Name: 'Normanby Medical Centre',
      OdsCode: 'MOCK_ODS',
      SurgeryTypeId: 5,
    },
    Id: 35863,
    LastName: '__TestName__',
    Name: '__TestName__',
    NhsInformation: {
      ErrorResponse: {
        Code: 'apollo_error',
        Message: 'Not Found',
        Tag: 'lookup',
      },
      HttpStatusCode: 404,
      SuccessResponse: {
        Title: 'Mr',
        NhsNumber: 'MOCK_NHS_NUMBER',
        GivenNames: ['name', 'and a name'],
        FamilyName: 'surname',
        Telephone: '07766576767',
        DateOfBirth: '03/03/1992',
        DateOfDeath: null,
        Gender: NhsGender.Male,
        OrganisationDataServiceCode: 'MOCK_ODS',
        SensitivePatient: false,
        PatientAddress: {
          AddressLines: ['Address Line 1'],
          Postcode: 'AB1 2CD',
          Use: '',
        },
        PracticeAddress: {
          AddressLines: ['Address Line 1'],
          Postcode: 'AB1 2CD',
          Use: '',
        },
        PracticeName: 'Practice',
      },
    },
    Phone: '07794518933',
  },
  Patient: {
    CustomerType: CustomerType.Adult,
    Address: {
      Line1: '',
      Line2: '2',
      Line3: '',
      Line4: 'ROAD CLOSE',
      Line5: '',
      Postcode: 'RG40 2PZ',
    },
    Age: 33,
    DateOfBirth: '1991-11-03',
    Email: 'test@test.com',
    FirstName: 'Matt',
    Gender: 1,
    GenderLabel: Gender[Gender.MALE] as string,
    GpShareStatus: GPShareStatus.DO_NOT_SHARE,
    HealthRecordShareStatus: HealthRecordShareStatus.DO_NOT_SHARE,
    GpSummary: {
      Address: {
        Line1: 'Normanby Medical Centre',
        Line2: 'Low Grange Hv,normanby Rd',
        Line3: 'Middlesbrough',
        Line4: 'Cleveland',
        Line5: null,
        Postcode: 'TS6 6TD',
      },
      Id: 19,
      Name: 'Normanby Medical Centre',
      OdsCode: 'MOCK_ODS',
      SurgeryTypeId: 5,
    },
    Id: 35863,
    LastName: '__TestName__',
    Name: '__TestName__',
    NhsInformation: {
      ErrorResponse: {
        Code: 'apollo_error',
        Message: 'Not Found',
        Tag: 'lookup',
      },
      HttpStatusCode: 404,
      SuccessResponse: {
        Title: 'Mr',
        NhsNumber: 'MOCK_NHS_NUMBER',
        GivenNames: ['name', 'and a name'],
        FamilyName: 'surname',
        Telephone: '07766576767',
        DateOfBirth: '03/03/1992',
        DateOfDeath: null,
        Gender: NhsGender.Male,
        OrganisationDataServiceCode: 'MOCK_ODS',
        SensitivePatient: false,
        PatientAddress: null,
        PracticeAddress: null,
        PracticeName: 'Dr Pains Practice',
      },
    },
    Phone: '07794518933',
  },
  Surgery: {
    Id: '123',
    OrderId: 19101982,
  },
  SurgeryLineId: 4276,
  IsCallRecordingEnabled: true,
};

export const smartCardPermsMock = {
  rbac: {
    sessions: [
      {
        sessionId: '555030370104',
        orgCode: 'MOCK_ODS',
        nhsJobRoleCode: 'S0010:G0020:R0260',
        active: true,
        role: 'R8000',
        permissions: [
          'prescription/sign',
          'prescription/create',
          'prescription/cancel',
          'prescription/view',
        ],
      },
      {
        sessionId: '555030369101',
        orgCode: 'MOCK_ODS',
        nhsJobRoleCode: 'S0080:G0440:R5090',
        active: false,
        role: 'R5090',
        permissions: [],
      },
    ],
    uniqueUserId: '555028633107',
    uniqueRoleProfileId: '555030370104',
  },
};

export const doctorMock = {
  GpConnectAccess: true,
  LinkedOrganisationId: null,
  Types: [2, 4, 5],
  strAboutYou: 'Hi, welcome to Push Doctor. 123412341234',
  strGMC: '123456',
  strMobile: '07976521360',
  strName: 'Dr Paul Johnson',
};

export const presBasketItemsMock = [
  {
    dosage: 'Take one after a meal while standing on your head.',
    duration: 11,
    quantity: 11,
    endorsements: [false, false, false, false, false],
    patientNote: '',
    pharmacyNote: '',
    medicationName: 'Paracetamol 325mg / Isometheptene 65mg capsules',
    medicationCode: '322804009',
  },
  {
    dosage: 'Throw into the air and catch in your mouth ',
    duration: 19,
    quantity: 20,
    endorsements: [false, false, false, false, false],
    patientNote: '',
    pharmacyNote: '',
    medicationName: 'Ibuprofen 200mg capsules',
    medicationCode: '370195008',
  },
  {
    dosage: 'Be carful with these.',
    duration: 20,
    quantity: 40,
    endorsements: [false, false, false, false, false],
    patientNote: '',
    pharmacyNote: '',
    medicationName: 'Codeine 15mg/5ml linctus',
    medicationCode: '15065911000001106',
  },
  {
    dosage: "Don't cry",
    duration: 23,
    quantity: 21,
    endorsements: [false, false, false, false, false],
    patientNote: '',
    pharmacyNote: '',
    medicationName: 'Hayleve 4mg tablets (Genesis Pharmaceuticals Ltd)',
    medicationCode: '7960911000001107',
  },
  {
    dosage: 'Inject into bits you want bigger.',
    duration: 10,
    quantity: 10,
    endorsements: [],
    pharmacyNote: '',
    medicationName: 'Botox 50unit powder for solution for injection vials (Allergan Ltd)',
    medicationCode: '13423211000001107',
    controlledScheduleNumber: 0,
  },
  {
    dosage: 'Put it in your pipe and smoke it',
    duration: 20,
    quantity: 10,
    endorsements: [],
    pharmacyNote: '',
    medicationName: 'Cannabidiol 100mg/ml oral solution sugar free',
    medicationCode: '36508311000001103',
    controlledScheduleNumber: 2,
  },
];
