<div class="w-full mb-3">
  <label class="block mb-2" [for]="label" data-cy="input-label">
    {{ label }}
    <span class="text-grey-600" *ngIf="optional" data-cy="input-label-optional">(optional)</span>
  </label>
  <ng-container *ngIf="multiline > 1; else singleLine">
    <textarea
      class="pdr-input resize-none"
      [formControl]="$any(control)"
      name="{{ label }}"
      [placeholder]="placeholder"
      [rows]="multiline"
      data-cy="input"
      [id]="label"
      [maxlength]="max">
    </textarea>
  </ng-container>
  <ng-template #singleLine>
    <input
      type="text"
      class="pdr-input"
      [formControl]="$any(control)"
      name="{{ label }}"
      [placeholder]="placeholder"
      data-cy="input"
      [id]="label"
      [maxlength]="max" />
  </ng-template>
  <p class="mb-0 text-right text-grey-600" data-cy="input-length">
    {{ control.value.length }} / {{ maxLength }}
  </p>
</div>
