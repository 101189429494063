import { Injectable } from '@angular/core';
import {
  AnalyticsBusService,
  AnalyticsService,
  InsightsService,
  PushDoctorLogsService,
  ErrorLogsService,
  GTMEventTrackingService,
} from '@pushdr/common/data-access/analytics';
import { EnvironmentProxyService } from '@pushdr/environment';

@Injectable({
  providedIn: 'root',
})
export class ClinicianAnalyticsService extends AnalyticsService {
  errorLogsFeature = true;

  private _initialised = false;

  constructor(
    protected bus: AnalyticsBusService,
    private env: EnvironmentProxyService,
    private insights: InsightsService,
    private logs: PushDoctorLogsService,
    private errorLogs: ErrorLogsService,
    private gtm: GTMEventTrackingService
  ) {
    super(bus);
  }

  initialise(appIdentifier: string) {
    if (!this._initialised) {
      this._initialised = true;
      this.logs.alwaysStore = true;
      this.insights.initialise(
        { user: [''], events: ['router', 'log', 'error', 'network'] },
        this.env.environment.appInsights,
        appIdentifier
      );
      this.gtm.initialise(
        this.env.environment.google.cliniciansGtm,
        {
          user: [''],
          events: ['view', 'click'],
        },
        'Clinicians'
      );
      this.logs.initialise({ user: [''], events: ['router', 'log', 'error', 'network', 'info'] });
      if (this.errorLogsFeature) {
        this.errorLogs.initialise({ user: [''], events: ['router', 'log', 'error', 'network'] });
      }
    } else {
      throw Error('Analytics service has already initialised');
    }
  }
}
