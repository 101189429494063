import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  RestClient,
  RestErrorParserService,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';
import { EnvironmentProxyService } from '@pushdr/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiDoctorsPatientService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.doctors.recordsAPI;
  }

  getConsultationReportPreview(orderId: number) {
    return this.get<any>(
      '/report/preview',
      {
        surgeryId: orderId,
      },
      this.headerService.legacyHeadersWithCorrelationId,
      2
    );
  }

  getConsultationReport(reportGuid: string) {
    return this.get<any>(
      `/report/${reportGuid}`,
      {},
      this.headerService.legacyHeadersWithCorrelationId,
      2
    );
  }

  getPreviousConsultationReports() {
    return this.get<any>(`/report/list/`, {}, this.headerService.legacyHeadersWithCorrelationId, 2);
  }

  getPatientRecord(nhsNumber: string, odsCode: string) {
    return this.get<string>(
      `/html/${odsCode}/${nhsNumber}`,
      {},
      this.headerService.legacyHeadersWithCorrelationId,
      2
    );
  }

  getStructuredPatientRecord(nhsNumber: string, odsCode: string) {
    return this.get<any>(
      `/structured/${odsCode}/${nhsNumber}`,
      {},
      this.headerService.legacyHeadersWithCorrelationId,
      2
    );
  }
}
