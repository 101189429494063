import { Component, OnInit } from '@angular/core';
import { PrescribedMedication } from '@pushdr/common/types';
import { BehaviorSubject, Observable } from 'rxjs';
import { MedicationsService } from '../../services/patient-records/medications.service';

@Component({
  selector: 'pushdr-acute-medications',
  styleUrls: ['./acute-medications.component.scss'],
  templateUrl: './acute-medications.component.html',
})
export class AcuteMedicationsComponent implements OnInit {
  pageSize$ = new BehaviorSubject(20);
  medications$: Observable<{ items: PrescribedMedication[]; pageCount: number }>;

  constructor(private medications: MedicationsService) {}

  ngOnInit() {
    this.medications$ = this.medications.getAcuteMedicationsPaged$(
      this.pageSize$,
      new BehaviorSubject(1)
    );
  }

  onScrolledToEnd() {
    this.pageSize$.next(this.pageSize$.value + 20);
  }
}
