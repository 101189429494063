import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  Prescription,
  PrescriptionErrorStatus,
  PrescriptionSuccessStatus,
} from '../../../../types/Prescription';

@Component({
  selector: 'pushdr-clinicians-prescribed-medications',
  templateUrl: './clinicians-prescribed-medications.component.html',
  styleUrls: ['./clinicians-prescribed-medications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CliniciansPrescribedMedicationsComponent {
  @Input() prescriptions: Prescription[];
  @Input() selectedItems: Set<string> = new Set<string>();

  prescriptionErrorStatus = PrescriptionErrorStatus;

  constructor() {}

  handleRowClick(prescription: Prescription) {
    const validMedStatus = +prescription.medicationStatus === +PrescriptionSuccessStatus.Success;
    const validPrescriptionStatusWithNoMedStatus =
      +prescription.medicationStatus === 0 &&
      +prescription.status === +PrescriptionSuccessStatus.Success;
    if (validMedStatus || validPrescriptionStatusWithNoMedStatus) {
      const medId = prescription.medicationId;
      if (this.selectedItems.has(medId)) {
        this.selectedItems.delete(medId);
      } else {
        this.selectedItems.add(medId);
      }
    }
  }
}
