import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findArrayItem',
})
export class FindArrayItemPipe implements PipeTransform {
  transform(arr: any[], key: string, expectedValue: string): any {
    if (arr && arr.length) {
      return arr.find(item => item[key].includes(expectedValue)) || {};
    } else {
      return {};
    }
  }
}
