<pushdr-consultation-card [hasData]="true" [showFooter]="true">
  <span header>End consultation</span>

  <ng-container body>
    <form [formGroup]="form">
      <div class="bg-orange-300 px-4 z-0 -mt-4 -mx-4 flex">
        <p class="text-orange-500 py-4 my-0 text-sm">
          Please take care to report what happened accurately, and advise your patient accordingly.
        </p>
      </div>

      <h3 class="my-4">Why are you ending this consultation?</h3>

      <div class="flex flex-col">
        <label class="input-radio-label pb-4" *ngFor="let item of constants.ClinicalOutcomeList">
          <input
            class="c-resolve-allergy__input ml-2 mr-1"
            type="radio"
            formControlName="reason"
            [value]="item" />
          {{ constants.ClinicalOutcomeLabelMap.get(item) }}
        </label>
      </div>

      <div [ngSwitch]="form.get('reason').valueChanges | async">
        <div *ngSwitchCase="ClinicalOutcomeResultTypeEnum.Success">
          <ng-container *ngTemplateOutlet="successReason"></ng-container>
          <div *ngIf="hasReferralRequired$ | async">
            <ng-container
              *ngTemplateOutlet="
                referralRequired;
                context: { $implicit: form.get('referralRequired') }
              "></ng-container>
          </div>
        </div>
        <div *ngSwitchCase="ClinicalOutcomeResultTypeEnum.Discontinuation">
          <ng-container *ngTemplateOutlet="discontinuationReason"></ng-container>
          <div *ngIf="hasReferralRequired$ | async">
            <ng-container
              *ngTemplateOutlet="
                referralRequired;
                context: { $implicit: form.get('referralDiscontinuation') }
              "></ng-container>
          </div>
        </div>
        <div *ngSwitchCase="ClinicalOutcomeResultTypeEnum.Disruption">
          <ng-container *ngTemplateOutlet="disruptionReason"></ng-container>
        </div>
      </div>

      <ng-container
        *ngTemplateOutlet="!!(clinicalOutcomes$ | async) ? null : loading"></ng-container>

      <ng-template #successReason>
        <h3 class="my-4">What outcome(s) happened?</h3>
        <div class="grid grid-cols-2">
          <label
            *ngFor="
              let item of clinicalOutcomes$
                | async
                | clinicalOutcomeFilter: 'resultType':ClinicalOutcomeResultTypeEnum.Success
            "
            class="pb-4">
            <input
              type="checkbox"
              class="ml-2 mr-1"
              (change)="toggleSuccessResult(item.id)"
              [checked]="successReasonSelection.isSelected(item.id)" />
            {{ item.title }}
          </label>
        </div>
      </ng-template>

      <ng-template #discontinuationReason>
        <h3 class="my-4">What is that disruption?</h3>
        <div class="flex flex-col">
          <label
            *ngFor="
              let item of clinicalOutcomes$
                | async
                | clinicalOutcomeFilter: 'resultType':ClinicalOutcomeResultTypeEnum.Discontinuation
            "
            class="pb-4">
            <input
              class="c-resolve-allergy__input ml-2 mr-1"
              type="radio"
              formControlName="reasonDiscontinuation"
              [value]="item.id" />
            {{ item.title }}
          </label>
        </div>
      </ng-template>

      <ng-template #disruptionReason>
        <h3 class="my-4">What is the reason for this?</h3>
        <div class="flex flex-col">
          <div
            *ngFor="
              let item of clinicalOutcomes$
                | async
                | clinicalOutcomeFilter: 'resultType':ClinicalOutcomeResultTypeEnum.Disruption
                | arrayGroupBy: 'categoryTitle'
            ">
            <h4 class="text-sm py-0 my-0 pb-4">{{ item.group }}</h4>
            <div class="grid {{ item.items.length > 1 ? 'grid-cols-2' : '1' }}">
              <label *ngFor="let subItem of item.items" class="pb-4">
                <input
                  class="c-resolve-allergy__input ml-2 mr-1"
                  type="radio"
                  formControlName="reasonDisruption"
                  [value]="subItem.id" />
                {{ subItem.title }}
              </label>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template #referralRequired let-control>
        <h3 class="my-4">Referral Required</h3>
        <ng-select
          class="w-full"
          placeholder="Clinician Type"
          id="clinicianType"
          [formControl]="control"
          [clearable]="false"
          [searchable]="false"
          class="mb-3">
          <ng-option *ngFor="let item of clinicianType$ | async" [value]="item.id">
            {{ item.title }}</ng-option
          >
          <div class="text-red-500" *ngIf="form.hasError('noReferralResultSelected')">
            Referral is required
          </div>
        </ng-select>
      </ng-template>
    </form>
  </ng-container>

  <ng-container footer>
    <div class="flex justify-evenly w-full p-3 px-12">
      <button
        data-cy="back-btn"
        class="w-2/6 btn-outline btn btn-tiny"
        (click)="patientNav.gotoConsultationHome()">
        Return to consult
      </button>
      <button
        class="w-2/6 btn btn-tiny bg-red-500 hover:bg-red-300 text-white"
        [class.opacity-50]="form.invalid || completing"
        [disabled]="form.invalid || completing"
        (click)="form.valid && complete()">
        End consultation
      </button>
    </div>
  </ng-container>
</pushdr-consultation-card>

<ng-template #loading>
  <pushdr-spinner-loader></pushdr-spinner-loader>
</ng-template>
