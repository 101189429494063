import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  RestClient,
  RestErrorParserService,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';
import { PartnerType, SurgeryType, GPShareStatus, Gender } from '@pushdr/common/types';
import { StorageService } from '@pushdr/common/utils';
import { EnvironmentProxyService } from '@pushdr/environment';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';

import * as moment from 'moment';
import { VideoParameters } from '@pushdr/common/components';

export class NextPatientConfig {
  videoProvider: number;
  surgeryType: SurgeryType;

  constructor(surgeryType: SurgeryType, videoProvider = 5) {
    this.videoProvider = videoProvider;
    this.surgeryType = surgeryType;
  }
}

// TODO Fix captilisations
export class GPShareDetails {
  Address: string;
  AddressLine1: string;
  AddressLine2: string;
  AddressLine3: string;
  AddressLine4: string;
  AddressLine5: string;
  County: string;
  CustomerID: number;
  DistanceDescription: string;
  DistanceValue: number;
  GPEmail: string;
  Id: number;
  Latitude: number;
  Longitude: number;
  Name: string;
  OdsCode: string;
  PartnershipType: number;
  PostCode: string;
  PostTown: string;
  SurgeryTypeId: SurgeryType;
}

// TODO Fix captilisations
export class NHSInformation {
  HttpStatusCode: number;
  Error: string;
  Title: string;
  NhsNumber: string;
  GivenNames: string;
  FamilyName: string;
  NhsAddress: NhsAddress;
  Telephone: string;
  DateOfBirth: string;
  DateOfDeath: any;
  Gender: string;
  NhsPractice: NhsPractice;
}

// TODO Fix captilisations
export class NhsAddress {
  AddressLines: string[];
  Postcode: string;
  Use: string;
}

export class PatientAlert {
  id: number;
  message: string;

  static fromApi(res) {
    const alert = new PatientAlert();
    alert.id = res.Id;
    alert.message = res.Message;
    return alert;
  }
}

// TODO Fix captilisations
class NhsPractice {
  OrganisationDataServiceCode: string;
  Name: string;
  NhsAddress: NhsAddress;
  TelephoneNumber: string;
}
export class Partner {
  Id: string;
  Name: string;
  Type: PartnerType;
}

// TODO Fix captilisations
export class NhsPatientDetails {
  id: number;
  Age: number;
  GPShareStatus: string;
  Gender: string;
  Name: string;
  Email: string;
  Phone: string;
  DOB: string;
  GPShareDetails: GPShareDetails;
  NHSInfo: NHSInformation;
  Partner?: Partner;
  SurgeryId?: string;
  IsPrivate: boolean;
  GPShareStatusEnum: GPShareStatus;

  static fromApi(res) {
    const nhsRes = new NhsPatientDetails();
    nhsRes.id = res.PatientId;
    nhsRes.Age = parseInt(res.strAge, 10);
    nhsRes.GPShareStatus = res.strGPShareStatus;
    nhsRes.GPShareStatusEnum = res.GPShareStatus;
    nhsRes.Gender = res.strGender;
    nhsRes.Name = res.strPatientName;
    nhsRes.Email = res.strEmail;
    nhsRes.Phone = res.strPhone;
    nhsRes.DOB = res.strDOB;
    nhsRes.SurgeryId = res.uuidSurgeryId;
    nhsRes.GPShareDetails = new GPShareDetails();
    Object.assign(nhsRes.GPShareDetails, res.GPShareDetails);

    nhsRes.NHSInfo = new NHSInformation();
    nhsRes.NHSInfo.Error = res.NhsInformation.ErrorResult;
    nhsRes.NHSInfo.HttpStatusCode = res.NhsInformation.HttpStatusCode;
    if (res.NhsInformation.Result) {
      Object.assign(nhsRes.NHSInfo, res.NhsInformation.Result);
    }
    nhsRes.Partner = res.Partner;

    // TODO: This will hopfully be returned from the API in future.
    nhsRes.IsPrivate = !res.Partner;

    return nhsRes;
  }
}
// TODO fix capitalisation
export class ExtendedNhsPatientDetails {
  GenderLabel: string;
  DOB: string;
  AddressLine1: string;
  AddressLine2: string;
  AddressLine3: string;
  AddressPostcode: string;
  IdentificationTypes: {
    Description: string;
    FurtherInformationRequired: boolean;
    ReferenceRequired: boolean;
    IdentificationTypeId: number;
  }[];

  static fromApi(res) {
    const nhs = new ExtendedNhsPatientDetails();
    nhs.DOB = moment(res.AccountHolderDateOfBirth, 'DD/MM/YYYY').format('DD MMM YYYY');
    nhs.GenderLabel = res.AccountHolderGender;
    nhs.AddressLine1 = res.AccountHolderAddressLineOne;
    nhs.AddressLine2 = res.AccountHolderAddressLineTwo;
    nhs.AddressLine3 = res.AccountHolderAddressLineThree;
    nhs.AddressPostcode = res.AccountHolderPostcode;
    nhs.IdentificationTypes = res.AccountHolderIdentificationTypes; //Description
    return nhs;
  }
}

export interface DisruptReasons {
  bActive: boolean;
  intDisplayOrder: number;
  intID: number;
  patientTypes: string[];
  strDisruptCategory: string;
  strType: string;
}

export interface SubmittedDisruptReason {
  intConsultationID;
  intDisruptID;
  intAddRemove;
}

export enum WhereShouldIBe {
  GETTING_NEXT_PATIENT = 0,
  IN_CONSULTATION = 2,
  ACCEPTING_NEW_TERMS = 4,
  ALREADY_IN_CONSULTATION = -1,
}

// Added until old matcher goes away and this is not needed
export enum ClinicianConsultationStatus {
  AVAILABLE = 1,
  BUSY = 2,
  UNAVAILABLE = 3,
}

export enum EndConsultationStatus {
  CANCEL_ENDING = 0,
  COMPLETED_SUCCESSFULLY = 1,
  DISRUPTED = 2,
  COMPLETED_ABUSIVE = 3,
}

@Injectable({
  providedIn: 'root',
})
export class ApiDoctorsConsultation extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService,
    protected storage: StorageService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.doctors.api + '/consultation.svc/consultationW/';
  }

  addDoctorLogRange(Logs: any[]) {
    return this.post(
      'AddDoctorLogRange',
      { Logs },
      this.headerService.authorisedHeadersLegacy
    ).pipe(pluck('AddDoctorLogRangeResult'));
  }

  logBrowserDetails(
    orderId: number,
    browser: string,
    operatingSystem: string,
    userAgentString: string
  ) {
    return this.post(
      'LogBrowserDetails',
      { browser, operatingSystem, userAgentString, orderId },
      this.headerService.authorisedHeadersLegacy
    ).pipe(pluck('LogBrowserDetailsResult'));
  }

  getIdentityDocument(orderId: number) {
    return this.post(
      'GetConsultationIdentityDocument',
      { orderId },
      this.headerService.authorisedHeadersLegacy
    ).pipe(pluck('GetConsultationIdentityDocumentResult'));
  }

  getMyPatientDetails() {
    return this.post('GetMyPatientDetails', {}, this.headerService.authorisedHeadersLegacy).pipe(
      pluck('GetMyPatientDetailsResult'),
      map(res => NhsPatientDetails.fromApi(res))
    );
  }

  dialTranslator(orderId: number, languageCode: number) {
    return this.post(
      'ConnectTranslatorToConsultation',
      { orderId, languageCode },
      this.headerService.authorisedHeadersLegacy
    );
  }

  disconnectTranslator(orderId) {
    return this.post(
      'DisconnectTranslatorFromConsultation',
      { orderId },
      this.headerService.authorisedHeadersLegacy
    );
  }

  getMyPatientExtendedDetails() {
    return this.post(
      'GetLinkedCustomerAdditionalInformation',
      {},
      this.headerService.authorisedHeadersLegacy
    ).pipe(
      pluck('GetLinkedCustomerAdditionalInformationResult'),
      map(res => ExtendedNhsPatientDetails.fromApi(res))
    );
  }

  getPatientId() {
    return this.post('GetLinkedCustomers', {}, this.headerService.authorisedHeadersLegacy).pipe(
      pluck('GetLinkedCustomersResult'),
      map(res => res[0].LinkedPatientID)
    );
  }

  getTranslatorLanguages() {
    return this.post('GetTranslatorLanguages', {}, this.headerService.authorisedHeadersLegacy).pipe(
      pluck('GetTranslatorLanguagesResult'),
      map(res => res.TranslatorLanguagesList)
    );
  }

  getVideoCredentials(orderId: number): Observable<VideoParameters> {
    return this.post(
      'GetConsultationCredentialsV2',
      { orderId },
      this.headerService.authorisedHeadersLegacy
    ).pipe(pluck('GetConsultationCredentialsV2Result'));
  }

  getNextPatientConfig() {
    return this.post('GetNextPatientV2', {}, this.headerService.authorisedHeadersLegacy).pipe(
      pluck('GetNextPatientV2Result'),
      map(result => {
        return new NextPatientConfig(result.SurgeryType, result.Id);
      })
    );
  }

  downloadImage(orderId: number, strImageID: string) {
    return this.post(
      'DownloadImage',
      { strImageID, orderId },
      this.headerService.authorisedHeadersLegacy
    ).pipe(
      pluck('DownloadImageResult'),
      map(base64 => 'data:image/png;base64,' + base64)
    );
  }

  endConsultation(orderId: number, intStatus: EndConsultationStatus): Observable<number> {
    return this.post<{ EndSessionResult: string }>(
      'EndSession',
      { intStatus, orderId },
      this.headerService.authorisedHeadersLegacy
    ).pipe(
      pluck('EndSessionResult'),
      map(id => parseInt(id, 10))
    );
  }

  endVideoSession(orderId: number): Observable<boolean> {
    const headers = this.headerService.authorisedHeadersLegacy;
    return this.post('DisconnectVideoSession', { orderId }, headers);
  }

  sendClericalNote(orderId: number, strNote: string) {
    return this.post(
      'SendClericalNote',
      {
        strNote,
        orderId,
      },
      this.headerService.authorisedHeadersLegacy
    );
  }

  updateStatus(status: ClinicianConsultationStatus) {
    return this.post(
      'UpdateDoctorStatus',
      {
        intStatus: status,
      },
      this.headerService.authorisedHeadersLegacy
    );
  }

  confirmPatient(orderId: number, identificationTypeId: number) {
    return this.post(
      'AddLinkedCustomerVerificationDetails',
      {
        identificationTypeId,
        orderId,
      },
      this.headerService.authorisedHeadersLegacy
    ).pipe(
      pluck('AddLinkedCustomerVerificationDetailsResult'),
      map(res => 'AddLinkedCustomerVerificationDetailsResult')
    );
  }

  createChildVerification(orderId: number, firstName, lastName, dob: Date, gender, idType) {
    const body = {
      FirstName: firstName,
      LastName: lastName,
      DateOfBirth: dob,
      Gender: gender,
      IdentificationTypeId: idType,
      OrderId: orderId,
    };
    return this.post(
      'CreateChildVerification',
      body,
      this.headerService.authorisedHeadersLegacy
    ).pipe(pluck('CreateChildVerificationResult'));
  }

  getPatientAlerts(orderId: number) {
    return this.post(
      'GetPatientAlerts',
      { orderId },
      this.headerService.authorisedHeadersLegacy
    ).pipe(
      pluck('GetPatientAlertsResult'),
      map(res => res.map(alert => PatientAlert.fromApi(alert)))
    );
  }

  whereShouldIBe(): Observable<WhereShouldIBe> {
    return this.post('WhereShouldIBe', {}, this.headerService.authorisedHeadersLegacy).pipe(
      pluck('WhereShouldIBeResult')
    );
  }

  sendPatientEmail(emailContent: string): Observable<any> {
    return this.post(
      'SendPatientEmail',
      { emailContent },
      this.headerService.authorisedHeadersLegacy
    ).pipe(pluck('SendPatientEmailResult'));
  }

  getConsultationDetails(): Observable<any> {
    return this.post('GetConsultationDetails', {}, this.headerService.authorisedHeadersLegacy).pipe(
      pluck('GetConsultationDetailsResult'),
      map(res => {
        res.Patient.Name = `${res.Patient.FirstName} ${res.Patient.LastName}`;
        res.Patient.GenderLabel = Gender[res.Patient.Gender];
        res.Customer.Name = `${res.Customer.FirstName} ${res.Customer.LastName}`;
        res.Customer.GenderLabel = Gender[res.Customer.Gender];
        console.log(res);
        return res;
      })
    );
  }

  getIdentificationTypes(): Observable<any[]> {
    return this.post('GetIdentificationTypes', {}, this.headerService.authorisedHeadersLegacy).pipe(
      pluck('GetIdentificationTypesResult'),
      pluck('IdentificationTypes')
    );
  }
}
