import { Injectable } from '@angular/core';
import { toCamelCase } from '@pushdr/common/utils';
import { EnvironmentProxyService } from '@pushdr/environment';
import {
  RestErrorParserService,
  RestHttpHeaderService,
  RestClient,
} from '@pushdr/common/data-access/rest-http-core';
import { HttpClient } from '@angular/common/http';
import { pluck, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IdName } from '@pushdr/common/types';

export interface ConsultationNotes {
  FollowUpConsultations: string[];
  IssueResolutionDate: string;
  intNoteID: number;
  strConsultationCode: string;
  strDate: string;
  strDiagnosis: string;
  strExamination: string;
  strManagementPlan: string;
  strSafetyNetting: string;
  confidential?: boolean;
  overrideGpShareReason?: string;
}

export interface HistoricConsultationNotes {
  FollowUpConsultations: any;
  IssueResolutionDate: any;
  intNoteID: number;
  strConsultationCode: string;
  strDate: string;
  strDiagnosis: string;
  strExamination: string;
  strManagementPlan: string;
  strSafetyNetting: string;
}

@Injectable({
  providedIn: 'root',
})
export class ApiDoctorsConsultationNotesService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.doctors.api + '/consultationnotes.svc/consultationnotesW/';
  }

  getConsultationNotes(orderId: number): Observable<ConsultationNotes> {
    return this.post(
      'GetConsultationNotes',
      { orderId },
      this.headerService.authorisedHeadersLegacy
    ).pipe(pluck('GetConsultationNotesResult'));
  }

  addConsultationNotes(orderId: number): Observable<number> {
    return this.post(
      'AddConsultationNotes',
      { orderId },
      this.headerService.authorisedHeadersLegacy
    ).pipe(
      pluck('AddConsultationNotesResult'),
      map(notesId => +notesId)
    );
  }

  editConsultationNotes(orderId: number, consultationNotes: ConsultationNotes) {
    return this.post(
      'EditConsultationNotes',
      { ...consultationNotes, orderId },
      this.headerService.authorisedHeadersLegacy
    ).pipe(pluck('EditConsultationNotesResult'));
  }

  getConsultationNote(consultationId: number): Observable<HistoricConsultationNotes> {
    return this.post(
      'GetConsultationNote',
      { intConsultationID: consultationId },
      this.headerService.authorisedHeadersLegacy
    ).pipe(pluck('GetConsultationNoteResult'));
  }

  getDiagnoses(): Observable<IdName[]> {
    return this.post('GetDiagnoses', {}, this.headerService.authorisedHeadersLegacy).pipe(
      pluck('Diagnoses'),
      toCamelCase()
    );
  }
}
