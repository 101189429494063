import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AnalyticsBusService,
  AnalyticsEvent,
  AnalyticsMessage,
} from '@pushdr/common/data-access/analytics';

declare const OT: any;

export interface VideoParameters {
  AccountId: string;
  RoomId: string;
  RoomUserId: string;
  VideoProvider?: number;
  consultationStatus?: any;
  EndVideoSession?: boolean;
}

@Component({
  selector: 'pushdr-opentok',
  templateUrl: './opentok.component.html',
  styleUrls: ['./opentok.component.scss'],
})
export class OpentokComponent implements OnInit, OnDestroy, OnChanges {
  @Input() videoParameters: VideoParameters;

  @Input() accountId: string;
  @Input() roomId: string;
  @Input() roomUserId: string;

  @Output() videoError = new EventEmitter<any>();
  @Output() participants = new EventEmitter<number>();

  session: any = null;
  publisher: any = null;
  private videoInitialised = false;
  private _connectionCount = 0;
  private publisherProperties = {
    resolution: '1280x720',
    framerate: 30,
    insertMode: 'append',
    width: '100%',
    height: '100%',
  };

  constructor(private bus: AnalyticsBusService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.accountId && changes.roomId && changes.roomUserId) {
      const newAccountId = changes.accountId.currentValue;
      const newUserId = changes.roomId.currentValue;
      const newRoomUserId = changes.roomUserId.currentValue;
      if (
        !this.videoInitialised &&
        this.hasValidData(newAccountId) &&
        this.hasValidData(newUserId) &&
        this.hasValidData(newRoomUserId)
      ) {
        this.videoParameters = {
          AccountId: newAccountId,
          RoomId: newUserId,
          RoomUserId: newRoomUserId,
        };
        this.initialiseOpentok();
      }
    }
  }

  ngOnInit() {
    if (this.videoParameters) {
      this.initialiseOpentok();
    }
  }

  initialiseOpentok() {
    this.createSession();
    this.createPublisher().catch(error =>
      this.handleError(error, 'create-publisher', 'errorCreatingPublisher: ')
    );
    this.connectToSession()
      .then(() => this.publishToSession())
      .catch(error => this.handleError(error, 'connect-to-session', 'errorConnectingToSession: '));

    this.session.on({
      streamCreated: event => {
        this.setupStream(event).catch(error =>
          this.handleError(error, 'stream-created', 'errorCreatingStream: ')
        );
      },
      connectionCreated: () => this.connectionCount++,
      connectionDestroyed: () => this.connectionCount--,
      sessionDisconnected: event =>
        this.handleError(event, 'session-disconnected', 'errorDisconnectingSession: '),
    });

    this.videoInitialised = true;
  }

  ngOnDestroy() {
    this.log(
      'disconnected',
      'DISCONNECTED: ' +
        JSON.stringify({ hasPublisher: !!this.publisher, hasSession: !!this.session })
    );
    if (this.publisher != null) this.publisher.destroy();
    if (this.session != null) {
      this.session.off('streamCreated');
      this.session.disconnect();
    }
  }

  get connectionCount() {
    return this._connectionCount;
  }

  set connectionCount(value: number) {
    this.log('participants-count', 'NumberOfParticipants: ' + value);
    this._connectionCount = value;
    this.participants.emit(this._connectionCount);
  }

  private createSession() {
    this.log('create-session-triggered', 'CREATE_SESSION_TRIGGERED');
    this.session = OT.initSession(this.videoParameters.AccountId, this.videoParameters.RoomId);
  }

  private createPublisher() {
    return new Promise<void>((resolve, reject) => {
      this.publisher = OT.initPublisher('publisher', this.publisherProperties, error => {
        this.log('init-publisher-result', error || 'PUBLISHER_INITED', !error);
        error ? reject(error) : resolve();
      });
    });
  }

  private connectToSession() {
    return new Promise<void>((resolve, reject) => {
      this.session.connect(this.videoParameters.RoomUserId, error => {
        this.log('session.connect-result', error || 'CONNECTED_TO_SESSION', !error);
        error ? reject(error) : resolve();
      });
    });
  }

  private publishToSession() {
    return new Promise<void>((resolve, reject) => {
      this.session.publish(this.publisher, error => {
        this.log('session.publish-result', error || 'SESSION_PUBLISH_SUCCESS', !error);
        error ? reject(error) : resolve();
      });
    });
  }

  private setupStream(event) {
    return new Promise<void>((resolve, reject) => {
      this.session.subscribe(
        event.stream,
        'subscriber',
        {
          insertMode: 'append',
          width: '100%',
          height: '100%',
          buttonDisplayMode: 'off',
        },
        error => {
          this.log('session.stream-created-result', error || 'SESSION_STREAM_CREATED');
          error ? reject(error) : resolve();
        }
      );
    });
  }

  private handleError(error, action: string, msg: string = '') {
    this.log(action, `ERROR: ${JSON.stringify(msg)}`, false);
    this.videoError.emit(error);
  }

  private hasValidData(data: string) {
    return data && data.indexOf('{{') === -1;
  }

  private log(action: string, message: AnalyticsMessage = 'OK', isInfo = true) {
    const event = isInfo
      ? AnalyticsEvent.info(`OT.${action}`, message)
      : AnalyticsEvent.error(`OT.${action}`, message);
    this.bus.trackEvent(event);
  }
}
