import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { CliniciansCommonModule } from '@pushdr/clinicians/common';
import { CommonComponentsModule } from '@pushdr/common/components';
import { CommonPipesModule } from '@pushdr/common/utils';
import { CliniciansWaitingRoomComponent } from './clinicians-waiting-room.component';
import { EndSessionModalComponent } from './components/end-session-modal/end-session-modal.component';
import { GoodPracticeModalComponent } from './components/good-practice-modal/good-practice-modal.component';
import { PersonIconComponent } from './components/person-icon/person-icon.component';
import { SessionStatsComponent } from './components/session-stats/session-stats.component';
import { WaitingRoomComponent } from './routes/waiting-room/waiting-room.component';
import { WelcomeComponent } from './routes/welcome/welcome.component';

export const cliniciansWaitingRoomRoutes: Route[] = [
  {
    path: '',
    redirectTo: 'welcome',
    pathMatch: 'full',
  },
  {
    path: 'welcome',
    component: WelcomeComponent,
  },
  {
    path: 'active',
    component: WaitingRoomComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CommonComponentsModule,
    CommonPipesModule,
    A11yModule,
    CliniciansCommonModule,
  ],
  declarations: [
    WelcomeComponent,
    WaitingRoomComponent,
    CliniciansWaitingRoomComponent,
    PersonIconComponent,
    GoodPracticeModalComponent,
    SessionStatsComponent,
    EndSessionModalComponent,
  ],
})
export class CliniciansWaitingRoomModule {}
