import { Component } from '@angular/core';
import { fadeInOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'pushdr-clinicians-auth',
  templateUrl: './clinicians-auth.component.html',
  styleUrls: ['./clinicians-auth.component.scss'],
  animations: [fadeInOnEnterAnimation({ anchor: 'showCard' })],
})
export class CliniciansAuthComponent {}
