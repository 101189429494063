import { Observable } from 'rxjs';

export class PropertyTransforms {
  constructor() {}
  private static toCamOrTitle(o, toCam = true): any {
    let origKey, newKey;
    if (o instanceof Array) {
      return o.map(value =>
        typeof value === 'object' ? PropertyTransforms.toCamOrTitle(value, toCam) : value
      );
    } else {
      const newO = {};
      for (origKey in o) {
        if (Object.prototype.hasOwnProperty.call(o, origKey)) {
          newKey = (
            (toCam ? origKey.charAt(0).toLowerCase() : origKey.charAt(0).toUpperCase()) +
              origKey.slice(1) || origKey
          ).toString();
          let value = o[origKey];
          if (
            value instanceof Array ||
            (typeof value !== 'undefined' && value !== null && value.constructor === Object)
          ) {
            value = PropertyTransforms.toCamOrTitle(value, toCam);
          }
          newO[newKey] = value;
        }
      }
      return newO;
    }
  }
  static toCamelCase = o => PropertyTransforms.toCamOrTitle(o, true);
  static toTitleCase = o => PropertyTransforms.toCamOrTitle(o, false);
}

export function toCamelCase() {
  return function <T>(source: Observable<T>): Observable<T> {
    return new Observable(subscriber => {
      source.subscribe({
        next(value) {
          if (value !== undefined && value !== null) {
            subscriber.next(PropertyTransforms.toCamelCase(value));
          }
        },
        error(error) {
          subscriber.error(error);
        },
        complete() {
          subscriber.complete();
        },
      });
    });
  };
}
