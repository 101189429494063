import { Pipe, PipeTransform } from '@angular/core';
import { ClinicianType } from '@pushdr/common/types';

@Pipe({
  name: 'clinicianTypeAbbreviation',
})
export class ClinicianTypeAbbreviationPipe implements PipeTransform {
  transform(value: ClinicianType): string {
    return clinicianTypeAbbreviationMap[value] ?? 'N/D';
  }
}

const clinicianTypeAbbreviationMap = {
  [ClinicianType.Unknown]: 'N/D',
  [ClinicianType.GeneralPractitioner]: 'GP',
  [ClinicianType.MinorAilmentsPharmacist]: 'MAPharma',
  [ClinicianType.Physio]: 'Physio',
  [ClinicianType.MentalHealthTherapist]: 'MHT',
};
