export enum ClinicalOutcomeResultTypeEnum {
  Success = 0,
  Discontinuation = 1,
  Disruption = 2,
}

export enum SuccessfulOutcome {
  ReferralRequired = 67,
}

export enum DiscontinuationOutcome {
  ReferralRequired = 72,
}
