import { Observable } from 'rxjs';

import {
  PatientDetailsV2,
  PatientStructuredRecords,
  PrescribedMedication,
} from '@pushdr/common/types';

// Used to mitigate issues with cross-imports (circular dependencies)
// Implements a proxy provider for the prescription services that requires these details
export abstract class PrescriptionProxy {
  abstract getPatientDetails(): Observable<PatientDetailsV2>;
  abstract getStructuredRecords(): Observable<PatientStructuredRecords>;
  abstract getCurrentAcuteMedications(): Observable<PrescribedMedication[]>;
}
