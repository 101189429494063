import { Component } from '@angular/core';
import { shareReplay, tap } from 'rxjs/operators';
import { PrescriptionWarningService } from '../services/prescription-warning.service';

@Component({
  selector: 'pushdr-accumulated-warnings',
  templateUrl: './accumulated-warnings.component.html',
  styleUrls: ['./accumulated-warnings.component.scss'],
})
export class AccumulatedWarningsComponent {
  warnings$ = this.prescriptionWarnings.warnings$.pipe(
    shareReplay({ bufferSize: 1, refCount: true }),
    tap({ error: () => (this.hasError = true) })
  );

  hasError = false;

  constructor(private prescriptionWarnings: PrescriptionWarningService) {}
}
