import { timer } from 'rxjs';
import { take, map } from 'rxjs/operators';

export interface QuestionAnswer {
  question: string;
  answer: string;
}

export const questionAnswers: QuestionAnswer[] = [
  {
    question: `Who should I contact regarding technical issues?`,
    answer: `Support team: <a class="text-primary flow-root" href="mailto:doctorsupport@pushdr.com?subject=PushConsult technical issue" target="_blank">doctorsupport@pushdr.com</a>
    0330 808 4702`,
  },
  {
    question: `Who should I contact regarding in-session patient related issues?`,
    answer: `Support team: <a class="text-primary flow-root" href="mailto:doctorsupport@pushdr.com">doctorsupport@pushdr.com</a>`,
  },
  {
    question: `In the event of a safeguarding issue, do I need to submit a referral myself?`,
    answer: `Yes, you will need to write and submit a referral
    yourself, then notify us using the provided form.
    Please closely follow the 4-step process which can
    be found by clicking the ‘Safeguarding
    Referral’ button on the bottom bar during consultation.`,
  },
];

// TODO: delete if we get an endpoint for this
export const questionAnswers$ = timer(200).pipe(
  take(1),
  map(() => questionAnswers)
);
