import { Pipe, PipeTransform } from '@angular/core';
import { toTitleCase } from '../../utils/utility-functions';

@Pipe({
  name: 'toTitleCase',
})
export class ToTitleCasePipe implements PipeTransform {
  transform(value: string): any {
    if (value) {
      return value.replace(/\w+/g, toTitleCase);
    } else {
      return '';
    }
  }
}
