import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Subject, of, merge } from 'rxjs';
import { debounceTime, takeUntil, map, switchMap, tap, catchError } from 'rxjs/operators';
import { ConsultationNotesService } from '../../../../services/consultation-notes/consultation-notes.service';
import { ConsultationNotes } from '@pushdr/doctors/data-access/doctors-api';
import { PatientNavigationService } from '../../../../services/patient-navigation/patient-navigation.service';

@Component({
  selector: 'pushdr-notes-capture',
  templateUrl: './notes-capture.component.html',
  styleUrls: ['./notes-capture.component.scss'],
})
export class NotesCaptureComponent implements OnInit, OnDestroy {
  notesForm = this.formBuilder.group({
    confidentialNotes: [false],
    examinationNotes: [''],
    managementPlanNotes: [''],
    safetyNettingNotes: [''],
    overrideGpShareReason: [''],
  });
  notes: ConsultationNotes;
  updating = false;
  errorUpdating = false;

  private destroy$ = new Subject<void>();
  private saveTrigger$ = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private notesService: ConsultationNotesService,
    private patientNav: PatientNavigationService
  ) {}

  ngOnInit() {
    this.notesService
      .getNotes$()
      .pipe(
        map(consultationNotes => (this.notes = consultationNotes)),
        tap(() => this.setInitialFormValues()),
        switchMap(() => this.updateTriggers$().pipe(tap(() => this.updateNotes$().subscribe()))),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: () => {
          this.notesForm.markAsPristine();
          this.updating = false;
        },
        error: () => {},
      });
  }

  ngOnDestroy() {
    this.manualSave();
    this.destroy$.next();
    this.destroy$.complete();
  }

  onClickBackToConsultation() {
    this.patientNav.gotoConsultationHome();
  }

  manualSave() {
    this.notesForm.markAsDirty();
    this.saveTrigger$.next();
  }

  setInitialFormValues() {
    this.notesForm.reset({
      confidentialNotes: !!this.notes.confidential,
      examinationNotes: this.notes.strExamination,
      managementPlanNotes: this.notes.strManagementPlan,
      safetyNettingNotes: this.notes.strSafetyNetting,
      overrideGpShareReason: this.notes.overrideGpShareReason,
    });
  }

  updateNotes$() {
    if (!this.notes || !this.notesForm.dirty) return of(null);
    this.updating = true;
    this.notes = {
      ...this.notes,
      confidential: this.notesForm.get('confidentialNotes').value,
      strExamination: this.notesForm.get('examinationNotes').value,
      strManagementPlan: this.notesForm.get('managementPlanNotes').value,
      strSafetyNetting: this.notesForm.get('safetyNettingNotes').value,
      overrideGpShareReason: this.notesForm.get('overrideGpShareReason').value,
    };
    return this.notesService.updateNotes(this.notes).pipe(
      map(() => {
        this.errorUpdating = false;
      }),
      catchError(() => {
        this.errorUpdating = true;
        return of(null);
      })
    );
  }

  private updateTriggers$() {
    return merge(this.handleFormUpdate$(), this.saveTrigger$);
  }

  private handleFormUpdate$() {
    return this.notesForm.valueChanges.pipe(debounceTime(2000));
  }
}
