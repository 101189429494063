<div class="c-modal__wrap c-modal__wrap--40" [cdkTrapFocus]="ready$ | async">
  <div class="c-modal__head">
    <div class="c-modal__title">
      <h2>
        {{ data.title }}
        <span *ngIf="data.dateCreated !== ''">
          <i class="material-icons">calendar_today</i>
          {{ data.dateCreated }}
        </span>
      </h2>
    </div>
    <div class="c-modal__iframe-modal-close">
      <button class="btn-outline" (click)="output()">Close</button>
    </div>
  </div>
  <iframe
    class="w-full bg-bluegrey-700"
    *ngIf="ready$ | async"
    #iframeInst
    title="{{ data.title }}"
    [src]="safeUrl"></iframe>
</div>

<ng-template #error>
  <h3>
    Something went wrong. If you wish to read this bulletin, please contact our operations team.
  </h3>
  <button class="btn-outline" (click)="output()">Close</button>
</ng-template>
