<article>
  <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
    <div
      class="c-form-part__field-wrap mb-4"
      [ngClass]="oldPasswordHasErrors ? 'pdr-input--error' : null">
      <label class="required">Current password</label>
      <pushdr-password-input formControlName="oldPassword"></pushdr-password-input>
      <p *ngIf="oldPasswordHasErrors" class="m-0 text-error">A password is required.</p>
    </div>

    <div class="c-form-part__field-wrap">
      <label class="required">New password</label>
      <pushdr-password-input-with-rules
        tooltipPosition="left"
        [formControlProxy]="passwordForm.get('newPassword')"></pushdr-password-input-with-rules>
    </div>

    <button
      class="px-8 mt-6 btn btn-primary btn-tiny"
      [ngClass]="passwordForm.invalid ? 'opacity-50 cursor-not-allowed' : null"
      type="submit"
      [disabled]="passwordForm.invalid">
      Update password
    </button>
  </form>
</article>
