import { Injectable } from '@angular/core';
import { MedicationTypes, PrescribedMedication, PrescriptionStatus } from '@pushdr/common/types';
import { cache, filterArray, PagedData, paging, sortDate } from '@pushdr/common/utils';
import { ApiPrescriptionsService } from '@pushdr/prescription/api';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, map, startWith, switchMap, toArray } from 'rxjs/operators';
import { PatientRecordsService } from './patient-records.service';

@Injectable({
  providedIn: 'root',
})
export class MedicationsService {
  private structuredCurrentRecordPD$: Observable<PrescribedMedication[]>;
  private structuredPastRecordPD$: Observable<PrescribedMedication[]>;

  constructor(
    private records: PatientRecordsService,
    private apiPrescription: ApiPrescriptionsService
  ) {}

  getStructAcuteMedications$() {
    return this.records
      .getStructuredPatientRecord()
      .pipe(
        map(record =>
          record.displayedMedicationsCombined.filter(
            medInfo => medInfo.type === MedicationTypes.acute
          )
        )
      );
  }

  getAcuteMedicationsPaged$(
    pageSize$: Observable<number>,
    page$: Observable<number>
  ): Observable<PagedData<PrescribedMedication>> {
    return this.getAcuteMedications$().pipe(paging(pageSize$, page$));
  }

  getAcuteMedicationsPagedFiltered$(
    pageSize$: Observable<number>,
    page$: Observable<number>,
    filter: (value: PrescribedMedication) => boolean = () => true
  ): Observable<PagedData<PrescribedMedication>> {
    return this.getAcuteMedications$().pipe(filterArray(filter), paging(pageSize$, page$));
  }

  getAcuteMedicationsPagedActive$(
    pageSize$: Observable<number>,
    page$: Observable<number>
  ): Observable<PagedData<PrescribedMedication>> {
    const activeFilter = (med: PrescribedMedication) => med.isActive;
    return this.getAcuteMedicationsPagedFiltered$(pageSize$, page$, activeFilter);
  }

  getAcuteMedicationsPagedPast$(
    pageSize$: Observable<number>,
    page$: Observable<number>
  ): Observable<PagedData<PrescribedMedication>> {
    const pastFilter = (med: PrescribedMedication) => !med.isActive;
    return this.getAcuteMedicationsPagedFiltered$(pageSize$, page$, pastFilter);
  }

  getAcuteMedications$() {
    return combineLatest([
      this.getStructAcuteMedications$().pipe(startWith([])),
      this.getPdPrivateMedicationsCurrent$().pipe(startWith([])),
      this.getPdPrivateMedicationsPast$().pipe(startWith([])),
    ]).pipe(
      map(([struct, pdCur, pdPast]) => {
        return [].concat(struct).concat(pdCur).concat(pdPast);
      }),
      sortDate<PrescribedMedication>(x => x.startDate, true),
      cache()
    );
  }

  getEpsCurrentAcuteMedications(): Observable<PrescribedMedication[]> {
    return this.getCurrentPushDoctorAcuteMedicationsFromApi$();
  }

  private getPdPrivateMedicationsCurrent$() {
    return this.structuredCurrentRecordPD$
      ? this.structuredCurrentRecordPD$
      : this.getCurrentPushDoctorAcuteMedicationsFromApi$();
  }

  private getPdPrivateMedicationsPast$() {
    return this.structuredPastRecordPD$
      ? this.structuredPastRecordPD$
      : this.getPastPushDoctorAcuteMedicationsFromApi$();
  }

  private getCurrentPushDoctorAcuteMedicationsFromApi$ = () =>
    this.getMedications(nhsNumber =>
      this.apiPrescription.getCurrentPushDoctorAcuteMedications(nhsNumber)
    );

  private getPastPushDoctorAcuteMedicationsFromApi$ = () =>
    this.getMedications(nhsNumber =>
      this.apiPrescription.getPastPushDoctorAcuteMedications(nhsNumber)
    );

  private getMedications(apiS: (nhsNumber: string) => Observable<PrescribedMedication[]>) {
    const isIssuedMedication = (medication: PrescribedMedication) =>
      medication.medicationStatus === PrescriptionStatus.SuccessfullyIssued;

    const mapPdMedication = (medication: PrescribedMedication) =>
      <PrescribedMedication>{
        ...medication,
        isPd: true,
        type: MedicationTypes.acute,
        // Remaps snomed code to normalize the models
        medicationCode: medication.snomedCode,
      };

    return this.records.patientNhsNumber$.pipe(
      switchMap(nhsNumber => apiS(nhsNumber)),
      switchMap(medications =>
        of(...medications).pipe(
          filter(medication => isIssuedMedication(medication)),
          map(medication => mapPdMedication(medication)),
          toArray()
        )
      ),
      catchError(() => of([]))
    );
  }
}
