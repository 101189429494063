<div
  class="p-2 pt-3 pl-4 text-sm leading-none text-red-500 bg-orange-300 editing-warning"
  [ngClass]="gridLayoutCssClass">
  You are currently in edit mode.
</div>

<div
  *ngIf="isConsultationStarted"
  class="patient-consultation-grid"
  [ngClass]="gridLayoutCssClass + ' ' + (largeMainPanel$ | async)">
  <ng-container *ngIf="{ customisations: customisations$ | async } as layout">
    <pushdr-video-chat *ngIf="layout.customisations?.showVideo"></pushdr-video-chat>
    <pushdr-key-information></pushdr-key-information>
    <pushdr-consultation-dynamic-group *ngFor="let panelId of panelIds" [panelId]="panelId">
    </pushdr-consultation-dynamic-group>
    <ng-container *ngTemplateOutlet="outlet"></ng-container>
    <pushdr-consultation-footer
      (safeGuarding)="onSafeguarding()"
      (patientEmergency)="onPatientEmergency()"
      [endConsultationText]="layout.customisations?.closeText"
      [hideEmergency]="!layout.customisations?.showEmergency"></pushdr-consultation-footer>
  </ng-container>
</div>

<ng-template #outlet>
  <div id="outlet">
    <router-outlet> </router-outlet>
  </div>
</ng-template>
