import { Component, Input } from '@angular/core';

@Component({
  selector: 'pushdr-medication-notes',
  templateUrl: './medication-notes.component.html',
  styleUrls: ['./medication-notes.component.scss'],
})
export class MedicationNotesComponent {
  @Input() notes: Array<{ text: string; date: string }>;
}
