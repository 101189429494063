import { Component, OnInit } from '@angular/core';
import {
  CliniciansBulletinsService,
  CliniciansTermsCheckerService,
  CliniciansDoctorsDetailsService,
  DELAY3,
  DELAY4,
  DELAY2,
} from '@pushdr/clinicians/common';
import { fadeInOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'pushdr-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [
    fadeInOnEnterAnimation({ anchor: 'showBulletins', delay: DELAY2 }),
    fadeInOnEnterAnimation({ anchor: 'showSchedule', delay: DELAY3 }),
    fadeInOnEnterAnimation({ anchor: 'showFAQ', delay: DELAY4 }),
    fadeInOnEnterAnimation({ anchor: 'showWhatsNew', delay: DELAY4 }),
  ],
})
export class DashboardComponent implements OnInit {
  constructor(
    private bulletins: CliniciansBulletinsService,
    private terms: CliniciansTermsCheckerService,
    private profile: CliniciansDoctorsDetailsService
  ) {}

  ngOnInit() {
    this.runChecks();
  }

  private async runChecks() {
    await this.terms.check();
    await this.bulletins.check();
    await this.profile.check();
  }
}
