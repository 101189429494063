import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { PatientRecordsSection } from '@pushdr/common/types';

@Component({
  selector: 'pushdr-patient-records-table',
  templateUrl: './patient-records-table.component.html',
  styleUrls: ['./patient-records-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientRecordsTableComponent {
  @Input() data: PatientRecordsSection;
}
