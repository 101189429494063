import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { AbstractModal } from '../abstract-modal';
import { KeyValue } from '@angular/common';

export enum ControlTypeEnum {
  TEXT,
  SELECT,
}

export interface FormControlOption {
  name: string;
  label: string;
  formControlConfig: [string, ValidatorFn[] | ValidatorFn];
  type: ControlTypeEnum;
  options?: KeyValue<string | number, string | number>[];
}

export interface FormGroupModalParams {
  header?: string;
  subheader?: string;
  formControlOptions: FormControlOption[];
}
@Component({
  selector: 'pushdr-form-group-modal',
  templateUrl: './form-group-modal.component.html',
  styleUrls: ['form-group-modal.component.scss'],
})
export class FormGroupModalComponent extends AbstractModal<FormGroupModalParams> implements OnInit {
  ControlTypeEnum = ControlTypeEnum;
  formGroup: UntypedFormGroup = null;

  constructor(private fb: UntypedFormBuilder) {
    super();
  }

  ngOnInit() {
    const opts = {};
    this.data.formControlOptions?.forEach(item => {
      opts[item.name] = item.formControlConfig;
    });
    this.formGroup = this.fb.group(opts);
  }
}
