import { Component, OnInit } from '@angular/core';
import { RecordsSectionTypes, PatientRecordsSection } from '@pushdr/common/types';
import { PatientRecordsService } from '../../../services/patient-records/patient-records.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'pushdr-patient-administration',
  templateUrl: './patient-administration.component.html',
  styleUrls: ['./patient-administration.component.scss'],
})
export class PatientAdministrationComponent implements OnInit {
  recordSectionData$: Observable<PatientRecordsSection>;

  constructor(private patientRecordsService: PatientRecordsService) {}

  ngOnInit() {
    this.getRecordData();
  }

  getRecordData() {
    this.recordSectionData$ = this.patientRecordsService.getRecordsSection(
      RecordsSectionTypes.ADMINISTRATIVE
    );
  }
}
