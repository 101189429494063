import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ConsultationState } from '@pushdr/clinicians/common';
import { ModalService } from '@pushdr/common/overlay';
import { PatientDetailsV2 } from '@pushdr/common/types';
import { PatientAlert } from '@pushdr/doctors/data-access/doctors-api';
import { EMPTY, Observable } from 'rxjs';
import { catchError, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { PatientDetailsService } from '../../../../../services/patient-details/patient-details.service';
import { PatientNavigationService } from '../../../services/patient-navigation/patient-navigation.service';
import { IdCheckService, IdentificationType } from '../../../services/id-check/id-check.service';

@Component({
  selector: 'pushdr-child-id-check',
  templateUrl: './child-id-check.component.html',
  styleUrls: ['./child-id-check.component.scss'],
})
export class ChildIdCheckComponent implements OnInit {
  patient$: Observable<PatientDetailsV2>;
  childFormGroup: UntypedFormGroup;
  nhsChildFormGroup: UntypedFormGroup;
  childIdentificationTypes$: Observable<IdentificationType[]>;
  genders = [
    { description: 'Male', value: 1 },
    { description: 'Female', value: 2 },
    { description: 'Unspecified', value: 3 },
  ];
  submitting = false;

  constructor(
    private patientDetails: PatientDetailsService,
    private patientNav: PatientNavigationService,
    private modal: ModalService,
    private idCheckService: IdCheckService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.childIdentificationTypes$ = this.idCheckService.childIdentificationTypes$;
    this.patient$ = this.patientDetails.details$.pipe(
      tap(() => {
        this.childFormGroup = this.fb.group({
          idTypeSelect: [null, [Validators.required]],
          recordCheckConfirm: [null, [Validators.requiredTrue]],
          sameSurgeryConfirm: [null, [Validators.requiredTrue]],
        });
      })
    );
  }

  onSubmitChild() {
    if (this.submitting) return;
    this.submitting = true;

    const { idTypeSelect } = this.childFormGroup.value;
    this.patientDetails.details$
      .pipe(
        switchMap(details =>
          this.idCheckService.confirmChild$(
            details.Patient.Id,
            details.Customer.Id,
            idTypeSelect.IdentificationTypeId
          )
        ),
        catchError(err => {
          this.submitting = false;
          return this.modal.error(err.message).pipe(mergeMap(() => EMPTY));
        }),
        switchMap(() => this.patientDetails.alerts$),
        take(1)
      )
      .subscribe(
        alerts => this.exitIdChecks(alerts),
        err => {
          this.submitting = false;
          this.modal.error(err.message);
        }
      );
  }

  exitIdChecks(alerts: PatientAlert[]) {
    const nextConsultationState =
      alerts.length > 0 ? ConsultationState.CHECKING_ALERTS : ConsultationState.CONSULTING;
    this.patientDetails.clearCache();
    this.patientNav.gotoConsultationHome(nextConsultationState);
  }
}
