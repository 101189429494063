import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class CopyToClipboardService {
  constructor(@Inject(DOCUMENT) private dom) {}

  copy(valueToCopy: any): boolean {
    let textarea = null;

    try {
      textarea = this.dom.createElement('textarea');
      textarea.style.height = '1px';
      textarea.style.left = '-100px';
      textarea.style.opacity = '0';
      textarea.style.position = 'fixed';
      textarea.style.top = '-100px';
      textarea.style.width = '1px';
      this.dom.body.appendChild(textarea);
      textarea.value = valueToCopy;
      textarea.select();
      this.dom.execCommand('copy');
    } catch (e) {
      return false;
    }
    if (textarea && textarea.parentNode) {
      textarea.parentNode.removeChild(textarea);
    }
    return true;
  }
}
