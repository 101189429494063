import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PatientMedicationGroup } from '@pushdr/common/types';

@Component({
  selector: 'pushdr-medication-group',
  templateUrl: './medication-group.component.html',
  styleUrls: ['./medication-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MedicationGroupComponent {
  @Input() medicationGroup: PatientMedicationGroup;

  trackByIndex(index: number): number {
    return index;
  }
}
