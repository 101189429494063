<pushdr-card *ngIf="{ questionAnswers: questionAnswers$ | async } as data" [hasData]="true">
  <section class="flex flex-col w-full h-full">
    <header class="pt-5 pb-3 mx-5 text-xl font-light border-b border-grey-300">FAQs</header>
    <article class="flex-auto h-px px-5 py-3 overflow-y-auto scrollbar">
      <p>
        Here are some answers to our most commonly asked questions, we’ll keep this list updated so
        be sure to check back.
      </p>
      <div *ngFor="let qa of data.questionAnswers" class="flex flex-col mb-4 text-sm">
        <div class="flex flex-row mb-1 leading-5">
          <div class="flex-none w-8 font-normal">Q:</div>
          <div class="flex-grow font-normal">{{ qa.question }}</div>
        </div>
        <div class="flex flex-row leading-5">
          <div class="flex-none w-8 font-normal">A:</div>
          <div class="flex-grow font-light" [innerHTML]="qa.answer"></div>
        </div>
      </div>
    </article>
  </section>
</pushdr-card>
