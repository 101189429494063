import { NgModule } from '@angular/core';
import { IsoDatePipe } from './iso-date/iso-date.pipe';
import { SafeHtmlPipe } from './safeHtml-pipe/safe-html.pipe';
import { ToTitleCasePipe } from './toTitleCase/to-title-case.pipe';
import { FullTextSearchPipe } from './full-text-search/full-text-search.pipe';
import { TrackByPropertyPipe } from './track-by-property.pipe';
import { FormatNHSNumberPipe } from './formatNhsNumber/format-nhs-number.pipe';
import { BritishDatePipe } from './british-date/british-date.pipe';
import { NumberToWordsPipe } from './number-to-words/number-to-words.pipe';

const pipes = [
  IsoDatePipe,
  SafeHtmlPipe,
  ToTitleCasePipe,
  FullTextSearchPipe,
  TrackByPropertyPipe,
  FormatNHSNumberPipe,
  BritishDatePipe,
  NumberToWordsPipe,
];

@NgModule({
  declarations: [...pipes],
  exports: [...pipes],
})
export class CommonPipesModule {}
