import { Injectable, OnDestroy } from '@angular/core';
import { AnalyticsBusService, AnalyticsEvent } from '@pushdr/common/data-access/analytics';
import {
  ApiDoctorsConsultation as ApiCliniciansConsultation,
  ClinicianConsultationStatus,
} from '@pushdr/doctors/data-access/doctors-api';
import * as moment from 'moment';
import { Moment } from 'moment';
import { combineLatest, Observable, Subject, timer } from 'rxjs';
import { finalize, map, takeUntil } from 'rxjs/operators';

export enum ClinicianAwayState {
  ACTIVE,
  AWAY_IDLE,
  AWAY_MANUAL,
}

export interface ClinicianAwayStatus {
  state: ClinicianAwayState;
  start: Moment;
}

@Injectable({
  providedIn: 'root',
})
export class ClinicianAwayService implements OnDestroy {
  private readonly timerInterval = 1000;
  private readonly awayTimeoutSeconds = 60;

  private _startTime$ = new Subject<Moment>();
  private _clinicianAwayStatus$ = new Subject<ClinicianAwayStatus>();
  lastAwayState: ClinicianAwayState = ClinicianAwayState.ACTIVE;
  stopTimer$ = new Subject<void>();
  timer$: Observable<number>;

  constructor(private api: ApiCliniciansConsultation, private msgBus: AnalyticsBusService) {}

  get clinicianAwayStatus$(): Observable<ClinicianAwayStatus> {
    return this._clinicianAwayStatus$.asObservable();
  }

  initAwayState() {
    this._startTime$.next(moment());
    this.lastAwayState = ClinicianAwayState.ACTIVE;
    this.api.updateStatus(ClinicianConsultationStatus.AVAILABLE).toPromise();
  }

  get currentTimer$() {
    console.log('getting currentTimer$');
    if (!this.timer$) {
      this.stopTimer$ = new Subject<void>();
      console.log('getting a new timer');
      this.timer$ = timer(0, this.timerInterval).pipe(
        takeUntil(this.stopTimer$),
        finalize(() => {
          this.timer$ = null;
          console.log('timer killed!!');
        })
      );
    }
    return this.timer$;
  }

  startTimer() {
    this.msgBus.trackEvent(
      AnalyticsEvent.info('clinician-away-service.startTimer', 'called startTimer')
    );
    combineLatest([this._startTime$, this.currentTimer$])
      .pipe(
        takeUntil(this.stopTimer$),
        map(([start]) => moment.duration(moment().diff(start, 'seconds') * 1000))
      )
      .subscribe(res => {
        if (
          this.lastAwayState === ClinicianAwayState.ACTIVE &&
          res.asSeconds() >= this.awayTimeoutSeconds
        ) {
          this.setAwayIdle();
        }
      });
    this.resetTimer();
  }

  ngOnDestroy() {
    this.stopTimer();
  }

  setAway(from: Moment) {
    this.msgBus.trackEvent(AnalyticsEvent.info('clinician-away-service.setAway', 'called setAway'));
    this.setState(ClinicianAwayState.AWAY_MANUAL, from);
    this.stopTimer();
    this.api.updateStatus(ClinicianConsultationStatus.BUSY).toPromise();
  }

  setAwayIdle() {
    this.msgBus.trackEvent(
      AnalyticsEvent.info('clinician-away-service.setAwayIdle', 'called setAwayIdle')
    );
    this.setState(ClinicianAwayState.AWAY_IDLE);
    this.stopTimer();
    this.api.updateStatus(ClinicianConsultationStatus.BUSY).toPromise();
  }

  setActive() {
    this.msgBus.trackEvent(
      AnalyticsEvent.info('clinician-away-service.setActive', 'called setActive')
    );
    this.setState(ClinicianAwayState.ACTIVE);
    this.stopTimer();
    this.api.updateStatus(ClinicianConsultationStatus.AVAILABLE).toPromise();
  }

  setUnavailable() {
    this.api.updateStatus(ClinicianConsultationStatus.UNAVAILABLE).toPromise();
  }

  stopTimer() {
    this.msgBus.trackEvent(
      AnalyticsEvent.info('clinician-away-service.stopTimer', 'called stopTimer')
    );
    this.stopTimer$.next();
    this.stopTimer$.complete();
  }

  resetTimer(from = moment()) {
    this.msgBus.trackEvent(
      AnalyticsEvent.info('clinician-away-service.resetTimer', 'called resetTimer')
    );
    this._startTime$.next(from);
  }

  private setState(state: ClinicianAwayState, from: Moment = moment()) {
    if (this.lastAwayState === state) return;
    this.msgBus.trackEvent(
      AnalyticsEvent.info(
        'clinician-away-service.set-state.now',
        'ClinicianAwayTimeNow: ' + moment().toISOString()
      )
    );
    this.msgBus.trackEvent(
      AnalyticsEvent.info(
        'clinician-away-service.set-state.startTime',
        'ClinicianAwayStartTime: ' + from.toISOString()
      )
    );
    this.msgBus.trackEvent(
      AnalyticsEvent.info('clinician-away-service.set-state.state', 'ClinicianAwayState: ' + state)
    );
    this._startTime$.next(from);
    this.resetTimer(from);
    this.lastAwayState = state;
    this._clinicianAwayStatus$.next({
      state: state,
      start: from,
    });
  }
}
