import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckBoxDirective } from './directives/checkbox.directive';
import { CheckPanelComponent } from './check-panel/check-panel.component';
import { InputNumberOnlyDirective } from './directives/input-number-only.directive';

@NgModule({
  declarations: [CheckPanelComponent, CheckBoxDirective, InputNumberOnlyDirective],
  imports: [CommonModule],
  exports: [CheckPanelComponent, CheckBoxDirective, InputNumberOnlyDirective],
})
export class FormComponentsModule {}
