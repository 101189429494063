import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Dispenser } from '@pushdr/prescription/api';
import { PrescriptionDispenserService } from '../services/prescription-dispenser.service';

@Component({
  selector: 'pushdr-select-dispenser',
  templateUrl: './select-dispenser.component.html',
  styleUrls: ['./select-dispenser.component.scss'],
})
export class SelectDispenserComponent {
  openDispensersOnly = false;

  constructor(
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private dispenser: PrescriptionDispenserService
  ) {}

  dispenserChanged(dispenser: Dispenser) {
    this.dispenser.setDispenser(dispenser);
    this.route.navigate(['../review-prescription'], { relativeTo: this.activatedRoute });
  }
}
