<pushdr-consultation-card [hasData]="true" [showFooter]="true" bodyPadding="p-0">
  <pushdr-prescription-card-header header></pushdr-prescription-card-header>

  <ng-container body>
    <div class="p-5 shadow-header" *ngIf="medication">
      <div class="rounded bg-indigo-100 p-1 flex items-center justify-between">
        <div class="text-base px-3">{{ medication.medicationName }}</div>
        <div>
          <button
            data-cy="change-medication"
            class="p-2 px-3 ml-5 bg-white btn text-primary"
            (click)="clearMedication()">
            Clear
          </button>
        </div>
      </div>
    </div>
    <div class="flex flex-col justify-start px-5 py-3 h-full" *ngIf="medication">
      <span class="my-2">Monograph</span>
      <div class="w-full relative flex-grow border border-grey-500">
        <div
          class="absolute top-0 bottom-0 w-full overflow-auto"
          [innerHTML]="medication?.monograph"></div>
      </div>
    </div>
  </ng-container>
  <ng-container footer>
    <div class="flex mt-3 mb-3">
      <div class="flex flex-col items-center w-full">
        <button
          data-cy="back-to-medication"
          class="py-3 px-5 btn bg-primary text-white"
          (click)="navigateToMedicationSpecify()">
          Back
        </button>
      </div>
    </div>
  </ng-container>
</pushdr-consultation-card>
