import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvironmentProxyService } from '@pushdr/environment';
import { StorageService } from '@pushdr/common/utils';

@Injectable()
export class MockApiInterceptor implements HttpInterceptor {
  mocks: {
    [k: string]: { dataPatch: string; requestPatch: any; preventApiCall: boolean; url: string };
  } = {};
  reroutes: { fromUrl: string; toUrl: string; enabled: boolean }[] = [];
  foundEndpoints: { dirty: boolean; list: { [k: string]: boolean } } = { dirty: false, list: {} };

  constructor(protected proxy: EnvironmentProxyService, protected storage: StorageService) {
    if (!this.proxy.environment.production) {
      this.mocks = this.storage.getLocalStorage('ApiMocks', true);
      this.reroutes = this.storage.getLocalStorage('ApiReroutes', true);

      if (!(this.mocks instanceof Object)) this.mocks = {};
      if (!(this.reroutes instanceof Object)) this.reroutes = [];
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.proxy.environment.production) {
      return next.handle(req);
    } else {
      req = this.applyReroutes(req);
      const matches = Object.entries(this.mocks)
        .filter(([, data]) => req.url.indexOf(data.url) >= 0)
        .map(([, data]) => data);
      if (matches.length) {
        const preventApiCall = matches.filter(f => f.preventApiCall).length > 0;
        const fullPatch = _.merge({}, ...matches.map(x => x.dataPatch));
        const requestPatch = _.merge({}, ...matches.map(x => x.requestPatch));
        if (preventApiCall) {
          return of(new HttpResponse({ status: 200, body: fullPatch }));
        } else {
          const patchedBody = _.merge(req.body, requestPatch);
          const patchedReq = req.clone({ body: patchedBody });
          return next.handle(patchedReq).pipe(
            map((event: any) => {
              if (!(event instanceof HttpResponse)) return event;
              return event.clone({ body: _.merge(event.body, fullPatch) });
            })
          );
        }
      } else {
        return next.handle(req);
      }
    }
  }

  private applyReroutes(req: HttpRequest<any>) {
    if (!this.foundEndpoints.list[req.url]) {
      this.foundEndpoints.list[req.url] = true;
      this.foundEndpoints.dirty = true;
    }

    const routeMatches = Object.entries(this.reroutes)
      .filter(([, data]) => data.enabled && req.url.indexOf(data.fromUrl) >= 0)
      .map(([, data]) => data);

    if (routeMatches.length > 0) {
      const bestMatch = routeMatches.sort((a, b) => b.fromUrl.length - a.fromUrl.length)[0];
      const newUrl = req.url.replace(bestMatch.fromUrl, bestMatch.toUrl);
      req = req.clone({ url: newUrl });
    }
    return req;
  }
}
