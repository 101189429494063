import { Pipe, PipeTransform } from '@angular/core';

// http://hl7.org/fhir/stu3/datatypes.html#codeableconcept
export interface CodeableConcept {
  coding?: Coding[];
  text?: string;
}

interface Coding {
  system?: string;
  version?: string;
  code?: string;
  display?: string;
  userSelected?: boolean;
}

@Pipe({
  name: 'getCodingText',
})
export class GetCodingTextPipe implements PipeTransform {
  transform(value: CodeableConcept, defaultValue = ''): any {
    if (value) {
      if (value.text) {
        return value.text;
      } else if (value.coding && value.coding[0] && value.coding[0].display) {
        return value.coding[0].display;
      }
    }
    return defaultValue;
  }
}
