<div class="pre-surgery-feedback" data-cy="feedback" *ngIf="state">
  <div
    class="c-notification"
    [ngClass]="{
      'c-notification__info': state.indexOf('Info') > -1,
      'c-notification__warning': state.indexOf('Warning') > -1,
      'c-notification__error': state.indexOf('Error') > -1
    }"
    [ngSwitch]="state">
    <ng-container *ngSwitchCase="CameraCheckStates.CAMERA_DEFAULT_INFO">
      <h3 class="c-notification__title">Camera</h3>
      <p class="u-mb0@xs">Make sure that you're in a well lit place so they can clearly see you.</p>
    </ng-container>

    <ng-container *ngSwitchCase="CameraCheckStates.CAMERA_ADVICE_ERROR">
      <h4 class="c-notification__title">Having camera issues?</h4>
      <p class="u-mb0@xs">
        If you are unsure of how to fix it, take a look at our&nbsp;<a
          [attr.data-elevio-article]="helpId"
          trackClick="help"
          >advice</a
        >.
      </p>
    </ng-container>

    <ng-container *ngSwitchCase="CameraCheckStates.CAMERA_BLOCKED_ERROR">
      <h4 class="c-notification__title">Your camera is blocked</h4>
      <p class="u-mb0@xs">
        You'll need to allow access to your camera to continue. If you are unsure how to do this
        follow this&nbsp;<a [attr.data-elevio-article]="helpId" trackClick="help">advice</a>.
      </p>
    </ng-container>
  </div>
</div>

<div class="test-container u-m-auto">
  <video id="localVideoStream" autoplay></video>
</div>

<p>Can you see yourself clearly?</p>

<div class="pre-surgery-progression">
  <div class="pre-surgery-buttons">
    <button
      [disabled]="canProgress ? null : true"
      class="c-btn c-btn--primary c-btn--small lg:mb-0"
      data-cy="next"
      (click)="next()"
      data-cy="next"
      trackClick="yes">
      Yes
    </button>
    <button
      [disabled]="canProgress ? null : true"
      data-cy="no"
      [attr.data-elevio-article]="helpId"
      class="c-btn c-btn--primary c-btn--white-bg c-btn--small pre-surgery--btn_margin lg:mb-0"
      (click)="userSaidNo()">
      No
    </button>
  </div>
</div>
