import { Pipe, PipeTransform } from '@angular/core';

import { Consultation } from '../types/Consultation';

@Pipe({ name: 'filterByPrescriber' })
export class ConsultationsFilterByPrescriberPipe implements PipeTransform {
  transform(allConsultations: Consultation[], prescriberId: string = '') {
    if (!allConsultations) {
      return [];
    }
    return allConsultations.filter((c: Consultation) => {
      if (!prescriberId) {
        return true;
      }
      return c.prescriberId === prescriberId;
    });
  }
}
