import { PharmaceuticalsMedicationDetails } from '@pushdr/prescription/api';
import { PrescriptionInputData } from '@pushdr/prescription/fdb';
import { PatientDetailsV2 } from '@pushdr/common/types';
import { DoctorDetails, RbacPerms, SmartCardData } from '@pushdr/doctors/data-access/doctors-api';
import { PrescriptionCreate, PrescriptionMedication } from '@pushdr/prescription/api';
import { Dispenser } from '@pushdr/prescription/api';

export class PrescriptionMapper {
  static mapMedication(
    basketMedication: PrescriptionInputData,
    medicationDetails: PharmaceuticalsMedicationDetails
  ): PrescriptionMedication {
    const unitName = basketMedication.unitOfMeasure;
    const foundPackSize = findPackSize(unitName, medicationDetails);

    // Currently EPS does not send duration field alongside with the dosage
    // Mitigate this issue by decorating the dosage field with the duration value
    const duration = +basketMedication.duration > 0 ? `(${basketMedication.duration} days)` : '';
    const decoratedDosage = `${basketMedication.dosage} ${duration}`;

    return <PrescriptionMedication>{
      unitTypeCode: foundPackSize.unitTypeCode,
      unitTypeDescription: foundPackSize.unitTypeDescription,
      controlledScheduleNumber: medicationDetails.controlledScheduleNumber,
      medicationCode: basketMedication.medicationCode, // Medication Code is the SnomedCode
      displayName: basketMedication.medicationName,
      quantity: basketMedication.quantity,
      dosage: decoratedDosage,
      durationDays: +basketMedication.duration,
      endorsement: basketMedication.endorsements?.filter(f => f), // TODO : This list contains some false objects for some reason.  Anton will sort this out later.  Filter will not be require when fixed
      dispenserNote: basketMedication.pharmacyNote, // Assuming this is correct mapping.  It errors if empty on the API
      patientNote: basketMedication.patientNote,
      warningOverriddenReason: basketMedication.warningOverriddenReason,
    };
  }

  static mapPrescription(
    details: { card: SmartCardData; patientDetails: PatientDetailsV2; doctor: DoctorDetails },
    dispenser: Dispenser,
    patientNotes: string
  ): PrescriptionCreate {
    const surgery = details.patientDetails.Surgery;
    const patient = details.patientDetails.Patient;
    const partner = details.patientDetails.Partner;
    const nhsInfo = details.patientDetails.Patient.NhsInformation.SuccessResponse;
    const gpSum = details.patientDetails.Patient.GpSummary;
    const cardInfo = details.card;
    const sessionForPractice = details.card.rbac.getSelectedSession();
    const doctor = details.doctor;
    if (!sessionForPractice) alert('You have no prescribe sessions for this practice');
    if (!sessionForPractice.hasPermission(RbacPerms.prescriptionCreate)) {
      alert('You cannot prescribe');
      return null;
    }

    return {
      cardInfo: details.card.cardInfo,
      patientNotes: patientNotes,
      patientId: patient.Id,
      consultationId: surgery.OrderId, // TODO: Not sure if this is correct.  Probably OrderId
      partner: {
        // This is pharmacy info
        partnerId: partner.Id, // Not sure where to get this id
        dispenser: {
          // Not sure why dispenser is here if this is the surgery
          ods: dispenser.ods,
          name: dispenser.name,
          postcode: dispenser.address.postcode,
          address: dispenser.address.line.filter(f => f).join(', '),
          phoneNumber: 'tel:' + dispenser?.patient_contact?.tel,
        },
      },
      patient: {
        nhsNumber: patient.NhsInformation.SuccessResponse.NhsNumber, //Not working for my patients nhs  Orpha Grace   9444500764
        dateOfBirth: nhsInfo.DateOfBirth,
      },
      pharmacyOdsCode: dispenser.ods, ///// Should this be from the dispenser?  I did think dispenser was the pharmacy but then there is a dispenser under partner.
      prescriber: {
        prescribersTelephoneNumber: 'tel:03308084702',
        prescribersName: doctor.name,
        prescribersGmcNumber: doctor.GMC, // get this from GetDetails strGMC
        roleProfileId: cardInfo.rbac.uniqueRoleProfileId, // should be from uniqueIdentifier in samal session
        roleBasedAccessCode: sessionForPractice.nhsJobRoleCode, // use the full nhsJobRoleCode for the sessions for this practice with r8000 perms
        prescribersUniqueIdentifier: cardInfo.rbac.uniqueUserId, // From the uid on the card
      },
      prescriptionType: '0001', // 0001 is for Acute | 0002 = Repeat Prescribing | 0003 = Repeat Dispensing
      dispensingSiteType: 'P1', // P1 = Community Prescription
      pertinentPrescriptionType: '0101', // 0101 = GP in Primary Care
      medications: [], // Populated later async
    } as PrescriptionCreate;
  }
}

function findPackSize(unitName: string, details: PharmaceuticalsMedicationDetails) {
  const foundPackSize = details.packSize.find(p => p.unitTypeDescription === unitName);
  return foundPackSize ?? details.packSize[0];
}
