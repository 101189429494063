import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, publishReplay, refCount, switchMap, toArray } from 'rxjs/operators';

import { ConsultationFeatureService } from './consultation-feature/consultation-feature.service';

export interface Visibility {
  html: boolean;
  struct: boolean;
}

export interface VisibilityItem {
  visibility?: Partial<Visibility>;
}

@Injectable({
  providedIn: 'root',
})
export class ConsultationVisibilityService {
  readonly visibilityFlags$ = combineLatest([
    this.consultationFeatures.canSeeRecordsHTML$,
    this.consultationFeatures.canSeeRecordsStructured$,
  ]).pipe(
    map(([html, struct]) => <Visibility>{ html, struct }),
    // Cache items to avoid redundant HTTP calls
    publishReplay(1),
    refCount()
  );

  constructor(private consultationFeatures: ConsultationFeatureService) {}

  getVisibleItemsFrom<T extends VisibilityItem>(items: T[]): Observable<T[]> {
    return this.visibilityFlags$.pipe(
      switchMap(checks => {
        return of(...items).pipe(
          filter(item => this.isVisible(item.visibility, checks)),
          toArray()
        );
      })
    );
  }

  getVisibleFor(flags: Partial<Visibility>): Observable<boolean> {
    return this.visibilityFlags$.pipe(map(checks => this.isVisible(flags, checks)));
  }

  isVisible(flags: Partial<Visibility>, checkFlags: Visibility): boolean {
    // Missing flag from the visibility object resolves into True value
    return Object.keys(flags ?? {}).every(key => flags[key] === checkFlags[key]);
  }
}
