import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'pushdr-person-icon',
  templateUrl: './person-icon.component.html',
  styleUrls: ['./person-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonIconComponent {
  @Input() isSearching = false;
  @Input() isFound = false;

  constructor() {}
}
