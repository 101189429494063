import { Injectable, Type } from '@angular/core';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { SimpleModalOptionsOverrides } from 'ngx-simple-modal/simple-modal/simple-modal-options';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { mergeMap, switchMap } from 'rxjs/operators';
import { ButtonTypesEnum } from '../types';
import { AbstractModal, AbstractModalConfig } from './abstract-modal';
import { AcknowledgeModalComponent } from './acknowledge-modal/acknowledge-modal.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import {
  FormGroupModalComponent,
  FormGroupModalParams,
} from './form-group-modal/form-group-modal.component';
import { GenericModalComponent, GenericModalParams } from './generic-modal/generic-modal.component';
import { LoaderComponent } from './loader/loader.component';
import { SuccessModalComponent } from './success-modal/success-modal.component';

@Injectable()
export class ModalService {
  modalGroups: {
    [key: string]: Subject<boolean>[];
  } = {};

  constructor(private simpleModal: SimpleModalService) {}

  private bootstrapModal<T>(config: T, group?: string): AbstractModalConfig<T> {
    group = group ?? config['group'] ?? 'default';
    const modalConfig: AbstractModalConfig<T> = {
      data: config,
      closer: new BehaviorSubject<boolean>(false),
      group: group,
    };

    if (group) {
      if (!this.modalGroups[group]) {
        this.modalGroups[group] = [];
      } else {
        this.close(group);
      }
      this.modalGroups[group].push(modalConfig.closer);
    }

    return modalConfig;
  }

  showGenericModal(data: GenericModalParams, group?: string): Observable<any> {
    if (!data.buttons) {
      data.buttons = [
        {
          caption: 'Okay',
          id: 'ok',
        },
      ];
    }
    const config = this.bootstrapModal(data, group);

    let classes = 'c-modal--is-visible';

    if (data.className) classes += ' ' + data.className;
    if (data.longModal) classes += ' c-modal--long-modal';

    return this.simpleModal.addModal(GenericModalComponent, config, {
      closeOnClickOutside: data.enableBackgroundClicks,
      wrapperClass: classes,
    });
  }

  showLoader(config?: { header?: string; bottomText?: string }, group?: string) {
    const modalConfig = this.bootstrapModal(config || { bottomText: 'Loading...' }, group);

    return this.simpleModal.addModal(LoaderComponent, modalConfig, {
      wrapperClass: 'c-modal--is-visible c-modal--loader u-text-center',
      animationDuration: 50,
      autoFocus: true,
    });
  }

  success(message: string, group?: string) {
    const config = this.bootstrapModal({ bottomText: message }, group);
    return this.simpleModal.addModal(SuccessModalComponent, config);
  }

  error(message: string, group?: string) {
    const config = this.bootstrapModal({ bottomText: message }, group);
    return this.simpleModal.addModal(ErrorModalComponent, config);
  }

  confirm(
    header: string,
    message: string,
    confirm: string = 'Confirm',
    cancel: string = 'Cancel',
    group?: string,
    buttonConfirmType = ButtonTypesEnum.primary,
    customHTML?: string
  ) {
    const config = this.bootstrapModal(
      {
        bottomText: message,
        header,
        confirm,
        cancel,
        buttonConfirmType,
        customHTML,
      },
      group
    );
    return this.simpleModal.addModal(ConfirmModalComponent, config, {
      closeOnClickOutside: false,
    });
  }

  acknowledge(header: string, message: string, acknowledge: string = 'Okay', group?: string) {
    const config = this.bootstrapModal({ bottomText: message, header, acknowledge }, group);
    return this.simpleModal.addModal(AcknowledgeModalComponent, config, {
      closeOnClickOutside: false,
    });
  }

  showCustom<T = any>(
    modalClass: Type<AbstractModal<any, T>>,
    props: any = {},
    group?: string,
    overrides?: SimpleModalOptionsOverrides
  ) {
    const config = this.bootstrapModal(props, group);
    return this.simpleModal.addModal(modalClass, config, overrides);
  }

  showForm(data: FormGroupModalParams, group?: string): Observable<any> {
    const config = this.bootstrapModal(data, group);
    return this.simpleModal.addModal(FormGroupModalComponent, config, {});
  }

  close(group = 'default') {
    if (!this.modalGroups[group]) return;
    this.modalGroups[group].forEach(closer => {
      closer.next(true);
    });
  }

  remove<T = any>(component: SimpleModalComponent<any, any>) {
    this.simpleModal.removeModal(component);
  }

  tryAgain(title: string, message: string, group?: string): Observable<boolean> {
    const config = this.bootstrapModal({
      header: title,
      bottomText: message,
      buttonConfirmType: ButtonTypesEnum.primary,
      confirm: 'Try Again',
      cancel: 'Cancel',
      group,
    });

    return this.simpleModal.addModal(ConfirmModalComponent, config);
  }

  tryAgainFor(errors: Observable<any>, title: string, group?: string): Observable<void> {
    return errors.pipe(
      mergeMap(error =>
        this.tryAgain(title, error?.message ?? error, group).pipe(
          switchMap(response => (response ? of(void 0) : throwError(error)))
        )
      )
    );
  }
}
