<div class="c-modal__wrap">
  <h3 class="mb-8">End Editing</h3>
  <p>Are you sure you want to end editing the last consultation and return to the dashboard?</p>

  <div class="flex-row justify-center sm:block md:flex">
    <button
      class="p-4 pl-6 pr-6 bg-white border border-solid rounded hover:bg-grey-100 border-primary text-primary md:mr-2"
      (click)="done(false)">
      Cancel
    </button>
    <button
      class="p-4 pl-6 pr-6 text-white rounded bg-primary hover:bg-red-300 md:ml-2"
      (click)="done(true)">
      Back to dashboard
    </button>
  </div>
</div>
