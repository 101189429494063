import { Component, Input } from '@angular/core';
import { MedicationTypes } from '@pushdr/common/types';

@Component({
  selector: 'pushdr-medication-issues-item',
  templateUrl: './medication-issues-item.component.html',
  styleUrls: ['./medication-issues-item.component.scss'],
})
export class MedicationIssuesItemComponent {
  @Input() medicationType: MedicationTypes;
  @Input() issue: any;
}
