<main>
  <pushdr-sidebar-menu></pushdr-sidebar-menu>
  <section class="flex flex-col">
    <pushdr-consultation-menu
      [@showMenu]
      *ngIf="isMenuVisible$ | async; else inSetupProgress"></pushdr-consultation-menu>
    <ng-template #inSetupProgress>
      <pushdr-consultation-setup-progress
        #inSetupProgress
        [@showMenu]></pushdr-consultation-setup-progress>
    </ng-template>
    <div class="h-full" [@showContent]>
      <router-outlet></router-outlet>
    </div>
  </section>
</main>
