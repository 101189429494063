<ng-container *ngIf="reportGuid; else noid">
  <iframe
    *ngIf="pdfReport$ | async as pdfReport; else loading"
    class="flex flex-grow -m-4"
    [src]="pdfReport"
    title="Consultation notes preview"></iframe>
</ng-container>
<ng-template #noid>
  <p>There are no PDF notes for this consultation</p>
</ng-template>
<ng-template #loading>
  <p>Fetching pdf notes with id {{ reportGuid }}</p>
</ng-template>
