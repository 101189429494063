import { Component, OnInit } from '@angular/core';
import { AbstractModal } from '@pushdr/common/overlay';
import { Observable } from 'rxjs';

@Component({
  selector: 'pushdr-patient-is-dead-modal',
  templateUrl: './patient-is-dead-modal.component.html',
  styleUrls: ['./patient-is-dead-modal.component.scss'],
})
export class PatientIsDeadModalComponent extends AbstractModal<any, boolean> implements OnInit {
  ngOnInit() {
    this.closer.subscribe({
      next: x => {
        if (x) this.close();
      },
    });
  }
}
