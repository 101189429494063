<div class="my-3" *ngFor="let infoGroup of referential">
  <label class="block text-gray-700 text-sm mb-2">Category</label>
  <ng-select
    class="mb-3"
    placeholder="Select a category"
    notFoundText="No categories"
    [formControl]="categoryControl">
    <ng-option
      *ngFor="let category of infoGroup.categories"
      [value]="{ data: infoGroup.data, category: this.category }">
      {{ category }}
    </ng-option>
  </ng-select>
</div>
<br />
<p class="text-base" *ngFor="let info of referentialWarnings$ | async">
  {{ info.text }}
</p>
