import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, filter, take } from 'rxjs/operators';

import { ModalService } from '@pushdr/common/overlay';
import { PrescriptionInputData, PrescriptionInputDataState } from '@pushdr/prescription/fdb';
import { PrescriptionService } from '../services/prescription.service';
import { PrescriptionBasketService } from '../services/prescription-basket.service';
import { PrescriptionDispenserService } from '../services/prescription-dispenser.service';

interface SpecifyMedicationModel {
  medicationName: string;
  snomedCode: string;
}

@UntilDestroy()
@Component({
  selector: 'pushdr-specify-medication',
  templateUrl: './specify-medication.component.html',
  styleUrls: ['./specify-medication.component.scss'],
})
export class SpecifyMedicationComponent implements OnInit {
  medicationToPrescribeState: PrescriptionInputDataState;

  hasDispenser$ = this.dispenser.dispenser$.pipe(
    // Determine if valid dispenser has been selected
    map(dispenser => dispenser && Object.prototype.hasOwnProperty.call(dispenser, 'ods'))
  );

  inputData$ = this.activatedRoute.queryParams.pipe(
    map(({ edit }) => this.basket.getPrescriptionBySnomedCode(edit) ?? null)
  );

  selectedMedication$ = this.inputData$.pipe(
    map(medication => medication ?? this.prescription.currentMedication),
    map((medication): SpecifyMedicationModel => {
      if (!medication) {
        return null;
      }
      if ('snomedCode' in medication) {
        return {
          snomedCode: medication.snomedCode,
          medicationName: medication.medicationName,
        };
      }
      return {
        snomedCode: medication.medicationCode,
        medicationName: medication.medicationName,
      };
    })
  );

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modal: ModalService,
    private prescription: PrescriptionService,
    private basket: PrescriptionBasketService,
    private dispenser: PrescriptionDispenserService
  ) {}

  ngOnInit() {
    const hasNoneMedicationSelected$ = this.selectedMedication$.pipe(
      filter(medication => !medication),
      untilDestroyed(this)
    );

    hasNoneMedicationSelected$.subscribe({
      next: () => this.clearMedication(),
    });
  }

  updateMedicationToPrescribe(event: PrescriptionInputDataState) {
    this.medicationToPrescribeState = event;
  }

  async prescribeMedication() {
    if (this.medicationToPrescribeState) {
      const warningOverriddenReason = this.prescription.currentOverriddenReason;
      const prescriptionItem: PrescriptionInputData = {
        ...this.medicationToPrescribeState.form,
        warningOverriddenReason,
      };

      if (await this.confirmQuantityAmount(prescriptionItem)) {
        this.addMedicationToBasket(prescriptionItem);
      }
    }
  }

  clearMedication() {
    this.router.navigate(['../select-medication'], { relativeTo: this.activatedRoute });
  }

  private confirmQuantityAmount(item: PrescriptionInputData): Promise<boolean> {
    const openConfirmModal = () =>
      this.modal.confirm(
        'Confirm Quantity Amount',
        'Quantity is over 1000, are you sure you want to prescribe this amount?',
        'Continue',
        'Cancel'
      );

    if (item.quantity > 1000) {
      return openConfirmModal().toPromise();
    } else {
      return Promise.resolve(true);
    }
  }

  private addMedicationToBasket(item: PrescriptionInputData): void {
    this.basket.addPrescription(item);
    this.redirectToReviewOrDispenser();
  }

  private async redirectToReviewOrDispenser() {
    const hasDispenser = await this.hasDispenser$.pipe(take(1)).toPromise();
    const nextRoute = hasDispenser ? ['../review-prescription'] : ['../select-dispenser'];
    this.router.navigate(nextRoute, { relativeTo: this.activatedRoute });
  }
}
