import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

// Custom minLength validator that takes into account '0' values
export function minLengthInclusive(minLength: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    if (control.value?.length < minLength) {
      const actualLength = control.value?.length;
      const requiredLength = minLength;
      return { minlength: { actualLength, requiredLength } };
    }
    return null;
  };
}

export function uppercaseLetterRequired(control: AbstractControl): ValidationErrors {
  return /[A-Z]/.test(control.value) ? null : { uppercaseLetterRequired: true };
}

export function lowercaseLetterRequired(control: AbstractControl): ValidationErrors {
  return /[a-z]/.test(control.value) ? null : { lowercaseLetterRequired: true };
}

export function oneNumberRequired(control: AbstractControl): ValidationErrors {
  return /\d/.test(control.value) ? null : { oneNumberRequired: true };
}

export function oneSymbolRequired(control: AbstractControl): ValidationErrors {
  // Handles exception for < and > characters
  const hasInvalidChar = /[<>]/.test(control.value);
  const hasSpecialChar = /[/\\!$%^&*()\-_+|={}[:;?.,`~"'@#¬£\]]/.test(control.value);
  return hasSpecialChar && !hasInvalidChar ? null : { oneSymbolRequired: true };
}
