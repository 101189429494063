import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CurrentOrderIdService } from '@pushdr/common/data-access/analytics';
import { TranslatorDialState } from '@pushdr/common/types';
import { ApiDoctorsConsultation } from '@pushdr/doctors/data-access/doctors-api';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ConsultationTranslationService } from '../../../services/consultation-translation/consultation-translation.service';
import { PatientNavigationService } from '../../../services/patient-navigation/patient-navigation.service';

@Component({
  selector: 'pushdr-consultation-dial-translator',
  templateUrl: './consultation-dial-translator.component.html',
  styleUrls: ['./consultation-dial-translator.component.scss'],
})
export class ConsultationDialTranslatorComponent implements OnInit, OnDestroy {
  control = new UntypedFormControl(null);
  currentState$ = this.translation.currentState$;
  languages$ = this.translation.languagesList$;
  translatorDialState = TranslatorDialState;
  componentDestroyed$ = new Subject<void>();
  accessCode$ = this.translation.surgeryAccessCodeForTranslation$;

  constructor(
    private translation: ConsultationTranslationService,
    private consultation: ApiDoctorsConsultation,
    private order: CurrentOrderIdService,
    private patientNav: PatientNavigationService
  ) {}

  ngOnInit() {
    this.translation.initLanguages();
  }

  onClickBackToConsultation() {
    this.patientNav.gotoConsultationHome();
  }

  dialTranslator() {
    this.consultation
      .dialTranslator(this.order.id, this.control.value.LanguageCode)
      .pipe(take(1), takeUntil(this.componentDestroyed$))
      .subscribe(() => this.translation.currentState$.next(this.translatorDialState.CONNECTING));
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }
}
