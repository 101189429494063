import { Routes } from '@angular/router';
import { PresurgeryComponent } from './routes/pre-surgery/pre-surgery.component';
import { CameraCheckComponent } from './routes/camera-check/camera-check.component';
import { MicCheckComponent } from './routes/mic-check/mic-check.component';
import { SoundCheckComponent } from './routes/sound-check/sound-check.component';
import { SpeedCheckComponent } from './routes/speed-check/speed-check.component';
import { StartComponent } from './routes/start/start.component';

export const preSurgeryRoutes: Routes = [
  {
    path: '',
    component: PresurgeryComponent,
    children: [
      {
        path: '',
        redirectTo: 'start',
        pathMatch: 'full',
      },
      {
        path: 'start',
        component: StartComponent,
      },
      {
        path: 'camera',
        component: CameraCheckComponent,
      },
      {
        path: 'microphone',
        component: MicCheckComponent,
      },
      {
        path: 'sound',
        component: SoundCheckComponent,
      },
      {
        path: 'speed',
        component: SpeedCheckComponent,
      },
    ],
  },
];
