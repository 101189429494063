<div
  *ngIf="config.chatMessagesArr$ | async as chatMessagesArr"
  class="flex flex-col w-full justify-end"
  [ngClass]="isPatient ? 'patientChat ' : 'absolute inset-0 pb-10 bg-opacity-50'">
  <div
    #chatScrollElement
    class="block h-full max-h-full overflow-x-hidden overflow-y-scroll scroll-smooth">
    <div *ngFor="let chatMessage of chatMessagesArr; trackBy: trackByIndex">
      <ng-container [ngSwitch]="true">
        <div *ngSwitchCase="chatMessage.sender === 1" class="flex justify-end">
          <div
            class="relative max-w-sm px-5 py-3 mx-4 my-2 break-words bg-indigo-300 rounded-chat text-bluegrey-800 sent-message">
            {{ chatMessage.message }}
          </div>
        </div>
        <div *ngSwitchCase="chatMessage.sender === 2" class="flex">
          <div class="relative w-64 m-2 bg-cover rounded cursor-pointer">
            <div *ngIf="chatMessage.image$ | async as chatImage">
              <img
                *ngIf="chatImage.image; else imageError"
                (click)="
                  chatImage.image && enlargeChatImage(chatImage.image, chatMessage.receivedTime)
                "
                class="w-full"
                [src]="chatImage.image" />
              <ng-template
                #imageError
                class="flex flex-col justify-center p-2 text-center bg-white rounded">
                <h5>There has been an error.</h5>

                <h5 class="mt-4">Please get the patient to resend the image.</h5>
              </ng-template>
            </div>
            <ng-template #loadingImage>
              <pushdr-loader class="flex justify-center pt-12 pb-32 bg-white"></pushdr-loader>
            </ng-template>
          </div>
        </div>
        <div *ngSwitchCase="chatMessage.sender === 4" class="flex justify-end">
          <div class="relative w-64 m-2 bg-cover rounded cursor-pointer">
            <img class="w-full" [src]="chatMessage.message" />
          </div>
        </div>
        <div *ngSwitchDefault class="flex">
          <div
            class="relative max-w-sm px-5 py-3 mx-4 my-2 break-words rounded-chat bg-bluegrey-200 text-bluegrey-800 received-message">
            {{ chatMessage.message }}
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="w-full p-4">
    <form
      class="flex"
      [formGroup]="sendMessageForm"
      (ngSubmit)="sendMessageForm.valid && sendChatMessage()">
      <input
        #messageToSendElement
        name="messageToSend"
        maxlength="125"
        placeholder="Message..."
        formControlName="messageToSend"
        autocomplete="off"
        class="px-4 w-full m-0 outline-none rounded-chat focus:border-2 focus:border-indigo-300 pdr-input"
        [ngClass]="{ 'bg-bluegrey-200': isPatient }" />
      <button
        class="relative w-12 h-12 px-5 ml-4 text-white rounded-chat bg-primary"
        type="submit"
        [disabled]="!sendMessageForm.valid">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="18"
          viewBox="0 0 21 18"
          class="relative fill-current right-4">
          <path d="M0.00999999 18L21 9L0.00999999 0L0 7L15 9L0 11L0.00999999 18Z"></path>
        </svg>
      </button>
    </form>
  </div>
</div>
