import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';
import { CliniciansCommonModule } from '@pushdr/clinicians/common';
import { CliniciansProfileComponent } from './clinicians-profile.component';
import { CliniciansEditProfilePageComponent } from './routes/clinicians-edit-profile-page/clinicians-edit-profile-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CliniciansEditProfileComponent } from './components/clinicians-edit-profile/clinicians-edit-profile.component';
import { CliniciansChangePasswordComponent } from './components/clinicians-change-password/clinicians-change-password.component';

export const CliniciansProfileRoutes: Route[] = [
  {
    path: '',
    redirectTo: 'active',
    pathMatch: 'full',
  },
  {
    path: 'edit-profile',
    component: CliniciansEditProfilePageComponent,
  },
];

@NgModule({
  declarations: [
    CliniciansProfileComponent,
    CliniciansEditProfilePageComponent,
    CliniciansEditProfileComponent,
    CliniciansChangePasswordComponent,
  ],
  imports: [CommonModule, CliniciansCommonModule, RouterModule, FormsModule, ReactiveFormsModule],
})
export class CliniciansProfileModule {}
