import { Injectable } from '@angular/core';

import { PatientDetailsService, PatientNavigationService } from '@pushdr/clinicians/consultation';
import { DoctorNotesProxy } from '@pushdr/consultation/doctor-notes';

@Injectable({
  providedIn: 'root',
})
export class AppDoctorNotesProxy extends DoctorNotesProxy {
  getCustomerId = () => this.details.getCustomerId$();
  getHomePath = () => this.patientNavigation.homePath$;

  constructor(
    private details: PatientDetailsService,
    private patientNavigation: PatientNavigationService
  ) {
    super();
  }
}
