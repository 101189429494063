import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { PrescriptionInputData } from '@pushdr/prescription/fdb';

type PrescriptionMap = Map<string, PrescriptionInputData>;

@Injectable({
  providedIn: 'root',
})
export class PrescriptionBasketService {
  private basketSubject = new BehaviorSubject<PrescriptionMap>(new Map());

  readonly prescriptions$ = this.basketSubject.pipe(
    map(basketMap => Array.from(basketMap.values()))
  );

  readonly hasPrescriptions$ = this.prescriptions$.pipe(
    map(basketItems => Array.isArray(basketItems) && basketItems.length > 0)
  );

  readonly snomedCodes$ = this.prescriptions$.pipe(
    map(basketItems => basketItems.map(item => item.medicationCode))
  );

  setPrescriptions(items: PrescriptionInputData[]): void {
    const itemsMap = buildPrescriptionMapFromArray(items);
    this.basketSubject.next(itemsMap);
  }

  addPrescription(item: PrescriptionInputData): void {
    const itemsMap = new Map(this.basketSubject.value);
    itemsMap.set(item.medicationCode, item);
    this.basketSubject.next(itemsMap);
  }

  removePrescriptionBySnomedCode(snomedCode: string): boolean {
    const itemsMap = new Map(this.basketSubject.value);
    const hasExisted = itemsMap.delete(snomedCode);
    this.basketSubject.next(itemsMap);
    return hasExisted;
  }

  getPrescriptionBySnomedCode(snomedCode: string): PrescriptionInputData {
    return this.basketSubject.value.get(snomedCode) ?? null;
  }
}

function buildPrescriptionMapFromArray(items: PrescriptionInputData[]): PrescriptionMap {
  return items.reduce(
    (acc, item) => acc.set(item.medicationCode, item),
    new Map<string, PrescriptionInputData>()
  );
}
