<div class="stages-container" data-cy="stages">
  <ng-container *ngFor="let stage of stages">
    <div
      class="tech-check-stage-container u-flex"
      [attr.data-cy]="'stages-' + stage.cyname"
      [ngClass]="{
        'tech-check-status__to-do': stage.index > progress,
        'tech-check-status__in-progress': progress === stage.index && !hasError,
        'tech-check-status__error': progress === stage.index && hasError
      }">
      <img
        class="tech-check-stage__icon"
        [ngClass]="{ show: progress === stage.index && hasError }"
        alt="Error icon"
        [src]="stage.icons.error" />
      <img
        class="tech-check-stage__icon"
        [ngClass]="{
          show: ((progress === stage.index || progress > stage.index) && !hasError) || stage > 2
        }"
        alt="Active Icon"
        [src]="stage.icons.active" />
      <img
        class="tech-check-stage__icon"
        [ngClass]="{ show: stage.index > progress }"
        alt="Pending Stage Icon"
        [src]="stage.icons.pending" />
      <div class="tech-check-stage">
        <span>{{ stage.name }}</span>
        <img
          *ngIf="progress === stage.index && !hasError"
          [attr.data-cy]="'stages-' + stage.cyname + '-doing'"
          alt="Arrow"
          src="assets/icons/back-arrow.png" />
        <img
          *ngIf="progress > stage.index"
          [attr.data-cy]="'stages-' + stage.cyname + '-green'"
          alt="Green check mark"
          src="assets/icons/check_mark_circle_green.svg" />
        <img
          *ngIf="progress === stage.index && hasError"
          [attr.data-cy]="'stages-' + stage.cyname + '-red'"
          alt="Red cross"
          src="assets/icons/cross_circle_red.svg" />
      </div>
    </div>
    <div class="c-divider c-divider_margin-sm"></div>
  </ng-container>
</div>
