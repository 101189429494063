<pushdr-card
  *ngIf="details$ | async as profileDetails"
  [@showProfile]
  [hasData]="profileDetails && !saving"
  [messageOveride]="saving ? 'Saving profile' : 'Loading profile'"
  class="max-w-5xl m-auto">
  <div class="flex flex-col w-full h-full">
    <header class="pt-5 pb-3 mx-5 text-xl border-b border-grey-300 text-bluegrey-800">
      Your profile
    </header>
    <div class="justify-between h-full p-8 lg:flex">
      <pushdr-clinicians-edit-profile
        [profileDetails]="profileDetails"
        [profileImage]="profileImage$ | async"
        (updateProfile)="updateProfile($event)"
        class="lg:w-2/3">
      </pushdr-clinicians-edit-profile>
      <div class="w-px h-full mx-8 sm:hidden lg:block bg-grey-300"></div>
      <pushdr-clinicians-change-password
        (changePassword)="updatePassword($event)"
        class="w-full lg:w-64">
      </pushdr-clinicians-change-password>
    </div>
  </div>
</pushdr-card>
