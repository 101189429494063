import { Directive, Input, HostListener } from '@angular/core';
import { AnalyticsBusService } from '../analytics-bus/analytics-bus.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[trackClick]',
})
export class AnalyticsTrackClickEventDirective {
  @Input('trackClick') targetName: string;
  @Input() trackClickLabel = 'click';

  constructor(private analytics: AnalyticsBusService) {}

  @HostListener('click')
  onClick() {
    const target = this.targetName;
    const action = this.trackClickLabel;
    this.analytics.trackEvent({ action, target });
  }
}
