import { Injectable } from '@angular/core';
import { AnalyticsBusService, AnalyticsEvent } from '@pushdr/common/data-access/analytics';
import { Observable, interval, Subject, of, merge, ReplaySubject } from 'rxjs';
import { map, filter, switchMap, catchError, tap } from 'rxjs/operators';
import {
  ConsultationState,
  ConsultationStateService,
} from '../consultation-state/consultation-state.service';
import { NetworkSpeedService, SpeedResult } from '@pushdr/common/utils';

export enum NetworkQuality {
  GOOD = 'GOOD',
  OK = 'OK',
  POOR = 'POOR',
}

export interface NetworkMonitorResult {
  status: NetworkQuality;
  result: SpeedResult;
}

@Injectable({
  providedIn: 'root',
})
export class NetworkMonitorService {
  private networkQualitySub$ = new ReplaySubject<NetworkMonitorResult>(1);
  private refresh$ = new Subject<void>();
  private speedResult: SpeedResult = { speed: 0, ping: 0 };
  private counter = 0;

  constructor(
    private networkSpeedTest: NetworkSpeedService,
    private msgBus: AnalyticsBusService,
    private consultationState: ConsultationStateService
  ) {}

  get result$(): Observable<NetworkMonitorResult> {
    return this.networkQualitySub$.asObservable();
  }

  runTest() {
    this.counter = 0;
    this.speedResult = { speed: 0, ping: 0 };
    this.refresh$.next();
  }

  initialise() {
    const config = {
      intervalInMins: 5,
      good: 8,
      poor: 4,
      numberOfTests: 5,
    };

    // The interval has been set to 1 minute to stop it erroring when the tab is backgrounded.
    merge(this.refresh$, interval(60000))
      .pipe(
        filter(() => this.counter % config.intervalInMins === 0),
        switchMap(() => this.consultationState.state$),
        filter(consultationState => consultationState === ConsultationState.NOT_CONSULTING),
        switchMap(() =>
          this.networkSpeedTest
            .start(config.numberOfTests)
            .pipe(catchError(() => of(this.speedResult)))
        ),
        map(speedResult => {
          this.speedResult = {
            speed: +speedResult.speed.toFixed(2),
            ping: +speedResult.ping.toFixed(2),
          };

          switch (true) {
            case speedResult.speed > config.good:
              return NetworkQuality.GOOD;
            case speedResult.speed < config.poor:
              return NetworkQuality.POOR;
            default:
              return NetworkQuality.OK;
          }
        }),
        tap(networkQuality =>
          this.msgBus.trackEvent(
            AnalyticsEvent.info(
              'network.connection.quality',
              `Network connection quality: ${networkQuality}`,
              this.speedResult
            )
          )
        ),
        tap(() => this.counter++)
      )
      .subscribe(networkQuality =>
        this.networkQualitySub$.next({ status: networkQuality, result: this.speedResult })
      );

    this.runTest();
  }
}
