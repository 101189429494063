export class SnomedData {
  snomedCode: string;
  snomedName: string;
}

// Maps "common" medication objects to snomed compatible data type
type MedicationCommonType = { medicationName: string; medicationCode: string };
export function mapSnomedData({
  medicationName,
  medicationCode,
}: MedicationCommonType): SnomedData {
  return { snomedName: medicationName, snomedCode: medicationCode };
}
