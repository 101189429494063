import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CliniciansCommonModule } from '@pushdr/clinicians/common';
import { CliniciansHomeComponent } from './clinicians-home.component';
import { BulletinsCardComponent } from './components/bulletins-card/bulletins-card.component';
import { SessionScheduleCardComponent } from './components/session-schedule-card/session-schedule-card.component';
import { WhatsNewCardComponent } from './components/whats-new-card/whats-new-card.component';
import { DashboardComponent } from './routes/dashboard/dashboard.component';
import {
  CliniciansProfileModule,
  CliniciansProfileRoutes,
} from './routes/profile/clinicians-profile.module';
import { CliniciansProfileComponent } from './routes/profile/clinicians-profile.component';
import { HasAuthGuard } from '@pushdr/clinicians/auth';
import { DatePickerModule } from '@pushdr/common/components';
import { FAQCardComponent } from './components/faq-card/faq-card.component';
import { FormsModule } from '@angular/forms';
import {
  CliniciansConsultationHistoryModule,
  CliniciansConsultationHistoryRoutes,
} from './routes/consultation-history/clinicians-consultation-history.module';

export const CliniciansHomeRoutes: Routes = [
  {
    path: 'home',
    component: CliniciansHomeComponent,
    canActivate: [HasAuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'profile',
        component: CliniciansProfileComponent,
        children: CliniciansProfileRoutes,
      },
      {
        path: 'consultationHistory',
        children: CliniciansConsultationHistoryRoutes,
      },
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(CliniciansHomeRoutes),
    CliniciansCommonModule,
    CliniciansProfileModule,
    CliniciansConsultationHistoryModule,
    DatePickerModule,
    FormsModule,
  ],
  declarations: [
    CliniciansHomeComponent,
    DashboardComponent,
    SessionScheduleCardComponent,
    BulletinsCardComponent,
    WhatsNewCardComponent,
    FAQCardComponent,
  ],
})
export class CliniciansHomeModule {}
