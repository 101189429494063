import { Injectable } from '@angular/core';
import { map, merge, Observable, Subject, switchMap } from 'rxjs';

import { VideoParameters } from '@pushdr/common/components';
import { CurrentOrderIdService } from '@pushdr/common/data-access/analytics';
import { ApiDoctorsConsultation } from '@pushdr/doctors/data-access/doctors-api';

export interface VideoSession {
  status: 'connected' | 'ended';
  credentials: VideoParameters;
}

@Injectable({
  providedIn: 'root',
})
export class VideoCredentialsService {
  private stopVideoSubject = new Subject<void>();

  videoSession: Observable<VideoSession> = merge(
    this.order.idChanges.pipe(
      switchMap(orderId => this.consultationApi.getVideoCredentials(orderId)),
      map(credential => mapCredentialsToVideoSession(credential))
    ),
    this.stopVideoSubject.pipe(map(() => ({ status: 'ended', credentials: null } as const)))
  );

  constructor(
    private consultationApi: ApiDoctorsConsultation,
    private order: CurrentOrderIdService
  ) {}

  stopVideoSession(): Observable<boolean> {
    this.stopVideoSubject.next();
    return this.consultationApi.endVideoSession(this.order.id);
  }
}

function mapCredentialsToVideoSession(credentials: VideoParameters): VideoSession {
  const status = credentials.EndVideoSession ? 'ended' : 'connected';
  return { status, credentials };
}
