<ng-container
  *ngIf="{
    allergies: activeAllergies$ | async,
    patientWarning: patientWarning$ | async,
    allergiesWarnings: allergiesWarnings$ | async
  } as data">
  <pushdr-consultation-card-dynamic [hasData]="!!data.allergies">
    <span header>Allergies</span>
    <ng-container body>
      <div class="-m-4">
        <div class="relative flex flex-col">
          <div *ngIf="data.allergies && !selectedAllergy" [@slideInLeft] [@slideOutLeft]>
            <pushdr-patient-warning [warning]="data.patientWarning" *ngIf="data.patientWarning">
            </pushdr-patient-warning>
            <pushdr-allergies-list
              [allergies]="data.allergies"
              [cdsWarnings]="data.allergiesWarnings"
              (viewAllergyDetails)="selectedAllergy = $event">
            </pushdr-allergies-list>
          </div>
          <pushdr-allergy-details
            *ngIf="selectedAllergy"
            class="absolute w-full"
            [allergy]="selectedAllergy"
            (backToListView)="selectedAllergy = null"
            [@slideInRight]
            [@slideOutRight]>
          </pushdr-allergy-details>
        </div>
      </div>
    </ng-container>
  </pushdr-consultation-card-dynamic>
</ng-container>
