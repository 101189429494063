import { Component, ChangeDetectionStrategy } from '@angular/core';
import { map, publishReplay, refCount } from 'rxjs/operators';
import * as _ from 'lodash';

import { ModalService } from '@pushdr/common/overlay';
import { ChatMessage, ChatMessageType } from '@pushdr/common/components';
import { CliniciansImageModalComponent } from '../image-modal/image-modal.component';
import { PatientConsultationService } from '../../services/patient-consultation/patient-consultation.service';

@Component({
  selector: 'pushdr-received-images',
  templateUrl: './received-images.component.html',
  styleUrls: ['./received-images.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientReceivedImagesComponent {
  receivedImages$ = this.consultationService.onChatUpdated$().pipe(
    map(messages => messages?.filter(msg => msg.sender === ChatMessageType.IMAGE) ?? []),
    map(messages => _.reverse(messages)),
    publishReplay(1),
    refCount()
  );

  constructor(
    private consultationService: PatientConsultationService,
    private modal: ModalService
  ) {}

  enlargeImage(base64Image: string, receivedTime: string): void {
    const data = {
      base64Image: base64Image,
      receivedTime: receivedTime,
    };
    const options = { autoFocus: false };
    this.modal.showCustom(CliniciansImageModalComponent, data, void 0, options);
  }

  trackByMessageId(index: number, msg: ChatMessage): string {
    return `${index}-${msg.sender}`;
  }
}
