import { Location } from '@angular/common';
import { Injectable, Inject, OnDestroy } from '@angular/core';
import {
  ExtendedWindow,
  WINDOW,
  ScriptInjectorService,
  StorageService,
} from '@pushdr/common/utils';
import { Subject } from 'rxjs';
import { filter, takeUntil, shareReplay, map, distinctUntilChanged } from 'rxjs/operators';
import { AnalyticsBusService } from '../analytics-bus/analytics-bus.service';
import { AnalyticsIntegrationConfig } from '../analytics-types';

@Injectable({
  providedIn: 'root',
})
export class GTMEventTrackingService implements OnDestroy {
  userid: string;

  private _appName: string;
  private _config: AnalyticsIntegrationConfig;
  private _ngUnsubscribe$ = new Subject<void>();

  constructor(
    @Inject(WINDOW) private window: ExtendedWindow,
    private scriptInject: ScriptInjectorService,
    private bus: AnalyticsBusService,
    private storage: StorageService,
    private location: Location
  ) {}

  initialise(gtmKey: string, config: AnalyticsIntegrationConfig, appName: string) {
    if (gtmKey) {
      this.scriptInject.injectScriptContent(
        'google-tag-manager',
        `
    (function (w, d, s, l, i) {
      w[l] = w[l] || []; w[l].push({
        'gtm.start':
          new Date().getTime(), event: 'gtm.js'
      }); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
          'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', '${gtmKey}');
    `
      );
    }

    this._config = config;
    this._appName = appName || 'default';
    this.subscribeToUser();
    this.subscribeToEvents();
    this.userid = this.storage.get('pdr_uid');
  }

  ngOnDestroy() {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }

  private subscribeToEvents() {
    this.bus.eventBus$
      .pipe(
        filter(event => this._config.events.includes(event.action)),
        takeUntil(this._ngUnsubscribe$)
      )
      .subscribe(event => this.parseEvent(event));
  }

  private parseEvent(event) {
    const category = this.location.path().split('/')[1] || 'default';
    const action = event.action;
    const label = event.target;
    this.logEvent(category, action, label);
  }

  private subscribeToUser() {
    const user$ = this.bus.userBus$.pipe(shareReplay(1));

    user$
      .pipe(
        map(user => user.id as string),
        distinctUntilChanged(),
        takeUntil(this._ngUnsubscribe$)
      )
      .subscribe(userId => {
        if (userId) {
          this.storage.set('pdr_uid', userId);
          this.userid = userId;
        }
      });
  }

  private logEvent(category: string, action: string, label: string) {
    try {
      this.dataLayer.push({
        userId: this.userid,
        event: `${this._appName}_EventTracking`,
        category,
        action,
        label,
      });
    } catch (err) {
      console.error(err);
    }
  }

  get dataLayer() {
    return (
      this.window.dataLayer || {
        push: stuff => {
          //ignore for mock
          console.log('I would of sent to GTM if i had one', stuff);
        },
      }
    );
  }

  // @deprecated
  trackSelectAppointment(strDateTime, index, dayOfWeek, cost = 0) {
    try {
      this.dataLayer.push({
        event: 'addToCart',
        ecommerce: {
          click: {
            actionFields: { list: dayOfWeek },
            products: [
              {
                name: strDateTime,
                id: strDateTime,
                price: cost,
                category: 'Select time',
                position: index + 1,
              },
            ],
          },
          detail: {
            actionFields: { list: dayOfWeek },
            products: [
              {
                name: strDateTime,
                id: strDateTime,
                price: cost,
                category: 'Select time',
                quantity: 1,
              },
            ],
          },
        },
      });
    } catch (err) {
      console.error(err);
    }
  }

  // @deprecated
  trackAppointmentBooking(orderNumber: string, costAtBooking = 0) {
    try {
      this.dataLayer.push({
        event: 'order',
        transactionId: orderNumber,
        transactionTotal: costAtBooking,
        transactionProducts: [
          {
            sku: 1,
            name: 'Appointment Booking',
            price: costAtBooking,
            quantity: 1,
          },
        ],
      });
    } catch (err) {
      console.error(err);
    }
  }

  // @deprecated
  trackRefund(strAppointmentNumber) {
    try {
      this.dataLayer.push({
        event: 'cancelorder',
        ecommerce: {
          refund: {
            actionField: { id: strAppointmentNumber },
          },
        },
      });
    } catch (err) {
      console.error(err);
    }
  }
}
