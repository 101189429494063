import { Injectable } from '@angular/core';
import { AnalyticsBusService, AnalyticsEvent } from '@pushdr/common/data-access/analytics';
import { StorageService } from '@pushdr/common/utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export enum ConsultationState {
  NOT_CONSULTING = '',
  PREVIEW_PATIENT = 'PREVIEWING PATIENT DETAILS',
  MEET_PATIENT = 'MEETING THE PATIENT',
  VERIFYING_IDENTITY = 'VERIFYING THE PATIENTS IDENTIFICATION',
  CHECKING_ALERTS = 'CHECKING ALERTS FOR PATIENT',
  CONSULTING = 'CONSULTING WITH PATIENT',
  EDITING = 'EDITING PREVIOUS CONSULTATION',
}

export interface StateCustomisation {
  closeText: string;
  showVideo: boolean;
  showEmergency: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ConsultationStateService {
  private statusStorageToken = 'consultation_status';
  private consultationState$ = new BehaviorSubject<ConsultationState>(this.getStoredSessionState());

  constructor(private storage: StorageService, private msgBus: AnalyticsBusService) {}

  get state$(): Observable<ConsultationState> {
    return this.consultationState$.asObservable();
  }

  getStateCustomisations$(): Observable<StateCustomisation> {
    return this.state$.pipe(
      map(x => ({
        closeText: x === ConsultationState.EDITING ? 'Save and close' : 'End consultation',
        showVideo: x !== ConsultationState.EDITING,
        showEmergency: x !== ConsultationState.EDITING,
      }))
    );
  }

  set state(nextState: ConsultationState) {
    this.msgBus.trackEvent(
      AnalyticsEvent.info('consultation.state.set-state', 'Set-state: ' + JSON.stringify(nextState))
    );
    this.storeSessionState(nextState);
    this.consultationState$.next(nextState);
  }

  get state() {
    return this.consultationState$.value;
  }

  clearConsultationState() {
    this.msgBus.trackEvent(AnalyticsEvent.info('consultation.state.clear-state', 'State cleared'));
    this.state = ConsultationState.NOT_CONSULTING;
  }

  private getStoredSessionState() {
    return (
      this.storage.getSessionStorage(this.statusStorageToken, true) ||
      ConsultationState.NOT_CONSULTING
    );
  }

  private storeSessionState(nextState: ConsultationState) {
    this.storage.setSessionStorage(this.statusStorageToken, nextState);
  }
}
