import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { PatientDetailsService } from '../../../../../services/patient-details/patient-details.service';
import { PatientNavigationService } from '../../../services/patient-navigation/patient-navigation.service';

@Component({
  selector: 'pushdr-safeguarding-steps',
  templateUrl: './safeguarding-steps.component.html',
  styleUrls: ['./safeguarding-steps.component.scss'],
})
export class SafeguardingStepsComponent implements OnInit {
  @ViewChild('referToDocs', { static: true }) referToDocs: TemplateRef<unknown>;
  @ViewChild('locateRefMeth', { static: true }) locateRefMeth: TemplateRef<unknown>;
  @ViewChild('addPatientsPractice', { static: true }) addPatientsPractice: TemplateRef<unknown>;
  @ViewChild('sendAccount', { static: true }) sendAccount: TemplateRef<unknown>;

  patientDetails$ = this.patient.details$;
  sectionsToShow: TemplateRef<unknown>[];

  constructor(
    private patient: PatientDetailsService,
    private patientNav: PatientNavigationService
  ) {}

  ngOnInit() {
    this.sectionsToShow = [
      this.referToDocs,
      this.locateRefMeth,
      this.addPatientsPractice,
      this.sendAccount,
    ];
  }

  onClickBackToConsultation() {
    this.patientNav.gotoConsultationHome();
  }

  onGotoPatientEmergency() {
    this.patientNav.gotoPatientEmergency();
  }
}
