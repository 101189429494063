<div *ngIf="hasNotes; else noNotes" class="text-grey-500">
  <button
    class="py-1 pl-2 pr-1 flex items-center bg-indigo-100 rounded hover:text-indigo-500 break-words"
    (click)="pdrEdit.emit()"
    [attr.tooltip-bottom]="notes">
    <span class="mt-1">Patient note</span>
    <i class="ml-2 material-icons icon text-xl">edit</i>
  </button>
</div>
<ng-template #noNotes>
  <button
    class="flex items-center h-8 text-grey-500 hover:text-indigo-500"
    (click)="pdrEdit.emit()">
    <i class="material-icons icon text-base">add</i>
    <span class="mt-1 ml-1/2">Patient note</span>
  </button>
</ng-template>
