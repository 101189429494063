import { Component, OnInit } from '@angular/core';
import { AbstractModal } from '@pushdr/common/overlay';
import { Observable, of, combineLatest, timer } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ApiDoctorsPatientService } from '@pushdr/doctors/data-access/doctors-api';
import { map, catchError, tap, startWith, refCount, publishReplay } from 'rxjs/operators';
import { CurrentOrderIdService } from '@pushdr/common/data-access/analytics';

@Component({
  selector: 'pushdr-notes-pdf-view-modal',
  templateUrl: './notes-pdf-view-modal.component.html',
  styleUrls: ['./notes-pdf-view-modal.component.scss'],
})
export class NotesPdfViewModalComponent extends AbstractModal<any> implements OnInit {
  pdfReport$: Observable<SafeResourceUrl>;
  loadingMessage$: Observable<string>;

  constructor(
    private patientApi: ApiDoctorsPatientService,
    private sanitizer: DomSanitizer,
    private order: CurrentOrderIdService
  ) {
    super();
  }

  ngOnInit() {
    this.pdfReport$ = this.patientApi.getConsultationReportPreview(this.order.id).pipe(
      map(res =>
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'data:application/pdf;base64,' + res.pdfBase64
        )
      ),
      publishReplay(1),
      refCount()
    );

    this.loadingMessage$ = combineLatest([
      this.pdfReport$.pipe(startWith({})),
      of('Getting consultation notes preview...'),
    ]).pipe(
      map(([report, message]) => message),
      catchError(() =>
        of('There was an issue previewing the notes.').pipe(
          tap(() => timer(5000).subscribe(() => this.close()))
        )
      )
    );
  }
}
