import { Component, OnInit } from '@angular/core';
import { AbstractModal } from '@pushdr/common/overlay';

@Component({
  selector: 'pushdr-edit-consultation-warning',
  templateUrl: './loading-modal.component.html',
  styleUrls: ['./loading-modal.component.scss'],
})
export class LoadingModalComponent
  extends AbstractModal<{ errorType: string }, boolean>
  implements OnInit
{
  title = 'NHS Smart Card';
  errorType: string;
  message: string;

  ngOnInit() {
    this.errorType = this.data.errorType;
    this.closer.subscribe(x => {
      if (x) this.close();
    });
  }
}
