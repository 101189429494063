<div
  class="border border-solid rounded border-bluegrey-400 flex p-4 text-base text-grey-700"
  *ngIf="consultation">
  <div class="ml-2">
    <div>Patient name</div>
    <div class="mt-1 font-medium text-indigo-700">
      {{ consultation.patientName }} {{ consultation.patientSurname }}
    </div>
  </div>
  <div class="ml-10 mr-10">
    <div>Date of birth</div>
    <div class="mt-1 font-medium text-indigo-700">
      {{ consultation.patientDateOfBirth | date: 'mediumDate' }}
    </div>
  </div>
  <div class="mr-2">
    <div>NHS number</div>
    <div class="mt-1 font-medium text-indigo-700">{{ consultation.nhsNumber }}</div>
  </div>
</div>
