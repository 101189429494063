import { Injectable, Inject } from '@angular/core';
import { BrowserDetectorService, BrowserName } from './browser-detector.service';
import { ExtendedWindow, WINDOW } from '../../utils/window/window.service';

@Injectable({
  providedIn: 'root',
})
export class SystemCheckService {
  userAgent = '';
  // OS Details
  isWindows = false;
  isMac = false;
  isLinux = false;
  OS = 'Unknown';

  // Device Details
  isAndroid = false;
  isiOS = false;
  androidDesktopMode = false;
  iosDesktopMode = false;
  isGoogle = false;
  isMobileDevice = false;

  // Browser
  browser = BrowserName.Unknown;
  isSafari = false;
  isChrome = false;

  // Device Compatibility
  isBrowserCompatible = true;
  isCompatible = false;
  isDeviceCompatible = true;

  // RegExp
  private iOSRegExp = new RegExp(/iPad|iPhone|iPod/);
  private mobileRegExp = new RegExp(/(iPhone|iPod|iPad|Android|BlackBerry|IEMobile)/);
  private webKitRegExp = new RegExp(/WebKit\/([0-9]+)|$/);

  constructor(
    private browserDetector: BrowserDetectorService,
    @Inject(WINDOW) private window: ExtendedWindow
  ) {}

  initialise(context: ExtendedWindow = this.window) {
    this.setOS(context.navigator.appVersion);
    this.setDeviceDetails(context);
    this.setBrowserCompatibility(context);
    this.setDeviceCompatibility();
  }

  private setDeviceDetails(context: ExtendedWindow) {
    const userAgent = context.navigator.userAgent;
    const platform = context.navigator.platform;
    const webkitVer = this.getWebKitVersion(context.navigator.appVersion);

    this.userAgent = userAgent;
    this.isGoogle = !!webkitVer && context.navigator.vendor.indexOf('Google') === 0; // Also true for Opera Mobile and maybe others
    this.isMobileDevice = !!userAgent.match(this.mobileRegExp);
    this.isAndroid = this.isGoogle && userAgent.toLowerCase().indexOf('android') > -1;
    this.androidDesktopMode =
      !this.isAndroid &&
      this.isGoogle &&
      platform.indexOf('Linux ') === 0 &&
      this.browserDetector.isChrome(context) &&
      'ontouchstart' in context.document.documentElement;
    this.isiOS = !!platform && this.iOSRegExp.test(platform);
    this.iosDesktopMode = !!(!userAgent.match(this.iOSRegExp) && this.isiOS);
  }

  private setOS(appVersion: string) {
    this.isWindows = appVersion.indexOf('Win') !== -1;
    this.isMac = appVersion.indexOf('Mac') !== -1;
    this.isLinux = appVersion.indexOf('Linux') !== -1;

    if (this.isWindows) {
      this.OS = 'Windows';
    } else if (this.isMac) {
      this.OS = 'Mac';
    } else if (this.isLinux) {
      this.OS = 'Linux';
    }
  }

  get isCypress() {
    return this.browser === BrowserName.Cypress;
  }

  private setDeviceCompatibility() {
    this.isDeviceCompatible = !!(
      !this.isMobileDevice &&
      !this.androidDesktopMode &&
      !this.iosDesktopMode &&
      (this.isWindows || this.isMac || this.isLinux)
    );

    this.isCompatible =
      !!(
        this.isDeviceCompatible &&
        !this.androidDesktopMode &&
        !this.iosDesktopMode &&
        this.isBrowserCompatible
      ) || this.isCypress;
  }

  private setBrowserCompatibility(context: ExtendedWindow) {
    this.browser = this.browserDetector.detectBrowser(context);
    if (
      this.browser === BrowserName.IE ||
      this.browser === BrowserName.Edge ||
      this.browser === BrowserName.Unknown
    ) {
      this.isBrowserCompatible = false;
    }
    if (this.browser === BrowserName.Safari) {
      this.isSafari = true;
    }
    if (this.browser === BrowserName.Chrome) {
      this.isChrome = true;
    }
  }

  private getWebKitVersion(appVersion: string) {
    return parseInt(this.webKitRegExp.exec(appVersion)[1], 10); // also matches AppleWebKit
  }
}
