import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { CliniciansAuthService } from '@pushdr/clinicians/common';
import { DoctorInfoService } from '@pushdr/common/utils';

@Injectable({
  providedIn: 'root',
})
export class HasAuthGuard implements CanActivate {
  constructor(
    private auth: CliniciansAuthService,
    private doctorInfo: DoctorInfoService,
    private router: Router
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.auth.hasAuth()) {
      if (!this.doctorInfo.get()?.LastPasswordUpdate || this.doctorInfo.getPasswordResetNeeded()) {
        this.router.navigate(['/auth/update-password']);
      } else return true;
    } else {
      return this.router.createUrlTree(['/auth/login']);
    }
  }
}
