import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import { DoctorClaim } from '@pushdr/common/types';

@Injectable({ providedIn: 'root' })
export class DoctorInfoService {
  private key = 'DoctorClaim';
  private keyPasswordResetNeeded = 'PasswordResetNeeded';

  constructor(private store: StorageService) {}

  exists() {
    return !!this.store.get(this.key);
  }

  set(data: DoctorClaim) {
    this.store.setLocalStorage(this.key, data);
  }

  setPasswordResetNeeded(data: boolean) {
    this.store.setLocalStorage(this.keyPasswordResetNeeded, data);
  }

  delete() {
    this.store.deleteLocalStorage(this.key);
    this.store.deleteLocalStorage(this.keyPasswordResetNeeded);
  }

  get(): DoctorClaim {
    return this.store.getLocalStorage(this.key, true);
  }

  getPasswordResetNeeded(): boolean {
    return this.store.getLocalStorage(this.keyPasswordResetNeeded, true);
  }
}
