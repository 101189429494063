<pushdr-tab-group (activateTab)="warningViewed($event)" [noCheck]="hideCheck">
  <ng-container *ngFor="let warning of warnings.warningsArr">
    <div
      class="w-full"
      [attr.pdTabView]="warning.name + ' (' + warning.data.length + ')'"
      [attr.pdTabId]="warning.name">
      <ng-container
        *ngIf="warning.name !== 'Patient Conditions' && warning.name !== 'Drug Interactions'">
        <p class="warning-sub-item" *ngFor="let info of warning.data">
          {{ info.text }}
        </p>
      </ng-container>
      <ng-container *ngIf="warning.name === 'Patient Conditions'">
        <div *ngFor="let patientCheck of warnings.patientChecksArr" class="warning-item">
          <p class="text-base text-red-500">
            {{ patientCheck[0] }}
          </p>
          <p
            *ngFor="let info of patientCheck[1]"
            class="warning-sub-item"
            [class.font-bold]="info.highlightWarning">
            {{ info.text }}
          </p>
        </div>
      </ng-container>
      <ng-container *ngIf="warning.name === 'Drug Interactions'">
        <div *ngFor="let drugInteraction of warnings.drugInteractionsArr" class="warning-item">
          <p class="text-base text-red-500">
            {{ drugInteraction[0] }}
          </p>
          <p class="warning-sub-item" *ngFor="let info of drugInteraction[1]">
            {{ info.text }}
          </p>
        </div>
      </ng-container>
    </div>
  </ng-container>
</pushdr-tab-group>
