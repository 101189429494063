import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EncryptedUser } from '@pushdr/common/types';
import { TokenService } from '@pushdr/common/utils';
import { v4 as uuid } from 'uuid';

@Injectable({ providedIn: 'root' })
export class RestHttpHeaderService {
  user: EncryptedUser = null;

  constructor(private token: TokenService) {}

  unauthorisedHeaders(): HttpHeaders {
    // override in subclass
    return new HttpHeaders({
      accept: 'application/json',
      'content-type': 'application/json',
      Source: 'Web',
    });
  }

  hasEncryptedUser() {
    return !!this.user && !!this.user.CustomerKey && !!this.user.Email;
  }

  encryptedUserHeaders() {
    const key = this.user ? this.user.CustomerKey : 'no-key';
    const username = this.user ? this.user.Email : 'no-email';
    return new HttpHeaders({
      accept: 'application/json',
      'content-type': 'application/json',
      key,
      Source: 'Web',
      username,
    });
  }

  hasBearerToken(): boolean {
    return this.token.get();
  }

  bearerTokenHeaders(token = ''): HttpHeaders {
    const bearerToken = token || this.token.get();
    return new HttpHeaders({
      accept: 'application/json',
      'content-type': 'application/json',
      Source: 'Web',
      Authorization: `bearer ${bearerToken}`,
    });
  }

  bearerTokenHeadersFormData(token = ''): HttpHeaders {
    const bearerToken = token || this.token.get();
    return new HttpHeaders({
      Authorization: `bearer ${bearerToken}`,
    });
  }

  hasHeaders() {
    return !!this.hasBearerToken() || !!this.hasEncryptedUser();
  }

  headers(token = '') {
    return this.hasBearerToken()
      ? this.bearerTokenHeaders(token)
      : this.hasEncryptedUser()
      ? this.encryptedUserHeaders()
      : this.noHeadersWhenExpected();
  }

  noHeadersWhenExpected() {
    return null;
  }

  emptyHeaders(): HttpHeaders {
    return new HttpHeaders();
  }

  get authorisedHeadersLegacy(): HttpHeaders {
    return new HttpHeaders(this.legacyHeaders());
  }

  get legacyHeadersWithCorrelationId(): HttpHeaders {
    return new HttpHeaders({ ...this.legacyHeaders(), 'pdr-correlation-id': uuid() });
  }

  private legacyHeaders() {
    return {
      accept: 'application/json',
      'content-type': 'application/json',
      token: this.token.get(),
    };
  }
}
