import { Injectable } from '@angular/core';
import { ExtendedWindow } from '../../utils/window/window.service';

export enum BrowserName {
  Opera = 'Opera',
  Edge = 'Edge',
  IE = 'IE',
  Chrome = 'Chrome',
  Safari = 'Safari',
  Firefox = 'Firefox',
  Cypress = 'Cypress',
  Unknown = 'Unknown',
}

// https://stackoverflow.com/a/9851769
// Use feature detection as more consistant than useragent

// IIFE to avoid minification of comments
// Works for IE10 and below
const IEDetector = (function () {
  'use strict';

  let isTheBrowser, actualVersion;

  isTheBrowser = false;
  const jscriptMap = {
    '5.5': '5.5',
    '5.6': '6',
    '5.7': '7',
    '5.8': '8',
    '9': '9',
    '10': '10',
  };
  const jscriptVersion = new Function('/*@cc_on return @_jscript_version; @*/')();

  if (jscriptVersion !== undefined) {
    isTheBrowser = true;
    actualVersion = jscriptMap[jscriptVersion];
  }

  const ret = {
    isTheBrowser: isTheBrowser,
    actualVersion: actualVersion,
  };

  return ret;
})();

@Injectable({
  providedIn: 'root',
})
export class BrowserDetectorService {
  detectBrowser(context: Partial<ExtendedWindow> = window): BrowserName {
    let browser = this.featureDetector(context);
    if (browser === BrowserName.Unknown) {
      browser = this.fallBackDetection(context.navigator.userAgent);
    }
    return browser;
  }

  // ? Some issues with fallback as some versions of IE & Edge can be confused with Chrome.
  // ! Order of these matters
  // public for easier testing.
  fallBackDetection(userAgent: string): BrowserName {
    if ((userAgent.indexOf('Opera') !== -1 || userAgent.indexOf('OPR/')) !== -1) {
      return BrowserName.Opera;
    } else if (userAgent.indexOf('Edg') !== -1) {
      return BrowserName.Edge;
    } else if (userAgent.indexOf('MSIE') !== -1 || userAgent.indexOf('Trident') !== -1) {
      return BrowserName.IE;
    } else if (userAgent.indexOf('Chrome') !== -1) {
      return BrowserName.Chrome;
    } else if (userAgent.indexOf('Safari') !== -1) {
      return BrowserName.Safari;
    } else if (userAgent.indexOf('Firefox') !== -1) {
      return BrowserName.Firefox;
    } else {
      return BrowserName.Unknown;
    }
  }

  isChrome(context): boolean {
    return (
      !!context['chrome'] &&
      (!!context['chrome'].webstore || !!context['chrome'].csi || !!context['chrome'].runtime) &&
      !/Edg/.test(navigator.userAgent)
    );
  }

  private featureDetector(context): BrowserName {
    if (this.isCypress(context)) {
      return BrowserName.Cypress;
    } else if (this.isOpera(context)) {
      return BrowserName.Opera;
    } else if (this.isIE(context)) {
      return BrowserName.IE;
    } else if (this.isFirefox(context)) {
      return BrowserName.Firefox;
    } else if (this.isSafari(context)) {
      return BrowserName.Safari;
    } else if (this.isChrome(context)) {
      return BrowserName.Chrome;
    } else if (this.isEdge(context)) {
      return BrowserName.Edge;
    } else {
      return BrowserName.Unknown;
    }
  }

  private isOpera(context): boolean {
    return (
      (!!context['opr'] && !!context['opr'].addons) ||
      !!context['opera'] ||
      context.navigator.userAgent.indexOf(' OPR/') >= 0
    );
  }

  private isCypress(context): boolean {
    return context.navigator.userAgent.toLowerCase().indexOf('cypress/') > -1;
  }

  private isFirefox(context): boolean {
    return typeof context['InstallTrigger'] !== 'undefined';
  }

  private isSafari(context): boolean {
    return (
      /constructor/i.test(context['HTMLElement']) ||
      ((p): boolean => {
        return p.toString() === '[object SafariRemoteNotification]';
      })(!context['safari'] || context['safari'].pushNotification)
    );
  }

  // TODO: find a way to stop webpack removing comment.
  private isIE(context): boolean {
    return IEDetector.isTheBrowser || !!context.document['documentMode'];
  }

  private isEdge(context): boolean {
    return (!this.isIE(context) && !context['StyleMedia']) || /Edg/.test(navigator.userAgent);
  }

  isIE10(): boolean {
    return IEDetector.isTheBrowser && IEDetector.actualVersion === '10';
  }
}
