import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pushdr-allergy-details',
  templateUrl: './allergy-details.component.html',
  styleUrls: ['./allergy-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AllergyDetailsComponent {
  @Input() allergy: any;
  @Output() backToListView: EventEmitter<any> = new EventEmitter();
}
