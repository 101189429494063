import { Component, OnInit } from '@angular/core';
import { PatientDetailsService } from '../../../../../services/patient-details/patient-details.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { merge, Observable, of, Subject, timer } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PatientNavigationService } from '../../../services/patient-navigation/patient-navigation.service';
import {
  ApiDoctorsConsultationDocumentsService,
  DoctorDetails,
  FitNote,
} from '@pushdr/doctors/data-access/doctors-api';
import {
  distinctUntilChanged,
  tap,
  switchMap,
  take,
  map,
  debounceTime,
  startWith,
  catchError,
  filter,
} from 'rxjs/operators';
import { CliniciansDoctorsDetailsService } from '@pushdr/clinicians/common';
import * as moment from 'moment';
import { PatientDetailsV2 } from '@pushdr/common/types';
import { AnalyticsBusService, AnalyticsEvent } from '@pushdr/common/data-access/analytics';
import { ModalService } from '@pushdr/common/overlay';

export enum Stage {
  READY = 'Ready',
  LOADING = 'Loading',
  SENDING = 'Sending',
  SENT = 'Sent',
}

@UntilDestroy()
@Component({
  selector: 'pushdr-consultation-fit-note',
  templateUrl: './consultation-fit-note.component.html',
  styleUrls: ['./consultation-fit-note.component.scss'],
})
export class ConsultationFitNoteComponent implements OnInit {
  patientDetails$: Observable<PatientDetailsV2>;
  doctorDetails$: Observable<DoctorDetails>;
  fitNoteReceived$: Observable<FitNote>;

  private saveTrigger$ = new Subject<void>();
  private fitNoteCreated$ = new Subject<FitNote>();

  private changesToSave = false;

  fitNoteForm: UntypedFormGroup;
  errorMessage = '';
  Stage = Stage;
  currentStage = Stage.LOADING;
  destroy$ = new Subject<null>();
  todaysDate = moment();
  submitted = false;
  surgeryId: string;
  emailCorrect: boolean | null = null;
  fitNoteId: string;
  fitNote: FitNote;
  fitNoteExists = false;
  updating = false;
  errorUpdating = false;

  fitForWorkOptions = [
    { description: 'patient is NOT fit for work', value: false },
    {
      description: 'patient MAY be fit for work taking account of the following advice',
      value: true,
    },
  ];

  constructor(
    private patientDetailsService: PatientDetailsService,
    private fb: UntypedFormBuilder,
    private patientNav: PatientNavigationService,
    private doctorDetailsService: CliniciansDoctorsDetailsService,
    private api: ApiDoctorsConsultationDocumentsService,
    private msgBus: AnalyticsBusService,
    private modal: ModalService
  ) {}

  ngOnInit() {
    this.fitNoteForm = this.fb.group({
      Condition: [null, [Validators.required]],
      FitForWork: [false, [Validators.required]],
      PhasedReturn: [false, []],
      AlteredHours: [false, []],
      AmendedDuties: [false, []],
      WorkplaceAdaptation: [false, []],
      TimeOffDays: [null, [Validators.required, Validators.min(1), Validators.max(1000)]],
      Comments: [null, [Validators.required]],
      signed: [false, [Validators.requiredTrue]],
      emailConfirmed: [false, [Validators.requiredTrue]],
    });

    this.patientDetails$ = this.patientDetailsService.details$.pipe(
      tap(details => (this.surgeryId = details.Surgery.Id))
    );
    this.doctorDetails$ = this.doctorDetailsService.details$;
    this.fitNoteReceived$ = this.api.getFitNote().pipe(catchError(() => of(null)));

    merge(this.fitNoteReceived$, this.fitNoteCreated$)
      .pipe(
        filter(fitNote => !!fitNote),
        tap(fitNote => (this.fitNoteExists = !!fitNote)),
        map(fitNote => (this.fitNote = fitNote)),
        tap(fitNote => {
          this.fitNoteForm.patchValue(this.fitNoteToForm(fitNote), { emitEvent: false });
          this.msgBus.trackEvent(
            AnalyticsEvent.info(`consultation-fit-note.started`, 'Fit note started')
          );
          this.currentStage = Stage.READY;
        }),
        switchMap(() =>
          this.updateTriggers$().pipe(
            tap(() => this.updateFitNote$().pipe(untilDestroyed(this)).subscribe())
          )
        ),
        untilDestroyed(this)
      )
      .subscribe(() => (this.changesToSave = false));
  }

  createFitNote(isEmailCorrect: boolean) {
    if (!isEmailCorrect) return;
    const initialFitNote = {
      SurgeryId: this.surgeryId,
      Condition: '',
      Comments: '',
      TimeOffDays: 0,
      FitForWork: false,
      WorkAdvice: {
        PhasedReturn: false,
        AmendedDuties: false,
        AlteredHours: false,
        WorkplaceAdaptation: false,
      },
    };
    this.api
      .createFitNote(initialFitNote)
      .pipe(
        untilDestroyed(this),
        tap(id => this.fitNoteCreated$.next({ ...initialFitNote, FitNoteId: id }))
      )
      .subscribe();
  }

  sendFitNote() {
    this.submitted = true;
    if (this.currentStage === Stage.SENDING) return;
    if (!this.fitNoteForm.valid) return;
    this.errorMessage = '';
    this.currentStage = Stage.SENDING;

    this.api
      .sendFitNoteToPatient(this.fitNote.FitNoteId)
      .pipe(
        tap(() =>
          this.msgBus.trackEvent(
            AnalyticsEvent.info(
              `consultation-fit-note.send`,
              'Send fit note id: ' + this.fitNote.FitNoteId
            )
          )
        ),
        take(1)
      )
      .subscribe({
        next: () => {
          this.fitNoteForm.reset();
          this.currentStage = Stage.SENT;
        },
        error: err => {
          this.errorMessage = err.message || 'Failed to send fit note, please try again.';
          this.currentStage = Stage.READY;
        },
      });
  }

  onClickBackToConsultation() {
    this.patientNav.gotoConsultationHome();
  }

  updateFitNote$() {
    if (!this.fitNote || !this.changesToSave) return of(null);
    this.updating = true;
    this.fitNote = {
      Condition: this.fitNoteForm.value.Condition ?? this.fitNote.Condition,
      Comments: this.fitNoteForm.value.Comments ?? this.fitNote.Comments,
      TimeOffDays: this.fitNoteForm.value.TimeOffDays ?? this.fitNote.TimeOffDays,
      SurgeryId: this.surgeryId,
      FitForWork: this.fitNoteForm.value.FitForWork ?? this.fitNote.FitForWork,
      WorkAdvice: {
        PhasedReturn: this.fitNoteForm.value.PhasedReturn ?? this.fitNote.WorkAdvice?.PhasedReturn,
        AmendedDuties:
          this.fitNoteForm.value.AmendedDuties ?? this.fitNote.WorkAdvice?.AmendedDuties,
        AlteredHours: this.fitNoteForm.value.AlteredHours ?? this.fitNote.WorkAdvice?.AlteredHours,
        WorkplaceAdaptation:
          this.fitNoteForm.value.WorkplaceAdaptation ??
          this.fitNote.WorkAdvice?.WorkplaceAdaptation,
      },
      FitNoteId: this.fitNote.FitNoteId,
    };
    return this.api.updateFitNote(this.fitNote).pipe(
      map(() => {
        this.updating = false;
        this.errorUpdating = false;
      }),
      catchError(() => {
        this.updating = false;
        this.errorUpdating = true;
        this.modal.error(
          'There has been an issue saving this patients fit note, please contact support if this problem persists'
        );
        return of(null);
      })
    );
  }

  manualSave() {
    this.fitNoteForm.markAsDirty();
    this.saveTrigger$.next();
  }

  fitNoteToForm(fitNote: FitNote) {
    return {
      Condition: fitNote.Condition,
      FitForWork: fitNote.FitForWork,
      PhasedReturn: fitNote.WorkAdvice?.PhasedReturn,
      AlteredHours: fitNote.WorkAdvice?.AlteredHours,
      AmendedDuties: fitNote.WorkAdvice?.AmendedDuties,
      WorkplaceAdaptation: fitNote.WorkAdvice?.WorkplaceAdaptation,
      TimeOffDays: fitNote.TimeOffDays,
      Comments: fitNote.Comments,
    };
  }

  private updateTriggers$() {
    return merge(
      this.fitNoteForm.valueChanges.pipe(
        distinctUntilChanged(),
        tap(() => (this.changesToSave = true)),
        debounceTime(1000)
      ),
      this.saveTrigger$.pipe(startWith(true)),
      timer(100, 5000)
    );
  }
}
