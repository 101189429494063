import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { StorageService } from '@pushdr/common/utils';

@Injectable({
  providedIn: 'root',
})
export class TechChecksPassedGuard implements CanActivate {
  constructor(private router: Router, private storage: StorageService) {}

  canActivate() {
    if (this.storage.get('Tech_Checked')) {
      this.router.navigate(['/consultation', 'waiting-room'], { replaceUrl: true });
      return false;
    }
    return true;
  }
}
