import { AddressLinesTownPC } from '../common';
import { Gender, NhsGender } from '../nhs';

export interface NHSAddress {
  postcode?: string;
  postalCode?: string;
  addressLines?: string[];
  line?: string[];
  city?: string;
  text?: string;
  type?: string;
  use?: string;
}

export enum ClinicianType {
  Unknown = 0,
  GeneralPractitioner = 1,
  MinorAilmentsPharmacist = 2,
  Physio = 3,
  MentalHealthTherapist = 4,
}

export enum HealthRecordShareStatus {
  DO_NOT_SHARE = 0,
  SHARE = 1,
}

interface Partner {
  EpsEnabled?: boolean;
  Id: string;
  Name: string;
  PartnerType: number;
  ClinicianType: ClinicianType;
}

interface GpSummary {
  Address: AddressLinesTownPC;
  Id: number;
  Name: string;
  OdsCode: string;
  SurgeryTypeId: number;
  SurgeryCode?: string;
}
interface NhsInformation {
  ErrorResponse?: {
    Code: string;
    Message: string;
    Tag: string;
  };
  HttpStatusCode: number;
  SuccessResponse?: {
    Title: string;
    NhsNumber: string;
    GivenNames: string[];
    FamilyName: string;
    Telephone: string;
    DateOfBirth: string;
    DateOfDeath: string;
    Gender: NhsGender;
    OrganisationDataServiceCode: string;
    SensitivePatient: boolean;

    PatientAddress: NhsAddress;
    PracticeAddress: NhsAddress;
    PracticeName: string;
  };
}
export interface NhsAddress {
  AddressLines: string[];
  Postcode: string;
  Use: string;
}

enum IdentityType {
  Passport = 0,
  DrivingLicence = 1,
  MilitaryIDCard = 2,
  PoliceWarrantCard = 3,
  UKDisabledDriverCard = 4,
  OtherPhotoID = 5,
  None = 6,
  BirthCertificate = 7,
  VerificationQuestions = 8,
}

interface ChildVerification {
  Id: number;
  FirstName: string;
  LastName: string;
  DateOfBirth: string;
  Gender: Gender;
  IdentificationTypeId: IdentityType;
}

interface Patient {
  Age: number;
  DateOfBirth: string;
  Email: string;
  FirstName: string;
  Gender: Gender;
  GenderLabel: string;
  GpShareStatus: number;
  HealthRecordShareStatus: HealthRecordShareStatus;
  GpSummary?: GpSummary;
  NhsNumber?: string;
  Id: number;
  LastName: string;
  NhsInformation: NhsInformation;
  Phone: string;
  Name: string; // Not sure if this is ever set
  Address: AddressLinesTownPC;
  CustomerType: CustomerType;
  ChildVerification?: ChildVerification;
}

export enum CustomerType {
  Adult = 1,
  Child = 2,
}

export enum TranslatorDialState {
  CONNECTING = 'connecting',
  ANSWERED = 'answered',
  COMPLETED = 'completed',
  FAILED = 'failed',
  BUSY = 'busy',
  UNANSWERED = 'unanswered',
  RINGING = 'ringing',
  TIMEOUT = 'timeout',
  DISCONNECTED = 'disconnected',
  CANCELLED = 'cancelled',
  REJECTED = 'rejected',
  CALL_NOT_STARTED = 'call_not_started',
}

interface Surgery {
  Id: string;
  OrderId: number;
}

interface TranslatorCallStatus {
  Status: TranslatorDialState;
}

interface ConsultationConfigItem {
  IsEnabled: boolean;
  AccessCode?: number;
}
interface ConsultationConfig {
  ChildConsultation: ConsultationConfigItem;
  TranslatorService: ConsultationConfigItem;
  TranslatorCallStatus?: TranslatorCallStatus;
}

export interface PatientDetailsV2 {
  Partner: Partner;
  Patient: Patient;
  Customer: Patient;
  Surgery: Surgery;
  SurgeryLineId: number;
  ConsultationConfig: ConsultationConfig;
  IsCallRecordingEnabled: boolean;
}
