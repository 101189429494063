import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CardReaderService } from '@pushdr/clinicians/common';
import {
  RestClient,
  RestErrorParserService,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';
import { ModalService } from '@pushdr/common/overlay';
import { cache } from '@pushdr/common/utils';
import { EnvironmentProxyService } from '@pushdr/environment';
import { RbacPerms } from '@pushdr/doctors/data-access/doctors-api';
import { sortBy, uniqBy } from 'lodash';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, map, startWith, switchMap, tap } from 'rxjs/operators';
import { Consultation, ConsultationPrescriber } from '../types/Consultation';
import {
  PrescriptionCancelReason,
  PrescriptionErrorStatus,
  PrescriptionStatus,
} from '../types/Prescription';
import { PrescriptionResponseItem } from '../types/PrescriptionResponseItem';

export interface PrescriptionsMappedData {
  prescribers: ConsultationPrescriber[];
  consultations: Consultation[];
  hasError: boolean;
  isLoading: boolean;
}

export interface CancelReason {
  Code: PrescriptionCancelReason;
  Text: string;
}

export interface Canceler {
  cancelersName: string;
  cancelersGmcNumber: string;
  cancelersUniqueIdentifier: string;
  cancelersTelephoneNumber: string;
  roleProfileId: string;
  roleBasedAccessCode: string;
}

export interface PrescriptionCancelRequest {
  medications: string[];
  cancellationReason: CancelReason;
  accompanyingDescription?: string;
  newPrescriptionRequired: boolean;
  canceler: Canceler;
}

const DEFAULT_VALUES: PrescriptionsMappedData = {
  prescribers: [],
  consultations: [],
  hasError: false,
  isLoading: true,
};

@Injectable({
  providedIn: 'root',
})
export class PrescriptionsManagementService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService,
    private cardReader: CardReaderService,
    private modalService: ModalService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  static hasErrorInPrescriptions(
    consultation: Consultation,
    prescriptionStatus: PrescriptionStatus
  ): boolean {
    return (
      consultation.hasErrorInPrescriptions ||
      (prescriptionStatus as unknown as string) in PrescriptionErrorStatus
    );
  }

  endpoint() {
    return this.proxy.environment.doctors.prescriptionAPI;
  }

  canCancel$ = () => this.cardReader.checkPermission$(RbacPerms.prescriptionCancel).pipe(cache());

  canView$ = () => this.cardReader.checkPermission$(RbacPerms.prescriptionView).pipe(cache());

  canAccess$ = () =>
    this.cardReader.checkPermission$(RbacPerms.prescriptionView, true, null, false).pipe(cache());

  private mapPrescriptionsData(response: PrescriptionResponseItem[]) {
    const result: PrescriptionsMappedData = { ...DEFAULT_VALUES };
    const prescribers: Record<string, ConsultationPrescriber> = {};
    const consultations = [];

    response = uniqBy(response, 'medicationId');

    // PJ: My name is on this but I didn't do it.  I just renamed the service
    response.forEach(resItem => {
      const prescriber: ConsultationPrescriber = {
        id: resItem.prescriberId,
        name: resItem.prescriberName,
      };
      const {
        patientName,
        patientSurname,
        patientPhoneNumber,
        patientId,
        nhsNumber,
        patientDateOfBirth,
        prescriberId,
        prescriberName,
        dispenserOds,
        dispenserName,
        dispenserPostCode,
        consultationId,
        createdDate,
        createdBy,
        updatedDate,
        updatedBy,
        ...prescription
      } = resItem;

      prescribers[prescriberId] = prescriber;
      const existingConsultationIndex = consultations.findIndex(
        consultation => consultation.consultationId === resItem.consultationId
      );
      if (existingConsultationIndex > -1) {
        const consultation = consultations[existingConsultationIndex];
        consultation.prescriptions.push(prescription);
        consultation.hasErrorInPrescriptions =
          PrescriptionsManagementService.hasErrorInPrescriptions(consultation, prescription.status);
      } else {
        const consultation = {
          patientName,
          patientSurname,
          patientPhoneNumber,
          patientId,
          nhsNumber,
          patientDateOfBirth,
          prescriberId,
          prescriberName,
          dispenserOds,
          dispenserName,
          dispenserPostCode,
          consultationId,
          createdDate,
          createdBy,
          updatedDate,
          updatedBy,
          prescriptions: [prescription],
        } as Consultation;
        consultation.hasErrorInPrescriptions =
          PrescriptionsManagementService.hasErrorInPrescriptions(consultation, prescription.status);
        consultations.push(consultation);
      }
    });
    result.consultations = consultations;
    result.prescribers = Object.values(prescribers);
    result.hasError = false;
    result.isLoading = false;
    return result;
  }

  searchByDate(from: Moment): Observable<PrescriptionsMappedData> {
    const fromDate = moment(from).startOf('day').toDate();
    const toDate = moment(from).add(1, 'day').startOf('day').toDate();
    return this.searchPrescriptions({
      From: fromDate,
      To: toDate,
    });
  }

  searchByConsultationId(consultationId: string): Observable<PrescriptionsMappedData> {
    return this.searchPrescriptions({
      ConsultationId: consultationId,
    }).pipe(
      tap(x =>
        x.consultations.forEach(
          c => (c.prescriptions = sortBy(c.prescriptions, ['prescriptionId']))
        )
      )
    );
  }

  permissionsError$<T>(permName: string, retVal: T): Observable<T> {
    return this.modalService
      .acknowledge(
        'Permissions',
        `You do not have the required permission on your smartcard to ${permName} prescriptions.
      You must have ${permName} prescription permissions for Push Dr.`
      )
      .pipe(switchMap(() => of(retVal)));
  }

  searchPrescriptions(requestBody): Observable<PrescriptionsMappedData> {
    const getData$ = this.post(
      `/search`,
      requestBody,
      this.headerService.legacyHeadersWithCorrelationId
    );

    return this.canView$().pipe(
      switchMap(x => {
        if (x) {
          return getData$.pipe(
            map(this.mapPrescriptionsData),
            startWith(DEFAULT_VALUES),
            catchError(() =>
              of({
                ...DEFAULT_VALUES,
                hasError: true,
                isLoading: false,
              })
            )
          );
        }
        return this.permissionsError$('view', {
          ...DEFAULT_VALUES,
          isLoading: false,
          hasError: true,
        } as PrescriptionsMappedData);
      })
    );
  }

  cancelPrescriptionItems(requestBody: PrescriptionCancelRequest): Observable<unknown> {
    return this.canCancel$().pipe(
      switchMap(canCancel => {
        if (canCancel) {
          return this.post(
            `/cancelItems`,
            requestBody,
            this.headerService.legacyHeadersWithCorrelationId
          );
        }

        return this.permissionsError$('cancel', null);
      })
    );
  }
}
