<div class="absolute inset-0 flex">
  <div class="max-w-xl px-20 py-16 m-auto text-center bg-white rounded shadow text-bluegrey-800">
    <div class="mb-8 text-xl leading-6">
      Sorry, we currently only support the Google&nbsp;Chrome&nbsp;browser
    </div>
    <div class="mb-12 font-extralight leading-6">
      Please either download Chrome using the button below, or if you have it installed already,
      open it and retry our link.
    </div>
    <a href="https://www.google.com/chrome/" target="_blank" class="btn btn-primary">
      Get&nbsp;Google&nbsp;Chrome
    </a>
  </div>
</div>
