import { Injectable } from '@angular/core';
import { CardReaderService, CliniciansDoctorsDetailsService } from '@pushdr/clinicians/common';
import { ClinicianType, PartnerType, PatientDetailsV2 } from '@pushdr/common/types';
import { RbacPerms } from '@pushdr/doctors/data-access/doctors-api';
import { combineLatest, iif, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PatientRecordsBaseService } from '../../routes/patient/services/patient-records/patient-records-base.service';
import { ConsultationRoleService } from '../consultation-role.service';
import { PatientDetailsService } from '../patient-details/patient-details.service';
import { DoctorDetails } from '@pushdr/doctors/data-access/doctors-api';
import { PrescriptionStatusService } from '@pushdr/prescription/shell';

interface PrescribeStatus {
  canPrescribe: boolean;
  dead?: boolean;
  sensitive?: boolean;
  noEps?: boolean;
  gpOds?: string;
  gpOdsMismatch?: boolean;
  featureDisabled?: boolean;
  notNhs?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ConsultationFeatureService {
  constructor(
    private doctorDetailsService: CliniciansDoctorsDetailsService,
    private patientDetailsService: PatientDetailsService,
    private cardReader: CardReaderService,
    private patientRecordsBase: PatientRecordsBaseService,
    private prescriptionStatus: PrescriptionStatusService,
    private consultationRole: ConsultationRoleService
  ) {}

  get canGetRecord$(): Observable<boolean> {
    const hasPdsTrace$ = this.patientDetailsService.foundPDS$;
    const isSensitivePatient$ = this.patientDetailsService.details$.pipe(
      map(details => details?.Patient?.NhsInformation?.SuccessResponse?.SensitivePatient === true)
    );
    return combineLatest([hasPdsTrace$, isSensitivePatient$, this.isConsultingWithChild$]).pipe(
      map(([hasPds, isSensitive, isChild]) => !isChild && !isSensitive && hasPds)
    );
  }

  get makeStructuredRecordsCall$(): Observable<boolean> {
    return this.patientDetailsService.foundPDS$;
  }

  get gpConnectAccess$(): Observable<boolean> {
    return this.doctorDetailsService.details$.pipe(map(d => d.gpConnectAccess));
  }

  get canSeeRecordsAll$(): Observable<boolean> {
    return combineLatest([this.canSeeRecordsHTML$, this.canSeeRecordsStructured$]).pipe(
      map(([html, struct]) => html && struct)
    );
  }

  get hasAccessToRecords$(): Observable<boolean> {
    return combineLatest([this.gpConnectAccess$, this.canGetRecord$]).pipe(
      map(([hasGpConnectAccess, canGetRecords]) => hasGpConnectAccess && canGetRecords)
    );
  }

  get canSeeRecordsHTML$(): Observable<boolean> {
    const canLoadHtml$ = this.patientRecordsBase.getPatientRecord().pipe(
      map(records => !records?.error),
      catchError(() => of(false))
    );

    return this.hasAccessToRecords$.pipe(
      switchMap(hasAccess => (hasAccess ? canLoadHtml$ : of(false)))
    );
  }

  get canSeeRecordsStructured$(): Observable<boolean> {
    const canLoadStruct$ = this.patientRecordsBase.getStructuredPatientRecord().pipe(
      map(records => !!records),
      catchError(() => of(false))
    );

    return this.hasAccessToRecords$.pipe(
      switchMap(hasAccess => (hasAccess ? canLoadStruct$ : of(false)))
    );
  }

  get prescribePermsIssue$(): Observable<PrescribeStatus> {
    return this.isNhs$.pipe(
      switchMap(isNhs => {
        if (isNhs) {
          return this.hasPrescribePermissionForPatient$;
        }
        return of({ canPrescribe: false, notNhs: true });
      })
    );
  }

  get hasPrescribePermissionForPatient$(): Observable<PrescribeStatus> {
    return this.patientDetailsService.details$.pipe(
      map(pat => {
        const pds = pat.Patient?.NhsInformation?.SuccessResponse;
        const dbOds = pat.Patient?.GpSummary?.OdsCode;
        // PGJ: Later this will always be from PDS
        const odsToUse = this.cardReader.surgeryOdsMustMatch()
          ? pds?.OrganisationDataServiceCode
          : dbOds;
        return <PrescribeStatus>{
          dead: !!pds?.DateOfDeath,
          gpOds: pds?.OrganisationDataServiceCode,
          gpOdsMismatch: odsToUse != dbOds,
          sensitive: pds?.SensitivePatient,
          noEps: !pat.Partner?.EpsEnabled,
          notNhs: !this.isNhs(pat),
        };
      }),
      switchMap(probs => {
        if (!probs.dead && !probs.noEps && !probs.sensitive && !probs.gpOdsMismatch) {
          return this.cardReader
            .checkPermission$(RbacPerms.prescriptionCreate, !probs.notNhs, probs.gpOds)
            .pipe(map(x => ({ canPrescribe: x, noPerms: !x } as PrescribeStatus)));
        }
        return of({ canPrescribe: false, ...probs });
      })
    );
  }

  get canPrescribe$(): Observable<boolean> {
    return this.canSeeRecordsAll$.pipe(
      switchMap(canGetRecord =>
        iif(
          () => canGetRecord,
          this.hasPrescribePermissionForPatient$.pipe(map(p => p.canPrescribe)),
          of(false)
        )
      )
    );
  }

  get isNhs$(): Observable<boolean> {
    return this.patientDetailsService.details$.pipe(map(details => this.isNhs(details)));
  }

  get canTakeNotes$(): Observable<boolean> {
    const hasPdsTrace$ = this.patientDetailsService.foundPDS$.pipe(
      switchMap(hasPds => (hasPds ? this.hasTakeNotesAccess$ : of(false)))
    );

    return this.sendDocsAccess$.pipe(
      switchMap(hasAccess => (hasAccess ? hasPdsTrace$ : of(false)))
    );
  }

  get hasTakeNotesAccess$(): Observable<boolean> {
    // Requirements for GP consultations
    const hasTakeNotesRequirements$ = combineLatest([
      this.isNotConsultingWithChild$,
      this.prescriptionStatus.hasPrescribed$,
    ]).pipe(map(flags => !flags.includes(false)));

    return this.hasTakeNotesRole$.pipe(
      switchMap(hasRole => (hasRole ? of(true) : hasTakeNotesRequirements$))
    );
  }

  get hasTakeNotesRole$(): Observable<boolean> {
    const roles = [
      ClinicianType.Physio,
      ClinicianType.MentalHealthTherapist,
      ClinicianType.MinorAilmentsPharmacist,
    ];
    return this.consultationRole.hasRole(roles);
  }

  get sendDocsAccess$(): Observable<boolean> {
    return this.doctorDetailsService.details$.pipe(
      map((details: DoctorDetails) => details.sendDocAccess)
    );
  }

  get canRecordDiagnosisForNHS$(): Observable<boolean> {
    return combineLatest([this.canTakeNotes$, this.isConsultingWithChild$, this.isNhs$]).pipe(
      map(
        ([canTakeNotes, isConsultatingWithChildren, isNhs]) =>
          !canTakeNotes && !isConsultatingWithChildren && isNhs
      )
    );
  }

  get canWriteFitNote$(): Observable<boolean> {
    return this.patientDetailsService.details$.pipe(
      map(details => details.Partner.PartnerType !== PartnerType.NHS)
    );
  }

  get canWriteReferral$(): Observable<boolean> {
    return this.patientDetailsService.details$.pipe(
      map(details => details.Partner.PartnerType !== PartnerType.NHS)
    );
  }

  get canConsultWithChildrenNHS$() {
    return this.patientDetailsService.details$.pipe(
      map(
        details =>
          details.Partner.PartnerType === PartnerType.NHS &&
          details.ConsultationConfig.ChildConsultation.IsEnabled
      )
    );
  }

  get hasTranslationServiceEnabled$() {
    return this.patientDetailsService.details$.pipe(
      map(details => details.ConsultationConfig.TranslatorService.IsEnabled)
    );
  }

  get isNotConsultingWithChild$() {
    return this.isConsultingWithChild$.pipe(map(isChild => !isChild));
  }

  get isConsultingWithChild$() {
    return this.patientDetailsService.details$.pipe(
      map(details => !!details.Patient.ChildVerification)
    );
  }

  isNhs(details: PatientDetailsV2) {
    return details?.Partner?.PartnerType === PartnerType.NHS;
  }
}
