import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SpinnerLoaderModule } from './spinner-loader/spinner-loader.module';
import { AlertBarModule } from './alert-bar/alert-bar.module';
import { InputWithIconModule } from './input-with-icon/input-with-icon.module';
import { DatePickerModule } from './date-picker/date-picker.module';
import { FormComponentsModule } from './form-components/form-components.module';
import { NotFoundModule } from './not-found/not-found.module';
import { ModalsModule } from './modals/modals.module';
import { InputWithCharacterCountModule } from './input-with-character-count/input-with-character-count.module';
import { ConsultationChatModule } from './consultation-chat/consultation-chat.module';
import { OpentokModule } from './video-providers/opentok/opentok.module';
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SpinnerLoaderModule,
    AlertBarModule,
    InputWithIconModule,
    DatePickerModule,
    FormComponentsModule,
    NotFoundModule,
    ModalsModule,
    ConsultationChatModule,
    OpentokModule,
  ],
  exports: [
    SpinnerLoaderModule,
    AlertBarModule,
    InputWithIconModule,
    DatePickerModule,
    InputWithCharacterCountModule,
    FormComponentsModule,
    NotFoundModule,
    ModalsModule,
    ConsultationChatModule,
    OpentokModule,
  ],
})
export class CommonComponentsModule {}
