import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ConsultationDynamicGroupComponent } from '../consultation-dynamic-group/consultation-dynamic-group.component';

@Component({
  selector: 'pushdr-consultation-card-dynamic',
  templateUrl: './consultation-card-dynamic.component.html',
  styleUrls: ['./consultation-card-dynamic.component.scss'],
})
export class ConsultationCardDynamicComponent {
  @Input() showHeader = true;
  @Input() extraCssClass = '';
  @Input() showFooter = false;
  @Input() bodyPadding = 'p-4';
  @Input() hasData = true;
  @Input() messageOveride = 'Loading';
  @Input() isEmpty = false;
  @Output() scrolledToEnd = new EventEmitter();

  @ViewChild('panelSwitch', { static: false }) panelSwitch: ElementRef;

  panelDdlOpen = false;
  availableCards$ = this.cardGroup.getAvailableCards();

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!(this.panelSwitch && this.panelSwitch.nativeElement.contains(event.target))) {
      this.panelDdlOpen = false;
    }
  }

  constructor(public cardGroup: ConsultationDynamicGroupComponent) {}

  onCardChange(card: any) {
    this.panelDdlOpen = false;
    this.cardGroup.onCardChange(card);
  }

  onPanelDdlToggle() {
    this.panelDdlOpen = !this.panelDdlOpen;
  }

  onScrolledToEnd() {
    this.scrolledToEnd.emit();
  }
}
