export const prescription = [
  {
    dosage: 'Take one after a meal while standing on your head.',
    duration: 11,
    quantity: 11,
    endorsements: [],
    patientNote: '',
    pharmacyNote: '',
    medicationName: 'Paracetamol 325mg / Isometheptene 65mg capsules',
    medicationCode: '322804009',
    unitOfMeasure: 'Capsule',
  },
  {
    dosage: 'Use it well',
    duration: 11,
    quantity: 11,
    endorsements: ['FS'],
    pharmacyNote: '',
    medicationName: 'Famciclovir 125mg tablets',
    medicationCode: '324793002',
    controlledScheduleNumber: 0,
    unitOfMeasure: 'Tablet',
  },
  {
    dosage: 'Throw into the air and catch in your mouth ',
    duration: 19,
    quantity: 20,
    endorsements: [],
    patientNote: '',
    pharmacyNote: '',
    medicationName: 'Ibuprofen 200mg capsules',
    medicationCode: '370195008',
    unitOfMeasure: 'Capsule',
  },
  {
    dosage: 'Be carful with these.',
    duration: 20,
    quantity: 40,
    endorsements: [],
    patientNote: '',
    pharmacyNote: '',
    medicationName: 'Codeine 15mg/5ml linctus',
    medicationCode: '15065911000001106',
    unitOfMeasure: 'Ml',
  },
  {
    dosage: "Don't cry",
    duration: 23,
    quantity: 21,
    endorsements: [],
    patientNote: '',
    pharmacyNote: '',
    medicationName: 'Hayleve 4mg tablets (Genesis Pharmaceuticals Ltd)',
    medicationCode: '7960911000001107',
    unitOfMeasure: 'Tablet',
  },
  {
    dosage: 'Inject into bits you want bigger.',
    duration: 10,
    quantity: 10,
    endorsements: [],
    pharmacyNote: '',
    medicationName: 'Botox 50unit powder for solution for injection vials (Allergan Ltd)',
    medicationCode: '13423211000001107',
    controlledScheduleNumber: 0,
    unitOfMeasure: 'Vial',
  },
  {
    dosage: 'Put it in your pipe and smoke it',
    duration: 20,
    quantity: 10,
    endorsements: [],
    pharmacyNote: '',
    medicationName: 'Cannabidiol 100mg/ml oral solution sugar free',
    medicationCode: '36508311000001103',
    controlledScheduleNumber: 2,
    unitOfMeasure: 'Ml',
  },
  {
    dosage: 'Take one a day standing on head',
    duration: 11,
    quantity: 11,
    endorsements: ['CC'],
    pharmacyNote: '',
    medicationName: 'Dianette tablets (Bayer Plc)',
    medicationCode: '587711000001100',
    controlledScheduleNumber: 0,
    unitOfMeasure: 'Tablet',
  },
];

export const prescriptionDispenser = {
  ods: 'FDC89',
  name: 'Instore Pharmacy',
  service_type: 'eps_pharmacy',
  address: {
    line: ['Manor Road', 'Altrincham', 'Cheshire', 'Cheshire'],
    postcode: 'WA15 9QT',
  },
  patient_contact: {
    tel: '0161 602 1052',
    web_address: 'https://www.tesco.com/groceries/en-GB/zone/pharmacy',
  },
  prescriber_contact: {
    tel: '',
    fax: '0161 956 9549',
  },
  location: {
    easting: 376983,
    northing: 387681,
  },
  opening: {
    open_247: false,
    mon: [
      {
        open: '08:00',
        close: '20:00',
      },
    ],
    tue: [
      {
        open: '08:00',
        close: '20:00',
      },
    ],
  },
  distance: 0.1,
};
