import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WebrtcDevicesetupService {
  localMediaStream: any;
  localMediaStreamContainer: any;

  constructor() {}

  getUserMediaStream(
    localVideoContainerId: string,
    video: boolean = false,
    audio: boolean = false,
    cameraDeviceId?: string,
    microphoneDeviceId?: string
  ): Observable<any> {
    console.log('get user media stream VIDEO: ' + video + ' AUDIO: ' + audio);
    this.localMediaStreamContainer = document.getElementById(localVideoContainerId);
    this.detachAllStreams();
    const userMediaConstraints: any = { video: video, audio: audio };
    const setLocalMediaStream = this.setLocalMediaStream.bind(this);
    if (cameraDeviceId && video) {
      userMediaConstraints.video = {
        optional: [{ sourceId: cameraDeviceId }],
      };
    }
    if (microphoneDeviceId && audio) {
      userMediaConstraints.audio = {
        optional: [{ sourceId: microphoneDeviceId }],
      };
    }

    return new Observable(observer => {
      console.log('navigator.mediaDevices.getUserMedia');
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = userMediaOptions => {
          // First get ahold of the legacy getUserMedia, if present
          const getUserMedia = navigator['webkitGetUserMedia'] || navigator['mozGetUserMedia'];

          // Some browsers just don't implement it - return a rejected promise with an error
          // to keep a consistent interface
          if (!getUserMedia) {
            return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
          }

          // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
          return new Promise((resolve, reject) => {
            getUserMedia.call(navigator, userMediaOptions, resolve, reject);
          });
        };
      }

      navigator.mediaDevices
        .getUserMedia(userMediaConstraints)
        .then(localMediaStream => {
          const permissions = {
            videoEnabled: !!localMediaStream.getVideoTracks().length,
            audioEnabled: !!localMediaStream.getAudioTracks().length,
          };
          console.log('set local media stream');
          setLocalMediaStream(localMediaStream);
          observer.next({ localMediaStream: localMediaStream, permissions: permissions });
          observer.complete();
        })
        .catch(error => {
          observer.error(error);
        });
    });
  }

  setLocalMediaStream(mediaStream) {
    this.localMediaStream = mediaStream;
    if ('srcObject' in this.localMediaStreamContainer) {
      console.log('src obj exists on video container');
      this.localMediaStreamContainer.srcObject = mediaStream;
    } else {
      console.log('create obj url');
      this.localMediaStreamContainer.src = window.URL.createObjectURL(mediaStream);
    }
    this.localMediaStreamContainer.onloadedmetadata = mediaStreamContainer => {
      try {
        mediaStreamContainer.play();
        console.log('play video');
      } catch (ex) {
        console.log('play video failed');
      }
    };
  }

  enumerateDeviceSupported() {
    return Boolean(navigator.mediaDevices && navigator.mediaDevices.enumerateDevices);
  }

  detachLocalVideoStream() {
    console.log('detach video stream');
    const userStream = this.localMediaStream;
    const videoTracks = userStream ? userStream.getVideoTracks() : 0;
    if (videoTracks.length > 0) {
      for (let i = 0; i < videoTracks.length; i++) {
        userStream.removeTrack(videoTracks[i]);
        videoTracks[i].stop();
        this.localMediaStreamContainer.setAttribute('src', '');
      }
    }
  }

  detachLocalAudioStream() {
    console.log('detach audio streams');
    const userStream = this.localMediaStream;
    const audioTracks = userStream ? userStream.getAudioTracks() : 0;
    if (audioTracks.length > 0) {
      for (let i = 0; i < audioTracks.length; i++) {
        userStream.removeTrack(audioTracks[i]);
        this.localMediaStreamContainer.setAttribute('src', '');
      }
    }
  }

  detachAllStreams() {
    // this.detachLocalVideoStream();
    // this.detachLocalAudioStream();
    console.log('detach all streams');
    if (this.localMediaStreamContainer) {
      if (this.localMediaStreamContainer.srcObject) {
        const stream = this.localMediaStreamContainer.srcObject;
        const tracks = stream.getTracks();

        tracks.forEach(track => {
          track.stop();
        });

        this.localMediaStreamContainer.srcObject = null;
      }
    }
  }

  getCameras() {
    return new Observable(function (observer) {
      if (navigator.mediaDevices.enumerateDevices) {
        navigator.mediaDevices
          .enumerateDevices()
          .then(devices => {
            const cameras = [];
            devices.forEach(device => {
              if (device.kind.toLowerCase() === 'videoinput') {
                cameras.push(device);
              }
            });
            observer.next(cameras);
            observer.complete();
          })
          .catch(err => {
            observer.error(err.name + ': ' + err.message);
          });
      }
    });
  }

  getMicrophones() {
    return new Observable(observer => {
      if (navigator.mediaDevices.enumerateDevices) {
        navigator.mediaDevices
          .enumerateDevices()
          .then(devices => {
            const microphones = [];
            devices.forEach(device => {
              if (device.kind.toLowerCase() === 'audioinput') {
                microphones.push(device);
              }
            });
            observer.next(microphones);
            observer.complete();
          })
          .catch(err => {
            observer.error(err.name + ': ' + err.message);
          });
      }
    });
  }
}
