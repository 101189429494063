import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ExtendedWindow, WINDOW } from '@pushdr/common/utils';
import { fadeInOnEnterAnimation } from 'angular-animations';
import { CliniciansAuthService } from '@pushdr/clinicians/common';

@Component({
  selector: 'pushdr-clinicians-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  animations: [fadeInOnEnterAnimation({ anchor: 'showContent' })],
})
export class CliniciansSignInComponent implements OnInit {
  loginDetailsIncorrect = false;
  isLoading = false;
  loginForm: UntypedFormGroup = null;
  isSubmitted = false;

  get formControls() {
    return this.loginForm.controls;
  }

  constructor(
    private auth: CliniciansAuthService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    @Inject(WINDOW) private window: ExtendedWindow
  ) {}

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.required],
    });
  }

  submit() {
    this.isLoading = true;
    this.isSubmitted = true;
    if (this.loginForm.invalid) {
      this.isLoading = false;
      return;
    }
    this.auth
      .sendSMSVerificationCode(this.loginForm.value.email, this.loginForm.value.password)
      .subscribe(
        validated => {
          this.isLoading = false;
          if (validated) {
            this.router.navigate(['/auth/secure-mobile']);
          }
        },
        err => {
          this.isLoading = false;
          this.loginDetailsIncorrect = true;
        }
      );
  }

  refresh() {
    this.window.location.reload();
  }
}
