import { Component, OnInit } from '@angular/core';
import { PatientRecordsSection, RecordsSectionTypes } from '@pushdr/common/types';
import { Observable } from 'rxjs';
import { PatientRecordsService } from '../../../services/patient-records/patient-records.service';

@Component({
  selector: 'pushdr-patient-summary',
  templateUrl: './patient-summary.component.html',
  styleUrls: ['./patient-summary.component.scss'],
})
export class PatientSummaryComponent implements OnInit {
  recordSectionData$: Observable<PatientRecordsSection>;

  constructor(private patientRecordsService: PatientRecordsService) {}

  ngOnInit() {
    this.getRecordData();
  }

  getRecordData() {
    this.recordSectionData$ = this.patientRecordsService.getRecordsSection(
      RecordsSectionTypes.SUMMARY
    );
  }
}
