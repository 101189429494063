import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from '@pushdr/common/data-access/analytics';
import { StorageService } from '@pushdr/common/utils';
import { PresurgeryService } from '../../services/patientapp-pre-surgery.service';
import { PresurgeryStateEnum } from '../../services/presurgery-common-states';

@Component({
  selector: 'pushdr-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss'],
})
export class StartComponent implements OnInit {
  _state: PresurgeryStateEnum = PresurgeryStateEnum.INITIALISED;
  PresurgeryStateEnum: typeof PresurgeryStateEnum = PresurgeryStateEnum;

  constructor(
    private router: Router,
    private preSurgeryService: PresurgeryService,
    private store: StorageService,
    private analytics: AnalyticsService
  ) {}

  ngOnInit() {
    // keep for cypress
    const storedStage = this.store.get('techCheckStage');
    if (storedStage) {
      this.restoreTechCheck(storedStage);
    }

    this.resetPresurgery();
  }

  next() {
    this.analytics.trackEvent({
      action: 'tech_checks_started',
    });
    this.router.navigate([`${this.preSurgeryService.baseHref}/camera`]);
  }

  get state() {
    return this._state;
  }

  set state(newState) {
    this.preSurgeryService.stateName = this._state = newState;
  }

  private resetPresurgery() {
    this.state = PresurgeryStateEnum.INITIALISED;
    this.preSurgeryService.stage = 1;
  }

  private restoreTechCheck(stage: number) {
    this.router.navigate(this.getPreviousRoute(stage));
  }

  private getPreviousRoute(stage) {
    if (stage < 3) {
      return [`${this.preSurgeryService.baseHref}/camera`];
    } else if (stage < 4) {
      return [`${this.preSurgeryService.baseHref}/microphone`];
    } else if (stage < 5) {
      return [`${this.preSurgeryService.baseHref}/sound`];
    } else {
      return [`${this.preSurgeryService.baseHref}/speed`];
    }
  }
}
