import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DoctorNote } from '@pushdr/consultation/api';

@Component({
  selector: 'pushdr-doctor-notes-list',
  templateUrl: './doctor-notes-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DoctorNotesListComponent {
  @Input() notes: DoctorNote[] | null = [];
}
