<ng-container *ngFor="let allergy of allergies">
  <a
    class="block hover:bg-indigo-100 cursor-pointer p-4 border-b border-grey-200"
    (click)="viewAllergyDetails.emit(allergy)">
    <pushdr-list-item [hasIcon]="shouldShowIcon(allergy)">
      <h4 title class="mb-0 text-sm font-normal">
        {{ allergy.code | getCodingText }}
      </h4>
      <h5 icon>CDS</h5>
      <div subtitle class="min-w-0 w-full text-xs truncate justify-between flex flex-row">
        <div>
          <p class="font-light text-grey-700 mb-0">Start Date</p>
          <p class="mb-0">{{ allergy | getAllergyOnsetDateTime | isoDate: 'DD-MMM-YYYY' }}</p>
        </div>
      </div>
    </pushdr-list-item>
  </a>
</ng-container>
