import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  RestClient,
  RestErrorParserService,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';
import { toCamelCase } from '@pushdr/common/utils';
import { EnvironmentProxyService } from '@pushdr/environment';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { BookingStatus, DoctorsBulletin, PasswordDetails } from './api-doctors-account.types';

@Injectable({
  providedIn: 'root',
})
export class ApiDoctorsAccount extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    const baseUrl = this.proxy.environment.doctors.api;
    return baseUrl + '/account.svc/accountW/';
  }

  addDoctorLog(log): Observable<any> {
    return this.post('AddDoctorLog', log, this.headerService.authorisedHeadersLegacy).pipe(
      pluck('AddDoctorLogResult')
    );
  }

  getDoctorDetails() {
    return this.post('GetDetails', {}, this.headerService.authorisedHeadersLegacy).pipe(
      pluck('GetDetailsResult')
    );
  }

  getPartnerConfiguration() {
    return this.post(
      'GetPartnerConfiguration',
      {},
      this.headerService.authorisedHeadersLegacy
    ).pipe(pluck('GetPartnerConfigurationResult'), toCamelCase());
  }

  getBookingStatus(): Observable<BookingStatus> {
    return this.post('GetBookingStatus', {}, this.headerService.authorisedHeadersLegacy).pipe(
      pluck('GetBookingStatusResult'),
      map(res => BookingStatus.fromApi(res))
    );
  }

  getAppointmentSummary() {
    return this.post('GetAppointmentSummary', {}, this.headerService.authorisedHeadersLegacy).pipe(
      pluck('GetAppointmentSummaryResult')
    );
  }

  getBulletins(): Observable<DoctorsBulletin[]> {
    return this.post('GetBulletins', {}, this.headerService.authorisedHeadersLegacy).pipe(
      pluck('GetBulletinsResult'),
      map(res => res.map(bulletin => DoctorsBulletin.fromApi(bulletin)))
    );
  }

  confirmBulletins(bulletinId: number) {
    return this.post(
      'ConfirmBulletins',
      { bulletinId },
      this.headerService.authorisedHeadersLegacy
    );
  }

  confirmTerms() {
    return this.post('ConfirmTerms', {}, this.headerService.authorisedHeadersLegacy).pipe(
      map(res => res.ConfirmTermsResult)
    );
  }

  checkForTerms(): Observable<boolean> {
    return this.post('ReconfirmTerms', {}, this.headerService.authorisedHeadersLegacy).pipe(
      map(res => !!res.ReconfirmTermsResult)
    );
  }

  getReadBulletins(): Observable<DoctorsBulletin[]> {
    return this.post('GetReadBulletins', {}, this.headerService.authorisedHeadersLegacy).pipe(
      pluck('GetReadBulletinsResult'),
      map(res => res.map(bulletin => DoctorsBulletin.fromApi(bulletin)))
    );
  }

  updatePassword(passwordDetails: PasswordDetails): Observable<any> {
    return this.post(
      'UpdatePassword',
      passwordDetails,
      this.headerService.authorisedHeadersLegacy
    ).pipe(
      pluck('UpdatePasswordResult'),
      map(res => !!res)
    );
  }

  updateAboutMe(strAboutYou: string): Observable<boolean> {
    return this.post(
      'UpdateAboutYou',
      { strAboutYou },
      this.headerService.authorisedHeadersLegacy
    ).pipe(
      pluck('UpdateAboutYouResult'),
      map(res => !!res)
    );
  }

  getProfileImage(): Observable<any> {
    return this.post('GetProfileImage', {}, this.headerService.authorisedHeadersLegacy).pipe(
      pluck('GetProfileImageResult')
    );
  }

  uploadProfileImage(base64Image: string): Observable<boolean> {
    return this.post(
      'UploadProfileImage',
      { strImageContents: base64Image },
      this.headerService.authorisedHeadersLegacy
    ).pipe(
      pluck('UploadProfileImageResult'),
      map(res => !!res)
    );
  }
}
