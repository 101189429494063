import { NgModule } from '@angular/core';

import { SpinnerLoaderComponent } from './spinner-loader.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  declarations: [SpinnerLoaderComponent],
  exports: [SpinnerLoaderComponent],
})
export class SpinnerLoaderModule {}
