import { Component, Input } from '@angular/core';

@Component({
  selector: 'pushdr-spinner-loader',
  templateUrl: './spinner-loader.component.html',
  styleUrls: ['./spinner-loader.component.scss'],
})
export class SpinnerLoaderComponent {
  @Input() message = '';
  @Input() title = '';
  @Input() minheight = 600;
  @Input() size = 8;
}
