import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { CliniciansAuthModule } from '@pushdr/clinicians/auth';
import { ClinicianAnalyticsService } from '@pushdr/clinicians/common';
import { CliniciansConsultationModule } from '@pushdr/clinicians/consultation';
import { CliniciansHomeModule } from '@pushdr/clinicians/home';
import { NotFoundComponent } from '@pushdr/common/components';
import { AnalyticsService, LOG_CONFIG } from '@pushdr/common/data-access/analytics';
import { RestNetworkLoggingInterceptorService } from '@pushdr/common/data-access/rest-http-core';
import {
  MessageQueue,
  SignalRV3Service,
  StorageService,
  TokenModule,
  WINDOW_PROVIDER,
} from '@pushdr/common/utils';
import { ApiDoctorsAccount, ApiDoctorsConsultation } from '@pushdr/doctors/data-access/doctors-api';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { SimpleModalModule } from 'ngx-simple-modal';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { EnvironmentProxyService } from '@pushdr/environment';
import { MockApiInterceptor } from './mock-api.interceptor';

import { PrescriptionProxy } from '@pushdr/prescription/shell';
import { AppPrescriptionProxy } from './prescription-proxy.provider';

import { DoctorNotesProxy } from '@pushdr/consultation/doctor-notes';
import { AppDoctorNotesProxy } from './doctor-notes-proxy.provider';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: NotFoundComponent,
    data: { withoutHeader: true },
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];

(
  c => s =>
    (l => (c.log = s ? (x, v) => (v === true ? l(x) : () => null) : l))(c.log)
)(console)(environment.id === 'local');

export function playerFactory() {
  return player;
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CliniciansAuthModule,
    CliniciansHomeModule,
    CliniciansConsultationModule,
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      relativeLinkResolution: 'legacy',
    }),
    TokenModule.forRoot({ tokenName: 'token' }),
    NgxIndexedDBModule.forRoot({
      name: 'CliniciansDb',
      version: 1,
      objectStoresMeta: [
        {
          store: 'Consultations',
          storeConfig: { keyPath: 'id', autoIncrement: false },
          storeSchema: [{ name: 'messageState', keypath: 'id', options: { unique: false } }],
        },
      ],
    }),
    SimpleModalModule.forRoot(
      { container: document.body },
      {
        closeOnEscape: false,
        closeOnClickOutside: false,
        wrapperDefaultClasses: 'c-modal',
        bodyClass: 'js-modal-open',
        wrapperClass: 'c-modal--is-visible',
        animationDuration: 300,
        autoFocus: true,
      }
    ),
    LottieModule.forRoot({ player: playerFactory }),
  ],
  providers: [
    WINDOW_PROVIDER,
    { provide: HTTP_INTERCEPTORS, useClass: MockApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RestNetworkLoggingInterceptorService, multi: true },
    {
      provide: EnvironmentProxyService,
      useFactory: (storage: StorageService) =>
        new EnvironmentProxyService(storage).setup(environment),
      deps: [StorageService],
    },
    {
      provide: AnalyticsService,
      useClass: ClinicianAnalyticsService,
    },
    {
      provide: MessageQueue,
      useClass: SignalRV3Service,
    },
    {
      provide: LOG_CONFIG,
      useFactory: (
        consultApi: ApiDoctorsConsultation,
        accountApi: ApiDoctorsAccount,
        storage: StorageService
      ) => {
        return {
          applicationName: 'Clinician App',
          get: storage.getSessionStorage,
          set: storage.setSessionStorage,
          delete: storage.deleteSessionStorage,
          emitStored: logs => consultApi.addDoctorLogRange(logs),
          emit: log => accountApi.addDoctorLog(log),
        };
      },
      deps: [ApiDoctorsConsultation, ApiDoctorsAccount, StorageService],
    },
    {
      provide: PrescriptionProxy,
      useClass: AppPrescriptionProxy,
    },
    {
      provide: DoctorNotesProxy,
      useClass: AppDoctorNotesProxy,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
