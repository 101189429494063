import { Pipe, PipeTransform } from '@angular/core';
import {
  Dispenser,
  DispenserOpenTime,
  DispenserOpenTimeSummary,
  DispenserWorkDay,
} from '@pushdr/prescription/api';
import * as moment from 'moment';

@Pipe({
  name: 'dispenserOpenTime',
})
export class DispenserOpenTimePipe implements PipeTransform {
  opening: DispenserOpenTimeSummary;
  prefix = 'Open: ';
  todayDate = moment().format('Y-M-D');

  formatOpenHours(openHours: DispenserOpenTime[]): string {
    if (this.opening.open_247) {
      return `${this.prefix}00:00&nbsp;-&nbsp;24:00`;
    }
    const workingHoursLine = openHours
      .map(part => `${part.open}&nbsp;-&nbsp;${part.close}`)
      .join(' then ');
    return `${this.prefix}${workingHoursLine}`;
  }

  filterPastHours(openHours: DispenserWorkDay, date) {
    if (!openHours) {
      return null;
    }
    let isDispenserOpenNow = false;
    const filteredTimeIntervals = openHours.filter(timePart => {
      const opens = moment(`${date.format('Y-M-D')} ${timePart.open}`, 'Y-M-D HH:mm');
      const closes = moment(`${date.format('Y-M-D')} ${timePart.close}`, 'Y-M-D HH:mm');
      const isFuture = date.isBefore(opens);
      isDispenserOpenNow = isDispenserOpenNow || date.isBetween(opens, closes) || opens.isAfter();
      return isFuture || isDispenserOpenNow;
    });

    return filteredTimeIntervals || null;
  }

  getOpenHours(date: moment.Moment): DispenserWorkDay {
    const dow = date.format('ddd');
    if (!this.opening) {
      return null;
    }

    const resHours = this.opening[dow.toLowerCase()];

    return resHours || null;
  }

  getSpecifiedDateOpenHours(date: string): DispenserWorkDay | undefined {
    if (
      this.opening &&
      this.opening.specified_date &&
      Object.prototype.hasOwnProperty.call(this.opening.specified_date, date)
    ) {
      return this.opening.specified_date[date];
    }
    return undefined;
  }

  transform(item: Dispenser): string {
    this.opening = item.opening;
    if (!this.opening) {
      return 'Unknown';
    }

    for (let dayShift = 0; dayShift <= 7; dayShift++) {
      const futureDay = moment().add(dayShift, 'day');
      let openHours = this.getOpenHours(futureDay);
      const daySpecificOpenHours = this.getSpecifiedDateOpenHours(futureDay.format('Y-M-D'));
      if (daySpecificOpenHours !== undefined) {
        openHours = daySpecificOpenHours;
      }
      openHours = this.filterPastHours(openHours, futureDay);

      if (openHours && openHours.length > 0) {
        if (dayShift) {
          this.prefix = 'Closed until ';
          if (dayShift === 1) {
            this.prefix += 'tomorrow. Open: ';
          } else {
            this.prefix += `${futureDay.format('dddd')}. Open: `;
          }
        }
        return this.formatOpenHours(openHours);
      }
    }
    // Dispenser is not opened within 1 week
    return "Won't be open within a week or more";
  }
}
