import { Component, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PasswordDetails } from '@pushdr/doctors/data-access/doctors-api';
import { PasswordInputWithRulesComponent } from '@pushdr/clinicians/common';

@Component({
  selector: 'pushdr-clinicians-change-password',
  templateUrl: './clinicians-change-password.component.html',
  styleUrls: ['./clinicians-change-password.component.scss'],
})
export class CliniciansChangePasswordComponent {
  @Output() changePassword = new EventEmitter<PasswordDetails>();

  passwordForm = new UntypedFormGroup({
    oldPassword: new UntypedFormControl('', [Validators.required]),
    newPassword: PasswordInputWithRulesComponent.createPasswordControl(),
  });

  get oldPasswordHasErrors(): boolean {
    const { oldPassword } = this.passwordForm.controls;
    return oldPassword.errors && oldPassword.touched;
  }

  onSubmit() {
    if (this.passwordForm.valid) {
      const { oldPassword, newPassword } = this.passwordForm.value;
      this.changePassword.emit({
        strOldPassword: oldPassword,
        strNewPassword: newPassword,
      });
      this.passwordForm.reset();
    }
  }
}
