import { Injectable } from '@angular/core';
import {
  ConsultationNotes,
  ApiDoctorsConsultationNotesService,
  ApiFollowUpService,
  FollowUpActions,
} from '@pushdr/doctors/data-access/doctors-api';
import { Observable, of, throwError } from 'rxjs';
import { map, publishReplay, refCount, mergeMap, tap, catchError } from 'rxjs/operators';
import { retryBackoff } from 'backoff-rxjs';
import { CurrentOrderIdService } from '@pushdr/common/data-access/analytics';
import { ModalService } from '@pushdr/common/overlay';

@Injectable({
  providedIn: 'root',
})
export class ConsultationNotesService {
  private _notes$: Observable<ConsultationNotes>;

  constructor(
    private api: ApiDoctorsConsultationNotesService,
    private followUpApi: ApiFollowUpService,
    private order: CurrentOrderIdService,
    private modal: ModalService
  ) {
    // Mitigates caching issues carried into next consultation
    this.order.idChanges.subscribe(() => this.clearCache());
  }

  get hasValidNotes$(): Observable<boolean> {
    return this.getNotes$().pipe(
      map(notes => {
        const confidential = notes?.confidential ? !!notes?.overrideGpShareReason : true;
        return (
          !!notes?.strExamination &&
          !!notes?.strManagementPlan &&
          !!notes?.strSafetyNetting &&
          confidential
        );
      })
    );
  }

  getNotes$(): Observable<ConsultationNotes> {
    if (!this._notes$) {
      this._notes$ = this.api.getConsultationNotes(this.order.id).pipe(
        mergeMap(consultationNotes => this.newConsultationNotesHandler$(consultationNotes)),
        retryBackoff({
          initialInterval: 2000,
          maxRetries: 3,
          shouldRetry: () => true,
        }),
        publishReplay(1),
        refCount()
      );
    }
    return this._notes$;
  }

  updateNotes(consultationNotes: ConsultationNotes) {
    return this.api.editConsultationNotes(this.order.id, consultationNotes).pipe(
      retryBackoff({
        initialInterval: 2000,
        maxRetries: 3,
        shouldRetry: () => true,
      }),
      tap(() => this.clearCache())
    );
  }

  clearCache() {
    this._notes$ = null;
  }

  getFollowUpActions$(): Observable<any> {
    return this.followUpApi.getFollowUpActions$(this.order.id).pipe(
      catchError(err => {
        this.modal.error(err?.error?.message);
        return throwError(err);
      })
    );
  }

  saveFollowUpActions$(followUpActions: FollowUpActions): Observable<boolean> {
    return this.followUpApi.saveFollowUpActions$(this.order.id, followUpActions).pipe(
      catchError(err => {
        this.modal.error(err?.error?.message);
        return throwError(err);
      })
    );
  }

  private newConsultationNotesHandler$(
    consultationNotes: ConsultationNotes
  ): Observable<ConsultationNotes> {
    if (consultationNotes.intNoteID === 0) {
      return this.api.addConsultationNotes(this.order.id).pipe(
        retryBackoff({
          initialInterval: 2000,
          maxRetries: 3,
          shouldRetry: () => true,
        }),
        map(noteId => {
          consultationNotes.intNoteID = noteId;
          return consultationNotes;
        })
      );
    }
    return of(consultationNotes);
  }
}
