import { HttpErrorResponse } from '@angular/common/http';

const htmlErrorStripper =
  /(<script(\s|\S)*?<\/script>)|(<title(\s|\S)*?<\/title>)|(<style(\s|\S)*?<\/style>)|(<!--(\s|\S)*?-->)|(<\/?(\s|\S)*?>)/g;

export class PDServerError extends HttpErrorResponse {
  header: string;
  message: string;

  constructor(init) {
    super(init);
  }

  static create(original: HttpErrorResponse, message: string, header: string = ''): PDServerError {
    const clonedError = new PDServerError({
      error: original,
      headers: original.headers,
      status: original.status,
      statusText: original.statusText,
      url: original.url || undefined,
    });
    const safeMessage = message || '';
    clonedError.header = header;
    clonedError.message = safeMessage.replace(htmlErrorStripper, '').trim();
    return clonedError;
  }

  get original() {
    return this.error;
  }
}
