<pushdr-consultation-card-dynamic
  *ngIf="{ list: resources$ | async } as resource"
  [hasData]="resource.list">
  <span header>GP Resources</span>
  <ng-container body>
    <div class="flex flex-col -m-4">
      <a
        class="block p-4 hover:bg-indigo-100"
        *ngFor="let resource of resource.list"
        [href]="resource.link"
        target="_blank">
        <pushdr-list-item>
          <i icon class="text-4xl text-bluegrey-700 material-icons-outlined">
            {{ resource.icon }}
          </i>
          <h4 title class="mb-0 text-sm font-normal">
            {{ resource.title }}
          </h4>
          <p subtitle class="min-w-0 mb-0 text-xs font-light truncate text-grey-700">
            {{ resource.label }}
          </p>
        </pushdr-list-item>
      </a>
    </div>
  </ng-container>
</pushdr-consultation-card-dynamic>
