<ng-container>
  <div *ngIf="base64Image === 'no-image'; else patientIdImage" class="h-full p-4">
    <div
      class="flex flex-col items-center justify-center h-full p-4 mx-auto text-center rounded bg-body-text sm:w-full md:w-3/5">
      <h4 class="text-white">ID not uploaded</h4>
      <p class="text-white">
        This account holder opted to show you their ID via the
        <br />
        video connection.
      </p>
    </div>
  </div>
  <ng-template #patientIdImage>
    <div class="id-image__wrap" [class.id-image__dragging]="dragging">
      <div
        class="id-image__background"
        [style.backgroundImage]="'url(' + base64Image + ')'"
        [style.backgroundPositionX]="left + 'px'"
        [style.backgroundPositionY]="top + 'px'"
        [style.backgroundSize]="
          (zoomPct / 100) * idWidth * idScale + 'px ' + (zoomPct / 100) * idHeight * idScale + 'px'
        "></div>
    </div>

    <div class="zoom-icons">
      <button class="zoom-icons__in" (click)="zoomIn()">
        <i class="material-icons">add</i>
      </button>
      <div class="zoom-icons__spacer"></div>
      <button class="zoom-icons__out" (click)="zoomOut()">
        <i class="material-icons">remove</i>
      </button>
    </div>

    <button
      *ngIf="showFullScreenButton"
      class="expand-icon"
      data-cy="fullscreen"
      (click)="toggleFullscreen()">
      <i class="material-icons">
        {{ isFullscreen ? 'fullscreen_exit' : 'fullscreen' }}
      </i>
    </button>
  </ng-template>
</ng-container>
<ng-template #imgLoading>
  <p>Checking for patient ID...</p>
</ng-template>
