import { SimpleModalComponent } from 'ngx-simple-modal';
import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface AbstractModalConfig<T> {
  data: Partial<T>;
  closer?: BehaviorSubject<boolean>;
  group: string;
}

@Injectable()
export class AbstractModal<T, U = any>
  extends SimpleModalComponent<AbstractModalConfig<T>, U>
  implements OnInit
{
  data: Partial<T> = {};
  closer: BehaviorSubject<boolean>;

  constructor() {
    super();
  }
  // eslint-disable-next-line @angular-eslint/contextual-lifecycle
  ngOnInit() {
    if (this.closer) {
      this.closer.subscribe(res => {
        if (res) {
          this.close();
          this.closer.complete();
        }
      });
    }
  }

  done(state: U) {
    this.result = state;
    this.close();
  }
}
