import { ClinicianType } from '@pushdr/common/types';
import * as moment from 'moment';
import { SurgeryType } from '../sessions/api-doctors-sessions.service';

export const doctorsBulletinPath = 'https://storage.googleapis.com';
export class DoctorsBulletin {
  dateStamp: string;
  heading: string;
  id: number;
  link: string;
  readDate?: string;

  static fromApi(apiResponse) {
    const db = new DoctorsBulletin();
    db.dateStamp = moment(apiResponse.DateStamp, 'DD-MM-YYYY HH:mm:SS').format('DD MMM YYYY');
    db.readDate = moment(apiResponse.ReadDate, 'DD-MM-YYYY HH:mm:SS').format('DD MMM YYYY');
    db.heading = apiResponse.Heading;
    db.id = apiResponse.Id;
    if (apiResponse.Link?.startsWith('https://')) {
      db.link = apiResponse.Link;
    } else {
      db.link = `https://storage.googleapis.com/${apiResponse.Link}`;
    }
    return db;
  }
}

export class DoctorDetails {
  name: string;
  aboutYou: string;
  surgeryTypes: SurgeryType[];
  GMC: string;
  mobile: string;
  linkedOrganisationId: any;
  gpConnectAccess?: boolean;
  sendDocAccess?: boolean;
  clinicianType?: ClinicianType;

  static fromApi(apiResponse) {
    const dd = new DoctorDetails();
    dd.name = apiResponse.strName;
    dd.aboutYou = apiResponse.strAboutYou;
    dd.surgeryTypes = apiResponse.Types;
    dd.GMC = apiResponse.strGMC;
    dd.mobile = apiResponse.strMobile;
    dd.linkedOrganisationId = apiResponse.LinkedOrganisationId;
    dd.gpConnectAccess = apiResponse.GpConnectAccess;
    dd.sendDocAccess = apiResponse.SendDocAccess;
    dd.clinicianType = apiResponse.ClinicianType;
    return dd;
  }
}

export class BookingStatus {
  line1: string;
  line2: string;
  line3: string;
  line4: string;
  line5: string;
  line6: string;

  static fromApi(apiResponse) {
    const dd = new BookingStatus();
    dd.line1 = apiResponse.strLine1;
    dd.line2 = apiResponse.strLine2;
    dd.line3 = apiResponse.strLine3;
    dd.line4 = apiResponse.strLine4;
    dd.line5 = apiResponse.strLine5;
    dd.line6 = apiResponse.strLine6;
    return dd;
  }
}

export class PasswordDetails {
  strOldPassword: string;
  strNewPassword: string;
}
