import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PresurgeryService } from '@pushdr/pre-surgery';
import { StorageService } from '@pushdr/common/utils';

@Component({
  selector: 'pushdr-tech-checks',
  templateUrl: './tech-checks.component.html',
  styleUrls: ['./tech-checks.component.scss'],
})
export class TechChecksComponent implements OnInit {
  constructor(
    private techChecks: PresurgeryService,
    private router: Router,
    private storage: StorageService
  ) {}

  ngOnInit() {
    this.techChecks.baseHref = '/consultation/tech-checks/';
    this.techChecks.help = {
      camera: { mac: 151, win: 152 },
      microphone: { mac: 153, win: 154 },
      speakers: { mac: 156, win: 157 },
      connection: { mac: 149, win: 149 },
    };

    this.techChecks.whenCompleted$().subscribe(() => {
      this.storage.set('Tech_Checked', true, 12);
      this.router.navigate(['consultation', 'waiting-room']);
    });
  }

  continue() {}
}
