import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PharmaceuticalsMedication } from '@pushdr/prescription/api';

@Component({
  selector: 'pushdr-medication-info',
  templateUrl: './medication-info.component.html',
  styleUrls: ['./medication-info.component.scss'],
})
export class MedicationInfoComponent implements OnInit {
  medication: PharmaceuticalsMedication;

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.medication = history.state?.medication;
    if (!this.medication?.snomedCode) {
      return this.clearMedication();
    }
    if (this.medication.monograph) {
      //this.medication.monograph = this.medication.monograph.replace('width="1280px"', 'width="100%"');
    }
  }

  navigateToMedicationSpecify() {
    this.router.navigate(['../specify-medication'], { relativeTo: this.route });
  }

  clearMedication() {
    this.router.navigate(['../select-medication'], { relativeTo: this.route });
  }
}
