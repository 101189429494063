import { Environment } from '../models';

// prettier-ignore
export const environment: Environment = {
  id: 'stub',
  version: '',
  testingMode: false,
  doctors: {
    api: '',
    newApi: '',
    recordsAPI: '',
    sessionsAPI: '',
    pharmaAPI: '',
    pharmacyAPI: '',
    prescriptionAPI: '',
    mq: {
      domainv3: '',
      signalRv3: '',
    },
    rbac:'',
    cardReaderEnv: ['LIVE', 'INT', 'DEV'],
  },
  production: false,
  appInsights: {
    config: {
      instrumentationKey: 'MOCK_KEY'
    }
  },
  google: {
    cliniciansGtm: ''
  },
  sentry: {
    environment: 'test'
  },
  zendesk: {
    key: '',
  },
};
