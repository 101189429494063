import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DispenserAddressPipe } from './pipes/dispenser-address-pipe';
import { DispenserOpenTimePipe } from './pipes/dispenser-open-time-pipe';
import { PrescribingDispenserSearchComponent } from './prescribing-dispenser-search.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [CommonModule, NgSelectModule],
  declarations: [PrescribingDispenserSearchComponent, DispenserAddressPipe, DispenserOpenTimePipe],
  exports: [PrescribingDispenserSearchComponent],
})
export class PrescriptionDispenserModule {}
