import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AnalyticsBusService, AnalyticsEvent } from '@pushdr/common/data-access/analytics';
import { ModalService } from '@pushdr/common/overlay';
import { DoctorsBulletin } from '@pushdr/doctors/data-access/doctors-api';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CliniciansBulletinsService } from '../bulletins.service';

@Component({
  selector: 'pushdr-clinicians-viewer-bulletins',
  templateUrl: './bulletins-viewer.component.html',
  styleUrls: ['./bulletins-viewer.component.scss'],
})
export class CliniciansBulletinsViewerComponent implements OnInit {
  @Input() needsAction = true;
  @Output() bulletinsComplete = new EventEmitter<void>();
  isChecked = false;
  submitted = false;
  trustedUrl: SafeResourceUrl;
  bulletins: DoctorsBulletin[];
  currentBulletin: DoctorsBulletin = null;

  currentBulletinIndex = 0;

  constructor(
    private bulletinsService: CliniciansBulletinsService,
    private msgBus: AnalyticsBusService,
    private sanitizer: DomSanitizer,
    private modal: ModalService
  ) {}

  ngOnInit() {
    this.getBulletins();
  }

  checkboxInput() {
    this.isChecked = !this.isChecked;
  }

  submit() {
    this.submitted = true;
    if (this.isChecked && this.currentBulletin.id) {
      this.confirmBulletins();
    }
  }

  private exitBulletinsViewer() {
    this.bulletinsService.flushUnreadMemCache();
    this.bulletinsComplete.emit();
  }

  private getBulletins() {
    this.bulletinsService
      .getUnreadBulletins()
      .pipe(
        catchError(error => {
          this.msgBus.trackEvent(AnalyticsEvent.error(`bulletins.viewer.get-error`, error.status));
          return of([]);
        })
      )
      .subscribe(res => {
        if (res && res.length > 0) {
          this.bulletins = res;
          this.getBulletin(this.currentBulletinIndex);
        } else {
          this.currentBulletin = null;
          this.bulletinsComplete.emit();
        }
      });
  }

  private getBulletin(index: number) {
    this.currentBulletin = this.bulletins[index];
    if (this.currentBulletin) {
      this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.currentBulletin.link);
    } else {
      this.exitBulletinsViewer();
    }
  }

  private confirmBulletins() {
    this.bulletinsService.markBulletinAsRead(this.currentBulletin.id).subscribe({
      next: () => {
        this.isChecked = false;
        this.submitted = false;
        this.currentBulletinIndex++;
        this.getBulletin(this.currentBulletinIndex);
      },
      error: err => {
        this.msgBus.trackEvent(
          AnalyticsEvent.error(`bulletins.viewer.confirm-error`, this.currentBulletin.id.toString())
        );
        this.modal.error(
          'When trying to mark this bulletin as agreed, something went wrong. Please try again.'
        );
      },
    });
  }
}
