<div class="p-4 bg-white">
  <div class="w-full cursor-pointer flex" (click)="backToListView.emit()">
    <i class="material-icons text-2xl mr-3">keyboard_backspace</i>
    <h4 class="mt-1">
      {{ allergy.code | getCodingText }}
    </h4>
  </div>
  <div class="min-w-0 w-full text-xs truncate justify-between flex flex-row mb-3">
    <div class="w-1/2">
      <p class="font-light text-grey-700 mb-0">Start Date</p>
      <p class="mb-0">{{ allergy | getAllergyOnsetDateTime | isoDate: 'DD-MMM-YYYY' }}</p>
    </div>
    <div class="w-1/2">
      <p class="font-light text-grey-700 mb-0">Clinical Status</p>
      <p class="mb-0">
        {{ allergy.clinicalStatus }}
      </p>
    </div>
  </div>
  <div class="min-w-0 w-full text-xs truncate justify-between flex flex-row mb-3">
    <div class="w-1/2">
      <p class="font-light text-grey-700 mb-0">Date Recorded</p>
      <p class="mb-0">
        {{ allergy.assertedDate | isoDate: 'DD-MMM-YYYY':'N/A' }}
      </p>
    </div>
    <div class="w-1/2">
      <p class="font-light text-grey-700 mb-0">Category</p>
      <p class="mb-0">
        <span *ngFor="let cat of allergy.category">{{ cat }}</span>
      </p>
    </div>
  </div>
  <div class="min-w-0 w-full text-xs truncate justify-between flex flex-row mb-3">
    <div>
      <p class="font-light text-grey-700 mb-0">Allergy Notes</p>
      <ng-container *ngIf="allergy.note && allergy.note.length > 0; else noNotes">
        <p class="mb-0" *ngFor="let note of allergy.note">
          {{ note.text }}
        </p>
      </ng-container>
      <ng-template #noNotes>
        <p class="mb-0">N/A</p>
      </ng-template>
    </div>
  </div>
</div>
