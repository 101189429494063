import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ClinicalDecisionSupportWarnings } from './models';

@Component({
  selector: 'pushdr-fdb-clinical-decision-support',
  templateUrl: './clinical-decision-support.component.html',
  styleUrls: ['./clinical-decision-support.component.scss'],
})
export class ClinicalDecisionSupportComponent implements OnChanges {
  @Input() warnings: ClinicalDecisionSupportWarnings = null;
  @Input() hideReferentialInfo = false;

  @Output() pdrViewed = new EventEmitter<null>();

  activeTab = 0;

  get hasReferentialInformation(): boolean {
    return this.warnings?.referentialInformationArr?.length > 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const warnings = changes?.warnings?.currentValue?.warningsArr ?? [];
    // Handles case when there's an empty warnings result
    if (warnings.length === 0) {
      this.pdrViewed.emit();
    }
  }
}
